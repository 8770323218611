import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-print-student-idcard',
  templateUrl: './print-student-idcard.component.html',
  styleUrls: ['./print-student-idcard.component.css']
})
export class PrintStudentIdcardComponent implements OnInit {
  // @Output() idCard = new EventEmitter<any>();
  idCardList: Array<any> = [];
  headingName: any = {};
  workItemId: any;

  constructor(private CommonService:CommonService,private active : ActivatedRoute) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadHeadings();

  }

  ngOnInit(): void {
    if (sessionStorage.idcardData) {
      this.idCardList = JSON.parse(sessionStorage.idcardData);
    }
  }


  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  Print() {
    var printContents: any = document.getElementById("print")?.innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();

    // const printContent = document.getElementById("print");
    // const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
    // WindowPrt.document.write(printContent.innerHTML);
    // WindowPrt.document.close();
    // WindowPrt.focus();
    // WindowPrt.print();
    // WindowPrt.close();
  }

  back() {
    window.history.back();
  }

}
