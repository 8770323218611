import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { combineLatest } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-receive-items',
  templateUrl: './receive-items.component.html',
  styleUrls: ['./receive-items.component.css']
})
export class ReceiveItemsComponent implements OnInit {
  isCheque: boolean = false;
  myForm: FormGroup;
  qId: any;
  qNo: any;
  editData: any = {};
  items: FormArray;
  Arr: Array<any> = [];
  total_cost: any;
  itemsDropDown: Array<any> = [];
  vendorId: any;
  mulVendors: Array<any> = [];
  list: FormArray;
  labelName: any = {};
  selectedList: Array<number> = [];
  selectedIndex: number = -1;
  headingName: any = {};
  workItemId: any;
  constructor(private ref: ChangeDetectorRef, private fb: FormBuilder, private route: Router, private CommonService: CommonService, private toastr: ToastrService, private active: ActivatedRoute,) {
    active.queryParams.subscribe((res: any) => {
      this.qId = res.qId;
      this.qNo = res.qNo;
      this.workItemId = res.wId;
    })

  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      receicedItems: this.fb.array([]),
      items: this.fb.array([]),
      // payment_date: [''], 
      // good_recieved_date: [''],
      // total_amount: [''],
      // advance_paid: [''],
      // paid_amount: [''],
      // cheque_number: [''],
      // payment_mode: [''],
      // cheque_date: [null],
      // quantity: this.fb.array([]),
      // items: this.fb.array([]),
    })
    this.add();
    this.getVendors();
    this.loadItems();
    this.getLabels();
    this.loadHeadings();
  }

  cheque(index: number) {
    // const isExists = this.selectedList.findIndex(e => e == index);
    // if (isExists)
    // this.selectedList.splice(index, 1);
    // else
    // this.selectedList.push(index);
  }

  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  loadItems() {
    this.itemsDropDown = [];
    // this.CommonService.postCall('InvetoryItems/InventoryItemsDropDownList', {}).subscribe((res: any) => {
    this.CommonService.getCall(`QuotationReceive/GetItems/${this.qId}`).subscribe((res: any) => {
      this.itemsDropDown = res;
    })
  }

  getOptionGroup(data) {
    let newGroup = this.fb.group({
      item_id: [data.item_id ? data.item_id : ''],
      unit_price: [data.unit_price ? data.unit_price : ''],
    });
    return newGroup
  }

  isAdd() {
    let arrayControl = <FormArray>this.myForm.controls['items'];
    if (arrayControl.length && arrayControl.length > 25) {
      return false
    }
    else {
      return true
    }
  }

  add(data = {}) {
    const arrayControl = <FormArray>this.myForm.controls['items'];
    let grp: FormGroup = this.getOptionGroup(data)
    arrayControl.push(grp)
  }

  delete() {
    const arrayControl = <FormArray>this.myForm.controls['items'];
    let index = arrayControl.length;
    arrayControl.removeAt(index - 1)
  }

  isVisable() {
    let arrayControl = <FormArray>this.myForm.controls['items'];
    if (arrayControl.length && arrayControl.length > 0) {
      return true
    }
    else {
      return false
    }
  }


  getVendors() {
    this.CommonService.getCall(`QuotationReceive/GetVendorsByquotation/${this.qId}`).subscribe((res: any) => {
      this.editData = res;
      this.mulVendors = res.mulvendors;
      this.vendorId = res.mulvendors.vendor_id;
      if (res.mulvendors != null && res.mulvendors.length > 0) {
        res.mulvendors.map((xe, index) => {

          this.list = this.fb.array(xe.itemlist.map(x => this.fb.group({
            // route_stop_id: x.route_stop_id,
            item_id: this.fb.control(x.item_id),
            quotated_quantity: this.fb.control(x.quotated_quantity),
            // quoted_cost: this.fb.control(x.Quotedfinalcost),
            Quotedfinalcost: this.fb.control(x.Quotedfinalcost),
            received_quantity: this.fb.control(x.received_quantity),
            amount: this.fb.control(x.amount),
          })))
          let newQuote = this.fb.group({
            vendor_name: this.fb.control(xe.vendor_name),
            vendor_id: this.fb.control(xe.vendor_id),
            payment_date: [''],
            good_recieved_date: [''],
            total_amount: [''],
            advance_paid: [''],
            paid_amount: [''],
            cheque_number: [''],
            payment_mode: [''],
            cheque_date: [null],
            quantity: this.list,
            // items: this.fb.array([]),  
          });
          for (let control of this.list.controls) {
            if (control instanceof FormGroup) {
              const quantityCtrl = control.get('Quotedfinalcost');
              const unitCostCtrl = control.get('received_quantity');
              const estimatedTotalCtrl = control.get('amount');
              const estimatedTotalamountCtrl = control.get('total_amount');
              combineLatest(quantityCtrl.valueChanges.pipe(startWith(quantityCtrl.value)),
                unitCostCtrl.valueChanges.pipe(startWith(unitCostCtrl.value))).subscribe(([Quotedfinalcost, received_quantity]) =>
                  estimatedTotalCtrl.setValue(+Quotedfinalcost * +received_quantity))
              if (newQuote.get('quantity')) {
                newQuote.get('quantity').valueChanges.subscribe(values => {
                  this.total_cost = 0;
                  const ctrl = <FormArray>newQuote.controls['quantity']
                  ctrl.controls.forEach(x => {
                    this.total_cost += x.get('amount').value;
                    ((this.myForm.controls['receicedItems'] as FormArray).at(index) as FormGroup).get('total_amount').patchValue(this.total_cost);
                    this.ref.detectChanges()
                  })
                })
              }
            }
          }
          (<FormArray>this.myForm.controls['receicedItems']).push(newQuote)
        })
      }
    })
  }

  onSubmit(form: any) {
    let value = form.value;
    let vendorlst = [];
    value.receicedItems.forEach(e => {
      let quantityArr = [];
      let add = parseInt(e.paid_amount) + parseInt(e.advance_paid)
      let addion = e.total_amount - add;
      e.quantity.forEach(es => {
        let obj = {
          item_id: es.item_id,
          received_quantity: es.received_quantity
        }
        quantityArr.push(obj);
      })
      let objs = {
        advance_paid: e.advance_paid,
        cheque_date: e.cheque_date,
        cheque_number: e.cheque_number,
        good_recieved_date: e.good_recieved_date,
        paid_amount: e.paid_amount,
        payment_date: e.payment_date,
        payment_mode: e.payment_mode,
        balance_amount: addion,
        quantity: quantityArr,
        total_amount: e.total_amount,
        vendor_name: e.vendor_name,
        quotation_number: this.qNo,
        quotation_receive_id: 23,
        quotation_id: this.qId,
        vendor_id: e.vendor_id,
        created_by: localStorage.getItem('UserId'),
        modified_by: localStorage.getItem('UserId'),
        tnt_code: localStorage.getItem('TenantCode'),
      }
      vendorlst.push(objs);
    });
    let payload = {
      "vendorlst": vendorlst,
      "items": value.items
    }
    this.CommonService.postCall('QuotationReceive/InsertReceiveItems', payload).subscribe((res: any) => {
      this.toastr.success("Quotation Items Recevied Succuessfully");
      this.route.navigate(['home/receivedQuotationsList']);
    }, err => {
      this.toastr.error(err.error ? err.error : 'Quotation Items Not Recevied')

    })

  }

  back() {
    window.history.back();
  }
  getName(i: number): string {
    let s: string = "radioGroup" + i.toString();
    return s;

  }

}
