<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.QuestionFeedbackList}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div *ngIf="table.length" class="pt-4">
                                            <table id="patreggraph" datatable [dtOptions]="dtOptions"
                                                class="table table-striped table-bordered mt-2 pt-2 table-sm small display"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624fd7; color:#ffffff;">
                                                    <tr>
                                                        <th> Q </th>
                                                        <th> Question </th>
                                                        <th> Total Users </th>
                                                        <th> Responded Users </th>
                                                        <th> Excellent </th>
                                                        <th> Very Good </th>
                                                        <th> Good </th>
                                                        <th> Poor </th>
                                                        <th> Very Poor </th>
                                                        <th> Percentage </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table;let i = index">
                                                        <td>{{i+1}}</td>
                                                        <td>{{item.question}}</td>
                                                        <td>{{item.course_student_id}}</td>
                                                        <td>{{item.responded_list}}</td>
                                                        <td>{{item.excellent}}</td>
                                                        <td>{{item.verygood}}</td>
                                                        <td>{{item.good}}</td>
                                                        <td>{{item.poor}}</td>
                                                        <td>{{item.verypoor}}</td>
                                                        <td class="text-center">
                                                            <button class="btn btn-sm btn-ai" data-bs-toggle="modal"
                                                                data-bs-target="#myModal"
                                                                (click)="clickView(item)">View</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center pt-4" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog  modal-lg">
        <div class="modal-content">

            <div class="modal-header py-2">
                <h4 class="modal-title w-100"> {{headingName.QuestionWiseReport}}</h4>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card radius-10 border-0 mb-4 overflow">
                            <div class="card-body">
                                <div>
                                    <h6 class="text-center">{{GraphView.question}}</h6>
                                </div>
                                <!-- <div class="d-flex align-items-center gap-3">
                            <div class="icon-box rounded p-2 shadow bg_blue text-white"
                              style="justify-content: center; text-align: center"><img
                                src="../../assets/img/super-admin-images/class.png" class="img-fluid" alt=""></div>
                            <div class="flex-grow-1">
                              <h6 class="mb-0 text_blue">Classess</h6>
                            </div>
                          </div> -->
                                <div class="my-3" id="chart7">
                                    <div>
                                        <div style="display: block">
                                            <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels"
                                                [options]="barChartOptions1" [legend]="barChartLegend"
                                                [chartType]="barChartType">
                                            </canvas>
                                        </div>
                                    </div>
                                </div>
                                <div style="line-height: 2; font-weight: 500; font-size: 12px;" class="ps-0 mb-0 row">
                                    <div class="col-4  card-values"><span class="text_green"><i
                                                class="fas fa-circle"></i></span><span class="ps-2">Excellent
                                            ({{GraphView.excellent_per}})</span></div>
                                    <div class="col-4  card-values"><span class="text_yellow"><i
                                                class="fas fa-circle"></i></span><span class="ps-2">Very Good
                                            ({{GraphView.verygood_per}})</span>
                                    </div>
                                    <div class="col-4  card-values"><span class="text_blue"><i
                                                class="fas fa-circle"></i></span><span class="ps-2">Good
                                            ({{GraphView.good_per}})</span>
                                    </div>
                                    <div class="col-4  card-values"><span class="text_orange"><i
                                                class="fas fa-circle"></i></span><span class="ps-2"> Poor
                                            ({{GraphView.poor_per}})</span>
                                    </div>
                                    <div class="col-4  card-values"><span class="text_red"><i
                                                class="fas fa-circle"></i></span><span class="ps-2"> Very Poor
                                            ({{GraphView.verypoor_per}})</span></div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-danger" id='md_close' (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>