import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-sms-status',
  templateUrl: './sms-status.component.html',
  styleUrls: ['./sms-status.component.css']
})
export class SmsStatusComponent extends BaseComponent implements OnInit {
  table: Array<any> = [];
  headingName: any = {};
  workItemId: any;
  labelName: any = {};
  constructor( CommonService: CommonService,private active : ActivatedRoute,toastr: ToastrService) {
    super(CommonService, toastr);
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.smsList();
    this.loadHeadings();
    this.getLabels();
  }

  ngOnInit(): void {
  }
  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  
  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }
  smsList() {
    let payload={
      tnt_code:this.tId || localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('EmsAlerts/GetSmsStatus',payload).subscribe((response: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = response;
      }, 10);
    })
  }
}
