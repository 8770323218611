import { Component, OnInit } from '@angular/core';
import { AbstractControl, Form, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-add-staff-attendance',
  templateUrl: './add-staff-attendance.component.html',
  styleUrls: ['./add-staff-attendance.component.css']
})
export class AddStaffAttendanceComponent implements OnInit {
  table: Array<any> = [];
  myForm: FormGroup;
  attendanceList: Array<any> = [];
  isTable: Boolean = false;
  isCheck: boolean = false;
  checked: boolean;
  id: any;
  date: any;
  selectAll: boolean;
  today: any = new Date();
  todayDate: any
  headingName: any = {};
  labelName: any = {};
  workItemId: any;
  constructor(private fb: FormBuilder,private active : ActivatedRoute ,private CommonService: CommonService, private toastr: ToastrService, private route: Router) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.calDate();
    this.loadHeadings();
    this.getLabels();
  }
  ngOnInit(): void {
    this.myForm = this.fb.group({
      date: ['', Validators.required]
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  getStaffList(date: any) {
    this.table = [];
    this.date = date
    let payload = {
      "date": this.date,
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('TimeTable/GetStaffList', payload).subscribe((res: any) => {
      this.table = res;
      setTimeout(() => {
        this.table = res;
      }, 10)
      this.selectAll = this.table.find(e => e.checkstatus)
    })
  }


  calDate() {
    var d = this.today,
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    this.todayDate = [year, month, day].join('-');
    console.log(this.todayDate);
    return [year, month, day].join('-');
  }

  checkAttendance(item: any, event: any, i: any) {
    // this.staffId = this.table.filter((e: any) => e.people_id == event.target.value)


    this.isCheck = true;
    // item.checked = event.target.checked;
    // this.table[i]['checked'] = event.target.checked;
    if (event.target.checked) {

      this.table[i]['attendance_status'] = "Present"
    } else {
      this.table[i]['attendance_status'] = "Absent"
    }
    // console.log(this.table)
  }

  checkAll(event: any) {

    this.isCheck = true;
    this.table.forEach(e => {
      e.checked = event.target.checked

      if (event.target.checked == true) {
        this.table.forEach(a => { a.checked = a.attendance_status == "Absent" ? false : true });
        e.attendance_status = "Present"
      } else {
        e.attendance_status = "Absent"
      }
    });


    if (event.target.checked == true) {
      this.table.forEach(a => { a.checked = a.attendance_status == "Absent" ? false : true });
    }
    else {
      this.table.forEach(a => { a.checked = a.attendance_status == "Absent" ? false : true });
    }
  }
  onSubmit(form: FormGroup) {
    let pay = this.myForm.value
    this.isTable = false;
    let payload = [];
    this.table.forEach(element => {
      let obj = {
        "people_id": element.people_id,
        "created_by": localStorage.getItem('UserId'),
        "modified_by": localStorage.getItem('UserId'),
        "tnt_code": localStorage.getItem('TenantCode'),
        "staff_attendance_id": element.staff_attendance_id || 0,
        "date": pay.date
      }
      if (element["attendance_status"] == "") {
        obj['attendance_status'] = "Absent"
        element['attendance_status'] = "Absent"
      } else {
        obj['attendance_status'] = element.attendance_status
        element['attendance_status'] = element.attendance_status
      }
      payload.push(obj);
    });
    this.CommonService.postCall('TimeTable/CreateStaffAttendance', payload).subscribe((res: any) => {
      this.attendanceList = res;
      this.toastr.success("Attendance Updated Successfully");
      this.route.navigate(['home/staffAttendance'])
    }, err => {
      this.toastr.error(err.error ? err.error : 'Attendance Not Updated')
    })

  }

  changeAttendance(pId: any, sId: any, status: any) {
    let payload = {
      "people_id": pId,
      "attendance_status": status,
      "modified_by": localStorage.getItem('TenantCode'),
      "date": this.date
    }
    if (confirm(`Are you sure you want to ${status} this Staff ?`)) {
      this.CommonService.postCall('TimeTable/AbsentOrPresent', payload).subscribe((res: any) => {
        this.getStaffList(this.date);
        this.isTable = true;
        this.toastr.success("Attendance Posted Successfully");
      })
    }
  }

  close() {
    this.route.navigate([`home/staffAttendance/${this.workItemId}`]);
  }


}
