<br>

<div class="page-wrapper">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">

                    <div class="container-fluid">

                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">



                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">Add Stops</h5>
                                    </div>
                             
                                    <div class="form-group row pt-4">
                                        <label class="col-md-2 control-label mt-1 ms-2">
                                            Route Name <span class="text-danger">*</span></label>
                                        <div class="col-md-5 inputGroupContainer">
                                            <div class="input-group"> <strong>:</strong> &nbsp;
                                                <select class="form-control form-control-sm shadow-sm rounded-lg"
                                                    formControlName='ROUTE_NAME'>
                                                    <option value="" [selected]="true">select</option>
                                                    <option [value]='item.DICTIONARYID' *ngFor='let item of titles'>
                                                        {{item.DICTIONARYNAME}}
                                                    </option>
                                                </select>
                                            </div>

                                        </div>
                                    </div>
                                 
                                    <div class="col-md-6">
                                        <div class="form-group row pt-4"style="margin-left:-5px">
                                            <label class="col-md-4 control-label line_2 rem_1 " >
                                                Stop Name <span class="text-danger">*</span></label>
                                            <div class="col-md-8 inputGroupContainer" style="display:flex;align-items: center;">
                                                <div class="input-group" style="margin: left -4px;"> <strong>:</strong> &nbsp;
                                                    <input type="text" style="margin-top: -18px;margin: left 10px;margin-right: 30px; margin-left: 5px;">
                                                </div>
                                                <div class="input-group"style="margin-right: 10px; margin-left: 5px;"> 
                                                    <input type="time"style="width:70%">
                                                </div>
                                                <div class="input-group"style="margin-right: 10px; margin-left: 5px;"> 
                                                    <input type="time"style="width:70%">
                                                </div>
                                                <div class="input-group"style="margin-right: 10px; margin-left: 5px;"> 
                                                    <input type="text">
                                                </div>
                                                <div class="input-group"style="margin-right: 30px; margin-left: 5px;"> 
                                                    <input type="text">
                                                </div>
                                            <div  ><i class="bi bi-x-square-fill" click="mk()"></i><svg xmlns="http://www.w3.org/2000/svg" width="20" height="30" fill="currentColor" class="bi bi-x-square-fill" viewBox="0 0 16 16">
                                                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z"/>
                                           
                                              </svg>  </div>  
                                        </div>
                                    </div>
                                </div>
                               
                                <div><i class="bi bi-plus" style="margin-left:900px"></i><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                  </svg></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>