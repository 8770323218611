import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, FormGroupName, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, from } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-recevied-quotation',
  templateUrl: './recevied-quotation.component.html',
  styleUrls: ['./recevied-quotation.component.css']
})
export class ReceviedQuotationComponent implements OnInit {
  myForm: FormGroup;
  isEdit: boolean = false;
  editData: any = {};
  qId: any;
  qrId: any;
  list: FormArray;
  vendors: Array<any> = [];
  items: Array<any> = [];
  item_id: any;
  quantity: any;
  estimated_cost: any;
  estimated_total: any;
  total: any;
  quoted_total: any;
  quotes: Array<any> = [];
  total_amount: any;
  comments: any;
  vendorID: any;
  labelName: any = {};
  getQuotationRes: Array<any> = [];
  headingName: any = {};
  workItemId: any;
  constructor(private ref: ChangeDetectorRef, private fb: FormBuilder, private route: Router, private CommonService: CommonService, private toastr: ToastrService, private active: ActivatedRoute,) {
    active.queryParams.subscribe((res) => {
      this.qId = res.qId;
      this.workItemId = res.wId;
      console.log(res, this.qId)
      if (res.qId) {
        this.qId = res.qId;
        this.workItemId = res.wId;
      }

    })
    this.myForm = this.fb.group({
      Quotation: this.fb.array([]),
    });
    // active.queryParams.subscribe((res)=>{
    //   if(res.qrId) {this.qrId = res.qrId}
    // })

    this.vendorDropdown();
    this.loadItems();
    this.getLabels();
    this.loadHeadings();
    let temp = this.getQuotation();

  }

  ngOnInit(): void {

    // this.add();
  }




  getOptionGroup(data) {
    // let newGroup = this.fb.group({
    //   vendor_id: [data.vendor_id ? data.vendor_id : '', Validators.required],
    //   item_id: [data.item_id ? data.item_id : '', Validators.required],
    //   quantity: [data.quantity ? data.quantity : '', Validators.required],
    //   estimated_cost: [data.estimated_cost ? data.estimated_cost : '', Validators.required],
    //   estimated_total: [data.estimated_total ? data.estimated_total : '', Validators.required],
    //   quoted_unit_cost: [data.quoted_unit_cost ? data.quoted_unit_cost : '', Validators.required],
    //   quoted_total: [data.quoted_total ? data.quoted_total : '', Validators.required],
    //   total_amount: [data.total_amount ? data.total_amount : '', Validators.required],
    //   admin_comments: [data.admin_comments ? data.admin_comments : ''],
    //   vendor_comment:[data.vendor_comment ? data.vendor_comment : ''],
    //   lis:[]

    // });
    let newQuote = this.fb.group({
      vendor_id: '',
      total_amount: '',
      admin_comments: '',
      vendor_comment: '',
      quoted_unit_cost: '',
      quoted_total: '',
      list: this.fb.array([])
    })
    console.log(this.getQuotationRes)
    this.list = this.fb.array(this.getQuotationRes.map(x => this.fb.group({
      item_id: this.fb.control(x.item_id),
      quantity: this.fb.control(x.quantity),
      estimated_cost: this.fb.control(x.estimated_cost),
      estimated_total: this.fb.control(x.estimated_total),
      quoted_unit_cost: this.fb.control(x.quoted_unit_cost),
      quoted_total: this.fb.control(x.quoted_total),
    })))
    console.log(newQuote)
    newQuote = this.fb.group({
      vendor_id: '',
      total_amount: '',
      admin_comments: '',
      vendor_comment: '',
      list: this.list,
    });
    console.log(this.list);

    // const quantityCtrl = this.list.get('quantity');
    // const unitCostCtrl = this.list.get('quoted_unit_cost');
    // const estimatedTotalCtrl = this.list.get('quoted_total');
    // combineLatest(quantityCtrl.valueChanges.pipe(startWith(quantityCtrl.value)),
    //   unitCostCtrl.valueChanges.pipe(startWith(unitCostCtrl.value))).subscribe(([quantity, quoted_unit_cost]) =>
    //     estimatedTotalCtrl.setValue(quantity * quoted_unit_cost))
    console.log(this.myForm)

    return newQuote
  }


  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  isAdd() {
    let arrayControl = <FormArray>this.myForm.controls['Quotation'];
    if (arrayControl.length && arrayControl.length > 25) {
      return false
    }
    else {
      return true
    }
  }

  add(data = {}) {
    const arrayControl = <FormArray>this.myForm.controls['Quotation'];
    let grp: FormGroup = this.getOptionGroup(data)
    arrayControl.push(grp)
  }

  delete() {
    const arrayControl = <FormArray>this.myForm.controls['Quotation'];
    let index = arrayControl.length;
    arrayControl.removeAt(index - 1)
  }

  isVisable() {
    let arrayControl = <FormArray>this.myForm.controls['Quotation'];
    if (arrayControl.length && arrayControl.length > 0) {
      return true
    }
    else {
      return false
    }
  }



  getQuotation() {
    this.isEdit = true;
    this.getQuotationRes = [];
    let tempArray = []
    this.CommonService.postCall('Quotation/Get', { "quotation_id": this.qId }).subscribe((res: any) => {
      this.getQuotationRes = res.list;
      let newQuote = this.fb.group({
        vendor_id: '',
        total_amount: '',
        admin_comments: '',
        vendor_comment: '',
        quoted_unit_cost: '',
        quoted_total: '',
        list: this.fb.array([])
      })

      this.list = this.fb.array(res.list.map(x => this.fb.group({
        item_id: this.fb.control(x.item_id),
        quantity: this.fb.control(x.quantity),
        estimated_cost: this.fb.control(x.estimated_cost),
        estimated_total: this.fb.control(x.estimated_total),
        quoted_unit_cost: this.fb.control(x.quoted_unit_cost),
        quoted_total: this.fb.control(x.quoted_total),
      })))
      console.log(this.list)
      console.log(newQuote)
      newQuote = this.fb.group({
        vendor_id: '',
        total_amount: '',
        admin_comments: '',
        vendor_comment: '',

        list: this.list,

      });

      (<FormArray>this.myForm.get('Quotation')).push(newQuote)
    })
  }


  vendorDropdown() {
    let payload = {
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('Vendor/VendorDropDownList', payload).subscribe((res: any) => {
      this.vendors = res;
    })
  }


  loadItems() {
    this.items = [];
    this.CommonService.postCall('InvetoryItems/InventoryItemsDropDownList', {}).subscribe((res: any) => {
      this.items = res;
    })
  }

  getTotal(i: any, ls: any) {
    console.log(i, ls)
    const qObj = this.myForm.value.Quotation[i];
    const lObj = qObj.list[ls]
    console.log(qObj)
    console.log(lObj)
    lObj.quoted_total = lObj.quantity * lObj.quoted_unit_cost;
    // qObj.total_amount = lObj.quantity * lObj.quoted_unit_cost;
    return lObj.quantity * lObj.quoted_unit_cost

  }

  grandTotal(i: any) {
    console.log(i)
    const qObj = this.myForm.value.Quotation[i];
    let sum = 0;
    qObj.list.forEach(element => {
      sum = sum + element.quoted_total;
    });
    qObj.total_amount = sum;
    return sum;
  }

  ngAfterViewChecked() {
    this.ref.detectChanges();
  }

  onSubmit(form: FormGroup) {
    let value: any = form.value;
    console.log(value);
    let payload = []
    value.Quotation.forEach(element => {
      console.log(element)
      let obj = {
        "quotation_id": this.qId,
        "vendor_id": element.vendor_id,
        "quoted_total": element.quoted_total,
        "vendor_comment": element.vendor_comment,
        "admin_comments": element.admin_comments,
        "quotation_receive_approved_by": localStorage.getItem('UserId'),
        "total_amount": element.total_amount,
        "created_by": localStorage.getItem('UserId'),
        "tnt_code": localStorage.getItem('TenantCode'),
      }
      let list = [];
      let iId: string | any;
      element.list.forEach(el => {
        let objList = {
          "quotation_receive_id": this.qrId,
          "quotation_detail_id": el.item_id,
          "quotated_quantity": el.quantity,
          "quoted_cost": el.estimated_cost,
          "quoted_detail_total": el.estimated_total,
          "created_by": localStorage.getItem('UserId'),
          "tnt_code": localStorage.getItem('TenantCode'),
        }
        let id = el.item_id;
        if (iId) {
          iId = iId + ',' + id
        } else {
          iId = id
        }
        list.push(objList)
      });
      obj['list'] = list;
      obj['selected_itemid'] = iId;
      payload.push(obj);

    });
    console.log(payload);
    this.CommonService.postCall('QuotationReceive/Create', payload).subscribe((res: any) => {
      this.toastr.success("Quotation Received Successfully");
      this.route.navigate(['home/quotation']);
    }, err => {
      this.toastr.error(err.error ? err.error : 'Unsuccessfull')
    })

  }
  close() {
    this.route.navigate([`home/quotation/${this.workItemId}`])
  }

  // edit(qrId: any) {
  //   this.editData = qrId;
  //   let payload = {
  //     "quotation_receive_id": this.qrId
  //   }
  //   this.CommonService.postCall('QuotationReceive/Get', payload).subscribe((res: any) => {
  //     if (res instanceof Array && res.length) {
  //       this.editData = res[0];
  //       this.dataTransForm()
  //     } else {
  //       this.editData = res;
  //       this.dataTransForm()
  //     }
  //   })
  // }
  // dataTransForm() {

  //   let ctrls = this.myForm.controls
  //   Object.keys(ctrls).map((key) => {
  //     let ctrl: AbstractControl = ctrls[key];
  //     ctrl.setValue(this.editData[key])

  //   });
  // }


}



