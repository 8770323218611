import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-vehicle-in-out',
  templateUrl: './vehicle-in-out.component.html',
  styleUrls: ['./vehicle-in-out.component.css']
})
export class VehicleInOutComponent implements OnInit {
  table: Array<any> = [];
  headingName: any = {};
  workItemId: any;

  constructor(private fb: FormBuilder,private active : ActivatedRoute, private CommonService: CommonService, private toastr: ToastrService) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.LoadVehicle();
    this.loadHeadings();
  }

  ngOnInit(): void {

  }
  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  LoadVehicle() {
    this.CommonService.postCall('Transport/GetVehicleInOutList', { "tnt_code": localStorage.getItem('TenantCode') }).subscribe((res: any) => {
      this.table = res;
    })
  }

}
