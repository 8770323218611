import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-taskmanager',
  templateUrl: './taskmanager.component.html',
  styleUrls: ['./taskmanager.component.css']
})
export class TaskmanagerComponent implements OnInit {

  table: Array<any> = [];
  myForm: FormGroup;
  isEdit: boolean = false;
  editData: any = {};
  parents: any = [];
  labelName: any = {};
  headingName: any = {};
  workItemId: any;
  constructor(private active : ActivatedRoute,private fb: FormBuilder, private CommonService: CommonService, private toastr: ToastrService) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadlabels();
    this.loadTaskManager();
    this.BindParentTask();
    this.loadHeadings();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      TaskName: ['', Validators.required],
      TaskType: ['', Validators.required],
      TaskUrl: ['',],
      Description: [''],
      ParentTaskId: [''],
      ShowInGrid: ['',],
      ShowInDashboard: [],
      CSSClass: [''],
      Status: [true, Validators.required]
    })
  }
  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }


  BindParentTask() {
    this.activeSpinner();
    let payload = {
      "TENANT_CODE": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('BindParentTask', payload).subscribe((res: any) => {
      this.parents = res;
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner() })
  }
  loadTaskManager() {
    this.activeSpinner();
    this.CommonService.postCall('LoadTaskManager', { TENANT_CODE: localStorage.getItem('TenantCode') }).subscribe((res: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = res;
      }, 10)

      this.deactivateSpinner();
    }, err => { this.deactivateSpinner() })
  }

  add() {
    this.editData = {};
    this.isEdit = false;
    this.myForm.reset();
  }
  edit(data) {
    this.editData = {}
    this.isEdit = true;
    this.myForm.reset();
    this.editData.TaskId = data.TASKID;
    this.CommonService.postCall('editTaskmanager', this.editData).subscribe((res: any) => {
      this.editData = res;
      let ctrls: any = this.myForm.controls;
      Object.keys(ctrls).map((key: string) => {
        let control: FormControl = ctrls[key];
        control.setValue(res[key])
      })
    }, err => { })
  }
  onSubmit(form: FormGroup) {
    let payload = form.value;
    payload["LASTMDFBY"] = localStorage.getItem('UserId'),
      payload["CREATEDBY"] = this.editData.CREATEDBY || localStorage.getItem('UserId');
    payload.CREATEDDATE = this.editData.CREATEDDATE || moment(new Date())
    payload.LASTMDFDATE = moment(new Date())
    payload.TENANT_CODE = localStorage.getItem('TenantCode');
    payload.ParentTaskId = payload.ParentTaskId ?? 0
    if (this.isEdit) {
      payload.TaskId = this.editData.TaskId;
      this.CommonService.postCall('UpdateTaskManager', payload).subscribe((res: any) => {
        this.loadTaskManager();
        this.toastr.success('Task Updated Successfully')
        document.getElementById('md_close').click()
      }, err => {
        this.toastr.error(err.error ? err.error : 'Task  not Updated')
      })
    } else {
      this.CommonService.postCall('CreateTaskManager', payload).subscribe((res: any) => {
        this.loadTaskManager();
        this.toastr.success('Task created Successfully')
        document.getElementById('md_close').click()
      }, err => {
        this.toastr.error(err.error ? err.error.text || err.error : 'Task  not created ')
      })

    }

  }
  close() { }
}
