import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-parent-idcard',
  templateUrl: './parent-idcard.component.html',
  styleUrls: ['./parent-idcard.component.css']
})
export class ParentIdcardComponent implements OnInit {
  myForm: FormGroup;
  campus: Array<any> = [];
  Courses: Array<any> = [];
  courseschedule: Array<any> = [];
  checkboxs: Array<any> = [];
  selectedList: Array<any> = [];
  idcardData: Array<any> = [];
  labelName: any = {};
  checked: boolean;
  isStudent: boolean = false;
  isStudentAll: boolean = false;
  selectAllStudents: Array<any> = [];
  ishide: boolean = false;
  isDisable: boolean = false;
  headingName: any = {};
  workItemId: any;
  constructor(private CommonService: CommonService,private active : ActivatedRoute, private fb: FormBuilder, private toastr: ToastrService, private router: Router) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadlabels();
    this.campusDropdown();
    this.loadcourses();
    this.loadHeadings();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      campus_id: ['', Validators.required],
      course_id: ['', Validators.required],
      courseschedule_id: ['', Validators.required],
      student: ['', Validators.required],
      principal: ['', Validators.required]
    })
  }

  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  campusDropdown() {
    let payload = {
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('EmsCampus/CampusDropDownList', payload).subscribe((res: any) => {
      this.campus = res;
    })
  }

  loadcourses() {
    // LoadCourseSchedule
    // Loadyear Loadcourses
    this.activeSpinner()
    let payaload = {
      "TENANT_CODE": localStorage.getItem('TenantCode')
    }
    this.CommonService.getAdminCourses().subscribe((res: any) => {
      this.Courses = res;
      this.deactivateSpinner();
    }, e => this.deactivateSpinner());
  }


  courseSchedule(id) {
    let payLoad = {
      "CourseId": id,
      'RoleId': localStorage.getItem('RoleId')
    }
    this.CommonService.postCall('CourseSchedule/GetAdminCourseSchedule', payLoad).subscribe((res: any) => {
      this.courseschedule = res;
    })
  }

  sheduleChange(event) {
    this.checkboxs = [];
    this.activeSpinner();
    let payLoad = {
      "COURSESHD_ID": event.target.value
    }
    this.myForm.controls['student'].setValue('');
    this.CommonService.postCall('Account/usersbycourseShedule', payLoad).subscribe((res: any) => {
      let arr: [] = res;
      if (arr.length == 0) {
        this.toastr.warning('There is no In-Charge for seleted Designation');
        this.deactivateSpinner();
      } else {
        this.ishide = true;
        this.checkboxs = res;
        this.deactivateSpinner();
      }
    }, e => {
      this.deactivateSpinner();
    });
  }

  changeCheck(event: any) {
    this.isDisable = false;
    this.isStudent = true;
    let peopleId = event.target.value;
    let obj = {
      people_id: peopleId
    }
    if (event.target.checked) {
      this.selectedList.push(obj);
    } else {
      this.selectedList = this.selectedList.filter(m => m.people_id != peopleId);
      if (this.selectedList.length == 0) {
        this.isDisable = true;
      }
    }
  }


  checkAll(event: any) {
    this.isDisable = false;
    this.isStudentAll = true;
    if (event.target.checked == true) {
      this.checkboxs.forEach(a => a.checked = true);
      this.checkboxs.filter(m => { if (m.checked) this.selectAllStudents.push({ people_id: m.USERID }) });
      console.log(this.selectAllStudents);
    } else {
      this.isDisable = true;
      this.checkboxs.forEach(a => a.checked = false);
      this.selectAllStudents = [];
    }
  }

  onSubmit1(form: FormGroup) {
    let value: any = form.value;
    let listOfMembers = [];
    if (this.isStudent) {
      listOfMembers = this.selectedList;
    } else if (this.isStudentAll) {
      listOfMembers = this.selectAllStudents;
    }
    // let selectedItem=[];
    // this.checkboxs.forEach(m=>{
  //   if(m.status)
    //   selectedItem.push({people_id: m.people_id})
    // });

    let payload = {
      "tnt_code": localStorage.getItem('TenantCode'),
      "modified_by": localStorage.getItem('UserId'),
      "created_by": localStorage.getItem('UserId'),
      "ListOfMembers": listOfMembers
    }
    sessionStorage.idcardData = JSON.stringify(payload);
    this.router.navigate([`home/printParentIdCard/${this.workItemId}`]);

  }

  onSubmit2(form: FormGroup) {
    let value: any = form.value;
    let listOfMembers = [];
    if (this.isStudent) {
      listOfMembers = this.selectedList;
    } else if (this.isStudentAll) {
      listOfMembers = this.selectAllStudents;
    }
    let payload = {
      "tnt_code": localStorage.getItem('TenantCode'),
      "modified_by": localStorage.getItem('UserId'),
      "created_by": localStorage.getItem('UserId'),
      "ListOfMembers": listOfMembers
    }
    sessionStorage.idcardData = JSON.stringify(payload);
    this.router.navigate([`home/parentIdcard2/${this.workItemId}`]);
 
  }
  onSubmit3(form: FormGroup) {
    let value: any = form.value;
    let listOfMembers = [];
    if (this.isStudent) {
      listOfMembers = this.selectedList;
    } else if (this.isStudentAll) {
      listOfMembers = this.selectAllStudents;
    }
    // let selectedItem=[];
    // this.checkboxs.forEach(m=>{
    //   if(m.status)
    //   selectedItem.push({people_id: m.people_id})
    // });

    let payload = {
      "tnt_code": localStorage.getItem('TenantCode'),
      "modified_by": localStorage.getItem('UserId'),
      "created_by": localStorage.getItem('UserId'),
      "ListOfMembers": listOfMembers
    }
    sessionStorage.idcardData = JSON.stringify(payload);
    this.router.navigate([`home/parentIdcard3/${this.workItemId}`]);

  }

  close() {

  }
}
