<!-- <nav class="sidebar slide" id="sidebar">
  <ul class="nav" style="margin-top: 5rem;">

    <li class="nav-item">
      <a class="nav-link" [routerLink]="['dashboard']">
        <span class="menu-title">Dashboard</span>
        <i class="mdi mdi-view-dashboard menu-icon"></i>
      </a>
    </li>

   

    <li class="nav-item" *ngFor=' let item of menus'>
      <a *ngIf="item.childs.length" class="nav-link" [attr.href]="'#'+getId(item.Name)" data-toggle="collapse"
        aria-expanded="false" aria-controls="ui-basic">
        <span class="menu-title">{{item.Name}}</span>
        <i class="menu-arrow"></i>
        <i [className]='item.CssClass' class="mdi mdi-clipboard-check menu-icon"></i>

      </a>
      <div *ngIf="item.childs.length" class="collapse" [attr.id]="getId(item.Name)">
        <ul class="nav flex-column sub-menu">
          <li class="nav-item dropdown" *ngFor="let subMenu of item.childs"> <a class="nav-link"
              (click)="navigate(subMenu.path,subMenu)">{{subMenu.Name}} </a>
              <ul *ngIf='subMenu.childs.length' class="dropdown-menu p-3 bg-transparent border-0" style="top:0px;">
                <li class="nav-item"  (click)="navigate(menu.path,menu)"  *ngFor="let menu of subMenu.childs"> <a class="nav-link" >{{menu.Name}} </a></li>
              </ul>
          </li>
        </ul>
      </div>

    </li>


    
    <li class="nav-item" *ngIf='false'>
      <a class="nav-link" data-toggle="collapse" href="#ui-basic6" aria-expanded="false" aria-controls="ui-basic">
        <span class="menu-title">Assessments</span>
        <i class="menu-arrow"></i>
        <i class="mdi mdi-clipboard-check menu-icon"></i>
      </a>
      <div class="collapse">
        <ul class="nav flex-column sub-menu">
          <li class="dropdown nav-item">
            <div class="">
              <a href="#" class="dropdown-toggle nav-link" aria-haspopup="true" aria-expanded="false">Dropdown <span
                  class="caret"></span></a>

              <ul class="dropdown-menu p-3 bg-transparent border-0" style="top:0px;">
                <li class="nav-item"> <a class="nav-link" [routerLink]="['createFAQs']">Create FAQs</a></li>
                <li class="nav-item"> <a class="nav-link" [routerLink]="['news']">Create News</a></li>
                <li class="nav-item"> <a class="nav-link" [routerLink]="['createPolls']">Create Poll</a></li>
                <li class="nav-item"> <a class="nav-link" [routerLink]="['createSurvey']">Create Survey</a></li>
                <li class="nav-item"> <a class="nav-link" [routerLink]="['feedbackQuestionnaire']">Feedback
                    Questionnaire</a>
                </li>
              </ul>

            </div>
          </li>
        </ul>
      </div>
    </li>

    <div *ngIf='false'>
      <li *ngIf="roleId==2" class="nav-item">
        <a class="nav-link" data-toggle="collapse" href="#ui-basic3" aria-expanded="false" aria-controls="ui-basic">
          <span class="menu-title">Learning Resources
          </span>
          <i class="menu-arrow"></i>
          <i class="mdi mdi-library-books menu-icon"></i>
        </a>
        <div class="collapse" id="ui-basic3">
          <ul class="nav flex-column sub-menu">
            <li class="nav-item"> <a class="nav-link" [routerLink]="['timeTracker']">Time Tracker </a></li>
          </ul>
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-toggle="collapse" href="#ui-basic2" aria-expanded="false" aria-controls="ui-basic">
          <span class="menu-title">Assignments</span>
          <i class="menu-arrow"></i>
          <i class="mdi mdi-clipboard-check menu-icon"></i>
        </a>
        <div class="collapse" id="ui-basic2">
          <ul class="nav flex-column sub-menu">
            <li *ngIf="roleId!=3" class="nav-item"> <a class="nav-link" [routerLink]="['assignments']">Assignments </a>
            </li>
            <li *ngIf="roleId!=3" class="nav-item"> <a class="nav-link" [routerLink]="['evaluateAssignments']">Evaluate
                Assignments </a></li>
            <li *ngIf="roleId==3" class="nav-item"> <a class="nav-link" [routerLink]="['submitassignment']">Submit
                Assignments </a></li>
          </ul>
        </div>
      </li>
      <li *ngIf="roleId==1" class="nav-item">
        <a class="nav-link" data-toggle="collapse" href="#ui-basic1" aria-expanded="false" aria-controls="ui-basic">
          <span class="menu-title">MasterData</span>
          <i class="menu-arrow"></i>
          <i class="mdi mdi-book-multiple menu-icon"></i>
        </a>
        <div class="collapse" id="ui-basic1">
          <ul class="nav flex-column sub-menu">
            <li class="nav-item"> <a class="nav-link" [routerLink]="['attainmentlevel']">Attainment level</a></li>
            <li class="nav-item"> <a class="nav-link" [routerLink]="['createFAQs']">Create FAQs</a></li>
            <li class="nav-item"> <a class="nav-link" [routerLink]="['news']">Create News</a></li>
            <li class="nav-item"> <a class="nav-link" [routerLink]="['createPolls']">Create Poll</a></li>
            <li class="nav-item"> <a class="nav-link" [routerLink]="['createSurvey']">Create Survey</a></li>
            <li class="nav-item"> <a class="nav-link" [routerLink]="['feedbackQuestionnaire']">Feedback
                Questionnaire</a>
            </li>
            <li class="nav-item"> <a class="nav-link" [routerLink]="['feedbackResults']">Feedback Results</a></li>
            <li class="nav-item"> <a class="nav-link" [routerLink]="['smtp']">SMTP Configuration</a></li>
            <li class="nav-item"> <a class="nav-link" [routerLink]="['taskmanager']">Task Manager</a></li>
            <li class="nav-item"> <a class="nav-link" [routerLink]="['role']">Role</a></li>
            <li class="nav-item"> <a class="nav-link" [routerLink]="['taskInRole']">Task In Roles</a></li>

          </ul>
        </div>
      </li>

      <li *ngIf="roleId==3" class="nav-item">
        <a class="nav-link" data-toggle="collapse" href="#ui-basic1" aria-expanded="false" aria-controls="ui-basic">
          <span class="menu-title">Learning Materials</span>
          <i class="menu-arrow"></i>
          <i class="mdi mdi-book-multiple menu-icon"></i>
        </a>
        <div class="collapse" id="ui-basic1">
          <ul class="nav flex-column sub-menu">

            <li class="nav-item"> <a class="nav-link" [routerLink]="['learningmaterial']">Learning Materials </a></li>

          </ul>
        </div>
      </li>


      <li *ngIf="roleId==2" class="nav-item">
        <a class="nav-link" data-toggle="collapse" href="#ui-basic3" aria-expanded="false" aria-controls="ui-basic">
          <span class="menu-title">Learning Resources
          </span>
          <i class="menu-arrow"></i>
          <i class="mdi mdi-library-books menu-icon"></i>
        </a>
        <div class="collapse" id="ui-basic3">
          <ul class="nav flex-column sub-menu">
            <li class="nav-item"> <a class="nav-link" [routerLink]="['timeTracker']">Time Tracker </a></li>
          </ul>
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-toggle="collapse" href="#ui-basic6" aria-expanded="false" aria-controls="ui-basic">
          <span class="menu-title">Assessments</span>
          <i class="menu-arrow"></i>
          <i class="mdi mdi-clipboard-check menu-icon"></i>
        </a>
        <div class="collapse" id="ui-basic6">
          <ul class="nav flex-column sub-menu">
            <li *ngIf="roleId!=3" class="nav-item"> <a class="nav-link" [routerLink]="['evaluateAssessment']">Evaluate
                Assessments </a></li>
            <li *ngIf="roleId!=3" class="nav-item"> <a class="nav-link" [routerLink]="['examResult']">Exam Result </a>
            </li>
            <li *ngIf="roleId==3" class="nav-item"> <a class="nav-link" [routerLink]="['postassessment']">Post
                Assessments
              </a></li>
            <li *ngIf="roleId==3" class="nav-item"> <a class="nav-link" [routerLink]="['myresults']">My Results </a>
            </li>
            <li *ngIf="roleId==1" class="nav-item"> <a class="nav-link"
                [routerLink]="['assessmentQuestionnaire']">Assessment Questionnaire </a></li>
            <li *ngIf="roleId==1" class="nav-item"> <a class="nav-link" [routerLink]="['scheduleAssessment']">Schedule
                Assessment </a></li>
            <li *ngIf="roleId==1" class="nav-item"> <a class="nav-link" [routerLink]="['masterAssessment']">Master
                Assessment </a></li>
            <li *ngIf="roleId==1" class="nav-item"> <a class="nav-link" [routerLink]="['attendance']">Attendance </a>
            </li>



          </ul>
        </div>
      </li>
      <li class="nav-item" *ngIf="roleId!=2" style="position: relative;">
        <a class="nav-link" data-toggle="collapse" href="#ui-basic" aria-expanded="false" aria-controls="ui-basic">
          <span class="menu-title">Communication</span>
          <i class="menu-arrow"></i>
          <i class="mdi mdi-account-switch menu-icon"></i>
        </a>
        <div class="collapse" id="ui-basic">
          <ul class="nav flex-column sub-menu">
            <li class="nav-item"> <a class="nav-link" [routerLink]="['surveys']">Take Surveys</a></li>
            <li class="nav-item"> <a class="nav-link" [routerLink]="['poll']">Take Poll </a></li>
            <li class="nav-item"> <a class="nav-link" [routerLink]="['joinconference']">Join Conference </a></li>
            <li class="nav-item"> <a class="nav-link" [routerLink]="['mailBox']">Mail Box </a></li>
            <li class="nav-item"> <a class="nav-link" [routerLink]="['forums']">Forums </a></li>
            <li class="nav-item"> <a class="nav-link" [routerLink]="['forumTopics']">Forums Topics </a></li>
            <li class="nav-item"> <a class="nav-link" [routerLink]="['myPosts']">My posts </a></li>
            <li class="nav-item"> <a class="nav-link" [routerLink]="['allBlogs']">All Blogs </a></li>
            <li class="nav-item"> <a class="nav-link" [routerLink]="['blogs']">Blogs </a></li>




          </ul>
        </div>
      </li>



      <li *ngIf="roleId ==1" class="nav-item" style="position: relative;">

        <div>
          <a class="nav-link" data-toggle="collapse" href="#ui-basic12" aria-expanded="false" aria-controls="ui-basic">

            <span class="menu-title">Admin</span>
            <i class="menu-arrow"></i>
            <i class="mdi mdi-clipboard-check menu-icon"></i>

          </a>
          <div style="position: relative;">
            <div class="collapse" id="ui-basic12">
              <ul class="nav flex-column sub-menu">

                <li class="nav-item"> <a class="nav-link" [routerLink]="['courceSessions']">Course Sessions </a></li>
                <li class="nav-item"> <a class="nav-link" [routerLink]="['courseCategory']">Course Category</a></li>
                <li class="nav-item"> <a class="nav-link" [routerLink]="['courseType']">Course Type</a></li>
                <li class="nav-item"> <a class="nav-link" [routerLink]="['cources']">Courses</a></li>
                <li class="nav-item"> <a class="nav-link" [routerLink]="['courceSchedule']">Course Schedule</a></li>
                <li class="nav-item"> <a class="nav-link" [routerLink]="['chapters']">Chapters</a></li>
                <li class="nav-item"> <a class="nav-link" [routerLink]="['enroll']">Enroll Students</a></li>
                <li class="nav-item"> <a class="nav-link" [routerLink]="['regulations']">Regulations </a></li>
                <li class="nav-item"> <a class="nav-link" [routerLink]="['rooms']">Rooms </a></li>
                <li class="nav-item"> <a class="nav-link" [routerLink]="['configurematerials']">Configure Materials </a>
                </li>
                <li class="nav-item"> <a class="nav-link" [routerLink]="['contentmanager']">Content Manager </a></li>



              </ul>
            </div>
          </div>
        </div>
      </li>

    </div>


  </ul>
</nav> -->


<!-- <div class="wrapper" style="cursor: pointer;">

  
  <div class="sidebar-wrapper" data-simplebar="true">
    <div class="sidebar-header">
      <div>
        <img src="../../assets/img/LMS_Logo 1.png" class="logo-icon" alt="logo icon">
      </div>
    </div>
    

    <ul class="metismenu" id="menu">
      <li class="mm-active">
        <a [routerLink]="['dashboard']">
          <div class="parent-icon">
            <svg width="17" height="17" fill="#A3AED0" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0.944444 9.44444H6.61111C7.13056 9.44444 7.55556 9.01944 7.55556 8.5V0.944444C7.55556 0.425 7.13056 0 6.61111 0H0.944444C0.425 0 0 0.425 0 0.944444V8.5C0 9.01944 0.425 9.44444 0.944444 9.44444ZM0.944444 17H6.61111C7.13056 17 7.55556 16.575 7.55556 16.0556V12.2778C7.55556 11.7583 7.13056 11.3333 6.61111 11.3333H0.944444C0.425 11.3333 0 11.7583 0 12.2778V16.0556C0 16.575 0.425 17 0.944444 17ZM10.3889 17H16.0556C16.575 17 17 16.575 17 16.0556V8.5C17 7.98056 16.575 7.55556 16.0556 7.55556H10.3889C9.86944 7.55556 9.44444 7.98056 9.44444 8.5V16.0556C9.44444 16.575 9.86944 17 10.3889 17ZM9.44444 0.944444V4.72222C9.44444 5.24167 9.86944 5.66667 10.3889 5.66667H16.0556C16.575 5.66667 17 5.24167 17 4.72222V0.944444C17 0.425 16.575 0 16.0556 0H10.3889C9.86944 0 9.44444 0.425 9.44444 0.944444Z" />
            </svg>

          </div>
          <div class="menu-title">Dashboard</div>
        </a>
      </li>
      
        <li metis-menu class="mt-0 " *ngFor='let item of menus'>
          <a href="courses-module.html" *ngIf="item.childs.length" [attr.href]="'#'+getId(item.Name)"
            class="has-arrow">
            <div class="menu-title">{{item.Name}}</div>
            
            
            </a>

          <ul  class="parent-icon" *ngIf="item.childs.length" [attr.id]="getId(item.Name)">
            <li *ngFor="let subMenu of item.childs"><a class="has-arrow"
                (click)="navigate(subMenu.path,subMenu)"><i class="bx bx-right-arrow-alt"></i>{{subMenu.Name}} </a>
              <ul *ngIf='subMenu.childs.length' style="top:0px;">
                <li class="nav-item" (click)="navigate(menu.path,menu)" *ngFor="let menu of subMenu.childs">
                  <a><i class="bx bx-right-arrow-alt"></i>{{menu.Name}} </a>
                </li>
              </ul>
            </li>
          </ul>

        </li>
      





    </ul>

  </div>
</div> -->

<!-- <img [src]="TNT_LOGO" class="logo-icon" alt="logo icon"> -->

<div class="wrapper" style="height: auto; overflow: hidden;cursor: pointer;">


  <div class="sidebar-wrapper" data-simplebar="true">



    <div class="sidebar-menu-wrapper">
      <div class="sidebar-header">    
        <!-- <img [src]="TNT_LOGO" class="logo-icon" alt="logo icon">     -->
        <div *ngIf="!isLogo" class="logo">
          <img [src]="TNT_LOGO" class="logo-icon" alt="logo icon">
       
        </div>
        <div *ngIf="isLogo">
          <img src="../../assets/img/LMS_Logo 1.png" class="logo-icon" alt="logo icon">
        </div>
        <!-- <div>
            <h4 class="logo-text">LMS</h4>
          </div> -->
        <!-- <div class="toggle-icon ms-auto"><i class='bx bx-arrow-to-left'></i>
          </div> -->
      </div>
      <div class="wrapper" style="margin-top: 9.6vh">
        <ul class="style-1 shadow nav-active1" style="display:block;">
          <li class="mm-active pt-2" >
            <a [routerLink]="['dashboard']">
              <div class="parent-icon">
                <svg width="12" height="17" fill="#000000" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.944444 9.44444H6.61111C7.13056 9.44444 7.55556 9.01944 7.55556 8.5V0.944444C7.55556 0.425 7.13056 0 6.61111 0H0.944444C0.425 0 0 0.425 0 0.944444V8.5C0 9.01944 0.425 9.44444 0.944444 9.44444ZM0.944444 17H6.61111C7.13056 17 7.55556 16.575 7.55556 16.0556V12.2778C7.55556 11.7583 7.13056 11.3333 6.61111 11.3333H0.944444C0.425 11.3333 0 11.7583 0 12.2778V16.0556C0 16.575 0.425 17 0.944444 17ZM10.3889 17H16.0556C16.575 17 17 16.575 17 16.0556V8.5C17 7.98056 16.575 7.55556 16.0556 7.55556H10.3889C9.86944 7.55556 9.44444 7.98056 9.44444 8.5V16.0556C9.44444 16.575 9.86944 17 10.3889 17ZM9.44444 0.944444V4.72222C9.44444 5.24167 9.86944 5.66667 10.3889 5.66667H16.0556C16.575 5.66667 17 5.24167 17 4.72222V0.944444C17 0.425 16.575 0 16.0556 0H10.3889C9.86944 0 9.44444 0.425 9.44444 0.944444Z" />
                </svg> 
                <span class="menu-title ms-3">Dashboard</span>
              </div>
            </a>
          </li>
          <li class="parent" *ngFor='let item of menus'><a [ngClass]="item.childs.length > 0 ? 'has-arrow' : ''"><span
                class="icon" *ngIf="item.childs.length" [attr.href]="'#'+getId(item.Name)"><i
                  class={{item.CssClass}}></i></span>&nbsp;&nbsp;<span class="text">{{item.Name}}</span></a>
            <div class="wrapper">
              <ul class="style-1 shadow  nav-active2" *ngIf="item.childs.length" [attr.id]="getId(item.Name)">
                <li class="parent" *ngFor="let subMenu of item.childs"><a
                    [ngClass]="subMenu.childs.length > 0 ? 'has-arrow' : ''"
                    (click)="navigate(subMenu.path,subMenu)">{{subMenu.Name}}</a>
                  <div class="wrapper">
                    <ul class="style-1 shadow  nav-active3" *ngIf='subMenu.childs.length'>
                      <li class="parent" *ngFor="let menu of subMenu.childs"><a
                          [ngClass]="menu.childs.length > 0 ? 'has-arrow' : ''"
                          (click)="navigate(menu.path,menu)">{{menu.Name}}</a>
                        <div class="wrapper">
                          <ul class="style-1 shadow  nav-active4" *ngIf='menu.childs.length'>
                            <li *ngFor="let fourthMenu of menu.childs"><a
                                [ngClass]="fourthMenu.childs.length > 0 ? 'has-arrow' : ''"
                                (click)="navigate(fourthMenu.path,fourthMenu)">{{fourthMenu.Name}}</a>
                              <div class="wrapper">
                                <ul class="style-1 shadow  nav-active4" *ngIf='fourthMenu.childs.length'>
                                  <li *ngFor="let fifthMenu of fourthMenu.childs"><a
                                      [ngClass]="fifthMenu.childs.length > 0 ? 'has-arrow' : ''"
                                      (click)="navigate(fifthMenu.path,fifthMenu)">{{fifthMenu.Name}}</a></li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>

                      <!-- <li class="parent"><a href="" class="has-arrow">Generate ID Card</a>
                        <div class="wrapper">
                          <ul class="style-1 shadow  nav-active4">
                            <li><a href="">Student</a></li>
                            <li><a href="">Parent</a></li>
                            <li><a href="">Employee</a></li>
                            <li><a href="">Templates List</a></li>
                            <li><a href="">Staff ID card History List</a></li>
                            <li><a href="">Student IDCard History List</a></li>
                          </ul>
                        </div>
                      </li> -->

                    </ul>
                  </div>
                </li>

              </ul>
            </div>
          </li>


        </ul>
      </div>
    </div>
    <!--navigation-->





    <!--end navigation-->
  </div>

</div>