<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 *ngIf="!isEdit" class="mb-0"> {{headingName.AddEventRegistration}}</h5>
                                        <h5 *ngIf="isEdit" class="mb-0"> {{headingName.EditEventRegistration}}</h5>

                                    </div>
                                    <div class="card-body">
                                        <div class="custom_container">
                                            <form class="well form-horizontal" [formGroup]="myForm"
                                                (ngSubmit)="onSubmit(myForm)">
                                                <fieldset>
                                                    <div class="form-group row">
                                                        <label class="col-md-4 control-label line_2 rem_1">
                                                            {{labelName.EventName}}
                                                            <span class="text-danger">*</span></label>
                                                        <div class="col-md-8 inputGroupContainer">
                                                            <div class="input-group">
                                                                <select
                                                                    class="form-select form-select-sm shadow-sm rounded-lg"
                                                                    formControlName="event_id"
                                                                    (change)="getFees($event.target.value)">
                                                                    <option value="" [selected]="true">select</option>
                                                                    <option *ngFor="let item of events"
                                                                        [value]='item.event_id'>
                                                                        {{item.event_name}}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-group row pt-4">
                                                        <label class="col-md-4 control-label line_2 rem_1">
                                                            {{labelName.CandidateType}}
                                                            <span class="text-danger">*</span></label>
                                                        <div class="col-md-8 inputGroupContainer">
                                                            <div class="input-group">
                                                                <input type="radio" name="type" id="internal"
                                                                    value="internal" (change)="RadioButtons($event)"
                                                                    formControlName="type">
                                                                &nbsp;<label class="form-check-label"
                                                                    for="internal">{{labelName.Internal}}</label>

                                                                &nbsp;&nbsp;
                                                                <input type="radio" name="type" id="external"
                                                                    value="external" (change)="RadioButtons($event)"
                                                                    formControlName="type">&nbsp;<label
                                                                    class="form-check-label"
                                                                    for="external">{{labelName.External}}</label>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="form-group row pt-4" *ngIf="externalFields">
                                                        <label class="col-md-4 control-label line_2 rem_1">
                                                            {{labelName.Name}}
                                                            <span class="text-danger">*</span></label>
                                                        <div class="col-md-8 inputGroupContainer">
                                                            <div class="input-group">
                                                                <input type="text" class="form-control"
                                                                    formControlName="name">
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="form-group row pt-4" *ngIf="externalFields">
                                                        <label class="col-md-4 control-label line_2 rem_1">
                                                            {{labelName.FatherName}}
                                                            <span class="text-danger">*</span></label>
                                                        <div class="col-md-8 inputGroupContainer">
                                                            <div class="input-group">
                                                                <input type="text" class="form-control"
                                                                    formControlName="parent_name">
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="form-group row pt-4" *ngIf="externalFields">
                                                        <label class="col-md-4 control-label line_2 rem_1">
                                                            {{labelName.Address}}
                                                            <span class="text-danger">*</span></label>
                                                        <div class="col-md-8 inputGroupContainer">
                                                            <div class="input-group">
                                                                <textarea name="" id="" cols="30" rows="3"
                                                                    formControlName="address"
                                                                    class="form-control"></textarea>
                                                                <!-- <input type="text" class="form-control"> -->
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-group row pt-4" *ngIf="internalFields">
                                                        <label class="col-md-4 control-label line_2 rem_1">
                                                            {{labelName.USID}}
                                                            <span class="text-danger">*</span></label>
                                                        <div class="col-md-8 inputGroupContainer">
                                                            <div class="input-group">
                                                                <input type="text" class="form-control"
                                                                    formControlName="usid" maxlength="10">
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-group row pt-4">
                                                        <label class="col-md-4 control-label line_2 rem_1">
                                                            {{labelName.Mobile}}
                                                            <span class="text-danger">*</span></label>
                                                        <div class="col-md-8 inputGroupContainer">
                                                            <div class="input-group">
                                                                <input class="form-control" formControlName="mobile"
                                                                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                                    type="number" maxlength="10">
                                                            </div>
                                                            <div *ngIf="myForm.get('mobile').touched">
                                                                <span class="text-danger"
                                                                    *ngIf="myForm.get('mobile').hasError('required')">
                                                                    This field is required.
                                                                </span>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-group row pt-4">
                                                        <label class="col-md-4 control-label line_2 rem_1">
                                                            {{labelName.Email}}
                                                            <span class="text-danger">*</span></label>
                                                        <div class="col-md-8 inputGroupContainer">
                                                            <div class="input-group">
                                                                <input type="email" email="true" class="form-control"
                                                                    formControlName="email">
                                                            </div>
                                                            <div *ngIf="myForm.get('email').touched">
                                                                <span class="text-danger"
                                                                    *ngIf="myForm.get('email').hasError('email')">
                                                                    Please Enter The Valid Email
                                                                </span>

                                                            </div>
                                                            <div *ngIf="myForm.get('email').touched">
                                                                <span class="text-danger"
                                                                    *ngIf="myForm.get('email').hasError('required')">
                                                                    This field is required.
                                                                </span>

                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="form-group row pt-4">
                                                        <label class="col-md-4 control-label line_2 rem_1">
                                                            {{labelName.RegistrationFee}}
                                                        </label>
                                                        <div class="col-md-8 inputGroupContainer">
                                                            <div class="input-group">
                                                                <input type="text" class="form-control" disabled
                                                                    [(ngModel)]="regFee"
                                                                    [ngModelOptions]="{standalone: true}" />
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="form-group row pt-4 pb-4" *ngIf="externalFields">
                                                        <label
                                                            class="col-md-4 control-label line_2 rem_1">{{labelName.HowdoyouknowaboutthisEvent}}
                                                            <span class="text-danger">*</span> </label>
                                                        <div class="col-md-8 inputGroupContainer">
                                                            <div class="input-group">
                                                                <input type="radio" name="reference" id="news"
                                                                    formControlName="reference" value="news">&nbsp;
                                                                <label class="form-check-label"
                                                                    for="internal">{{labelName.NewsPaper}}</label>&nbsp;&nbsp;
                                                                <input type="radio" name="reference" id="Internet"
                                                                    formControlName="reference" value="Internet"> &nbsp;
                                                                <label class="form-check-label"
                                                                    for="internal">{{labelName.Internet}}</label>&nbsp;&nbsp;
                                                                <input type="radio" name="reference" id="Friends"
                                                                    formControlName="reference" value="Friends">&nbsp;
                                                                <label class="form-check-label"
                                                                    for="internal">{{labelName.Friends}}</label>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </form>
                                        </div>
                                        <br>
                                        <div class="modal-footer pt-4">
                                            <button *ngIf="!isEdit" class="btn btn-save btn-sm" type="button"
                                                (click)="onSubmit(myForm)" [disabled]="myForm.invalid">Save</button>
                                            <button *ngIf="isEdit" class="btn btn-save btn-sm" type="button"
                                                (click)="onSubmit(myForm)" [disabled]="myForm.invalid">Update</button>
                                            <button class="btn btn-danger btn-sm" type="button"
                                                (click)="close()">close</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>