<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.LeaveAllotments}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div *ngIf='isSuperAdmin' class="col-md-3">
                                                <label for="sel1">{{labelName.TenantName}} : </label>
                                                <select [(ngModel)]='tId ' (change)="changeTname()"
                                                    class="form-control form-control-sm shadow-sm rounded-lg" id="sel1">
                                                    <option value="" [selected]="true">select</option>
                                                    <option *ngFor="let item of tenanates" [value]='item.TNT_CODE'>
                                                        {{item.TNT_NAME}}</option>
                                                </select>

                                            </div>
                                            <div *ngIf="table.length">
                                                <table id="patergraph" datatable
                                                    class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                    style="width:100%">
                                                    <thead class="top"
                                                        style="background-color: #624FD7; color:#ffffff;">
                                                        <tr>
                                                            <th>S.No </th>
                                                            <th>Designation</th>
                                                            <th>Leave Type</th>
                                                            <th>No.Leaves</th>
                                                            <th>Year</th>
                                                            <th>Status</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let item of table;let i = index;">
                                                            <td>{{i+1}}</td>
                                                            <td>{{item.role_name}}</td>
                                                            <td>{{item.leavetypes_leavename}}</td>
                                                            <td>{{item.total_leaves}}</td>
                                                            <td>{{item.from_date | date : 'yyyy'}}</td>
                                                            <td>{{item.leaves_status}}</td>
                                                            <td style="display: flex; cursor: pointer;">
                                                                <button *ngIf="item.leaves_status == 'Pending'"
                                                                    class="btn btn-warning"
                                                                    (click)="changeStatus(item.leaves_id,'Approve')">Approve</button>
                                                                <button *ngIf="item.leaves_status == 'Rejected'"
                                                                    class="btn btn-warning"
                                                                    (click)="changeStatus(item.leaves_id,'Approve')">Approve</button>
                                                                <button *ngIf="item.leaves_status == 'Approved'"
                                                                    class="btn btn-warning"
                                                                    (click)="changeStatus(item.leaves_id,'Reject')">Reject</button>
                                                            </td>
                                                        </tr>
                                                    </tbody>

                                                </table>
                                            </div>
                                            <div class="text-center" *ngIf="!table.length">
                                                No Records to display
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>