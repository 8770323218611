<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.AssignMarks}} </h5>
                                    </div>
                                    <div class="card-body">
                                        <!-- <form class="well form-horizontal" [formGroup]="myForm"
                                            (ngSubmit)="onSubmit(myForm)">
                                            <fieldset> -->
                                        <div class="row">
                                            <div class="form-group col-md-3">
                                                <label>{{labelName.Course}} <strong>:</strong></label>


                                                <div class="input-group">
                                                    <select [(ngModel)]='courceId '
                                                        (change)="courceChange($any($event).target.value)"
                                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                                        <option value="" selected>Select
                                                        </option>
                                                        <option *ngFor="let course of cources"
                                                            [value]='course.COURSE_ID'>
                                                            {{course.COURSE_NAME}}
                                                        </option>
                                                    </select>
                                                </div>

                                            </div>

                                            <div class="form-group col-md-3">
                                                <label>{{labelName.Section}} <strong>:</strong></label>


                                                <div class="input-group">
                                                    <select (change)="examDropdown($any($event.target).value)"
                                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                                        <option value="" selected>Select</option>
                                                        <option *ngFor="let schedul of scheduls"
                                                            [value]='schedul.COURSESHD_ID'>
                                                            {{schedul.COURSESHD_NAME}}</option>
                                                    </select>
                                                </div>

                                            </div>
                                            <div class="form-group col-md-3">
                                                <label>{{labelName.ExamName}} <strong>:</strong></label>


                                                <div class="input-group">
                                                    <select [(ngModel)]='exam'
                                                        (change)="specificDropdown($any($event).target.value)"
                                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                                        <option value="" selected>Select</option>
                                                        <option *ngFor="let name of examMarks" [value]='name.id'>
                                                            {{name.name}}</option>
                                                    </select>
                                                </div>

                                            </div>
                                            <div class="form-group col-md-3">
                                                <label>{{labelName.SpecificExamName}} <strong>:</strong></label>


                                                <div class="input-group">
                                                    <select (change)="studentDropdown($any($event).target.value)"
                                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                                        <option value="" selected>Select </option>
                                                        <option *ngFor="let item of specificExam"
                                                            [value]='item.specific_exam_name'>
                                                            {{item.specific_exam_name}}</option>
                                                    </select>
                                                </div>

                                            </div>

                                            <div class="form-group col-md-3">
                                                <label> {{labelName.StudentName}} <strong>:</strong></label>
                                                <div class="input-group">
                                                    <select (change)="studentChange($any($event).target.value)"
                                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                                        <option value="" selected>Select </option>
                                                        <option *ngFor="let item of student"
                                                            [value]='item.STUDENT_PAYMENT_STUDENT_ID'>
                                                            {{item.USER_FIRSTNAME}}</option>
                                                    </select>
                                                </div>

                                            </div>

                                            <div class="form-group col-md-3">
                                                <label> {{labelName.Attendance}} <strong>:</strong></label>
                                                <div class="input-group">
                                                    <select (change)="attendanceChange($any($event).target.value)" 
                                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                                        <option value="" selected>Select </option>
                                                        <option value="Present">Present</option>
                                                        <option value="Absent">Absent</option>
                                                    </select>
                                                </div>

                                            </div>

                                            <div *ngIf="table.length && isTable" class="pt-4">
                                                <table id="patergraph" datatable
                                                    class=" table-striped table-bordered mt-2 pt-2 table-sm small" 
                                                    style="width:100%">
                                                    <thead class="top"
                                                        style="background-color: #624FD7; color:#ffffff;">
                                                        <tr>
                                                            <th>S.No</th>
                                                            <th>Maximum Marks</th>
                                                            <th>Obtained Marks</th>
                                                        </tr>
                                                    </thead>


                                                    <tbody>
                                                        <tr *ngFor="let item of table;let i = index">
                                                            <td>{{i+1}}</td>
                                                            <td>{{item.max_marks}}</td>
                                                            <td><input placeholder="Enter Obtained Marks"  [readonly]="isDisableds"
                                                                    [(ngModel)]="item.obtained_marks"
                                                                    (input)="enterMarks($event,item.max_marks)"
                                                                    class="form-control form-control-sm shadow-sm rounded-lg"
                                                                    type="number"></td>
                                                        </tr>
                                                    </tbody>

                                                </table>
                                            </div>
                                            <div class="text-center" *ngIf="!table.length" style="color: red;">
                                                No Records to display
                                            </div>
                                        </div>
                                        <div class="text-center pt-4">
                                            <button type="button" (click)="onSubmit()" [disabled]="!isDisabled"
                                                class="btn btn-sm  btn-save">Save</button>
                                            &nbsp;&nbsp;
                                            <button type="button" class="btn btn-sm btn-danger" (click)="close()"
                                                id="md_close">cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>