import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, Form, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { el } from 'date-fns/locale';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-assign-marks',
  templateUrl: './assign-marks.component.html',
  styleUrls: ['./assign-marks.component.css']
})
export class AssignMarksComponent implements OnInit {
  myForm: FormGroup
  table: Array<any> = [];
  cources: Array<any> = [];
  scheduls: Array<any> = [];
  courceId: any = '';
  cschedId: any = '';
  examMarks: Array<any> = [];
  COURSESHD_ID: any;
  sheduleId: any;
  exam: string = '';
  student: Array<any> = [];
  specificExam: Array<any> = [];
  isDisabled: boolean = false;
  isDisableds: boolean = false;
  obtained: any;
  obj: any = {
    '1': 'CT',
    '2': 'SE',
    '3': 'PBE',
    '4': 'FE'
  }
  examName = [{ id: 1, name: 'CT' }, { id: 2, name: 'SE' }, { id: 3, name: 'PBE' }, { id: 4, name: 'FE' }]
  specificName: any;
  userId: any;
  obtained_marks: any;
  labelName: any = {};
  headingName: any = {};
  attendance: any;
  isTable: boolean = false;
  workItemId: any;
  constructor(private active : ActivatedRoute,private fb: FormBuilder, private CommonService: CommonService, private toastr: ToastrService, private route: Router) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.getCourses();
    this.loadHeadings();
    this.loadlabels();

  }

  ngOnInit(): void {
  }


  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }
  getCourses() {
    this.activeSpinner()
    this.CommonService.getAdminCourses().subscribe((res: any) => {
      this.cources = res;
      this.deactivateSpinner()
    }, e => {
      this.deactivateSpinner()
    })
  }
  courceChange(id: any) {
    this.sheduleId = '';
    let data = {
      "CourseId": id
    }
    this.activeSpinner()
    this.CommonService.getAdminCourseSchedule(data).subscribe((res: any) => {
      this.deactivateSpinner()
      this.scheduls = res;
    }, e => { this.deactivateSpinner() })
  }


  examDropdown(id: any) {
    this.examMarks = [];
    this.sheduleId = id;
    let payLoad = {
      "courseshd_id": id,
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('CourseSchedule/GetExamNameByCourseShd', payLoad).subscribe((res: any) => {
      this.examMarks = this.examName.filter(e => res.find(e1 => e1.exam_names == e.id));

      // if (res.length > 3) {
      //   this.examMarks = res.filter(e => this.examName.find(e1 => e1.id == e.exam_names));        
      //   console.log(this.examMarks);
      // } else {
      //   this.examMarks = this.examName.filter(e => res.find(e1 => e1.exam_names == e.id));
      //   console.log(this.examMarks);
      // }
    })

  }

  specificDropdown(id: any) {
    this.specificExam = [];
    let payLoad = {
      "exam_names": id,
      "tnt_code": localStorage.getItem('TenantCode'),
      "courseshd_id": this.sheduleId
    }
    this.CommonService.postCall('CourseSchedule/GetSpecificExamNameByExamName', payLoad).subscribe((res: any) => {
      this.specificExam = res;

    })
  }


  studentDropdown(name: any) {
    this.student = [];
    this.specificName = name
    let payLoad = {
      "STUDENT_PAYMENT_COURSESHD_ID": this.sheduleId
    }
    this.CommonService.postCall('CourseSchedule/LoadUsersByCourseScheduleId', payLoad).subscribe((res: any) => {
      this.student = res;
    })
  }


  studentChange(id: any) {
    this.userId = id;

  }
  
  attendanceChange(attendance: any) {
    this.attendance = attendance
    this.table = [];
    let payload = {
      "specific_exam_name": this.specificName,
      "tnt_code": localStorage.getItem('TenantCode'),
      "user_id": this.userId,
      "courseshd_id": this.sheduleId
    }
    this.CommonService.postCall('CourseSchedule/GetExamQuestionList', payload).subscribe((res: any) => {
      if (attendance == 'Present') {
        this.table = res;
        this.isTable = true;
        this.isDisableds = false;
        this.isDisabled = false;
      } else if(attendance == ''){
        this.isTable = true;
        this.table = []
      }else {
        res.forEach(element => {
          let obj = {
            answer: element.answer,
            chapter: element.chapter,
            chapter_name: element.chapter_name,
            exam_question_id:element.exam_question_id,
            created_by: element.created_by,
            created_on: element.created_on,
            exam_mark_id: element.exam_mark_id,
            exam_type_id: element.exam_type_id,
            max_marks: element.max_marks,
            modified_by: element.modified_by,
            modified_on: element.modified_on,
            obtained_marks: 0,
            question: element.question,
            status: element.status,
            student_attendance: element.student_attendance,
            tnt_code: element.tnt_code,
            user_id: element.user_id
          }
          this.table.push(obj);
        });
        this.isTable = true;
        this.isDisableds = true;
        this.isDisabled = true;

      }

    })
  }
  enterMarks(event: any, maxMarks: any) {
    // let mark = marks
    this.isDisabled = true;
    if (event.target.value > maxMarks || event.target.value < 0) {
      this.toastr.warning("Plases enter less than maximum marks");
      this.isDisabled = false
      return event.target.value = null;
    }
  }

  onSubmit() {
    let payLoad = []
    this.table.forEach((e: any) => {

      let obj = {
        "exam_mark_id": e.exam_mark_id,
        "exam_type_id": e.exam_type_id,
        "exam_question_id": e.exam_question_id,
        "obtained_marks": e.obtained_marks,
        "student_attendance": this.attendance,
        "created_by": localStorage.getItem('UserId'),
        "modified_by": localStorage.getItem('UserId'),
        "tnt_code": localStorage.getItem('TenantCode'),
        "user_id": this.userId,
      }
      payLoad.push(obj);
    })
    this.CommonService.postCall('CourseSchedule/InsertExamMarks', payLoad).subscribe((res: any) => {
      this.toastr.success("Marks Updated Successfully");
      // this.studentChange(this.userId);
      // window.location.reload();
      // this.table = [];
    }, err => {
      this.toastr.error(err.error ? err.error : 'Not Updated')
    });
  }

  close() {
    this.route.navigate(['/home/dashboard']);
  }
}
