import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { startWith } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-student-sales',
  templateUrl: './student-sales.component.html',
  styleUrls: ['./student-sales.component.css']
})
export class StudentSalesComponent implements OnInit {
  table: Array<any> = [];
  myForm: FormGroup;
  isEdit: boolean = false;
  campus: Array<any> = [];
  Courses: Array<any> = [];
  isCampus: boolean = false;
  courceId: string = "";
  courseschedule: Array<any> = [];
  student: Array<any> = [];
  students: Array<any> = [];
  updateList: Array<any> = [];
  total_cost: any;
  totalcost: any
  final_amount: any;
  total_discount: any;
  items: Array<any> = [];
  editData: any;
  ucost: any;
  addItem: any = {
    item: 0,
    cost: 0,
    quantity: 0,
    total_amount: 0
  };
  updateAmount: any = 0;
  updateDiscount: any = 0;
  updateFinal: any = 0;
  camId: any;
  studId: any;
  classId: boolean;
  sectionId: any;
  stId: any;
  labelName: any = {};
  headingName: any = {};
  workItemId: any;

  constructor(private active: ActivatedRoute,private ref: ChangeDetectorRef, private fb: FormBuilder, private toastr: ToastrService, private CommonService: CommonService) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadStudent();
    this.campusDropdown();
    this.loadcourses();
    this.getLabels();
    this.loadHeadings();
    this.loadItems();

  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      campus_id: ['', Validators.required],
      course_id: ['', Validators.required],
      courseschedule_id: ['', Validators.required],
      student_id: ['', Validators.required],
      item_id: [''],
      amounts: ['', Validators.required],
      total_discounts: [''],
      final_amounts: ['', Validators.required],
      payment_type: ['', Validators.required],
      list: this.fb.array([]),
    })
    this.add();
  }
  activeSpinner() {
    this.CommonService.activateSpinner();
  }
  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }
  loadStudent() {
    let payload = {
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('EmsStudentSales/GetList', payload).subscribe((res: any) => {
      this.table = res;
    })
  }
  campusDropdown() {

    let payload = {
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('EmsCampus/CampusDropDownList', payload).subscribe((res: any) => {
      this.campus = res;
    })
  }


  loadcourses() {
    // LoadCourseSchedule
    // Loadyear Loadcourses
    this.activeSpinner()
    let payaload = {
      "TENANT_CODE": localStorage.getItem('TenantCode')
    }
    this.CommonService.getAdminCourses().subscribe((res: any) => {
      this.Courses = res;
      this.deactivateSpinner();
    }, e => this.deactivateSpinner());
  }
  courseSchedule(id) {
    let payLoad = {
      "CourseId": id,
      'RoleId': localStorage.getItem('RoleId')
    }
    this.CommonService.postCall('CourseSchedule/GetAdminCourseSchedule', payLoad).subscribe((res: any) => {
      this.courseschedule = res;
    })
  }


  scheduleStudent(sid) {
    let payLoad = {
      "courseshd_id": sid
    }
    this.CommonService.postCall('Registration/GetStudentsListbyCourseSchedule', payLoad).subscribe((res: any) => {
      this.student = res;
    })
  }

  studentTable(sId) {
    this.isCampus = true;
    let value = this.myForm.value;

    let payload = {
      "student_id": value.student_id,
      "campus_id": value.campus_id,
      "class_id": value.course_id,
      "section_id": value.courseschedule_id
    }
    this.CommonService.postCall('EmsStudentSales/GetItemsStudentSales', payload).subscribe((res: any) => {
      this.students = res;
    })
  }

  getOptionGroup(data) {
    let newGroup = this.fb.group({
      item_id: [data.item_id ? data.item_id : Number, Validators.required],
      cost: [data.cost ? data.cost : Number, Validators.required],
      quantity: [data.quantity ? data.quantity : Number, Validators.required],
      total_amount: [data.total_amount ? data.total_amount : Number, Validators.required],
    });

    const priceCtrl = newGroup.get('cost');
    const quantityCtrl = newGroup.get('quantity');
    const amountCtrl = newGroup.get('total_amount');
    const TotalCtrl = this.myForm.get('total_cost');
    const finalCtrl = this.myForm.get('final_amount');
    const discountCtrl = this.myForm.get('total_discount');
    combineLatest(priceCtrl.valueChanges.pipe(startWith(priceCtrl.value)),
      quantityCtrl.valueChanges.pipe(startWith(quantityCtrl.value))).subscribe(([cost, quantity]) =>
        amountCtrl.setValue(cost * quantity))
    if (this.myForm.get('list')) {
      this.myForm.get('list').valueChanges.subscribe(values => {
        this.total_cost = 0;
        this.final_amount = 0;
        // this.total_discount = 0;
        this.myForm.value.total_discount;
        const ctrl = <FormArray>this.myForm.controls['list']
        ctrl.controls.forEach(x => {
          if (this.total_discount == 0) {
            this.total_cost += x.get('total_amount').value;
            this.final_amount = x.get('total_amount').value;
          }
          else {
            this.total_cost += x.get('total_amount').value;
            this.final_amount += x.get('total_amount').value;

          }


          this.ref.detectChanges()

        })
      })
    }

    return newGroup
  }

  discountCalculate(discount: any) {
    return this.total_cost * discount / 100;
  }

  discountChange(discountper: any) {
    this.final_amount = this.total_cost - this.discountCalculate(discountper)
  }


  add(data = {}) {

    const arrayControl = <FormArray>this.myForm.controls['list'];
    let grp: FormGroup = this.getOptionGroup(data)
    arrayControl.push(grp)
  }
  delete() {
    const arrayControl = <FormArray>this.myForm.controls['list'];
    let index = arrayControl.length;
    arrayControl.removeAt(index - 1)

  }


  isVisable() {
    let arrayControl = <FormArray>this.myForm.controls['list'];
    if (arrayControl.length && arrayControl.length > 0) {
      return true
    }
    else {
      return false
    }
  }
  isAdd() {

    let arrayControl = <FormArray>this.myForm.controls['list'];
    if (arrayControl.length && arrayControl.length > 25) {
      return false
    }
    else {
      return true
    }

  }

  loadItems() {
    this.items = [];
    this.CommonService.postCall('InvetoryItems/InventoryItemsDropDownList', {}).subscribe((res: any) => {
      this.items = res;
    })
  }
  priceAmount(event, i) {
    let ovj = this.items.filter(x => x.item_id == event.target.value);
    ovj.forEach((e, i) => {
      this.myForm.value.list['price'][i] = e.unit_price
    });
  }

  onSubmit(myForm: any) {
    let value: any = myForm.value;
    let list = [];
    myForm.value.list.forEach(e => {
      let item: any = '';
      Object.keys(e).map((key, i) => {
        let value = e[key];
        if (i > 0) {
          if (value) {
            item = item ? item + ',' + i : i;
          }
        }
      })
      let obj = {
        "item_id": e.item_id,
        "cost": e.cost,
        "quantity": e.quantity,
        "total_amount": e.total_amount
        // "floor_name": e.floor_name,
        // "amenities": item
      }
      list.push(obj);
    });
    let payload = {
      "campus_id": value.campus_id,
      "class_id": value.course_id,
      "discount": value.total_discounts,
      "cost": value.amounts,
      "total_amount": value.final_amounts,
      "student_id": value.student_id,
      "section_id": value.courseschedule_id,
      "tnt_code": localStorage.getItem('TenantCode'),
      "created_by": localStorage.getItem('UserId'),
      "ItemsSalesStudent": list
    }
    if (this.isEdit) {
      value.modified_by = localStorage.getItem('UserId');
      // value['room_type_id'] = this.editData.room_type_id;
      payload['sales_student_id'] = this.editData.sales_student_id;
      this.CommonService.postCall('EmsStudentSales/Update', payload).subscribe((res: any) => {
        this.loadStudent();
        this.toastr.success("Updated Successfully");
        document.getElementById('md_close').click();
        this.updateAmount = 0;
        this.updateDiscount = 0;
        this.updateFinal = 0;

      }, err => {
        this.toastr.error(err.error ? err.error : 'Not Updated')
      })
    } else {
      this.CommonService.postCall('EmsStudentSales/Create', payload).subscribe((res: any) => {
        this.loadStudent();
        this.toastr.success("StudentSales Created Succuessfully");
        document.getElementById('md_close').click();
        this.updateAmount = 0;
        this.updateDiscount = 0;
        this.updateFinal = 0;

      }, err => {
        this.toastr.error(err.error ? err.error : 'StudentSales Not created')

      })

    }
  }
  edit(studentid) {
    this.editData = studentid;
    this.isEdit = true;
    this.myForm.reset();
    let payLoad = {
      "sales_student_id": studentid
    }
    this.CommonService.postCall('EmsStudentSales/Get', payLoad).subscribe((res: any) => {
      this.updateList = res.ItemsSalesStudent
      this.updateAmount = res.total_amount;
      this.totalcost;
      this.updateDiscount = res.total_discount;
      this.updateFinal = res.final_amount;

      if (res instanceof Array && res.length) {
        this.editData = res[0];
        this.dataTransfer(res[0]);
      } else {
        this.editData = res;
        this.dataTransfer(res)
      }
      // this.campusTable(this.editData.campus_id)
      // this.changeDesignation(this.editData.designation_id);
      this.courseSchedule(this.editData.class_id);
      this.scheduleStudent(this.editData.section_id);
    })
  }

  dataTransfer(data) {
    let ctrls = this.myForm.controls;
    // Object.keys(ctrls).map(key => {
    //   let ctrl = ctrls[key];
    //   ctrl?.setValue(data[key])
    // })

    ctrls['campus_id'].setValue(data['campus_id'])
    ctrls['course_id'].setValue(data['class_id'])
    //  ctrls['table_data'].setValue(data['table_data'])

  }
  close() {
    this.isEdit = false;
    this.myForm.reset();
    this.editData = {};
    this.isCampus = null;
  }
}

