import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-student-placed-order',
  templateUrl: './student-placed-order.component.html',
  styleUrls: ['./student-placed-order.component.css']
})
export class StudentPlacedOrderComponent implements OnInit {
  table: Array<any> = [];
  headingName: any = {};
  workItemId: any;
  constructor(private CommonService: CommonService,private active: ActivatedRoute) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadOrders();
    this.loadHeadings();
  }

  ngOnInit(): void {
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  loadOrders() {
    let payLoad = {
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('EmsConsumption/StudentPlacedOrders', payLoad).subscribe((res: any) => {
      this.table = res;
      console.log(this.table);

    })

  }

}
