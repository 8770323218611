<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">

                    <div class="container-fluid">

                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.AllStaffAttendenceList}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="form-group col-md-3">
                                                <label>{{labelName.Month}}</label>
                                                <div class="input-group">
                                                    <input placeholder="Select Month" (change)="getStaffList($event)"
                                                        class="form-control form-control-sm shadow-sm rounded-lg"
                                                        placeholder="Select Month YYYY/MM" onfocus="(this.type='month')"
                                                        type="text">

                                                </div>
                                            </div>
                                        </div>
                                        <div class="text-right mb-2">
                                            <button type="button" (click)="add()" class="btn btn-primary">Add Post
                                                Attendence </button>
                                        </div>
                                        <div *ngIf="table.length">
                                            <table id="patreggraph" datatable
                                                class="table table-striped table-bordered mt-2 pt-2 table-sm small display"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624fd7; color:#ffffff;">
                                                    <tr>

                                                        <th> Full Name </th>
                                                        <th> Designation </th>
                                                        <th> Total Present</th>
                                                        <th> Total Absent </th>
                                                        <!-- <th> Total Working Days</th> -->

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">

                                                        <td>{{item.name}}</td>
                                                        <td>{{item.designation}}</td>
                                                        <td>{{item.total_present}}</td>
                                                        <td>{{item.total_absent}}</td>
                                                        <!-- <td>{{item.totalworkingdays}}</td> -->
                                                    </tr>
                                                </tbody>

                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records To Display
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>