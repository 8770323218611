import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-outing-details',
  templateUrl: './outing-details.component.html',
  styleUrls: ['./outing-details.component.css']
})
export class OutingDetailsComponent implements OnInit {
  myForm: FormGroup;
  campus: Array<any> = [];
  block: Array<any> = [];
  floor: Array<any> = [];
  room: Array<any> = [];
  students: Array<any> = [];
  table: Array<any> = [];
  isEdit: boolean = false;
  editData: any = {};
  labelName: any = {};
  headingName: any = {};
  workItemId: any;

  constructor(private fb: FormBuilder, private active: ActivatedRoute, private CommonService: CommonService, private toastr: ToastrService) {
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
    this.load();
    this.campusDropdown();
    this.getLabels();
    this.loadHeadings();

    this.studentDropdown();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      campus_id: ['', Validators.required],
      block_id: ['', Validators.required],
      floor_id: ['', Validators.required],
      room_id: ['', Validators.required],
      student_id: ['', Validators.required],
      outing_out_time: ['', Validators.required],
      outing_in_time: ['', Validators.required],
      outing_purpose: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      expected_in_time: ['', Validators.required],
      reason_for_late: ['']
    })
  }
  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  load() {
    let payload = {
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('EmsOutingDetails/GetList', payload).subscribe((res: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = res;
      }, 10)
    })
  }

  campusDropdown() {
    let payload = {
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('EmsCampus/CampusDropDownList', payload).subscribe((res: any) => {
      this.campus = res;
    })
  }
  campusChange(id) {
    let payload = {
      "campus_id": id
    }
    this.CommonService.postCall('EmsBlock/BlockDropDownList', payload).subscribe((res: any) => {
      this.block = res;
    })
  }
  blockChange(id) {
    let payload = {
      "block_id": id
    }
    this.CommonService.postCall('EmsFloor/FloorDropDownList', payload).subscribe((res: any) => {
      this.floor = res;
    })
  }
  floorChange(id) {
    let payload = {
      "floor_id": id
    }
    this.CommonService.postCall('EmsRoom/RoomDropDownListList', payload).subscribe((res: any) => {
      this.room = res;
    })
  }
  studentDropdown() {
    let payLoad = {
      "objUserinrole":
      {
        "RoleId": 3
      },
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('Registration/UserRolesChange', payLoad).subscribe((res: any) => {
      this.students = res;
    }, e => {

    });
  }

  onSubmit(form: FormGroup) {
    let value: any = form.value
    value.tnt_code = localStorage.getItem('TenantCode');
    value.created_by = localStorage.getItem('UserId');
    if (this.isEdit) {
      value.modified_by = localStorage.getItem('UserId');
      value['outing_details_id'] = this.editData.outing_details_id;
      this.CommonService.postCall('EmsOutingDetails/Update', value).subscribe((res: any) => {
        this.load();
        this.toastr.success("Outing Details Updated Successfully");
        document.getElementById('md_close')?.click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Outing Details Not Updated')
      })
    }
    else {
      this.CommonService.postCall('EmsOutingDetails/Create', value).subscribe((res: any) => {
        this.load();
        this.toastr.success("Outing Details Created Successfully");
        document.getElementById('md_close')?.click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Outing Details Not created')

      })
    }
  }


  edit(outingId) {
    this.isEdit = true;
    let payLoad = {
      "outing_details_id": outingId
    }
    this.CommonService.postCall('EmsOutingDetails/Get', payLoad).subscribe((res: any) => {
      this.editData = res;
      console.log(res);
      Promise.all([
        this.blockChange(this.editData.block_id),
        this.campusChange(this.editData.campus_id),
        this.floorChange(this.editData.floor_id),
      ]).then(() => {
        this.dataTransForm(res);
      })
    })
  }

  dataTransForm(data) {

    // let ctrls = this.myForm.controls
    // Object.keys(ctrls).map((key) => {
    //   let ctrl: AbstractControl = ctrls[key];
    //   ctrl.setValue(this.editData[key])

    // });

    this.myForm.patchValue({
      campus_id: this.editData['campus_id'],
      block_id: this.editData['block_id'],
      floor_id: this.editData['floor_id'],
      room_id: this.editData['room_id'],
      student_id: this.editData['student_id'],
      outing_in_time: this.editData['outing_in_time'],
      outing_out_time: this.editData['outing_out_time'],
      outing_purpose: this.editData['outing_purpose'],
      expected_in_time: this.editData['expected_in_time'],
      reason_for_late: this.editData['reason_for_late'],

    })
  }
  close() {
    this.isEdit = null;
    this.myForm.reset();
    this.editData = {};
    this.blockChange(this.editData.block_id);
    this.campusChange(this.editData.campus_id);
    this.floorChange(this.editData.floor_id);
  }

}
