import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../base.component';
@Component({
  selector: 'app-email-status',
  templateUrl: './email-status.component.html',
  styleUrls: ['./email-status.component.css']
})
export class EmailStatusComponent extends BaseComponent implements OnInit {
  table: Array<any> = [];
  viewMailData: any = {};
  headingName: any = {};
  workItemId: any;
  labelName: any = {};
  constructor(private fb: FormBuilder,  CommonService: CommonService,  toastr: ToastrService,private active : ActivatedRoute) {
    super(CommonService, toastr);
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.load();
    this.loadHeadings();
    this.getLabels();
  }

  ngOnInit(): void {
  }
  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }
  activiceSpinner() {
    this.CommonService.activateSpinner();
  }
  deactivateSpinner() {
    this.CommonService.deactivateSpinner();
  }

  load() {
    this.activiceSpinner();
    this.CommonService.getCall(`EmsAlerts/GetEmailStatus/${this.tId ||localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = res;
      }, 10)
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner(), console.log(e) })
  }


  // changeStatus(lId: any, status: string) {
  //   let payload = {
  //     "leavetypes_id": lId,
  //     "leavetypes_status": status
  //   }
  //   if (confirm(`Are you sure you want to ${status} status`)) {
  //     this.CommonService.getCall(`EmsLeaveManagement/UpdateLeaveStatus/${lId}/${status}`).subscribe((res: any) => {
  //       this.load();
  //       this.toastr.success(`Leave type ${status} Successfully`);
  //     })
  //   }
  // }

  resendMail(id) {

  }

  view(id) {
    this.activiceSpinner();
    this.CommonService.getCall(`EmsAlerts/GetEmailQueue/${id}`).subscribe((res: any) => {
      this.viewMailData = {};
      setTimeout(() => {
        this.viewMailData = res;
      }, 10)
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner(), console.log(e) })
  }

  close() {
    this.viewMailData = {};
  }
}
