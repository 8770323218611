<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">

                    <div class="container-fluid">

                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.StudentDailyDiaryList4}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <form class="well form-horizontal" [formGroup]="myForm"
                                            (ngSubmit)="onSubmit(myForm)">
                                            <fieldset>
                                                <div class="form-group row pt-4">
                                                    <label class="col-md-4 control-label line_2 rem_1">
                                                       {{labelName.Campus}} <span class="text-danger">*</span></label>
                                                    <div class="col-md-8 inputGroupContainer">
                                                        <div class="input-group">
                                                            <strong>:</strong> &nbsp;
                                                            <select
                                                                class="form-control form-control-sm shadow-sm rounded-lg"
                                                                formControlName="campus_id">
                                                                <option value="" selected>Select</option>
                                                                <option *ngFor="let item of campusDropdown"
                                                                    [value]="item.campus_id">
                                                                    {{item.campus_name}}</option>
                                                            </select>
                                                        </div>
                                                        <div *ngIf="myForm.get('campus_id').touched">
                                                            <span class="text-danger"
                                                                *ngIf="myForm.get('campus_id').hasError('required')">
                                                                This field is required.
                                                            </span>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group row pt-4">
                                                    <label class="col-md-4 control-label line_2 rem_1">
                                                       {{labelName.Course}} <span class="text-danger">*</span></label>
                                                    <div class="col-md-8 inputGroupContainer">
                                                        <div class="input-group">
                                                            <strong>:</strong> &nbsp;
                                                            <select formControlName="course_id" [(ngModel)]='courceId '
                                                                (change)="courceChange($any($event).target.value)"
                                                                class="form-control form-control-sm shadow-sm rounded-lg">
                                                                <option value="" selected>Select</option>
                                                                <option *ngFor="let course of cources"
                                                                    [value]='course.COURSE_ID'>
                                                                    {{course.COURSE_NAME}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div *ngIf="myForm.get('course_id').touched">
                                                            <span class="text-danger"
                                                                *ngIf="myForm.get('course_id').hasError('required')">
                                                                This field is required.
                                                            </span>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group row pt-4">
                                                    <label class="col-md-4 control-label line_2 rem_1">
                                                        {{labelName.CourseSchedule}}<span class="text-danger">*</span></label>
                                                    <div class="col-md-8 inputGroupContainer">
                                                        <div class="input-group">
                                                            <strong>:</strong> &nbsp;
                                                            <select formControlName="courseshd_id"
                                                                [(ngModel)]='courseshdId'
                                                                (change)="schedulsChange($any($event).target.value)"
                                                                class="form-control form-control-sm shadow-sm rounded-lg">
                                                                <option value="" selected>Select</option>
                                                                <option *ngFor="let schedul of scheduls"
                                                                    [value]='schedul.courseshd_id'>
                                                                    {{schedul.courseshd_name}}</option>
                                                            </select>
                                                        </div>
                                                        <div *ngIf="myForm.get('courseshd_id').touched">
                                                            <span class="text-danger"
                                                                *ngIf="myForm.get('courseshd_id').hasError('required')">
                                                                This field is required.
                                                            </span>

                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="form-group row pt-4">
                                                    <label class="col-md-4 control-label line_2 rem_1">
                                                       {{labelName.Student}} <span class="text-danger">*</span></label>
                                                    <div class="col-md-8 inputGroupContainer">
                                                        <div class="input-group">
                                                            <strong>:</strong> &nbsp;
                                                            <select formControlName="student"
                                                                class="form-control form-control-sm shadow-sm rounded-lg">
                                                                <option value="" selected>Select</option>
                                                                <option *ngFor="let item of studentDropdown"
                                                                    [value]='item.student_payment_student_id '>
                                                                    {{item.studentname }}</option>
                                                            </select>
                                                        </div>
                                                        <div *ngIf="myForm.get('student').touched">
                                                            <span class="text-danger"
                                                                *ngIf="myForm.get('student').hasError('required')">
                                                                This field is required.
                                                            </span>

                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="form-group row pt-4 mb-4">
                                                    <label class="col-md-4 control-label line_2 rem_1">
                                                        {{labelName.Date}}<span class="text-danger">*</span></label>
                                                    <div class="col-md-8 inputGroupContainer">
                                                        <div class="input-group">
                                                            <strong>:</strong> &nbsp;
                                                            <input type="date" formControlName="date"
                                                                (change)="loadSubjectsList();" class="form-control"/>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div *ngIf="viewTable">
                                                    <div class="form-group row pt-4" *ngIf="myForm.controls['list']" >

                                                        <table formArrayName='list'
                                                            class="table table-striped table-bordered table-responsive">
                                                            <thead class="top"
                                                                style="background-color: #624fd7; color:#ffffff;">
                                                                <tr class="text-center">
                                                                    <th>Subjects</th>
                                                                    <th>Home Work</th>
                                                                    <th>Class Work</th>
                                                                    <th>Class Activity</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let item of myForm.controls.list.controls;let i=index"
                                                                    [formGroupName]="i">
                                                                    <td>
                                                                        <!-- <span formControlName="subject_name"></span> -->
                                                                        <input type="text" class="form-control "
                                                                            disabled formControlName="subject_name" />
                                                                    </td>
                                                                    <td>
                                                                        <div><textarea class="form-control "
                                                                                formControlName="home_work_description"
                                                                                placeholder="Home Work Description"
                                                                                id="floatingTextarea"></textarea>
                                                                        </div>
                                                                        <div class="input-group pt-4">
                                                                            <input type="file" class="form-control "
                                                                           
                                                                                (change)="changeFile($event,i,'home_work_attachment_file')"
                                                                                 />
                                                                        </div>
                                                                   

                                                                        <div class="input-group pt-4">
                                                                            <input type="test" class="form-control "
                                                                                formControlName="home_work_submission_date"
                                                                                placeholder="Home Work Submission Date"
                                                                                onfocus="(this.type='date')" />
                                                                        </div>
                                                                        <!-- <div class="input-group pt-4" 
                                                                            *ngIf="myForm.controls['list']['home_work_attachment_file'].length>0">
                                                                            <button class=" btn btn-warning btn-sm"><a href="javascript: void(0)" value="Newsletter" >
                                                                                Attachment
                                                                            </a></button>
                                                                        </div> -->  

                                                                    </td>
                                                                    <td>
                                                                        <div><textarea class="form-control "
                                                                                formControlName="class_work_description"
                                                                                placeholder="Class Work Description"
                                                                                id="floatingTextarea"></textarea>
                                                                        </div>
                                                                        <div class="input-group pt-4">
                                                                            <input type="file" class="form-control "
                                                                           
                                                                                (change)="changeFile($event,i,'class_work_attachment_file')" />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div><textarea class="form-control "
                                                                                placeholder="Class Activity Description"
                                                                                formControlName="class_activity_description"
                                                                                id="floatingTextarea"></textarea>
                                                                        </div>
                                                                        <div class="input-group pt-4">
                                                                            <input type="file" class="form-control "
                                                                      
                                                                                (change)="changeFile($event,i,'class_activity_attachment_file')" />
                                                                        </div>
                                                                    </td>

                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <div class="form-group row pt-4 mb-4">
                                                        <div class="col-md-12 inputGroupContainer">
                                                            <div class="input-group">

                                                                <textarea class="form-control"
                                                                    formControlName="addtional_remarks"
                                                                    placeholder="Announcement"
                                                                    id="floatingTextarea"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-group row pt-4 mb-4">
                                                        <label class="col-md-2 control-label line_2 rem_1">
                                                          {{labelName.NewsletterAttachment}} <span
                                                                class="text-danger"></span></label>
                                                        <div class="col-md-4 inputGroupContainer">
                                                            <div class="input-group">
                                                                <strong>:</strong> &nbsp;
                                                                <input type="file" class="form-control "
                                                                   
                                                                    (change)="changeFile1($event,'newsletter_attachment_file')"
                                                                    id="floatingTextarea" />
                                                            </div>
                                                        </div>
                                                        <label class="col-md-2 control-label line_2 rem_1">
                                                           {{labelName.MonthlyEventAttachment}} <span
                                                                class="text-danger"></span></label>
                                                        <div class="col-md-4 inputGroupContainer">
                                                            <div class="input-group">
                                                                <strong>:</strong> &nbsp;
                                                                <input type="file" class="form-control "
                                                                   
                                                                    (change)="changeFile1($event,'monthly_event_attachment_file')"
                                                                    id="floatingTextarea" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </fieldset>
                                        </form>
                                        <div>
                                            <div class="text-center pt-4">

                                                <button type="button" class="btn btn-sm  btn-save"
                                                    [disabled]="myForm.invalid" (click)="onSubmit(myForm)"
                                                    type="submit">Save</button>
                                                &nbsp; <button class="btn btn-danger btn-sm"
                                                    (click)='close()'>Cancel</button>

                                            </div>

                                        </div>

                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>