<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.ExamResult}}</h5>
                                    </div>

                                    <div class="card-body">
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <label for="sel1">{{labelName.CourseName}} : </label>
                                                    <select [(ngModel)]='courceId '
                                                        class="form-select form-select-sm shadow-sm rounded-lg"
                                                        id="sel1" (change)="courceChange()">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let course of cources"
                                                            [value]='course.COURSE_ID'>
                                                            {{course.COURSE_NAME}}
                                                        </option>

                                                    </select>
                                                </div>
                                                <div class="col-md-3">
                                                    <label for="sel1">{{labelName.CourseSchedule}} :</label>
                                                    <select [(ngModel)]='schedulId '
                                                        class="form-select form-select-sm shadow-sm rounded-lg"
                                                        id="sel1" (change)="schedulChange()">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let schedul of scheduls"
                                                            [value]='schedul.COURSESHD_ID'>
                                                            {{schedul.COURSESHD_NAME}}</option>

                                                    </select>


                                                </div>
                                                <div class="col-md-3">
                                                    <label for="sel1">{{labelName.AssessmentName}}: </label>
                                                    <select [(ngModel)]='assessmentId '
                                                        class="form-select form-select-sm shadow-sm rounded-lg"
                                                        id="sel1" (change)="assessmentChange()">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let item of assessments"
                                                            [value]='item.ASSESSMENT_ID'>
                                                            {{item.ASSESSMENT_ASSESSMENT_NAME}}
                                                        </option>

                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="pt-4" *ngIf="results.length">
                                            <table id="patreggraph" datatable
                                                class="table table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th>User Name</th>
                                                        <th>Roll Number</th>
                                                        <th>Marks</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of results">
                                                        <td>{{item.FirstName}}</td>
                                                        <td>{{item.RollNumber}}</td>
                                                        <td><input type="number" [(ngModel)]="item.EXM_MARKS"></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="text-center">
                                                <button type="button" class="btn btn-sm  btn-save"
                                                    (click)="submit()">Submit</button>
                                            </div>
                                        </div>
                                        <div class="text-center" *ngIf="!results.length&&assessmentId!=''">
                                            No Records To display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>