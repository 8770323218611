import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { FileuploadService } from 'src/app/services/fileupload.service';
import { id } from 'date-fns/locale';


@Component({
  selector: 'app-copo-assessment',
  templateUrl: './copo-assessment.component.html',
  styleUrls: ['./copo-assessment.component.css']
})
export class CopoAssessmentComponent implements OnInit {
  table: Array<any> = [];
  myForm: FormGroup;
  isEdit: boolean = false;
  dropdownSettings: any = {}
  COURSESHD_ID: number;
  COURSE_ID: number;
  staff: Array<any> = [];
  chap: Array<any> = [];
  dropdownList: { CHAPTER_ID: number; CHAPTER_NAME: string; }[] = [];
  selectedChapter: { CHAPTER_ID: number; CHAPTER_NAME: string; }[] = [];
  editData: any;
  headingName: any = {};
  labelName: any = {};
  rno: any;
  exam_names = [{ id: 1, name: 'CT' }, { id: 2, name: 'SE' }, { id: 3, name: 'PBE' }, { id: 4, name: 'FE' }]
  examTypeId: any;
  workItemId: any;
  specificExam: Array<any> = [];
  constructor(private fb: FormBuilder, private route: Router, private CommonService: CommonService, private toastr: ToastrService, private active: ActivatedRoute) {
    active.queryParams.subscribe((res) => {
      this.COURSESHD_ID = res.id;
      this.COURSE_ID = res.ids;
      this.workItemId = res.wId;
      console.log(this.COURSESHD_ID);
      console.log(this.COURSE_ID);
    })
    this.loadHeadings();
    this.getLabels();
    this.loadHeadings();
    this.loadList();

  }

  ngOnInit(): void {

    this.myForm = this.fb.group({
      exam_type: ['', Validators.required],
      questionType: [''],
      exam_names: ['', Validators.required],
      specific_exam_name: ['', Validators.required],
      threshold_percentage: ['', Validators.required],
      chapter: [''],

      ems_exam_questions: this.fb.array([]),
    })
    this.chapterDropdown();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'CHAPTER_ID',
      textField: 'CHAPTER_NAME',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  onItemSelect(e) { }
  onSelectAll(e) { }
  getName(id: any) {
    return this.exam_names.find(f => f.id == id)?.name ?? '';
  }
  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  loadList() {
    this.table = [];
    let payload = {
      "tnt_code": localStorage.getItem('TenantCode'),
      "courseshd_id": this.COURSESHD_ID
    }
    this.CommonService.postCall('CourseSchedule/GetExamTypeList', payload).subscribe((res: any) => {
      this.table = res;
      setTimeout(() => {
        this.table = res;
      }, 10);
    })
  }

  loadSpecificExam(event: any) {
    this.specificExam = [];
    let exam_name_id = event;
    this.CommonService.getCall(`CourseSchedule/LoadSpecificExamNamesByExamName/${exam_name_id}`).subscribe((res: any) => {
      this.specificExam = res;
    })

  }


  getOptionGroup(data) {
    let newGroup = this.fb.group({
      exam_question_id: [data.exam_question_id ? data.exam_question_id : 0],
      chapter: [data.chapter ? data.chapter : ''],
      question: [data.question ? data.question : '', Validators.required],
      max_marks: [data.max_marks ? data.max_marks : '', Validators.required],
    });
    return newGroup
  }

  isAdd() {
    let arrayControl = <FormArray>this.myForm.controls['ems_exam_questions'];
    if (arrayControl.length && arrayControl.length > 25) {
      return false
    }
    else {
      return true
    }
  }

  add(data = {}) {
    const arrayControl = <FormArray>this.myForm.controls['ems_exam_questions'];
    let grp: FormGroup = this.getOptionGroup(data)
    arrayControl.push(grp)
  }

  delete() {
    const arrayControl = <FormArray>this.myForm.controls['ems_exam_questions'];
    let index = arrayControl.length;
    arrayControl.removeAt(index - 1)
  }

  isVisable() {
    let arrayControl = <FormArray>this.myForm.controls['ems_exam_questions'];
    if (arrayControl.length && arrayControl.length > 0) {
      return true
    }
    else {
      return false
    }
  }

  thresholdPercentage() {
    let amount: any = document.getElementById('amount');
    amount.addEventListener('input', function () {
      const n = amount.value.replace('%', '');
      if (n >= 1 && n <= 100) {
        amount.value = amount.value.replace('%', '')
      } else {
        amount.value = n.slice(0, -1)
      }
    })
  }
  chapterDropdown() {
    this.chap = [];
    let payload = {
      CHAPTER_CS_ID: this.COURSESHD_ID
    }
    this.CommonService.postCall('Chapters/LoadChaptersByCourseSchedule', payload).subscribe((res: any) => {
      this.chap = res;
      // this.staff = res.filter(e => e != null);
      // res.map(e => {
      //   let obj = {
      //     CHAPTER_ID: e.CHAPTER_ID,
      //     CHAPTER_NAME: e.CHAPTER_NAME
      //   }
      //   this.dropdownList.push(obj)
      // })
      // console.log(this.dropdownList);

    });
  }


  // setSelected() {
  //   let value: string = this.editData['room_items']
  //   let list: any = []
  //   value?.split(',').map((id: any) => {

  //     let index = this.staff.findIndex(data => data.CHAPTER_ID == id);

  //     if (index > 1) {
  //       list.push(this.staff[index])
  //     }
  //   })
  //   this.selectedChapter = list;
  // }

  // addArray() {
  //   this.add();
  // }

  onSubmit(form) {
    let value: any = form.value;
    let payload = {
      "exam_type": value.exam_type,
      "exam_names": value.exam_names,
      "specific_exam_name": this.specificExam.find(e => e.specificname_id == value.specific_exam_name)?.name,
      "specificname_id": value.specific_exam_name,
      "threshold_percentage": value.threshold_percentage,
      "courseshd_id": this.COURSESHD_ID,
      // "chapter": this.selectedChapter.map(m => m.CHAPTER_ID).join(","),
      "created_by": localStorage.getItem('UserId'),
      "modified_by": localStorage.getItem('UserId'),
      "tnt_code": localStorage.getItem('TenantCode'),
      "ems_exam_questions": value.ems_exam_questions

    }
    console.log(payload);
    if (this.isEdit) {
      // value['modified_by'] = localStorage.getItem('UserId');
      // value['created_by'] = localStorage.getItem('UserId');
      // value['tnt_code'] = localStorage.getItem('TenantCode'),
      //   value['courseshd_id'] = this.COURSESHD_ID
      // value['exam_type_id'] = this.examTypeId;
      // value['ems_exam_questions'] = value.ems_exam_questions
      payload['exam_type_id'] = this.examTypeId;
      this.CommonService.postCall('CourseSchedule/UpdateExamTypeQuestions', payload).subscribe((res: any) => {
        this.loadList();
        this.toastr.success(" Updated Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Bus Number Not Updated')
      })
    } else {
      if (this.table.find((e: any) => e.specific_exam_name == value.specific_exam_name)) {
        this.toastr.warning('Specific Exam Already Exists');
        return;
      }
      this.CommonService.postCall('CourseSchedule/InsertTypeQuestions', payload).subscribe((res: any) => {
        this.loadList();
        // this.route.navigate(['home/courseSchedule']);
        this.toastr.success(" Created Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Not created')
      });
    }
  }

  edit(bmId: any) {
    this.isEdit = true;
    this.examTypeId = bmId;
    console.log(bmId);

    this.CommonService.postCall('CourseSchedule/GetQuestion', { "exam_type_id": bmId }).subscribe((res: any) => {
      this.editData = res;
      setTimeout(() => {
        this.loadSpecificExam(this.editData['exam_names']);
      }, 150);

      if (res.ems_exam_questions != null && res.ems_exam_questions.length > 0) {
        res.ems_exam_questions.map(x => {
          let obj = this.fb.group({
            exam_question_id: this.fb.control(x.exam_question_id),
            chapter: this.fb.control(x.chapter),
            question: this.fb.control(x.question),
            max_marks: this.fb.control(x.max_marks),

          });
          (<FormArray>this.myForm.get('ems_exam_questions')).push(obj);

        })

      }

      setTimeout(() => {
        this.dataTransfer(res);
      }, 10);
    })
  }
  dataTransfer(data) {

    this.myForm.patchValue({
      exam_type: this.editData['exam_type'],
      exam_names: this.editData['exam_names'],
      // specific_exam_name: this.editData['specific_exam_name'],
      specific_exam_name: this.editData['specificname_id'],
      threshold_percentage: this.editData['threshold_percentage'],
      chapter: this.editData['chapter'],


    })


  }

  // close() {
  //   this.isEdit = null;
  //   this.myForm.reset();
  //   this.myForm.controls['ems_exam_questions'] = this.fb.array([]);
  //   this.route.navigate([`home/courseSchedule/${this.workItemId}`]);

  // }
back(){
  window.history.back();
}
  close() {
    this.loadSpecificExam(this.editData['specificname_id']),
      this.isEdit = null;
    this.myForm.controls['ems_exam_questions'] = this.fb.array([]);
    this.myForm.reset();
  }
}
