import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-coursesh-assign-users',
  templateUrl: './coursesh-assign-users.component.html',
  styleUrls: ['./coursesh-assign-users.component.css']
})
export class CourseshAssignUsersComponent implements OnInit {
  table: Array<any> = [];
  workItemId: any;
  headingName: any = {};
  constructor( private CommonService: CommonService,active:ActivatedRoute) {
    active.queryParams.subscribe((res:any)=>{
      this.workItemId = res.wId;
      if(res.csId){
        this.loadUsers(res.csId)
      }
    });
    this.loadHeadings();
  }

  ngOnInit(): void {
    // this.loadUsers();
    
  }


  activeSpinner(){
    this.CommonService.activateSpinner();
  }

  deactivateSpinner(){
    this.CommonService.deactivateSpinner()
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
 back(){
  window.history.back();
 }

  loadUsers(id){
     this.activeSpinner();
     this.CommonService.postCall('LoadUsersByCourseScheduleId',{STUDENT_PAYMENT_COURSESHD_ID:id}).subscribe(
       (res:any)=>{
         this.table=res;
         this.deactivateSpinner()
       },err=>{this.deactivateSpinner()})
  }
  


}
