import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from '../base.component';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-approval-list',
  templateUrl: './approval-list.component.html',
  styleUrls: ['./approval-list.component.css']
})
export class ApprovalListComponent extends BaseComponent implements OnInit {
  table: Array<any> = [];
  address: any = '';
  city: any = '';
  state: any = '';
  country: any = '';
  pincode: any = '';
  ADDRESSDETAILS : any = '';
  headingName : any = {};
  workItemId: any;
  constructor(private fb: FormBuilder,private active : ActivatedRoute, CommonService: CommonService, toastr: ToastrService) {
    super(CommonService, toastr)
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadApprovalList();
    this.addresses();
    this.loadHeadings();
  }

  ngOnInit(): void {
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  loadApprovalList() {
    this.CommonService.postCall('Transport/GetEmsApprovalList', { "tnt_code": localStorage.getItem('TenantCode') }).subscribe((res: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = res;
        this.renderDataTable();
      }, 10)
    })
  }

  addresses() {
    let Address = [];
    this.table.forEach(e => {
      Address = e.address;
    });
    const first = Address.find((obj) => {
      return obj.ADDRESSTYPE === "Permanent Address";
    });
    // console.log(first,Address)
  }

  reject(uId: any, status: boolean) {
    let payload = {
      "userid": uId,
      "modified_by": localStorage.getItem('UserId'),
      "approved_status": status
    }
    if (confirm(`Are you sure you want to ${status ? 'Approve' : 'Reject'} Student Transport`)) {
      this.CommonService.postCall(`Transport/UpdateApprovalStatus`, payload).subscribe((res: any) => {
        this.loadApprovalList();
        this.toastr.success(`${status ? 'Approved' : 'Rejected'} Successfully`);
      })
    }

  }


}
