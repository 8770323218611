import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { file, files } from 'jszip';
@Component({
  selector: 'app-add-student-diary',
  templateUrl: './add-student-diary.component.html',
  styleUrls: ['./add-student-diary.component.css']
})
export class AddStudentDiaryComponent implements OnInit {
  tnt_code: number;
  myForm: FormGroup;
  campusDropdown: Array<any> = [];
  cources: Array<any> = [];
  scheduls: Array<any> = [];
  studentDropdown: Array<any> = [];
  subjectList: Array<any> = [];
  subjectLists: Array<any> = [];
  courceId: any = '';
  courseshdId: any = '';
  viewTable: boolean = false;
  list: FormArray;
  uploadHomeWork: File;
  uploadClassWork: File;
  uploadAvtivityWork: File;
  uploadNewsLatter: File;
  uploadMonthlyevent: File;
  fileList: Array<File> = [];
  formData = new FormData();
  labelName: any = {};
  headingName: any = {};
  workItemId: any;
  constructor(private route: Router, private fb: FormBuilder, private CommonService: CommonService, private toastr: ToastrService, private active: ActivatedRoute) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadCampusDropdown();
    this.getCourses();
    this.getLabels();
    this.loadHeadings();
    this.tnt_code = +localStorage.getItem('TenantCode');
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      campus_id: ['', Validators.required],
      course_id: ['', Validators.required],
      courseshd_id: ['', Validators.required],
      student: ['', Validators.required],
      date: ['', Validators.required],
      list: this.fb.array([]),
      addtional_remarks: [''],
      newsletter_attachment_file: [File],
      monthly_event_attachment_file: [File],
    });
  }

  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }


  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  loadCampusDropdown() {
    this.campusDropdown = [];
    this.CommonService.postCall('EmsCampus/CampusDropDownList', { 'tnt_code': localStorage.getItem('TenantCode') }).subscribe((res: any) => {
      this.campusDropdown = res;
    })
  }
  getCourses() {
    this.activeSpinner()
    this.CommonService.getAdminCourses().subscribe((res: any) => {
      this.cources = res;
      this.deactivateSpinner()
    }, e => {
      this.deactivateSpinner()
    })
  }

  courceChange(id: any) {

    let data = {
      "CourseId": id
    }
    this.activeSpinner()
    this.CommonService.getCall(`CourseSchedule/GetCourseshdByCourseId/${id}`).subscribe((res: any) => {
      this.deactivateSpinner()
      this.scheduls = res;
    }, e => { this.deactivateSpinner() })
  }

  schedulsChange(id: any) {
    let data = {
      "CourseId": id
    }
    this.activeSpinner()
    this.CommonService.getCall(`Registration/LoadStudentsByCourseshd/${id}`).subscribe((res: any) => {
      this.deactivateSpinner()
      this.studentDropdown = res;
    }, e => { this.deactivateSpinner() })
  }

  // trackByFn(index: any, item: any) {
  //   return index;
  // }


  getSubjectList(subject: any) {
    let newSubject = this.fb.group({

    })
    return newSubject
  }


  loadSubjectsList() {
    this.subjectLists = [];
    // this.myForm.controls['list']=this.fb.array([]);
    this.viewTable = false;
    let payload = {
      "class_id": this.myForm.value['course_id']
      // "courseshd_id": this.myForm.value['courseshd_id'],
      // "date": this.myForm.value['date'],
      // "student_id": this.myForm.value['student'],
    }
    this.CommonService.postCall(`TimeTable/LoadSubjectsByCourseshd`, payload).subscribe((res: any) => {
      this.subjectLists = res;
      // res.forEach(e => {
      // this.subjectLists.push(e.attachments[0])
      // });

      this.subjectLists.map(x => {
        let obj = this.fb.group({
          subject_id: x.subject_id,
          subject_name: x.subject_name,
          home_work_description: x.home_work_description,
          home_work_submission_date: x.home_work_submission_date,
          home_work_attachment_file: x.home_work_attachment_file,
          class_work_attachment_file: x.class_work_attachment_file,
          class_work_description: x.class_work_description,
          class_activity_description: x.class_activity_description,
          class_activity_attachment_file: x.class_activity_attachment_file,
        });
        (<FormArray>this.myForm.get('list')).push(obj);
      })
      this.viewTable = true;
    })

  }


  getSubjects() {
    this.subjectList = [];
    let data = {
      "CourseId": this.courseshdId
    }
    this.CommonService.getCall(`Subject/LoadSubjectsByCourseshd/${this.courseshdId}`).subscribe((res: any) => {
      this.subjectList = res;
      res.map(x => {
        let obj = this.fb.group({
          subject_id: this.fb.control(x.subject_id),
          subject_name: this.fb.control(x.subject_name),
          home_work_description: '',
          home_work_submission_date: '',
          home_work_attachment_file: File,
          class_work_attachment_file: File,
          class_work_description: '',
          class_activity_description: '',
          class_activity_attachment_file: File,
        });
        (<FormArray>this.myForm.get('list')).push(obj);
      })
      this.viewTable = true;
    })
    this.viewTable = true;
    const arrayControl = <FormArray>this.myForm.controls['list'];
    for (let index = 0; index > this.subjectList.length; index++) {
      let grp: FormGroup = this.getSubjectList(this.subjectList[index])
      arrayControl.push(grp)
    }
  }


  changeFile(event, i, name) {
    if (event.target.files.length > 0) {
      let file: File = event.target.files[0];
      if (name == 'home_work_attachment_file') {
        // this.uploadHomeWork = event.target.files[0];
        // this.myForm.value.list[i]['home_work_attachment_file'] = event.target.files[0];
        ((this.myForm.get('list') as FormArray).at(i) as FormGroup).get('home_work_attachment_file').patchValue(event.target.files[0]);
      } else if (name == 'class_work_attachment_file') {
        // this.uploadClassWork = event.target.files[0];
        // this.myForm.value.list[i]['class_work_attachment_file'] = event.target.files[0];
        ((this.myForm.get('list') as FormArray).at(i) as FormGroup).get('class_work_attachment_file').patchValue(event.target.files[0]);
      } else if (name == 'class_activity_attachment_file') {
        // this.uploadAvtivityWork = event.target.files[0];
        // this.myForm.value.list[i]['class_activity_attachment_file'] = event.target.files[0];
        ((this.myForm.get('list') as FormArray).at(i) as FormGroup).get('class_activity_attachment_file').patchValue(event.target.files[0]);
      }
    }
  }

  changeFile1(event, name) {
    if (event.target.files.length > 0) {
      let file: File = event.target.files[0];
      if (name == 'newsletter_attachment_file') {
        this.uploadNewsLatter = event.target.files[0];
        // this.myForm.value['newsletter_attachment_file'] = event.target.files[0];
      } else if (name == 'monthly_event_attachment_file') {
        this.uploadMonthlyevent = event.target.files[0];
        // this.myForm.value['monthly_event_attachment_file'] = event.target.files[0];
      }
    }
  }

  // changeFile1(event, i, name) {   
  //     let file: File = event.target.files[0];
  //     console.log(file);
  //       this.myForm.value.list[i]['home_work_attachment_file'] = file;     

  // }
  // changeFile2(event, i, name) {

  //     let file: File = event.target.files[0];
  //     console.log(file);    
  //       this.myForm.value.list[i]['class_work_attachment_file'] = file;


  // }
  // changeFile3(event, i, name) {

  //     let file: File = event.target.files[0];
  //     console.log(file);    
  //       this.myForm.value.list[i]['class_activity_attachment_file'] = file;

  // }
  // changeFile4(event,  name) {

  //     let file: File = event.target.files[0];
  //     console.log(file);     
  //       this.myForm.value['newsletter_attachment_file'] = file;          

  // }

  // changeFile5(event,  name) {

  //     let file: File = event.target.files[0];
  //     console.log(file);     
  //       this.myForm.value['monthly_event_attachment_file'] = file;          

  // }


  onSubmit(form: any) {
    this.formData = new FormData();
    let val = form.value;
    let data = {
      "class_id": val.course_id,
      "section_id": val.courseshd_id,
      "student_id": val.student,
      "date": val.date,
      "list": val.list,
      "addtional_remarks": val.addtional_remarks,
      "newsletter_attachment_file": this.uploadNewsLatter,
      "monthly_event_attachment_file": this.uploadMonthlyevent,
      "created_by": localStorage.getItem('UserId'),
      "modified_by": localStorage.getItem('UserId'),
      "tnt_code": localStorage.getItem('TenantCode'),
    }
    val.list.forEach((e, i) => {
      this.formData = new FormData();
      // formData.append('home_work_attachment_file', e.home_work_attachment_file);
      data['home_work_description'] = e.home_work_description;
      data['home_work_submission_date'] = e.home_work_submission_date;
      data['class_work_description'] = e.class_work_description;
      data['class_activity_description'] = e.class_activity_description;
      data['subject_id'] = e.subject_id;
      this.formData.append('data', JSON.stringify(data));
      // formData.append('home_work_attachment_file', this.uploadHomeWork[i]);
      // formData.append('class_work_attachment_file', this.uploadClassWork[i]);
      // formData.append('class_activity_attachment_file', this.uploadAvtivityWork[i]);
      this.formData.append('newsletter_attachment_file', this.uploadNewsLatter);
      this.formData.append('monthly_event_attachment_file', this.uploadMonthlyevent);
      this.formData.append('home_work_attachment_file', this.myForm.value.list[i]['home_work_attachment_file']);
      this.formData.append('class_work_attachment_file', this.myForm.value.list[i]['class_work_attachment_file']);
      this.formData.append('class_activity_attachment_file', this.myForm.value.list[i]['class_activity_attachment_file']);
      // formData.append('newsletter_attachment_file', this.myForm.value.newsletter_attachment_file);
      // formData.append('monthly_event_attachment_file', this.myForm.value.monthly_event_attachment_file);
      this.CommonService.postCall('TimeTable/CreateStudentDailyDiary', this.formData).subscribe((res: any) => {

      })

    });
    window.history.back();
    this.toastr.success("Student Daily Diary saved Successfully");
    // this.CommonService.postCall('TimeTable/CreateStudentDailyDiary', formData).subscribe((res: any) => {
    //   window.history.back();
    //   this.toastr.success("Student Daily Diary saved Successfully");      
    // }) 

  }

  close() {
    window.history.back();
  }

}
