import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from "ngx-spinner";
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MaincontentComponent } from './maincontent/maincontent.component';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { httpInterceptorProviders } from 'src/interceptor';
import { LoginService } from './services/login.service';
import { PolsComponent } from './pages/pols/pols.component';
import { TakepolsComponent } from './pages/takepols/takepols.component';
import { SurveysComponent } from './pages/surveys/surveys.component';
import { TakesurveysComponent } from './pages/takesurveys/takesurveys.component';
import { SubmitassignmentsComponent } from './pages/submitassignments/submitassignments.component';
import { LearningmaterialComponent } from './pages/learningmaterial/learningmaterial.component';
import { PostassessmentComponent } from './pages/postassessment/postassessment.component';
import { JoinconferenceComponent } from './pages/joinconference/joinconference.component';
import { MyresultsComponent } from './pages/myresults/myresults.component';
import { FooterComponent } from './footer/footer.component';
import { DataTablesModule } from 'angular-datatables';
import { StartexamComponent } from './pages/startexam/startexam.component';
import { CountdownModule } from 'ngx-countdown';
import { TimetrackerComponent } from './pages/timetracker/timetracker.component';
import { AssignmentsComponent } from './pages/assignments/assignments.component';
import { AddassignmentComponent } from './pages/addassignment/addassignment.component';
import { EvaluateassignmentsComponent } from './pages/evaluateassignments/evaluateassignments.component';
import { EvaluateassessmentComponent } from './pages/evaluateassessment/evaluateassessment.component';
import { ExamresultComponent } from './pages/examresult/examresult.component';
import { CourcesessionsComponent } from './pages/courcesessions/courcesessions.component';
import { SafePipe } from './pipes/safe.pipe';
import { CreatepolComponent } from './pages/createpol/createpol.component';
import { AddpolComponent } from './pages/addpol/addpol.component';
import { CreatesurveyComponent } from './pages/createsurvey/createsurvey.component';
import { AddsurveyComponent } from './pages/addsurvey/addsurvey.component';
// import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AddsurveyquestionComponent } from './pages/addsurveyquestion/addsurveyquestion.component';
import { CreateFAQsComponent } from './pages/create-faqs/create-faqs.component';
import { FeedbackquestionaireComponent } from './pages/feedbackquestionaire/feedbackquestionaire.component';
import { FeedbackResultsComponent } from './pages/feedback-results/feedback-results.component';
import { SmtpComponent } from './pages/smtp/smtp.component';
import { TaskmanagerComponent } from './pages/taskmanager/taskmanager.component';
import { NewsComponent } from './pages/news/news.component';
import { CourceTypeComponent } from './pages/cource-type/cource-type.component';
import { CourceCategoryComponent } from './pages/cource-category/cource-category.component';
import { CourcesComponent } from './pages/cources/cources.component';
import { EnrollComponent } from './pages/enroll/enroll.component';
import { ChaptersComponent } from './pages/chapters/chapters.component';
import { RegulationsComponent } from './pages/regulations/regulations.component';
import { AttainmentlevelComponent } from './pages/attainmentlevel/attainmentlevel.component';
import { RoomsComponent } from './pages/rooms/rooms.component';
import { AssignMaterialsComponent } from './pages/assign-materials/assign-materials.component';
import { ContentManagerComponent } from './pages/content-manager/content-manager.component';
import { AssessmentQuestionnaireComponent } from './pages/assessment-questionnaire/assessment-questionnaire.component';
import { ScheduleAssessmentComponent } from './pages/schedule-assessment/schedule-assessment.component';
import { MasterAssessmentComponent } from './pages/master-assessment/master-assessment.component';
import { AttendanceComponent } from './pges/attendance/attendance.component';
import { CourceAssignTrainerComponent } from './pges/cource-assign-trainer/cource-assign-trainer.component';
import { CourceScheduleComponent } from './pges/cource-schedule/cource-schedule.component';
import { AddCourceScheduleComponent } from './pges/add-cource-schedule/add-cource-schedule.component';
import { CourceAssignUsersComponent } from './pges/cource-assign-users/cource-assign-users.component';
import { MailBoxComponent } from './pges/mail-box/mail-box.component';
import { ForumComponent } from './pges/forum/forum.component';
import { ForumTopicsComponent } from './pges/forum-topics/forum-topics.component';
import { AddAsseementQuestioniareComponent } from './pages/add-asseement-questioniare/add-asseement-questioniare.component';
import { AddMasterAssessmentComponent } from './pages/add-master-assessment/add-master-assessment.component';
import { CourseshAssigntrainersComponent } from './pages/coursesh-assigntrainers/coursesh-assigntrainers.component';
import { CourseshAssignUsersComponent } from './pages/coursesh-assign-users/coursesh-assign-users.component';
import { CourseProgramOutcomeComponent } from './pages/course-program-outcome/course-program-outcome.component';
import { RoleComponent } from './pages/role/role.component';
import { TaskInRolesComponent } from './pages/task-in-roles/task-in-roles.component';
import { BlogsComponent } from './pages/blogs/blogs.component';
import { AllBlogsComponent } from './pages/all-blogs/all-blogs.component';
import { MyPostsComponent } from './pages/my-posts/my-posts.component';
import { PublishedPostsComponent } from './components/published-posts/published-posts.component';
import { DraftmypostsComponent } from './components/draftmyposts/draftmyposts.component';
import { ViewPostComponent } from './pages/view-post/view-post.component';
import { CommentsComponent } from './components/comments/comments.component'
import { Resolver } from './../app/services/resolve.service'
import { from } from 'rxjs';
import { UserRegistrationComponent } from './pages/user-registration/user-registration.component';
import { AddUserRegistrationComponent } from './pages/add-user-registration/add-user-registration.component';
import { EditUserRegistrationComponent } from './pages/edit-user-registration/edit-user-registration.component';
import { PersonalDataComponent } from './components/userregistration/personal-data/personal-data.component';
import { ContactDetailsComponent } from './components/userregistration/contact-details/contact-details.component';
import { EducationComponent } from './components/userregistration/education/education.component';
import { SkillsComponent } from './components/userregistration/skills/skills.component';
import { ProjectsComponent } from './components/userregistration/projects/projects.component';
import { WorkExperienceComponent } from './components/userregistration/work-experience/work-experience.component';
import { LanguagesComponent } from './components/userregistration/languages/languages.component';
import { AddressComponent } from './components/userregistration/address/address.component';
import { AboutMeComponent } from './components/userregistration/about-me/about-me.component';
import { TenantRegistrationComponent } from './pages/tenant-registration/tenant-registration.component';
import { CreateTenantRegistrationComponent } from './pages/create-tenant-registration/create-tenant-registration.component';
import { ContentAuthoringComponent } from './pages/content-authoring/content-authoring.component';
import { BackupManagerComponent } from './pages/backup-manager/backup-manager.component';
import { OfflinePaymentComponent } from './pages/offline-payment/offline-payment.component';
import { BillingInformationComponent } from './pages/billing-information/billing-information.component';
import { SubjectComponent } from './pages/subject/subject.component';
import { BlockCopyPasteDirective } from './../app/directives/block-copy-paste.directive'
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ChartsModule } from 'ng2-charts';
import { PollResultsComponent } from './pages/poll-results/poll-results.component';
import { DataDictionaryComponent } from './pages/data-dictionary/data-dictionary.component';
import { EventRequestComponent } from './pages/event-request/event-request.component';
import { SubscriptionTaskComponent } from './pages/subscription-task/subscription-task.component';
import { LocationsComponent } from './pages/locations/locations.component';
import { DetailedAssessmentComponent } from './pages/reports/detailed-assessment/detailed-assessment.component';
import { StudentInformationComponent } from './pages/reports/student-information/student-information.component';
import { CourseTrainersDetailsComponent } from './pages/reports/course-trainers-details/course-trainers-details.component';
import { AttendanceDetailsReportComponent } from './pages/reports/attendance-details-report/attendance-details-report.component';
import { CourseInformationReportComponent } from './pages/reports/course-information-report/course-information-report.component';
import { OnlineExamreportComponent } from './pages/reports/online-examreport/online-examreport.component';
import { OnlineCoursesReportComponent } from './pages/reports/online-courses-report/online-courses-report.component';
import { OnlineCoursesDetailsReportComponent } from './pages/reports/online-courses-details-report/online-courses-details-report.component';
import { UserReportComponent } from './pages/reports/user-report/user-report.component';
import { CoursewiseperformanceReportComponent } from './pages/reports/coursewiseperformance-report/coursewiseperformance-report.component';
import { AssessmentResultComponent } from './pages/assessment-result/assessment-result.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TrainingCalendarComponent } from './pages/training-calendar/training-calendar.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import { UploadTemplateComponent } from './pages/upload-template/upload-template.component';
import { MainComponent } from './pages/iscribe/main/main.component';
import { PageComponent } from './pages/iscribe/page/page.component';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { PaintComponent } from './components/paint/paint.component';
import { StudentCgpaComponent } from './pages/reports/student-cgpa/student-cgpa.component';
import { StudentSGPAReportComponent } from './pages/reports/student-sgpa-report/student-sgpa-report.component';
import { TreenodeComponent } from './components/treenode/treenode.component';
import { FolderComponent } from './pages/folder/folder.component';
import { VisualEditorComponent } from './pages/visual-editor/visual-editor.component';
import { SemesterComponent } from './pages/semester/semester.component';
import { StudentAdmissionComponent } from './pages/student-admission/student-admission.component';
import { AddStudentAdmissionComponent } from './pages/add-student-admission/add-student-admission.component';
import { NewTeacherComponent } from './pages/new-teacher/new-teacher.component';
import { AddNewTeacherComponent } from './pages/add-new-teacher/add-new-teacher.component';
import { AcademicYearComponent } from './pages/academic-year/academic-year.component';
import { ComposeMailComponent } from './components/compose-mail/compose-mail.component';
import { ViewmailComponent } from './components/viewmail/viewmail.component';
import { FeesTypeComponent } from './pages/fees-type/fees-type.component';
import { FeeReceivableComponent } from './pages/fee-receivable/fee-receivable.component';
import { FeeDescriptionComponent } from './pages/fee-description/fee-description.component';
import { EditFeesComponent } from './pages/edit-fees/edit-fees.component';
import { CurriculumComponent } from './pages/curriculum/curriculum.component';
import { StudentFeereceivableComponent } from './pages/student-feereceivable/student-feereceivable.component';
import { StudentRegistrationApprovalComponent } from './pages/student-registration-approval/student-registration-approval.component';
import { ContentClassComponent } from './pages/content-class/content-class.component';
import { ContentSubjectComponent } from './pages/content-subject/content-subject.component';
import { ContentChapterComponent } from './pages/content-chapter/content-chapter.component';
import { ContentRepoComponent } from './pages/content-repo/content-repo.component';
import { ContentRepoAddComponent } from './pages/content-repo-add/content-repo-add.component';
import { ContentResourcesComponent } from './pages/content-resources/content-resources.component';
import { BookAllocationReportComponent } from './pages/book-allocation-report/book-allocation-report.component';
import { BookAllocationViewReportComponent } from './pages/book-allocation-view-report/book-allocation-view-report.component';
import { StudentsReportComponent } from './pages/students-report/students-report.component';
import { FetchAssignedBooksComponent } from './pages/fetch-assigned-books/fetch-assigned-books.component';
import { ReturnDateComponent } from './pages/return-date/return-date.component';
import { UpdateReturnDateComponent } from './pages/update-return-date/update-return-date.component';
import { LibraryManagementSystemComponent } from './pages/library-management-system/library-management-system.component';
import { LibraryBooksAllocationComponent } from './pages/library-books-allocation/library-books-allocation.component';
import { ListOfBooksComponent } from './pages/list-of-books/list-of-books.component';
import { DepartmentComponent } from './pages/department/department.component';
import { HolidayNotificationComponent } from './pages/holiday-notification/holiday-notification.component';
import { ViewNewsComponent } from './pages/view-news/view-news.component';
import { ViewDiscussionComponent } from './pages/view-discussion/view-discussion.component';
import { SamvaadMeetingsComponent } from './pages/samvaad-meetings/samvaad-meetings.component';
import { DatePipe } from '@angular/common';
import { MetismenuAngularModule } from "@metismenu/angular";
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { CourseComponent } from './pages/course/course.component';
import { ViewCourseComponent } from './pages/view-course/view-course.component';
import { ClassFeeComponent } from './pages/class-fee/class-fee.component';
import { RoutesComponent } from './pages/routes/routes.component';
import { AlertComponent } from './pages/alert/alert.component';
import { AddstopsComponent } from './pages/addstops/addstops.component';
import { TransportSlabsComponent } from './pages/transport-slabs/transport-slabs.component';
import { OutingDetailsComponent } from './pages/outing-details/outing-details.component';
import { ComplaintsComponent } from './pages/complaints/complaints.component';
import { EarlyPickupComponent } from './pages/early-pickup/early-pickup.component';
import { VisitorMonitoringComponent } from './pages/visitor-monitoring/visitor-monitoring.component';
import { DropComponent } from './pages/drop/drop.component';
import { VehiclesComponent } from './pages/vehicles/vehicles.component';
import { BusMappingComponent } from './pages/bus-mapping/bus-mapping.component';
import { StudentConveyanceComponent } from './pages/student-conveyance/student-conveyance.component';
import { StaffConvenyanceComponent } from './pages/staff-convenyance/staff-convenyance.component';
import { HostelStudentsComponent } from './pages/hostel-students/hostel-students.component';
import { HostelVisitorsComponent } from './pages/hostel-visitors/hostel-visitors.component';
import { TimeTableComponent } from './pages/time-table/time-table.component';
import { FeeTypeComponent } from './pages/fee-type/fee-type.component';
import { ApprovalListComponent } from './pages/approval-list/approval-list.component';
import { StudentTripAttendanceComponent } from './pages/student-trip-attendance/student-trip-attendance.component';
import { VehicleTripAttendanceComponent } from './pages/vehicle-trip-attendance/vehicle-trip-attendance.component';
import { ReportComponent } from './pages/report/report.component';
import { BoardingReportComponent } from './pages/boarding-report/boarding-report.component';
import { VehicleInOutComponent } from './pages/vehicle-in-out/vehicle-in-out.component';
import { DriverHealthComponent } from './pages/driver-health/driver-health.component';
import { VendorsComponent } from './pages/vendors/vendors.component';
import { AssignedPeriodsComponent } from './pages/assigned-periods/assigned-periods.component';
import { DropoffStudentsComponent } from './pages/dropoff-students/dropoff-students.component';
import { DailyDiaryComponent } from './pages/daily-diary/daily-diary.component';
import { SpecificStudentDiaryComponent } from './pages/specific-student-diary/specific-student-diary.component';
import { NewsLetterComponent } from './pages/news-letter/news-letter.component';
import { RoomComponent } from './pages/room/room.component';
import { RoomTypesComponent } from './pages/room-types/room-types.component';
import { ItemsComponent } from './pages/items/items.component';
import { ItemsReportComponent } from './pages/items-report/items-report.component';
import { ReceviedQuotationListComponent } from './pages/recevied-quotation-list/recevied-quotation-list.component';
import { ClassItemSalesComponent } from './pages/class-item-sales/class-item-sales.component';
import { StudentPlacedOrderComponent } from './pages/student-placed-order/student-placed-order.component';
import { EmployeeSalesReportComponent } from './pages/employee-sales-report/employee-sales-report.component';
import { CompusComponent } from './pages/compus/compus.component';
import { AddCompusComponent } from './pages/add-compus/add-compus.component';
import { QuotationsComponent } from './pages/quotations/quotations.component';
import { AddQuotationComponent } from './pages/add-quotation/add-quotation.component';
import { FloorsComponent } from './pages/floors/floors.component';
import { BuldingsComponent } from './pages/block/buldings/buldings.component';
import { StudentSalesComponent } from './pages/student-sales/student-sales.component';
import { HostelItemsComponent } from './pages/hostel-items/hostel-items.component';
import { ConsumptionComponent } from './pages/consumption/consumption.component';
import { ReceviedQuotationComponent } from './pages/recevied-quotation/recevied-quotation.component';
import { CampusSalesComponent } from './pages/campus-sales/campus-sales.component';
import { AddTimeTableComponent } from './pages/add-time-table/add-time-table.component';
import { StaffAttendanceComponent } from './pages/staff-attendance/staff-attendance.component';
import { AddStaffAttendanceComponent } from './pages/add-staff-attendance/add-staff-attendance.component';
import { AttendanceReportEmsComponent } from './pages/attendance-report-ems/attendance-report-ems.component';
import { HostelRoomComponent } from './pages/hostel-room/hostel-room.component';
import { AddStudentAttendanceComponent } from './pages/add-student-attendance/add-student-attendance.component';
import { StudentAttendanceComponent } from './pages/student-attendance/student-attendance.component';
import { AssignDriverComponent } from './pages/assign-driver/assign-driver.component';
import { AddClientDetailsComponent } from './pages/add-client-details/add-client-details.component';
import { ClientsListComponent } from './pages/clients-list/clients-list.component';
import { EditTimeTableComponent } from './pages/edit-time-table/edit-time-table.component';
import { VendorPaymentsComponent } from './pages/vendor-payments/vendor-payments.component';
import { ReceiveItemsComponent } from './pages/receive-items/receive-items.component';
import { IssueItemsComponent } from './pages/issue-items/issue-items.component';
import { ReturnItemsComponent } from './pages/return-items/return-items.component';
import { TripTimingsComponent } from './pages/trip-timings/trip-timings.component';
import { VehicleServiceComponent } from './pages/vehicle-service/vehicle-service.component';
import { AddStudentDiaryComponent } from './pages/add-student-diary/add-student-diary.component';
import { ViewStudentDiaryComponent } from './pages/view-student-diary/view-student-diary.component';
import { EditTimeTableNewComponent } from './pages/time-table/edit-time-table-new/edit-time-table-new.component';
import { EmployeeSalesComponent } from './pages/employee-sales/employee-sales.component';
import { ClassItemComponent } from './pages/class-item/class-item.component';
import { StudentSalesReportsComponent } from './pages/student-sales-reports/student-sales-reports.component';
import { SuperAdminCreationComponent } from './pages/super-admin-creation/super-admin-creation.component';
import { CreateSuperAdminComponent } from './pages/create-super-admin/create-super-admin.component';
import { TenantPaymentsComponent } from './pages/tenant-payments/tenant-payments.component';
import { FineComponent } from './pages/fine/fine.component';
import { LeaveTypesComponent } from './pages/leave-types/leave-types.component';
import { LeaveAllotmentComponent } from './pages/leave-allotment/leave-allotment.component';
import { LeaveApplicationComponent } from './pages/leave-application/leave-application.component';
import { StudentLeavesComponent } from './pages/student-leaves/student-leaves.component';
import { ParentAlertComponent } from './pages/parent-alert/parent-alert.component';
import { SmsStatusComponent } from './pages/sms-status/sms-status.component';
import { EmailStatusComponent } from './pages/email-status/email-status.component';
import { CircularAlertComponent } from './pages/circular-alert/circular-alert.component';
import { StaffAttendanceReportComponent } from './pages/staff-attendance-report/staff-attendance-report.component';
import { StaffDailyAttendanceComponent } from './pages/staff-daily-attendance/staff-daily-attendance.component';
import { StudentAttendanceReportComponent } from './pages/student-attendance-report/student-attendance-report.component';
import { ClassesComponent } from './pages/classes/classes.component';
import { ExamMarksComponent } from './pages/exam-marks/exam-marks.component';
import { FeesComponent } from './pages/fees/fees.component';
import { HealthComponent } from './pages/health/health.component';
import { InstitutionsComponent } from './pages/institutions/institutions.component';
import { InventoryComponent } from './pages/inventory/inventory.component';
import { LeavesComponent } from './pages/leaves/leaves.component';
import { LibraryComponent } from './pages/library/library.component';
import { QuotationsReportComponent } from './pages/quotations-report/quotations-report.component';
import { StudentComponent } from './pages/student/student.component';
import { EmployeesComponent } from './pages/employees/employees.component';
import { CampusReportComponent } from './pages/campus-report/campus-report.component';
import { StaffComponent } from './pages/staff/staff.component';
import { StudentsComponent } from './pages/students/students.component';
import { TransportComponent } from './pages/transport/transport.component';
import { VendorPaymentsReportComponent } from './pages/vendor-payments-report/vendor-payments-report.component';
import { ClassListComponent } from './pages/class-list/class-list.component';
import { DefineTemplateComponent } from './pages/define-template/define-template.component';
import { GiveConcessionComponent } from './pages/give-concession/give-concession.component';
import { StaffIdcardHistorylistComponent } from './pages/staff-idcard-historylist/staff-idcard-historylist.component';
import { StudentIdcardHistroylistComponent } from './pages/student-idcard-histroylist/student-idcard-histroylist.component';
import { TemplatesListComponent } from './pages/templates-list/templates-list.component';
import { EmployeeIdcardComponent } from './pages/employee-idcard/employee-idcard.component';
import { ParentIdcardComponent } from './pages/parent-idcard/parent-idcard.component';
import { StudentIdcardComponent } from './pages/student-idcard/student-idcard.component';
import { PrintStudentIdcardComponent } from './pages/print-student-idcard/print-student-idcard.component';
import { PrintParentIdcardComponent } from './pages/print-parent-idcard/print-parent-idcard.component';
import { PrintEmployeeIdcardComponent } from './pages/print-employee-idcard/print-employee-idcard.component';
import { ItemCategoryComponent } from './pages/item-category/item-category.component';
import { ItemUnitComponent } from './pages/item-unit/item-unit.component';
import { DynamicLabelingComponent } from './pages/dynamic-labeling/dynamic-labeling.component';
import { BusFeeReceiptComponent } from './pages/bus-fee-receipt/bus-fee-receipt.component';
import { BonafiedCertificateComponent } from './pages/bonafied-certificate/bonafied-certificate.component';
import { FeeReceiptComponent } from './pages/fee-receipt/fee-receipt.component';
import { CharacterCertificateComponent } from './pages/character-certificate/character-certificate.component';
import { TransferCertificateComponent } from './pages/transfer-certificate/transfer-certificate.component';
import { AdditionalDetailsComponent } from './pages/additional-details/additional-details.component';
import { AssignPrivilegesComponent } from './pages/assign-privileges/assign-privileges.component';
import { CategoriesListComponent } from './pages/categories-list/categories-list.component';
import { EventsListComponent } from './pages/events-list/events-list.component';
import { AddEventComponent } from './pages/add-event/add-event.component';
import { EventRegistrationComponent } from './pages/event-registration/event-registration.component';
import { EventRegistrationsListComponent } from './pages/event-registrations-list/event-registrations-list.component';
import { EventParticipantsListComponent } from './pages/event-participants-list/event-participants-list.component';
import { AddEventParticipantsComponent } from './pages/add-event-participants/add-event-participants.component';
import { FaculityFeedbackComponent } from './pages/faculity-feedback/faculity-feedback.component';
import { HodFeedbackComponent } from './pages/hod-feedback/hod-feedback.component';
import { FacultyFeedbackListComponent } from './pages/faculty-feedback-list/faculty-feedback-list.component';
import { IdcardTemplate2Component } from './pages/idcard-template2/idcard-template2.component';
import { IdcardTemplate3Component } from './pages/idcard-template3/idcard-template3.component';
import { ParentIdcardTemplate2Component } from './pages/parent-idcard-template2/parent-idcard-template2.component';
import { ParentIdcardTemplate3Component } from './pages/parent-idcard-template3/parent-idcard-template3.component';
import { EmployeeIdcardTemplate3Component } from './pages/employee-idcard-template3/employee-idcard-template3.component';
import { EmployeeIdcardTemplate2Component } from './pages/employee-idcard-template2/employee-idcard-template2.component';
import { ContentLogComponent } from './pages/content-log/content-log.component';
import { ScheduleFeedbackComponent } from './pages/schedule-feedback/schedule-feedback.component';
import { NitIdcardTemplateComponent } from './pages/nit-idcard-template/nit-idcard-template.component';
import { CollegeFeedbackComponent } from './pages/college-feedback/college-feedback.component';
import { CollegeFeedbackListComponent } from './pages/college-feedback-list/college-feedback-list.component';
import { CopoAssessmentComponent } from './pages/copo-assessment/copo-assessment.component';
import { AssignMarksComponent } from './pages/assign-marks/assign-marks.component';
import { CollageFeedbackListComponent } from './pages/collage-feedback-list/collage-feedback-list.component';
import { QuestionsFeedbackListComponent } from './pages/questions-feedback-list/questions-feedback-list.component';
import { DynamicHeadingComponent } from './pages/dynamic-heading/dynamic-heading.component';
import { PromotionComponent } from './pages/promotion/promotion.component';
import { AddDynamicLabelsComponent } from './pages/add-dynamic-labels/add-dynamic-labels.component';
import { AddDynamicHeadingsComponent } from './pages/add-dynamic-headings/add-dynamic-headings.component';
import { CopoReportsComponent } from './pages/copo-reports/copo-reports.component';
import { EnquiryComponent } from './pages/enquiry/enquiry.component';
import { ConsolidatedMarkSheetComponent } from './pages/consolidated-mark-sheet/consolidated-mark-sheet.component';
import { CasteComponent } from './pages/caste/caste.component';
import { IssueInformationComponent } from './pages/issue-information/issue-information.component';
import { BookRequestComponent } from './pages/book-request/book-request.component';
import { StudentBookRequestComponent } from './pages/student-book-request/student-book-request.component';
import { BookMastersComponent } from './pages/book-masters/book-masters.component';
import { SubChaptersComponent } from './pages/sub-chapters/sub-chapters.component';
import { OverallReportComponent } from './pages/overall-report/overall-report.component';
import { BarcodeGenerateComponent } from './pages/barcode-generate/barcode-generate.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { CasteCategoryComponent } from './pages/caste-category/caste-category.component';
import { SearchPipePipe } from './services/search-pipe.pipe';
import { BookCategoryComponent } from './pages/book-category/book-category.component';
import { BookRackComponent } from './pages/book-rack/book-rack.component';
import { BookShelfComponent } from './pages/book-shelf/book-shelf.component';
import { StudentDiaryComponent } from './pages/student-diary/student-diary.component';
import { UpdateRackCellsComponent } from './pages/update-rack-cells/update-rack-cells.component';
import { BookIssueReturnReportComponent } from './pages/book-issue-return-report/book-issue-return-report.component';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

export function momentAdapterFactory() {
  return adapterFactory(moment);
};
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    MaincontentComponent,
    LoginComponent,
    HomeComponent,
    PolsComponent,
    TakepolsComponent,
    SurveysComponent,
    TakesurveysComponent,
    SubmitassignmentsComponent,
    LearningmaterialComponent,
    PostassessmentComponent,
    JoinconferenceComponent,
    MyresultsComponent,
    FooterComponent,
    StartexamComponent,
    TimetrackerComponent,
    AssignmentsComponent,
    AddassignmentComponent,
    EvaluateassignmentsComponent,
    EvaluateassessmentComponent,
    ExamresultComponent,
    CourcesessionsComponent,
    SafePipe,
    CreatepolComponent,
    AddpolComponent,
    CreatesurveyComponent,
    AddsurveyComponent,
    AddsurveyquestionComponent,
    CreateFAQsComponent,
    FeedbackquestionaireComponent,
    FeedbackResultsComponent,
    SmtpComponent,
    TaskmanagerComponent,
    NewsComponent,
    CourceTypeComponent,
    CourceCategoryComponent,
    CourcesComponent,
    EnrollComponent,
    ChaptersComponent,
    RegulationsComponent,
    AttainmentlevelComponent,
    RoomsComponent,
    AssignMaterialsComponent,
    ContentManagerComponent,
    AssessmentQuestionnaireComponent,
    ScheduleAssessmentComponent,
    MasterAssessmentComponent,
    AttendanceComponent,
    CourceAssignTrainerComponent,
    CourceScheduleComponent,
    AddCourceScheduleComponent,
    CourceAssignUsersComponent,
    MailBoxComponent,
    ForumComponent,
    ForumTopicsComponent,
    AddAsseementQuestioniareComponent,
    AddMasterAssessmentComponent,
    CourseshAssigntrainersComponent,
    CourseshAssignUsersComponent,
    CourseProgramOutcomeComponent,
    RoleComponent,
    TaskInRolesComponent,
    BlogsComponent,
    AllBlogsComponent,
    MyPostsComponent,
    PublishedPostsComponent,
    DraftmypostsComponent,
    ViewPostComponent,
    CommentsComponent,
    UserRegistrationComponent,
    AddUserRegistrationComponent,
    EditUserRegistrationComponent,
    PersonalDataComponent,
    ContactDetailsComponent,
    EducationComponent,
    SkillsComponent,
    ProjectsComponent,
    WorkExperienceComponent,
    LanguagesComponent,
    AddressComponent,
    AboutMeComponent,
    TenantRegistrationComponent,
    CreateTenantRegistrationComponent,
    ContentAuthoringComponent,
    BackupManagerComponent,
    OfflinePaymentComponent,
    BillingInformationComponent,
    SubjectComponent,
    BlockCopyPasteDirective,
    PollResultsComponent,
    DataDictionaryComponent,
    EventRequestComponent,
    SubscriptionTaskComponent,
    LocationsComponent,
    DetailedAssessmentComponent,
    StudentInformationComponent,
    CourseTrainersDetailsComponent,
    AttendanceDetailsReportComponent,
    CourseInformationReportComponent,
    OnlineExamreportComponent,
    OnlineCoursesReportComponent,
    OnlineCoursesDetailsReportComponent,
    UserReportComponent,
    CoursewiseperformanceReportComponent,
    AssessmentResultComponent,
    TrainingCalendarComponent,
    UploadTemplateComponent,
    MainComponent,
    PageComponent,
    ColorPickerComponent,
    PaintComponent,
    StudentCgpaComponent,
    StudentSGPAReportComponent,
    TreenodeComponent,
    FolderComponent, VisualEditorComponent,
    SemesterComponent,
    StudentAdmissionComponent,
    AddStudentAdmissionComponent,
    NewTeacherComponent,
    AddNewTeacherComponent,
    AcademicYearComponent,
    ComposeMailComponent,
    ViewmailComponent,
    FeesTypeComponent,
    FeeReceivableComponent,
    FeeDescriptionComponent,
    EditFeesComponent,
    CurriculumComponent,
    StudentFeereceivableComponent,
    StudentRegistrationApprovalComponent,
    ContentClassComponent,
    ContentSubjectComponent,
    ContentChapterComponent,
    ContentRepoComponent,
    ContentRepoAddComponent,
    ContentResourcesComponent,
    ContentResourcesComponent,
    BookAllocationReportComponent,
    BookAllocationViewReportComponent,
    StudentsReportComponent,
    FetchAssignedBooksComponent,
    ReturnDateComponent,
    UpdateReturnDateComponent,
    LibraryManagementSystemComponent,
    ListOfBooksComponent,
    LibraryBooksAllocationComponent,
    DepartmentComponent,
    HolidayNotificationComponent,
    ViewNewsComponent,
    ViewDiscussionComponent,
    SamvaadMeetingsComponent,
    CourseComponent,
    ViewCourseComponent,
    ClassFeeComponent,
    RoutesComponent,
    AlertComponent,
    AddstopsComponent,
    TransportSlabsComponent,
    OutingDetailsComponent,
    ComplaintsComponent,
    EarlyPickupComponent,
    VisitorMonitoringComponent,
    DropComponent,
    VehiclesComponent,
    BusMappingComponent,
    StudentConveyanceComponent,
    StaffConvenyanceComponent,
    HostelStudentsComponent,
    HostelVisitorsComponent,
    TimeTableComponent,
    FeeTypeComponent,
    ApprovalListComponent,
    StudentTripAttendanceComponent,
    VehicleTripAttendanceComponent,
    ReportComponent,
    BoardingReportComponent,
    VehicleInOutComponent,
    DriverHealthComponent,
    VendorsComponent,
    AssignedPeriodsComponent,
    DropoffStudentsComponent,
    DailyDiaryComponent,
    SpecificStudentDiaryComponent,
    NewsLetterComponent,
    RoomComponent,
    RoomTypesComponent,
    ItemsComponent,
    ItemsReportComponent,
    BuldingsComponent,
    FloorsComponent,
    CompusComponent,
    AddCompusComponent,
    QuotationsComponent,
    AddQuotationComponent,
    ClassItemSalesComponent,
    EmployeeSalesReportComponent,
    ReceviedQuotationListComponent,
    StudentPlacedOrderComponent,
    StudentSalesComponent,
    HostelItemsComponent,
    ConsumptionComponent,
    ReceviedQuotationComponent,
    CampusSalesComponent,
    AddTimeTableComponent,
    StaffAttendanceComponent,
    AddStaffAttendanceComponent,
    AttendanceReportEmsComponent,
    HostelRoomComponent,
    StudentAttendanceComponent,
    AddStudentAttendanceComponent,
    AssignDriverComponent,
    ClientsListComponent,
    AddClientDetailsComponent,
    EditTimeTableComponent,
    VendorPaymentsComponent,
    ReceiveItemsComponent,
    IssueItemsComponent,
    ReturnItemsComponent,
    TripTimingsComponent,
    VehicleServiceComponent,
    AddStudentDiaryComponent,
    ViewStudentDiaryComponent,
    EditTimeTableNewComponent,
    EmployeeSalesComponent,
    ClassItemComponent,
    StudentSalesReportsComponent,
    SuperAdminCreationComponent,
    CreateSuperAdminComponent,
    TenantPaymentsComponent,
    FineComponent,
    LeaveTypesComponent,
    LeaveAllotmentComponent,
    LeaveApplicationComponent,
    StudentLeavesComponent,
    ParentAlertComponent,
    SmsStatusComponent,
    EmailStatusComponent,
    CircularAlertComponent,
    StaffAttendanceReportComponent,
    StaffDailyAttendanceComponent,
    StudentAttendanceReportComponent,
    ClassesComponent,
    ExamMarksComponent,
    FeesComponent,
    HealthComponent,
    InstitutionsComponent,
    InventoryComponent,
    LeavesComponent,
    LibraryComponent,
    QuotationsReportComponent,
    StudentComponent,
    EmployeesComponent,
    CampusReportComponent,
    StaffComponent,
    StudentsComponent,
    TransportComponent,
    VendorPaymentsReportComponent,
    ClassListComponent,
    DefineTemplateComponent,
    GiveConcessionComponent,
    StaffIdcardHistorylistComponent,
    StudentIdcardHistroylistComponent,
    TemplatesListComponent,
    EmployeeIdcardComponent,
    ParentIdcardComponent,
    StudentIdcardComponent,
    PrintStudentIdcardComponent,
    PrintParentIdcardComponent,
    PrintEmployeeIdcardComponent,
    ItemCategoryComponent,
    ItemUnitComponent,
    DynamicLabelingComponent,
    BusFeeReceiptComponent,
    BonafiedCertificateComponent,
    FeeReceiptComponent,
    CharacterCertificateComponent,
    TransferCertificateComponent,
    AdditionalDetailsComponent,
    AssignPrivilegesComponent,
    CategoriesListComponent,
    EventsListComponent,
    AddEventComponent,
    EventRegistrationComponent,
    EventRegistrationsListComponent,
    EventParticipantsListComponent,
    AddEventParticipantsComponent,
    FaculityFeedbackComponent,
    HodFeedbackComponent,
    FacultyFeedbackListComponent,
    IdcardTemplate2Component,
    IdcardTemplate3Component,
    ParentIdcardTemplate2Component,
    ParentIdcardTemplate3Component,
    EmployeeIdcardTemplate3Component,
    EmployeeIdcardTemplate2Component,
    ContentLogComponent,
    ScheduleFeedbackComponent,
    NitIdcardTemplateComponent,
    CollegeFeedbackComponent,
    CollegeFeedbackListComponent,
    CopoAssessmentComponent,
    AssignMarksComponent,
    CollageFeedbackListComponent,
    QuestionsFeedbackListComponent,
    DynamicHeadingComponent,
    PromotionComponent,
    AddDynamicLabelsComponent,
    AddDynamicHeadingsComponent,
    CopoReportsComponent,
    EnquiryComponent,
    ConsolidatedMarkSheetComponent,
    CasteComponent,
    IssueInformationComponent,
    BookRequestComponent,
    StudentBookRequestComponent,
    BookMastersComponent,
    SubChaptersComponent,
    OverallReportComponent,
    BarcodeGenerateComponent,
    PrivacyPolicyComponent,
    CasteCategoryComponent,
    SearchPipePipe,
    BookCategoryComponent,
    BookRackComponent,
    BookShelfComponent,
    StudentDiaryComponent,
    UpdateRackCellsComponent,
    BookIssueReturnReportComponent




  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MetismenuAngularModule, PerfectScrollbarModule,
    HttpClientModule, FormsModule, ReactiveFormsModule, DataTablesModule, CountdownModule, BrowserAnimationsModule, NgxSpinnerModule,
    ToastrModule.forRoot(), NgMultiSelectDropDownModule.forRoot(), AngularEditorModule, ChartsModule, DataTablesModule, NgbModule, CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory })
  ],
  providers: [LoginService, httpInterceptorProviders, Resolver, DatePipe,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
// {provide : LocationStrategy , useClass: HashLocationStrategy}