import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { async } from 'rxjs/internal/scheduler/async';
import { dataDictionary } from 'src/app/dataDictionary';
import { EditUserRegistrationComponent } from 'src/app/pages/edit-user-registration/edit-user-registration.component';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit {
  @Input() parent: EditUserRegistrationComponent;
  @Input() editData: any = {};
  changed: boolean = false;
  table: Array<any> = [];
  myForm: FormGroup;
  isEdit: boolean = false;
  index: number;
  addrTypes: Array<any> = [];
  addressCountry: Array<any> = [];
  addressStates: Array<any> = [];
  addressCity: Array<any> = [];
  Address: Array<any> = [];
  labelName: any = {};
  roleId: number;
  headingName: any = {};
  constructor(public CommonService: CommonService, private fb: FormBuilder) {

  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      AddressType: [''],
      Country: [''],
      State: [''],
      City: [''],
      Pincode: [''],
      AddressDetails: ['']

    });
    this.getAll();
    this.getLabels();
    this.loadHeadings();
    this.roleId = +localStorage.getItem('RoleId');
    this.parent.childs['AddressList'] = this
    //console.log(this.parent.childs['AddressList'] = this);
  }
  ngOnChanges() {
    if (Object.keys(this.editData).length && !this.changed) {
      this.changed = true;
      this.dataTransform(this.editData['Address'].slice(0));
    }
  }

  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${localStorage.getItem('regWorkItem')}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;


    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${localStorage.getItem('regWorkItem')}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  dataTransform(data: Array<any>) {
    // this.table = data
    data.map(item => {
      let obj = {
        type: item['TYPE'],
        AddressId: item['ADDRESSID'],
        AddressType: item['ADDRESSTYPE'],
        Country: item['COUNTRY'],
        State: item['STATE'],
        City: item['CITY'],
        Pincode: item['PINCODE'],
        AddressDetails: item['ADDRESSDETAILS']
      }
      this.table.push(obj);
    });
    this.Address = this.table.slice(0);
  }
  getAll() {
    let types = this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.AddressType });//GetAddressType
    let country = this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.Country });
    forkJoin([types, country,]).subscribe((res) => {
      [this.addrTypes, this.addressCountry,] = [...res]
    })

  }
  async onChangeCountry(value) {

    await this.CommonService.postCall('GetChildDictionary', { DictionaryID: this.addressCountry?.find(m => m.DICTIONARYNAME == value).DICTIONARYID }).subscribe({
      next: (res: any) => {
        this.addressStates = res;
      },
      error: (err) => {
        console.log('addressStates error', err);
      },
      complete: () => { }
    }
    )
  }
  async onChangeState(value) {
    await this.CommonService.postCall('GetChildDictionary', { DictionaryID: this.addressStates?.find(m => m.DICTIONARYNAME == value).DICTIONARYID }).subscribe({
      next: (res: any) => {
        this.addressCity = res;
      },
      error: (err) => {
        console.log('addressCity error', err);
      },
      complete: () => { }
    }
    )
  }
  add() {
    this.isEdit = false;
    this.setDefaults()
  }
  setDefaults() {
    let keys = ['AddressType', 'Country', 'State', 'City'];
    let ctrls = this.myForm.controls;
    keys.map((key) => {
      let ctrl = ctrls[key];
      ctrl.setValue('')
    })
  }
  editAddress(data, i) {
    this.index = i;
    this.isEdit = true;
    this.editData = data;
    Promise.all([this.onChangeCountry(this.editData.Country),
    setTimeout(() => {
      this.onChangeState(this.editData.State)
    }, 200)]).then(() => {
      this.assignDataForm(data);
    });
  }
  close() {
    this.myForm.reset();
    this.addressStates = [];
    this.addressCity = [];
  }

  onSubmit(form: FormGroup) {
    let value = form.value;
    if (this.isEdit) {
      if (this.editData.TYPE == 'edit') {
        value.TYPE = 'update'
      } else {
        value.TYPE = 'insert'
      }
      let data = Object.assign({}, value);
      this.table[this.index] = data;
      this.Address.push(data);
    } else {
      value.TYPE = 'insert'
      let data = Object.assign({}, value);
      this.table.push(data);
      this.Address.push(data);
    }
    $('.close').click();
    this.parent.toastr.success('Updated successfully')
  }
  assignDataForm(data: any) {
    let ctrls = this.myForm.controls;
    Object.keys(ctrls).map((formControlName: string) => {
      let control: AbstractControl = ctrls[formControlName];
      // console.log(`${formControlName}=${data[formControlName]}`);
      control.setValue(data[formControlName]);
    })
  }
}
