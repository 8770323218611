<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.AssignDriverToVehicle}}</h5>
                                        <!-- <h5 *ngIf="isEdit" class="mb-0">Edit Quotation</h5> -->
                                    </div>
                                    <div class="card-body">
                                        <div class="custom_container">
                                            <div class="row">
                                                <div class="col-xl-8 offset-xl-2">
                                                    <form class="well form-horizontal" [formGroup]="myForm"
                                                        (ngSubmit)="onSubmit(myForm)">

                                                        <fieldset>
                                                            <!-- <div class="form-group row pt-4">
                                                        <label class="col-md-2 control-label mt-1 ">
                                                            Driver <span class="text-danger">*</span></label>
                                                        <div class="col-md-5 inputGroupContainer">
                                                            <div class="input-group"> <strong>:</strong> &nbsp;
                                                                <select
                                                                    class="form-control form-control-sm shadow-sm rounded-lg"
                                                                    formControlName="driver_id">
                                                                    <option value="0" [selected]="true">select</option>
                                                                    <option *ngFor='let item of driver' value="item.id">
                                                                        {{item.name}}
                                                                    </option>
                                                                </select>
                                                            </div>

                                                        </div>
                                                    </div> -->

                                                            <div class="form-group row pt-4">
                                                                <label class="col-md-4 control-label mt-1 ">
                                                                    {{labelName?.Driver}} <span
                                                                        class="text-danger">*</span></label>
                                                                <div class="col-md-8 inputGroupContainer">
                                                                    <div class="input-group"> <strong>:</strong> &nbsp;
                                                                        <select
                                                                            class="form-select form-select-sm shadow-sm rounded-lg"
                                                                            formControlName="driver_id">
                                                                            <option [value]="" [selected]="true">select
                                                                            </option>
                                                                            <option *ngFor='let item of driver'
                                                                                [value]="item.id">
                                                                                {{item.name}}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                    <div *ngIf="myForm.get('driver_id').touched">
                                                                        <span class="text-danger"
                                                                            *ngIf="myForm.get('driver_id').hasError('required')">
                                                                            This field is required.
                                                                        </span>

                                                                    </div>

                                                                </div>
                                                            </div>


                                                            <div class="form-group row pt-4">
                                                                <label class="col-md-4 control-label mt-1 ">
                                                                    {{labelName?.DriverLicenceNumber}} <span
                                                                        class="text-danger">*</span></label>
                                                                <div class="col-md-8 inputGroupContainer">
                                                                    <div class="input-group"> <strong>:</strong> &nbsp;
                                                                        <input placeholder="Driving Licence Number"
                                                                            class="form-control form-control-sm"
                                                                            formControlName="driving_licence_number"
                                                                            required type="text">
                                                                    </div>
                                                                    <div
                                                                        *ngIf="myForm.get('driving_licence_number')?.touched">
                                                                        <span class="text-danger"
                                                                            *ngIf="myForm.get('driving_licence_number')?.hasError('required')">
                                                                            This field is required.
                                                                        </span>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="form-group row pt-4">
                                                                <label class="col-md-4 control-label mt-1 ">
                                                                    {{labelName?.LicenceExpiryDate}}<span
                                                                        class="text-danger">*</span></label>
                                                                <div class="col-md-8 inputGroupContainer">
                                                                    <div class="input-group"> <strong>:</strong> &nbsp;
                                                                        <input placeholder="Request Date"
                                                                            class="form-control form-control-sm"
                                                                            formControlName="licence_expire_date"
                                                                            type="date">
                                                                    </div>
                                                                    <div
                                                                        *ngIf="myForm.get('licence_expire_date')?.touched">
                                                                        <span class="text-danger"
                                                                            *ngIf="myForm.get('licence_expire_date')?.hasError('required')">
                                                                            This field is required.
                                                                        </span>

                                                                    </div>
                                                                </div>
                                                            </div>



                                                            <div class="form-group row pt-4">
                                                                <label class="col-md-4 control-label mt-1 ">
                                                                    {{labelName?.LicenceDocument}}<span
                                                                        class="text-danger"></span></label>
                                                                <div class="col-md-8 inputGroupContainer">
                                                                    <div class="input-group"> <strong>:</strong> &nbsp;
                                                                        <input (change)='changeFile($event)' type="file"
                                                                            #materialFile id="materialFile1"
                                                                            placeholder="" class="form-control"
                                                                            formControlName="licence_document">



                                                                    </div>
                                                                    <p>{{fileName}}</p>
                                                                </div>

                                                            </div>

                                                            <div class="form-group row pt-4">
                                                                <label class="col-md-4 control-label mt-1 ">
                                                                    {{labelName?.Cleaner}} <span
                                                                        class="text-danger">*</span></label>
                                                                <div class="col-md-8 inputGroupContainer">
                                                                    <div class="input-group"> <strong>:</strong> &nbsp;
                                                                        <select
                                                                            class="form-select form-select-sm shadow-sm rounded-lg"
                                                                            formControlName="cleaner_id">
                                                                            <option [value]="" [selected]="true">select
                                                                            </option>
                                                                            <option *ngFor='let item of cleaner'
                                                                                [value]="item.id">
                                                                                {{item.name}}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                    <div *ngIf="myForm.get('cleaner_id').touched">
                                                                        <span class="text-danger"
                                                                            *ngIf="myForm.get('cleaner_id').hasError('required')">
                                                                            This field is required.
                                                                        </span>

                                                                    </div>
                                                                </div>
                                                            </div>



                                                            <div class="form-group row pt-4">
                                                                <label class="col-md-4 control-label mt-1 ">
                                                                    {{labelName?.StaffMember}}<span
                                                                        class="text-danger"></span></label>
                                                                <div class="col-md-8 inputGroupContainer">
                                                                    <div class="input-group"> <strong>:</strong> &nbsp;

                                                                        <ng-multiselect-dropdown style="width:98%"
                                                                            formControlName="staff_member"
                                                                            [placeholder]="'select Staff '"
                                                                            [settings]="dropdownSettings" [data]="staff"
                                                                            [(ngModel)]='selectedStaff'>
                                                                            <!-- (onSelect)="onItemSelect($any($event.target).value)"
                                                                    (onSelectAll)="onSelectAll($any($event.target).value)" -->
                                                                        </ng-multiselect-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>



                                                            <!-- <div class="form-group row pt-4">
                                                        <label class="col-md-2 control-label mt-1 ">
                                                            Staff Members<span class="text-danger">*</span></label>
                                                        <div class="col-md-5 inputGroupContainer">
                                                            <div class="input-group"> <strong>:</strong> &nbsp;
                                                                <ng-multiselect-dropdown style="width:96%"
                                                                    formControlName="staff_member"
                                                                    [placeholder]="'select Category '"
                                                                    [settings]="dropdownSettings"
                                                                    [data]="staff"
                                                                    (onSelect)="onItemSelect($any($event.target).value)"
                                                                    (onSelectAll)="onSelectAll($any($event.target).value)"
                                                                    [(ngModel)]='selectedStaff'>
                                                                </ng-multiselect-dropdown>
                                                            </div>
                                                        </div>
                                                    </div> -->

                                                            <!-- <div class="form-group row pt-4">
                                                        <label class="col-md-2 control-label mt-1 ">
                                                            Staff Members<span class="text-danger">*</span></label>
                                                        <div class="col-md-5 inputGroupContainer">
                                                            <div class="input-group"> <strong>:</strong> &nbsp;
                                                                <ng-multiselect-dropdown style="width:96%"
                                                                    formControlName="staff_member"
                                                                    [placeholder]="'select Category'"
                                                                    [settings]="dropdownSettings" [data]="staff"(onSelect)="onItemSelect($any($event.target).value)"
                                                                    (onSelectAll)="onSelectAll($any($event.target).value)"
                                                                    [(ngModel)]="selectedStaff">
                                                                </ng-multiselect-dropdown>
                                                            </div>
                                                        </div>
                                                    </div> -->
                                                        </fieldset>
                                                    </form>
                                                    <div class="text-center pt-4">
                                                        <button type="button" (click)="onSubmit(myForm)"
                                                            class="btn btn-sm  btn-save"
                                                            [disabled]="myForm.invalid">Save</button> &nbsp;
                                                        <button type="button" class="btn btn-sm btn-danger"
                                                            class="btn btn-danger  btn-sm" id='md_close'
                                                            (click)="close()">close</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>