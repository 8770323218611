import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr'
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-add-event-participants',
  templateUrl: './add-event-participants.component.html',
  styleUrls: ['./add-event-participants.component.css']
})

export class AddEventParticipantsComponent implements OnInit {
  myForm: FormGroup;
  table: Array<any> = [];
  eventDropdownlist: Array<any> = [];
  labelName: any = {};
  eventId: any;
  isdate: boolean = false;
  isrank: boolean = false;
  isDesable: boolean = false;
  startDate: any = moment(new Date()).format('yyyy-MM-DD');
  endDate: any = moment(new Date()).format('yyyy-MM-DD');
  headingName: any = {};
  workItemId: any;
  constructor(private fb: FormBuilder, private active: ActivatedRoute, private CommonService: CommonService, private toastr: ToastrService, private route: Router) {
    this.active.queryParams.subscribe((res: any) => {
      this.workItemId = res.wId;;
    })
    this.loadEventDropdown();
    this.loadlabels();
    this.loadHeadings();
  }

  ngOnInit(): void {
  }


  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }


  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  loadEventDropdown() {
    this.eventDropdownlist = [];
    this.CommonService.postCall('EmsEventsCategories/EventGetList', { "tnt_code": localStorage.getItem('TenantCode') }).subscribe((res: any) => {
      this.eventDropdownlist = res;
    })
  }

  getParticipants(event: any) {
    this.isDesable = true;
    let eId = event.target.value;
    this.eventId = eId;
    this.CommonService.postCall(`EmsEventsCategories/ParticipantsDrop`, { "event_id": eId }).subscribe((res: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = res;
        this.startDate = moment(res[0].event_from_timestamp).format('yyyy-MM-DD');
        this.endDate = moment(res[0].event_to_timestamp).format('yyyy-MM-DD');
        console.log(this.startDate, this.endDate);
        this.table.forEach(a => { a.isPresent = a.participant_status == "Absent" ? false : true });
        this.table.forEach(a => { a.isRank = a.award_status == "Looser" ? false : true });
      }, 10);
    })
  }

  validateRank(val: any) {
    let num = val.value;
    if (num >= 11 || num <= 0) {
      this.toastr.warning('Please enter rank 1 to 10');
      return val.value = '';
    }
  }


  onSubmit() {

    let participant = [];
    this.table.forEach(e => {
      let obj = {
        "participation_timestamp": e.participation_timestamp,
        "participant_status": e.isPresent == true ? "Present" : "Absent",
        "award_status": e.isRank == true ? "Winner" : "Looser",
        "rank": e.rank,
        "registration_id": e.event_registration_id,
        "ems_event_participants_id": e.ems_event_participants_id
      }
      participant.push(obj);
    })
    let payload = {
      "created_by": localStorage.getItem('UserId'),
      "modified_by": localStorage.getItem('UserId'),
      "tnt_code": localStorage.getItem('TenantCode'),
      "template_id": 0,
      "event_id": this.eventId,
      "participant": participant
    }
    console.log(payload);
    this.CommonService.postCall('EmsEventsCategories/CreateEventsParticipants', payload).subscribe((res: any) => {
      this.route.navigate(['home/eventParticipantsList']);
      this.toastr.success("participant Added Successfully");
    }, err => {
      this.toastr.error(err.error.message ? err.error.message : 'participant Not Added')
    })
  }




  close() {
    this.route.navigate([`home/eventParticipantsList/${this.workItemId}`]);
  }




}
