import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-buldings',
  templateUrl: './buldings.component.html',
  styleUrls: ['./buldings.component.css']
})
export class BuldingsComponent implements OnInit {
  table: Array<any> = [];
  myForm: FormGroup;
  isEdit: boolean = null;
  editData: any = {};
  campusDropdown: Array<any> = [];
  incharge: Array<any> = [];
  loadDesignationDropdown: Array<any> = [];
  tId: string = '';
  labelName: any = {};
  headingName: any = {};
  workItemId: any;
  constructor(private fb: FormBuilder, private CommonService: CommonService, private toastr: ToastrService,private active : ActivatedRoute) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadlabels();
    this.loadBlock();
    this.loadCampusDropdown();
    this.loadDesignation();
    this.loadHeadings();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      "campus_id": ['', Validators.required],
      "block_name": ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      "designation_id": ['', Validators.required],
      "incharge_id": ['', Validators.required],
      "block_status": ['', Validators.required]
    })
  }


  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }


  loadBlock() {
    this.CommonService.postCall('EmsBlock/GetList', { 'tnt_code': localStorage.getItem('TenantCode') }).subscribe((res: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = res;
      }, 10)
    })
  }

  loadCampusDropdown() {
    this.campusDropdown = [];
    this.CommonService.postCall('EmsCampus/CampusDropDownList', { 'tnt_code': localStorage.getItem('TenantCode') }).subscribe((res: any) => {
      this.campusDropdown = res;
    })
  }

  loadDesignation() {
    this.loadDesignationDropdown = [];
    this.CommonService.postCall('GetRolesByTenantCode', { TENANT_CODE: this.tId || localStorage.getItem('TenantCode') }).subscribe((res: any) => {
      this.loadDesignationDropdown = res;
    })
  }
  inCharge(id) {
    let payload = {
      "TenantCode": localStorage.getItem('TenantCode'),
      "objUserinrole": { "RoleId": id }
    }
    this.CommonService.postCall('Registration/UserRolesChange', payload).subscribe((res: any) => {
      this.incharge = res;
    })
  }


  onSubmit(myForm: any) {
    let value: any = myForm.value
    value['created_by'] = localStorage.getItem('UserId');
    value['modified_by'] = localStorage.getItem('UserId');
    value['tnt_code'] = localStorage.getItem('TenantCode');

    if (this.isEdit) {
      value['block_id'] = this.editData.block_id;
      this.CommonService.postCall('EmsBlock/Update', value).subscribe((res: any) => {
        this.loadBlock();
        this.toastr.success("Block Updated Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Block Not Updated')
      })
    } else {

      this.CommonService.postCall('EmsBlock/Create', value).subscribe((res: any) => {
        this.loadBlock();
        this.toastr.success("Block Created Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Block Not created')

      })
    }
  }

  edit(id: any) {
    this.isEdit = true;
    let payload = {
      "block_id": id
    }
    this.CommonService.postCall('EmsBlock/Get', payload).subscribe((res: any) => {
      this.editData = res;
      this.inCharge(this.editData['designation_id']),
        setTimeout(() => {
          this.dataTransfer(res);
        }, 100);
      // Promise.all([
      //   this.inCharge(this.editData['designation_id']),
      // ]).then(() => {
      //   this.dataTransfer(res);
      // })
    })
  }


  dataTransfer(data) {
    this.myForm.patchValue({
      campus_id: this.editData['campus_id'],
      block_name: this.editData['block_name'],
      designation_id: this.editData['designation_id'],
      incharge_id: this.editData['incharge_id'],
      block_status: this.editData['block_status'],
    })
  }

  close() {
    this.inCharge(this.editData['designation_id']);
    this.isEdit = null;
    this.myForm.reset();
    this.editData = {};
  }


}
