import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { forkJoin } from 'rxjs';
import { dataDictionary } from 'src/app/dataDictionary';

@Component({
  selector: 'app-hod-feedback',
  templateUrl: './hod-feedback.component.html',
  styleUrls: ['./hod-feedback.component.css']
})
export class HodFeedbackComponent implements OnInit {
  myForm!: FormGroup;
  table: Array<any> = [];
  courses: Array<any> = [];
  academicYearDropdown: Array<any> = [];
  semesterDropdown: Array<any> = [];

  constructor(private CommonService: CommonService, private fb: FormBuilder, private toastr: ToastrService) {
    this.loadCourse();
    this.getDropdown();
  }

  ngOnInit(): void {
  }
  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  loadCourse() {
    this.activeSpinner();
    this.CommonService.getAdminCourses().subscribe((res: any) => {
      this.courses = res;
      this.deactivateSpinner();
    }, e => {
      this.deactivateSpinner();
    })

  }

  
  getDropdown() {
    this.activeSpinner()
    let academicYear = this.CommonService.getCall(`Academic/GetAcademicDropDownList/${localStorage.getItem('TenantCode')}`);
    let semister = this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.Semester });
    let dropdowns = ['academicYearDropdown', 'semesterDropdown']
    forkJoin([academicYear, semister]).subscribe((res) => {
      dropdowns.map((key, index) => {
        this[key] = res[index]
      });
      this.deactivateSpinner()
    }, err => { this.deactivateSpinner() });
  }

  onSubmit(form: any) {

  }
  close() {

  }
}
