<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0"> {{headingName.StaffIDCard}} </h5>
                                        <!-- <h5 *ngIf="isEdit" class="mb-0">Edit Quotation</h5> -->
                                    </div>
                                    <div class="card-body">
                                        <div class="custom_container">
                                            <div class="row">
                                                <div class="col-xl-8 offset-xl-2">
                                                    <form class="well form-horizontal" [formGroup]="myForm"
                                                        (ngSubmit)="onSubmit1(myForm);onSubmit2(myForm);onSubmit3(myForm)">

                                                        <fieldset>
                                                            <div class="form-group row pt-4">
                                                                <label class="col-md-4 control-label">
                                                                    {{labelName.Designation}} <span class="text-danger">*</span></label>
                                                                <div class="col-md-8 inputGroupContainer">
                                                                    <div class="input-group"> <strong>:</strong> &nbsp;
                                                                        <select class="form-select form-select-sm shadow-sm rounded-lg" formControlName="designation_id" (change)="inCharge($any($event.target).value)">
                                                                         
                                                                             <option value='' [selected]="true">Select</option>
                                                                            <option *ngFor="let item of loadDesignationDropdown" [value]="item.ROLE_ID">{{item.ROLE_NAME}}</option>  
                                                                        </select>
                                                                    </div>
                                                                    <!-- <div *ngIf="myForm.get('designation_id').touched">
                                                                            <span class="text-danger" *ngIf="myForm.get('designation_id').hasError('required')">
                                                                                This field is required.
                                                                            </span>
                                                                        </div> -->
                                                                </div>
                                                            </div>

                                                            <div class="form-group row pt-4">
                                                                <label class="col-md-4 control-label"> {{labelName.InCharge}}<span class="text-danger">*</span></label>
                                                                <div class="col-md-8 inputGroupContainer" *ngIf="ishide">
                                                                    <div class="col-md-8 inputGroupContainer">
                                                                        <div class="form-check"
                                                                        >
                                                                         <input class="form-check-input" type="checkbox"
                                                                             id="defaultCheck1" formControlName="incharge"
                                                                             (change)="checkAll($event)"
                                                                            >
                                                                         Select All
                                                                     </div>

                                                                     <div class="form-check"
                                                                     *ngFor="let item of checkboxs">
                                                                            <input class="form-check-input" type="checkbox"
                                                                                id="defaultCheck1" formControlName="incharge"
                                                                                (change)="changeCheck($event)"
                                                                                [ngModelOptions]="{standalone: true}"
                                                                                [value]="item.USERID"[checked]="item.checked">
                                                                            {{item.FIRSTNAME}}
                                                                        </div>
                                                                    </div>
                                                                </div>
    

    
                                                                    <!-- <div *ngIf="myForm.get('incharge_id').touched">
                                                                        <span class="text-danger" *ngIf="myForm.get('incharge_id').hasError('required')">
                                                                            This field is required.
                                                                        </span>
                                                                    </div> -->
                                                             
                                                            </div>
         
                                                        </fieldset>
                                                    </form>
                                                    <div class="text-center pt-4">
                                                        <button type="button" (click)="onSubmit1(myForm)"
                                                            class="btn btn-sm  btn-save" [disabled]="isDisable"
                                                            [disabled]="myForm.invalid">Template1</button>&nbsp;
                                                            <button type="button" (click)="onSubmit2(myForm)"
                                                            class="btn btn-sm  btn-save" [disabled]="isDisable"
                                                            [disabled]="myForm.invalid">Template2</button>&nbsp;
                                                            <button type="button" (click)="onSubmit3(myForm)"
                                                            class="btn btn-sm  btn-save" [disabled]="isDisable"
                                                            [disabled]="myForm.invalid">Template3</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>