import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-sub-chapters',
  templateUrl: './sub-chapters.component.html',
  styleUrls: ['./sub-chapters.component.css']
})
export class SubChaptersComponent implements OnInit {
  courses: Array<any> = [];
  myForm: FormGroup;
  labelName: any = {};
  courceId: any;
  schedulId: string = ''
  headingName: any = {};
  workItemId: any;
  table: Array<any> = [];
  isEdit: boolean = false;
  editData: any;
  chapters: Array<any> = [];
  shedules: Array<any> = [];
  sheduleId: string = '';
  constructor(private CommonService: CommonService, private active: ActivatedRoute, private fb: FormBuilder, private toastr: ToastrService, private router: Router) {
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    });
    this.loadlabels();
    this.loadHeadings();
    this.load();
    // this.chapterDropdown();
    this.getCourses();

  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      course_id:[''],
      courseshd_id:[''],
      chapter_id: ['', Validators.required],
      sub_chapter_name: ['', Validators.required],
      sub_chapter_description: ['', Validators.required],
      sub_chapter_status: ['', Validators.required]

    })
  }
  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      // console.log(res);
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  getCourses() {
    this.activeSpinner()
    this.CommonService.getAdminCourses().subscribe((res: any) => {
      this.courses = res;
      this.deactivateSpinner()
    }, e => {
      this.deactivateSpinner()
    })
  }

  courseChange(id: any) {
    this.chapters = [];
    this.sheduleId = '';
    let data = {
      "CourseId": id
    }
    this.activeSpinner()
    this.CommonService.getAdminCourseSchedule(data).subscribe((res: any) => {
      this.deactivateSpinner()
      this.shedules = res;
    }, e => { this.deactivateSpinner() })
  }



  sheduleChange(cId: any) {
    this.activeSpinner();
    let payLoad = {
      CHAPTER_CS_ID: cId
    }
    this.CommonService.postCall('LoadChaptersByCourseSchedule', payLoad).subscribe((res: any) => {
      this.chapters = res;
      this.deactivateSpinner();
    }, err => { this.chapters = []; this.deactivateSpinner() })
  }

  // chapterDropdown(){
  //   this.chapters=[];
  //   let payload={
  //     TENANT_CODE:localStorage.getItem('TenantCode')
  //   }
  //   this.CommonService.postCall('LoadchapterGrid', payload).subscribe((res: any) => {
  //     this.chapters = res;
  //   })
  // }
  load() {
    let payload = {
      tnt_code: localStorage.getItem('TenantCode'),
      role_name:localStorage.getItem('RoleName'),
      user_id:localStorage.getItem('UserId')
    }
    this.CommonService.postCall('EmsSubChapters/GetList', payload).subscribe((res: any) => {
      this.table = res;
    })
  }
  add() {
    this.isEdit = false;
  }
  onSubmit(form) {
    let value: any = form.value;
    let payload = {
      "course_id": value.course_id,
      "courseshd_id": value.courseshd_id,
      "sub_chapter_name": value.sub_chapter_name,
      "sub_chapter_description": value.sub_chapter_description,
      "chapter_id": value.chapter_id,
      "sub_chapter_status": value.sub_chapter_status,
      "sub_chapter_createdby": localStorage.getItem('UserId'),
      "sub_chapter_modifiedby": localStorage.getItem('UserId'),
      "tnt_code": localStorage.getItem('TenantCode')
    }

    if (this.isEdit) {
      payload['sub_chapter_id'] = this.editData.sub_chapter_id;
      this.CommonService.postCall('EmsSubChapters/Update', payload).subscribe((res: any) => {
        this.load();
        this.toastr.success("SubChapter Updated Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Book Not Updated')
      })
    } else {

      this.CommonService.postCall('EmsSubChapters/Create', payload).subscribe((res: any) => {
        this.load();
        this.toastr.success("SubChapter Created Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Book Not created')

      })
    }
  }

  edit(subId) {
    this.editData = subId;
    this.isEdit = true;
    this.myForm.reset();
    let payLoad = {
      "sub_chapter_id": subId
    }
    this.CommonService.postCall('EmsSubChapters/GetbyId', payLoad).subscribe((res: any) => {
      this.editData = res;
      this.courseChange(this.editData['course_id']);
      this.sheduleChange(this.editData['courseshd_id'])
      setTimeout(() => {
        this.dataTransForm();
      }, 100) 
  }
  )
}
  dataTransForm() {
    let ctrls = this.myForm.controls
    Object.keys(ctrls).map((key) => {
      let ctrl: AbstractControl = ctrls[key];
      ctrl.setValue(this.editData[key])
    });
    ctrls['sub_chapter_status'].setValue(this.editData['sub_chapter_status']);
    ctrls['course_id'].setValue(this.editData['course_id']);
    ctrls['courseshd_id'].setValue(this.editData['courseshd_id']);
    ctrls['chapter_id'].setValue(this.editData['chapter_id']);
  }
  close() {
    this.isEdit = null;
    this.myForm.reset();
    this.editData = {};
    this.courceId = '';
  }
}
