<div class="page-wrapper">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">

                    <div class="container-fluid">

                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.ClassItemsSalesList}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right mb-2">
                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                                data-bs-target="#myModal">Add Class Items Sales</button>
                                        </div>
                                        <div *ngIf="table.length">
                                            <table id="patreggraph" datatable
                                                class="table table-striped table-bordered mt-2 pt-2 table-sm small display"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624fd7; color:#ffffff;">
                                                    <tr>
                                                        <th> Student Name </th>
                                                        <th> Campus Name </th>
                                                        <th> Class </th>
                                                        <th> Section </th>
                                                        <!-- <th> No of Items</th> -->
                                                        <th> Total amount </th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.student_name}}</td>
                                                        <td>{{item.campus_name }}</td>
                                                        <td>{{item.class_name}}</td>
                                                        <td>{{item.section}}</td>
                                                        <!-- <td></td> -->
                                                        <td>{{item.total_amount}}</td>
                                                        <!-- <tr >
                                                    <td style="display: flex; text-align: center;">
                                                        <a data-bs-toggle="modal" data-bs-target="#myModal"
                                                            (click)="edit()"><i
                                                                class="zmdi zmdi-edit zmdi-hc-2x"> </i></a>
                                                    </td>
                                                </tr> -->
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog  modal-lg">
        <div class="modal-content">

            <div class="modal-header py-2">
                <h4 *ngIf="!isEdit" class="modal-title"> {{headingName.AddClassItemsSales}}</h4>
                <h4 *ngIf="isEdit" class="modal-title"> {{headingName.EditClassItemsSales}}</h4>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal">&times;</button>
            </div>


            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>

                        <div class="form-group row pt-3">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.Campus}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName='campus_id'
                                        (change)="campusChange($any($event).target.value)"
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option value="" *ngFor="let item of campus" [value]='item.campus_id'>
                                            {{item.campus_name}}
                                        </option>
                                    </select>
                                </div>
                                   <div *ngIf="myForm.get('campus_id').touched">
                                    <span class="text-danger" *ngIf="myForm.get('campus_id').hasError('required')">
                                        This field is required.
                                    </span>

                                </div> 
                            </div>
                        </div>
                        <div class="form-group row pt-3">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.Course}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName="course_id"
                                        (change)="courseSchedule($any($event).target.value)"
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" [selected]="true">Select</option>
                                        <option value="" *ngFor="let item of Courses" [value]="item.COURSE_ID">
                                            {{item.COURSE_NAME}}
                                        </option>
                                    </select>
                                </div>
                                 <div *ngIf="myForm.get('course_id').touched">
                                    <span class="text-danger" *ngIf="myForm.get('course_id').hasError('required')">
                                        This field is required.
                                    </span>

                                </div> 
                            </div>
                        </div>


                        <div class="form-group row pt-3">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.CourseSchedule}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName="courseschedule_id"
                                        (change)="scheduleStudent($any($event).target.value)"
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" [selected]="true">Select</option>
                                        <option value="" *ngFor="let item of courseschedule"
                                            [value]="item.COURSESHD_ID">
                                            {{item.COURSESHD_NAME}}
                                        </option>
                                    </select>
                                </div>
                                 <div *ngIf="myForm.get('courseschedule_id').touched">
                                    <span class="text-danger" *ngIf="myForm.get('courseschedule_id').hasError('required')">
                                        This field is required.
                                    </span>
                                </div> 
                            </div>
                        </div>

                        <div class="form-group row pt-3">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.Student}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName="student_id"
                                        (change)="studentTable($any($event).target.value)"
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" [selected]="true">Select</option>
                                        <option value="" *ngFor="let item of student" [value]="item.userid">
                                            {{item.name}}
                                        </option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('student_id').touched">
                                    <span class="text-danger" *ngIf="myForm.get('student_id').hasError('required')">
                                        This field is required.
                                    </span>

                                </div> 
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <table *ngIf="isCampus" id="patreggraph"
                                class=" table-striped table-bordered mt-2 pt-2 table-sm small">
                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                    <tr>
                                        <th>Item </th>
                                        <th> Price </th>
                                        <th> Amount</th>
                                        <th> Quantity</th>
                                         <th>Quantity In Hand</th> 
                                        <th>Issued On</th>
                                        <th> <input type="checkbox" (change)="checkAll($event)"></th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of students let i = index;">
                                        <td>{{item.item_name}}</td>
                                        <td>{{item.total_amount}}</td>
                                        <td>{{item.final_amount}}</td>
                                        <td>{{item.quantity}}</td>
                                        <td>{{item.quantity_hand}}</td>
                                        <td>{{item.issued_on}}</td>
                                        <td><input type="checkbox" name="" id="" [checked]="item.checked"
                                                (change)="checkValue($event,item.final_amount,item.item_id,item.quantity)"></td>

                                        <!-- <td>
                                        <input type="text" (change)="commsQounty($any($event.target).value)" formControlName="qounty" [value]="item.inventory_employee_details_id" >
                                      </td> -->
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="form-group row pt-3">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.Amount}} </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <input placeholder="" class="form-control" formControlName="AMOUNT"
                                        [(ngModel)]="fAmount">
                                </div>
                                <div *ngIf="myForm.get('AMOUNT').touched">
                                    <span class="text-danger" *ngIf="myForm.get('AMOUNT').hasError('required')">
                                        This field is required.
                                    </span>

                                </div> 
                            </div>
                        </div>
                        <div class="form-group row pt-3">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.TotalDiscount}}</label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <input placeholder="" class="form-control" formControlName="TOTAL_DISCOUNT"
                                        (change)="finalDiscount($event)" [(ngModel)]="tDiscount">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-3">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.FinalAmount}}</label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <input placeholder="" class="form-control" formControlName="FINAL_AMOUNT"
                                        [(ngModel)]="tAmount">
                                </div>
                                <div *ngIf="myForm.get('FINAL_AMOUNT').touched">
                                    <span class="text-danger" *ngIf="myForm.get('FINAL_AMOUNT').hasError('required')">
                                        This field is required.
                                    </span>

                                </div> 
                            </div>
                        </div>
                        <div class="form-group row pt-3">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.PaymentType}} <span class="text-danger"></span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName='DROP_ROUTES'
                                    class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="0" selected>Select</option>
                                        <option value=1>Cash</option>
                                    </select>
                                </div>
                            </div>
                        </div>


                    </fieldset>
                </form>

                <div class="modal-footer">
                    <button type="button" *ngIf="!isEdit" class="btn  btn-sm btn-save" (click)="onSubmit(myForm)"
                        [disabled]="myForm.invalid">Save</button>
                    <button type="button" *ngIf="isEdit" class="btn btn-sm btn-save" (click)="onSubmit(myForm)"
                        [disabled]="myForm.invalid">Update</button>
                    <button type="button" class="btn btn-sm btn-danger" id='md_close' (click)="close()"
                        data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>