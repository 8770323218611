<div class="default_class">
    <div class="content-wrapper p-0">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-12">
                    <div class="card rounded shadow-lg">
                        <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                            <h5 class="mb-0 text-center">Teacher Registration</h5>
                        </div>
                        <div class="card-body">
                            <div class="text-right">
                                <button type="button" class="btn btn-primary mb-1" data-toggle="modal"
                                    data-target="#myModal" (click)="add()">Add</button>
                            </div>
                            <div *ngIf="editData.length">
                                <table id="patreggraph" datatable
                                    class=" table-striped table-bordered mt-2 pt-2 table-sm small" style="width:100%">
                                    <thead class="top" style="background-color: #0d9bc4; color:#ffffff;">
                                        <tr>
                                            <th> Teacher ID </th>
                                            <th> Teacher Name</th>
                                            <th>Village Name</th>
                                            <th>Gender</th>
                                            <th>Email</th>
                                            <th>School Name</th>
                                            <!-- <th> Class To Be Taught</th> -->
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of editData">
                                            <td>{{item.TEACHERREG_ID}}</td>
                                            <td>{{item.TEACHER_NAME}}</td>
                                            <td>{{item.VILLAGE_NAME}}</td>
                                            <td>{{item.GENDER_ID}}</td>
                                            <td>{{item.EMAIL}}</td>
                                            <td>{{item.SCHOOL_NAME}}</td>
                                            <!-- <td>{{item.CLASS_TAUGHT}}</td> -->


                                            <td style="display: flex;"> <input type="image"
                                                    src="./../../../assets/images/sprites/edit.svg" class="edit_icon"
                                                    data-toggle="modal" (click)="onEdit(item.TEACHERREG_ID)"
                                                    data-target="#myModal">

                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                            <div class="text-center" *ngIf="!editData.length">
                                No Records to display
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>