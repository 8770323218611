<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.FeedbackQuestionnaire}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right">
                                            <button type="button" class="btn btn-primary mb-1" data-bs-toggle="modal"
                                                data-bs-target="#myModal" (click)="add()">Add</button>
                                        </div>
                                        <div class="form-group ">
                                            <div class="row">
                                                <div *ngIf='isSuperAdmin' class="col-md-3">
                                                    <label for="sel1">Tenant Name : </label>
                                                    <select [(ngModel)]='tId ' (change)="changeTname()"
                                                        class="form-control form-control-sm shadow-sm rounded-lg"
                                                        id="sel1">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let item of tenanates" [value]='item.TNT_CODE'>
                                                            {{item.TNT_NAME}}</option>
                                                    </select>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="pt-4">
                                            <table datatable id="DataTables_Table_0" [dtOptions]="dtOptions"
                                                [dtTrigger]="dtTrigger"
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th>Question </th>
                                                        <th>Answer</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.FEEDBACK_QUESTION}}</td>
                                                        <td>{{item.STATUS}}</td>
                                                        <td style="display: flex;">
                                                            <span data-bs-toggle="modal" data-bs-target="#myModal"
                                                                src="" class="edit_icon" (click)="edit(item)"> <i
                                                                    class="fas fa-pen pt-1 mt-1"></i></span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="modal" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false">
                <div class="modal-dialog ">
                    <div class="modal-content">

                        <!-- Modal Header -->
                        <div class="modal-header py-2">
                            <h6 *ngIf="!isEdit" class=" modal-title w-100"> {{headingName.AddFeedbackQuestionnaire}}</h6>
                            <h6 *ngIf="isEdit" class=" modal-title w-100"> {{headingName.EditFeedbackQuestionnaire}}</h6>
                            <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i class="fas fa-times"></i></button>
                        </div>

                        <!-- Modal body -->
                        <div class="modal-body">
                            <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                                <fieldset>
                                    <div class="form-group row pt-4">
                                        <label class="col-md-4 control-label"> {{labelName.Status}} <span
                                                class="text-danger">*</span></label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group">
                                                <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                    id="sel1" formControlName="QUESTION_STATUS">
                                                    <option value=true>Active</option>
                                                    <option value=false>InActive </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row pt-4">
                                        <label class="col-md-4 control-label line_2 rem_1">
                                            {{labelName.Question}} <span class="text-danger">*</span></label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group">
                                                <textarea placeholder="" class="form-control"
                                                    formControlName="FEEDBACK_QUES"></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="myForm.controls['formArray']">
                                        <div formArrayName='formArray'>

                                            <div class="form-group row pt-4"
                                                *ngFor="let item of myForm.controls['formArray'].controls;let i=index"
                                                [formGroupName]="i">
                                                <label class="col-md-4 control-label">
                                                    {{labelName.Option}} {{i+1}} <span class="text-danger">*</span></label>
                                                <div class="col-md-8 inputGroupContainer">
                                                    <div class="input-group">

                                                        <textarea rows="1" class="form-control"
                                                            formControlName="option"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                            <div class="text-right pt-4">
                                <button class="btn text-success rem_2" (click)="addControl()">+</button> <button
                                    class="btn text-danger rem_2" *ngIf="isVisable()"
                                    (click)="deleteControl()">-</button>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" *ngIf="!isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                                [disabled]="myForm.invalid">Save</button>
                            <button type="button" *ngIf="isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                                [disabled]="myForm.invalid">Update</button>
                            <button type="button" class="btn btn-danger btn-sm" id="md_close" (click)="close()"
                                data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>