<div class="default_class">
    <div class="content-wrapper p-0">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-12">
                    <div class="card rounded shadow-lg">
                        <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                            <h5 class="mb-0 text-center">Student Admissions</h5>
                        </div>
                        <div class="card-body">
                            <div class="text-right">
                                <button type="button" class="btn btn-primary mb-1"  data-toggle="modal"
                                    data-target="#myModal" (click)="add()" >Add</button>      
                            </div>
                    <div *ngIf="editData.length">
                        <table id="patreggraph" datatable
                        class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                        style="width:100%">
                        <thead class="top" style="background-color: #0d9bc4; color:#ffffff;">
                            <tr>
                              <th> Admission Number</th>
                            
                             
                              <th>Date of Admission</th>
                              <th>Firstname	</th>
                             <th>
                                Admission into class</th>
                                <!-- <th>Status</th> -->
                           
                          <th></th>
                          

                            </tr>
                        </thead>
                        <tbody>
                            <tr  *ngFor="let item of editData">
                                <td>{{item.ADMISSION_NO}}</td>
                                
                                <td>{{item.DATE_OF_ADMISSION}}</td>
                                <td>{{item.FIRST_NAME}}</td>
                                <td>{{item.ADMISSION_INTO_CLASS}}</td>
                                <!-- <td>{{item.STATUS_DESCRIPTION}}</td> -->
                             
                                   
                                   <td style="display: flex;"> <input type="image"
                                    src="./../../../assets/images/sprites/edit.svg" class="edit_icon"
                                    data-toggle="modal" (click)="edit(item.student_regid)"
                                    data-target="#myModal">

                            </td>
                            </tr>
                        </tbody>
                       
                    </table>
                </div>
                <div class="text-center" *ngIf="!editData.length">
                    No Records to display
                </div>
            </div>
        </div>
    </div>
</div>
</div>
</div>
</div>