<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.ClassList}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right mb-1">
                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                                data-bs-target="#myModal">Add</button>
                                        </div>
                                        <div class="row">
                                            <div *ngIf='isSuperAdmin' class="col-md-3">
                                                <label for="sel1">{{labelName.TenantName}} : </label>
                                                <select [(ngModel)]='tId ' (change)="GetList()"
                                                    class="form-control form-control-sm shadow-sm rounded-lg"
                                                    id="sel1">
                                                    <option value="" [selected]="true">select</option>
                                                    <option *ngFor="let item of tenanates" [value]='item.TNT_CODE'>
                                                        {{item.TNT_NAME}}</option>
                                                </select>

                                            </div>
                                        </div>
                                        <div *ngIf="table.length">
                                            <table id="patreggraph" datatable
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th>Curriculum Name </th>
                                                        <th>Description</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.CURRICULUM_NAME}}</td>
                                                        <td>{{item.CURRICULUM_DESCRIPTION}}</td>
                                                        <td>{{item.CURRICULUM_STATUS == true ? "Active" : "Inactive"}}
                                                        </td>
                                                        <!-- <td style="display: flex;">
                                                            <span data-bs-toggle="modal" data-bs-target="#myModal"
                                                                src="" class="edit_icon"
                                                                (click)="edit(item.CURRICULUM_ID)">
                                                                <i class="fas fa-pen pt-1 mt-1"></i></span>
                                                        </td> -->
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>





<div class="modal" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog  modal-lg">
        <div class="modal-content">

            <div class="modal-header py-2">
                <h6 *ngIf="!isEdit" class=" modal-title w-100"> {{headingName.AddClassList}}</h6>
                <h6 *ngIf="isEdit" class=" modal-title w-100"> {{headingName.EditClassList}}</h6>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>

            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName.Name}}<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="" class="form-control" formControlName="CURRICULUM_NAME"
                                        onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">
                                </div>

                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">{{labelName.CourseName}} <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select [(ngModel)]='courceId' (change)="courseChange()"
                                        class="form-select form-select-sm shadow-sm rounded-lg"
                                        formControlName="CURRICULUM_COURSE_ID">
                                        <option value="" [selected]="true">select</option>
                                        <option *ngFor="let course of courses" [value]='course.COURSE_ID'>
                                            {{course.COURSE_NAME}}
                                        </option>
                                    </select>
                                </div>

                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">{{labelName.AcademicYear}}<span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                        formControlName="CURRICULUM_ACADEMIC_ID">
                                        <option value="" [selected]="true">select</option>
                                        <option *ngFor="let academic of academics" [value]='academic.ACADEMIC_ID'>
                                            {{academic.ACADEMIC_NAME}}
                                        </option>

                                    </select>

                                </div>

                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName.FeeType}}<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                        formControlName="fee_type" (change)="numofPaments($event)">
                                        <option value="" [selected]="true">select</option>
                                        <option *ngFor="let item of feeTypes" [value]='item.FEETYPE_ID'>
                                            {{item.FEETYPE_NAME}}
                                        </option>

                                    </select>
                                </div>

                            </div>
                        </div>

                        <div class="form-group row pt-4" *ngIf="payments">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName.NumberofPayments}}<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="" class="form-control" 
                                        (change)="onChangePayments($event.target.value)"
                                        formControlName="CURRICULUM_NO_OF_PAYMENTS"
                                        onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">
                                </div>
                                <div *ngIf="myForm.get('CURRICULUM_NO_OF_PAYMENTS').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('CURRICULUM_NO_OF_PAYMENTS').hasError('required')">
                                        This field is required.
                                    </span>
                                    <div class="text-danger" *ngIf="flag">
                                        Not More Than 6 Payments
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4" *ngIf="payments1">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName.NumberofPayments}}<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="1" class="form-control" disabled
                                        formControlName="CURRICULUM_NO_OF_PAYMENTS"
                                        (change)="onChangePayments($event.target.value)">
                                </div>
                                <div *ngIf="myForm.get('CURRICULUM_NO_OF_PAYMENTS').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('CURRICULUM_NO_OF_PAYMENTS').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <!-- <div *ngFor="let item of termList; let i=index;">
                            <div class="form-group row pt-4" *ngIf="payments1" >
                                <label class="col-md-4 control-label line_2 rem_1">
                                    Term {{i+1}}</label>
                                <div class="col-md-8 inputGroupContainer">
                                    <div class="input-group">
                                        <input type="date" placeholder="DD/MM/YYYY" class="form-control"
                                                    [(ngModel)]="item.TERMFEE_FEEDATE"
                                                    [ngModelOptions]="{standalone: true}">
                                    </div>                               
                                </div>
                            </div>
                        </div> -->
                        

                        <div>
                            <div *ngIf="termList.length > 0">

                                <div *ngFor="let item of termList; let i=index;" class="form-group row pt-4">
                                    <label class="col-md-4 control-label line_2 rem_1">
                                        {{labelName.Terms}} {{i+1}}</label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group">
                                            <input type="date" placeholder="DD/MM/YYYY" class="form-control"
                                                [(ngModel)]="item.TERMFEE_FEEDATE"
                                                [ngModelOptions]="{standalone: true}">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div *ngIf="tempArray.length > 0">
                                <div *ngFor="let item of tempArray; let i=index;" class="form-group row">
                                    <label class="col-md-4 control-label line_2 rem_1">
                                        Term {{i+1}}</label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group">
                                            <input type="date" placeholder="DD/MM/YYYY"  class="form-control"
                                                [(ngModel)]="item.TERMFEE_FEEDATE" [ngModelOptions]="{standalone: true}">
                                        </div>
                                    </div>
                                </div>
                            </div > -->
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName.Amount}}</label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input type="number" placeholder="" class="form-control" formControlName="amount">
                                </div>
                            </div>
                        </div>



                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName.Description}}</label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="" class="form-control" formControlName="CURRICULUM_DESCRIPTION">
                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label  line_2 rem_1">{{labelName.Status}} <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                        formControlName="CURRICULUM_STATUS">
                                        <option value="" [selected]="true">Select</option>
                                        <option [ngValue]='true'>Active</option>
                                        <option [ngValue]='false'>InActive </option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('CURRICULUM_STATUS').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('CURRICULUM_STATUS').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>


                    </fieldset>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid || flag">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-sm btn-danger" id='md_close' (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>