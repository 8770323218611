<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">Online Attendance Report</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="form-group ">
                                            <div class="row">
                                                <div class="col-md-3" *ngIf='roleId=="4"'>
                                                    <label for="sel1">Tenant Name : </label>
                                                    <select [(ngModel)]='tId ' (change)="changeTname()"
                                                        class="form-control form-control-sm shadow-sm rounded-lg"
                                                        id="sel1">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let item of tenanates" [value]='item.TNT_CODE'>
                                                            {{item.TNT_NAME}}</option>
                                                    </select>
                                                </div>
                                                <div class="col-md-3">
                                                    <label for="sel1">Course Name : </label>
                                                    <select [(ngModel)]='courseId' (change)="changeCourse()"
                                                        class="form-control form-control-sm shadow-sm rounded-lg"
                                                        id="sel1">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let course of courses"
                                                            [value]='course.COURSE_ID'>
                                                            {{course.COURSE_NAME}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-md-3">
                                                    <label for="sel1"> Batch : </label>
                                                    <select [(ngModel)]='scheduleId'
                                                        class="form-control form-control-sm shadow-sm rounded-lg"
                                                        id="sel1">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let schedule of schedules"
                                                            [value]='schedule.COURSESHD_ID'>
                                                            {{schedule.COURSESHD_NAME}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-md-3">
                                                    <label for="sel1">Start Date : </label>
                                                    <input style="min-height: 38px;"
                                                        class="form-control form-control-sm shadow-sm rounded-lg"
                                                        type="date" [(ngModel)]="sDate">

                                                </div>
                                                <div class="col-md-3">
                                                    <label for="sel1">End Date : </label>
                                                    <input style="min-height: 38px;"
                                                        class="form-control form-control-sm shadow-sm rounded-lg"
                                                        type="date" [(ngModel)]="eDate">

                                                </div>
                                                <div class="col-md-2">
                                                    <label></label><br>
                                                    <button class="btn btn-sm  btn-save"
                                                        [disabled]='!courseId ||!scheduleId||!sDate||!eDate'
                                                        (click)='submit()'>submit </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="table.length">
                                            <table id="patreggraph" datatable [dtOptions]="dtOptions"
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th>S.No</th>
                                                        <th>Student Name</th>
                                                        <th>In Time</th>
                                                        <th>Out Time</th>
                                                        <th>Attendence Status</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table;let i=index">
                                                        <td>{{i+1}}</td>
                                                        <td>{{item.STUDENTNAME}}</td>
                                                        <td>{{item.ATTENDANCE_INTIME | date:"dd/MM/yyyy"}}</td>
                                                        <td>{{item.ATTENDANCE_OUTTIME  | date:"dd/MM/yyyy"}}</td>
                                                        <td>{{item.attendance_status}}</td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>