<br>
<div class="page-wrapper">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.StudentTripList}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <!-- <div class="text-right">
                                <button type="button" class="btn btn-primary mb-1"  data-toggle="modal"
                                    data-target="#myModal" (click)="add()" >Add</button>      
                            </div> -->
                                        <div >
                                            <table id="patreggraph" datatable
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #0d9bc4; color:#ffffff;">
                                                    <tr>
                                                        <!-- <th> USID</th> -->


                                                        <th> ADMN No. </th>
                                                        <th> Class Name </th>
                                                        <th> Section </th>
                                                        <th> Student Name </th>
                                                        <th> Cross Board </th>
                                                        <th> Bus </th>
                                                        <th> Route Name </th>
                                                        <th> Stop Name </th>
                                                        <th> Trip Type </th>
                                                        <th> Area </th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngIf="let item of table">
                                                        <td>{{item.admission_no}}</td>
                                                        <td>{{item.class_name}}</td>
                                                        <td>{{item.section}}</td>
                                                        <td>{{item.student_name}}</td>
                                                        <td>{{item.crossboard }}</td>
                                                        <td>{{item.bus_no}}</td>
                                                        <td>{{item.route_name}}</td>
                                                        <td>{{item.stop_name}}</td>
                                                        <td>{{item.trip_type}}</td>
                                                        <td>{{item.area}}</td>                                                        
                                                    </tr>
                                                </tbody>

                                            </table>
                                        </div>
                                        <!-- <div class="text-center">
                                            No Records to display
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>