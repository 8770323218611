<br>
<div class="page-wrapper">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">Add Dynamic Labeling</h5>
                                    </div>
                                    <div class="card-body">   
                                        <form class="well form-horizontal" [formGroup]="myForm"
                                            (ngSubmit)="onSubmit(myForm)">
                                            <fieldset>
                                                <div class="form-group row">
                                                    <label class="col-md-4 control-label line_2 rem_1">
                                                        Modules <span class="text-danger">*</span></label>
                                                    <div class="col-md-8 inputGroupContainer">
                                                        <div class="input-group">
                                                            <strong>:</strong> &nbsp;
                                                            <select
                                                                class="form-select form-select-sm shadow-sm rounded-lg"
                                                                formControlName="madule"
                                                                (change)="modulesChange($event)">
                                                                <option value="" [selected]="true">select</option>
                                                                <option *ngFor="let item of modules"
                                                                    [value]='item.TaskId'>
                                                                    {{item.TaskName}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div *ngIf="myForm.get('madule').touched">
                                                            <span class="text-danger"
                                                                *ngIf="myForm.get('madule').hasError('required')">
                                                                This field is required.
                                                            </span>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group row pt-4">
                                                    <label class="col-md-4 control-label line_2 rem_1">
                                                        WorkItems <span class="text-danger">*</span></label>
                                                    <div class="col-md-8 inputGroupContainer">
                                                        <div class="input-group">
                                                            <strong>:</strong> &nbsp;
                                                            <select name="workItem" id="workItem"
                                                                formControlName="work_item"
                                                                class="form-select form-select-sm shadow-sm rounded-lg">
                                                                <option value="" [selected]="true">Select</option>
                                                                <option *ngFor="let item of workItems"
                                                                    [value]="item.TaskId">
                                                                    {{item.TaskName}}</option>
                                                            </select>
                                                        </div>
                                                        <div *ngIf="myForm.get('work_item').touched">
                                                            <span class="text-danger"
                                                                *ngIf="myForm.get('work_item').hasError('required')">
                                                                This field is required.
                                                            </span>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div *ngIf="myForm.controls['Labels']">
                                                    <div formArrayName='Labels'>
                                                        <div *ngFor="let item of myForm.controls['Labels'].controls;let i=index"
                                                            [formGroupName]="i">
                                                            <div class="form-group row pt-4">
                                                                <label class="col-md-4 control-label">
                                                                    Label Key <span class="text-danger">*</span></label>
                                                                <div class="col-md-8 inputGroupContainer">
                                                                    <div class="input-group"> <strong>:</strong> &nbsp;
                                                                        <input type="text" placeholder=""
                                                                            class="form-control form-control-sm"
                                                                            formControlName="label_key"
                                                                            onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">
                                                                    </div>
                                                                    <br>

                                                                </div>
                                                            </div>
                                                            <div class="form-group row ">
                                                                <label class="col-md-4 control-label">
                                                                    Label value <span
                                                                        class="text-danger">*</span></label>
                                                                <div class="col-md-8 inputGroupContainer">
                                                                    <div class="input-group"> <strong>:</strong> &nbsp;
                                                                        <input type="text" placeholder=""
                                                                            class="form-control form-control-sm"
                                                                            formControlName="label_value"
                                                                            onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">
                                                                    </div>
                                                                    <br>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </fieldset>
                                        </form>
                                        <div class="row mt-4" >
                                            <div class="col-md-12 ">
                                                <div class="float-end">
                                                    <button class="btn btn-di float-end" *ngIf="isVisable()" (click)="delete()"><i
                                                            class="fas fa-trash"></i></button>
                                                    <button class="btn btn-ai float-end" *ngIf="isAdd()" (click)="add()">Add
                                                        Label</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                                            [disabled]="myForm.invalid">Save</button>
                                        <button type="button" class="btn btn-sm btn-danger" id='md_close'
                                            (click)="close()" data-bs-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>