<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 text-center">Online Exam Questions Details</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="form-group ">
                                            <div class="row">
                                                <div class="col-md-3" *ngIf='roleId=="4"'>
                                                    <label for="sel1">Tenant Name : </label>
                                                    <select [(ngModel)]='tId ' (change)="changeTname()"
                                                        class="form-control form-control-sm shadow-sm rounded-lg"
                                                        id="sel1">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let item of tenanates" [value]='item.TNT_CODE'>
                                                            {{item.TNT_NAME}}</option>
                                                    </select>
                                                </div>
                                                <div class="col-md-3">
                                                    <label for="sel1">Course Name : </label>
                                                    <select [(ngModel)]='courseId'
                                                        class="form-control form-control-sm shadow-sm rounded-lg"
                                                        id="sel1">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let course of courses"
                                                            [value]='course.COURSE_ID'>
                                                            {{course.COURSE_NAME}}
                                                        </option>
                                                    </select>
                                                </div>

                                                <div class="col-md-2">
                                                    <label> </label>
                                                    <button class="btn btn-success" [disabled]='!courseId'
                                                        (click)='submit()'>submit </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="pt-4" *ngIf="table.length">
                                            <table id="patreggraph" datatable [dtOptions]="dtOptions"
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>

                                                        <th> QuestionNo </th>
                                                        <th>Question</th>
                                                        <th>Attempts</th>
                                                        <th>Average Score</th>


                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table;let i=index">
                                                        <td>{{i+1}}</td>
                                                        <td>{{item.COURSE_NAME}}</td>
                                                        <td>{{item.COURSE_DESIGNED_FOR}}</td>
                                                        <td>{{item.COURSE_CREATEDDATE}}</td>
                                                        <td>{{item.ENROLLEDFORCOURSE}}</td>


                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
