<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.Studentleaves1}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div *ngIf="table.length">
                                            <table id="patergraph" datatable
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th>S.No </th>
                                                        <th>Student name</th>
                                                        <th>USID</th>
                                                        <!-- <th>Campus</th> -->
                                                        <th>Class</th>
                                                        <!-- <th>Section</th> -->
                                                        <th>From Date</th>
                                                        <th>To Date</th>
                                                        <!-- <th>Subject</th> -->
                                                        <th>Description</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table;let i = index;">
                                                        <td>{{i+1}}</td>
                                                        <td>{{item.studentname}}</td>
                                                        <td>{{item.userid}}</td>
                                                        <!-- <td>{{item.campus}}</td> -->
                                                        <td>{{item.course_name}}</td>
                                                        <!-- <td>{{item.section}}</td> -->
                                                        <td>{{item.from_date | date : 'dd/MM/yyyy'}}</td>
                                                        <td>{{item.to_date | date : 'dd/MM/yyyy'}}</td>
                                                        <!-- <td>{{item.reason}}</td> -->
                                                        <td>{{item.description}}</td>
                                                        <td>{{item.leaves_status}}</td>
                                                        <td style="display: flex; cursor: pointer;">
                                                            <button *ngIf="item.leaves_status == 'Pending'"
                                                                class="btn btn-warning"
                                                                (click)="changeStatus(item.leaves_id,'Approve')">Approve</button>
                                                            <button *ngIf="item.leaves_status == 'Rejected'"
                                                                class="btn btn-warning"
                                                                (click)="changeStatus(item.leaves_id,'Approve')">Approve</button>
                                                            <button *ngIf="item.leaves_status == 'Approved'"
                                                                class="btn btn-warning"
                                                                (click)="changeStatus(item.leaves_id,'Reject')">Reject</button>
                                                        </td>
                                                    </tr>
                                                </tbody>

                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>