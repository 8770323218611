<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
        <div class="default_class">
            <div class="content-wrapper p-0">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="card rounded shadow-lg">
                            <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                <h5 *ngIf="!isEdit" class="mb-0 text-center">{{headingName.AddClient}}</h5>
                                <h5 *ngIf="isEdit" class="mb-0 text-center">{{headingName.EditClient}}</h5>
                            </div>
                            <div class="card-body">
                                <div class="custom_container">
                                    <form class="well form-horizontal" [formGroup]="myForm"
                                        (ngSubmit)="onSubmit(myForm)">
                                        <fieldset>
                                            <div class="form-group row mb-3">
                                                <label class="col-md-2 control-label line_2 rem_1">
                                                    Slug Name <span class="text-danger">*</span></label>
                                                <div class="col-md-8 inputGroupContainer">
                                                    <div class="input-group">
                                                        <input type="text" placeholder="Client Name"
                                                            class="form-control">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row mb-3">
                                                <label class="col-md-2 control-label line_2 rem_1">
                                                    Reg No <span class="text-danger">*</span></label>
                                                <div class="col-md-8 inputGroupContainer">
                                                    <div class="input-group">
                                                        <input type="text" placeholder="Registration Number"
                                                            class="form-control">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row mb-3">
                                                <label class="col-md-2 control-label line_2 rem_1">
                                                    Reg Date<span class="text-danger">*</span></label>
                                                <div class="col-md-8 inputGroupContainer">
                                                    <div class="input-group">
                                                        <input type="text" placeholder="Registration Date"
                                                            class="form-control">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row mb-3">
                                                <label class="col-md-2 control-label line_2 rem_1">
                                                    Display Name <span class="text-danger">*</span></label>
                                                <div class="col-md-8 inputGroupContainer">
                                                    <div class="input-group">
                                                        <input type="text" placeholder="Display Name"
                                                            class="form-control">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row mb-3">
                                                <label class="col-md-2 control-label line_2 rem_1">
                                                    Website </label>
                                                <div class="col-md-8 inputGroupContainer">
                                                    <div class="input-group">
                                                        <input type="text" placeholder="Website" class="form-control">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group row mb-3">
                                                <label class="col-md-2 control-label line_2 rem_1">
                                                    Organization Type <span class="text-danger">*</span></label>
                                                <div class="col-md-2 inputGroupContainer">
                                                    <div class="input-group">
                                                        <input type="checkbox"> &nbsp; Primary
                                                    </div>
                                                </div>
                                                <div class="col-md-2 inputGroupContainer">
                                                    <div class="input-group">
                                                        <input type="checkbox"> &nbsp; Secondary
                                                    </div>
                                                </div>
                                                <div class="col-md-2 inputGroupContainer">
                                                    <div class="input-group">
                                                        <input type="checkbox"> &nbsp; Higher Secondary
                                                    </div>
                                                </div>
                                                <div class="col-md-2 inputGroupContainer">
                                                    <div class="input-group">
                                                        <input type="checkbox"> &nbsp; Undergraduate
                                                    </div>
                                                </div>
                                                <div class="col-md-2 inputGroupContainer">
                                                    <div class="input-group">
                                                        <input type="checkbox"> &nbsp; Postgraduate
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row mb-3">
                                                <label class="col-md-2 control-label line_2 rem_1">
                                                    Boards <span class="text-danger">*</span></label>
                                                <div class="col-md-2 inputGroupContainer">
                                                    <div class="input-group">
                                                        <input type="checkbox"> &nbsp; SSC
                                                    </div>
                                                </div>
                                                <div class="col-md-2 inputGroupContainer">
                                                    <div class="input-group">
                                                        <input type="checkbox"> &nbsp; CBSE
                                                    </div>
                                                </div>
                                                <div class="col-md-2 inputGroupContainer">
                                                    <div class="input-group">
                                                        <input type="checkbox"> &nbsp; ICSE
                                                    </div>
                                                </div>
                                                <div class="col-md-2 inputGroupContainer">
                                                    <div class="input-group">
                                                        <input type="checkbox"> &nbsp; IPE
                                                    </div>
                                                </div>
                                                <div class="col-md-2 inputGroupContainer">
                                                    <div class="input-group">
                                                        <input type="checkbox"> &nbsp; University
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row mb-3">
                                                <label class="col-md-2 control-label line_2 rem_1"> State <span
                                                        class="text-danger">*</span></label>
                                                <div class="col-md-8 inputGroupContainer">
                                                    <div class="input-group">
                                                        <select
                                                            class="form-select form_select_border shadow-sm rounded-lg">
                                                            <option value="">select state</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row mb-3">
                                                <label class="col-md-2 control-label line_2 rem_1">
                                                    District <span class="text-danger">*</span></label>
                                                <div class="col-md-8 inputGroupContainer">
                                                    <div class="input-group">
                                                        <input type="text" placeholder="District" class="form-control">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row mb-3">
                                                <label class="col-md-2 control-label line_2 rem_1">
                                                    City <span class="text-danger">*</span> </label>
                                                <div class="col-md-8 inputGroupContainer">
                                                    <div class="input-group">
                                                        <input type="text" placeholder="City" class="form-control">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row mb-3">
                                                <label class="col-md-2 control-label line_2 rem_1">
                                                    Address <span class="text-danger">*</span> </label>
                                                <div class="col-md-8 inputGroupContainer">
                                                    <div class="input-group">
                                                        <textarea class="form-control" placeholder="Address"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row mb-3">
                                                <label class="col-md-2 control-label line_2 rem_1">
                                                    Phone <span class="text-danger">*</span> </label>
                                                <div class="col-md-8 inputGroupContainer">
                                                    <div class="input-group">
                                                        <input type="text" placeholder="Phone Number"
                                                            class="form-control">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row mb-3">
                                                <label class="col-md-2 control-label line_2 rem_1"> Subscription
                                                    Type <span class="text-danger">*</span></label>
                                                <div class="col-md-8 inputGroupContainer">
                                                    <div class="input-group">
                                                        <select
                                                            class="form-select form_select_border shadow-sm rounded-lg"
                                                            (change)="onLive($event)">
                                                            <option value="0">Select Subscription Type</option>
                                                            <option value="1">Trail Version</option>
                                                            <option value="2">Live Version</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="liveVersion">
                                                <div class="form-group row mb-3">
                                                    <label class="col-md-2 control-label line_2 rem_1"> Subscription
                                                        Mode <span class="text-danger">*</span></label>
                                                    <div class="col-md-8 inputGroupContainer">
                                                        <div class="input-group">
                                                            <select
                                                                class="form-select form_select_border shadow-sm rounded-lg">
                                                                <option value="">Select Subscription Mode</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group row mb-3">
                                                    <label class="col-md-2 control-label line_2 rem_1"> Payment Type
                                                        <span class="text-danger">*</span></label>
                                                    <div class="col-md-8 inputGroupContainer">
                                                        <div class="input-group">
                                                            <select
                                                                class="form-select form_select_border shadow-sm rounded-lg"
                                                                (change)="onChequePay($event)">
                                                                <option value="0">Select Payment Type</option>
                                                                <option value="1">Cash</option>
                                                                <option value="2">Cheque</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group row mb-3">
                                                    <label class="col-md-2 control-label line_2 rem_1">
                                                        Total Amount <span class="text-danger">*</span></label>
                                                    <div class="col-md-8 inputGroupContainer">
                                                        <div class="input-group">
                                                            <input type="text" placeholder="Amount"
                                                                class="form-control">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group row mb-3">
                                                    <label class="col-md-2 control-label line_2 rem_1">
                                                        Paying Amount <span class="text-danger">*</span></label>
                                                    <div class="col-md-8 inputGroupContainer">
                                                        <div class="input-group">
                                                            <input type="text" placeholder="Amount"
                                                                class="form-control">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="chequePayment">
                                                <div class="form-group row mb-3">
                                                    <label class="col-md-2 control-label line_2 rem_1">
                                                        Cheque Number <span class="text-danger">*</span></label>
                                                    <div class="col-md-8 inputGroupContainer">
                                                        <div class="input-group">
                                                            <input type="text" placeholder="Cheque Number"
                                                                class="form-control">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group row mb-3">
                                                    <label class="col-md-2 control-label line_2 rem_1">
                                                        Cheque Date <span class="text-danger">*</span></label>
                                                    <div class="col-md-8 inputGroupContainer">
                                                        <div class="input-group">
                                                            <input type="text" placeholder="Cheque Date"
                                                                class="form-control">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row mb-3">
                                                <label class="col-md-2 control-label line_2 rem_1">
                                                    License Start Date <span class="text-danger">*</span></label>
                                                <div class="col-md-8 inputGroupContainer">
                                                    <div class="input-group">
                                                        <input type="text" placeholder="License Start Date"
                                                            class="form-control">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row mb-3">
                                                <label class="col-md-2 control-label line_2 rem_1">
                                                    License End Date <span class="text-danger">*</span> </label>
                                                <div class="col-md-8 inputGroupContainer">
                                                    <div class="input-group">
                                                        <input type="text" placeholder="License End Date"
                                                            class="form-control">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row mb-3">
                                                <label class="col-md-2 control-label line_2 rem_1">
                                                    Email <span class="text-danger">*</span></label>
                                                <div class="col-md-8 inputGroupContainer">
                                                    <div class="input-group">
                                                        <input type="text" placeholder="Email" class="form-control">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row mb-3">
                                                <label class="col-md-2 control-label line_2 rem_1">
                                                    User Name <span class="text-danger">*</span> </label>
                                                <div class="col-md-8 inputGroupContainer">
                                                    <div class="input-group">
                                                        <input type="text" placeholder="User Name" class="form-control">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group row mb-3">
                                                <label class="col-md-2 control-label line_2 rem_1">
                                                    Allow Franchise </label>
                                                <div class="col-md-8 inputGroupContainer">
                                                    <div class="input-group">
                                                        <input type="checkbox" (change)="clickfranchise($event)">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row mb-3" *ngIf="franchise">
                                                <label class="col-md-2 control-label line_2 rem_1"> Franchise <span
                                                        class="text-danger">*</span></label>
                                                <div class="col-md-8 inputGroupContainer">
                                                    <div class="input-group">
                                                        <select
                                                            class="form-select form_select_border shadow-sm rounded-lg">
                                                            <option value="">Select Franchise</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="container-fluid">
                                                <div class="form-group row mb-3">
                                                    <div class="col-md-12 inputGroupContainer">
                                                        <div class="input-group ">
                                                            <input type="checkbox" [(ngModel)]="masterSelected"
                                                                [ngModelOptions]="{standalone: true}" name="list_name"
                                                                value="m1" (change)="checkUncheckAll()" />&nbsp; &nbsp;
                                                            <label class="control-label line_2 rem_1">
                                                                Select / Deselect All </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <label class="col-sm-2 control-label line_2 rem_1">Modules <span
                                                            class="text-danger">*</span></label>
                                                    <div class="col-sm-10 ">
                                                        <div class="row">
                                                            <div class="col-8 col-sm-3 mt-3 inputGroupContainer"
                                                                *ngFor="let item of checklist">
                                                                <div class="input-group ">
                                                                    <input type="checkbox" [(ngModel)]="item.isSelected"
                                                                        [ngModelOptions]="{standalone: true}"
                                                                        name="list_name" value="{{item.id}}"
                                                                        (change)="isAllSelected()" />&nbsp; &nbsp;
                                                                    <label class="control-label ">{{item.value}}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </fieldset>

                                    </form>
                                    <div class="text-center mt-4">
                                        <button type="button" *ngIf="isEdit" class="btn btn-success"
                                            [disabled]="myForm.invalid" (click)='onSubmit(myForm)'
                                            type="submit">Update</button>
                                        <button type="button" *ngIf="!isEdit" class="btn btn-success"
                                            [disabled]="myForm.invalid" (click)='onSubmit(myForm)'
                                            type="submit">Submit</button>
                                        &nbsp; <button class="btn btn-danger"> Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>