import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-specific-student-diary',
  templateUrl: './specific-student-diary.component.html',
  styleUrls: ['./specific-student-diary.component.css']
})
export class SpecificStudentDiaryComponent implements OnInit {
  myForm: FormGroup;
  table: Array<any> = [];
  studentGetList: Array<any> = [];
  cources: Array<any> = [];
  scheduls: Array<any> = [];
  tnt_code: number;
  courceId: any = '';
  courseshdId: any = '';
  isTable: Boolean = false;
  headingName: any = {};
  workItemId: any;
  labelName: any = {};
  constructor(private route: Router, private fb: FormBuilder, private CommonService: CommonService, private toastr: ToastrService, private active: ActivatedRoute) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    // this.loadStudentDiary();
    this.getCourses();
    this.loadHeadings();
    this.loadlabels();
    this.tnt_code = +localStorage.getItem('TenantCode');
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      course_id: [''],
      courseshd_id: [''],
      status: [''],
      date: [''],
    })
  }

  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }
  getCourses() {
    this.activeSpinner()
    this.CommonService.getAdminCourses().subscribe((res: any) => {
      this.cources = res;
      this.deactivateSpinner()
    }, e => {
      this.deactivateSpinner()
    })
  }

  courceChange(id: any) {
    let data = {
      "CourseId": id
    }
    this.activeSpinner()
    this.CommonService.getCall(`CourseSchedule/GetCourseshdByCourseId/${id}`).subscribe((res: any) => {
      this.deactivateSpinner()
      this.scheduls = res;
    }, e => { this.deactivateSpinner() })
  }


  loadStudentDiary() {
    this.table = [];
    this.CommonService.postCall(`TimeTable/GetStudentDailyDiaryList`, { "tnt_code": localStorage.getItem('TenantCode')}).subscribe((res: any) => {
      this.table = res.filter(e => e != null);
    });
  }

  add() {
    this.route.navigate([`home/studentDiary/${this.workItemId}`]);
  }

  edit(sId: any, date: any, csId) {
    this.route.navigate(['home/viewStudentDiary/view'], { queryParams: { 'sId': sId, 'date': date, 'csId': csId } })
  }

  onSubmit(form: any) {
    let value = form.value;
    this.table = [];
    this.isTable = true;

    let payload = {
      "date": value.date || null,
      "class_id": value.course_id || 0,
      "section_id": value.courseshd_id || 0,
      "daily_diary_status": value.status || null,
      "tnt_code": localStorage.getItem('TenantCode'),
      "ROLE_NAME":localStorage.getItem('RoleName'),
      "USER_ID":localStorage.getItem('UserId')
    }
    this.CommonService.postCall(`TimeTable/GetStudentDailyDiaryList`, payload).subscribe((res: any) => {
      this.table = res;
    })
  }

  changeApprove(sdId: any, status: any, date: any, sectionId: any) {
    let payload = {
      "student_id": sdId,
      "section_id": sectionId,
      "daily_diary_status": status,
      "date": date,
    }
    if (confirm(`Are you sure you want to ${status} Student Daily Diary`)) {
      this.CommonService.postCall(`TimeTable/UpdateStatus`, payload).subscribe((res: any) => {
        this.loadStudentDiary();
        this.toastr.success(`${status} Successfully`);
      })
    }
  }


}
