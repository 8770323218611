<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 *ngIf="!isEdit" class="mb-0">{{headingName.AddCampus}}</h5>
                                        <h5 *ngIf="isEdit" class="mb-0">{{headingName.EditCampus}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="custom_container">
                                            <form class="well form-horizontal" [formGroup]="myForm"
                                                (ngSubmit)="onSubmit(myForm)">

                                                <fieldset>
                                                    <div class="form-group row">
                                                        <label class="col-md-2 offset-md-3 control-label mb-4">
                                                            {{labelName.CampusName}} <span class="text-danger">*</span></label>
                                                        <div class="col-md-4 mb-4 inputGroupContainer">
                                                            <div class="input-group"> <strong>:</strong> &nbsp;
                                                                <input placeholder="Campus Name"
                                                                    class="form-control form-control-sm"
                                                                    onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));"
                                                                    formControlName="campus_name" type="text">
                                                            </div>
                                                            <div *ngIf="myForm.get('campus_name').touched">
                                                                <span class="text-danger"
                                                                    *ngIf="myForm.get('campus_name').hasError('required')">
                                                                    This field is required.
                                                                </span>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label class="col-md-2 offset-md-3 control-label mb-4">
                                                            {{labelName.CampusCode}} <span class="text-danger">*</span></label>
                                                        <div class="col-md-4 mb-4 inputGroupContainer">
                                                            <div class="input-group"> <strong>:</strong> &nbsp;
                                                                <input placeholder="Campus Code"
                                                                    class="form-control form-control-sm"
                                                                    formControlName="campus_code" type="text">
                                                            </div>
                                                            <div *ngIf="myForm.get('campus_code').touched">
                                                                <span class="text-danger"
                                                                    *ngIf="myForm.get('campus_code').hasError('required')">
                                                                    This field is required.
                                                                </span>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label class="col-md-2 offset-md-3 control-label mb-4">
                                                            {{labelName.Campustype}} <span class="text-danger">*</span></label>
                                                        <div class="col-md-4 mb-4 inputGroupContainer">
                                                            <div class="input-group"> <strong>:</strong> &nbsp;
                                                                <select
                                                                    class="form-select form-select-sm shadow-sm rounded-lg"
                                                                    formControlName="campus_type">
                                                                    <option value="" [selected]="true">Select</option>
                                                                    <option value="School">School</option>
                                                                    <option value="Collage">Collage</option>
                                                                    <option value="Hostel">Hostel</option>
                                                                    <option value="Both(School,Collage)">
                                                                        Both(School,Collage)</option>
                                                                    <option value="Both(School,Hostel)">
                                                                        Both(School,Hostel)</option>
                                                                    <option value="Both(Collage,Hostel)">
                                                                        Both(Collage,Hostel)</option>
                                                                    <option value="All">All</option>
                                                                </select>
                                                            </div>
                                                            <div *ngIf="myForm.get('campus_type').touched">
                                                                <span class="text-danger"
                                                                    *ngIf="myForm.get('campus_type').hasError('required')">
                                                                    This field is required.
                                                                </span>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="form-group row">
                                                        <label class="col-md-2 offset-md-3 control-label mb-4">
                                                            {{labelName.Castes}} <span class="text-danger">*</span></label>
                                                        <div class="col-md-4 mb-4 inputGroupContainer">
                                                            <div class="input-group"> <strong>:</strong> &nbsp;
                                                                <select
                                                                    class="form-select form-select-sm shadow-sm rounded-lg"
                                                                    formControlName="caste_id">
                                                                    <option value="" [selected]="true">Select</option>
                                                                    <option [value]="2">SC</option>
                                                                    <option [value]="3">ST</option>
                                                                    <option [value]="4">BC(A)</option>
                                                                    <option [value]="5">BC(B)</option>
                                                                    <option [value]="6">BC(C)</option>
                                                                    <option [value]="7">BC(D)</option>
                                                                    <option [value]="8">OBC</option>
                                                                    <option [value]="9">OC</option>
                                                                </select>
                                                            </div>
                                                            <div *ngIf="myForm.get('caste_id').touched">
                                                                <span class="text-danger"
                                                                    *ngIf="myForm.get('caste_id').hasError('required')">
                                                                    This field is required.
                                                                </span>

                                                            </div>
                                                        </div>
                                                    </div> -->
                                                    <div class="form-group row">
                                                        <label class="col-md-2 offset-md-3 control-label mb-4">
                                                            {{labelName.CampusAddress}} <span class="text-danger">*</span></label>
                                                        <div class="col-md-4 mb-4 inputGroupContainer">
                                                            <div class="input-group"> <strong>:</strong> &nbsp;
                                                                <input placeholder="Campus Address"
                                                                    class="form-control form-control-sm"
                                                                    formControlName="campus_address" type="text">
                                                            </div>
                                                            <div *ngIf="myForm.get('campus_address').touched">
                                                                <span class="text-danger"
                                                                    *ngIf="myForm.get('campus_address').hasError('required')">
                                                                    This field is required.
                                                                </span>

                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div class="form-group row">
                                                        <label class="col-md-2 offset-md-3 control-label mb-4">
                                                            {{labelName.CampusAddressType}} <span
                                                                class="text-danger">*</span></label>
                                                        <div class="col-md-4 mb-4 inputGroupContainer">
                                                            <div class="input-group"> <strong>:</strong> &nbsp;
                                                                <select
                                                                    class="form-select form-select-sm shadow-sm rounded-lg"
                                                                    formControlName="campus_address_type">
                                                                    <option value="" [selected]="true">Select</option>
                                                                    <option value="Owned">Owned</option>
                                                                    <option value="Rented">Rented</option>
                                                                    <option value="Leased">Leased</option>
                                                                </select>
                                                            </div>
                                                            <div *ngIf="myForm.get('campus_address_type').touched">
                                                                <span class="text-danger"
                                                                    *ngIf="myForm.get('campus_address_type').hasError('required')">
                                                                    This field is required.
                                                                </span>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- <div class="form-group row">

                                                        <label class="col-md-2 offset-md-3 control-label mb-4">
                                                            Gender <span class="text-danger">*</span></label>
                                                        <div class="col-md-4 mb-4 inputGroupContainer">
                                                            <div class="input-group"> <strong>:</strong> &nbsp;
                                                                <select  class="form-select form-select-sm shadow-sm rounded-lg"
                                                                    formControlName="classification">
                                                                    <option value="" [selected]="true">Select</option>
                                                                    <option value="1">Male</option>
                                                                    <option value="2">Female</option>
                                                                    <option value="3">Others</option>
                                                                </select>
                                                            </div>
                                                            <div *ngIf="myForm.get('classification').touched">
                                                                <span class="text-danger"
                                                                    *ngIf="myForm.get('classification').hasError('required')">
                                                                    This field is required.
                                                                </span>
                            
                                                            </div>
                                                        </div>
                                                    </div> -->

                                                    <div class="form-group row">
                                                        <label class="col-md-2 offset-md-3 control-label mb-4">
                                                            {{labelName.CampusPhoto}} <span class="text-danger"></span></label>
                                                        <div class="col-md-4 mb-4 inputGroupContainer">
                                                            <div class="input-group"> <strong>:</strong> &nbsp;
                                                                <input (change)='changeFile($event)' type="file"
                                                                    #materialFile id="materialFile1" placeholder=""
                                                                    class="form-control"   formControlName="campus_photo">
                                                                    <!-- <input (change)='changeFile($event)' type="file"
                                                                        #materialFile id="materialFile1" placeholder=""
                                                                        class="form-control"> -->
                                                                <p *ngIf="isEdit">{{fileName}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label class="col-md-2 offset-md-3 control-label mb-4">
                                                            {{labelName.RentalAgreement}} <span class="text-danger"></span></label>
                                                        <div class="col-md-4 mb-4 inputGroupContainer">
                                                            <div class="input-group"> <strong>:</strong> &nbsp;
                                                                <input (change)='changeFile1($event)' type="file"
                                                                    accept="image/png, image/gif, image/jpeg /*"
                                                                    placeholder="" class="form-control form-control-sm"
                                                                    formControlName="rental_agreement">
                                                                    <!-- <input (change)='changeFile1($event)' type="file"
                                                                        #materialFile id="materialFile1" placeholder=""
                                                                        class="form-control"> -->
                                                                <p *ngIf="isEdit">{{fileName1}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label class="col-md-2 offset-md-3 control-label mb-4">
                                                            {{labelName.AreaOfCampus}} <span class="text-danger">*</span></label>
                                                        <div class="col-md-4 mb-4 inputGroupContainer">
                                                            <div class="input-group"> <strong>:</strong> &nbsp;

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-6 offset-md-3">
                                                            <div
                                                                style="    border: 1px solid rgba(0,0,0,.25); padding: 10px;">
                                                                <div class="form-group row">

                                                                    <label class="col-md-3 control-label mb-4">
                                                                        {{labelName.CampusSft }} <span
                                                                            class="text-danger">*</span></label>
                                                                    <div class="col-md-3 mb-4 inputGroupContainer">
                                                                        <div class="input-group"> <strong>:</strong>
                                                                            &nbsp;
                                                                            <input placeholder="Campus Sft"
                                                                                class="form-control form-control-sm"
                                                                                formControlName="campus_sft"
                                                                                type="number">
                                                                        </div>
                                                                        <div *ngIf="myForm.get('campus_sft').touched">
                                                                            <span class="text-danger"
                                                                                *ngIf="myForm.get('campus_sft').hasError('required')">
                                                                                This field is required.
                                                                            </span>

                                                                        </div>

                                                                    </div>

                                                                    <label class="col-md-3 control-label mb-4">
                                                                        {{labelName.ConstructionArea}} <span
                                                                            class="text-danger">*</span></label>
                                                                    <div class="col-md-3 mb-4 inputGroupContainer">
                                                                        <div class="input-group"> <strong>:</strong>
                                                                            &nbsp;
                                                                            <input placeholder="Construction Area"
                                                                                class="form-control form-control-sm"
                                                                                formControlName="construction_area"
                                                                                type="number">
                                                                        </div>
                                                                        <div
                                                                            *ngIf="myForm.get('construction_area').touched">
                                                                            <span class="text-danger"
                                                                                *ngIf="myForm.get('construction_area').hasError('required')">
                                                                                This field is required.
                                                                            </span>

                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                <div class="form-group row">

                                                                    <label class="col-md-3 control-label mb-4">
                                                                        {{labelName.OpenLand}} <span
                                                                            class="text-danger">*</span></label>
                                                                    <div class="col-md-3 mb-4 inputGroupContainer">
                                                                        <div class="input-group"> <strong>:</strong>
                                                                            &nbsp;
                                                                            <input placeholder="Open Land"
                                                                                class="form-control form-control-sm"
                                                                                formControlName="openland"
                                                                                type="number">
                                                                        </div>
                                                                        <div *ngIf="myForm.get('openland').touched">
                                                                            <span class="text-danger"
                                                                                *ngIf="myForm.get('openland').hasError('required')">
                                                                                This field is required.
                                                                            </span>

                                                                        </div>

                                                                    </div>

                                                                    <label class="col-md-3 control-label mb-4">
                                                                        {{labelName.ActivityDisciplines}} </label>
                                                                    <div class="col-md-3 mb-4 inputGroupContainer">
                                                                        <div class="input-group"> <strong>:</strong>
                                                                            &nbsp;
                                                                            <input placeholder="Activity Disciplines"
                                                                                class="form-control form-control-sm"
                                                                                onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));"
                                                                                formControlName="activity_disciplines"
                                                                                type="text">
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-group row pt-4">
                                                        <label class="col-md-2 offset-md-3 control-label mb-4">
                                                            {{labelName.Status}} <span class="text-danger">*</span></label>
                                                        <div class="col-md-4 mb-4 inputGroupContainer">
                                                            <div class="input-group">
                                                                <select
                                                                    class="form-select form-select-sm shadow-sm rounded-lg"
                                                                    formControlName="campus_status">
                                                                    <option value=true>Active</option>
                                                                    <option value=false>InActive </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </fieldset>

                                            </form>

                                            <div>
                                                <div class="text-center pt-4">
                                                    <button type="button" *ngIf="isEdit" class="btn btn-sm  btn-save"
                                                        [disabled]="myForm.invalid" (click)="onSubmit(myForm)"
                                                        type="submit">Update</button>

                                                    <button type="button" *ngIf="!isEdit" class="btn btn-sm  btn-save"
                                                        [disabled]="myForm.invalid" (click)="onSubmit(myForm)"
                                                        type="submit">Save</button>
                                                    &nbsp; <button class="btn btn-danger btn-sm"
                                                        (click)='back()'>Close</button>

                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>