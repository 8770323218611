import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-tenant-payments',
  templateUrl: './tenant-payments.component.html',
  styleUrls: ['./tenant-payments.component.css']
})
export class TenantPaymentsComponent implements OnInit {
  table: Array<any> = [];
  headingName: any = {};
  constructor(private route: Router, private CommonService: CommonService, private toastr: ToastrService) {
    this.getPaymentList();
    this.loadHeadings();
  }

  ngOnInit(): void {
  }


  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/4291/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }
  add() {
    this.route.navigate(['/home/createSuperAdmin/add'])
  }

  getPaymentList() {
    let payLoad = {
    }
    this.activeSpinner();
    this.CommonService.postCall('TenantRegistration/LoadTenantsByPayment', payLoad).subscribe(
      (res: any) => {
        this.deactivateSpinner()
        this.table = res;
      }, err => {
        this.deactivateSpinner()
      }
    )
  }

}
