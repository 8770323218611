import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppComponent } from '../app.component';
import { environment } from './../../environments/environment'
import { constants } from '../constants'
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  assessment: any = {}
  userId = localStorage.getItem('UserId');
  appComponent: AppComponent;
  spinnerCount: number = 0;
  fileUrl: string = environment.fileUrl;
  url = environment.serviceUrl
  public fileObs: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);
  samvaadUrl: string = environment.SamvaadUrl;
  constructor(public http: HttpClient,) {
    if (location.hostname != "localhost") {
      this.url = `${location.origin}/api/`;
      this.fileUrl = `${location.origin}/api`;
    }
  }


  /////////////////////////////////////spinner/////////////////////////////////////////
  activateSpinner() {
    this.spinnerCount++;
    if (this.spinnerCount == 1) {
      this.appComponent.activateSpinner()

    }
  }

  deactivateSpinner() {
    if (!this.spinnerCount) return
    this.spinnerCount--
    if (!this.spinnerCount) this.appComponent.deactivateSpinner();
    if (this.spinnerCount < 0) {
      this.spinnerCount = 0;
      this.appComponent.deactivateSpinner()
    }

  }

  /////////////////////////////////////spinner///////////////////////////////////////

  ///////////////////////////////////post,put and get //////////////////////////////////
  postCall(route, data): Observable<any> {
    let apiUrl = constants[route] || route
    let uri = this.url + apiUrl;
    return this.http.post<any>(uri, data)
  }



  getEditorData(arg0: string, payload: { TENANT_CODE: number; }) {
    let apiUrl = constants[arg0] || arg0
    let finalUrl = this.url + apiUrl;
    console.log(apiUrl)
    return this.http.post(finalUrl, payload);
  }



  getCall(route): Observable<any> {
    let apiUrl = constants[route] || route
    let uri = this.url + apiUrl;
    return this.http.get(uri)
  }
  getCallWithId(route, id): Observable<any> {
    let apiUrl = constants[route] || route
    let uri = this.url + apiUrl;
    return this.http.get(uri + '/' + id)
  }
  putCall(route, data): Observable<any> {
    let apiUrl = constants[route] || route
    let uri = this.url + apiUrl;
    return this.http.put<any>(uri, data)
  }

  getCallWithParams(route, data) {
    let apiUrl = constants[route] || route
    let uri = this.url + apiUrl;
    return this.http.get(uri, { params: data })
  }
  deleteCall(route, data): Observable<any> {
    let apiUrl = constants[route] || route
    let uri = this.url + apiUrl;
    return this.http.delete<any>(uri, { params: data })
  }

  samvaadPost(url, payLoad) {
    let uri = this.samvaadUrl + url;
    return this.http.post(uri, payLoad, { headers: this.getSamvaadHeaders() })
  }
  samvaadGet(url) {
    let uri = this.samvaadUrl + url;
    return this.http.get(uri, { headers: this.getSamvaadHeaders() })
  }
  ///////////////////////////////////post,put and get //////////////////////////////////


  getCourseSchedule(data) {
    data.UserId = this.userId;
    data.RoleId = localStorage.RoleId
    let apiUrl = constants['GetCourseSchedule'] || 'GetCourseSchedule'
    let uri = this.url + apiUrl
    return this.http.post(uri, data)

  }

  getCourses() {
    let apiUrl = constants['GetCourses'] || 'GetCourses'
    let uri = this.url + apiUrl + '/' + this.userId;
    let id = localStorage.RoleId
    return this.http.get(uri + '/' + id)
  }

  ///////get cources by admin//////////// http://localhost:50905/GetAdminCourses/68664158/2/51964213/////////////

  getAdminCourses(tnt_code?) {
    let apiUrl = constants['GetAdminCourses'] || 'GetAdminCourses'
    let uri = this.url + apiUrl + '/' + this.userId;
    let id = localStorage.RoleId
    let code = tnt_code || localStorage.getItem('TenantCode');
    return this.http.get(uri + '/' + id + '/' + code)
  }
  ///////get cources by admin////////////


  /////////////////GetAdminCourseSchedule///////////////////////////////////////////
  getAdminCourseSchedule(data) {
    // data.UserId = this.userId;
    let apiUrl = constants['GetAdminCourseSchedule'] || 'GetAdminCourseSchedule'
    data.RoleId = localStorage.RoleId
    let uri = this.url + apiUrl
    return this.http.post(uri, data)

  }

  /////////////////////my results///////////////

  getResults() {
    let apiUrl = constants['StudentAssessementResult'] || 'StudentAssessementResult'
    let uri = this.url + apiUrl + '/' + this.userId;
    return this.http.get(uri)
  }

  /////////////////////my results///////////////

  //////////////////////////get Post Assessments/////////////////////
  getAssessments(data) {
    data.UserId = this.userId;
    let uri = this.url + 'Assessment/ListOfAssessments'
    return this.http.post(uri, data)
  }
  //////////////////////////get Post Assessments/////////////////////

  //////////////////////////Assignments/////////////////////
  getAssignments(data) {
    let apiUrl = constants['StudentAssingments'] || 'StudentAssingments'
    data.UserId = this.userId;
    let uri = this.url + apiUrl
    return this.http.post(uri, data)
  }
  //////////////////////////Assignments/////////////////////

  joinConference(data) {
    data.UserId = this.userId;
    let apiUrl = constants['VCDetails'] || 'VCDetails'
    let uri = this.url + apiUrl;
    return this.http.post(uri, data)
  }

  /////////////////////////////////takeExam//////////////////////     
  takeExam(data) {
    // let uri = url + 'GetAssessment'
    let apiUrl = constants['GetAssessment'] || 'GetAssessment'
    let uri = this.url + apiUrl;
    return this.http.post(uri, data)
  }

  /////////////////////////////////takeExam//////////////////////     

  ///////////////////////////time tracker////////////////////////
  getTimeTracker() {
    let uri = constants['TimeTracker'] ? constants['TimeTracker'] + '/' + this.userId : ['TimeTracker'] + '/' + this.userId;
    return this.http.get(this.url + uri)
  }
  ///////////////////////////time tracker////////////////////////

  ////////////////////////////get learning material///////////////////////
  getLearningMeterial(data) {
    data.UserId = this.userId;
    let uri = this.url + constants['LearningMeterials'] || 'LearningMeterials'
    return this.http.post(uri, data)
  }

  trackMaterialTime(data) {
    data.UserId = parseInt(this.userId);
    let uri = this.url + constants['TrackMaterialTime'] || 'TrackMaterialTime'
    return this.http.post(uri, data)
  }
  ////////////////////////////get learning material///////////////////////

  /////////////////////////// SetAssessments/////////////////////////////
  setAssessments(data) {
    data.UserId = this.userId;
    // let uri = url + 'SetAssessments'
    let uri = this.url + constants['SetAssessments'] || 'SetAssessments'
    return this.http.post(uri, data)
  }
  /////////////////////////// SetAssessments/////////////////////////////

  ////////////////////////// check answers//////////////////////////////
  checkAnswers(id, saId) {
    let apiUrl = constants['CheckAnswers'] || 'CheckAnswers'
    let uri = this.url + apiUrl + '/' + id + '/' + this.userId + '/' + saId
    return this.http.get(uri)
  }

  ////////////////////////// check answers//////////////////////////////

  //////////////////////////get load assignments///////////////////////
  loadAssignments() {
    let apiUrl = constants['LoadAssignments'] || 'LoadAssignments'
    let uri = this.url + apiUrl;
    let data = {
      ROLE_NAME:localStorage.getItem('RoleName'),
      TenantCode: localStorage.getItem('TenantCode'),
      UserId: this.userId,
      RoleId: localStorage.getItem('RoleId')
    }///localStorage.getItem('RoleId') ||
    return this.http.post(uri, data)

  }
  //////////////////////////get load assignments///////////////////////

  //////////////////////////  GetAssignmentsById////////////////////////
  getAssignmentsById(id) {
    let apiUrl = constants['GetAssignmentsById'] || 'GetAssignmentsById'
    let uri = this.url + apiUrl + '/' + id
    return this.http.get(uri);
  }

  //////////////////////////  GetAssignmentsById////////////////////////

  ///////////////////////////LoadAssessmentDropdown//////////////////////

  loadAssessmentDropdown(id, s_id) {
    let apiUrl = constants['LoadAssessmentDropdown'] || 'LoadAssessmentDropdown'
    let uri = this.url + apiUrl + '/' + id + '/' + s_id;
    return this.http.get(uri)
  }

  ///////////////////////////LoadAssessmentDropdown//////////////////////

  //////////////////////////LoadEvaluateAssignments/////////////////////////
  loadEvaluateAssignments(data) {
    let apiUrl = constants['LoadEvaluateAssignments'] || 'LoadEvaluateAssignments'
    let uri = this.url + apiUrl;
    return this.http.post(uri, data)
  }
  //////////////////////////LoadEvaluateAssignments/////////////////////////


  ////////////////////////SetEvaluateAssignments///////////////////////////////

  setEvaluateAssignments(data) {
    let apiUrl = constants['SetEvaluateAssignments'] || 'SetEvaluateAssignments';
    let uri = this.url + apiUrl;
    data.TenantCode = localStorage.getItem('TenantCode');
    data.UserId = this.userId;
    return this.http.post(uri, data)
  }
  ////////////////////////SetEvaluateAssignments///////////////////////////////

  ////////////////////////GetAssignments drop down/////////////////////////////
  getAssignmentsDropDown(id) {
    let apiUrl = constants['GetAssignments'] || 'GetAssignments';
    let uri = this.url + apiUrl + '/' + id;
    return this.http.get(uri)
  }

  ////////////////////////GetAssignments drop down/////////////////////////////

  ///////////////////////SetAssignments/////////////////////////////////////////
  setAssignments(data) {
    let apiUrl = constants['SetAssignments'] || 'SetAssignments'
    let uri = this.url + apiUrl;
    return this.http.post(uri, data)
  }
  ///////////////////////SetAssignments/////////////////////////////////////////

  //////////////////////////////////assessment/////////////////////////////////

  ///////////////////////////////GetAssessmentTime////////////////////////////
  getAssessmentTime(data) {
    let apiUrl = constants['GetAssessmentTime'] || 'GetAssessmentTime'
    data.TrainerId = this.userId;
    let uri = this.url + apiUrl;
    return this.http.post(uri, data)
  }

  //////////////////////////////GetAssessmentUsers/////////////////////////////

  getAssessmentUsers(data) {
    let apiUrl = constants['GetAssessmentUsers'] || 'GetAssessmentUsers'
    data.TrainerId = this.userId;
    let uri = this.url + apiUrl;
    return this.http.post(uri, data)
  }

  /////////////////////////////////GetAssessmentAnswers//////////////////////

  getAssessmentAnswers(data) {
    let apiUrl = constants['GetAssessmentAnswers'] || 'GetAssessmentAnswers'
    let uri = this.url + apiUrl;
    return this.http.post(uri, data)
  }
  /////////////////////////////////SetEvaluateAssessments//////////////////////

  setEvaluateAssessments(data) {
    let uri = this.url + constants['SetEvaluateAssessments'] || 'SetEvaluateAssessments';
    return this.http.post(uri, data)
  }

  ///////////////////////////GetStudentResult/////////////////////////////////

  getStudentResult(s_id, a_id) {
    let apiUrl = constants['GetStudentResult'] || 'GetStudentResult'
    let uri = this.url + apiUrl + '/' + s_id + '/' + a_id;
    return this.http.get(uri);
  }

  /////////////////////////SetStudentResult/////////////////////////////////
  setStudentResult(data) {
    data.UserId = this.userId;
    data.TenantCode = localStorage.getItem('TenantCode')
    let uri = this.url + constants['SetStudentResult'] || 'SetStudentResult';
    return this.http.post(uri, data)
  }


  //////////////////////////////////assessment/////////////////////////////////

  ///////////////////////////////// cource sessions///////////////////////////
  getCourseScheduleSession(id) {
    let apiUrl = constants['GetCourseScheduleSession'] || 'GetCourseScheduleSession'
    let uri = this.url + apiUrl + '/' + id;
    return this.http.get(uri)
  }

  setCourseScheduleSessions(data) {
    let apiUrl = constants['SetCourseScheduleSessions'] || 'SetCourseScheduleSessions'

    let uri = this.url + apiUrl;
    return this.http.post(uri, data);
  }

  ///////////////////////////////// cource sessions///////////////////////////
  getSamvaadHeaders() {
    const headers = new HttpHeaders()
    headers.append('Authorization', localStorage.getItem('stoken'));
    return headers;
  }
}
//--skipTests=true