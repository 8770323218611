<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.HostelStudents}}</h5>
                                    </div>
                                    <div class="card-body">                                     
                                        <div class="pt-2" *ngIf="table.length">
                                            <table datatable id="DataTables_Table_0"
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #0d9bc4; color:#ffffff;">
                                                    <tr>
                                                        <th> Student Name </th>
                                                        <th> Campus Name </th>
                                                        <!-- <th> Class </th> -->
                                                        <th> Block Name </th>
                                                        <th> Floor Name </th>
                                                        <th> Room Name </th>
                                                        <th> Joined Date </th>
                                                        <th> Action </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.student_name}}</td>
                                                        <td>{{item.campus_name}}</td>
                                                        <!-- <td>{{item.class}}</td> -->
                                                        <td>{{item.block_name}}</td>
                                                        <td>{{item.floor_name}}</td>
                                                        <td>{{item.room_name}}</td>
                                                        <td>{{item.joined_date | date:'dd/MM/yyyy'}}</td> 
                                                        <td style="display: flex; text-align: center;cursor: pointer;">                                                       
                                                        <button 
                                                        class="btn btn-sm btn-ai"(click)="issue(item.Id,item.student_id)"
                                                        >Issue Items</button>&nbsp;&nbsp;
                                                        <button 
                                                        class="btn btn-sm btn-ai"(click)="return(item.Id)"
                                                       >Return Items</button>   
                                                     
                                                      </td>
                                                        <!-- <td style="display: flex;">
                                                            <span data-bs-toggle="modal" data-bs-target="#myModal"
                                                                src="" class="edit_icon" (click)="edit(item.item_id)">
                                                                <i class="fas fa-pen pt-1 mt-1"></i></span>
                                                        </td> -->
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>