import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-employee-idcard',
  templateUrl: './employee-idcard.component.html',
  styleUrls: ['./employee-idcard.component.css']
})
export class EmployeeIdcardComponent implements OnInit {
  myForm: FormGroup;
  loadDesignationDropdown: Array<any> = [];
  tId: string = '';
  selectedList: Array<any> = [];
  idcardData: Array<any> = [];
  checkboxs: Array<any> = [];
  labelName: any = {};
  checked: boolean;
  isStudent: boolean = false;
  isStudentAll: boolean = false;
  selectAllStudents: Array<any> = [];
  ishide: boolean = false;
  isDisable: boolean = false;
  headingName: any = {};
  workItemId: any;
  idCardPayload: any = {};
  constructor(private fb: FormBuilder,private active : ActivatedRoute, private CommonService: CommonService, private toastr: ToastrService, private router: Router) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadlabels();
    this.loadHeadings();
    this.loadDesignation();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      designation_id: ['', Validators.required],
      incharge: ['', Validators.required]
    })
  }

  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  loadDesignation() {
    this.loadDesignationDropdown = [];
    this.CommonService.postCall('Role/GetStaffRoles', {RoleName:localStorage.getItem('RoleName'), TENANT_CODE: this.tId || localStorage.getItem('TenantCode') }).subscribe((res: any) => {
      this.loadDesignationDropdown = res;
    })
  }

  inCharge(id) {
    this.checkboxs = [];
    let payload = {
      "objUserinrole": { "RoleId": id }
    }
    this.myForm.controls['incharge'].setValue('');
    this.CommonService.postCall('Registration/UserRolesChange', payload).subscribe((res: any) => {
      let arr: [] = res;
      if (arr.length == 0) {
        this.toastr.warning('There is no In-Charge for seleted Designation');
      } else {
        this.ishide = true;
        this.checkboxs = res;
      }

    })
  }

  changeCheck(event: any) {
    this.isStudent = true;
    this.isDisable = false;
    let peopleId = event.target.value;
    if (event.target.checked) {
      let obj = {
        people_id: peopleId
      }
      this.selectedList.push(obj);
    } else {
      this.selectedList = this.selectedList.filter(m => m.people_id != peopleId);
      if (this.selectedList.length == 0) {
        this.isDisable = true;
      }
    }
  }
  checkAll(event: any) {
    this.isDisable = false;
    this.isStudentAll = true;
    if (event.target.checked == true) {
      this.checkboxs.forEach(a => a.checked = true);
      this.checkboxs.filter(m => { if (m.checked) this.selectAllStudents.push({ people_id: m.USERID }) });
      console.log(this.selectAllStudents);
    } else {
      this.isDisable = true;
      this.checkboxs.forEach(a => a.checked = false);
      this.selectAllStudents = [];
    }
  }

  onSubmit1(form: FormGroup) {
    let value: any = form.value;
    let listOfMembers;
    if (this.isStudent) {
      listOfMembers = this.selectedList;
    } else if (this.isStudentAll) {
      listOfMembers = this.selectAllStudents;
    }


    let payload = {
      "tnt_code": localStorage.getItem('TenantCode'),
      "modified_by": localStorage.getItem('UserId'),
      "created_by": localStorage.getItem('UserId'),
      "ListOfMembers": listOfMembers
    }
    sessionStorage.idcardData = JSON.stringify(payload);
    this.router.navigate([`home/printEmployeeIdCard/${this.workItemId}`]);

  }

  onSubmit2(form: FormGroup) {
    let value: any = form.value;
    let listOfMembers;
    if (this.isStudent) {
      listOfMembers = this.selectedList;
    } else if (this.isStudentAll) {
      listOfMembers = this.selectAllStudents;
    }
    
    let payload = {
      "tnt_code": localStorage.getItem('TenantCode'),
      "modified_by": localStorage.getItem('UserId'),
      "created_by": localStorage.getItem('UserId'),
      "ListOfMembers": listOfMembers
    }
    sessionStorage.idcardData = JSON.stringify(payload);
    this.router.navigate([`home/idcard3/${this.workItemId}`]);
  }

  onSubmit3(form: FormGroup) {
    let value: any = form.value;
    let listOfMembers;
    if (this.isStudent) {
      listOfMembers = this.selectedList;
    } else if (this.isStudentAll) {
      listOfMembers = this.selectAllStudents;
    }


    let payload = {
      "tnt_code": localStorage.getItem('TenantCode'),
      "modified_by": localStorage.getItem('UserId'),
      "created_by": localStorage.getItem('UserId'),
      "ListOfMembers": listOfMembers
    }
    sessionStorage.idcardData = JSON.stringify(payload);
    this.router.navigate([`home/idcard3/${this.workItemId}`]);
  }

}
