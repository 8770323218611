import { Component, OnInit } from '@angular/core';
import { FormArray, FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-floors',
  templateUrl: './floors.component.html',
  styleUrls: ['./floors.component.css']
})
export class FloorsComponent implements OnInit {
  myForm: FormGroup;
  table: Array<any> = [];
  isEdit: boolean = false;
  editData: any = {};
  campus: Array<any> = [];
  block: Array<any> = [];
  eitem: any;
  labelName: any = {};
  headingName: any = {};
  workItemId: any;

  constructor(private fb: FormBuilder, private route: Router, private CommonService: CommonService, private toastr: ToastrService, private active: ActivatedRoute) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadlabels();
    this.loadFloors();
    this.loadHeadings();
    this.campusDropdown();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      campus_id: ['', Validators.required],
      block_id: ['', Validators.required],
      status: ['', Validators.required],
      Floor: this.fb.array([]),
    });
  }

  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  getOptionGroup(data) {
    let newGroup = this.fb.group({
      floor_name: [data.floor_name ? data.floor_name : '', Validators.required],
      amenities1: [data.amenities1 ? data.amenities1 : ''],
      amenities2: [data.amenities2 ? data.amenities2 : ''],
      amenities3: [data.amenities3 ? data.amenities3 : ''],
    });
    return newGroup
  }

  isAdd() {
    let arrayControl = <FormArray>this.myForm.controls['Floor'];
    if (arrayControl.length && arrayControl.length > 25) {
      return false
    }
    else {
      return true
    }
  }

  add(data = {}) {
    const arrayControl = <FormArray>this.myForm.controls['Floor'];
    let grp: FormGroup = this.getOptionGroup(data)
    arrayControl.push(grp)
  }

  delete() {
    const arrayControl = <FormArray>this.myForm.controls['Floor'];
    let index = arrayControl.length;
    arrayControl.removeAt(index - 1)
  }

  isVisable() {
    let arrayControl = <FormArray>this.myForm.controls['Floor'];
    if (arrayControl.length && arrayControl.length > 0) {
      return true
    }
    else {
      return false
    }
  }



  async loadFloors() {
    await this.CommonService.postCall('EmsFloor/GetList', { 'tnt_code': localStorage.getItem('TenantCode') }).subscribe((res: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = res;
      }, 10)
    })
  }

  campusDropdown() {
    let payload = {
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('EmsCampus/CampusDropDownList', payload).subscribe((res: any) => {
      this.campus = res;
    })
  }
  campusChange(id) {
    let payload = {
      "campus_id": id
    }
    this.CommonService.postCall('EmsBlock/BlockDropDownList', payload).subscribe((res: any) => {
      this.block = res;
    })
  }

  onSubmit(myForm: any) {
    let value: any = myForm.value;
    let Floor = [];
    myForm.value.Floor.forEach(e => {
      let item: any = '';
      Object.keys(e).map((key, i) => {
        let value = e[key];
        if (i > 0) {
          if (value) {
            item = item ? item + ',' + i : i;
          }
        }
      })
      let obj = {
        "floor_name": e.floor_name,
        "amenities": item
      }
      Floor.push(obj);
    });
    // console.log('form date', this.myForm.getRawValue());
    let payload = {
      "campus_id": value.campus_id,
      "block_id": value.block_id,
      "created_by": localStorage.getItem('UserId'),
      "modified_by": localStorage.getItem('UserId'),
      "tnt_code": localStorage.getItem('TenantCode'),
      "status": value.status,
      "Floor": Floor,
    }
    console.log(payload);
    // return    
    if (this.isEdit) {
      payload['floor_id'] = this.editData.floor_id;
      this.CommonService.postCall('EmsFloor/Update', payload).subscribe((res: any) => {
        window.location.reload();
        // this.loadFloors();
        this.toastr.success("Floor Updated Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Floor Not Updated')
      })
    } else {
      this.CommonService.postCall('EmsFloor/Create', payload).subscribe((res: any) => {
        window.location.reload();
        // this.loadFloors();
        this.toastr.success("Floor Created Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Floor Not created')

      })
    }
  }

  get removeFloor() {
    return this.myForm.get('Floor') as FormArray;
  }

  edit(fId: any) {
    this.isEdit = true;
    // this.myForm.controls['Floor'][0]=this.fb.array([]);
    this.removeFloor.removeAt(0)
    this.CommonService.postCall('EmsFloor/Get', { "floor_id": fId }).subscribe((res: any) => {
      this.editData = res;
      this.campusChange(res.campus_id);
      setTimeout(() => {
        this.dataTransfer(res);
      }, 100)

    })
  }

  dataTransfer(data) {
    // this.campusChange(data.campus_id);    
    this.myForm.patchValue({
      campus_id: this.editData['campus_id'],
      block_id: this.editData['block_id'],
      status: this.editData['status']
    })
    var amenities: Array<any> = this.editData['amenities'].length > 0 ? this.editData['amenities'].split(',') : [];
    var localdata = {
      "floor_name": this.editData['floor_name'],
      "amenities1": amenities.includes("1") ? 1 : 0,
      "amenities2": amenities.includes("2") ? 2 : 0,
      "amenities3": amenities.includes("3") ? 3 : 0
    };
    this.add(localdata);

  }



  close() {
    this.isEdit = null;
    this.myForm.reset();
    this.editData = {};
    this.myForm.controls['Floor'] = this.fb.array([]);
    // window.location.reload();
  }

  addArray() {
    this.add();
  }




}
