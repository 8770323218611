import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { EditUserRegistrationComponent } from '../edit-user-registration/edit-user-registration.component';
import * as moment from 'moment';
@Component({
  selector: 'app-additional-details',
  templateUrl: './additional-details.component.html',
  styleUrls: ['./additional-details.component.css']
})
export class AdditionalDetailsComponent implements OnInit {
  @Input() parent: EditUserRegistrationComponent;
  @Input() editData: any = {};
  myForm: FormGroup;
  additionalDetailsData: any = {}
  changed: boolean = false;
  isEdit: boolean = false;
  index: any;
  labelName: any = {};
  caste: Array<any> = [];
  casteCategory: Array<any> = [];
  constructor(private fb: FormBuilder, private CommonService: CommonService, private toastr: ToastrService) {
    this.getLabels();
    this.casteDropdown();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      mother_name: [''],
      nationality: [''],
      place_of_birth: [''],
      caste_id: [0],
      caste_category_id: [''],
      last_institute_attended: [''],
      dateofleavinginstitution: [''],
      standardinwhichstudying: [''],
      conduct_character: [''],
      conduct_progress: [''],
      // reason_of_leavingthecollege: [''],
      remarks: ['']
    })
    this.parent.childs['ems_transfer_certificate'] = this;

  }
  ngOnChanges() {
    // if (Object.keys(this.editData).length && !this.changed) {
    this.changed = true;
    // this.additionalDetailsData = this.editData['ems_transfer_certificate'];
    this.additionalDetailsData = this.editData['ems_transfer_certificate'];
    this?.dataTransform();
    // this.assignDataForm();
    //}
  }
  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${localStorage.getItem('regWorkItem')}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;


    })
  }

  casteDropdown() {
    this.caste = [];
    let payload = {
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('EmsCaste/CasteDropDownList', payload).subscribe((res: any) => {
      this.caste = res;
    })
  }

  casteCategoryDropdown(id: any) {
    this.casteCategory = [];
    let payload = {
      "caste_id": id
    }
    this.CommonService.postCall('EmsCasteCategory/Dropdownbycastid', payload).subscribe((res: any) => {
      this.casteCategory = res;
    })
  }

  dataTransform() {
    let data = this.additionalDetailsData; this.myForm?.markAllAsTouched;
    setTimeout(() => {
      this.casteCategoryDropdown(this.additionalDetailsData?.caste_id)
    }, 100);
    let controls = this.myForm?.controls;
    controls['mother_name']?.setValue(this.additionalDetailsData?.mother_name);
    controls['nationality']?.setValue(this.additionalDetailsData?.nationality);
    controls['place_of_birth']?.setValue(this.additionalDetailsData?.place_of_birth);
    controls['caste_id']?.setValue(this.additionalDetailsData?.caste_id);
    controls['caste_category_id']?.setValue(this.additionalDetailsData?.caste_category_id);
    controls['last_institute_attended']?.setValue(this.additionalDetailsData?.last_institute_attended);
    if (this.additionalDetailsData?.dateofleavinginstitution)
      controls['dateofleavinginstitution']?.setValue(moment(this.additionalDetailsData?.dateofleavinginstitution).format('yyyy-MM-DD'));
    controls['standardinwhichstudying']?.setValue(this.additionalDetailsData?.standardinwhichstudying);
    controls['conduct_character']?.setValue(this.additionalDetailsData?.conduct_character);
    controls['reason_of_leavingthecollege']?.setValue(this.additionalDetailsData?.reason_of_leavingthecollege);
    controls['conduct_progress']?.setValue(this.additionalDetailsData?.conduct_progress);
    controls['remarks']?.setValue(this.additionalDetailsData?.remarks);
  }
  close() {
    this.myForm.reset();
  }
}
