import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-students',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.css']
})
export class StudentsComponent  extends BaseComponent implements OnInit {
  myForm: FormGroup;
  table: Array<any> = [];
  cources: Array<any> = [];
  isTable: Boolean = false;
  payload: {};
  labelName: any = {};
  headingName: any = {};
  workItemId: any;

  constructor(private fb: FormBuilder,  CommonService: CommonService,private active : ActivatedRoute,toastr:ToastrService) {
    super(CommonService,toastr);
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.getCourses();
    this.getLabels();
    this.loadHeadings();

  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      class_id: ['']
    })
  }
  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  getCourses() {
    this.activeSpinner()
    this.CommonService.getAdminCourses(this.tId == '' ? undefined : this.tId).subscribe((res: any) => {
      this.cources = res;
      this.deactivateSpinner()
    }, e => {
      this.deactivateSpinner()
    })
  }
  onSubmit(form: any) {
    let val = form.value;
    this.isTable = false;
    this.payload = {
      "course_id": val.class_id
    }
    this.CommonService.getCall(`EmsReports/StudentsReport/${val.class_id || 0}/${this.tId||localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.table = res;
    })
  }
} 
