<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">

                    <div class="container-fluid">

                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.VendorsList}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right mb-2">
                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                                data-bs-target="#myModal">Add Vendor</button>
                                        </div>
                                        <div *ngIf="table.length">
                                            <table id="patreggraph" datatable
                                                class="table table-striped table-bordered mt-2 pt-2 table-sm small display"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624fd7; color:#ffffff;">
                                                    <tr>
                                                        <th> Vendor Name </th>
                                                        <th> Phone No. </th>
                                                        <th> Pan No. </th>
                                                        <th> GST No. </th>
                                                        <th> Address </th>
                                                        <th> Vendor Code </th>
                                                        <th> Status </th>
                                                        <th> Action </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.vendor_name}}</td>
                                                        <td>{{item.phone_number}}</td>
                                                        <td>{{item.pan}}</td>
                                                        <td>{{item.vat_number}}</td>
                                                        <td>{{item.address}}</td>
                                                        <td>{{item.vendor_code}}</td>
                                                        <td>{{item.status == true ? "Active" : "Inactive" }}</td>
                                                        <td style="display: flex; text-align: center; ">
                                                            <a data-bs-toggle="modal" data-bs-target="#myModal"
                                                                (click)="edit(item.vendor_id,item.document_type)"><i
                                                                    class="zmdi zmdi-edit zmdi-hc-2x pointer"> </i></a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog  modal-lg">
        <div class="modal-content">

            <div class="modal-header py-2">
                <h5 *ngIf="!isEdit" class="modal-title w-100"> {{headingName.AddVendors}}</h5>
                <h5 *ngIf="isEdit" class="modal-title w-100"> {{headingName.EditVendors}}</h5>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>


            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.Name}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <input placeholder="Name" class="form-control" formControlName="vendor_name"
                                        onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)">
                                </div>
                                <div *ngIf="myForm.get('vendor_name').touched">
                                    <span class="text-danger" *ngIf="myForm.get('vendor_name').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-3">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.Email}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <input type="email" email="true" placeholder="Email" class="form-control"
                                        formControlName="email">
                                </div>
                                <div *ngIf="myForm.get('email').touched">
                                    <span class="text-danger" *ngIf="myForm.get('email').hasError('email')">
                                        Please Enter The Valid Email
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-3">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.PanNumber}} </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <input placeholder="Pan Number" maxlength="10" class="form-control"
                                        formControlName="pan" style="text-transform:uppercase">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-3">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.MobileNumber}} <span class="text-danger">*</span> </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <input type="number" maxlength="10" placeholder="Phone Number" class="form-control"
                                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                        type="number" formControlName="phone_number">
                                </div>
                                <div *ngIf="myForm.get('phone_number').touched">
                                    <span class="text-danger" *ngIf="myForm.get('phone_number').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-3">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.LadlineNumber}} </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <input placeholder="Landline Number" class="form-control"
                                        formControlName="landline">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-3">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.FaxNumber}}</label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <input placeholder="Fax Number" class="form-control" formControlName="fax_number">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-3">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.Website}} </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <input placeholder="Website" class="form-control" formControlName="website">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-3">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.VATNumber}}</label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <input placeholder="GST Number" class="form-control" formControlName="vat_number">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-3">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.Address}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <textarea placeholder="Address" class="form-control" formControlName="address"
                                        rows="2"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-3">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.VendorCode}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <input type="text" placeholder="Vendor Code"  maxlength="20"
                                        class="form-control" formControlName="vendor_code"
                                        style="text-transform:uppercase"
                                        onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">
                                </div>
                                <div *ngIf="myForm.get('vendor_code').touched">
                                    <span class="text-danger" *ngIf="myForm.get('vendor_code').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-3">
                            <label class="col-md-4 control-label line_2 rem_1"> {{labelName?.VendorLogo}}</label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <input (change)='changeFile($event)' type="file" #materialFile id="materialFile1"
                                        placeholder="" class="form-control">
                                    <p *ngIf="isEdit">{{attachment}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label"> {{labelName?.Status}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select class="form-control form-control-sm shadow-sm rounded-lg" id="sel1"
                                        formControlName="status">
                                        <option [ngValue]=true>Active</option>
                                        <option [ngValue]=false>InActive </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                    </fieldset>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn  btn-sm btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-sm btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-sm btn-danger" id='md_close' (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>