import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'src/app/pages/base.component';
@Component({
  selector: 'app-leave-application',
  templateUrl: './leave-application.component.html',
  styleUrls: ['./leave-application.component.css']
})
export class LeaveApplicationComponent extends BaseComponent implements OnInit {
  myForm: FormGroup;
  isEdit: boolean = null;
  isDisable: boolean = false;
  table: Array<any> = [];
  leaveType: Array<any> = [];
  editData: any;
  minDate: any = moment().format('yyyy-MM-DD');
  labelName: any = {};
  headingName:any= {};
  workItemId: any;

  constructor(private fb: FormBuilder,private active : ActivatedRoute,  CommonService: CommonService,  toastr: ToastrService) {
    super(CommonService, toastr);
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.load();
    this.leavesType();
    this.loadlabels();
    this.loadHeadings();
   }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      leave_type_id: ['', [Validators.required,]],
      title: ['', [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      description: ['', [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      from_date: ['', [Validators.required,]],
      to_date: ['', [Validators.required,]],
    });
  }

  activiceSpinner() {
    this.CommonService.activateSpinner();
  }
  deactivateSpinner() {
    this.CommonService.deactivateSpinner();
  }


  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }


  load() {
    this.activiceSpinner();
    this.CommonService.getCall(`EmsLeaveManagement/GetList/${localStorage.getItem('UserId')}`).subscribe((res: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = res;
      }, 10)
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner(), console.log(e) })
  }

  leavesType(){
    this.leaveType = [];
    this.CommonService.postCall(`EmsLeaveManagement/LeaveTypeDropDownList`,{tnt_code:localStorage.getItem('TenantCode')}).subscribe((res: any) => {
      this.leaveType = res;
    })
    
  }

  onSubmit(form: any) {
    let value: any = form.value;
    let payload = {
      // "leave_type_id": value.leave_type,
      "leave_type_id": value.leave_type_id,
      "people_id": localStorage.getItem('UserId'),
      "from_date": value.from_date,
      "to_date":value.to_date,
      "title": value.title,
      "description":value.description ,
      "reason":null,
      "tnt_code": localStorage.getItem('TenantCode'),
      "created_by": localStorage.getItem('UserId'),
      "modified_by": localStorage.getItem('UserId'),
    }
    if (this.isEdit) {
      payload['leaves_id'] = this.editData.leaves_id;
      this.CommonService.postCall('EmsLeaveManagement/Update', payload).subscribe((res: any) => {
        this.load();
        this.toastr.success("Leave Application Updated Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Leave Application Not Updated');
      })
    } else {
      this.CommonService.postCall('EmsLeaveManagement/Create', payload).subscribe((res: any) => {
        this.load();
        this.toastr.success("Leave Application Created Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Leave Application Not created');
      })
    }
  }

  edit(leaves_id) {
    this.isEdit = true;
    this.CommonService.getCall(`EmsLeaveManagement/Get/${leaves_id}`).subscribe((res: any) => {
      if (res instanceof Array && res.length) {
        this.editData = res[0];
        this.dataTransForm()
      } else {
        this.editData = res;
        this.dataTransForm()
      }
      this.dataTransForm();
    }), err => { }
  }

  dataTransForm() {
    let ctrls = this.myForm.controls
    Object.keys(ctrls).map((key) => {
      let ctrl: AbstractControl = ctrls[key];
      ctrl.setValue(this.editData[key])
    });
    ctrls['from_date'].setValue(moment(this.editData['from_date']).format('yyyy-MM-DD'));
    ctrls['to_date'].setValue(moment(this.editData['to_date']).format('yyyy-MM-DD'));

  }

  changeStatus(){

  }

  close() {
    this.isEdit = null;
    this.myForm.reset();
    this.editData = {};
  }
}
