import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { BaseComponent } from '../base.component';


@Component({
  selector: 'app-student-feereceivable',
  templateUrl: './student-feereceivable.component.html',
  styleUrls: ['./student-feereceivable.component.css']
})
export class StudentFeereceivableComponent extends BaseComponent implements OnInit {

  table: Array<any> = [];
  courses: Array<any> = [];
  Schedules: Array<any> = [];
  sheduleId: string;
  myForm: FormGroup;
  academics: any;
  Curriculums: any;
  users: any;
  isParam: boolean = false;
  academic: any
  academicId: string = '';
  curriculumId: string;
  acId: any
  yearId: any;
  labelName: any = {};
  headingName: any = {};
  workItemId: any;

  constructor(private fb: FormBuilder, private active: ActivatedRoute, CommonService: CommonService, toastr: ToastrService, private route: Router) {
    super(CommonService, toastr);
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
    this.loadCourse();
    this.getLabels();
    this.loadHeadings();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({

    })
    this.table = []
  }
  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }
  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  // getAcademic() {
  //   // this.activeSpinner()
  //   this.CommonService.getCall('Academic/GetAcademicDropDownList/'+localStorage.getItem('TenantCode')).subscribe((res: any) => {
  //     this.academics = res;
  //     console.log(this.academics)
  //     this.deactivateSpinner()
  //   }, e => {
  //     this.deactivateSpinner()
  //   })
  // }
  // courceChange(acId) {

  //   this.yearId=acId.target.value
  //   console.log(acId)
  //   this.activeSpinner()
  //   let payLoad = {
  //     "academicId": acId.target.value
  //   }
  //   this.CommonService.getCall('Curriculum/CurriculumDropDownList/' + acId.target.value).subscribe((res: any) => {
  //     this.Curriculums = res;
  //     console.log(this.Curriculums)
  //     this.deactivateSpinner()

  //   }, e => {
  //     this.deactivateSpinner()
  //   })
  // }



  // Change(id) {
  //   this.curriculumId=id.target.value;
  //   this.activeSpinner()
  //   this.CommonService.getCall(`StudentRegistration/StudentDropDownList/${id.target.value}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
  //     this.users = res;
  //     console.log(this.users)
  //     this.deactivateSpinner()
  //   }, e => {
  //     this.deactivateSpinner()
  //   })
  // }




  // load(id) {

  //   this.activeSpinner();
  //   let payLoad = {
  //     "FEERECEIVABLE_ACADEMIC_ID": this.yearId,
  //     "FEERECEIVABLE_CURRICULUM_ID": this.curriculumId,
  //     "FEERECEIVABLE_STUDENT_ID": id.target.value,
  //     "TNT_CODE": localStorage.getItem('TenantCode'),

  //   }
  //   this.CommonService.postCall('StudentFeeReceivables/GetStudentFeeReceivables/', payLoad).subscribe((res: any) => {
  //     console.log(res)
  //     this.table = [];

  //     this.table = res;


  //     this.deactivateSpinner();
  //   }, e => { this.deactivateSpinner(), console.log(e) })
  // }

  loadCourse() {
    this.activeSpinner();
    this.CommonService.getAdminCourses(this.tId == '' ? undefined : this.tId).subscribe((res: any) => {
      this.courses = res;
      this.deactivateSpinner();
    }, e => {
      this.deactivateSpinner();
    })

  }

  courceChange(event) {

    this.sheduleId = '';
    let data = {
      "CourseId": event.target.value
    }
    this.CommonService.getAdminCourseSchedule(data).subscribe((res: any) => {
      this.deactivateSpinner()
      this.Schedules = res;
    }, e => { this.deactivateSpinner() })
  }

  sheduleChange(id) {
    this.activeSpinner();
    let payLoad = {
      "COURSESHD_ID": id.target.value
    }
    this.CommonService.postCall('Account/usersbycourseShedule', payLoad).subscribe((res: any) => {
      this.deactivateSpinner();
      this.users = res;
    }, e => {
      this.deactivateSpinner();
    });
  }

  load(id) {

    this.activeSpinner();
    let payLoad = {
      "FEERECEIVABLE_STUDENT_ID": id.target.value,
      "TNT_CODE": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('StudentFeeReceivables/GetBystudent', payLoad).subscribe((res: any) => {
      this.table = [];
      this.table = res;
      this.renderDataTable();
      this.table.map(a => a.checked = false);
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner(), console.log(e) })
  }




  // Update(){

  //   let item= this.table;
  //   console.log(item);

  //   this.CommonService.postCall('StudentFeeReceivables/Payment',item).subscribe((res: any) => {

  //     window.location.reload();
  //     this.toastr.success("Fee Receivable Updated Succuessfully");
  //     document.getElementById('md_close').click();
  //   }, err => {
  //     this.toastr.error(err.error ? err.error : 'Class Not Updated')
  //     this.deactivateSpinner();
  //   })


  // }

}