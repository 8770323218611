<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.FacultyFeedbackList}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div *ngIf='isSuperAdmin' class="col-md-3">
                                                <label for="sel1">{{labelName.TenantName}} : </label>
                                                <select [(ngModel)]='tId ' (change)="viewGetList()"
                                                    class="form-control form-control-sm shadow-sm rounded-lg"
                                                    id="sel1">
                                                    <option value="" [selected]="true">select</option>
                                                    <option *ngFor="let item of tenanates" [value]='item.TNT_CODE'>
                                                        {{item.TNT_NAME}}</option>
                                                </select>

                                            </div>
                                            </div>

                                        <div *ngIf="facultyList.length">
                                            <table id="patreggraph" datatable
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th>
                                                            Course Name</th>
                                                        <th>
                                                            Academic Year
                                                        </th>
                                                        <th>Semester </th>
                                                        <th>Faculty Name</th>
                                                        <th>Student Name</th>
                                                        <th>Action</th>


                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of facultyList">
                                                        <td>{{item.course_name}}</td>
                                                        <td>{{item.academic_name}}</td>
                                                        <td>{{item.semester}}</td>
                                                        <td>{{item.faculty_name}}</td>
                                                        <td>{{item.student_name}}</td>
                                                        <td style="display: flex;">
                                                            <a href="javascript:" data-bs-toggle="modal"
                                                                data-bs-target="#myModal"
                                                                (click)="viewGetList(item.course_id,item.academic_year_id,item.semester_id,item.faculty_id,item.student_id)">View</a>
                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!facultyList.length">
                                            No Records to display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-xl">
        <div class="modal-content ">
            <div class="modal-header py-2 ">
                <h6 class="modal-title w-100">{{headingName.FacultyFeedbackList}} </h6>

                <button type="button" class="close" data-bs-dismiss="modal"><i class="fas fa-times"></i></button>
            </div>
            <div class="modal-body">
                <div *ngIf="feedbackList.length">
                    <table id="patreggraph" datatable
                        class=" table table-striped table-bordered mt-2 pt-2 table-sm small display" style="width:100%">
                        <thead class="top" style="background-color: #624fd7; color:#ffffff;">
                            <tr>
                                <th>Question Number</th>
                                <th>Question </th>
                                <th>Answer</th>



                            </tr>

                        </thead>
                        <tbody>
                            <tr *ngFor="let item of feedbackList">
                                <td>{{item.question_id}}</td>
                                <td>{{item.question}}</td>
                                <td>{{item.answer}}</td>
                               



                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="text-center" *ngIf="!feedbackList.length">No Records to Display</div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-danger btn-sm" id="md_close" data-bs-dismiss="modal" (click)="close()">Close</button>&nbsp;&nbsp;

            </div>
        </div>
    </div>
</div>