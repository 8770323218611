import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { from } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-clients-list',
  templateUrl: './clients-list.component.html',
  styleUrls: ['./clients-list.component.less']
})
export class ClientsListComponent implements OnInit {

  table: Array<any> = [];
  myForm!: FormGroup;
  isEdit: boolean = false;
  editData: any = {};
  headingName: any = {};
  constructor(private fb: FormBuilder, private CommonService: CommonService, private toastr: ToastrService, private route: Router) {
    this.loadHeadings();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
    });
  }
  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/4290/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  onSubmit(form: FormGroup) {

  }

  edit() {
    this.isEdit = true;
  }


  add() {
    this.route.navigate(['/home/addClientDetails']);
  }

  close() {
    this.isEdit = false;
    this.myForm.reset();
    this.editData = {};
  }

}

