import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { dataDictionary } from 'src/app/dataDictionary';
import { BaseComponent } from 'src/app/pages/base.component';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-cource-schedule',
  templateUrl: './cource-schedule.component.html',
  styleUrls: ['./cource-schedule.component.css']
})
export class CourceScheduleComponent extends BaseComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
  courses: Array<any> = [];
  courseYear: any[];
  semester: any[];
  courseYearId: any;
  CourseId: any;
  semesterId: any;
  isSearch: boolean = false;
  headingName: any = {};
  labelName: any = {};
  workItemId: any;
  subjectId: any;
  subjectsList: any[];
  constructor(CommonService: CommonService, private active: ActivatedRoute, toastr: ToastrService, private route: Router) {
    super(CommonService, toastr);
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
    this.onSearch();
    this.loadCourse();
    this.changeTname();
    this.loadHeadings();
    this.getLabels();
  }

  ngOnInit(): void {
    this.renderDataTable();
  }


  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  loadCourse() {
    this.activeSpinner();
    this.CommonService.getAdminCourses().subscribe((res: any) => {
      this.courses = res;
      this.deactivateSpinner();
    }, e => {
      this.deactivateSpinner();
    })

  }

  courseChange(event: any) {
    this.courseYear = [];
    this.CourseId = event.target.value;
    this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.CourseYear }).subscribe((res: any) => {
      this.courseYear = res;
    })


  }

  changeCourseYear(event: any) {
    this.semester = [];
    this.courseYearId = event.target.value;
    this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.Semester }).subscribe((res: any) => {
      this.semester = res;
    })
  }

  changeSemester(event: any) {
    this.semesterId = event.target.value;
    this.subjectsList = [];
    let payload = {
      "COURSESHD_COURSE_ID": this.CourseId,
      "COURSESHD_COURSEYEAR": this.courseYearId,
      "COURSESHD_SEMESTER": this.semesterId
    }
    this.CommonService.postCall('CourseSchedule/SubjectDropdownbySemId', payload).subscribe((res: any) => {
      this.subjectsList = res;
    })
  }

  changeSubject(event: any) {
    this.subjectId = event.target.value;
    this.isSearch = true;
  }




  onSearch() {
    this.activeSpinner();
    this.table = [];
    let payLoad = {
      "RoleId": this.roleId,
      "COURSETRAINERID": this.userId,
      "TENANT_CODE": this.tId || this.TenantCode,
      "USERID": this.userId,
      "COURSESHD_COURSE_ID": this.CourseId,
      "COURSESHD_COURSEYEAR": this.courseYearId,
      "COURSESHD_SEMESTER": this.semesterId,
      "COURSESHD_SUBJECT": this.subjectId,
      "ROLE_NAME":localStorage.getItem('RoleName')
    }

    this.CommonService.postCall('LoadCourseScheduleBatchPlan', payLoad).subscribe((res: any) => {
      this.deactivateSpinner();
      setTimeout(() => {
        this.table = res;
      }, 10)
      this.renderDataTable()
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner() })

  }



  //CourseshAssigntrainersComponent courceSchedule-AssignTrainer  courceSchedule-AssignUser
  add() {
    this.route.navigate(['home/aeCourseSchedule/add'], { queryParams: { cId: 0, wId: this.workItemId } });
  }
  addAssessment(cId: any) {
    this.route.navigate(['home/assessmentCopo/add'], { queryParams: { id: cId, wId: this.workItemId } });
  }

  close() {

  }
  edit(item) {
    this.route.navigate(['home/aeCourseSchedule/edit'], { queryParams: { cId: item.COURSESHD_ID, wId: this.workItemId } });
  }
  navigate(data, route) {
    this.route.navigate(['home/' + route], { queryParams: { csId: data.COURSESHD_ID, cId: data.COURSE_ID, wId: this.workItemId } });
  }
  sessions(item) {
    let params = {
      csId: item.COURSESHD_ID,
      cId: item.COURSE_ID,
      sDate: item.COURSESHD_STARTDATE,
      eDate: item.COURSESHD_ENDDATE,
      wId: this.workItemId
    }
    this.route.navigate(['home/courseSessions'], { queryParams: params })

  }

  changeTname() {
    this.activeSpinner();
    this.CommonService.getAdminCourses(this.tId).subscribe((res: any) => {
      this.courses = res;
      this.deactivateSpinner();
    }, e => {
      this.deactivateSpinner();
    })
    this.onSearch()
  }
  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }
}

