<div *ngIf='false' class="loginbg" style="background-image: url('./assets/img/bg-image.png');">
    <div class="container">
        <div class="row">
            <div class="offset-xl-4 col-xl-4">
                <div class="card  loginform">

                    <div class="card-body p-1 loginform">
                        <div id="login-row" class="row justify-content-center align-items-center">
                            <div id="login-column" class="col-xl-12">
                                <div id="login-box" class="col-xl-12">
                                    <form id="login-form" class="form" #form="ngForm" (ngSubmit)="registerUser(form)">
                                        <h3 class="text-center text-info">Login</h3>
                                        <div class="form-group">
                                            <label for="username" class="text-info">Username:</label><br>
                                            <!-- <input type="text" name="username" id="username" class="form-control"> -->
                                            <input type="email" class="form-control" id="userName" placeholder="Email"
                                                email="true" name="userName" userName required ngModel
                                                #userName="ngModel">
                                            <span class="help-bpx" *ngIf="userName.touched && !userName.valid ">Please
                                                enter the Email </span>
                                        </div>
                                        <div class="form-group">
                                            <label for="password" class="text-info">Password:</label><br>
                                            <!-- <input type="text" name="password" id="password" class="form-control"> -->
                                            <input type="password" class="form-control" id="password"
                                                placeholder="password" name="password" password required ngModel
                                                #password="ngModel">
                                            <span class="help-bpx" *ngIf="password.touched && !password.valid ">Please
                                                enter the password </span>
                                        </div>
                                        <div class="text-center">
                                            <div class="form-group">
                                                <!-- <label for="remember-me" class="text-info"><span>Remember me</span> <span><input id="remember-me" name="remember-me" type="checkbox"></span></label><br> -->
                                                <!-- <input type="submit" name="submit" class="btn btn-info btn-md" value="submit"> -->
                                                <button type="submit" class="btn btn-info btn-sm btn-block"
                                                    id="register" [disabled]="!form.valid">Submit</button>
                                            </div>
                                        </div>

                                        <!-- <div id="register-link" class="text-right">
                                        <a href="#" class="text-info">Register here</a>
                                    </div> -->
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>




</div>

<div class="limiter">
    <div class="container-login100" style="background-image: url('../../../assets/images/dhanush/bg-image.png');">
        <div class="wrap-login100">
            <div class="text-center"> <img src="../../../assets/images/dhanush/logo-sam.png" class="img-fluid" /></div>
            <br>
            <form class="login100-form validate-form">
                <span class="login100-form-title p-b-24"> <span style="font-size:22px;">Welcome to Samvaad</span> <br>
                    <span style="font-size:15px;">Learning Management System</span> </span>
                <div class="wrap-input100 validate-input mt-4" data-validate="Valid email is: a@b.c">
                    <input class="input100" [(ngModel)]="data.userName" type="text" name="email">
                    <span class="focus-input100" data-placeholder="Email"></span>
                </div>
                <div class="wrap-input100 validate-input" data-validate="Enter password"> <span class="btn-show-pass">
                        <i class="zmdi zmdi-eye"></i> </span>
                    <input class="input100" [(ngModel)]="data.password" type="password" name="pass">
                    <span class="focus-input100" data-placeholder="Password"></span>
                </div>
                <div class="container-login100-form-btn">
                    <div class="wrap-login100-form-btn">
                        <div class="login100-form-bgbtn"></div>
                        <button class="btn login100-form-btn login-btn "> Login </button>
                    </div>
                </div>

                <!-- <div class="text-center p-t-23"> <span class="txt1"> Don’t have an account? </span> <a class="txt2" href="#"> Sign Up </a> </div> -->
            </form>
            <a class="container-login100-form-btn" data-bs-toggle="modal" style="cursor: pointer;"
                data-bs-target="#changePassword">Forgot
                Password?</a>

               
        </div>
    </div>
</div>


<div class="modal" id="changePassword" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog  m-auto mt-5">
        <div class="modal-content">

            <div class="modal-header">
                <h4 class="modal-title w-100">Change Password</h4>               
                <button type="button" (click)="close()" class="close px-3" data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>
            <div class="modal-body ">
                <div class="card">
                    <div class="card-body p-4">
                        <div class="row">
                            <label for="email" class="col-sm-4 col-form-label">Email</label>
                                <div class="col-sm-7">
                                <input type="email" class="form-control" id="email" [(ngModel)]="email_id"
                                  >
                                </div>
                          </div>                        
                    </div>
                </div>
            </div>
            <div class="modal-footer p-2 text-center">
               <button type="button" class="btn btn-sm btn-save px-3 py-1 me-2" (click)="onSubmit()"
                    >send</button>              
                <button type="button" class="btn btn-sm  btn-danger px-3 py-1 " id='md_close' (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>


 