import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-addstops',
  templateUrl: './addstops.component.html',
  styleUrls: ['./addstops.component.css']
})
export class AddstopsComponent implements OnInit {
  titles:Array<any>=[];
  constructor() { }

  ngOnInit(): void {
  }
  mk(){
    
  }
}
