<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0"> {{headingName.Fine}} </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="col-xl-12">
                                            <div class="table">
                                                <form class="well form-horizontal" [formGroup]="myForm"
                                                    (ngSubmit)="onSubmit(myForm)">
                                                    <fieldset>
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group row">
                                                                    <label class="col-md-4 control-label line_2 rem_1">
                                                                        {{labelName?.StudentId}}<span
                                                                            class="text-danger">*</span></label>
                                                                    <div class="col-md-8 inputGroupContainer">
                                                                        <div class="input-group"> <strong>:</strong>
                                                                            &nbsp;
                                                                            <input type="text" placeholder=""
                                                                                class="form-control"
                                                                                (change)=scanQr($event.target.value)>
                                                                        </div>
                                                                        <p style="color:black">* please select the textbox and scan the bar code</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>&nbsp;&nbsp;




                                                    </fieldset>
                                                    <br><br>
                                                    <!-- <div class="text-center">
                                                        <button type="submit" [disabled]="myForm.invalid"
                                                        class="btn btn-sm  btn-save">Submit</button>
                                                    </div> -->
                                                </form>
                                                <!-- <button class="btn btn-primary"
                                                    type="button" (click)="scanQr($event.target.value)">Search</button>&nbsp;&nbsp;
                                                <button type="button" class="btn btn-danger">Cancel</button> -->
                                            </div>
                                        </div>

                                        <div class="pt-4" *ngIf="qrCode.length">
                                            <table id="patergraph" datatable
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <!-- <th>Book Id </th> -->
                                                        <th>Book Name</th>
                                                        <th>Issue Date</th>
                                                        <th>Due Date</th>
                                                        <th>Return Date</th>
                                                        <th>Delay Days</th>
                                                        <th>Fine</th>
                                                        <th>Status</th>
                                                        <th></th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of qrCode">
                                                        <!-- <td>{{item.book_id}}</td> -->
                                                        <td>{{item.book_name}}</td>
                                                        <td>{{item.book_std_date_of_issue | date:'dd/MM/yyyy'}} </td>
                                                        <td>{{item.due_date | date:'dd/MM/yyyy'}}</td>
                                                        <td>{{item.return_date | date:'dd/MM/yyyy'}}</td>
                                                        <td>{{item.delay_days}}</td>
                                                        <td>{{item.fine_amount}}</td>
                                                        <td>{{item.pament_status}}</td>
                                                        <td style="display: flex; text-align: center;">
                                                            <button type="button" class="btn btn-primary mb-1"(click)="payAmount($event,item.book_std_id,item.fine_amount)"
                                                                [disabled]="item.pament_status == 'Paid'?true:false">Pay</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="text-center pt-4 text-red" *ngIf="!qrCode.length">
                                                No Records to display
                                            </div>
                                        </div>

                                        <!-- <div class="form-group ">
                                            <form class="well form-horizontal" [formGroup]="myForm"
                                                (ngSubmit)="onSubmit(myForm)">
                                                <fieldset>
                                                    <div class="row">
                                                        <div class="col-md-3 ">
                                                            <label for="sel1"> {{labelName?.CourseName}} <span
                                                                    class="text-danger">*</span>:
                                                            </label>
                                                            <select
                                                                class="form-control form-control-sm shadow-sm rounded-lg"
                                                                id="sel1" (change)="courseChange($event)"
                                                                formControlName="courseName">
                                                                <option value="" [selected]="true">select</option>
                                                                <option *ngFor="let course of courses"
                                                                    [value]='course.COURSE_ID'>
                                                                    {{course.COURSE_NAME}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div class="col-md-3 ">
                                                            <label for="sel1"> {{labelName?.CourseSchedule}}<span
                                                                    class="text-danger">*</span>:
                                                            </label>
                                                            <select name="courseSchedule" id="courseSchedule"
                                                                (change)="sheduleChange($event)"
                                                                formControlName="courseSchedule"
                                                                class="form-control form-control-sm shadow-sm rounded-lg ">
                                                                <option value="" [selected]="true">Select</option>
                                                                <option *ngFor="let courseSchedule of Schedules"
                                                                    [value]="courseSchedule.COURSESHD_ID">
                                                                    {{courseSchedule.COURSESHD_NAME}}</option>
                                                            </select>
                                                        </div>

                                                        <div class="col-md-3">
                                                            <label for="sel1">{{labelName?.StudentName}}<span
                                                                    class="text-danger">*</span>:</label>
                                                            <select name="student" id="student"
                                                                formControlName="student"
                                                                (change)="loadTable($any($event).target.value)"
                                                                class="form-control form-control-sm shadow-sm rounded-lg ">
                                                                <option value="" [selected]="true">Select</option>
                                                                <option *ngFor="let student of students"
                                                                    [value]="student.USERID">{{student.FIRSTNAME}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div *ngIf="table.length" class="form-group row pt-4">
                                                            <table *ngIf="isTable" id="patreggraph" datatable
                                                                class="table table-striped table-bordered mt-2 pt-2 table-sm small display"
                                                                style="width:100%">
                                                                <thead class="top"
                                                                    style="background-color: #624fd7; color:#ffffff;">
                                                                    <tr>
                                                                        <th>Book Name </th>
                                                                        <th>ID</th>
                                                                        <th>Name </th>
                                                                        <th> Issue Date</th>
                                                                        <th> Return Date</th>
                                                                        <th> Actual Return Date</th>
                                                                        <th> Delay Days</th>
                                                                         <th> Fine Amount</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr *ngFor="let item of table">
                                                                        <td>{{item.book_name}}</td>
                                                                        <td>{{item.book_std_id}}</td>
                                                                        <td>{{item.name}}</td>
                                                                        <td>{{item.book_std_date_of_issue | date:'dd/MM/yyyy'}}</td>
                                                                        <td>{{item.book_std_return_date | date:'dd/MM/yyyy'}}</td>
                                                                        <td>{{item.book_std_actual_return_date | date:'dd/MM/yyyy'}}</td>
                                                                        <td>{{item.delaydays}}</td>
                                                                         <td>{{item.finalamount}}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </form>
                                        </div> -->


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>