<div>
    <div class="text-right">
        <button [disabled]="roleId==3" type="button" class="btn btn-sm btn-primary" (click)="add()"
            data-bs-toggle="modal" data-bs-target="#myModal_education">Add</button>

    </div>
    <table *ngIf='table.length' class="table table-striped table-responsive-md btn-table table-bordered text-dark">
        <thead>
            <tr>
                <th>Education Type</th>
                <th>Education Mode</th>
                <th>Education Name</th>
                <th>Specialization</th>
                <th>University Institute</th>
                <th>Year Of Passing</th>
                <th>Operation</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor='let item of table;let i=index'>
                <td>{{item.EDUCATIONTYPE}}</td>
                <td>{{item.EDUCATIONMODE}}</td>
                <td>{{item.EDUCATIONNAME}}</td>
                <td>{{item.SPECIALIZATION}}</td>
                <td>{{item.UNIVERSITYINSTITUTE}}</td>
                <td>{{item.YEAROFPASSING}}</td>
                <td style="display: flex;"> <span data-bs-toggle="modal" data-bs-target="#myModal_education" src=""
                        class="edit_icon" (click)="edit(item,i)">
                        <i class="fas fa-pen pt-1 mt-1"></i></span>
            </tr>
        </tbody>
    </table>
    <p class="text-center" *ngIf='!table.length'>No Records</p>
</div>

<div class="modal" id="myModal_education" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog ">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header py-2">
                <h4 *ngIf="!isEdit" class="modal-title"> {{headingName.AddEducation}}</h4>
                <h4 *ngIf="isEdit" class="modal-title"> {{headingName.EditEducation}}</h4>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label">{{labelName.EducationType}} <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select formControlName='EDUCATIONTYPE'
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option *ngFor='let item of educationTypes' [value]='item.DICTIONARYNAME'>
                                            {{item.DICTIONARYNAME}}</option>
                                    </select>
                                </div>

                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label"> {{labelName.EducationMode}} <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select formControlName='EDUCATIONMODE' (change)='modeChange($event.target.value)'
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option *ngFor='let item of educationModes' [value]='item.DICTIONARYNAME'>
                                            {{item.DICTIONARYNAME}}</option>
                                    </select>
                                </div>

                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label">{{labelName.EducationName}} <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select formControlName='EDUCATIONNAME'
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option *ngFor='let item of EducationName' [value]='item.DICTIONARYNAME'>
                                            {{item.DICTIONARYNAME}}</option>

                                    </select>
                                </div>

                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label">{{labelName.Specialization}} <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select formControlName='SPECIALIZATION'
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option *ngFor='let item of Specialization' [value]='item.DICTIONARYNAME'>
                                            {{item.DICTIONARYNAME}}</option>
                                    </select>
                                </div>

                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label"> {{labelName.UniversityInstitute}} <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select formControlName='UNIVERSITYINSTITUTE'
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option *ngFor='let item of Institutes' [value]='item.DICTIONARYNAME'>
                                            {{item.DICTIONARYNAME}}</option>
                                    </select>
                                </div>

                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName.YearOfPassing}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input type="number" min='1' placeholder="" class="form-control"
                                        formControlName="YEAROFPASSING">
                                </div>

                            </div>

                        </div>



                    </fieldset>
                </form>

            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-sm btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-sm btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-sm btn-danger" id="md_close_education" (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>