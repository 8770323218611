import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { BaseComponent } from 'src/app/pages/base.component';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-classes',
  templateUrl: './classes.component.html',
  styleUrls: ['./classes.component.css']
})
export class ClassesComponent extends BaseComponent implements OnInit {
  myForm: FormGroup;
  table: Array<any> = [];
  headingName: any = {};
  workItemId: any;
  labelName: any = {};
  constructor(commonService: CommonService, private active: ActivatedRoute, toastr: ToastrService) {
    super(commonService, toastr);
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
    this.loadClasses();
    this.loadHeadings();
    this.getLabels();
  }

  ngOnInit(): void {
  }


  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadClasses() {
    this.table = [];
    this.CommonService.getCall(`EmsReports/ClassesReport/${this.tId || localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.table = res;
    })
  }

  onSubmit(myForm) {

  }
}
