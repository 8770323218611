
<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.DailyDiaryList}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right mb-2">
                                            <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal"
                                                data-bs-target="#myModal">Add Daily Diary</button>
                                        </div>
                                        <div *ngIf="table.length">
                                            <table datatable id="DataTables_Table_0"
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #0d9bc4; color:#ffffff;">
                                                    <tr>
                                                        <th>
                                                            Campus Name</th>
                                                        <th> Course</th>
                                                        
                                                   
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody >
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.campus}}</td>
                                                        <td>{{item.course_name}}</td>
                                                        
                                                        <!-- |date:'dd-MM-yyyy' -->
                                                        <td  style="display: flex;"> 
                                                            <span data-bs-toggle="modal" data-bs-target="#myModal"
                                                            src=""
                                                            class="edit_icon" (click)="edit(item.daily_diary_id)"> <i class="fas fa-pen pt-1 mt-1"></i></span >
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">No records to display!</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="myModal" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header py-2">
                <h6 *ngIf="!isEdit" class="modal-title w-100">{{headingName.AddDailyDiary}}</h6>
                <h6 *ngIf="isEdit" class="modal-title w-100"> {{headingName.EditDailyDiary}}</h6>

                <button type="button" (click)="close()" class="close"
                    data-bs-dismiss="modal"><i
                    class="fas fa-times"></i></button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row">
                            <label class="col-md-4 control-label ">{{labelName.Campus}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <select class="form-select form-select-sm shadow-sm rounded-lg" formControlName="campus_id">
                                        <option value="" selected>Select</option>
                                        <option *ngFor="let item of campusDropdown" [value]="item.campus_id">{{item.campus_name}}</option>                                        
                                    </select>
                                </div>
                                <!-- <div *ngIf="myForm.get('campus').touched">
                                            <span class="text-danger" *ngIf="myForm.get('campus').hasError('required')">
                                                This field is required.
                                            </span>
                                        </div> -->
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName.Course}}   <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName="class_id" [(ngModel)]='courceId '
                                        (change)="courceChange($any($event).target.value)"
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option *ngFor="let course of cources" [value]='course.COURSE_ID'>
                                            {{course.COURSE_NAME}}
                                        </option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('class_id').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('class_id').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName.CourseSchedule}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName="section_id"
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option *ngFor="let schedul of scheduls"
                                            [value]='schedul.COURSESHD_ID'>
                                            {{schedul.COURSESHD_NAME}}</option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('section_id').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('section_id').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label ms-2">{{labelName.Date}}<span
                                    class="text-danger">*</span></label>
                            <div class="col-md-6 inputGroupContainer">
                                <div class="input-group"><strong>:</strong>&nbsp;
                                    <input type="date" placeholder="Select Date" class="form-control"
                                        formControlName="daily_diary_date">
                                </div>
                            </div>
                        </div>
                      
                    </fieldset>
                </form>

            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-save btn-sm" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-save btn-sm" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-danger  btn-sm" id='md_close' (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>