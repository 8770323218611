<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.Campus}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right mb-1">
                                            <button type="button" class="btn btn-primary" (click)="add()">Add</button>
                                        </div>
                                        <div *ngIf="table.length">
                                            <table id="patergraph" datatable
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <!-- <th>S.No </th> -->
                                                        <th>Campus Name</th>
                                                        <th>Campus Type</th>
                                                        <th> Campus Address Type</th>
                                                        <th>Status</th>
                                                        <th>Action</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <!-- <td></td> -->
                                                        <td>{{item.campus_name}}</td>
                                                        <td>{{item.campus_type}}</td>
                                                        <td>{{item.campus_address_type}}</td>
                                                        <td>{{item.campus_status == true ? "Active" : "Inactive"}}</td>

                                                        <td style="display: flex;cursor: pointer;">
                                                            <span src="" class="edit_icon" (click)="edit(item)">
                                                                <i class="fas fa-pen pt-1 mt-1"></i></span>
                                                        </td>

                                                    </tr>
                                                </tbody>

                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>