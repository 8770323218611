import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { FileuploadService } from 'src/app/services/fileupload.service';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-parent-alert',
  templateUrl: './parent-alert.component.html',
  styleUrls: ['./parent-alert.component.css']
})
export class ParentAlertComponent extends BaseComponent implements OnInit {
  htmlContent: any = '';
  htmlContentWithOutHtml = '';
  headingName: any = {};
  workItemId: any;
  stId: any;

  showHtml() {
    this.htmlContentWithOutHtml = document.getElementById('htmldiv').innerHTML;
  }

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold']
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  }

  smsInputs: boolean = false;
  emailInputs: boolean = false;
  voipcallInputs: boolean = false;
  selectCampus: boolean = false;
  selectCourse: boolean = false;
  selectCheckbox: boolean = false;
  campus: Array<any> = [];
  courses: Array<any> = [];
  courceId: string = ''
  scheduls: Array<any> = [];
  students: Array<any> = [];
  dropdownSettings: any = {}
  selectedItems: { item_id: number; item_text: string; }[];
  dropdownList: { item_id: number; item_text: string; }[] = [];
  isData: boolean;
  file: File;
  fileName: any;
  myForm!: FormGroup;
  documentType = "parentAlert";
  documentTypeId: any;
  labelName: any = {};
  constructor( CommonService: CommonService, private fb: FormBuilder,  toastr: ToastrService, private FileuploadService: FileuploadService,private active : ActivatedRoute) {
    super(CommonService, toastr);
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadlabels();
    this.loadHeadings();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      message: ['', Validators.required],
      subject: ['', Validators.required],
      sending_type: ['', Validators.required],

    })
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.campusdropDown(localStorage.getItem('TenantCode'));
  }
  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }


  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  sms(event: any) {
    let type = event.target.value;
    if (type == "SMS") {
      this.smsInputs = true;
      this.emailInputs = false;
      this.voipcallInputs = false;
    } else if (type == "Email") {
      this.emailInputs = true;
      this.smsInputs = false;
      this.voipcallInputs = false;
      this.selectCampus = false;
      this.selectCourse = false;
      this.selectCheckbox = false;
      $("#materialFile1").val('');
      this.fileName = '';
      this.campusdropDown(localStorage.getItem('TenantCode'));
    } else if (type == "VoipCall") {
      this.voipcallInputs = true;
      this.smsInputs = false;
      this.emailInputs = false;
    }
  }


  campusdropDown(tId) {
    this.stId = tId
    let payload = {
      "tnt_code": tId
    }
    this.CommonService.postCall('EmsCampus/CampusDropDownList', payload).subscribe((response: any) => {
      this.campus = response;
    })
  }


  campusChange() {
    this.selectCampus = true;
    this.CommonService.getAdminCourses(this.stId).subscribe((res: any) => {
      this.courses = res;

    })
  }
  courseChange(event: any) {
    this.selectCourse = true;
    let data = {
      "CourseId": event
    }
    this.CommonService.getAdminCourseSchedule(data).subscribe((res: any) => {
      this.scheduls = res;
    }, e => { })
  }

  isChecked(event: any) {
    if (event.target.checked) {
      this.selectCheckbox = true;
    } else {
      this.selectCheckbox = false;
    }
  }

  onItemSelect(e) { }
  onSelectAll(e) { }

  ScheduleChange(id: any) {
    let payLoad = {
      "COURSESHD_ID": id
    }
    this.CommonService.postCall('Account/usersbycourseShedule', payLoad).subscribe((res: any) => {
      this.students = res;
      res.map(item => {
        let obj = {
          item_id: item.USERID,
          item_text: item.FIRSTNAME,
          // user_name: item.USERNAME
        }
        this.dropdownList.push(obj);
        this.isData = true;

      })
    }, e => {
    });
  }

  onSend(form: any) {
    let value = form.value;
    let userlist = [];
    // let usernames = [];
    this.selectedItems.forEach(element => {
      let obj = {
        "usid": element.item_id,

      }
      // let obj1 = {
      //   "username": element.user_name
      // }
      userlist.push(obj);
      // usernames.push(obj1);

    });

    $("#materialFile1").val('');

    let Emailpayload = {
      "sending_type": "Email",
      // "username": usernames.map(e => e.username).join(","),
      "userList": userlist,
      "subject": value.subject,
      "message": this.htmlContent,
      // "circular_upload": this.fileName,
      "attachment": this.fileName,
      "created_by": localStorage.getItem('UserId'),
      "modified_by": localStorage.getItem('UserId'),
      "tnt_code": this.stId,
    }

    let smsPayload = {
      "sending_type": "SMS",
      "userList": userlist,
      "message": value.message,
      "created_by": localStorage.getItem('UserId'),
      "modified_by": localStorage.getItem('UserId'),
      "tnt_code":  this.stId,
    }

    if (value.sending_type == "Email") {
      this.CommonService.postCall('EmsAlerts/ParentAlert', Emailpayload).subscribe((res: any) => {
        this.documentTypeId = res;
        this.toastr.success("Email alert has been sent");
        setTimeout(() => {
          window.location.reload();
        }, 200);
      }, err => {
        this.toastr.error(err.error ? err.error : 'Email alert has not been sent')
      })
    } else if (value.sending_type == "SMS") {
      this.CommonService.postCall('EmsAlerts/ParentAlert', smsPayload).subscribe((res: any) => {
        setTimeout(() => {
          window.location.reload();
        }, 200);
        this.toastr.success("SMS alert has been sent");
      }, err => {
        this.toastr.error(err.error ? err.error : 'SMS alert has not been sent')
      })
    }

  }

  changeFile(event) {
    if (event.target.files.length > 0) {
      let file: File = event.target.files[0];
      let name = file.name;
      let filetype = name.split('.').pop()
      let types: Array<any> = ['doc', 'docx', 'pdf', 'jpg', 'gif', 'png', 'xlsx', 'xlr', 'ppt', 'pptx', 'jpeg']
      let check = types.includes(filetype);
      if (check) {
        this.file = file;
        this.upload();
      }
      else {
        // alert(' Please upload pdf and doc file formats only.')
        this.toastr.warning('Please upload pfc,doc and jpeg file formats only.')
        event.target.value = ''
      }
    }
  }

  upload() {
    let data = {
    }
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('attachment', this.file);
    this.FileuploadService.upload(formData, 'EmsCampus/UploadImage').subscribe((res: any) => {

      try {
        this.fileName = res.path;
        if (this.fileName) {
          this.myForm.controls['QuestionImage'].setValue(this.fileName)
        }
      } catch (e) {
        console.log(e)
      }

    }, err => { this.toastr.warning('Upload Failed') })
  }




  cancel() {
    $("#materialFile1").val('');
    this.fileName = '';
    window.history.back();
  }
}
