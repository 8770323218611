import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css']
})
export class InventoryComponent extends BaseComponent implements OnInit {
  myForm: FormGroup;
  table: Array<any> = [];
  headingName: any = {};
  workItemId: any;
  labelName: any = {};
  constructor(private commonService: CommonService,private active : ActivatedRoute,toastr:ToastrService) {
    super(commonService,toastr);
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadInventory();
    this.loadHeadings();
    this.getLabels();
  }

  ngOnInit(): void {
  }
  loadHeadings() {
    this.headingName = {};
    this.commonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  getLabels() {
    this.labelName = {};
    this.commonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  } 

  loadInventory() {
    let payload = {

    }
    this.commonService.getCall(`EmsReports/InventoryReport/${this.tId || localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.table = res;
    })
  }
  onSubmit(myForm) {

  }
}
