<div class="page-wrapper pt-4">
  <div class="page-content pt-0 pe-2 ps-2">
    <div class="container-fluid">
      <div class="default_class">
        <div class="content-wrapper p-0">
          <div class="container-fluid">
            <div class="row">
              <div class="col-xl-12">
                <div class="card rounded shadow-lg">
                  <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                    <h5 class="mb-0">{{headingName.AssessmentResults}}</h5>
                  </div>
                  <div class="card-body">
                    <table id="patreggraph" datatable [dtTrigger]="dtTrigger2"
                      class="table table-striped table-bordered mt-2 pt-2 table-sm small display" style="width:100%">
                      <thead class="top" style="background-color: #624fd7; color:#ffffff;">
                        <tr >
                          <th>Assessment Name</th>
                          <th>Assessment Type</th>
                          <th>Course Name</th>
                          <th>Written Date</th>
                          <th>Percentage Scored</th>
                          <th>Result</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of results; let i=index">
                          <td>{{item.ASSESSMENT_ASSESSMENT_NAME}}</td>
                          <td>{{item.ASSESSMENTTYPE}}</td>
                          <td>{{item.COURSE_NAME}}</td>
                          <td>{{item.ASSESSMENTDATE}}</td>
                          <td>{{item.RESULT_PERCENTAGESCORED}}</td>
                          <td>{{item.RESULT}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>


      <!-- datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger2"  -->