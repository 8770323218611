<div>
    <div class="text-right">
        <button [disabled]="roleId==3" type="button" class="btn btn-primary" (click)="add()" data-bs-toggle="modal"   data-bs-target="#myModal_lan">Add</button>

    </div>
    <div class="pt-2">
        <table *ngIf='table.length' class="table table-striped table-responsive-md btn-table table-bordered text-dark">
            <thead>
                <tr>
                    <th>
                        Language</th>
                    <th>	Proficiency Level</th>
                    <th>	Read</th>
                    <th>Write</th>
                    <th>Speak</th>          
                    <th>Operation</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor='let item of table;let i=index'>
                    <td>{{item.LanguageName}}</td>
                    <td>{{item.ProficiencyLevelName}}</td>
                    <td>{{item.ReadL}}</td>
                    <td>{{item.WriteL}}</td>
                    <td>{{item.SpeakL}}</td>
                    <td style="display: flex;"> 
                        <span data-bs-toggle="modal" data-bs-target="#myModal_lan"
                        src=""
                        class="edit_icon" (click)="edit(item,i)"> 
                        <i class="fas fa-pen pt-1 mt-1"></i></span ></td>
    
                </tr>
            </tbody>
        </table>
        <p class="text-center" *ngIf='!table.length'>No Records</p>
    </div>
  
</div>

<div class="modal" id="myModal_lan" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog  modal-md">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header py-2">
                <h6 *ngIf="!isEdit" class="modal-title w-100"> {{headingName.AddLanguage}} </h6>
                <h6 *ngIf="isEdit" class="modal-title w-100"> {{headingName.EditLanguage}}</h6>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i class="fas fa-times"></i></button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row">
                            <label class="col-md-4 control-label"> {{labelName.Language}}  <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select  formControlName='Language'
                                    class="form-select form-select-sm shadow-sm rounded-lg">
                                    <option value="" selected>Select Language</option>
                                    <option *ngFor='let item of languages' [value]='item.DICTIONARYID'>{{item.DICTIONARYNAME}}</option>
                                </select>

                                </div>
                                <div *ngIf="myForm.get('Language').touched">
                                    <span class="text-danger" *ngIf="myForm.get('Language').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label"> {{labelName.EmployementStatus}} <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select  formControlName='ProficiencyLevel'
                                    class="form-select form-select-sm shadow-sm rounded-lg">
                                    <option value="" selected>Select Employement Status</option>
                                    <option *ngFor='let item of levels' [value]='item.DICTIONARYID'>{{item.DICTIONARYNAME}}</option>
                                </select>

                                </div>
                                <div *ngIf="myForm.get('ProficiencyLevel').touched">
                                    <span class="text-danger" *ngIf="myForm.get('ProficiencyLevel').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                             
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName.ReadLanguage}}  </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input type="checkbox" formControlName="ReadL">
                                   
                                </div>
                                
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName.WriteLanguag}} </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input type="checkbox" formControlName="WriteL">
                                    
                                </div>
                              
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName.SpeakLanguagee}}  </label>
                                <div class="col-md-8 inputGroupContainer">
                                    <div class="input-group">
                                        <input type="checkbox" formControlName="SpeakL">
                                        
                                    </div>
                                  
                                </div>
                        </div>
                      
                    </fieldset>
                </form>

            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-danger btn-sm" id="md_close_lan" (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>