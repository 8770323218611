<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.Floors}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right mb-1">
                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                              (click)="addArray()"  data-bs-target="#myModal">Add</button>
                                        </div>
                                        <div *ngIf="table.length">
                                            <table id="patergraph" datatable
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <!-- <th>S.No </th> -->
                                                        <th>Campus Name</th>
                                                        <th>Block Name</th>
                                                        <th> Floor Name</th>
                                                        <th>Status</th>
                                                        <th>Action</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <!-- <td></td> -->
                                                        <td>{{item.campus_name}}</td>
                                                        <td>{{item.block_name}}</td>
                                                        <td>{{item.floor_name}}</td>
                                                        <td>{{item.status == true ? "Active" : "Inactive"}}</td>

                                                        <td style="display: flex; cursor: pointer;">
                                                            <span data-bs-toggle="modal" data-bs-target="#myModal"
                                                                src="" class="edit_icon" (click)="edit(item.floor_id)">
                                                                <i class="fas fa-pen pt-1 mt-1"></i></span>
                                                        </td>

                                                    </tr>
                                                </tbody>

                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




<div class="modal" id="myModal" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <div class="modal-header py-2">
                <h6 *ngIf="!isEdit" class=" modal-title w-100">{{headingName.AddFloors}}</h6>
                <h6 *ngIf="isEdit" class=" modal-title w-100"> {{headingName.EditFloors}}</h6>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>


            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName.Campus}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName="campus_id"
                                        (change)="campusChange($any($event).target.value)"
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" [selected]="true">Select</option>
                                        <option *ngFor="let item of campus" [value]='item.campus_id'>
                                            {{item.campus_name}}
                                        </option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('campus_id').touched">
                                    <span class="text-danger" *ngIf="myForm.get('campus_id').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName.Block}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName="block_id"
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option *ngFor="let item of block" [value]='item.block_id'>
                                            {{item.block_name}}
                                        </option>

                                    </select>
                                </div>
                                <div *ngIf="myForm.get('block_id').touched">
                                    <span class="text-danger" *ngIf="myForm.get('block_id').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>




                        <div *ngIf="myForm.controls['Floor']">
                            <div formArrayName='Floor'>
                                <div class="form-group row pt-4"
                                    *ngFor="let item of myForm.controls['Floor'].controls;let i=index"
                                    [formGroupName]="i">
                                    <label class="col-md-4 control-label">
                                        {{labelName.FloorName}} <span class="text-danger">*</span></label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input class="form-control" type="text" formControlName="floor_name" onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">
                                        </div>
                                        <br>
                                        <div>
                                            <input type="checkbox" class="col-md-1 " [value]="1"
                                                formControlName="amenities1">
                                            <label class="col-md-3 control-label ">
                                                {{labelName.Water}} </label>
                                            <input type="checkbox" class="col-md-1 " [value]="2"
                                                formControlName="amenities2">

                                            <label class="col-md-3 control-label">
                                                {{labelName.Electricity}} </label>
                                            <input type="checkbox" class="col-md-1 " [value]="3"
                                                formControlName="amenities3">
                                            <label class="col-md-3 control-label">
                                                {{labelName.FireExit}}</label>
                                            <!-- <label formArrayName="Floor" *ngFor="let Floor of amenities; let i = index">
                                                    <input type="checkbox" [value]="Floor.id" [formControlName]="i">
                                                    {{Floor.name}}
                                                  </label> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName.Status}} <span class="text-danger">*</span></label>
                                <div class="col-md-8 inputGroupContainer">
                                    <div class="input-group">
                                        <strong>:</strong> &nbsp;
                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                        formControlName="status">
                                        <option value="" selected>Select</option>
                                        <option value=true>Active</option>
                                        <option value=false>InActive </option>
                                    </select>
                                </div>
                            </div>
                        </div>


                    </fieldset>
                </form>
                <!-- <div class="col-md-6 offset-md-3 mt-4">
                    <div class="float-end" *ngIf="!isEdit">
                        <button  class="btn btn-di float-end btn btn-save btn-sm" *ngIf="isVisable()"
                            (click)="delete()">-</button>&nbsp;&nbsp;
                        <button class="btn btn-ai float-end btn btn-save btn-sm " *ngIf="isAdd()" (click)="add()">+
                        </button>

                    </div>

                </div> -->
                <div class="row mt-4" *ngIf="!isEdit">
                    <div class="col-md-12 ">
                        <div class="float-end">
                            <button class="btn btn-di float-end" *ngIf="isVisable()" (click)="delete()"><i
                                    class="fas fa-trash"></i></button>
                            <button class="btn btn-ai float-end" *ngIf="isAdd()" (click)="add()">Add
                                Item</button>
                        </div>
                    </div>
                </div>

            </div>

          

            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-sm btn-danger" id='md_close' (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>



        </div>

    </div>

</div>