import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-fee-type',
  templateUrl: './fee-type.component.html',
  styleUrls: ['./fee-type.component.css']
})
export class FeeTypeComponent implements OnInit {
  table: Array<any> = [];
  headingName: any = {};
  constructor(private commonService: CommonService) {
    this.loadStudent();
    this.loadHeadings();
  }

  ngOnInit(): void {
  }

  loadHeadings() {
    this.headingName = {};
    this.commonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/4217/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  loadStudent() {
    let payload = {

    }
    this.commonService.postCall('FeesType/LoadStudentFeesDetails', payload).subscribe((res: any) => {
      this.table = res;
    })
  }
}
