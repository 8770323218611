<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.FacultyFeedback}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right mb-1">
                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                                data-bs-target="#myModal" [disabled]="isAdd">Feedback Form</button>
                                                <!-- [disabled]="isAdd" -->
                                        </div>
                                        <div *ngIf="table.length">
                                            <table id="patreggraph" datatable
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th>
                                                            Course Name</th>
                                                      
                                                        <th>Academic Year</th>

                                                        <th>
                                                            Semester
                                                        </th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                         <td>{{item.course_name}}</td>
                                                        <td>{{item.academic_name}}</td>
                                                        <td>{{item.semester}}</td>
                                                  


                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="myModal" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header py-2">
                <h6 class="modal-title w-100"> {{headingName.AddFeedback}}</h6>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>
            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row">
                            <label class="col-md-4 control-label">{{labelName.Course}}<span class="text-danger">*</span> </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select class="form-control form-control-sm shadow-sm rounded-lg"
                                        formControlName="course_id" (change)="getSubjectsbyCourse($event)">
                                        <option value="" [selected]="true">select</option>
                                        <option *ngFor="let item of courses" [value]="item.COURSE_ID">
                                            {{item.COURSE_NAME}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label">{{labelName.AcademicYear}}<span class="text-danger">*</span>
                            </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select formControlName="academic_year_id"
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option *ngFor="let academic of academicYearDropdown"
                                            [value]='academic.ACADEMIC_ID'>
                                            {{academic.ACADEMIC_NAME}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label">{{labelName.Semester}}<span class="text-danger">*</span> </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select formControlName="semester_id"
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option *ngFor="let semester of semesterDropdown"
                                            [value]='semester.DICTIONARYID'>
                                            {{semester.DICTIONARYNAME}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label">{{labelName.CourseYear}}<span class="text-danger">*</span> </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select class="form-control form-control-sm shadow-sm rounded-lg"
                                        formControlName="course_year">
                                        <option value="" [selected]="true">select</option>
                                        <option *ngFor="let item of courseYearDropdown"
                                        [value]="item.DICTIONARYID">
                                        {{item.DICTIONARYNAME}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label">{{labelName.Subject}}<span class="text-danger">*</span> </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select class="form-control form-control-sm shadow-sm rounded-lg"
                                        formControlName="subject_id" (change)="subjectChange($event)">
                                        <option value="" [selected]="true">select</option>
                                        <option *ngFor="let item of subjectDropdown"
                                        [value]='item.SUBJECT_ID'>{{item.SUBJECT_NAME}}
                                    </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label">{{labelName.Trainer}}<span class="text-danger">*</span> </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select formControlName="faculty_id"
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option *ngFor="let trainer of trainerDropdown"
                                            [value]='trainer.userid'>
                                            {{trainer.trainer_name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row pt-4" >
                            <div class="col-xl-4">
                                <div><span class="text-danger">*</span> Q1.Punctuality and discipline
                                </div>
                                <div style="margin-left: 30px;" *ngFor="let item of FirstArray">


                                    <input type="radio" name="answer"  [value]="item.answer_id" formControlName="answer">
                                    &nbsp; <label >{{item.answer}}</label> <br>

                                    <!-- <input type="radio" name="answer" id="2" value="veryGood"
                                        formControlName="answer">&nbsp; <label for="veryGood">Very Good</label> <br>

                                    <input type="radio" name="answer" id="3" value="Good"
                                        formControlName="answer">&nbsp;<label for="Good">Good</label> <br>

                                    <input type="radio" name="answer" id="4" value="Poor"
                                        formControlName="answer">&nbsp;<label for="Poor">Poor</label> <br>

                                    <input type="radio" name="answer" id="5" value="VeryPoor"
                                        formControlName="answer">&nbsp; <label for="VeryPoor">Very poor</label> -->

                                </div>
                            </div>
                            <div class="col-xl-4">
                                <div><span class="text-danger">*</span>Q2. Domain Knowledge
                                </div>
                                <div style="margin-left: 30px;" *ngFor="let item of SecondArray" >
                                  
                                    <input type="radio" name="answerOne" [value]="item.answer_id" formControlName="answerOne">
                                    &nbsp; <label >{{item.answer}}</label> <br>

                                </div>
                            </div>
                            <div class="col-xl-4">
                                <div><span class="text-danger">*</span>Q3. Presentation required skills and interaction
                                    with the students
                                </div>
                                <div style="margin-left: 30px;" *ngFor="let item of ThirdArray" >
                                  
                                    <input type="radio" name="answerTwo" [value]="item.answer_id" formControlName="answerTwo">
                                    &nbsp; <label >{{item.answer}}</label> <br>

                                </div>
                            </div>
                        </div>
                        <div class="row pt-4">
                            <div class="col-xl-4">
                                <div><span class="text-danger">*</span>Q4. Able to resolve difficulties
                                </div>
                                <div style="margin-left: 30px;" *ngFor="let item of FourthArray" >
                                  
                                    <input type="radio" name="answerThree" [value]="item.answer_id" formControlName="answerThree">
                                    &nbsp; <label >{{item.answer}}</label> <br>

                                </div>
                            </div>

                            <div class="col-xl-4">
                                <div><span class="text-danger">*</span>Q5. Effective use of teaching aids
                                </div>
                                <div style="margin-left: 30px;" *ngFor="let item of FifthArray" >
                                  
                                    <input type="radio" name="answerFour" [value]="item.answer_id" formControlName="answerFour">
                                    &nbsp; <label >{{item.answer}}</label> <br>

                                </div>
                            </div>
                            <div class="col-xl-4">
                                <div><span class="text-danger">*</span>Q6. Faculty member completes the syllabus in
                                    time?
                                </div>
                                <div style="margin-left: 30px;" *ngFor="let item of SixthArray" >
                                  
                                    <input type="radio" name="answerFive" [value]="item.answer_id" formControlName="answerFive">
                                    &nbsp; <label >{{item.answer}}</label> <br>

                                </div>
                            </div>

                        </div>

                        <div class="row pt-4">
                            <div class="col-xl-4">
                                <div><span class="text-danger">*</span>Q7. Teacher offer assistance on counselling to
                                    needy students
                                </div>
                                <div style="margin-left: 30px;" *ngFor="let item of SeventhArray" >
                                  
                                    <input type="radio" name="answerSix" [value]="item.answer_id" formControlName="answerSix">
                                    &nbsp; <label >{{item.answer}}</label> <br>

                                </div>
                            </div>
                            <div class="col-xl-4">
                                <div><span class="text-danger">*</span>Q8. Teacher ensures learners activity and problem
                                    solving ability in class?
                                </div>
                                <div style="margin-left: 30px;" *ngFor="let item of eighthArray" >
                                  
                                    <input type="radio" name="answerSeven" [value]="item.answer_id" formControlName="answerSeven">
                                    &nbsp; <label >{{item.answer}}</label> <br>

                                </div>
                            </div>
                            <div class="col-xl-4">
                                <div><span class="text-danger">*</span>Q9. Teacher is capable of keeping class under
                                    discipline and control
                                </div>
                                <div style="margin-left: 30px;" *ngFor="let item of NinthArray" >
                                  
                                    <input type="radio" name="answerEight" [value]="item.answer_id" formControlName="answerEight">
                                    &nbsp; <label >{{item.answer}}</label> <br>

                                </div>
                            </div>
                        </div>


                        <div class="row pt-4">
                            <div class="col-xl-4">
                                <div><span class="text-danger">*</span>Q10. Knowledge of faculty beyond the syllabus
                                </div>
                                <div style="margin-left: 30px;" *ngFor="let item of TenthArray" >
                                  
                                    <input type="radio" name="answerNine" [value]="item.answer_id" formControlName="answerNine">
                                    &nbsp; <label >{{item.answer}}</label> <br>

                                </div>
                            </div>
                            <div class="col-xl-4">
                                <div><span class="text-danger">*</span>Q11. Quality of self develop Videos by the
                                    Faculty
                                </div>
                                <div style="margin-left: 30px;" *ngFor="let item of EleventhArray" >
                                  
                                    <input type="radio" name="answerTen" [value]="item.answer_id" formControlName="answerTen">
                                    &nbsp; <label >{{item.answer}}</label> <br>

                                </div>
                            </div>

                            <div class="col-xl-4">
                                <div><span class="text-danger">*</span>Q12. Quality of online teaching, Theory and
                                    Tutorials during Pandemic
                                </div>
                                <div style="margin-left: 30px;" *ngFor="let item of TwelvethArray" >
                                  
                                    <input type="radio" name="answerEleven" [value]="item.answer_id" formControlName="answerEleven">
                                    &nbsp; <label >{{item.answer}}</label> <br>

                                </div>
                            </div>
                        </div>


                        <div class="row pt-4">
                            <div class="col-xl-4">
                                <div><span class="text-danger">*</span>Q13. Quality of practical explain in online mode
                                    during Pandemic.
                                </div>
                                <div style="margin-left: 30px;" *ngFor="let item of ThirteenthArray" >
                                  
                                    <input type="radio" name="answerTwelveth" [value]="item.answer_id" formControlName="answerTwelveth">
                                    &nbsp; <label >{{item.answer}}</label> <br>

                                </div>
                            </div>
                            <div class="col-xl-4">
                                <div><span class="text-danger">*</span>Q14. Effectiveness of Mentoring provided
                                </div>
                                <div style="margin-left: 30px;" *ngFor="let item of Fourtenth" >
                                  
                                    <input type="radio" name="answerThirteenth" [value]="item.answer_id" formControlName="answerThirteenth">
                                    &nbsp; <label >{{item.answer}}</label> <br>

                                </div>
                            </div>
                            <div class="col-xl-4">
                                <div><span class="text-danger">*</span>Q15. Overall rating of the faculty
                                </div>
                                <div style="margin-left: 30px;" *ngFor="let item of Fifteenth" >
                                  
                                    <input type="radio" name="answerFourteenth" [value]="item.answer_id" formControlName="answerFourteenth">
                                    &nbsp; <label >{{item.answer}}</label> <br>

                                </div>
                            </div>
                        </div>

                    </fieldset>
                </form>
            </div>
            <div class="modal-footer">
                <button class="btn btn-save btn-sm"  type="button"  (click)="onSubmit(myForm)">Save</button>
                <button class="btn btn-danger btn-sm" type="button" id='md_close' data-bs-dismiss="modal"
                    (click)="close()">Close</button>
                    <!-- [disabled]="myForm.invalid" -->
            </div>
        </div>
    </div>
</div>