<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.ParticipantsList}}</h5>
                                    </div>
                                    <div class="card-body">   
                                        <div class="row">
                                            <div *ngIf='isSuperAdmin' class="col-md-3">
                                                <label for="sel1">{{labelName.TenantName}} : </label>
                                                <select [(ngModel)]='tId ' (change)="loadParticipantsList()"
                                                    class="form-control form-control-sm shadow-sm rounded-lg"
                                                    id="sel1">
                                                    <option value="" [selected]="true">select</option>
                                                    <option *ngFor="let item of tenanates" [value]='item.TNT_CODE'>
                                                        {{item.TNT_NAME}}</option>
                                                </select>

                                            </div>
                                            </div>         
                                        <div class="text-right mb-2">                                           
                                            <button  (click)="add()" type="button"
                                                class="btn btn-primary">Mark Attendance</button>
                                        </div>                              
                                        <div *ngIf="table.length">
                                            <table id="patreggraph" datatable
                                                class="table table-striped table-bordered mt-2 pt-2 table-sm small display"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624fd7; color:#ffffff;">
                                                    <tr>
                                                        <th> S.No </th>
                                                        <th> Event </th>
                                                        <th> Registration No </th>
                                                        <th> Type </th>
                                                        <th> Name </th>
                                                        <th> Participation Status </th>
                                                        <th> Participated on  </th>
                                                        <th> Award Status </th>
                                                        <th> Rank </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table;let i = index">
                                                        <td>{{i+1}}</td>
                                                        <td>{{item.event_name}}</td>
                                                        <td>{{item.registration_no}}</td>
                                                        <td>{{item.type}}</td>
                                                        <td>{{item.name}}</td>
                                                        <td>{{item.participant_status}}</td>
                                                        <td>{{item.participation_timestamp | date : "dd/MM/yyyy"}}</td>
                                                        <td>{{item.award_status}}</td>
                                                        <td>{{item.rank}}</td>
                                                        <!-- <td style="display: flex; text-align: center;cursor: pointer;">
                                                            <a (click)="edit(item.route_buses_id)"><i
                                                                    class="zmdi zmdi-edit zmdi-hc-2x ">
                                                                </i></a>
                                                        </td> -->
                                                        <!-- <td>
                                                            <div class="btn-group">
                                                                <button type="button" class="btn btn-warning dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                  Action
                                                                </button>
                                                                <div class="dropdown-menu">
                                                                  <a class="dropdown-item" href="javascript:;">Close Event</a>
                                                                  <a class="dropdown-item" [routerLink]="['/home/eventRegistrationsList']">Registrations</a>
                                                                  <a class="dropdown-item" [routerLink]="['/home/eventParticipantsList']">Participants</a>
                                                                  <a class="dropdown-item" href="javascript:;">Approve</a>
                                                                  <a class="dropdown-item" href="javascript:;">Reject</a>
                                                                  <a class="dropdown-item" href="javascript:;">Edit</a>                                                                  
                                                                </div>
                                                              </div>
                                                        </td> -->
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>