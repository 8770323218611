<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.Subject}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div *ngIf='isSuperAdmin' class="col-md-3">
                                                <label for="sel1">{{labelName.TenantName}} : </label>
                                                <select [(ngModel)]='tId ' (change)="changeTname()"
                                                    class="form-control form-control-sm shadow-sm rounded-lg"
                                                    id="sel1">
                                                    <option value="" [selected]="true">select</option>
                                                    <option *ngFor="let item of tenanates" [value]='item.TNT_CODE'>
                                                        {{item.TNT_NAME}}</option>
                                                </select>

                                            </div>
                                            <div class="col-md-3 ">
                                                <label for="sel1">{{labelName.Coursename}}
                                                </label>
                                                <select class="form-control form-control-sm shadow-sm rounded-lg"
                                                    id="sel1" [(ngModel)]="courseId" (change)="onSearch()">
                                                    <option value="" [selected]="true">Select</option>
                                                    <option *ngFor="let course of courses" [value]='course.COURSE_ID'>
                                                        {{course.COURSE_NAME}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-md-3 pt-4" *ngIf="isSearch">
                                                <button type="button" (click)="onClear()"
                                                    class="btn btn-sm  btn-save">Clear</button>
                                            </div>
                                        </div>
                                        <div class="text-right mb-1">
                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                                data-bs-target="#myModal">Add</button>
                                        </div>
                                        <div *ngIf="table.length">
                                            <table id="patreggraph" datatable
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th>Subject</th>
                                                        <th>Description</th>
                                                        <th>Course Name</th>
                                                        <th>Credits</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.SUBJECT_NAME}}</td>
                                                        <td>{{item.SUBJECT_DESCRIPTION}}</td>
                                                        <td>{{item.COURSE_NAME}}</td>
                                                        <td>{{item.CREDITS}}</td>
                                                        <td>{{item.SubjectStatus}}</td>
                                                        <td style="display: flex;">
                                                            <span data-bs-toggle="modal" data-bs-target="#myModal"
                                                                src="" class="edit_icon" (click)="edit(item)">
                                                                <i class="fas fa-pen pt-1 mt-1"></i></span>
                                                        </td>


                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="modal" id="myModal" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog  modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header py-2">
                <h6 *ngIf="!isEdit" class="modal-title w-100"> {{headingName.AddSubject}}</h6>
                <h6 *ngIf="isEdit" class="modal-title w-100"> {{headingName.EditSubject}}</h6>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row">
                            <label class="col-md-4 control-label">{{labelName?.Coursename}}</label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select [attr.disabled]="this.isEdit"
                                        class="form-select form-select-sm shadow-sm rounded-lg"
                                        formControlName="COURSE_ID">
                                        <option value="" [selected]="true">select</option>
                                        <option *ngFor="let item of courses" [value]="item.COURSE_ID">
                                            {{item.COURSE_NAME}}</option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('COURSE_ID').touched">
                                    <span class="text-danger" *ngIf="myForm.get('COURSE_ID').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.SubjectName}}<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input [attr.disabled]="isEdit" placeholder="" class="form-control"
                                        formControlName="SUBJECT_NAME"
                                        onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">
                                </div>
                                <div *ngIf="myForm.get('SUBJECT_NAME').touched">
                                    <span class="text-danger" *ngIf="myForm.get('SUBJECT_NAME').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.Description}}<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="" class="form-control" formControlName="SUBJECT_DESCRIPTION"
                                        onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">
                                </div>
                                <div *ngIf="myForm.get('SUBJECT_DESCRIPTION').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('SUBJECT_DESCRIPTION').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>


                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.Credits}}<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input [attr.disabled]="isEdit" type="number" min="1" max="20" placeholder=""
                                        class="form-control" formControlName="CREDITS">
                                </div>
                                <div *ngIf="myForm.get('CREDITS').touched">
                                    <span class="text-danger" *ngIf="myForm.get('CREDITS').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>


                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label">{{labelName?.Status}}</label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                        formControlName="SubjectStatus">
                                        <option [ngValue]='1'>Active</option>
                                        <option [ngValue]='0'>InActive </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-danger btn-sm" id='md_close' (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>