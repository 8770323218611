import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-issue-items',
  templateUrl: './issue-items.component.html',
  styleUrls: ['./issue-items.component.css']
})
export class IssueItemsComponent implements OnInit {
  myForm: FormGroup;
  checkboxs: Array<any> = [];
  selectedList: Array<any> = [];
  Id: number;
  sId: any;
  labelName: any = {};
  headingName: any = {};
  workItemId: any;

  constructor(private fb: FormBuilder, private CommonService: CommonService, private toastr: ToastrService, private route: Router, private active: ActivatedRoute) {
    active.queryParams.subscribe((res) => {
      this.Id = res.id;
      this.sId = res.stuId;
      this.workItemId=res.wId
      console.log(this.Id);
    })
    this.loadData();
    this.getLabels();
    this.loadHeadings();

  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      items: [''],
      issued_on: ['', Validators.required],
      created_by: ['', Validators.required],

    })
  }
  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  loadData() {
    let payload = {
      "tnt_code": localStorage.getItem('TenantCode'),
    }
    this.CommonService.postCall('EmsHostelStudents/GetHostelItemsList', payload).subscribe((res: any) => {
      this.checkboxs = res
      console.log(res);

    })
    let getCheck = []
    getCheck = this.checkboxs.filter(a => a.status == false);
    console.log(getCheck);

    getCheck.forEach(element => {
      this.myForm.patchValue({
        items: element['items']
      })

    });
  }

  changeCheck(event: any) {
    let itemId = event.target.value;
    // console.log(itemId);
    // console.log(event.target.checked);
    let obj = {
      item_id: itemId
    }
    if (event.target.checked) {
      this.selectedList.push(obj);
    } else {
      this.selectedList = this.selectedList.filter(m => m.item_id != itemId);
    }
    console.log(this.selectedList);

  }


  onSubmit(form: FormGroup) {
    let value: any = form.value;
    let selectedItem = [];
    this.checkboxs.forEach(m => {
      if (m.status)
        selectedItem.push({ item_id: m.item_id })
    });
    if (selectedItem.length == 0) {
      this.toastr.warning('Please select atlist one item.');
      return;
    }
    let payload = {
      "issued_on": value.issued_on,
      "issued_by": 123456,
      "tnt_code": localStorage.getItem('TenantCode'),
      "modified_by": localStorage.getItem('UserId'),
      "student_id": this.sId,
      "items": selectedItem
    }
    console.log(payload);

    this.CommonService.postCall('EmsHostelStudents/InsertItems', payload).subscribe((res: any) => {
      this.route.navigate(['home/hostel-students']);
      this.toastr.success(" Created Successfully");
    }, err => {
      this.toastr.error(err.error ? err.error : 'Not created')

    });

  }



  close() {
    this.myForm.reset();
    this.route.navigate([`home/hostel-students/${this.workItemId}`]);
  }
}
