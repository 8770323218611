<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0"> {{headingName.HolidayNotification}} </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right mb-1">
                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                                data-bs-target="#myModal">Add</button>
                                        </div>
                                        <div *ngIf="table.length">
                                            <table id="patergraph" datatable
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th>
                                                            Holiday Date </th>
                                                        <th>Description</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.HOLIDAY_DATE | date :'dd/mm/yyyy'}}</td>
                                                        <td>{{item.DESCRIPTION}}</td>

                                                    </tr>
                                                </tbody>

                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal" id="myModal" data-backdrop="static" data-keyboard="false">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header py-2">
                            <h4 *ngIf="!isEdit" class=" modal-title w-100">{{headingName.SendHolidayNotification}}</h4>

                            <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i class="fas fa-times"></i></button>
                        </div>
                        <div class="modal-body">
                            <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                                <fieldset>
                                    <div class="form-group row">
                                        <label class="col-md-4 control-label">  {{labelName.Course}} <span
                                                class="text-danger">*</span></label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group">
                                                <select  class="form-select form-select-sm shadow-sm rounded-lg"
                                                    id="sel1" (change)="courseChange($event)"
                                                    formControlName="courseName">
                                                    <option value="" [selected]="true">select</option>
                                                    <option *ngFor="let course of courses" [value]='course.COURSE_ID'>
                                                        {{course.COURSE_NAME}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row pt-4">
                                        <label class="col-md-4 control-label">  {{labelName.CourseSchedule}}<span
                                                class="text-danger">*</span></label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group">
                                                <select name="courseSchedule" id="courseSchedule"
                                                    formControlName="courseSchedule"
                                                    class="form-select form-select-sm shadow-sm rounded-lg">
                                                    <option value="" [selected]="true">Select</option>
                                                    <option *ngFor="let courseSchedule of Schedules"
                                                        [value]="courseSchedule.COURSESHD_ID">
                                                        {{courseSchedule.COURSESHD_NAME}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row pt-4">
                                        <label class="col-md-4 control-label"> {{labelName.Date}}<span class="text-danger">*</span>
                                        </label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group">
                                                <input formControlName="HOLIDAY_DATE" type="date"
                                                    class="form-control form-control-sm shadow-sm rounded-lg">

                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row pt-4">
                                        <label class="col-md-4 control-label"> {{labelName.Title}}<span
                                                class="text-danger">*</span> </label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group">
                                                <input placeholder="" formControlName='TITLE'
                                                    class="form-control">

                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row pt-4">
                                        <label class="col-md-4 control-label">  {{labelName.Description}}<span
                                                class="text-danger">*</span> </label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group">
                                                <textarea placeholder="" formControlName='DESCRIPTION'
                                                    class="form-control"></textarea>

                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                        <div class="modal-footer">
                            <button type="button" *ngIf="!isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                                [disabled]="myForm.invalid">Send</button>

                            <button type="button" class="btn btn-sm btn-danger" id='md_close' (click)="close()"
                                data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>