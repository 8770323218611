<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">

                    <div class="container-fluid">

                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.VendorsPayments}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-xl-6 offset-xl-3">
                                                <form class="well form-horizontal" [formGroup]="myForm"
                                                    (ngSubmit)="onSubmit(myForm)">
                                                    <fieldset>
                                                        <div class="form-group row">
                                                            <label class="col-md-4 control-label line_2 rem_1">
                                                                {{labelName?.Vendors}}<span class="text-danger">*</span></label>
                                                            <div class="col-md-8 inputGroupContainer">
                                                                <div class="input-group">
                                                                    <strong>:</strong> &nbsp;
                                                                    <select  formControlName="vendor_id" (change)="changeVendor($any($event).target.value)"
                                                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                                                        <option value="" selected>Select</option>
                                                                        <option *ngFor="let item of itemsDropDown"
                                                                            [value]="item.vendor_id" >
                                                                            {{item.vendor_name}}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                                <div *ngIf="myForm.get('vendor_id').touched">
                                                                    <span class="text-danger"
                                                                        *ngIf="myForm.get('vendor_id').hasError('required')">
                                                                        This field is required.
                                                                    </span>

                                                                </div>

                                                            </div>
                                                        </div>&nbsp;&nbsp;
                                                        <div class="form-group row">
                                                            <label class="col-md-4 control-label line_2 rem_1">
                                                                {{labelName?.IsItemRecieved}}</label>
                                                            <div class="col-md-8 inputGroupContainer">
                                                                <div class="input-group">
                                                                    <strong>:</strong> &nbsp;
                                                                    <input type="checkbox" (change)="onCheck($event)">
                                                                </div>

                                                            </div>
                                                        </div>&nbsp;&nbsp;
                                                        <div class="form-group row" *ngIf="isCheck">
                                                            <label class="col-md-4 control-label line_2 rem_1">
                                                                {{labelName?.AmountTobePaid}} <span class="text-danger">*</span></label>
                                                            <div class="col-md-8 inputGroupContainer">
                                                                <div class="input-group">
                                                                    <strong>:</strong> &nbsp;
                                                                    <input placeholder="" class="form-control" disabled
                                                                       value="{{balanceAmount}}" />
                                                                </div>

                                                            </div>
                                                        </div>&nbsp;&nbsp;
                                                        <div class="form-group row">
                                                            <label class="col-md-4 control-label line_2 rem_1">
                                                                {{labelName?.PaidAmount}} <span class="text-danger">*</span></label>
                                                            <div class="col-md-8 inputGroupContainer">
                                                                <div class="input-group">
                                                                    <strong>:</strong> &nbsp;
                                                                    <input placeholder="" class="form-control" 
                                                                        formControlName="paid_amount" />
                                                                </div>

                                                            </div>
                                                        </div>&nbsp;&nbsp;
                                                        <div class="form-group row">
                                                            <label class="col-md-4 control-label line_2 rem_1">
                                                                {{labelName?.PaidDate}} <span class="text-danger">*</span> </label>
                                                            <div class="col-md-8 inputGroupContainer">
                                                                <div class="input-group">
                                                                    <strong>:</strong> &nbsp;
                                                                    <input type="date" placeholder=""
                                                                        class="form-control"
                                                                        formControlName="payment_date" />
                                                                </div>
                                                                <div *ngIf="myForm.get('payment_date').touched">
                                                                    <span class="text-danger"
                                                                        *ngIf="myForm.get('payment_date').hasError('required')">
                                                                        This field is required.
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>&nbsp;&nbsp;
                                                        <div class="form-group row">
                                                            <label class="col-md-4 control-label line_2 rem_1">
                                                                {{labelName?.PaymentType}} <span class="text-danger">*</span></label>
                                                            <div class="col-md-8 inputGroupContainer">
                                                                <strong>:</strong> &nbsp;
                                                                <div class="form-check form-check-inline">
                                                                    <input class="form-check-input" value="Cash"
                                                                        formControlName="payment_mode" type="radio"
                                                                        name="payment_mode" id="inlineRadio1" checked
                                                                        (change)="cheque($event)">
                                                                    <label class="form-check-label"
                                                                        for="inlineRadio1">Cash</label>
                                                                </div>
                                                                <div class="form-check form-check-inline">
                                                                    <input class="form-check-input" value="Cheque"
                                                                        formControlName="payment_mode" type="radio"
                                                                        name="payment_mode" id="inlineRadio2"
                                                                        (change)="cheque($event)">
                                                                    <label class="form-check-label"
                                                                        for="inlineRadio2">Cheque</label>
                                                                </div>

                                                            </div>
                                                        </div>&nbsp;&nbsp;
                                                        <div class="form-group row" *ngIf="isChaque">
                                                            <label class="col-md-4 control-label line_2 rem_1">
                                                                {{labelName?.ChequeNumber}} <span class="text-danger">*</span></label>
                                                            <div class="col-md-8 inputGroupContainer">
                                                                <div class="input-group">
                                                                    <strong>:</strong> &nbsp;
                                                                    <input placeholder="" class="form-control"
                                                                        formControlName="cheque_number" />
                                                                </div>
                                                                <div *ngIf="myForm.get('cheque_number').touched">
                                                                    <span class="text-danger"
                                                                        *ngIf="myForm.get('cheque_number').hasError('required')">
                                                                        This field is required.
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>&nbsp;&nbsp;
                                                        <div class="form-group row" *ngIf="isChaque">
                                                            <label class="col-md-4 control-label line_2 rem_1">
                                                                {{labelName?.ChequeDate}} <span class="text-danger">*</span></label>
                                                            <div class="col-md-8 inputGroupContainer">
                                                                <div class="input-group">
                                                                    <strong>:</strong> &nbsp;
                                                                    <input type="date" placeholder=""
                                                                        class="form-control"
                                                                        formControlName="cheque_date" />
                                                                </div>
                                                                <div *ngIf="myForm.get('cheque_date').touched">
                                                                    <span class="text-danger"
                                                                        *ngIf="myForm.get('cheque_date').hasError('required')">
                                                                        This field is required.
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>&nbsp;&nbsp;
                                                    </fieldset>
                                                </form>
                                            </div>
                                            <div class="text-center">
                                                <button type="button" class="btn  btn-sm btn-save"
                                                    (click)="onSubmit(myForm)" [disabled]="myForm.invalid">Save</button>
                                                &nbsp;
                                                <button type="button" class="btn btn-sm btn-danger" id='md_close'
                                                    (click)="close()">Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>