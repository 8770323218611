<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.PostStudentAttendance1}} </h5>
                                    </div>
                                    <div class="card-body">
                                        <form class="well form-horizontal" [formGroup]="myForm"
                                            (ngSubmit)="onSubmit(myForm)">
                                            <fieldset>
                                                <div class="row">

                                                    <div class="form-group col-md-3">
                                                        <label>{{labelName.AttendanceType}}</label>
                                                        <div class="input-group">
                                                            <select formControlName="attendance_type"
                                                                (change)="priodeChange($event)"
                                                                placeholder="Select Attendance Type"
                                                                class="form-select form-select-sm shadow-sm rounded-lg">
                                                                <option value='' selected>Select</option>
                                                                <option value='daywise'>Day Wise
                                                                </option>
                                                                <!-- <option value='priodewise' disabled>Period Wise</option> -->
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="form-group col-md-3">
                                                        <label>{{labelName.SelectMonth}}</label>
                                                        <div class=" inputGroupContainer">
                                                            <div class="input-group"> 
                                                                <input placeholder="Select Month" [max]="todayDate"
                                                                    class="form-control form-control-sm shadow-sm rounded-lg" 
                                                                    type="date" formControlName="date" >                                                                  

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-group col-md-3">
                                                        <label>{{labelName.Campus}}</label>
                                                        <div class="input-group">
                                                            <select
                                                                class="form-select form-select-sm shadow-sm rounded-lg"
                                                                formControlName="campus_id">
                                                                <option value='' selected>Select</option>
                                                                <option *ngFor="let item of campusDropdown"
                                                                    [value]="item.campus_id">
                                                                    {{item.campus_name}}</option>
                                                            </select>

                                                        </div>
                                                    </div>


                                                    <div class="form-group col-md-3">
                                                        <label>{{labelName.Course}}</label>
                                                        <div class="input-group">
                                                            <select formControlName="class_id" [(ngModel)]='courceId '
                                                                (change)="courceChange($any($event).target.value);getSubjectsbyCourse($any($event).target.value)"
                                                                class="form-select form-select-sm shadow-sm rounded-lg">
                                                                <option value='' selected>Select</option>
                                                                <option *ngFor="let course of cources"
                                                                    [value]='course.COURSE_ID'>
                                                                    {{course.COURSE_NAME}}
                                                                </option>
                                                            </select>
                                                        </div>

                                                    </div>

                                                    <div class="form-group col-md-3 ">
                                                        <label>{{labelName.Section}}</label>
                                                        <div class="input-group">
                                                            <select formControlName="section_id"
                                                                class="form-select form-select-sm shadow-sm rounded-lg">
                                                                <option value='' selected>Select </option>
                                                                <option *ngFor="let schedul of scheduls"
                                                                    [value]='schedul.COURSESHD_ID'>
                                                                    {{schedul.COURSESHD_NAME}}</option>
                                                            </select>
                                                        </div>
                                                    </div>


                                                    <div *ngIf="isPeriod" class="form-group col-md-3 ">
                                                        <label>{{labelName.Periods}} </label>
                                                        <div class="input-group">
                                                            <select formControlName="period_id"
                                                                placeholder="Select Attendance Type"
                                                                class="form-select form-select-sm shadow-sm rounded-lg">
                                                                <option value='' selected>Select</option>
                                                                <option value='1'>Periods
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div *ngIf="isPeriod" class="form-group col-md-3 ">
                                                        <label>{{labelName.Subject}} </label>
                                                        <div class="input-group">
                                                            <select formControlName='subject_id'
                                                                class="form-select form-select-sm shadow-sm rounded-lg ">
                                                                <option value='' selected>Select</option>
                                                                <option *ngFor="let item of subjects"
                                                                    [value]='item.SUBJECT_ID'>
                                                                    {{item.SUBJECT_NAME}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>


                                                    <div class="form-group col-md-3 pt-4">
                                                        <div class="input-group">
                                                            <input placeholder="Search To Name"
                                                                class="form-control form-control-sm shadow-sm rounded-lg"
                                                                type="text" formControlName="name">
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3 pt-4">
                                                        <button type="button" [disabled]="myForm.invalid"
                                                            (click)="onSubmit(myForm)" class="btn btn-sm  btn-save"><i
                                                                class="fas fa-search"></i></button>
                                                    </div>

                                                </div>
                                            </fieldset>
                                        </form>
                                        <!-- <div>
                                            <button type="button" [disabled]="myForm.invalid" (click)="onSubmit(myForm)"
                                                class="btn btn-sm  btn-save"><i class="fas fa-search"></i></button>
                                        </div> -->

                                        <br>

                                        <div class="text-center">
                                            <p *ngIf="!isTable" style="color: red;">
                                                Search for Student to post attendance!</p>
                                        </div>


                                        <div *ngIf="table.length">
                                            <table id="addStudent" datatable 
                                            class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                            style="width:100%">
                                            <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                <tr>
                                                        <th><input class="form-check-input" type="checkbox" value="" *ngIf="!selectAll"
                                                              (change)="checkAll($event)" id=""></th>
                                                              <th>S.NO</th>
                                                              <th>Enrollment Number</th>
                                                        <th>Full Name</th>
                                                        <!-- <th>Gender</th> -->
                                                        <th>Course</th>
                                                        <th>Section</th>
                                                        <th>Status</th>
                                                        <th> Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table;let i =index">
                                                        <td ><input class="form-check-input" *ngIf="!item.checkstatus" [(ngmodel)]="item.checked" [checked]="item.checked"
                                                                (change)="checkAttendance(item,$event,i)"
                                                                type="checkbox" value="{{item.student_id}}" id=""></td>
                                                                <td>{{item.sno}}</td>
                                                                <td>{{item.enrollment_no}}</td>
                                                        <td>{{item.student_name }}</td>
                                                        <!-- <td>{{item.gender}}</td>    -->
                                                        <td>{{item.course_name }}</td>
                                                        <td>{{item.courseshd_name }}</td>
                                                        <td >{{item.attendance_status }}</td>
                                                        <td >
                                                            <button *ngIf="(item.attendance_status == 'Present')"
                                                            class="btn btn-sm btn-ai"
                                                            (click)="changeAttendance(item.student_id,'Absent') ">Absent</button>
                                                        <button *ngIf="(item.attendance_status == 'Absent')"
                                                            class="btn btn-sm btn-ai"
                                                            (click)="changeAttendance(item.student_id,'Present')">Present</button>
                                                        </td>
                                                    </tr>
                                                </tbody>

                                            </table>
                                        </div>
                                        <!-- <div class="text-center" *ngIf="!table.length">
                                            No Records To Dispaly
                                        </div> -->

                                        <!-- <div *ngIf="attendanceList.length">
                                            <table id="patreggraph" datatable *ngIf="!isTable"
                                                class="table table-striped table-bordered mt-2 pt-2 table-sm small display"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624fd7; color:#ffffff;">
                                                    <tr>
                                                        <th></th>
                                                        <th>Full Name</th>
                                                        <th>Gender</th>
                                                        <th>Class</th>
                                                        <th>Section</th>
                                                        <th>Status</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of attendanceList">
                                                        <td></td>
                                                        <td>{{item.student_name }}</td>
                                                        <td>{{item.gender}}</td>
                                                        <td>{{item.course_name }}</td>
                                                        <td>{{item.courseshd_name }}</td>
                                                        <td>{{item.attendance_status }}</td>
                                                        <td>
                                                            <button *ngIf="item.attendance_status == 'Present'"
                                                                class="btn btn-sm btn-ai"
                                                                (click)="changeAttendance(item.people_id,item.attendance_status)">Absent</button>
                                                            <button *ngIf="item.attendance_status == 'Absent'"
                                                                class="btn btn-sm btn-ai"
                                                                (click)="changeAttendance(item.people_id,item.attendance_status)">Present</button>
                                                        </td>

                                                    </tr>
                                                </tbody>

                                            </table>
                                        </div> -->
                                        <!-- <div class="text-center" *ngIf="!attendanceList.length">
                                            No Records To Dispaly
                                        </div> -->

                                        <div class="float-end pt-4">
                                            <button type="button" (click)="submitAttendance(myForm)" *ngIf="isCheck"
                                                class="btn btn-sm  btn-save">Submit</button>
                                            &nbsp;&nbsp;
                                            <button type="button" class="btn btn-sm btn-danger" (click)="close()"
                                                id="close">cancel</button>
                                        </div>

                                        <!-- <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>