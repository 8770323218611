import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { eventNames } from 'process';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-class-item-sales',
  templateUrl: './class-item-sales.component.html',
  styleUrls: ['./class-item-sales.component.css']
})
export class ClassItemSalesComponent implements OnInit {

  table: Array<any> = [];
  myForm: FormGroup;
  isEdit: boolean = false;
  campus: Array<any> = [];
  class: Array<any> = [];
  section: Array<any> = [];
  student: Array<any> = [];
  paymentType: Array<any> = [];
  Courses: Array<any> = [];
  courseschedule: Array<any> = [];
  isCampus: boolean = false;
  // campuses: Array<any> = [];
  employees: Array<any> = [];
  fAmount: number = 0;
  tAmount: number = 0;
  tDiscount: number;
  camId: any;
  campusId: any;
  checked: boolean;
  TenantCode = localStorage.TenantCode;
  id: any;
  courseId: any;
  cam: any;
  students: any;
  labelName: any = {};

  finalAmount: number;
  quantityhand: any = [];
  headingName: any = {};
  workItemId: any;
  constructor(private active: ActivatedRoute,private fb: FormBuilder, private CommonService: CommonService, private toastr: ToastrService) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadStudent();
    this.campusDropdown();
    this.loadcourses();
    this.getLabels();
    this.loadHeadings();

    // this.loadItems();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      campus_id: ['', Validators.required],
      course_id: ['', Validators.required],
      courseschedule_id: ['', Validators.required],
      student_id: ['', Validators.required],
      AMOUNT: ['', Validators.required],
      TOTAL_DISCOUNT: [''],
      FINAL_AMOUNT: ['', Validators.required],
      // PAYMENT_TYPE: [''],

    });
  }
  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  loadStudent() {
    let payload = {
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('EmsStudentSales/GetList', payload).subscribe((res: any) => {
      this.table = res;
    })
  }
  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }
  campusDropdown() {

    let payload = {
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('EmsCampus/CampusDropDownList', payload).subscribe((res: any) => {
      this.campus = res;
    })
  }
  campusChange(id: any) {
    this.cam = id
  }
  loadcourses() {
    // LoadCourseSchedule
    // Loadyear Loadcourses
    this.activeSpinner()
    let payaload = {
      "TENANT_CODE": localStorage.getItem('TenantCode')
    }
    this.CommonService.getAdminCourses().subscribe((res: any) => {
      this.Courses = res;
      this.deactivateSpinner();
    }, e => this.deactivateSpinner());
  }
  courseSchedule(id) {
    this.courseId = id;
    let payLoad = {
      "CourseId": id,
      'RoleId': localStorage.getItem('RoleId')
    }
    this.CommonService.postCall('CourseSchedule/GetAdminCourseSchedule', payLoad).subscribe((res: any) => {
      this.courseschedule = res;
    })
  }


  scheduleStudent(sid) {
    let payLoad = {
      "courseshd_id": sid
    }
    this.CommonService.postCall('Registration/GetStudentsListbyCourseSchedule', payLoad).subscribe((res: any) => {
      this.student = res;
    })
  }
  // campusValue(event){
  //   this.campusId == event.target.value
  // }

  studentTable(campusId: any) {
    this.students = [];
    this.isCampus = true;
    let payload = {
      "class_id": this.courseId,
      "campus_id": this.cam,
      "tnt_code": localStorage.getItem('TenantCode')

    }
    this.CommonService.postCall('EmsStudentSales/GetClassItemsList', payload).subscribe((res: any) => {
      this.students = res;
      this.students.map(a => a.checked = false);
    })

  }
  checkValue(event: any, fAmt: number = 0, id, qty) {
    // console.log(id,qty);

    let ob = {
      "item_id": id,
      "quantity": qty
    }
    // this.quantityhand.push(ob)
    // console.log(this.quantityhand);

    if (event.target.checked) {
      this.quantityhand.push(ob)
      this.fAmount = this.fAmount + fAmt;
      this.tAmount = this.tAmount + fAmt;

    } else {
      this.quantityhand = this.quantityhand.filter(e => e.item_id != id)
      this.fAmount = this.fAmount - fAmt;
      this.tAmount = this.tAmount - fAmt;
    }

  }
  finalDiscount(event) {
    this.finalAmount = this.fAmount * this.tDiscount / 100;
    this.tAmount = this.fAmount - this.finalAmount;
  }
  checkAll(event: any) {
    this.fAmount = 0;
    if (event.target.checked == true) {
      this.students.forEach(a => a.checked = true);
      this.students.forEach(e => {
        this.fAmount += e.final_amount;
        this.tAmount += e.final_amount;
        this.finalAmount = this.fAmount * this.tDiscount / 100;
        this.tAmount = this.fAmount - this.finalAmount;
      });

    }
    else {
      this.fAmount = 0;
      this.tAmount = 0;
      this.tDiscount = 0;
      this.students.forEach(a => a.checked = false);
    }
  }


  add() {

  }

  edit() {

  }

  onSubmit(myForm: FormGroup) {
    let value: any = myForm.value;
    let payload = {
      "student_id": value.student_id,
      "cost": value.AMOUNT,
      "total_amount": value.FINAL_AMOUNT,
      "discount": value.TOTAL_DISCOUNT,
      "quantityhand": this.quantityhand
    }

    this.CommonService.postCall('EmsStudentSales/UpdateStudentCost', payload).subscribe((res: any) => {
      this.loadStudent();
      this.toastr.success("Updated Successfully");
      document.getElementById('md_close')?.click();


    }, err => {
      this.toastr.error(err.error ? err.error : 'Not Updated')
    })

  }

  close() {
    this.myForm.reset();
    this.isEdit = false;
    this.students = [];
    this.isCampus = null;
  }
}
