
<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 text-center">Poll Results</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <h5>{{question}}</h5>
                                                <div style="display: block;" *ngIf='pieChartData.length'>
                                                    <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels"
                                                        [chartType]="pieChartType" [options]="pieChartOptions"
                                                        [plugins]="pieChartPlugins" [legend]="pieChartLegend"
                                                        [colors]='pieChartColors'>
                                                    </canvas>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <p class="text-right">Total Votes : {{count}}</p>
                                                <table class="table" *ngIf='table.length'>
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>Count</th>
                                                            <th>Percent</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor='let item of table'>
                                                            <td><button class="color_box"
                                                                    [ngStyle]='{"background-color":item.color}'></button>
                                                                {{item.OptionText}}</td>
                                                            <td>{{item.Options}}</td>
                                                            <td>{{item.userCount}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>