import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-fee-receivable',
  templateUrl: './fee-receivable.component.html',
  styleUrls: ['./fee-receivable.component.css']
})
export class FeeReceivableComponent extends BaseComponent implements OnInit {
  table: Array<any> = [];
  courses: Array<any> = [];
  Schedules: Array<any> = [];
  sheduleId: string;
  myForm: FormGroup;
  // courceId: string;
  academics: any;
  Curriculums: any;
  users: any;
  isParam: boolean = false;
  paymentMode: boolean = false;
  isDesable: boolean = false;
  Cheque: boolean = false;
  Online: boolean = false;
  academic: any
  academicId: string ='';
  curriculumId: string;
  acId: any;
  gTotal: number = 0;
  dTotal: number = 0;
  pTotal: number = 0;
  cTotal: number = 0;
  fTotal: number = 0;
  dateOfPayment: Date = null;
  modeOfPayment: any;
  transactionNumber: any;
  chequeNumber: any;
  chequeDate: any;
  bankName: any;
  totalAmount: number = 0;
  labelName: any = {};
  headingName: any = {};
  workItemId: any;
  constructor(private fb: FormBuilder, private active: ActivatedRoute, CommonService: CommonService, toastr: ToastrService, private route: Router) {
    super(CommonService, toastr);
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
    this.loadCourse();
    this.getLabels();
    this.loadHeadings();

  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
    })
    this.table = []
  }
  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  mopChange(event, i) {
    // this.dateOfPayment=null;
    // this.modeOfPayment=0;
    // this.transactionNumber='';
    // this.chequeNumber=0
    // this.chequeDate=null;
    // this.bankName='';
    let paymentMode = event.target.value;
    if (paymentMode == 2) {
      this.Cheque = true;
      this.Online = false;
      this.isDesable = true;
    } else if (paymentMode == 3) {
      this.Online = true;
      this.Cheque = false;
      this.isDesable = true;
    } else {
      this.Online = false;
      this.Cheque = false;
      this.isDesable = true;
    }
  }
  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  ValidatePayAmount(event, dueAmount) {
    this.totalAmount = 0;
    let payAmount = event.target.value;
    if (payAmount > dueAmount) {
      this.toastr.warning('Pay amount should be less than Due amount.')
      return event.target.value = '';
    }
    let data = this.table.filter(a => a.checked);
    data.forEach(e => {
      this.totalAmount += e.TNT_CODE;
    });

  }



  loadCourse() {
    this.activeSpinner();
    this.CommonService.getAdminCourses(this.tId == '' ? undefined : this.tId).subscribe((res: any) => {
      this.courses = res;
      this.deactivateSpinner();
    }, e => {
      this.deactivateSpinner();
    })

  }

  // courceChange(acId) {
  //   this.activeSpinner()
  //   let payLoad = {
  //     "academicId": acId.target.value
  //   }
  //   this.CommonService.getCall('Curriculum/CurriculumDropDownList/' + acId.target.value).subscribe((res: any) => {
  //     this.Curriculums = res;
  //     this.deactivateSpinner()

  //   }, e => {
  //     this.deactivateSpinner()
  //   })
  // }

  courceChange(event) {

    this.sheduleId = '';
    let data = {
      "CourseId": event.target.value
    }
    this.CommonService.getAdminCourseSchedule(data).subscribe((res: any) => {
      this.deactivateSpinner()
      this.Schedules = res;
    }, e => { this.deactivateSpinner() })
  }

  sheduleChange(id) {
    this.activeSpinner();
    let payLoad = {
      "COURSESHD_ID": id.target.value
    }
    this.CommonService.postCall('Account/usersbycourseShedule', payLoad).subscribe((res: any) => {
      this.deactivateSpinner();
      this.users = res;
    }, e => {
      this.deactivateSpinner();
    });
  }

  load(id) {
    this.table = []; this.fTotal = 0; this.gTotal = 0; this.dTotal = 0; this.pTotal = 0; this.cTotal = 0;
    this.totalAmount = 0; this.dateOfPayment = null; this.isDesable = false; this.modeOfPayment = "";
    this.activeSpinner();
    let payLoad = {
      "FEERECEIVABLE_STUDENT_ID": id.target.value,
      "TNT_CODE": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('StudentFeeReceivables/GetBystudent', payLoad).subscribe((res: any) => {
      res.forEach(e => {
        this.fTotal += e.FEERECEIVABLE_TERM_AMOUNT;
        this.gTotal = (this.fTotal - this.cTotal);
        this.dTotal += e.Due_amount;
        this.pTotal += e.FEERECEIVABLE_PAIDAMOUNT;
        this.cTotal += e.fee_concession_term_amount;
      });
      this.deactivateSpinner();
      this.table = res;
      this.renderDataTable();
      this.table.map(a => a.checked = false);
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner(), console.log(e) })
  }


  Update() {
    let checkedData = [];
    let data = this.table.filter(a => a.checked);
    data.forEach(e => {
      e.Due_amount = e.TNT_CODE;
      e.TNT_CODE = 0;
      checkedData.push(e)
    });

    let payload = {
      "Toatal_amount": this.totalAmount,
      "modeofpaymnet": parseInt(this.modeOfPayment),
      "tnt_code": localStorage.getItem('TenantCode'),
      "payment_created_by": localStorage.getItem('UserId'),
      "StudentFeeReceivables": checkedData,
      // "cheque_number": this.chequeNumber || 0,
      // "cheque_date": this.chequeDate || '',
      // "tranaction_number": this.transactionNumber || '',
      // "bank_name": this.bankName || '',
    }
    if (this.modeOfPayment == "2") {
      payload['cheque_number'] = this.chequeNumber
      payload['cheque_date'] = this.chequeDate
      payload['bank_name'] = this.bankName
    } else if (this.modeOfPayment == "3") {
      payload['tranaction_number'] = this.transactionNumber
    }
    this.CommonService.postCall('StudentFeeReceivables/Payment', payload).subscribe((res: any) => {
      this.table = [];
      this.isDesable = false;
      this.toastr.success("Fee Payments Updated Successfully");
    }, err => {
      this.toastr.error(err.error ? err.error : 'Fee Payments Not Updated')
      this.deactivateSpinner();
    })
  }

}


