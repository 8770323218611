import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-master-assessment',
  templateUrl: './master-assessment.component.html',
  styleUrls: ['./master-assessment.component.css']
})
export class MasterAssessmentComponent extends BaseComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
  labelName: any = {};
  headingName: any = {};
  workItemId: any;
  constructor(CommonService: CommonService,private active : ActivatedRoute, private route: Router, toastr: ToastrService) {
    super(CommonService, toastr)
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.load();
    this.loadlabels();
    this.loadHeadings();
  }

  ngOnInit(): void {
    // this.getCourses();

  }
  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }
  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }
  load() {
    this.activeSpinner();
    let payLoad: any = {
      ROLE_NAME:localStorage.getItem('RoleName'),
      USER_ID:localStorage.getItem('UserId'),
      TENANT_CODE: localStorage.getItem('TenantCode'),
      RoleID: localStorage.getItem('RoleId'),
      "COURSETRAINERID": localStorage.getItem('UserId'),
      "ASSESSMENT_MODE": "1"

    }
    this.CommonService.postCall('LoadMasterAssessment', payLoad).subscribe((res: any) => {
      this.table = [];
      this.table = res;
      this.renderDataTable();
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner() })
  }

  add() {
    this.route.navigate(['home/addmasterAssessment'],{ queryParams: { mId: 0 , wId:this.workItemId} })
  }

  edit(id) {
    this.route.navigate(['home/addmasterAssessment'], { queryParams: { mId: id , wId:this.workItemId} })
  }
  changeTname() {
    this.load()
  }
  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

}
