import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-book-request',
  templateUrl: './book-request.component.html',
  styleUrls: ['./book-request.component.css']
})
export class BookRequestComponent implements OnInit {
  table: Array<any> = [];
  bookStd:any;
  workItemId: any;
  headingName: any = {};
  constructor( private toastr: ToastrService,private active:ActivatedRoute,private CommonService: CommonService) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.load();
    this.loadHeadings();
  }

  ngOnInit(): void {
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  requestBook(event: any,id:any) {
    let payload = {
      "book_id": id,
      "student_id": localStorage.getItem('UserId'),
      "tnt_code": localStorage.getItem('TenantCode'),
      "created_by": localStorage.getItem('UserId'),
      "modified_by": localStorage.getItem('UserId')
    }
    this.CommonService.postCall('LibraryManagement/InsertStudentRequest', payload).subscribe((res: any) => {
      this.toastr.success('Book Requested Successfully');
    window.location.reload();
    })
  }
  load() {
    this.table = [];
    this.CommonService.getCall(`LibraryManagement/LoadBooksByStudent/${localStorage.getItem('UserId')}`).subscribe((res: any) => {
      this.table = res;
      
    })
  }
}
