<br>
<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid ">
                        <div class="text-right mb-1">
                            <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                <h5 class="text-left mb-0 ">{{headingName.PrintIDCard1}}</h5>
                            </div>&nbsp;
                            <div>
                                <button type="button" class="btn btn-primary" (click)="Print()">
                                    Print</button> &nbsp;
                                <button type="button" class="btn btn-danger" (click)="back()">
                                    Back</button>
                            </div>
                        </div>
                        <div id="Nit" >
                            <div id="print" *ngFor="let item of idCardList" style="height:270mm;width: 210mm;margin: auto;">
                                <div class="id-1 card-body">
                                    <div class="certificate-container">
                                        <div class="certificate-header">
                                            <div class="logo1"
                                                style="width: 15%; text-align: center; align-items: center; justify-content: center; display: flex;">
                                                <img src="../../../assets/img/logo2.jpg" alt="" width="20px">
                                            </div>
                                            <div style="width: 70%;  text-align: center;">
                                                <img src="../../../assets/img/logo1.jpg" alt="" width="38px"
                                                    height="27px">
                                                <p style="font-size: 9px; margin-bottom: 0px">Shri Sai Shikshan
                                                    Sanstha's
                                                </p>
                                            </div>
                                            <div class="logo2"
                                                style="width: 15%; text-align: center; align-items: center; justify-content: center; display: flex;">
                                                <img src="../../../assets/img/logo2.jpg" alt="" width="20px">
                                            </div>
                                        </div>
                                        <div class="" style="padding: 0;">



                                            <h2
                                                style="color: #612c64; font-size: 15px; font-weight: bolder; word-spacing: 1; text-transform: uppercase; text-align: center; margin-bottom: 0;">
                                                NIT Polytechnic, Nagpur</h2>
                                            <h4
                                                style="color: #0063a6; font-size: 10px; font-weight: bold; text-align: center; margin-bottom: 0px;">
                                                NBA Accredited Program</h4>
                                            <p
                                                style="text-align: center; color: #612c64; font-weight: bold; font-size: 9px; margin-bottom: 0px">
                                                (An ISO 9001 - 2015 Certified
                                                Institute)</p>
                                            <div style="text-align: center;  margin-bottom: 0px;  margin-top: 0px;">
                                                <span
                                                    style="font-size: 11px; background: #612c64; color: #fff; border-radius: 3px; padding: 3px 5px; text-align: center; font-weight: bolder;">STUDENT
                                                    IDENTITY CARD</span>
                                            </div>

                                        </div>
                                        <!-- <div style="width: 80%;">
                                                <p style="font-size: 12px;">(An ISO 9001-2015 Certified Institute)<br/>(Approved by: AICTE, DTE Mumbal, Govt. of Maharashtra and Affiliated to MSBTE Mumbai)</p>
                                                <p style="margin-top: 8px; font-size: 12px;"><b>Campus :</b> Survey No. 13/2. Mahurzari, Near Fetri, Katol Road, Nagpur-441 501<br/><b>Mobile :</b> 9545525450, 9545557700, 7972515445, 8007777170<br/> <b>Website :</b> www.nitpoly.edu.in,&nbsp;&nbsp;<b>E-mail :</b> principainitpoly@nitpoly.edu.in<br/><b>Corporate Office :</b> 2nd Floor, Midas Heights, Central Bazar Road, Ramdaspeth, Nagpur-10<br/><b>Ph.:</b> (0712) 2462268.</p>
                                                <div style="font-size: 1rem; margin-top: 10px; margin-bottom: 5px; color: #FD5845;">
                                                    <span style="float: left;">Principal: Prof. G, F. Potbhare</span> 
                                                    <span style="float: right;">Secretary: Salil Deshmukh</span>
                                                    <div style="clear: both;"></div>
                                                </div>
                                            </div> -->

                                    </div>
                                    <hr
                                        style="color: #612c64; border-width: 2px; margin-bottom: 0px; margin-top: 0px;" />
                                    <div style="text-align: center;">
                                        <div
                                            style="border: 1px #612c64 solid; width: 87px; height: 100px; padding-top: 5px; margin: 0px auto;">
                                            <img [src]="item.userfrimages" width="80px" height="90px" alt="">
                                            <!-- <img src="../../../assets/img/sign.png" width="40px" height="15px" alt=""> -->
                                        </div>
                                    </div>
                                    <div style="padding: 3px; font-size: 10px;" class="txt-content">
                                        <p style="margin-bottom: 0px;text-align: left !important;"><span
                                                style="color: #131e3b; font-weight: 600; margin-bottom: 0px">Name:-</span>
                                            <span
                                                style="color:#b30891; font-size: 10px; font-weight: 600;">{{item.name}}</span>
                                        </p>
                                        <div style="text-align: center;  margin-bottom: 4px;  margin-top: 8px;">
                                            <div
                                                style="font-size: 11px; background: #131e3b; color: #fff; border-radius: 3px; padding: 3px; text-align: center; font-weight: bolder;">
                                                {{item.course_name}}.</div>
                                        </div>
                                        <p style="margin-bottom: 0px;text-align: left !important;"><span
                                                style="color: #131e3b; font-weight: 600;">Year:-</span>
                                            <span>{{item.course_year}}</span></p>
                                        <p style="margin-bottom: 0px;text-align: left !important;"><span
                                                style="color: #131e3b; font-weight: 600;">College Id:-</span>
                                            <span>{{item.rollnumber}}</span>
                                        </p>
                                        <p style="margin-bottom: 0px;text-align: left !important;"><span
                                                style="color: #131e3b; font-weight: 600;">Enrolment No:-</span>
                                            <span><b></b></span>
                                        </p>
                                    </div>
                                    <div style="text-align: center; margin-top: 0px; margin-bottom: 0px;">
                                        <!-- <img alt='Barcode Generator TEC-IT' [src]="barcode"
                                       /> -->
                                        <img style="height: 50px;
                                       width: 150px;"
                                            src="https://barcode.tec-it.com/barcode.ashx?data={{item.enrollment_no}}&code=Code128&translate-esc=on"
                                            alt="Barcode Generator TEC-IT">
                                        <!-- <img
                                        src="../../../assets/img/bar.jpg" alt=""> -->
                                    </div>

                                    <div style="text-align: right;"><img src="../../../assets/img/signa.png"
                                            style="width: 30px; height: 15px; margin-right: 10px;" alt=""></div>
                                    <div
                                        style="display: flex; justify-content: space-between; align-items: center; font-size: 12px;">
                                        <div style="color:#612c64">Session</div>
                                        <div style="float: left"><b>27/03/2022</b></div>
                                        <div style="float: right">Principal</div>
                                    </div>
                                    <div style="clear: both;"></div>
                                </div>

                                <div id="container">
                                    <div class="certificate-container">
                                        <div>
                                            <div
                                                style=" font-size: 10px; font-weight: 600; margin: 3px 7px; text-align: left;">
                                                <span style="color: #0063a6;">Date of Birth : </span>
                                                <span>{{item.dob |date:'dd/MM/yyyy'}}</span>
                                            </div>
                                            <div
                                                style=" font-size: 10px; font-weight: 600; margin: 3px 7px; text-align: left;">
                                                <span style="color: #0063a6;">Phone / Mobie No : </span>
                                                <span> {{item.parent_mobile_number}}</span>
                                            </div>
                                            <div
                                                style="font-size: 10px; font-weight: 600; margin: 3px 7px; text-align: left;">
                                                <span style="color: #0063a6;">Address : </span>
                                                <span>{{item.addressdetails}}</span>
                                            </div>

                                            <div
                                                style=" font-size: 10px; font-weight: 600; margin: 3px 7px; text-align: left;">
                                                <div style="float: left; "><span style="color: #612c64;">Emergancy
                                                        Contact
                                                        No : </span>&nbsp;</div>
                                                <div style="float: left;">
                                                    <ul
                                                        style="list-style-type: none; color: #612c64; margin-left: 0; padding-left: 0; margin-bottom: 0px;">
                                                        <li><span></span></li>
                                                        <li><span style="margin-left: 20px;"></span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div style="clear: both;"></div>
                                        </div>

                                        <hr
                                            style="color: #612c64; border-width: 2px; margin-top: 0px ; margin-bottom: 0px;" />

                                        <div>
                                            <div
                                                style=" font-size: 10px; font-weight: 600; margin: 3px 7px; color: #612c64; text-decoration: underline; text-align: left;">
                                                Ruler & Regulations :</div>
                                            <div class="rules">
                                                <p><span style="color: #612c64;">01.</span> The identity card is issued
                                                    to
                                                    individua! and
                                                    iis mon transferable in case of misuse, original
                                                    cardholder shall be responsible</p>
                                                <p><span style="color: #612c64;">02.</span> This card shall be produced
                                                    whenever de
                                                    manded by the college / university | Govt.
                                                    Athority Card Is valid for current academic year
                                                    only</p>
                                                <p><span style="color: #612c64;">03.</span> 1n case this card is fost.
                                                    It
                                                    should bo reported
                                                    forthwith to the HOD.</p>
                                                <p>04.
                                                    This Card must be roturned at the time of No
                                                    Dues Certificate
                                                </p>
                                            </div>
                                            <div
                                                style="font-size: 10px; font-weight: 600; margin-top: 2.8rem; margin-left: 5rem; color: #0063a6;">
                                                <!-- <i>Powerad by - SyNanRonl Inc.</i> -->
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>