import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { forkJoin } from 'rxjs';
import { dataDictionary } from 'src/app/dataDictionary';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-faculity-feedback',
  templateUrl: './faculity-feedback.component.html',
  styleUrls: ['./faculity-feedback.component.css']
})
export class FaculityFeedbackComponent implements OnInit {

  myForm!: FormGroup;
  table: Array<any> = [];
  courses: Array<any> = [];
  academicYearDropdown: Array<any> = [];
  semesterDropdown: Array<any> = [];
  courseYearDropdown: Array<any> = [];
  subjectDropdown: Array<any> = [];
  trainerDropdown: Array<any> = [];
  startDate: any;
  endDate: any;
  today: any = new Date();
  todayDate: any;
  isAdd: boolean = false;

  FirstArray: Array<any> = [
    { "answer_id": 1, "answer": 'Excellent' },
    { "answer_id": 2, "answer": 'Very Good' },
    { "answer_id": 3, "answer": 'Good' },
    { "answer_id": 4, "answer": 'Poor' },
    { "answer_id": 5, "answer": 'Very Poor' },
  ];
  SecondArray: Array<any> = [
    { "answer_id": 1, "answer": 'Excellent' },
    { "answer_id": 2, "answer": 'Very Good' },
    { "answer_id": 3, "answer": 'Good' },
    { "answer_id": 4, "answer": 'Poor' },
    { "answer_id": 5, "answer": 'Very Poor' },
  ];
  ThirdArray: Array<any> = [
    { "answer_id": 1, "answer": 'Excellent' },
    { "answer_id": 2, "answer": 'Very Good' },
    { "answer_id": 3, "answer": 'Good' },
    { "answer_id": 4, "answer": 'Poor' },
    { "answer_id": 5, "answer": 'Very Poor' },
  ];
  FourthArray: Array<any> = [
    { "answer_id": 1, "answer": 'Excellent' },
    { "answer_id": 2, "answer": 'Very Good' },
    { "answer_id": 3, "answer": 'Good' },
    { "answer_id": 4, "answer": 'Poor' },
    { "answer_id": 5, "answer": 'Very Poor' },
  ];
  FifthArray: Array<any> = [
    { "answer_id": 1, "answer": 'Excellent' },
    { "answer_id": 2, "answer": 'Very Good' },
    { "answer_id": 3, "answer": 'Good' },
    { "answer_id": 4, "answer": 'Poor' },
    { "answer_id": 5, "answer": 'Very Poor' },
  ];
  SixthArray: Array<any> = [
    { "answer_id": 1, "answer": 'Excellent' },
    { "answer_id": 2, "answer": 'Very Good' },
    { "answer_id": 3, "answer": 'Good' },
    { "answer_id": 4, "answer": 'Poor' },
    { "answer_id": 5, "answer": 'Very Poor' },
  ];
  SeventhArray: Array<any> = [
    { "answer_id": 1, "answer": 'Excellent' },
    { "answer_id": 2, "answer": 'Very Good' },
    { "answer_id": 3, "answer": 'Good' },
    { "answer_id": 4, "answer": 'Poor' },
    { "answer_id": 5, "answer": 'Very Poor' },
  ];
  eighthArray: Array<any> = [
    { "answer_id": 1, "answer": 'Excellent' },
    { "answer_id": 2, "answer": 'Very Good' },
    { "answer_id": 3, "answer": 'Good' },
    { "answer_id": 4, "answer": 'Poor' },
    { "answer_id": 5, "answer": 'Very Poor' },
  ];
  NinthArray: Array<any> = [
    { "answer_id": 1, "answer": 'Excellent' },
    { "answer_id": 2, "answer": 'Very Good' },
    { "answer_id": 3, "answer": 'Good' },
    { "answer_id": 4, "answer": 'Poor' },
    { "answer_id": 5, "answer": 'Very Poor' },
  ];
  TenthArray: Array<any> = [
    { "answer_id": 1, "answer": 'Excellent' },
    { "answer_id": 2, "answer": 'Very Good' },
    { "answer_id": 3, "answer": 'Good' },
    { "answer_id": 4, "answer": 'Poor' },
    { "answer_id": 5, "answer": 'Very Poor' },
  ];
  EleventhArray: Array<any> = [
    { "answer_id": 1, "answer": 'Excellent' },
    { "answer_id": 2, "answer": 'Very Good' },
    { "answer_id": 3, "answer": 'Good' },
    { "answer_id": 4, "answer": 'Poor' },
    { "answer_id": 5, "answer": 'Very Poor' },
  ];
  TwelvethArray: Array<any> = [
    { "answer_id": 1, "answer": 'Excellent' },
    { "answer_id": 2, "answer": 'Very Good' },
    { "answer_id": 3, "answer": 'Good' },
    { "answer_id": 4, "answer": 'Poor' },
    { "answer_id": 5, "answer": 'Very Poor' },
  ];
  ThirteenthArray: Array<any> = [
    { "answer_id": 1, "answer": 'Excellent' },
    { "answer_id": 2, "answer": 'Very Good' },
    { "answer_id": 3, "answer": 'Good' },
    { "answer_id": 4, "answer": 'Poor' },
    { "answer_id": 5, "answer": 'Very Poor' },
  ];
  Fourtenth: Array<any> = [
    { "answer_id": 1, "answer": 'Excellent' },
    { "answer_id": 2, "answer": 'Very Good' },
    { "answer_id": 3, "answer": 'Good' },
    { "answer_id": 4, "answer": 'Poor' },
    { "answer_id": 5, "answer": 'Very Poor' },
  ];
  Fifteenth: Array<any> = [{ "answer_id": 1, "answer": 'Excellent' },
  { "answer_id": 2, "answer": 'Very Good' },
  { "answer_id": 3, "answer": 'Good' },
  { "answer_id": 4, "answer": 'Poor' },
  { "answer_id": 5, "answer": 'Very Poor' },];
  workItemId: any;
  headingName: any = {};
  labelName: any = {};
  constructor(private active: ActivatedRoute, private CommonService: CommonService, private fb: FormBuilder, private toastr: ToastrService) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadCourse();
    this.getList();
    this.getDropdown();
    this.newDate();
    this.getFeedback();
    this.getLabels();
    this.loadHeadings();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      course_id: ['', Validators.required],
      academic_year_id: ['', Validators.required],
      semester_id: ['', Validators.required],
      course_year: ['', Validators.required],
      subject_id: ['', Validators.required],
      faculty_id: ['', Validators.required],
      answer: [''],
      answerOne: [''],
      answerTwo: [''],
      answerThree: [''],
      answerFour: [''],
      answerFive: [''],
      answerSix: [''],
      answerSeven: [''],
      answerEight: [''],
      answerNine: [''],
      answerTen: [''],
      answerEleven: [''],
      answerTwelveth: [''],
      answerThirteenth: [''],
      answerFourteenth: [''],
    });
  }
  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }


  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  getList() {
    let payload = { "tnt_code": localStorage.getItem('TenantCode'), "student_id": localStorage.getItem('UserId') }
    this.CommonService.postCall('EmsFeedback/GetFacultyFeedbackList', payload).subscribe((response: any) => {
      setTimeout(() => {
        this.table = response;
        if (this.table.length == 0) {
          this.isAdd = false;
        } else if (this.table.length == 1) {
          this.isAdd = true;
        }
        else {
          this.isAdd = true;
          this.toastr.warning("Already submitted your feedback");
        }
      }, 10);

    })
  }

  getFeedback() {
    this.CommonService.getCall(`EmsFeedback/GetFeedbackSettings/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.startDate = moment(res.faculty_feedback_start_date).format('yyyy-MM-DD');
      this.endDate = moment(res.faculty_feedback_end_date).format('yyyy-MM-DD');

      if (this.startDate == "Invalid date") {
        this.toastr.warning("Feedback has not been scheduled");
        this.isAdd = true;
      } else if (this.todayDate < this.startDate) {
        this.toastr.warning(`Feedback form will be available from ${this.startDate}`);
        this.isAdd = true;
      } else if (this.todayDate >= this.startDate && this.todayDate <= this.endDate) {
        this.isAdd = false;
      } else {
        this.isAdd = true;
        this.toastr.warning('Your feedback form date has expired');

      }
    })
  }

  newDate() {
    var d = this.today,
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    this.todayDate = [year, month, day].join('-');
    return [year, month, day].join('-');
  }
  getSubjectsbyCourse(event: any) {
    let payLoad = {
      COURSE_ID: event.target.value,
      TNT_CODE: localStorage.getItem('TenantCode')
    }
    this.activeSpinner();
    this.CommonService.postCall('GetSubjects', payLoad).subscribe(
      (res: any) => {
        this.subjectDropdown = res;
        this.deactivateSpinner();
      }, err => {
        this.deactivateSpinner();
      }
    )
  }
  loadCourse() {
    this.activeSpinner();
    this.CommonService.getCall(`Courses/GetCourses/${localStorage.getItem('UserId')}/${localStorage.getItem('RoleId')}`).subscribe((res: any) => {
      this.courses = res;
      this.deactivateSpinner();
    }, e => {
      this.deactivateSpinner();
    })

  }
  // loadCourse() {
  //   this.activeSpinner();
  //   this.CommonService.getAdminCourses().subscribe((res: any) => {
  //     this.courses = res;
  //     this.deactivateSpinner();
  //   }, e => {
  //     this.deactivateSpinner();
  //   })

  // }
  getDropdown() {
    this.activeSpinner()
    let academicYear = this.CommonService.getCall(`Academic/GetAcademicDropDownList/${localStorage.getItem('TenantCode')}`);
    let semister = this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.Semester });
    let courseYear = this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.CourseYear });
    let subject = this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.Subject });
    let dropdowns = ['academicYearDropdown', 'semesterDropdown', 'courseYearDropdown', 'subjectDropdown']
    forkJoin([academicYear, semister, courseYear, subject]).subscribe((res) => {
      dropdowns.map((key, index) => {
        this[key] = res[index]
      });
      this.deactivateSpinner()
    }, err => { this.deactivateSpinner() });
  }
  subjectChange(event: any) {
    this.CommonService.getCall(`Registration/LoadTrainersBySubject/${event.target.value}`).subscribe((res: any) => {
      this.trainerDropdown = res;
    })
  }
  onSubmit(form: any) {
    let value = form.value;
    let answer1 = this.FirstArray.find((e: any) => e.answer_id == value.answer);
    let answer2 = this.SecondArray.find((e: any) => e.answer_id == value.answerOne);
    let answer3 = this.ThirdArray.find((e: any) => e.answer_id == value.answerTwo);
    let answer4 = this.FourthArray.find((e: any) => e.answer_id == value.answerThree);
    let answer5 = this.FifthArray.find((e: any) => e.answer_id == value.answerFour);
    let answer6 = this.SixthArray.find((e: any) => e.answer_id == value.answerFive);
    let answer7 = this.SeventhArray.find((e: any) => e.answer_id == value.answerSix);
    let answer8 = this.eighthArray.find((e: any) => e.answer_id == value.answerSeven);
    let answer9 = this.NinthArray.find((e: any) => e.answer_id == value.answerEight);
    let answer10 = this.TenthArray.find((e: any) => e.answer_id == value.answerNine);
    let answer11 = this.EleventhArray.find((e: any) => e.answer_id == value.answerTen);
    let answer12 = this.TwelvethArray.find((e: any) => e.answer_id == value.answerEleven);
    let answer13 = this.ThirteenthArray.find((e: any) => e.answer_id == value.answerTwelveth);
    let answer14 = this.Fourtenth.find((e: any) => e.answer_id == value.answerThirteenth);
    let answer15 = this.Fifteenth.find((e: any) => e.answer_id == value.answerFourteenth);

    let payload = {
      "course_id": value.course_id,
      "academic_year_id": value.academic_year_id,
      "semester_id": value.semester_id,
      "faculty_id": value.faculty_id,
      "subject_id": value.subject_id,
      "course_year": value.course_year,
      "student_id": localStorage.getItem('UserId'),
      "created_by": localStorage.getItem('UserId'),
      "modified_by": localStorage.getItem('UserId'),
      "tnt_code": localStorage.getItem('TenantCode'),
      "question_answers": [
        {
          "question_id": 1,
          "question": "Punctuality and discipline",
          "answer_id": answer1?.answer_id,
          "answer": answer1?.answer
        },
        {
          "question_id": 2,
          "question": "Domain Knowledge",
          "answer_id": answer2?.answer_id,
          "answer": answer2?.answer
        },
        {
          "question_id": 3,
          "question": "Presentation required skills and interaction with the students",
          "answer_id": answer3?.answer_id,
          "answer": answer3?.answer
        },
        {
          "question_id": 4,
          "question": "Able to resolve difficulties",
          "answer_id": answer4?.answer_id,
          "answer": answer4?.answer
        },
        {
          "question_id": 5,
          "question": "Effective use of teaching aids ",
          "answer_id": answer5?.answer_id,
          "answer": answer5?.answer
        },
        {
          "question_id": 6,
          "question": "Faculty member completes the syllabus in time?",
          "answer_id": answer6?.answer_id,
          "answer": answer6?.answer
        },
        {
          "question_id": 7,
          "question": "Teacher offer assistance on counselling to needy students ",
          "answer_id": answer7?.answer_id,
          "answer": answer7?.answer
        },
        {
          "question_id": 8,
          "question": ". Teacher ensures learners activity and problem solving ability in class?",
          "answer_id": answer8?.answer_id,
          "answer": answer8?.answer
        },
        {
          "question_id": 9,
          "question": "Teacher is capable of keeping class under discipline and control ",
          "answer_id": answer9?.answer_id,
          "answer": answer9?.answer
        },
        {
          "question_id": 10,
          "question": "Knowledge of faculty beyond the syllabus",
          "answer_id": answer10?.answer_id,
          "answer": answer2?.answer
        },
        {
          "question_id": 11,
          "question": "Quality of self develop Videos by the Faculty ",
          "answer_id": answer11?.answer_id,
          "answer": answer11?.answer
        },
        {
          "question_id": 12,
          "question": "Quality of online teaching, Theory and Tutorials during Pandemic",
          "answer_id": answer12?.answer_id,
          "answer": answer12?.answer
        },
        {
          "question_id": 13,
          "question": "Quality of practical explain in online mode during Pandemic.",
          "answer_id": answer13?.answer_id,
          "answer": answer13?.answer
        },
        {
          "question_id": 14,
          "question": "Effectiveness of Mentoring provided",
          "answer_id": answer14?.answer_id,
          "answer": answer14?.answer
        },
        {
          "question_id": 15,
          "question": "Overall rating of the faculty ",
          "answer_id": answer15?.answer_id,
          "answer": answer15?.answer
        },

      ]
    }
    let UnselectedQuestions = [];
    UnselectedQuestions = payload.question_answers.filter((e: any) => e.answer_id == undefined);
    let questionNumbers = [];
    UnselectedQuestions.forEach((e: any) => {
      questionNumbers.push(e.question_id);
    })
    if (questionNumbers.length > 0) {
      this.toastr.warning(`Question No. ${questionNumbers.join(',')} have not been answered, please answer all the questions to submit feedback.`);
    } else {
      this.CommonService.postCall('EmsFeedback/CreateFacultyFeedback', payload).subscribe((response: any) => {
        this.getList();
        this.toastr.success('Submitted Successfully');
        document.getElementById('md_close').click();
      })
    }
  }
  close() {
    this.myForm.reset();
  }

}
