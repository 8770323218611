<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.VehicleServiceFor}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <form action="" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                                            <fieldset>
                                                <div class="row">
                                                    <div class="col-md-1">
                                                        <h6 style="color: red;">{{labelName?.Daily}}</h6>
                                                    </div>

                                                    <div class="col-md-8">
                                                        <h6><strong>:</strong> Check all fluid levels (engine oil,
                                                            coolant,brake
                                                            fluid,transmission
                                                            fluid)</h6>
                                                    </div>

                                                </div>
                                                <div class="row">
                                                    <div class="col-md-1">
                                                        <h6 style="color: red;">{{labelName?.Weekly}}</h6>
                                                    </div>

                                                    <div class="col-md-8">
                                                        <h6><strong>:</strong> Inspect tires, wash vehicle and clean out
                                                            interior</h6>
                                                    </div>

                                                </div>

                                                <div class="row">
                                                    <h6 class="col-md-1" style="color: red;">{{labelName?.Montly}}</h6>
                                                    <div class="form-group col-md-3">
                                                        <div class=" inputGroupContainer">
                                                            <div class="input-group"><strong>: &nbsp;</strong>
                                                                <input placeholder="Select Month" (change)="isflag()"
                                                                    class="form-control form-control-sm shadow-sm rounded-lg"
                                                                    placeholder="Select YYYY/MM"
                                                                    formControlName="inspection_month"
                                                                    onfocus="(this.type='month')" type="text" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <h6 class="col-md-2 offset-xl-2" style="color: red;">{{labelName?.InspectedBy}}*
                                                    </h6>
                                                    <div class="form-group col-md-3">
                                                        <div class="input-group">
                                                            <select formControlName="inspected_by" 
                                                                class="form-select form-select-sm shadow-sm rounded-lg">
                                                                <option value="" selected>Select</option>
                                                                <option *ngFor="let item of inspectedByDropdown"
                                                                    value="{{item.userid}}">{{item.name}}</option>
                                                            </select>

                                                        </div>
                                                    </div>

                                                    <div class="row pt-4">
                                                        <div class="col-xl-12">

                                                            <table class="table-data" style="width:100%">
                                                                <tr class="text-center">
                                                                    <th style="padding: 0.5rem;">Name</th>
                                                                    <th style="text-align:center; padding: 0.5rem;">Ok
                                                                    </th>
                                                                    <th style="text-align:center; padding: 0.5rem;">Date
                                                                    </th>
                                                                    <th style="text-align:center; padding: 0.5rem;">
                                                                        Comment</th>
                                                                </tr>
                                                                <tr class="text-center"
                                                                    *ngFor="let item of vehicleList">
                                                                    <td
                                                                        style="border-right: 1px solid #5E5E5E; padding: 0.5rem; font-weight: 500; color: #5E5E5E;">
                                                                        {{item.vechile_service_name}}

                                                                    </td>

                                                                    <td
                                                                        style="border-right: 1px solid #5E5E5E; padding: 0.5rem; font-weight: 500; color: #5E5E5E;">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            [ngModelOptions]="{standalone: true}"
                                                                            value="" [(ngModel)]="item.status"
                                                                            id="flexCheckDefault">

                                                                    </td>

                                                                    <td
                                                                        style="border-right: 1px solid #5E5E5E; padding: 0.5rem; font-weight: 500; color: #5E5E5E;">
                                                                        <input class="form-control form-control-sm"
                                                                            [ngModelOptions]="{standalone: true}"
                                                                            [(ngModel)]="item.inspection_date"
                                                                            type="date" placeholder=".form-control-sm"
                                                                            aria-label=".form-control-sm example">
                                                                    </td>
                                                                    <td
                                                                        style="border-right: 1px solid #5E5E5E; padding: 0.5rem; font-weight: 500; color: #5E5E5E;">
                                                                        <textarea class="form-control"
                                                                            [ngModelOptions]="{standalone: true}"
                                                                            [(ngModel)]="item.code_comments"
                                                                            placeholder="Comment" rows="1"
                                                                            id="floatingTextarea"></textarea>
                                                                    </td>

                                                                </tr>



                                                            </table>


                                                        </div>

                                                    </div>

                                                </div>
                                            </fieldset>
                                        </form>
                                        <div class="float-end mb-1 pt-4">
                                            <button type="button" (click)="onSubmit(myForm)"
                                                class="btn btn-sm  btn-save"
                                                [disabled]="myForm.invalid">Submit</button>&nbsp;
                                            <button type="button" (click)="close()" class="btn btn-sm btn-danger"
                                                id='md_close'>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>