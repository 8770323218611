<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.CampusItemsList}}</h5>
                                    </div>

                                    <div class="card-body">
                                        <div class="text-right">
                                            <button type="button" class="btn btn-primary mb-1" data-bs-toggle="modal"
                                           data-bs-target="#myModal">Add Campus Items</button>
                                        </div>
                                        <div *ngIf="table.length">
                                            <table id="patreggraph" datatable
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th>Campus </th>
                                                        <th>No.Of Items</th>
                                                        <th>
                                                            Total Amount
                                                        </th>

                                                        <th>Total Discount</th>
                                                        <th>Final Amount</th>
                                                        <th>Action</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.campus_name}}</td>
                                                        <td>{{item.quantity}}</td>
                                                        <td>{{item.total_amount}}</td>
                                                        <td>{{item.total_discount}}</td>
                                                        <td>{{item.final_amount}}</td>

                                                        <td style="display: flex;">
                                                            <span data-bs-toggle="modal" data-bs-target="#myModal"
                                                                src="" class="edit_icon" (click)="edit(item.items_campus_id)">
                                                                <i class="fas fa-pen pt-1 mt-1"></i></span>
                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <div class="modal-header py-2">
                <h6 *ngIf="!isEdit" class=" modal-title w-100">{{headingName.AddCampusItems}}</h6>
                <h6 *ngIf="isEdit" class=" modal-title w-100"> {{headingName.EditCampusItems}}</h6>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>


            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row pt-4">
                            <label class="col-md-3 control-label line_2 rem_1">
                                {{labelName?.Campus}} <span class="text-danger">*</span></label>
                            <div class="col-md-6 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName="campus_id"
                                        (change)="campusTable($any($event).target.value)"
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" [selected]="true">Select</option>
                                        <option value="" *ngFor="let item of campus" [value]='item.campus_id'>
                                            {{item.campus_name}}
                                        </option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('campus_id').touched">
                                    <span class="text-danger" *ngIf="myForm.get('campus_id').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <table *ngIf="isCampus" id="patreggraph" class=" table-striped table-bordered mt-2 pt-2 table-sm small" >
                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                <tr>
                                    <th>Item </th>
                                    <th> Price </th>
                                    <th> Amount</th>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            <th>Issued On</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of campuses">
                                        <td>{{item.item_name}}</td>  
                                        <td>{{item.price}}</td> 
                                        <td>{{item.amount}}</td> 
                                      <td>{{item.issued_on}}</td> 
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div *ngIf="myForm.controls['list']">
                            <div formArrayName='list'>
                                <div class="form-group row pt-4">
                                    <table class="col-md-6 offset-md-3 text-center">
                                        <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                            <tr>
                                                <th>Item</th>
                                                <th>Price</th>
                                                <th>Amount</th>
                                                <th>Quantity</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let item of myForm.controls['list'].controls;let i=index"
                                            [formGroupName]="i">
                                            <tr>

                                                <td><select class="form-control form-control-sm"
                                                        formControlName="item_id" id="item"(change)="priceAmount($event,i)">
                                                        <option value="" [selected]="0">Select</option>
                                                        <option *ngFor="let item of items" [value]="item.item_id">
                                                            {{item.item_name}}
                                                        </option>


                                                    </select></td>
                                                <td> <input placeholder="Price" formControlName="price"
                                                        class="form-control form-control-sm" type="number" id="price"   >
                                                </td>
                                                <td> <input placeholder="Amount" formControlName="amount"
                                                        class="form-control form-control-sm" type="number" id="amount"
                                                        [(ngModel)]="item.amount" disabled>
                                                </td>
                                                <td> <input placeholder="Quantity" formControlName="quantity"
                                                        class="form-control form-control-sm" [(ngModel)]="item.quantity"
                                                        type="number" id="quantity">

                                                </td>


                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="row"  *ngIf="!isEdit">
                                <div class="col-md-6 offset-md-3 mt-4">
                                    <div class="float-end">
                                        <button class="btn btn-di float-end" *ngIf="isVisable()" type="button" (click)="delete()"><i
                                                class="fas fa-trash"></i></button>
                                        <button class="btn btn-ai float-end" *ngIf="isAdd()" type="button"(click)="add()">Add
                                            Item</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row ">
                            <label class="col-md-2 offset-md-3 control-label mb-4 mt-4">
                                {{labelName?.Amount}}<span class="text-danger">*</span></label>
                            <div class="col-md-4 mb-4 inputGroupContainer  mt-4">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <input placeholder="Amount" [(ngModel)]="total_cost" disabled
                                        class="form-control form-control-sm" formControlName="amounts" type="test">
                                </div>
                                <div *ngIf="myForm.get('amounts').touched">
                                    <span class="text-danger" *ngIf="myForm.get('amounts').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row ">
                            <label class="col-md-2 offset-md-3 control-label mb-4 mt-4">
                                {{labelName?.TotalDiscount}}<span class="text-danger"></span></label>
                            <div class="col-md-4 mb-4 inputGroupContainer  mt-4">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <input placeholder="Total Discount" (change)="discountChange($any($event).target.value)" [(ngModel)]="total_discount"
                                        class="form-control form-control-sm" formControlName="total_discounts"
                                        type="test">
                                </div>
                            </div>

                        </div>

                        <div class="form-group row ">
                            <label class="col-md-2 offset-md-3 control-label mb-4 mt-4">
                                {{labelName?.FinalDiscount}}<span class="text-danger">*</span></label>
                            <div class="col-md-4 mb-4 inputGroupContainer  mt-4">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <input placeholder="Final Amount" [(ngModel)]="final_amount" disabled
                                        class="form-control form-control-sm" formControlName="final_amounts"
                                        type="test">
                                </div>
                                <div *ngIf="myForm.get('final_amounts').touched">
                                    <span class="text-danger" *ngIf="myForm.get('final_amounts').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
                <div class="text-center pt-4">
                    <button type="button" *ngIf="!isEdit" class="btn btn-sm  btn-save"
                        (click)="onSubmit(myForm)" [disabled]="myForm.invalid" >Save</button>
                    <button type="button" *ngIf="isEdit" class="btn btn-sm  btn-save"
                        (click)="onSubmit(myForm)"[disabled]="myForm.invalid" >Update</button>&nbsp;
                    <button type="button" class="btn btn-sm btn-danger" id='md_close' (click)="close()"
                        data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>