<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.GiveConcession}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="form-group">
                                            <div class="row">
                                                <div *ngIf='isSuperAdmin' class="col-md-3">
                                                    <label for="sel1">{{labelName.TenantName}} : </label>
                                                    <select [(ngModel)]='tId ' (change)="loadCourse()"
                                                        class="form-control form-control-sm shadow-sm rounded-lg"
                                                        id="sel1">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let item of tenanates" [value]='item.TNT_CODE'>
                                                            {{item.TNT_NAME}}</option>
                                                    </select>
    
                                                </div>
                                                <div class="col-md-3">
                                                    <label for="sel1">{{labelName?.CourseName}}<span
                                                            class="text-danger">*</span></label>
                                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                        [disabled]="isParam" (change)="courceChange($event)"
                                                        [(ngModel)]='academicId'>
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let course of courses"
                                                            [value]='course.COURSE_ID'>
                                                            {{course.COURSE_NAME}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-md-3">
                                                    <label for="sel1">{{labelName?.CourseSchedule}}<span
                                                            class="text-danger">*</span></label>
                                                    <select name="courseSchedule" id="courseSchedule"
                                                        (change)="sheduleChange($event)"
                                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                                        <option value="" [selected]="true">Select</option>
                                                        <option *ngFor="let courseSchedule of Schedules"
                                                            [value]="courseSchedule.COURSESHD_ID">
                                                            {{courseSchedule.COURSESHD_NAME}}</option>
                                                    </select>
                                                </div>
                                                <div class="col-md-3">
                                                    <label for="sel1">{{labelName?.UserName}} <span
                                                            class="text-danger">*</span></label>
                                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                        [disabled]="isParam" (change)="load($event)">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let user of users" [value]='user.USERID'>
                                                            {{user.FIRSTNAME}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <!-- <div class="col-md-3">
                                        <label for="sel1">Number Of Terms <span class="text-danger">*</span></label>
                                        <select class="form-control form-control-sm shadow-sm rounded-lg"
                                        [disabled]="isParam"  formControlName="NUMBER_OF_TERMS">
                                            <option value="" [selected]="true">select</option>
                                            <option value="">1</option>
                                            <option value="">2</option>
                                            <option value="">3</option>
                                       
                                        </select>
                                    </div> -->
                                            </div>
                                        </div>
                                        <div *ngIf="table.length" class="pt-4">
                                            <table id="patreggraph" datatable [dtOptions]="dtOptions"
                                                [dtTrigger]="dtTrigger"
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th>Fee Type</th>
                                                        <th>Terms</th>
                                                        <th>Amount</th>
                                                        <th>Concession</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td><span
                                                                *ngIf="item.fee_concession_term_id==1">{{item.FEETYPE_NAME}}</span>
                                                        </td>
                                                        <td>TERM {{item.fee_concession_term_id}}</td>
                                                        <td>{{item.fee_concession_actualamount}}</td>
                                                        <td>
                                                            <label *ngIf="item.checked">
                                                                <input type="number" placeholder=""
                                                                    (input)="ValidateGiveConcession($event,item.fee_concession_actualamount)"
                                                                    [(ngModel)]="item.fee_concession_term_amount"
                                                                    #amount="ngModel" class="form-control">
                                                            </label>
                                                        </td>
                                                        <td>
                                                            <div class="form-check"
                                                                *ngIf="!item.fee_concession_actualamount == 0">
                                                                <input class="form-check-input" type="checkbox"
                                                                    [(ngModel)]="item.checked" value=""
                                                                    id="flexCheckDefault"
                                                                    (change)="onPayCheck($event,item.fee_concession_id,item.fee_concession_term_amount,item.fee_concession_actualamount)">
                                                                <label class="form-check-label" for="flexCheckDefault">
                                                                    Give Concession
                                                                </label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>
                                    <div class="text-center pt-4 pb-4">
                                        <button id='update' type="button" class="btn btn-sm  btn-save"
                                            [disabled]="!isDesable" (click)="Update()">Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>