import { Component, OnInit } from '@angular/core';
import { CommonService } from './../../services/common.service';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-character-certificate',
  templateUrl: './character-certificate.component.html',
  styleUrls: ['./character-certificate.component.css']
})
export class CharacterCertificateComponent implements OnInit {
  cources: Array<any> = [];
  students: Array<any> = [];
  usersData: Array<any> = [];
  receipt: boolean = false;
  date: any = new Date();
  labelName: any = {};
  headingName: any = {};
  workItemId: any;
  tenanates:Array<any>=[];
  roleId = localStorage.getItem('RoleId');
  tenantCode = localStorage.getItem('TenantCode');
  tId: any;
  constructor(private CommonService: CommonService,private active : ActivatedRoute) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    // this.loadcourses();
    this.loadlabels();
    this.loadHeadings();
    this.tntLoad();
    if (this.roleId == '1') {
      this.getCourses(this.tenantCode);
    }
  }

  ngOnInit(): void {
  }
  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }
  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }


  tntLoad() {
    let payload = {
      "RoleId": this.roleId,
      "TENANT_CODE": this.tenantCode
    }
    this.CommonService.postCall('Registration/GetTenantByRoleId', payload).subscribe((res: any) => {
      this.tenanates = res;
    })
  }
  // getCourses() {
  //   this.activeSpinner()
  //   this.CommonService.getAdminCourses().subscribe((res: any) => {
  //     this.cources = res;
  //     this.deactivateSpinner()
  //   }, e => {
  //     this.deactivateSpinner()
  //   })
  // }
  getCourses(tId: any) {
    this.activeSpinner()
    this.CommonService.getCall(`Courses/GetAdminCourses/${localStorage.getItem('UserId')}/${localStorage.getItem('RoleId')}/${tId || localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.cources = res;
      this.deactivateSpinner()
    }, e => {
      this.deactivateSpinner()
    })
  }
  courceChange(event: any) {
    let courseId = event.target.value;
    let payload = {
      "courseid": courseId,
      "tenantcode": this.tId||localStorage.getItem('TenantCode'),
    }
    this.CommonService.postCall('Registration/LoadStudentsByCourse', payload).subscribe((response: any) => {
      this.students = response;
    })
  }

  getUsersData(event: any) {
    this.receipt = true;
    let userId = event.target.value;
    this.CommonService.getCall(`Registration/LoadUserDetails/${userId}`).subscribe((response: any) => {
      this.usersData = response;
    })
  }



  convetToPDF() {
    var data: any = document.getElementById('printtable');
    html2canvas(data).then((canvas: any) => {
      // Few necessary setting options
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;

      const contentDataURL = canvas.toDataURL('image/png');
      let pdf: any = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      pdf.save('characterCertificate.pdf'); // Generated PDF
      // window.close();
    });

  }
  print() {
    var printContents: any = document.getElementById("printtable")?.innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  }
}
