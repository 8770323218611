<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.CircularAlert}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <form class="well form-horizontal" [formGroup]="myForm"
                                            (ngSubmit)="onSubmit(myForm)">
                                            <fieldset>
                                                <div class="form-group row pt-4">
                                                    <label
                                                        class="col-md-4 control-label ">{{labelName.Messagesendingtype}}<span
                                                            class="text-danger">*</span></label>
                                                    <div class="col-md-8 inputGroupContainer"><strong>:</strong>
                                                        &nbsp;
                                                        <div class="form-check form-check-inline pe-5">
                                                            <input class="form-check-input" type="radio"
                                                                (change)="typeChange($event.target.value)"
                                                                name="message_type" id="inlineRadio1" value="SMS"
                                                                checked formControlName="message_type">
                                                            <label class="form-check-label"
                                                                for="inlineRadio1">SMS</label>
                                                        </div>
                                                        <div class="form-check form-check-inline pe-5">
                                                            <input class="form-check-input" type="radio"
                                                                (change)="typeChange($event.target.value)"
                                                                name="message_type" id="inlineRadio2" value="Email"
                                                                formControlName="message_type">
                                                            <label class="form-check-label"
                                                                for="inlineRadio2">E-Mail</label>
                                                        </div>
                                                        <div class="form-check form-check-inline pe-5">
                                                            <input class="form-check-input" type="radio"
                                                                (change)="typeChange($event.target.value)"
                                                                name="message_type" id="inlineRadio3"
                                                                value="Notification" formControlName="message_type">
                                                            <label class="form-check-label"
                                                                for="inlineRadio3">Notification</label>
                                                        </div>
                                                        <div *ngIf="myForm.get('msgType')?.touched">
                                                            <span class="text-danger"
                                                                *ngIf="myForm.get('msgType')?.hasError('required')">
                                                                This field is required.
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group row pt-4" *ngIf="sms">
                                                    <label class="col-md-4 control-label ">{{labelName.Mesage}}<span
                                                            class="text-danger">*</span></label>
                                                    <div class="col-md-8 inputGroupContainer">
                                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                                            <textarea type="text" placeholder="" class="form-control"
                                                                rows="2" formControlName="message"></textarea>
                                                        </div>
                                                        <div *ngIf="myForm.get('message')?.touched">
                                                            <span class="text-danger"
                                                                *ngIf="myForm.get('message')?.hasError('required')">
                                                                This field is required.
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group row pt-4" *ngIf="eMail">
                                                    <label class="col-md-4 control-label">{{labelName.TemplateType}}
                                                        <span class="text-danger">*</span></label>
                                                    <div class="col-md-8 inputGroupContainer">
                                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                                            <select
                                                                class="form-select form-select-sm shadow-sm rounded-lg"
                                                                formControlName="template_type">
                                                                <option value="" selected>Select</option>
                                                                <option value="1">Normal </option>
                                                                <option value="2">Promotional </option>

                                                            </select>
                                                        </div>
                                                        <div *ngIf="myForm.get('template_type')?.touched">
                                                            <span class="text-danger"
                                                                *ngIf="myForm.get('template_type')?.hasError('required')">
                                                                This field is required.
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="form-group row pt-4" *ngIf="notification">
                                                    <label
                                                        class="col-md-4 control-label ">{{labelName.NotificationType}}
                                                        <span class="text-danger">*</span></label>
                                                    <div class="col-md-8 inputGroupContainer">
                                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                                            <input type="text" placeholder="" class="form-control"
                                                                formControlName="notification_type">
                                                        </div>
                                                        <div *ngIf="myForm.get('notification_type')?.touched">
                                                            <span class="text-danger"
                                                                *ngIf="myForm.get('notification_type')?.hasError('required')">
                                                                This field is required.
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group row pt-4" *ngIf="notification">
                                                    <label class="col-md-4 control-label ">{{labelName.Subject}}<span
                                                            class="text-danger">*</span></label>
                                                    <div class="col-md-8 inputGroupContainer">
                                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                                            <input type="text" placeholder="" class="form-control"
                                                                formControlName="notification_subject">
                                                        </div>
                                                        <div *ngIf="myForm.get('notification_subject')?.touched">
                                                            <span class="text-danger"
                                                                *ngIf="myForm.get('notification_subject')?.hasError('required')">
                                                                This field is required.
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="form-group row pt-4" *ngIf="notification">
                                                    <label class="col-md-4 control-label ">{{labelName.StartDate}}<span
                                                            class="text-danger">*</span></label>
                                                    <div class="col-md-8 inputGroupContainer">
                                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                                            <input type="date" placeholder="" class="form-control"
                                                                formControlName="start_date">
                                                        </div>
                                                        <div *ngIf="myForm.get('start_date')?.touched">
                                                            <span class="text-danger"
                                                                *ngIf="myForm.get('start_date')?.hasError('required')">
                                                                This field is required.
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group row pt-4" *ngIf="notification">
                                                    <label class="col-md-4 control-label ">{{labelName.EndDate}}<span
                                                            class="text-danger">*</span></label>
                                                    <div class="col-md-8 inputGroupContainer">
                                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                                            <input type="date" placeholder="" class="form-control"
                                                                formControlName="end_date">
                                                        </div>
                                                        <div *ngIf="myForm.get('end_date')?.touched">
                                                            <span class="text-danger"
                                                                *ngIf="myForm.get('end_date')?.hasError('required')">
                                                                This field is required.
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="form-group row pt-4" *ngIf="eMail || notification">
                                                    <label class="col-md-4 control-label ">{{labelName.Message}}<span
                                                            class="text-danger">*</span></label>
                                                    <div class="col-md-8 inputGroupContainer">
                                                        <div class="input-group editor"> <strong>:</strong> &nbsp;

                                                            <angular-editor class="angular_editor"
                                                                [(ngModel)]="htmlContent" (input)="showHtml()"
                                                                [ngModelOptions]="{standalone: true}" [config]="config"
                                                                [placeholder]="'Enter text here...'">
                                                            </angular-editor>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group row pt-4" *ngIf="notification">
                                                    <label class="col-md-4 control-label">{{labelName.Priority}} <span
                                                            class="text-danger">*</span></label>
                                                    <div class="col-md-8 inputGroupContainer">
                                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                                            <select
                                                                class="form-select form-select-sm shadow-sm rounded-lg"
                                                                formControlName="notification_priority">
                                                                <option value="" selected>Select</option>
                                                                <option value="1">Low </option>
                                                                <option value="2">Medium </option>
                                                                <option value="3">High </option>

                                                            </select>
                                                        </div>
                                                        <div *ngIf="myForm.get('notification_priority')?.touched">
                                                            <span class="text-danger"
                                                                *ngIf="myForm.get('notification_priority')?.hasError('required')">
                                                                This field is required.
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                              

                                                <div *ngIf='isSuperAdmin'>
                                                    <div class="pt-3"  *ngIf="sms || eMail || notification">
                                                        <div class="form-group row">
                                                            <label
                                                                class="col-md-4 control-label">{{labelName.TenantName}}
                                                                <span class="text-danger">*</span></label>
                                                                <div class="col-md-8 inputGroupContainer">
                                                                    <div class="input-group"> <strong>:</strong> &nbsp;
                                                                    <select 
                                                                        (change)="getRoles($event.target.value)"
                                                                        class="form-control form-control-sm shadow-sm rounded-lg"
                                                                        id="sel1">
                                                                        <option value="" [selected]="true">select
                                                                        </option>
                                                                        <option *ngFor="let item of tenanates"
                                                                            [value]='item.TNT_CODE'>
                                                                            {{item.TNT_NAME}}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>




                                                <div class="form-group row pt-4" *ngIf="sms || eMail || notification">
                                                    <label class="col-md-4 control-label">{{labelName.Designation}}
                                                        <span class="text-danger">*</span></label>
                                                    <div class="col-md-8 inputGroupContainer">
                                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                                            <select
                                                                class="form-select form-select-sm shadow-sm rounded-lg"
                                                                formControlName="designation_id"
                                                                (change)="designationChange($event.target.value)">
                                                                <option value="" [selected]="true">select</option>
                                                                <option *ngFor="let item of roles"
                                                                    [value]='item.ROLE_ID'>
                                                                    {{item.ROLE_NAME}}</option>

                                                            </select>
                                                        </div>
                                                        <div *ngIf="myForm.get('designation_id')?.touched">
                                                            <span class="text-danger"
                                                                *ngIf="myForm.get('designation_id')?.hasError('required')">
                                                                This field is required.
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group row pt-4" *ngIf="sms || eMail || notification">
                                                    <label class="col-md-4 control-label">{{labelName.Staff}} <span
                                                            class="text-danger">*</span></label>
                                                    <div class="col-md-8 inputGroupContainer">
                                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                                            <!-- <select class="form-control form-control-sm shadow-sm rounded-lg"
                                                                formControlName="staff">
                                                                <option value="" [selected]="true">select</option>
                                                                <option *ngFor="let item of table" [value]='item.USERID'>
                                                                    {{item.FIRSTNAME}}</option>                                                              

                                                            </select> -->
                                                            <ng-multiselect-dropdown style="width:98%"
                                                                formControlName="staff" [placeholder]="'select'"
                                                                [settings]="dropdownSettings"
                                                                [data]="dropdownListCategory"
                                                                (onSelect)="onItemSelect($event)"
                                                                (onSelectAll)="onSelectAll($event)"
                                                                [(ngModel)]='selectedCategory'>
                                                            </ng-multiselect-dropdown>
                                                            <!-- <ng-multiselect-dropdown style="width:98%" 
                                                                [placeholder]="'select cource'"
                                                                [settings]="dropdownSettings" [data]="dropdownListCategory"
                                                                (onSelect)="onItemSelect($event)"
                                                                [ngModelOptions]="{standalone: true}"
                                                                (onSelectAll)="onSelectAll($event)"
                                                                [(ngModel)]='selectedCategory'>
                                                            </ng-multiselect-dropdown> -->
                                                        </div>
                                                        <div *ngIf="myForm.get('staff')?.touched">
                                                            <span class="text-danger"
                                                                *ngIf="myForm.get('staff')?.hasError('required')">
                                                                This field is required.
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group row pt-4" *ngIf="notification || eMail">
                                                    <label class="col-md-4 control-label ">{{labelName.FileUpload}}
                                                        <span class="text-danger"></span></label>
                                                    <div class="col-md-8 inputGroupContainer">
                                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                                            <input (change)='changeFile($event)' type="file"
                                                                #materialFile id="materialFile1" placeholder=""
                                                                class="form-control">
                                                        </div>
                                                        <p>{{fileName}}</p>
                                                    </div>
                                                </div>


                                            </fieldset>
                                        </form>
                                    </div>
                                    <hr>
                                    <div class="text-center mt-2 pb-4 ">
                                        <button type="button" (click)="onSubmit(myForm)"
                                            class="btn btn-sm  btn-save">Submit</button> &nbsp;
                                        <button type="button" (click)="cancle()"
                                            class="btn btn-danger btn-sm">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>