<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 *ngIf="!isEdit" class="mb-0">{{headingName.AddEvent1}}</h5>
                                        <h5 *ngIf="isEdit" class="mb-0">{{headingName.EditEvent}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <form class="well form-horizontal" [formGroup]="myForm"
                                            (ngSubmit)="onSubmit(myForm)">
                                            <fieldset>
                                                <div class="row">
                                                    <div class="col-md-6 mb-3">
                                                        <div class="form-group row ">
                                                            <label class="col-md-4 control-label line_2 rem_1">
                                                                {{labelName.CategoryName}} <span class="text-danger">*</span></label>
                                                            <div class="col-md-8 inputGroupContainer">
                                                                <div class="input-group"> <strong>:</strong> &nbsp;
                                                                    <select formControlName='event_category_id'
                                                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                                                        <option value="" [selected]="true">select
                                                                        </option>
                                                                        <option *ngFor="let item of categoryDropdown"
                                                                            [value]='item.event_category_id'>
                                                                            {{item.event_category_name}}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                                <div *ngIf="myForm.get('event_category_id').touched">
                                                                    <span class="text-danger"
                                                                        *ngIf="myForm.get('event_category_id').hasError('required')">
                                                                        This field is required.
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="col-md-6 mb-3">
                                                        <div class="form-group row ">
                                                            <label class="col-md-4 control-label line_2 rem_1">
                                                                {{labelName.Logo}}<span class="text-danger">*</span></label>
                                                            <div class="col-md-8 inputGroupContainer">
                                                                <div class="input-group"> <strong>:</strong> &nbsp;
                                                                    <input type="file" #materialFile id="materialFile1"
                                                                        placeholder="" (change)='changeFile($event)'
                                                                        class="form-control form-control-sm shadow-sm rounded-lg "
                                                                        formControlName="event_logo">
                                                                </div>
                                                                <p>{{fileName}}</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6 mb-3">
                                                        <div class="form-group row ">
                                                            <label class="col-md-4 control-label line_2 rem_1">
                                                                {{labelName.Title}}<span class="text-danger">*</span></label>
                                                            <div class="col-md-8 inputGroupContainer">
                                                                <div class="input-group"> <strong>:</strong> &nbsp;
                                                                    <input formControlName='event_title'
                                                                        placeholder='Event Title'
                                                                        class="form-control form-control-sm shadow-sm rounded-lg "
                                                                        type="text">
                                                                </div>
                                                                <div *ngIf="myForm.get('event_title').touched">
                                                                    <span class="text-danger"
                                                                        *ngIf="myForm.get('event_title').hasError('required')">
                                                                        This field is required.
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6 mb-3">
                                                        <div class="form-group row ">
                                                            <label class="col-md-4 control-label line_2 rem_1">
                                                               {{labelName.EventName}}<span class="text-danger">*</span></label>
                                                            <div class="col-md-8 inputGroupContainer">
                                                                <div class="input-group"> <strong>:</strong> &nbsp;
                                                                    <input formControlName='event_name'
                                                                        placeholder='Event Name'
                                                                        class="form-control form-control-sm shadow-sm rounded-lg "
                                                                        type="text">
                                                                </div>
                                                                <div *ngIf="myForm.get('event_name').touched">
                                                                    <span class="text-danger"
                                                                        *ngIf="myForm.get('event_name').hasError('required')">
                                                                        This field is required.
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6 mb-3">
                                                        <div class="form-group row">
                                                            <label class="col-md-4 control-label line_2 rem_1">
                                                                {{labelName.Description}}</label>
                                                            <div class="col-md-8 inputGroupContainer">
                                                                <div class="input-group"><strong>:</strong> &nbsp;
                                                                    <textarea formControlName='event_description'
                                                                        placeholder='Event Description'
                                                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                                            </textarea>
                                                                </div>
                                                                <div *ngIf="myForm.get('event_description').touched">
                                                                    <span class="text-danger"
                                                                        *ngIf="myForm.get('event_description').hasError('required')">
                                                                        This field is required.
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6 mb-3">
                                                        <div class="form-group row">
                                                            <label class="col-md-4 control-label line_2 rem_1">
                                                                {{labelName.StartDate}} <span class="text-danger">*</span></label>
                                                            <div class="col-md-8 inputGroupContainer">
                                                                <div class="input-group"> <strong>:</strong> &nbsp;
                                                                    <input min="{{minDate}}"
                                                                        formControlName='event_from_timestamp'
                                                                        placeholder='Start Date'
                                                                        class="form-control form-control-sm shadow-sm rounded-lg "
                                                                        type="date">
                                                                </div>
                                                                <div *ngIf="myForm.get('event_from_timestamp').touched">
                                                                    <span class="text-danger"
                                                                        *ngIf="myForm.get('event_from_timestamp').hasError('required')">
                                                                        This field is required.
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6 mb-3">
                                                        <div class="form-group row">
                                                            <label class="col-md-4 control-label line_2 rem_1">
                                                               {{labelName.EndDate}}<span class="text-danger">*</span></label>
                                                            <div class="col-md-8 inputGroupContainer">
                                                                <div class="input-group"> <strong>:</strong> &nbsp;
                                                                    <input min="{{minDate}}"
                                                                        formControlName='event_to_timestamp'
                                                                        class="form-control form-control-sm shadow-sm rounded-lg "
                                                                        type="date">
                                                                </div>
                                                                <div *ngIf="myForm.get('event_to_timestamp').touched">
                                                                    <span class="text-danger"
                                                                        *ngIf="myForm.get('event_to_timestamp').hasError('required')">
                                                                        This field is required.
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6 mb-3">
                                                        <div class="form-group row">
                                                            <label class="col-md-4 control-label line_2 rem_1">
                                                                {{labelName.Type}} <span class="text-danger">*</span></label>
                                                            <div class="col-md-8 inputGroupContainer">
                                                                <div class="input-group"> <strong>:</strong> &nbsp;
                                                                    <select formControlName='event_type'
                                                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                                                        <option value="" [selected]="true">select</option>
                                                                        <option value='1'>Internal</option>
                                                                        <option value='2'>External</option>
                                                                    </select>
                                                                </div>
                                                                <div *ngIf="myForm.get('event_type').touched">
                                                                    <span class="text-danger"
                                                                        *ngIf="myForm.get('event_type').hasError('required')">
                                                                        This field is required.
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6 mb-3">
                                                        <div class="form-group row">
                                                            <label class="col-md-4 control-label line_2 rem_1">
                                                                {{labelName.ParticipationType}} <span
                                                                    class="text-danger">*</span></label>
                                                            <div class="col-md-8 inputGroupContainer">
                                                                <div class="input-group"> <strong>:</strong> &nbsp;
                                                                    <select formControlName='event_participation_type'
                                                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                                                        <option value="" [selected]="true">select</option>
                                                                        <option [value]='1'>Individual</option>
                                                                        <option [value]='2'>Group</option>
                                                                    </select>
                                                                </div>
                                                                <div
                                                                    *ngIf="myForm.get('event_participation_type').touched">
                                                                    <span class="text-danger"
                                                                        *ngIf="myForm.get('event_participation_type').hasError('required')">
                                                                        This field is required.
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 mb-3">
                                                        <div class="form-group row">
                                                            <label class="col-md-4 control-label line_2 rem_1">
                                                                {{labelName.Eligibility}} <span class="text-danger">*</span></label>
                                                            <div class="col-md-8 inputGroupContainer">
                                                                <div class="input-group"> <strong>:</strong> &nbsp;
                                                                    <input formControlName='event_eligibility'
                                                                        placeholder='Eligibility'
                                                                        class="form-control form-control-sm shadow-sm rounded-lg "
                                                                        type="text">
                                                                </div>
                                                                <div *ngIf="myForm.get('event_eligibility').touched">
                                                                    <span class="text-danger"
                                                                        *ngIf="myForm.get('event_eligibility').hasError('required')">
                                                                        This field is required.
                                                                    </span>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 mb-3">
                                                        <div class="form-group row">
                                                            <label class="col-md-4 control-label line_2 rem_1">
                                                                {{labelName.Location}} <span class="text-danger">*</span></label>
                                                            <div class="col-md-8 inputGroupContainer">
                                                                <div class="input-group"> <strong>:</strong> &nbsp;
                                                                    <input formControlName='event_location'
                                                                        placeholder='Location'
                                                                        class="form-control form-control-sm shadow-sm rounded-lg "
                                                                        type="text">
                                                                </div>
                                                                <div *ngIf="myForm.get('event_location').touched">
                                                                    <span class="text-danger"
                                                                        *ngIf="myForm.get('event_location').hasError('required')">
                                                                        This field is required.
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6 mb-3">
                                                        <div class="form-group row">
                                                            <label class="col-md-4 control-label line_2 rem_1">
                                                                {{labelName.Sponsor}}</label>
                                                            <div class="col-md-8 inputGroupContainer">
                                                                <div class="input-group"> <strong>:</strong> &nbsp;
                                                                    <input formControlName='event_sponsor'
                                                                        placeholder='Sponsor'
                                                                        class="form-control form-control-sm shadow-sm rounded-lg "
                                                                        type="text">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6 mb-3">
                                                        <div class="form-group row">
                                                            <label class="col-md-4 control-label line_2 rem_1">
                                                                {{labelName.Organizer}}</label>
                                                            <div class="col-md-8 inputGroupContainer">
                                                                <div class="input-group"> <strong>:</strong> &nbsp;
                                                                    <input formControlName='event_organizer'
                                                                        placeholder='Organizer'
                                                                        class="form-control form-control-sm shadow-sm rounded-lg "
                                                                        type="text">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6 mb-3">
                                                        <div class="form-group row">
                                                            <label class="col-md-4 control-label line_2 rem_1">
                                                                {{labelName.OrganizerName}}</label>
                                                            <div class="col-md-8 inputGroupContainer">
                                                                <div class="input-group"> <strong>:</strong> &nbsp;
                                                                    <input
                                                                        formControlName='event_organizer_contact_name'
                                                                        placeholder='Organizer Name'
                                                                        class="form-control form-control-sm shadow-sm rounded-lg "
                                                                        type="text">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="col-md-6 mb-3">
                                                        <div class="form-group row">
                                                            <label class="col-md-4 control-label line_2 rem_1">
                                                               {{labelName.OrganizerEmail}}</label>
                                                            <div class="col-md-8 inputGroupContainer">
                                                                <div class="input-group"> <strong>:</strong> &nbsp;
                                                                    <input formControlName='event_organizer_email'
                                                                        placeholder='Organizer Email'
                                                                        class="form-control form-control-sm shadow-sm rounded-lg "
                                                                        type="email">
                                                                </div>
                                                                <div>
                                                                    <span class="text-danger"
                                                                        *ngIf="myForm.get('event_organizer_email').hasError('email')">
                                                                        please enter valid email.
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6 mb-3">
                                                        <div class="form-group row ">
                                                            <label class="col-md-4 control-label line_2 rem_1">
                                                                {{labelName.OrganizerMobile}}</label>
                                                            <div class="col-md-8 inputGroupContainer">
                                                                <div class="input-group"> <strong>:</strong> &nbsp;
                                                                    <input formControlName='event_organizer_mobile'
                                                                        placeholder='Organizer Mobile'
                                                                        class="form-control form-control-sm shadow-sm rounded-lg"
                                                                        minlength="10" maxlength="12"
                                                                        onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6 mb-3">
                                                        <div class="form-group row">
                                                            <label class="col-md-4 control-label line_2 rem_1">
                                                                {{labelName.RegistrationStartDate}}<span
                                                                    class="text-danger">*</span></label>
                                                            <div class="col-md-8 inputGroupContainer">
                                                                <div class="input-group"> <strong>:</strong> &nbsp;
                                                                    <input min="{{minDate}}"
                                                                        formControlName='registration_start_timestamp'
                                                                        class="form-control form-control-sm shadow-sm rounded-lg "
                                                                        type="date">
                                                                </div>
                                                                <div
                                                                    *ngIf="myForm.get('registration_start_timestamp').touched">
                                                                    <span class="text-danger"
                                                                        *ngIf="myForm.get('registration_start_timestamp').hasError('required')">
                                                                        This field is required.
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6 mb-3">
                                                        <div class="form-group row">
                                                            <label class="col-md-4 control-label line_2 rem_1">
                                                                {{labelName.RegistrationEndDate}} <span
                                                                    class="text-danger">*</span></label>
                                                            <div class="col-md-8 inputGroupContainer">
                                                                <div class="input-group"> <strong>:</strong> &nbsp;
                                                                    <input min="{{minDate}}"
                                                                        formControlName='registration_end_timestamp'
                                                                        class="form-control form-control-sm shadow-sm rounded-lg "
                                                                        type="date">
                                                                </div>
                                                                <div
                                                                    *ngIf="myForm.get('registration_end_timestamp').touched">
                                                                    <span class="text-danger"
                                                                        *ngIf="myForm.get('registration_end_timestamp').hasError('required')">
                                                                        This field is required.
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6 mb-3">
                                                        <div class="form-group row">
                                                            <label class="col-md-4 control-label line_2 rem_1">
                                                                {{labelName.RegistrationFee}}<span
                                                                class="text-danger">*</span></label>
                                                            <div class="col-md-8 inputGroupContainer">
                                                                <div class="input-group"> <strong>:</strong> &nbsp;
                                                                    <input formControlName='registration_fee'
                                                                        placeholder='Registration Fee'
                                                                        class="form-control form-control-sm shadow-sm rounded-lg "
                                                                        type="number">
                                                                </div>
                                                                <div *ngIf="myForm.get('registration_fee').touched">
                                                                    <span class="text-danger"
                                                                        *ngIf="myForm.get('registration_fee').hasError('required')">
                                                                        This field is required.
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </form>
                                        <div class="text-center pt-4">
                                            <button type="button" *ngIf="!isEdit" [disabled]="myForm.invalid"
                                                (click)="onSubmit(myForm)" class="btn btn-sm  btn-save">Save</button>
                                            <button type="button" [disabled]="myForm.invalid" *ngIf="isEdit"
                                                (click)="onSubmit(myForm)" class="btn btn-sm  btn-save">Update</button>
                                            &nbsp;&nbsp;
                                            <button type="button" class="btn btn-sm btn-danger" (click)="close()"
                                                id="md_close">cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>