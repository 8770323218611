<br>

<div class="page-wrapper">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">

                    <div class="container-fluid">

                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">



                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">Class Fee</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right mb-2">
                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                                data-bs-target="#myModal">Add Class Fee</button>
                                        </div>
                                        <div>
                                            <table datatable id="DataTables_Table_0"
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #0d9bc4; color:#ffffff;">
                                                    <tr>
                                                        <th>
                                                            Academic Year</th>
                                                        <th> Course</th>
                                                        <th>Course Schedule</th>

                                                        <th>Select Fee Type</th>
                                                        <th>Amount</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody >
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td  style="display: flex;"> 
                                                            <span data-bs-toggle="modal" data-bs-target="#myModal"
                                                            src=""
                                                            class="edit_icon" (click)="edit()"> <i class="fas fa-pen pt-1 mt-1"></i></span >
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <div class="modal" id="myModal" data-backdrop="static" data-keyboard="false">
                <div class="modal-dialog ">
                    <div class="modal-content">
                        <!-- Modal Header -->
                        <div class="modal-header">
                            <h4 class="modal-title">Add Class Fees </h4>
                            <button type="button" (click)="close()" class="close"
                                data-bs-dismiss="modal">&times;</button>
                        </div>
                        <!-- Modal body -->
                        <div class="modal-body">
                            <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                                <fieldset>
                                    <div class="form-group row pt-4">
                                        <label class="col-md-4 control-label">Academic Year</label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group"><strong> :</strong> &nbsp;
                                                <select class="form-control form-control-sm shadow-sm rounded-lg"
                                                    formControlName="Academicyear">
                                                    <option value="" [selected]="true">select</option>
                                                    <option *ngFor="let category of categories"
                                                        [value]='category.DEPARTMENT_ID'>
                                                        {{category.DEPARTMENT_NAME}}
                                                    </option>

                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row pt-4">
                                        <label class="col-md-4 control-label">Course</label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group"><strong> :</strong> &nbsp;
                                                <select class="form-control form-control-sm shadow-sm rounded-lg"
                                                    formControlName="Course">
                                                    <option value="" [selected]="true">select</option>
                                                    <option *ngFor="let category of categories"
                                                        [value]='category.DEPARTMENT_ID'>
                                                        {{category.DEPARTMENT_NAME}}
                                                    </option>

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                   
                                    <div class="form-group row pt-4">
                                        <label class="col-md-4 control-label">Course Schedule</label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group"><strong> :</strong> &nbsp;
                                                <select class="form-control form-control-sm shadow-sm rounded-lg"
                                                    formControlName="Courseschedule">
                                                    <option value="" [selected]="true">select</option>
                                                    <option *ngFor="let category of categories"
                                                        [value]='category.DEPARTMENT_ID'>
                                                        {{category.DEPARTMENT_NAME}}
                                                    </option>

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                 
                                    <div class="form-group row pt-4">
                                        <label class="col-md-4 control-label">Select Fee Type</label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group"><strong> :</strong> &nbsp;
                                                <select class="form-control form-control-sm shadow-sm rounded-lg"
                                                    formControlName="feetype">
                                                    <option value="" [selected]="true">select</option>
                                                    <option *ngFor="let category of categories"
                                                        [value]='category.DEPARTMENT_ID'>
                                                        {{category.DEPARTMENT_NAME}}
                                                    </option>

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                   
                                    <div class="form-group row pt-4">
                                        <label class="col-md-4 control-label ">Amount<span
                                                class="text-danger">*</span></label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group"> <strong>:</strong> &nbsp;
                                                <input type="text" placeholder="" class="form-control"
                                                    formControlName="Amount">
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>

                        </div>
                        <div class="modal-footer">
                            <button type="button" (click)="onSubmit(myForm)" class="btn btn-success"
                                [disabled]="myForm.invalid">Save</button>

                            <button type="button" class="btn btn-danger" id='md_close' (click)="close()"
                                data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>