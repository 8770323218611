import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray, AbstractControl } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
@Component({
  selector: 'app-return-date',
  templateUrl: './return-date.component.html',
  styleUrls: ['./return-date.component.css']
})
export class ReturnDateComponent implements OnInit {
  myForm:FormGroup;
  minDate: any = moment().format('yyyy-MM-DD')
  returnDate: Array<any> = [];
  calendarList: Array<any> = [];
  headingName: any = {};
  workItemId: any;
  labelName: any = {};
  qrCode:Array<any>=[];
  studentId: any;
  bookid: any;
  bookStdId:any
  constructor(private commonService: CommonService, private toastr: ToastrService, private fb: FormBuilder, private active: ActivatedRoute, private route: Router,) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
  }

  ngOnInit(): void {
    // this.loadReturnDate()
    this.loadHeadings();
    this.getLabels();
    
  }

  activeSpinner() {
    this.commonService.activateSpinner();
  }

  deactivateSpinner() {
    this.commonService.deactivateSpinner()
  }

  loadHeadings() {
    this.headingName = {};
    this.commonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  getLabels() {
    this.labelName = {};
    this.commonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }
  // loadReturnDate() {
  //   this.returnDate = [];
  //   this.activeSpinner();
  //   let payLoad = {
  //     "BOOK_STD_TNT_CODE": localStorage.getItem("TenantCode"),
  //     //  "BOOK_STD_STUDENT_ID":25663508,
  //   }
  //   this.commonService.postCall('LibraryManagement/AdminViewReports', payLoad).subscribe((res: any) => {
  //     this.returnDate = res;
  //     this.deactivateSpinner();
  //   }, err => { this.returnDate = []; this.deactivateSpinner() })
  // }


  scanQr(StuId: any) {
    if(StuId==""){
      this.qrCode=[];
      return;
    }
    this.studentId = StuId;
    let payload = {
      "enrollment_no": StuId
    }
    this.commonService.postCall('LibraryManagement/Getbookreturn', payload).subscribe((res: any) => {
      console.log(res);
       this.qrCode = res;
       this.bookid=res[0].book_id
      // this.singleData = res[0];

    })
  }

  calendarSelect(event:any,id:any) {
    this.calendarList = [];
    let obj = {
      "BOOK_STD_ACTUAL_RETURN_DATE": this.bookStdId,
       "BOOK_STD_ID": id,
       "BOOK_ID":this.bookid
    }
    this.calendarList.push(obj);
    this.commonService.postCall('LibraryManagement/UpdateActualReturnDate', this.calendarList).subscribe((res: any) => {
      this.toastr.success('Book Returned Successfully');
      window.location.reload();
    })
  }

  // calendarSelect(event, id) {
  //   this.calendarList = [];
  //   let obj = {
  //     "BOOK_STD_ACTUAL_RETURN_DATE": event.target.value,
  //     "BOOK_STD_ID": id
  //   }
  //   this.calendarList.push(obj);
  //   this.commonService.postCall('LibraryManagement/UpdateActualReturnDate', this.calendarList).subscribe((res: any) => {
  //     this.loadReturnDate();
  //     this.toastr.success('Return Date Upated Successfully');
  //   })
  // }

onSubmit(myForm){

}

  ok() {
    this.activeSpinner();
    this.route.navigate(['/home/dashboard']);
    this.deactivateSpinner();
  }



}
