import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.css']
})
export class ItemsComponent implements OnInit {
  myForm: FormGroup;
  isItems: boolean = false;
  isUnit: boolean = false;
  isCategory: boolean = false;
  table: Array<any> = [];
  editData: any;
  isEdit: boolean = false;
  categoryDropdown: Array<any> = [];
  unitDropdown: Array<any> = [];
  labelName: any = {};
  headingName: any = {};
  workItemId: any;

  constructor(private fb: FormBuilder,private active : ActivatedRoute, private CommonService: CommonService, private toastr: ToastrService) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadItems();
    this.loadCategory();
    this.loadUnit();
    this.loadlabels();
    this.loadHeadings();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      item_name: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      description: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      category_id: ['', Validators.required],
      unit: ['', Validators.required],
      item_nature: ['', Validators.required],
      item_type: ['', Validators.required],
      usage_place: ['', Validators.required],
      item_code: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
    })
  }
  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  loadCategory() {
    this.categoryDropdown = [];

    this.CommonService.getCall(`EmsRoomTypesDetails/ItemsCategoryDropDownList/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {

      this.categoryDropdown = res;

    })
  }

  loadUnit() {
    this.unitDropdown = [];
    let payload = {
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('EmsItemUnit/GetList', payload).subscribe((res: any) => {

      this.unitDropdown = res;

    })
  }

  loadItems() {
    let payload = {
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('InvetoryItems/GetList', payload).subscribe((res: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = res;
      }, 10)
    })
  }

  onSubmit(form: FormGroup) {
    let value = form.value;
    value.tnt_code = localStorage.getItem('TenantCode');
    value.created_by = localStorage.getItem('UserId');

    if (this.isEdit) {

      value.modified_by = localStorage.getItem('UserId')
      value['item_id'] = this.editData.item_id;
      this.CommonService.postCall('InvetoryItems/Update', value).subscribe((res: any) => {
        this.loadItems();
        this.toastr.success(" Updated Successfully");
        document.getElementById('md_close')?.click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Not Updated')
      })
    }
    else {

      this.CommonService.postCall('InvetoryItems/Create', value).subscribe((res: any) => {
        this.loadItems();
        this.toastr.success(" Created Successfully");
        document.getElementById('md_close')?.click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Not created')

      })
    }
  }
  edit(itemId) {
    this.editData = itemId;
    this.isEdit = true;
    this.myForm.reset();
    let payLoad = {
      "item_id": itemId
    }
    this.CommonService.postCall('InvetoryItems/Get', payLoad).subscribe((res: any) => {
      if (res instanceof Array && res.length) {
        this.editData = res[0];
        this.dataTransForm()
      } else {
        this.editData = res;
        this.dataTransForm()
      }

      this.dataTransForm();

    }), err => { }
  }

  dataTransForm() {
    let ctrls = this.myForm.controls
    Object.keys(ctrls).map((key) => {
      let ctrl: AbstractControl = ctrls[key];
      ctrl.setValue(this.editData[key])

    });
  }
  add() {
    this.isItems = false;
  }
  close() {
    this.isItems = false;
    this.isEdit = false;
    this.myForm.reset();
    this.loadItems();

  }


  Items() { this.isItems = true; }

  category() { this.isCategory = true }

  unit() { this.isUnit = true }
}
