import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { BaseComponent } from 'src/app/pages/base.component';
@Component({
  selector: 'app-fees-type',
  templateUrl: './fees-type.component.html',
  styleUrls: ['./fees-type.component.css']
})
export class FeesTypeComponent extends BaseComponent implements OnInit {
  table: Array<any> = [];
  myForm: FormGroup;
  isEdit: boolean = false;
  editData: any = {};
  TenantCode: string = localStorage.getItem('TenantCode');
  UserId: string = localStorage.getItem('UserId');
  labelName: any = {};
  headingName: any = {};
  workItemId:any;
  constructor(private fb: FormBuilder, private active : ActivatedRoute,CommonService: CommonService, toastr: ToastrService) {
    super(CommonService, toastr);
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadlabels();
    this.loadfees();
    this.loadHeadings();
   
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      FEETYPE_NAME: ['', [Validators.required, Validators.pattern('^[a-zA-Z \-\']+'), Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      FEETYPE_DESCRIPTION: [''],
      feetype_mode: ['', [Validators.required]]

    });
    this.loadfees();
  }


  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  loadfees() {
    this.activeSpinner();
    let payLoad: any = {
      //"TNT_CODE": 71258324,
      "TNT_CODE":this.tId || localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('Feestype/GetList', payLoad).subscribe((res: any) => {

      this.table = [];
      setTimeout(() => {
        this.table = res;

      }, 10)
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner() })
  }

  onSubmit(form: FormGroup) {
    let value: any = form.value;

    let payload = {

      "FEETYPE_NAME": value.FEETYPE_NAME,
      "FEETYPE_DESCRIPTION": value.FEETYPE_DESCRIPTION,
      "feetype_mode": value.feetype_mode,
      "FEETYPE_STATUS": true,
      "TNT_CODE": localStorage.getItem("TenantCode"),
      "FEETYPE_CREATED_BY": localStorage.getItem("UserId"),
      "FEETYPE_CREATED_DATE": new Date(),
      "FEETYPE_MODIFIED_BY": localStorage.getItem("UserId"),
      "FEETYPE_MODIFIED_DATE": new Date(),
    }
    if (this.isEdit) {


      payload['FEETYPE_ID'] = this.editData.FEETYPE_ID;
      payload['FEETYPE_CREATED_DATE'] = this.editData.FEETYPE_CREATED_DATE;
      this.CommonService.postCall('Feestype/Update', payload).subscribe((res: any) => {
        this.loadfees();
        this.toastr.success("fees Updated Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Fees Not Updated')
      })
    } else {

      this.CommonService.postCall('Feestype/Create', payload).subscribe((res: any) => {
        this.loadfees();
        this.toastr.success("Fees Created Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error.message : 'Class Not created')
        console.log(err.error.message)

      })
    }



  }



  edit(feesId) {
    this.isEdit = true;

    this.myForm.reset();
    // let payLoad = {
    //   "FEETYPE_ID": feesId,
    // }
    this.CommonService.getCall('Feestype/Get/' + feesId).subscribe((res: any) => {
      if (res instanceof Array && res.length) {
        this.editData = res[0];
        this.datatransform()
      } else {
        this.editData = res;
        this.datatransform()
      }
    }, err => { }

    )
  }
  datatransform() {
    let ctrls: any = this.myForm.controls;
    Object.keys(ctrls).map((key: string) => {
      let control: FormControl = ctrls[key];
      let value = this.editData[key];
      if (value != undefined) control.setValue(value);
    });
    ctrls['FEETYPE_ID'].setValue(this.editData['FEETYPE_ID'])
    ctrls['FEETYPE_NAME'].setValue(this.editData['FEETYPE_NAME'])
    ctrls['FEETYPE_DESCRIPTION'].setValue(this.editData['FEETYPE_DESCRIPTION'])
  }




  add() {
    this.editData = {};
    this.isEdit = false;
    this.myForm.reset();
  }

  close() {
    this.isEdit = false;
    this.myForm.reset();
  }

}
