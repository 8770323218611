import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { BaseComponent } from '../base.component';
@Component({
  selector: 'app-give-concession',
  templateUrl: './give-concession.component.html',
  styleUrls: ['./give-concession.component.css']
})
export class GiveConcessionComponent extends BaseComponent implements OnInit {
  table: Array<any> = [];
  concessionArr: Array<any> = [];
  courses: Array<any> = [];
  Schedules: Array<any> = [];
  sheduleId: string;
  feeConcessionTermAmount: number = 0;
  academics: any;
  Curriculums: any;
  users: any;
  userId: any;
  isParam: boolean = false;
  academicId: string;
  curriculumId: string;
  isDesable: boolean = false;
  labelName: any = {};
  headingName: any = {};
  workItemId:any;
  constructor(private fb: FormBuilder,private active: ActivatedRoute, CommonService: CommonService, toastr: ToastrService, private route: Router) {
    super(CommonService, toastr);
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
    this.loadCourse();
    this.getLabels();
    this.loadHeadings();
  }

  ngOnInit(): void {
  }

  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }


  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  loadCourse() {
    this.activeSpinner();
    this.CommonService.getAdminCourses(this.tId == '' ? undefined : this.tId).subscribe((res: any) => {
      this.courses = res;
      this.deactivateSpinner();
    }, e => {
      this.deactivateSpinner();
    })

  }

  // courceChange(acId) {
  //   this.activeSpinner()
  //   let payLoad = {
  //     "academicId": acId.target.value
  //   }
  //   this.CommonService.getCall('Curriculum/CurriculumDropDownList/' + acId.target.value).subscribe((res: any) => {
  //     this.Curriculums = res;
  //     this.deactivateSpinner()

  //   }, e => {
  //     this.deactivateSpinner()
  //   })
  // }

  courceChange(event) {

    this.sheduleId = '';
    let data = {
      "CourseId": event.target.value
    }
    this.CommonService.getAdminCourseSchedule(data).subscribe((res: any) => {
      this.deactivateSpinner()
      this.Schedules = res;
    }, e => { this.deactivateSpinner() })
  }

  sheduleChange(id) {
    this.activeSpinner();
    let payLoad = {
      "COURSESHD_ID": id.target.value
    }
    this.CommonService.postCall('Account/usersbycourseShedule', payLoad).subscribe((res: any) => {
      this.deactivateSpinner();
      this.users = res;
    }, e => {
      this.deactivateSpinner();
    });
  }



  load(event) {
    this.userId = event.target.value;
    this.activeSpinner();
    this.CommonService.postCall(`StudentFeeReceivables/GetBystudentfeeconcession`, { "fee_concession_student_id": this.userId }).subscribe((res: any) => {
      this.table = [];
      this.table = res;
      this.renderDataTable();
      this.table.map(a => a.checked = false);
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner(), console.log(e) })
  }

  onPayCheck(event, id, concession, actualAmount) {
    let check = event.target.checked;
    if (check == true) {
      let obj = {
        fee_concession_id: id,
        fee_concession_term_amount: concession
      }
      this.concessionArr.push(obj)
      this.isDesable = true;
    } else {
      this.concessionArr = this.concessionArr.filter(m => m.fee_concession_id != id);
      if (this.concessionArr.length == 0) {
        this.isDesable = false;
      }
    }
  }


  ValidateGiveConcession(event, concessionAmount) {
    let payAmount = event.target.value;
    if (concessionAmount < payAmount) {
      this.toastr.warning('Concession amount should be less than Amount.')
      return event.target.value = '';
    }
  }


  Update() {
    let payload = [];
    let data = this.table.filter(a => a.checked);
    data.forEach(e => {
      let obj = {
        fee_concession_id: e.fee_concession_id,
        fee_concession_term_amount: e.fee_concession_term_amount
      }
      payload.push(obj)
    });
    this.CommonService.postCall('StudentFeeReceivables/updateConcession', payload).subscribe((res: any) => {
      this.table = [];
      this.toastr.success("Concession Given Successfully");
    }, err => {
      this.toastr.error(err.error ? err.error : 'Concession Not Given Updated')
      this.deactivateSpinner();
    })
  }

}
