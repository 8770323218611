<br>
<div class="page-wrapper">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.VehicleTripList}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <!-- <div class="text-right">
                                <button type="button" class="btn btn-primary mb-1"  data-toggle="modal"
                                    data-target="#myModal" (click)="add()" >Add</button>      
                            </div> -->
                                        <div>
                                            <table id="patreggraph" datatable
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #0d9bc4; color:#ffffff;">
                                                    <tr>
                                                        <!-- <th> USID</th> -->


                                                        <th> Driver Name </th>
                                                        <th> Registration No</th>
                                                        <th> Bus No </th>
                                                        <th> Route Name </th>
                                                        <th> Lattitude </th>
                                                        <th> Longitude </th>
                                                        <th> Speed </th>
                                                        <th> Aayamma Name </th>
                                                        <th> Created on </th>
                                                        <th> Status </th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.driver_name }}</td>
                                                        <td>{{item.registration_number }}</td>
                                                        <td>{{item.bus_no }}</td>
                                                        <td>{{item.route_name }}</td>
                                                        <td>{{item.lattitude }}</td>
                                                        <td>{{item.longitude }}</td>
                                                        <td>{{item.speed }}</td>
                                                        <td>{{item.aayamma_name }}</td>
                                                        <td>{{item.created_on }}</td>
                                                        <td>{{item.status }}</td>
                                                    </tr>
                                                </tbody>

                                            </table>
                                        </div>
                                        <!-- <div *ngIf="!table.length" class="text-center">
                                            No Records to display
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>