<br>

<div class="page-wrapper">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">

                    <div class="container-fluid">

                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">



                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">Alert</h5>
                                    </div>
                                    <div class="form-group row pt-4" style="margin-left:-4px">
                                        <label class="col-md-4 control-label ">
                                            Message sending type<span class="text-danger">*</span></label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="radio"> <strong>:</strong> &nbsp;
                                                <div class="form-check-inline">
                                                    <label class="form-check-label">
                                                        <input value='0' formControlName="EVENT_TYPE" type="radio"
                                                            class="form-check-input" name="EVENT_TYPE">SMS
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label class="form-check-label">
                                                        <input value='1' type="radio" formControlName="EVENT_TYPE"
                                                            class="form-check-input" name="EVENT_TYPE">E-Mail
                                                    </label>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                             
                                    <div class="form-group row pt-4">
                                        <label class="col-md-4 control-label ms-2">Message<span
                                                class="text-danger">*</span></label>
                                        <div class="col-md-6 inputGroupContainer">
                                            <div class="input-group"> <strong>:</strong> &nbsp;
                                                <input type="text" placeholder="" class="form-control"
                                                    formControlName="Amount">
                                            </div>
                                        </div>
                                    </div>
                                  
                                    <div class="form-group row pt-4">
                                        <label class="col-md-4 control-label ms-2">Route</label>
                                        <div class="col-md-6 inputGroupContainer">
                                            <div class="input-group"><strong> :</strong> &nbsp;
                                                <select class="form-control form-control-sm shadow-sm rounded-lg"
                                                    formControlName="feetype">
                                                    <option value="" [selected]="true">select</option>
                                                    <option *ngFor="let category of categories"
                                                        [value]='category.DEPARTMENT_ID'>
                                                        {{category.DEPARTMENT_NAME}}
                                                    </option>

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                               
                                    <div class="form-group row pt-4">
                                        <label class="col-md-4 control-label ms-2">Class</label>
                                        <div class="col-md-6 inputGroupContainer">
                                            <div class="input-group"><strong> :</strong> &nbsp;
                                                <select class="form-control form-control-sm shadow-sm rounded-lg"
                                                    formControlName="feetype">
                                                    <option value="" [selected]="true">select</option>
                                                    <option *ngFor="let category of categories"
                                                        [value]='category.DEPARTMENT_ID'>
                                                        {{category.DEPARTMENT_NAME}}
                                                    </option>

                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="text-center pt-4">
                                        <button type="button" [disabled]="myForm.invalid"(click)="onSubmit(myForm)" class="btn btn-success">Save</button> &nbsp;
                                        <!-- <button type="button" [disabled]="myForm.invalid" *ngIf="isEdit" (click)="onSubmit(myForm)" class="btn btn-success" >Update</button>  -->
                                  
                                        <button
                                            type="button" class="btn btn-danger" (click)="close()" id="md_close" >cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>