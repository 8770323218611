import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-content-log',
  templateUrl: './content-log.component.html',
  styleUrls: ['./content-log.component.css']
})
export class ContentLogComponent implements OnInit {
  table: Array<any> = [];
  headingName: any = {};
  workItemId: any;
  constructor(private active : ActivatedRoute,private CommonService: CommonService) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.Getlist();
    this.loadHeadings();
  }

  ngOnInit(): void {
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  Getlist() {
    this.CommonService.getCall(`ContentManager/GetContentlogList/${localStorage.getItem('TenantCode')}/${localStorage.getItem('RoleName')}/${localStorage.getItem('UserId')}`).subscribe((response: any) => {
      this.table = response;
    })
  }
}
