import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../services/dashboard.service';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { CommonService } from '../services/common.service';
import { filter } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
declare let $: any;
@Component({
  selector: 'app-maincontent',
  templateUrl: './maincontent.component.html',
  styleUrls: ['./maincontent.component.css']
})
export class MaincontentComponent implements OnInit {
  cources: [] = [];
  newsList = [];
  news = {};
  isNews = false;
  isPieChart: Boolean = false;
  totalAmount: number = 0;
  paidAmount: number = 0;
  balance: number = 0;
  studentPresent: number = 0;
  studentAbsent: number = 0;
  staffPresent: number = 0;
  staffAbsent: number = 0;
  roleId: number | string = localStorage.getItem('RoleId');
  userName = localStorage.getItem('Username');
  roleName = localStorage.getItem('RoleName');
  superAdminDashboardData: any = {};
  AdminDashboard: any = {};
  staffAttendancePresent: any = {};
  staffAttendanceAbsent: any = {};
  fees: any = {};
  studentAttendancePresent: any = {};
  studentAttendanceAbsent: any = {};
  library: any = {};
  inventory: any = {};
  feedback: any = {};
  users: any = {};
  events: any = {};
  courses: any = {};
  totalStaff: any = {};
  totalStudent: any = {};
  studentfeereceivables: any = {};

  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true
  };
  public lineChartLabels = ['2000', '2001', '2002', '2003', '2004', '2005', '2006'];
  public lineChartType = 'line';
  public lineChartLegend = true;
  public lineChartData = [
    { data: [75, 49, 89, 31, 86, 35, 50], label: 'Present' },
    { data: [48, 38, 65, 39, 66, 17, 80], label: 'Absent' }
  ];




  public barChartOptions1 = {
    scaleShowVerticalLines: false,
    responsive: true
  };
  public barChartLabels = ['2000', '2001', '2002', '2003', '2004', '2005', '2006'];
  public barChartType = 'bar';
  public barChartLegend = true;
  public barChartData = [
    { data: [75, 49, 89, 31, 86, 35, 50], label: 'Series A' },
    { data: [48, 38, 65, 39, 66, 17, 80], label: 'Series B' }
  ];




  public pieChartLabels: string[] = ['Present', 'Absent']
  public pieChartData = [this.staffPresent, this.staffAbsent];
  public pieChartType = 'pie';


  public pieChartLabels1: string[] = ['Present', 'Absent']
  public pieChartData1 = [this.studentPresent, this.studentAbsent];
  public pieChartType1 = 'pie';



  public doughnutChartLabels = ['Total Amount', 'Paid Amount', 'Balance'];
  public doughnutChartData = [this.totalAmount, this.paidAmount, this.balance];
  public doughnutChartType = 'doughnut';



  public radarChartLabels = ['Q1', 'Q2', 'Q3', 'Q4'];
  public radarChartData = [
    { data: [80, 83, 94, 87], label: '2017' },
    { data: [90, 150, 121, 65], label: '2018' }
  ];
  public radarChartType = 'radar';




  constructor(private DashboardService: DashboardService, private route: Router, private commonService: CommonService, private toast: ToastrService) {


    if (this.roleId == 3 || this.roleId == 2 || this.roleName == 'HOD') {
      this.loadCourse();
    }
    this.getCource();
    this.getDashboard()
    this.calender();
    this.getNewsList();
    this.LoadSuperAdminDashboard();
    this.loadAdminDashboard();
  }

  course: any = {
    isSessions: false,
    isClasses: true
  }
  data: any = {}
  ngOnInit(): void {

  }

  loadCourse() {
    this.commonService.activateSpinner()
    this.commonService.getCall(`Courses/GetCourses/${localStorage.getItem('UserId')}/${localStorage.getItem('RoleId')}`).subscribe((res: any) => {
      this.cources = res;
      this.commonService.deactivateSpinner()
    }, e => {
      this.commonService.deactivateSpinner()
    })
  }


  getCource() {
    this.commonService.activateSpinner()
    this.DashboardService.getCource().subscribe((data: any) => {
      this.course = { ...this.course, ...data }
      this.stopSpinner()
    }, (e) => {
      this.stopSpinner()
    })
  }
  toggleCource(key, collapse) {
    this.course.isSessions = false;
    this.course.isClasses = false;
    this.course[key] = !collapse;
  }
  navigate(data, url) {
    delete data.Name;
    this.route.navigate([url], { queryParams: data })
  }
  getDashboard() {
    this.commonService.activateSpinner()
    this.DashboardService.loadDashboard().subscribe((res: any) => {
      this.stopSpinner()
      this.data = res ? res : {};

    }, (e) => {

      this.stopSpinner()
    })
  }

  stopSpinner() {
    this.commonService.deactivateSpinner()
  }
  joinSession(item) {
    this.commonService.activateSpinner();
    let payload = {
      "UserId": localStorage.getItem('UserId'), //RoleId,TenantCode,UserId,Username
      "TenantCode": localStorage.getItem('TenantCode'),
      "Username": localStorage.getItem('Username'),
      "APPOINTMENT_ID": item?.URL,
      "RoleId": localStorage.getItem('RoleId'),
      "CourseScheduleId": 1
    }

    this.commonService.postCall('AVService/Joinurl', payload).subscribe(res => {
      this.commonService.deactivateSpinner();
      if (res.JoinUrl) {
        window.open(res.JoinUrl, "_blank")
      } else {
        this.toast.warning("Host Not joined");
      }
    }, err => {
      this.toast.warning('Please Try Later');
      this.commonService.deactivateSpinner();
    });

  }
  calender() {
    $(function () {
      $(".b-notes").notes(".b-notes");

      $("#calendar").datepicker({
        todayHighlight: true,
        weekStart: 1
      }).on({

        'changeDate': function (e) {

          if (typeof (e.date) == "undefined") return false;

          var milliseconds = Date.parse(e.date);

          setCelendarDay(milliseconds);
        }

      });

      var today = new Date();
      var milliseconds = Date.parse(today.toString());

      setCelendarDay(milliseconds);

      function setCelendarDay(milliseconds) {
        // var date = new Date(milliseconds).format("dd/mm/yyyy");
        // var formatTitle = new Date(milliseconds).format("dddd, <b>d mmmm</b>");
        var list = $(".b-notes__list");
        var title = $(".b-app__title");


      }
    });
  }

  close() {

  }

  getNewsList() {
    this.newsList = []
    let payload = {
      "TNT_CODE": localStorage.getItem('TenantCode'),
      "ROLE_NAME": this.roleName
    }
    this.commonService.postCall("News/GetNewsList", payload).subscribe((response: any) => {
      this.newsList = response;
    })
  }

  getNews(EVENT_ID) {
    this.news = {}
    let payload = {
      "EVENT_ID": EVENT_ID
    }
    this.commonService.postCall("News/Get", payload).subscribe((response: any) => {
      this.isNews = true;
      this.news = response;
    })
  }
  // surveys() {
  //   this.route.navigate(['/home/surveys/93'])
  // }
  // polls() {
  //   this.route.navigate(['/home/poll/94'])
  // }

  LoadSuperAdminDashboard() {
    this.superAdminDashboardData = {}
    this.isPieChart = true;
    this.commonService.getCall(`Dashboard/LoadSuperAdminDashboard/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.superAdminDashboardData = res;
      this.totalAmount = res.studentfeereceivables[0].total_amount;
      this.paidAmount = res.studentfeereceivables[0].paid_amount;
      this.balance = res.studentfeereceivables[0].balance;
      this.doughnutChartData = [this.totalAmount, this.paidAmount, this.balance];
      this.staffPresent = res.staffattendance.find((e: any) => e.staff_attendance_status == "Present")?.staff_count;
      this.staffAbsent = res.staffattendance.find((e: any) => e.staff_attendance_status == "Absent")?.staff_count;
      this.pieChartData = [this.staffPresent, this.staffAbsent];
      this.studentPresent = res.studentattendance.find((e: any) => e.student_day_wise_status == "Present")?.student_count;
      this.studentAbsent = res.studentattendance.find((e: any) => e.student_day_wise_status == "Absent")?.student_count;
      this.pieChartData1 = [this.studentPresent, this.studentAbsent];
    })
  }

  loadAdminDashboard() {
    this.AdminDashboard = {}
    this.commonService.getCall(`Dashboard/LoadAdminOrSuperadminDashboard/${localStorage.getItem('RoleId')}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.AdminDashboard = res;
      this.studentfeereceivables = res['studentfeereceivables'];
      this.staffAttendancePresent = res['staffattendance'][0];
      this.staffAttendanceAbsent = res['staffattendance'][2]
      this.studentAttendancePresent = res['studentattendance'][0];
      this.studentAttendanceAbsent = res['studentattendance'][1];
      this.library = res['library'];
      this.inventory = res['inventory'];
      this.feedback = res['feedback'];
      this.users = res['users1'];
      this.events = res['Event'];
      this.courses = res['Courese'];
      this.totalStaff = res['Totalstaff'];
      this.totalStudent = res['TotalStudents'];
    })
  }
}
