import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { MaincontentComponent } from './maincontent/maincontent.component';
import { PolsComponent } from './pages/pols/pols.component';
import { TakepolsComponent } from './pages/takepols/takepols.component';
import { SurveysComponent } from './pages/surveys/surveys.component';
import { TakesurveysComponent } from './pages/takesurveys/takesurveys.component';
import { SubmitassignmentsComponent } from './pages/submitassignments/submitassignments.component';
import { JoinconferenceComponent } from './pages/joinconference/joinconference.component';
import { LearningmaterialComponent } from './pages/learningmaterial/learningmaterial.component';
import { PostassessmentComponent } from './pages/postassessment/postassessment.component';
import { MyresultsComponent } from './pages/myresults/myresults.component';
import { StartexamComponent } from './pages/startexam/startexam.component';
import { TimetrackerComponent } from './pages/timetracker/timetracker.component';
import { AssignmentsComponent } from './pages/assignments/assignments.component';
import { AddassignmentComponent } from './pages/addassignment/addassignment.component';
import { EvaluateassignmentsComponent } from './pages/evaluateassignments/evaluateassignments.component';
import { EvaluateassessmentComponent } from './pages/evaluateassessment/evaluateassessment.component';
import { ExamresultComponent } from './pages/examresult/examresult.component';
import { CourcesessionsComponent } from './pages/courcesessions/courcesessions.component';
import { CreatepolComponent } from './pages/createpol/createpol.component';
import { CreatesurveyComponent } from './pages/createsurvey/createsurvey.component';
import { AddpolComponent } from './pages/addpol/addpol.component';
import { AddsurveyComponent } from './pages/addsurvey/addsurvey.component';
import { AddsurveyquestionComponent } from './pages/addsurveyquestion/addsurveyquestion.component';
import { CreateFAQsComponent } from './pages/create-faqs/create-faqs.component';
import { FeedbackquestionaireComponent } from './pages/feedbackquestionaire/feedbackquestionaire.component'
import { FeedbackResultsComponent } from './pages/feedback-results/feedback-results.component'
import { SmtpComponent } from './pages/smtp/smtp.component';
import { TaskmanagerComponent } from './pages/taskmanager/taskmanager.component';
import { NewsComponent } from './pages/news/news.component';
import { CourceCategoryComponent } from './pages/cource-category/cource-category.component';
import { CourceTypeComponent } from './pages/cource-type/cource-type.component';
import { CourcesComponent } from './pages/cources/cources.component';
import { ChaptersComponent } from './pages/chapters/chapters.component';
import { EnrollComponent } from './pages/enroll/enroll.component';
import { RegulationsComponent } from './pages/regulations/regulations.component';
import { AttainmentlevelComponent } from './pages/attainmentlevel/attainmentlevel.component';
import { RoomsComponent } from './pages/rooms/rooms.component';
import { AssignMaterialsComponent } from './pages/assign-materials/assign-materials.component';
import { ContentManagerComponent } from './pages/content-manager/content-manager.component';
import { AssessmentQuestionnaireComponent } from './pages/assessment-questionnaire/assessment-questionnaire.component';
import { ScheduleAssessmentComponent } from './pages/schedule-assessment/schedule-assessment.component';
import { MasterAssessmentComponent } from './pages/master-assessment/master-assessment.component';
import { AttendanceComponent } from './pges/attendance/attendance.component';
import { CourceAssignTrainerComponent } from './pges/cource-assign-trainer/cource-assign-trainer.component';
import { CourceScheduleComponent } from './pges/cource-schedule/cource-schedule.component';
import { AddCourceScheduleComponent } from './pges/add-cource-schedule/add-cource-schedule.component';
import { CourceAssignUsersComponent } from './pges/cource-assign-users/cource-assign-users.component';
import { MailBoxComponent } from './pges/mail-box/mail-box.component';
import { ForumComponent } from './pges/forum/forum.component';
import { ForumTopicsComponent } from './pges/forum-topics/forum-topics.component';
import { AddAsseementQuestioniareComponent } from './pages/add-asseement-questioniare/add-asseement-questioniare.component';
import { AddMasterAssessmentComponent } from './pages/add-master-assessment/add-master-assessment.component';
import { CourseshAssignUsersComponent } from './pages/coursesh-assign-users/coursesh-assign-users.component';
import { CourseshAssigntrainersComponent } from './pages/coursesh-assigntrainers/coursesh-assigntrainers.component';
import { CourseProgramOutcomeComponent } from './pages/course-program-outcome/course-program-outcome.component';
import { RoleComponent } from './pages/role/role.component';
import { TaskInRolesComponent } from './pages/task-in-roles/task-in-roles.component';
import { MyPostsComponent } from './pages/my-posts/my-posts.component';
import { AllBlogsComponent } from './pages/all-blogs/all-blogs.component';
import { BlogsComponent } from './pages/blogs/blogs.component';
import { ViewPostComponent } from './pages/view-post/view-post.component';
import { Resolver } from './services/resolve.service';
import { UserRegistrationComponent } from './pages/user-registration/user-registration.component';
import { AddUserRegistrationComponent } from './pages/add-user-registration/add-user-registration.component';
import { EditUserRegistrationComponent } from './pages/edit-user-registration/edit-user-registration.component';
import { TenantRegistrationComponent } from './pages/tenant-registration/tenant-registration.component';
import { CreateTenantRegistrationComponent } from './pages/create-tenant-registration/create-tenant-registration.component';
import { ContentAuthoringComponent } from './pages/content-authoring/content-authoring.component';
import { BackupManagerComponent } from './pages/backup-manager/backup-manager.component';
import { OfflinePaymentComponent } from './pages/offline-payment/offline-payment.component';
import { BillingInformationComponent } from './pages/billing-information/billing-information.component';
import { SubjectComponent } from './pages/subject/subject.component';
import { PollResultsComponent } from './pages/poll-results/poll-results.component';
import { EventRequestComponent } from './pages/event-request/event-request.component';
import { DataDictionaryComponent } from './pages/data-dictionary/data-dictionary.component';
import { SubscriptionTaskComponent } from './pages/subscription-task/subscription-task.component';
import { LocationsComponent } from './pages/locations/locations.component';
import { DetailedAssessmentComponent } from './pages/reports/detailed-assessment/detailed-assessment.component';
import { StudentInformationComponent } from './pages/reports/student-information/student-information.component';
import { CourseTrainersDetailsComponent } from './pages/reports/course-trainers-details/course-trainers-details.component';
import { CourseInformationReportComponent } from './pages/reports/course-information-report/course-information-report.component';
import { OnlineExamreportComponent } from './pages/reports/online-examreport/online-examreport.component';
import { OnlineCoursesReportComponent } from './pages/reports/online-courses-report/online-courses-report.component';
import { OnlineCoursesDetailsReportComponent } from './pages/reports/online-courses-details-report/online-courses-details-report.component';
import { UserReportComponent } from './pages/reports/user-report/user-report.component';
import { CoursewiseperformanceReportComponent } from './pages/reports/coursewiseperformance-report/coursewiseperformance-report.component';
import { AssessmentResultComponent } from './pages/assessment-result/assessment-result.component';
import { AttendanceDetailsReportComponent } from './pages/reports/attendance-details-report/attendance-details-report.component';
import { TrainingCalendarComponent } from './pages/training-calendar/training-calendar.component';
import { UploadTemplateComponent } from './pages/upload-template/upload-template.component';
import { MainComponent } from './pages/iscribe/main/main.component';
import { StudentCgpaComponent } from './pages/reports/student-cgpa/student-cgpa.component'
import { StudentSGPAReportComponent } from './pages/reports/student-sgpa-report/student-sgpa-report.component'
import { VisualEditorComponent } from './pages/visual-editor/visual-editor.component';
import { FolderComponent } from './pages/folder/folder.component';
import { SemesterComponent } from './pages/semester/semester.component';
import { StudentAdmissionComponent } from './pages/student-admission/student-admission.component';
import { AddStudentAdmissionComponent } from './pages/add-student-admission/add-student-admission.component';
import { AcademicYearComponent } from './pages/academic-year/academic-year.component';
import { NewTeacherComponent } from './pages/new-teacher/new-teacher.component';
import { AddNewTeacherComponent } from './pages/add-new-teacher/add-new-teacher.component';
import { FeesTypeComponent } from './pages/fees-type/fees-type.component';
import { FeeReceivableComponent } from './pages/fee-receivable/fee-receivable.component';
import { FeeDescriptionComponent } from './pages/fee-description/fee-description.component';
import { EditFeesComponent } from './pages/edit-fees/edit-fees.component';
import { CurriculumComponent } from './pages/curriculum/curriculum.component';
import { StudentFeereceivableComponent } from './pages/student-feereceivable/student-feereceivable.component';
import { StudentRegistrationApprovalComponent } from './pages/student-registration-approval/student-registration-approval.component';
import { ContentResourcesComponent } from './pages/content-resources/content-resources.component';
import { ContentClassComponent } from './pages/content-class/content-class.component';
import { ContentChapterComponent } from './pages/content-chapter/content-chapter.component';
import { ContentSubjectComponent } from './pages/content-subject/content-subject.component';
import { ContentRepoAddComponent } from './pages/content-repo-add/content-repo-add.component';
import { ContentRepoComponent } from './pages/content-repo/content-repo.component';
import { BookAllocationReportComponent } from './pages/book-allocation-report/book-allocation-report.component';
import { BookAllocationViewReportComponent } from './pages/book-allocation-view-report/book-allocation-view-report.component';
import { StudentsReportComponent } from './pages/students-report/students-report.component';
import { FetchAssignedBooksComponent } from './pages/fetch-assigned-books/fetch-assigned-books.component';
import { ReturnDateComponent } from './pages/return-date/return-date.component';
import { UpdateReturnDateComponent } from './pages/update-return-date/update-return-date.component';
import { LibraryManagementSystemComponent } from './pages/library-management-system/library-management-system.component';
import { LibraryBooksAllocationComponent } from './pages/library-books-allocation/library-books-allocation.component';
import { ListOfBooksComponent } from './pages/list-of-books/list-of-books.component';
import { DepartmentComponent } from './pages/department/department.component';
import { HolidayNotificationComponent } from './pages/holiday-notification/holiday-notification.component';
import { ViewNewsComponent } from './pages/view-news/view-news.component';
import { ViewDiscussionComponent } from './pages/view-discussion/view-discussion.component';
import { SamvaadMeetingsComponent } from './pages/samvaad-meetings/samvaad-meetings.component';
import { CourseComponent } from './pages/course/course.component';
import { ViewCourseComponent } from './pages/view-course/view-course.component';
import { VehiclesComponent } from './pages/vehicles/vehicles.component';
import { BusMappingComponent } from './pages/bus-mapping/bus-mapping.component';
import { StudentConveyanceComponent } from './pages/student-conveyance/student-conveyance.component';
import { StaffConvenyanceComponent } from './pages/staff-convenyance/staff-convenyance.component';
import { HostelStudentsComponent } from './pages/hostel-students/hostel-students.component';
import { HostelVisitorsComponent } from './pages/hostel-visitors/hostel-visitors.component';
import { TimeTableComponent } from './pages/time-table/time-table.component';
import { ClassFeeComponent } from './pages/class-fee/class-fee.component';
import { RoutesComponent } from './pages/routes/routes.component';
import { AlertComponent } from './pages/alert/alert.component';
import { AddstopsComponent } from './pages/addstops/addstops.component';
import { TransportSlabsComponent } from './pages/transport-slabs/transport-slabs.component';
import { OutingDetailsComponent } from './pages/outing-details/outing-details.component';
import { ComplaintsComponent } from './pages/complaints/complaints.component';
import { EarlyPickupComponent } from './pages/early-pickup/early-pickup.component';
import { VisitorMonitoringComponent } from './pages/visitor-monitoring/visitor-monitoring.component';
import { DropComponent } from './pages/drop/drop.component';
import { FeeTypeComponent } from './pages/fee-type/fee-type.component';
import { ApprovalListComponent } from './pages/approval-list/approval-list.component'
import { StudentTripAttendanceComponent } from './pages/student-trip-attendance/student-trip-attendance.component'
import { VehicleTripAttendanceComponent } from './pages/vehicle-trip-attendance/vehicle-trip-attendance.component'
import { ReportComponent } from './pages/report/report.component';
import { BoardingReportComponent } from './pages/boarding-report/boarding-report.component'
import { VehicleInOutComponent } from './pages/vehicle-in-out/vehicle-in-out.component';
import { DriverHealthComponent } from './pages/driver-health/driver-health.component';
import { VendorsComponent } from './pages/vendors/vendors.component';
import { AssignedPeriodsComponent } from './pages/assigned-periods/assigned-periods.component';
import { DropoffStudentsComponent } from './pages/dropoff-students/dropoff-students.component';
import { DailyDiaryComponent } from './pages/daily-diary/daily-diary.component';
import { SpecificStudentDiaryComponent } from './pages/specific-student-diary/specific-student-diary.component';
import { NewsLetterComponent } from './pages/news-letter/news-letter.component';
import { RoomComponent } from './pages/room/room.component';
import { RoomTypesComponent } from './pages/room-types/room-types.component';
import { ItemsComponent } from './pages/items/items.component';
import { ItemsReportComponent } from './pages/items-report/items-report.component';
import { ReceviedQuotationListComponent } from './pages/recevied-quotation-list/recevied-quotation-list.component';
import { ClassItemSalesComponent } from './pages/class-item-sales/class-item-sales.component';
import { StudentPlacedOrderComponent } from './pages/student-placed-order/student-placed-order.component';
import { EmployeeSalesReportComponent } from './pages/employee-sales-report/employee-sales-report.component';
import { BuldingsComponent } from './pages/block/buldings/buldings.component';
import { CompusComponent } from './pages/compus/compus.component';
import { AddCompusComponent } from './pages/add-compus/add-compus.component';
import { QuotationsComponent } from './pages/quotations/quotations.component';
import { AddQuotationComponent } from './pages/add-quotation/add-quotation.component';
import { FloorsComponent } from './pages/floors/floors.component';
import { StudentSalesComponent } from './pages/student-sales/student-sales.component';
import { HostelItemsComponent } from './pages/hostel-items/hostel-items.component';
import { ConsumptionComponent } from './pages/consumption/consumption.component';
import { ReceviedQuotationComponent } from './pages/recevied-quotation/recevied-quotation.component';
import { CampusSalesComponent } from './pages/campus-sales/campus-sales.component';
import { AddTimeTableComponent } from './pages/add-time-table/add-time-table.component';
import { StaffAttendanceComponent } from './pages/staff-attendance/staff-attendance.component';
import { AddStaffAttendanceComponent } from './pages/add-staff-attendance/add-staff-attendance.component';
import { AttendanceReportEmsComponent } from './pages/attendance-report-ems/attendance-report-ems.component';
import { HostelRoomComponent } from './pages/hostel-room/hostel-room.component';
import { AddStudentAttendanceComponent } from './pages/add-student-attendance/add-student-attendance.component';
import { StudentAttendanceComponent } from './pages/student-attendance/student-attendance.component';
import { AssignDriverComponent } from './pages/assign-driver/assign-driver.component';
import { AddClientDetailsComponent } from './pages/add-client-details/add-client-details.component';
import { ClientsListComponent } from './pages/clients-list/clients-list.component';
import { EditTimeTableComponent } from './pages/edit-time-table/edit-time-table.component';
import { VendorPaymentsComponent } from './pages/vendor-payments/vendor-payments.component';
import { ReceiveItemsComponent } from './pages/receive-items/receive-items.component';
import { IssueItemsComponent } from './pages/issue-items/issue-items.component';
import { ReturnItemsComponent } from './pages/return-items/return-items.component';
import { TripTimingsComponent } from './pages/trip-timings/trip-timings.component';
import { VehicleServiceComponent } from './pages/vehicle-service/vehicle-service.component';
import { AddStudentDiaryComponent } from './pages/add-student-diary/add-student-diary.component';
import { ViewStudentDiaryComponent } from './pages/view-student-diary/view-student-diary.component';
import { EditTimeTableNewComponent } from './pages/time-table/edit-time-table-new/edit-time-table-new.component';
import { EmployeeSalesComponent } from './pages/employee-sales/employee-sales.component';
import { ClassItemComponent } from './pages/class-item/class-item.component';
import { StudentSalesReportsComponent } from './pages/student-sales-reports/student-sales-reports.component';
import { SuperAdminCreationComponent } from './pages/super-admin-creation/super-admin-creation.component';
import { CreateSuperAdminComponent } from './pages/create-super-admin/create-super-admin.component';
import { TenantPaymentsComponent } from './pages/tenant-payments/tenant-payments.component';
import{FineComponent} from './pages/fine/fine.component';
import { LeaveTypesComponent } from './pages/leave-types/leave-types.component';
import { LeaveAllotmentComponent } from './pages/leave-allotment/leave-allotment.component';
import { LeaveApplicationComponent } from './pages/leave-application/leave-application.component';
import { StudentLeavesComponent } from './pages/student-leaves/student-leaves.component';
import { ParentAlertComponent } from './pages/parent-alert/parent-alert.component';
import { SmsStatusComponent } from './pages/sms-status/sms-status.component';
import { EmailStatusComponent } from './pages/email-status/email-status.component';
import { CircularAlertComponent } from './pages/circular-alert/circular-alert.component';
import { StaffAttendanceReportComponent } from './pages/staff-attendance-report/staff-attendance-report.component';
import { StaffDailyAttendanceComponent } from './pages/staff-daily-attendance/staff-daily-attendance.component';
import { StudentAttendanceReportComponent } from './pages/student-attendance-report/student-attendance-report.component';
import { ClassesComponent } from './pages/classes/classes.component';
import { ExamMarksComponent } from './pages/exam-marks/exam-marks.component';
import { FeesComponent } from './pages/fees/fees.component';
import { HealthComponent } from './pages/health/health.component';
import { InstitutionsComponent } from './pages/institutions/institutions.component';
import { InventoryComponent } from './pages/inventory/inventory.component';
import { LeavesComponent } from './pages/leaves/leaves.component';
import { LibraryComponent } from './pages/library/library.component';
import { QuotationsReportComponent } from './pages/quotations-report/quotations-report.component';
import { StudentComponent } from './pages/student/student.component';
import { EmployeesComponent } from './pages/employees/employees.component';
import { CampusReportComponent } from './pages/campus-report/campus-report.component';
import { StaffComponent } from './pages/staff/staff.component';
import { StudentsComponent } from './pages/students/students.component';
import { TransportComponent } from './pages/transport/transport.component';
import { VendorPaymentsReportComponent } from './pages/vendor-payments-report/vendor-payments-report.component';
import { ClassListComponent } from './pages/class-list/class-list.component';
import { DefineTemplateComponent } from './pages/define-template/define-template.component';
import { GiveConcessionComponent } from './pages/give-concession/give-concession.component';
import { StaffIdcardHistorylistComponent } from './pages/staff-idcard-historylist/staff-idcard-historylist.component';
import { StudentIdcardHistroylistComponent } from './pages/student-idcard-histroylist/student-idcard-histroylist.component';
import { TemplatesListComponent } from './pages/templates-list/templates-list.component';
import { EmployeeIdcardComponent } from './pages/employee-idcard/employee-idcard.component';
import { ParentIdcardComponent } from './pages/parent-idcard/parent-idcard.component';
import { StudentIdcardComponent } from './pages/student-idcard/student-idcard.component';
import { PrintStudentIdcardComponent } from './pages/print-student-idcard/print-student-idcard.component';
import { PrintParentIdcardComponent } from './pages/print-parent-idcard/print-parent-idcard.component';
import { PrintEmployeeIdcardComponent } from './pages/print-employee-idcard/print-employee-idcard.component';
import { ItemCategoryComponent } from './pages/item-category/item-category.component';
import { ItemUnitComponent } from './pages/item-unit/item-unit.component';
import { DynamicLabelingComponent } from './pages/dynamic-labeling/dynamic-labeling.component';
import { BusFeeReceiptComponent } from './pages/bus-fee-receipt/bus-fee-receipt.component';
import { BonafiedCertificateComponent } from './pages/bonafied-certificate/bonafied-certificate.component';
import { FeeReceiptComponent } from './pages/fee-receipt/fee-receipt.component';
import { CharacterCertificateComponent } from './pages/character-certificate/character-certificate.component';
import { TransferCertificateComponent } from './pages/transfer-certificate/transfer-certificate.component';
import { AdditionalDetailsComponent } from './pages/additional-details/additional-details.component';
import { AssignPrivilegesComponent } from './pages/assign-privileges/assign-privileges.component';
import { CategoriesListComponent } from './pages/categories-list/categories-list.component';
import { EventsListComponent } from './pages/events-list/events-list.component';
import { AddEventComponent } from './pages/add-event/add-event.component';
import { EventRegistrationComponent } from './pages/event-registration/event-registration.component';
import { EventRegistrationsListComponent } from './pages/event-registrations-list/event-registrations-list.component';
import { EventParticipantsListComponent } from './pages/event-participants-list/event-participants-list.component';
import { AddEventParticipantsComponent } from './pages/add-event-participants/add-event-participants.component';
import { FaculityFeedbackComponent } from './pages/faculity-feedback/faculity-feedback.component';
import { HodFeedbackComponent } from './pages/hod-feedback/hod-feedback.component';
import { CollegeFeedbackListComponent } from './pages/college-feedback-list/college-feedback-list.component';
import { FacultyFeedbackListComponent } from './pages/faculty-feedback-list/faculty-feedback-list.component';
import { IdcardTemplate2Component } from './pages/idcard-template2/idcard-template2.component';
import { IdcardTemplate3Component } from './pages/idcard-template3/idcard-template3.component';
import { ParentIdcardTemplate2Component } from './pages/parent-idcard-template2/parent-idcard-template2.component';
import { ParentIdcardTemplate3Component } from './pages/parent-idcard-template3/parent-idcard-template3.component';
import { EmployeeIdcardTemplate3Component } from './pages/employee-idcard-template3/employee-idcard-template3.component';
import { EmployeeIdcardTemplate2Component } from './pages/employee-idcard-template2/employee-idcard-template2.component';
import { ContentLogComponent } from './pages/content-log/content-log.component';
import { ScheduleFeedbackComponent } from './pages/schedule-feedback/schedule-feedback.component';
import { CollegeFeedbackComponent } from './pages/college-feedback/college-feedback.component';
import { NitIdcardTemplateComponent } from './pages/nit-idcard-template/nit-idcard-template.component';
import { CopoAssessmentComponent } from './pages/copo-assessment/copo-assessment.component';
import { AssignMarksComponent } from './pages/assign-marks/assign-marks.component';
import { CollageFeedbackListComponent } from './pages/collage-feedback-list/collage-feedback-list.component';
import { QuestionsFeedbackListComponent } from './pages/questions-feedback-list/questions-feedback-list.component';
import { DynamicHeadingComponent } from './pages/dynamic-heading/dynamic-heading.component';
import { PromotionComponent } from './pages/promotion/promotion.component';
import { AddDynamicLabelsComponent } from './pages/add-dynamic-labels/add-dynamic-labels.component';
import { AddDynamicHeadingsComponent } from './pages/add-dynamic-headings/add-dynamic-headings.component';
import { CopoReportsComponent } from './pages/copo-reports/copo-reports.component';
import { EnquiryComponent } from './pages/enquiry/enquiry.component';
import { ConsolidatedMarkSheetComponent } from './pages/consolidated-mark-sheet/consolidated-mark-sheet.component';
import { CasteComponent } from './pages/caste/caste.component';
import { IssueInformationComponent } from './pages/issue-information/issue-information.component';
import { BookRequestComponent } from './pages/book-request/book-request.component';
import { StudentBookRequestComponent } from './pages/student-book-request/student-book-request.component';
import { BookMastersComponent } from './pages/book-masters/book-masters.component';
import { OverallReportComponent } from './pages/overall-report/overall-report.component';
import { SubChaptersComponent } from './pages/sub-chapters/sub-chapters.component';
import { BarcodeGenerateComponent } from './pages/barcode-generate/barcode-generate.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { CasteCategoryComponent } from './pages/caste-category/caste-category.component';
import { BookCategoryComponent } from './pages/book-category/book-category.component';
import { BookRackComponent } from './pages/book-rack/book-rack.component';
import { BookShelfComponent } from './pages/book-shelf/book-shelf.component';
import { StudentDiaryComponent } from './pages/student-diary/student-diary.component';
import { UpdateRackCellsComponent } from './pages/update-rack-cells/update-rack-cells.component';
import { BookIssueReturnReportComponent } from './pages/book-issue-return-report/book-issue-return-report.component';
const routes: Routes = [
  {
    path:'enquiry',
    component:EnquiryComponent
  },
  {
    path:"privacyPolicy",
    component: PrivacyPolicyComponent
  },
  {
    path: 'home',
    component: HomeComponent,

    children: [
      {
        path: 'dashboard',
        component: MaincontentComponent
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'poll/:id',
        component: PolsComponent,
      },
      {
        path: 'takepoll',
        component: TakepolsComponent
      },
      {
        path: 'surveys/:id',
        component: SurveysComponent
      },
      {
        path: 'takesurvey',
        component: TakesurveysComponent
      }, {
        path: 'submitassignment/:id',
        component: SubmitassignmentsComponent
      }, {
        path: 'joinconference/:id',
        component: JoinconferenceComponent
      }, {
        path: 'learningmaterial',
        component: LearningmaterialComponent
      }, {
        path: 'postassessment',
        component: PostassessmentComponent
      },
      {
        path: 'myresults/:id',
        component: MyresultsComponent
      },
      {
        path: 'takeExam',
        component: StartexamComponent
      },
      {
        path: 'timeTracker',
        component: TimetrackerComponent
      },
      {
        path: 'assignments/:id',
        component: AssignmentsComponent
      },
      {
        path: 'addAssignments',
        component: AddassignmentComponent
      },
      {
        path: 'evaluateAssignments/:id',
        component: EvaluateassignmentsComponent
      },
      {
        path: 'evaluateAssessment/:id',
        component: EvaluateassessmentComponent
      },
      {
        path: 'examResult/:id',
        component: ExamresultComponent
      },
      {
        path: "courseSessions",
        component: CourcesessionsComponent
      },
      {
        path: "createPolls/:id",
        component: CreatepolComponent
      },
      {
        path: "createSurvey/:id",
        component: CreatesurveyComponent
      },
      {
        path: 'addPoll',
        component: AddpolComponent
      },
      {
        path: 'addSurvey',
        component: AddsurveyComponent
      },
      {
        path: 'surveyQuestion',
        component: AddsurveyquestionComponent
      },
      {
        path: "createFAQs/:id",
        component: CreateFAQsComponent

      },
      {
        path: 'feedbackQuestionnaire',
        component: FeedbackquestionaireComponent
      },
      {
        path: 'feedbackResults',
        component: FeedbackResultsComponent
      },
      {
        path: 'smtp',
        component: SmtpComponent
      },
      {
        path: 'taskmanager/:id',
        component: TaskmanagerComponent
      },
      {
        path: 'news/:id',
        component: NewsComponent
      }, {
        path: 'courseCategory/:id',
        component: CourceCategoryComponent
      },
      {
        path: 'courseType/:id',
        component: CourceTypeComponent
      },
      {
        path: "courses/:id",
        component: CourcesComponent
      },
      {
        path: 'course-assignTrainer',
        component: CourceAssignTrainerComponent,
      },
      {
        path: 'course-programOutcome',
        component: CourseProgramOutcomeComponent
      },
      {
        path: 'chapters/:id',
        component: ChaptersComponent
      },
      {
        path: 'enroll/:id',
        component: EnrollComponent
      },
      {
        path: "regulations/:id",
        component: RegulationsComponent
      },
      {
        path: 'attainmentlevel/:id',
        component: AttainmentlevelComponent
      }, {
        path: 'rooms/:id',
        component: RoomsComponent
      }, {
        path: 'configurematerials/:id',
        component: AssignMaterialsComponent
      },
      {
        path: 'contentmanager/:id',
        component: ContentManagerComponent
      },
      {
        path: 'assessmentQuestionnaire/:id',
        component: AssessmentQuestionnaireComponent
      },
      {
        path: 'asssessemnt/:id',
        component: AddAsseementQuestioniareComponent
      },
      {
        path: 'scheduleAssessment/:id',
        component: ScheduleAssessmentComponent
      },
      {
        path: 'masterAssessment/:id',
        component: MasterAssessmentComponent
      },
      {
        path: 'addmasterAssessment',
        component: AddMasterAssessmentComponent
      },
      // {
      //   path: 'masterAssessment',
      //   component: AddMasterAssessmentComponent
      // },
      {
        path: 'attendance/:id',
        component: AttendanceComponent
      },

      {
        path: 'courseSchedule/:id',
        component: CourceScheduleComponent
      },
      {
        path: 'aeCourseSchedule/:id',
        component: AddCourceScheduleComponent
      },
      {
        path: 'courseSchedule-AssignUser',
        component: CourseshAssignUsersComponent
      },
      {
        path: 'courseSchedule-AssignTrainer',
        component: CourseshAssigntrainersComponent
      },
      {
        path: 'mailBox/:id',
        component: MailBoxComponent
      },
      {
        path: 'forums/:id',
        component: ForumComponent
      },
      {
        path: 'forumTopics/:id',
        component: ForumTopicsComponent
      }, {
        path: 'role/:id',
        component: RoleComponent
      },
      {
        path: 'taskInRole/:id',
        component: TaskInRolesComponent
      },
      {
        path: 'myPosts/:id',
        component: MyPostsComponent
      },
      {
        path: 'allBlogs/:id',
        component: AllBlogsComponent
      },
      {
        path: 'blogs/:id',
        component: BlogsComponent
      },
      {
        path: 'viewPost',
        component: ViewPostComponent
      }, {
        path: 'userRegistration/:id',
        component: UserRegistrationComponent
      },
      {
        path: 'addUserRegistration',
        component: AddUserRegistrationComponent
      },
      {
        path: 'editUserRegistration',
        component: EditUserRegistrationComponent
      },
      {
        path: 'tenantRegistration',
        component: TenantRegistrationComponent
      },
      {
        path: 'tenantRegistration/:id',
        component: CreateTenantRegistrationComponent
      },
      {
        path: 'contentAuthoring',
        component: ContentAuthoringComponent
      },
      {
        path: 'backupManager',
        component: BackupManagerComponent
      },
      {
        path: 'offlinePayment/:id',
        component: OfflinePaymentComponent
      },
      {
        path: 'billingInformation',
        component: BillingInformationComponent
      },
      {
        path: 'subject/:id',
        component: SubjectComponent
      },
      {
        path: 'pollResults',
        component: PollResultsComponent
      },
      {
        path: 'eventRequest/:id',
        component: EventRequestComponent
      },
      {
        path: 'meetings/:id',
        component: SamvaadMeetingsComponent
      },
      {
        path: 'dataDictionary/:id',
        component: DataDictionaryComponent
      },
      {
        path: 'subscriptionTasks',
        component: SubscriptionTaskComponent
      },
      {
        path: 'locations/:id',
        component: LocationsComponent
      },
      {
        path: 'DetailedAssessment',
        component: DetailedAssessmentComponent
      },
      {
        path: 'studentInformation',
        component: StudentInformationComponent
      },
      {
        path: 'courseTrainersReport',
        component: CourseTrainersDetailsComponent
      },
      {
        path: 'courseInformationReport',
        component: CourseInformationReportComponent
      },
      {
        path: 'onlineExamReport',
        component: OnlineExamreportComponent
      },
      {
        path: 'onlineCoursesReport',
        component: OnlineCoursesReportComponent
      },
      {
        path: 'onlineCoursesDeatilsReport',
        component: OnlineCoursesDetailsReportComponent
      },
      {
        path: 'userReport',
        component: UserReportComponent
      },
      {
        path: 'courseWisePerformance',
        component: CoursewiseperformanceReportComponent
      },
      {
        path: 'assessmentResult/:id',
        component: AssessmentResultComponent
      },
      {
        path: "attendanceReport",
        component: AttendanceDetailsReportComponent
      },
      {
        path: 'trainingCalendar',
        component: TrainingCalendarComponent
      },
      {
        path: 'uploadTemplate/:id',
        component: UploadTemplateComponent
      },
      {
        path: 'iscribe',
        component: MainComponent
      },
      {
        path: 'CGPA_Report/:id',
        component: StudentCgpaComponent
      },
      {
        path: 'SGPA_Report/:id',
        component: StudentSGPAReportComponent
      },
      {
        path: 'visualcontent/:id',
        component: VisualEditorComponent
      },
      {
        path: 'foldermanagemnet/:id',
        component: FolderComponent
      },
      {
        path: 'semester',
        component: SemesterComponent
      },
      {
        path: 'studentAdmission',
        component: StudentAdmissionComponent
      },
      {
        path: 'addstudentAdmission',
        component: AddStudentAdmissionComponent
      },
      {
        path: 'addNewTeacher',
        component: AddNewTeacherComponent
      },
      {
        path: 'addNewTeacher/:id',
        component: AddNewTeacherComponent
      },
      {
        path: 'NewTeacher',
        component: NewTeacherComponent
      },
      {

        path: 'feesType/:id',
        component: FeesTypeComponent
      },
      {
        path: 'feesDescription/:id',
        component: FeeDescriptionComponent
      },
      {
        path: 'editFees',
        component: EditFeesComponent
      },
      {
        path: 'editFees/:id',
        component: EditFeesComponent
      },
      {
        path: 'curriculum/:id',
        component: CurriculumComponent
      },
      {
        path: 'AcademicYear/:id',
        component: AcademicYearComponent
      },
      {
        path: 'feeReceivable/:id',
        component: FeeReceivableComponent
      },
      {
        path: 'studentFeeReceivable/:id',
        component: StudentFeereceivableComponent
      },
      {
        path: 'studentRegistrationApproval',
        component: StudentRegistrationApprovalComponent
      },

      {
        path: 'contentRepository',
        component: ContentRepoComponent
      },
      {
        path: 'contentRepository/:id',
        component: ContentRepoAddComponent
      },
      {
        path: 'contentSubject',
        component: ContentSubjectComponent
      },
      {
        path: "contentChapter",
        component: ContentChapterComponent
      },

      {
        path: "contentClass",
        component: ContentClassComponent
      },
      {
        path: 'contentResources',
        component: ContentResourcesComponent
      },
      {
        path: 'bookAllocation/:id',
        component: BookAllocationReportComponent
      },
      {
        path: 'viewReport',
        component: BookAllocationViewReportComponent
      },
      {
        path: 'assignedBooks',
        component: FetchAssignedBooksComponent
      },
      {
        path: 'studentReport',
        component: StudentsReportComponent
      },
      {
        path: "returnDate/:id",
        component: ReturnDateComponent
      },
      {
        path: 'updateReturnDate',
        component: UpdateReturnDateComponent
      },
      {
        path: 'libraryManagementSystem/:id',
        component: LibraryManagementSystemComponent
      },
      {
        path: 'libraryBooksAllocated/:id',
        component: LibraryBooksAllocationComponent
      },
      {
        path: 'listofbooks',
        component: ListOfBooksComponent

      },
      {
        path: 'department',
        component: DepartmentComponent
      },
      {
        path: 'holidayNotification/:id',
        component: HolidayNotificationComponent
      },
      {
        path: 'viewNews/:id',
        component: ViewNewsComponent
      },
      {
        path: 'viewNews',
        component: ViewNewsComponent
      },
      {
        path: 'viewDiscussion/:id',
        component: ViewDiscussionComponent
      },
      {
        path: 'viewDiscussion',
        component: ViewDiscussionComponent
      },
      {
        path: 'course',
        component: CourseComponent
      },
      {
        path: 'viewCourse',
        component: ViewCourseComponent
      },
      {
        path: 'vehicles/:id',
        component: VehiclesComponent
      },
      {
        path: 'bus-mapping/:id',
        component: BusMappingComponent
      },
      {
        path: 'student-conveyance/:id',
        component: StudentConveyanceComponent
      },
      {
        path: 'staff-convenyance/:id',
        component: StaffConvenyanceComponent
      },
      {
        path: 'hostel-students/:id',
        component: HostelStudentsComponent
      },
      {
        path: 'hostel-visitors/:id',
        component: HostelVisitorsComponent
      },
      {
        path: 'time-table/:id',
        component: TimeTableComponent
      },
      {
        path: 'classFee',
        component: ClassFeeComponent,
      },
      {
        path: 'routes/:id',
        component: RoutesComponent,
      },
      {
        path: 'alert',
        component: AlertComponent,
      },
      {
        path: 'addstops',
        component: AddstopsComponent,
      },
      {
        path: 'transportslabs/:id',
        component: TransportSlabsComponent,
      },
      {
        path: 'outingdetails/:id',
        component: OutingDetailsComponent,
      },
      {
        path: 'earlypickup/:id',
        component: EarlyPickupComponent,
      },
      {
        path: 'complaints/:id',
        component: ComplaintsComponent,
      },
      {
        path: 'drop/:id',
        component: DropComponent,
      },
      {
        path: 'feeType',
        component: FeeTypeComponent,
      },
      {
        path: 'visitormonitoring/:id',
        component: VisitorMonitoringComponent,
      },
      {
        path: 'approval-list/:id',
        component: ApprovalListComponent
      },
      {
        path: 'student-trip-attendance/:id',
        component: StudentTripAttendanceComponent
      },
      {
        path: 'vehicle-trip-attendance/:id',
        component: VehicleTripAttendanceComponent
      },
      {
        path: 'report/:id',
        component: ReportComponent
      },
      {
        path: 'boarding-report/:id',
        component: BoardingReportComponent
      },
      {
        path: 'vehicleinout/:id',
        component: VehicleInOutComponent
      },
      {
        path: 'driver-health/:id',
        component: DriverHealthComponent
      },
      {
        path: 'vendors/:id',
        component: VendorsComponent
      },
      {
        path: 'assignedperiods/:id',
        component: AssignedPeriodsComponent,
      },
      {
        path: 'dropoffstudents/:id',
        component: DropoffStudentsComponent,
      },
      {
        path: 'dailydiary/:id',
        component: DailyDiaryComponent,
      },
      {
        path: 'specificstudentdiary/:id',
        component: SpecificStudentDiaryComponent,
      },

      {
        path: 'newsletter',
        component: NewsLetterComponent,
      },
      {
        path: 'room/:id',
        component: RoomComponent
      },
      {
        path: 'roomTypes/:id',
        component: RoomTypesComponent
      },
      {
        path: 'items/:id',
        component: ItemsComponent
      },
      {
        path: 'itemReport',
        component: ItemsReportComponent
      },
      {
        path: 'block/:id',
        component: BuldingsComponent,
      },
      {
        path: 'floors/:id',
        component: FloorsComponent,
      },
      {
        path: 'campus/:id',
        component: CompusComponent,
      },
      {
        path: 'addCampus/:id',
        component: AddCompusComponent
      },
      {
        path: 'quotation/:id',
        component: QuotationsComponent
      },
      {
        path: 'Quotation',
        component: AddQuotationComponent
      },
      {
        path: 'receivedQuotationsList/:id',
        component: ReceviedQuotationListComponent
      },
      {
        path: 'classitemsales/:id',
        component: ClassItemSalesComponent
      },
      {
        path: 'studentPlacedOrder/:id',
        component: StudentPlacedOrderComponent
      },
      {
        path: 'employeeSalesReport/:id',
        component: EmployeeSalesReportComponent
      },
      {
        path: 'studentSales/:id',
        component: StudentSalesComponent
      },
      {
        path: 'hostelItems/:id',
        component: HostelItemsComponent
      },
      {
        path: 'consumption/:id',
        component: ConsumptionComponent
      },
      {
        path: 'receviedQuotation',
        component: ReceviedQuotationComponent
      }, {
        path: 'campusSales/:id',
        component: CampusSalesComponent
      },
      {
        path: 'addTimeTable/:id',
        component: AddTimeTableComponent
      },
      {
        path: 'staffAttendance/:id',
        component: StaffAttendanceComponent
      },
      {
        path: 'addStaffAttendance/:id',
        component: AddStaffAttendanceComponent
      },
      {
        path: 'attendancereports/:id',
        component: AttendanceReportEmsComponent
      },
      {
        path: 'hostelroom',
        component: HostelRoomComponent
      },
      {
        path: 'additionalDetails',
        component: AdditionalDetailsComponent
      },
      {
        path: 'studentAttendance/:id',
        component: StudentAttendanceComponent
      },
      {
        path: 'addStudentAttendance/:id',
        component: AddStudentAttendanceComponent
      },
      {
        path: 'assignDriver',
        component: AssignDriverComponent
      },
      {
        path: 'assignDriver/:id',
        component: AssignDriverComponent
      },
      {
        path: 'clientsList',
        component: ClientsListComponent
      },
      {
        path: 'addClientDetails',
        component: AddClientDetailsComponent
      },
      {
        path: 'editTimeTable',
        component: EditTimeTableNewComponent
      },
      {
        path: 'receiveItems',
        component: ReceiveItemsComponent
      },
      {
        path: 'vendorPayments',
        component: VendorPaymentsComponent
      },
      {
        path: 'issueItems',
        component: IssueItemsComponent
      },
      {
        path: 'issueItems/:id',
        component: IssueItemsComponent
      },
      {
        path: 'returnItems',
        component: ReturnItemsComponent
      },
      {
        path: 'returnItems/:id',
        component: ReturnItemsComponent
      },
      {
        path: 'tripTimings/:id',
        component: TripTimingsComponent
      },
      {
        path: 'vehicleService',
        component: VehicleServiceComponent
      },
      {
        path: 'studentDiary/:id',
        component: AddStudentDiaryComponent
      },
      {
        path: 'viewStudentDiary/:id',
        component: ViewStudentDiaryComponent
      },
      {
        path: 'classItem/:id',
        component: ClassItemComponent
      },
      {
        path: 'superAdminCreation/:id',
        component: SuperAdminCreationComponent
      },
      {
        path: 'createSuperAdmin/:id',
        component: CreateSuperAdminComponent
      }, 
      {
        path: 'clientPaymentList/:id',
        component: TenantPaymentsComponent
      },
      {
        path: 'fine/:id',
        component: FineComponent
      },
      {
        path: 'leaveTypes/:id',
        component: LeaveTypesComponent
      },
      {
        path: 'leaveAllotment/:id',
        component: LeaveAllotmentComponent
      },
      {
        path: 'leaveApplication/:id',
        component: LeaveApplicationComponent
      },
      {
        path: 'studentLeaves/:id',
        component: StudentLeavesComponent
      },
      {
        path:'parentAlert/:id',
        component:ParentAlertComponent

      },
      {
        path:'smsStatus/:id',
        component:SmsStatusComponent
      },
      {
        path:'emailStatus/:id',
        component:EmailStatusComponent

      },
      {
        path:'circularAlert/:id',
        component:CircularAlertComponent
      },
      {
        path:'staffAttendanceReport/:id',
        component:StaffAttendanceReportComponent
      },
      {
        path:'staffDailyAttendance/:id',
        component:StaffDailyAttendanceComponent
      },
      {
        path:'studentAttendanceReport/:id',
        component:StudentAttendanceReportComponent
      },
      {
        path:'classes/:id',
        component:ClassesComponent
      },
      {
        path:'examMarks/:id',
        component:ExamMarksComponent
      },
      {
        path:'fees/:id',
        component:FeesComponent
      },
      {
        path:'health',
        component:HealthComponent
      },
      {
        path:'institutions/:id',
        component:InstitutionsComponent
      },
      {
        path:'inventory/:id',
        component:InventoryComponent
      },
      {
        path:'leaves/:id',
        component:LeavesComponent
      },
      {
        path:'library/:id',
        component:LibraryComponent
      },
      {
        path:'quotationsReport/:id',
        component:QuotationsReportComponent
      },
      {
        path:'student/:id',
        component:StudentComponent
      },
      {
        path:'employees/:id',
        component:EmployeesComponent
      },
      {
        path:'campusReport',
        component:CampusReportComponent
      },
      {
        path:'staff/:id',
        component:StaffComponent
      },
      {
        path:'students/:id',
        component:StudentsComponent
      },
       {
        path:'transport/:id',
        component:TransportComponent
      }, 
      {
        path:'vendorPaymentsReport/:id',
        component:VendorPaymentsReportComponent
      },
      {
        path:'classList/:id',
        component:ClassListComponent
      },
      {
        path:'defineTemplate',
        component:DefineTemplateComponent
      },
      {
        path:'giveConcession/:id',
        component:GiveConcessionComponent
      },      
      {
        path: 'studentSalesReports/:id',
        component: StudentSalesReportsComponent
      },
      {
        path: 'employeeSales/:id',
        component: EmployeeSalesComponent
      }, 
      {
        path:'staffIdCardHistoryList/:id',
        component:StaffIdcardHistorylistComponent
      },
      {
        path:'studentIdcardHistoryList/:id',
        component:StudentIdcardHistroylistComponent
      },
      {
        path:'templatesList/:id',
        component:TemplatesListComponent
      },
      {
        path:'employeeIdCard/:id',
        component:EmployeeIdcardComponent
      },
      {
        path:'parentIdCard/:id',
        component:ParentIdcardComponent
      },
      {
        path:'studentIdCard/:id',
        component:StudentIdcardComponent
      },
      {
        path:'printStudentIdCard/:id',
        component:PrintStudentIdcardComponent
      },
      {
        path:'printParentIdCard/:id',
        component:PrintParentIdcardComponent
      },
      {
        path:'printEmployeeIdCard/:id',
        component:PrintEmployeeIdcardComponent
      },
      {
        path:'itemCategory/:id',
        component:ItemCategoryComponent
      },
      {
        path:'itemUnit/:id',
        component:ItemUnitComponent
      },
      {
        path:'labeling/:id',
        component:DynamicLabelingComponent
      },
      {
        path:'busfeeReceipt/:id',
        component:BusFeeReceiptComponent
      },
      {
        path:'feereceipt/:id',
        component:FeeReceiptComponent
      },
      {
        path:'characterCertificate/:id',
        component:CharacterCertificateComponent
      },
      {
        path:'transferCertificate/:id',
        component:TransferCertificateComponent
      },
      {
        path:'bonafiedCertificate/:id',
        component:BonafiedCertificateComponent
      },
      {
        path:'assignPrivileges/:id',
        component:AssignPrivilegesComponent
      },
      {
        path:'categories/:id',
        component:CategoriesListComponent
      },
      {
        path:'event/:id',
        component:EventsListComponent
      },
      {
        path:'addEvent',
        component:AddEventComponent
      },
      // {
      //   path:'addEvent/:id',
      //   component:AddEventComponent
      // },
      {
        path:'eventRegistration',
        component:EventRegistrationComponent
      },
      {
        path:'eventRegistration/:id',
        component:EventRegistrationComponent
      },
      {
        path:'eventRegistrationsList/:id',
        component:EventRegistrationsListComponent
      },
      {
        path:'eventParticipantsList/:id',
        component:EventParticipantsListComponent
      },
      {
        path:'addParticipants',
        component:AddEventParticipantsComponent
      },
      // {
      //   path:'collegeFeedback',
      //   component:TrainerFeedbackComponent
      // },
      {
        path:'collegeFeedback/:id',
        component:CollegeFeedbackComponent
      },
      {
        path:'facultyFeedback/:id',
        component:FaculityFeedbackComponent
      },
      {
        path:'hodFeedback',
        component:HodFeedbackComponent
      },
      {
        path:'collegeFeedbackList',
        component:CollegeFeedbackListComponent
      },
      {
        path:'facultyFeedbackList/:id',
        component:FacultyFeedbackListComponent
      },
      {
        path:'idcard2/:id',
        component:IdcardTemplate2Component
      },
      {
        path:'idcard3/:id',
        component:IdcardTemplate3Component
      },
      {
        path:'parentIdcard2/:id',
        component:ParentIdcardTemplate2Component
      },
      {
        path:'parentIdcard3/:id',
        component:ParentIdcardTemplate3Component
      },
      {
        path:'employeeIdcard2/:id',
        component:EmployeeIdcardTemplate3Component
      },
      {
        path:'employeeIdcard3/:id',
        component:EmployeeIdcardTemplate2Component
      },
      {
        path:'contentLog/:id',
        component:ContentLogComponent
      },
      {
        path:'scheduleFeedback/:id',
        component:ScheduleFeedbackComponent
      },
      {
        path:'nitIdCard/:id',
        component:NitIdcardTemplateComponent
      },
      {
        path:'assessmentCopo',
        component:CopoAssessmentComponent
      },
      {
        path: 'assessmentCopo/:id',
        component: CopoAssessmentComponent
      },
      {
        path:'assignMarks/:id',
        component:AssignMarksComponent
      },
      {
        path: 'collageFeedbackList/:id',
        component: CollageFeedbackListComponent
      },
      {
        path:'questionsFeedbackList',
        component:QuestionsFeedbackListComponent
      },
      {
        path:'dynamicHeading/:id',
        component:DynamicHeadingComponent
      },
      {
        path:'promotion/:id',
        component:PromotionComponent
      },
      {
        path:'addDynamicLabels',
        component:AddDynamicLabelsComponent
      },
      {
        path:'addDynamicHeadings',
        component:AddDynamicHeadingsComponent
      },
      {
        path:'copoReports/:id',
        component:CopoReportsComponent
      },    
      {
        path:'consolidatedMark/:id',
        component:ConsolidatedMarkSheetComponent
      }, 
      {
        path:'caste/:id',
        component:CasteComponent
      }, 
      {
        path:'issueInformation/:id',
        component:IssueInformationComponent
      },
      {
        path:'bookRequest/:id',
        component:BookRequestComponent
      },
      {
        path:'studentBookRequest/:id',
        component:StudentBookRequestComponent
      },
      {
        path:'bookMasters',
        component:BookMastersComponent
      },
      {
        path:'overallReport/:id',
        component:OverallReportComponent
      },
      {
        path:'subChapters/:id',
        component:SubChaptersComponent
      },
      {
        path:'barCode',
        component:BarcodeGenerateComponent
      },
      {
        path:'casteCategory/:id',
        component:CasteCategoryComponent
      },
      {
        path:'bookCategory/:id',
        component:BookCategoryComponent
      },  {
        path:'bookRack/:id',
        component:BookRackComponent
      },
      {
        path:'bookShelf/:id',
        component:BookShelfComponent
      },
      {
        path:'studentDiarys/:id',
        component:StudentDiaryComponent
      },
      {
        path:'transferBooks/:id',
        component:UpdateRackCellsComponent
      },
      {
        path:'bookReport/:id',
        component:BookIssueReturnReportComponent
      }

    ]     
    ,
 
 
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'Home/dashboard',
    redirectTo: 'home',
  },
  {
    path: '**',
    redirectTo: 'home'
  },
  
 

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

// resolve:{menu:Resolver},
