import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-quotations',
  templateUrl: './quotations.component.html',
  styleUrls: ['./quotations.component.css']
})
export class QuotationsComponent implements OnInit {
  myForm: FormGroup
  table: Array<any> = [];
  dropdownListVendors: any[] = [];
  dropdownSettings: any = {};
  isEdit: boolean = false;
  isData: boolean = true;
  isRecived: boolean = false;
  isSend: boolean = false;
  qId: any;
  qrId: any;
  selectedVendors: { vendor_id: number; vendor_name: string; }[];
  editData: any;
  vid: any;
  labelName: any = {};
  headingName: any = {};
  workItemId: any;

  constructor(private fb: FormBuilder, private active: ActivatedRoute, private route: Router, private CommonService: CommonService, private toastr: ToastrService) {
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
    this.loadQuotations();
    this.loadlabels();
    this.loadHeadings();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      "vendor_id": ['']
    })

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  loadQuotations() {
    this.CommonService.postCall('Quotation/GetList', { "tnt_code": localStorage.getItem('TenantCode') }).subscribe((res: any) => {
      this.table = res;
    })
  }






  // getVendors(e: any) {
  //   this.dropdownListVendors = [];
  //   this.qId = e;
  //   this.CommonService.postCall('Vendor/VendorDropDownList', {}).subscribe((res: any) => {
  //     this.isData = false;
  //     this.vid = res.vendor_id;
  //     res.map((item: { vendor_id: any; vendor_name: any; }) => {
  //       let obj = {
  //         item_id: item.vendor_id,
  //         item_text: item.vendor_name
  //       }
  //       this.dropdownListVendors.push(obj);
  //     })
  //     setTimeout(() => { this.isData = true }, 10)
  //     if (this.isEdit) this.setSelectedVendor()
  //   }, e => {
  //   })
  // }

  // setSelectedVendor() {
  //   let value: string = this.editData[this.vid ]
  //   let list: any = []
  //   value?.split(',').map((id: any) => {
  //     let index = this.dropdownListVendors.findIndex(data => data.item_id == id);

  //     if (index > -1) {
  //       list.push(this.dropdownListVendors[index])
  //     }
  //   })
  //   this.selectedVendors = list;
  // }


  getVendors(e: any) {
    this.dropdownListVendors = [];
    this.qId = e;
    this.CommonService.postCall('Vendor/VendorDropDownList', { "tnt_code": localStorage.getItem('TenantCode') }).subscribe((res: any) => {
      this.isData = false;
      this.vid = res.vendor_id;
      res.map((item: { vendor_id: any; vendor_name: any; }) => {
        let obj = {
          item_id: item.vendor_id,
          item_text: item.vendor_name
        }
        this.dropdownListVendors.push(obj);
      })
    })
  }

  // edit(item : any){
  //   let payload={
  //     cId : item.campus_id
  //    }
  //    this.route.navigate(['home/addCampus/edit'],{queryParams:payload});
  // }


  reciQuto(quotId: any, qrId: any) {
    this.route.navigate(['home/receviedQuotation'], { queryParams: { qId: quotId, wId: this.workItemId } });
    // let payload = {
    //   "quotation_id": quotId
    // }
    // this.CommonService.postCall('Quotation/Get', payload).subscribe((res: any) => {

    // })
  }


  add() {
    this.route.navigate(['home/Quotation'],{ queryParams: { wId: this.workItemId }});
  }
  editQuotation(qReceivedId,) {
    this.route.navigate(['home/receviedQuotation'], { queryParams: { qrId: qReceivedId, wId: this.workItemId } });

  }
  onSelectOne(e: any) { }
  onSelectAll(e: any) { }

  onSubmit(myForm: any) {
    let value: any = myForm.value;
    let vendor: string | any;

    this.dropdownListVendors.map((item: { item_id: any; }) => {
      let id = item.item_id;
      if (vendor) {
        vendor = vendor + ',' + id
      } else {
        vendor = id
      }
    });




    let payload = {
      "quotation_id": this.qId,
      "vendors": vendor.toString(),
    }
    console.log(payload)
    this.CommonService.postCall('Quotation/UpdateVendorsQuotation', payload).subscribe((res: any) => {
      this.toastr.success("Quotation Updated Successfully");
      this.loadQuotations();
      document.getElementById('md_close').click();
    }, err => {
      this.toastr.error(err.error ? err.error : 'Quotation Not Updated')

    })

  }

  close() {
    this.isEdit = null;
    this.myForm.reset();
  }
}
