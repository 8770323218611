import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { FileuploadService } from 'src/app/services/fileupload.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-evaluateassignments',
  templateUrl: './evaluateassignments.component.html',
  styleUrls: ['./evaluateassignments.component.css']
})
export class EvaluateassignmentsComponent implements OnInit {

  cources: [] = [];
  courceId: string = ''
  schedulId: string | number = '';
  scheduls: [] = []
  table: any = [];
  assignmentId: string = '';
  assignments: Array<any> = []
  fileName: string;
  file: File;
  url = environment.serviceUrl;
  labelName: any = {};
  headingName: any = {};
  workItemId: any;
  constructor(private active : ActivatedRoute,private CommonService: CommonService, private toastr: ToastrService, private FileuploadService: FileuploadService) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    if (location.hostname != "localhost")
      this.url = `${location.origin}/api/`;
    this.loadlabels();
    this.getCourses();
    this.loadHeadings();
  }
  ngOnInit(): void {
  }
  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  getCourses() {
    this.activeSpinner()
    this.CommonService.getAdminCourses().subscribe((res: any) => {
      this.deactivateSpinner();
      this.cources = res
    }, e => { this.deactivateSpinner(); })
  }

  courceChange() {
    this.activeSpinner();
    let data = {
      "CourseId": this.courceId
    }
    this.CommonService.getAdminCourseSchedule(data).subscribe((res: any) => {
      this.deactivateSpinner();
      this.scheduls = res;
    }, e => { this.deactivateSpinner(); })
  }
  schedulChange() {
    this.activeSpinner();
    this.CommonService.getAssignmentsDropDown(this.schedulId).subscribe((res: any) => {
      this.deactivateSpinner();
      this.assignments = res;
    }, err => { this.deactivateSpinner(); })
  }
  assignmentChange() {
    this.activeSpinner()
    let data = {
      CourseScheduleId: this.schedulId,
      AssessmentId: this.assignmentId
    }
    this.CommonService.loadEvaluateAssignments(data).subscribe((res: any) => {
      this.deactivateSpinner();
      this.table = res;
    }, err => { this.deactivateSpinner(); })
  }
  evaluate(data) {
    let emarks = data.EV_STUDENT_MARKS;
    let maxMarks = data.ASSIGNMENT_MAX_MARKS;
    if (!emarks) {
      // alert("please enter the marks")
      this.toastr.warning('please enter the marks');
      return
    }
    if (emarks > maxMarks) {
      this.toastr.warning("Marks not more than Max Marks")
      // alert("Marks not more than Max Marks")
      return
    }
    let param = {
      EvaluateID: data.EV_ASSIGNMENT_ID || 0,
      Marks: emarks,
      AssessmentId: data.ASSIGNMENT_ID,
      CourseScheduleId: this.schedulId
    }
    this.activeSpinner();
    this.CommonService.setEvaluateAssignments(param).subscribe((res) => {
      //  alert('Information updated successfully.');
      this.deactivateSpinner();
      this.toastr.success('Information updated successfully.');
      this.assignmentChange()
    }, err => { this.deactivateSpinner(); })

  }
  selectFile() {
    document.getElementById('file_upload').click();
  }
  upload(data) {
    this.fileName = null;
    const formData = new FormData();
    formData.append('Yes', this.file);
    formData.append('ClientDocs', 'ClientDocs');
    formData.append('Course', this.courceId);
    formData.append('EV_AssignmentId', data.EV_ASSIGNMENT_ID)
    this.activeSpinner();
    this.FileuploadService.upload(formData, 'Assignments/UploadEvaluateAssignment').subscribe((res: any) => {
      // this.file = null

      this.fileName = res.path;
      if (this.fileName) {
        data.UploadFilePath = res.path;
        this.deactivateSpinner()
      }
    }, err => { this.deactivateSpinner(); })
  }
  change(event, data) {
    if (event.target.files.length > 0) {
      let file: File = event.target.files[0];
      let name = file.name;
      let filetype = name.split('.').pop()
      let types: Array<any> = ['doc', 'docx', 'pdf']
      let check = types.includes(filetype);
      if (check) {
        this.file = file;
        this.upload(data)
      }
      else {
        // alert(' Please upload pdf and doc file formats only.')
        this.toastr.warning('Please upload pdf and doc file formats only.')
        event.target.value = ''
      }
    }
  }
  download(link: string) {
    //let url = environment.serviceUrl;
    link = link.substr(18);
    window.open(this.url + 'Assignments/SubmitAssignmentDownload' + link, '_blank')
  }
}
