<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.AddTimeTable}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="custom_container">
                                            <form class="well form-horizontal" [formGroup]="myForm"
                                                (ngSubmit)="onSubmit(myForm)">
                                                <fieldset>
                                                    <div class="form-group row">
                                                        <label class="col-md-4 control-label ">{{labelName.Campus}}
                                                            <span class="text-danger">*</span></label>
                                                        <div class="col-md-8 inputGroupContainer">
                                                            <div class="input-group"> <strong>:</strong> &nbsp;
                                                                <select
                                                                    class="form-select form-select-sm shadow-sm rounded-lg"
                                                                    formControlName="campus_id">
                                                                    <option value="" selected>Select</option>
                                                                    <option *ngFor="let item of campusDropdown"
                                                                        [value]="item.campus_id">
                                                                        {{item.campus_name}}</option>
                                                                </select>
                                                            </div>
                                                            <!-- <div *ngIf="myForm.get('campus').touched">
                                                            <span class="text-danger" *ngIf="myForm.get('campus').hasError('required')">
                                                                This field is required.
                                                            </span>
                                                        </div> -->
                                                        </div>
                                                    </div>
                                                    <div class="form-group row pt-4">
                                                        <label class="col-md-4 control-label line_2 rem_1">
                                                            {{labelName.course}} <span
                                                                class="text-danger">*</span></label>
                                                        <div class="col-md-8 inputGroupContainer">
                                                            <div class="input-group">
                                                                <strong>:</strong> &nbsp;
                                                                <select formControlName="course_id"
                                                                    [(ngModel)]='courceId '
                                                                    (change)="courceChange($any($event).target.value)"
                                                                    class="form-select form-select-sm shadow-sm rounded-lg">
                                                                    <option value="" selected>Select</option>
                                                                    <option *ngFor="let course of cources"
                                                                        [value]='course.COURSE_ID'>
                                                                        {{course.COURSE_NAME}}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                            <div *ngIf="myForm.get('course_id').touched">
                                                                <span class="text-danger"
                                                                    *ngIf="myForm.get('course_id').hasError('required')">
                                                                    This field is required.
                                                                </span>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- <div class="form-group row pt-4">
                                                        <label class="col-md-4 control-label line_2 rem_1">
                                                            {{labelName.CourseSchedule}}   <span class="text-danger">*</span></label>
                                                        <div class="col-md-8 inputGroupContainer">
                                                            <div class="input-group">
                                                                <strong>:</strong> &nbsp;
                                                                <select formControlName="courseshd_id"
                                                                    class="form-control form-control-sm shadow-sm rounded-lg"
                                                                    (change)="getSubjectsbyCourse($any($event).target.value)">
                                                                    <option value="" selected>Select</option>
                                                                    <option *ngFor="let schedul of scheduls"
                                                                        [value]='schedul.COURSESHD_ID'>
                                                                        {{schedul.COURSESHD_NAME}}</option>
                                                                </select>
                                                            </div>
                                                            <div *ngIf="myForm.get('courseshd_id').touched">
                                                                <span class="text-danger"
                                                                    *ngIf="myForm.get('courseshd_id').hasError('required')">
                                                                    This field is required.
                                                                </span>

                                                            </div>
                                                        </div>
                                                    </div> -->

                                                    <div class="form-group row pt-4">
                                                        <label class="col-md-4 control-label line_2 rem_1">
                                                            {{labelName.AcademicYear}} <span
                                                                class="text-danger">*</span></label>
                                                        <div class="col-md-8 inputGroupContainer">
                                                            <div class="input-group">
                                                                <strong>:</strong> &nbsp;
                                                                <select formControlName="academic_year_id"
                                                                    class="form-select form-select-sm shadow-sm rounded-lg">
                                                                    <option value="" selected>Select</option>
                                                                    <option
                                                                        *ngFor="let academic of academicYearDropdown"
                                                                        [value]='academic.ACADEMIC_ID'>
                                                                        {{academic.ACADEMIC_NAME}}</option>
                                                                </select>
                                                            </div>
                                                            <div *ngIf="myForm.get('academic_year_id').touched">
                                                                <span class="text-danger"
                                                                    *ngIf="myForm.get('academic_year_id').hasError('required')">
                                                                    This field is required.
                                                                </span>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row pt-4">
                                                        <label class="col-md-4 control-label line_2 rem_1">
                                                            {{labelName.CourseYear}} <span
                                                                class="text-danger">*</span></label>
                                                        <div class="col-md-8 inputGroupContainer">
                                                            <div class="input-group">
                                                                <strong>:</strong> &nbsp;
                                                                <select formControlName="course_year"
                                                                    class="form-select form-select-sm shadow-sm rounded-lg">
                                                                    <option value="" selected>Select</option>
                                                                    <option *ngFor="let course of courseYearDropdown"
                                                                        [value]='course.DICTIONARYID'>
                                                                        {{course.DICTIONARYNAME}}</option>
                                                                </select>
                                                            </div>
                                                            <div *ngIf="myForm.get('course_year').touched">
                                                                <span class="text-danger"
                                                                    *ngIf="myForm.get('course_year').hasError('required')">
                                                                    This field is required.
                                                                </span>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row pt-4">
                                                        <label class="col-md-4 control-label line_2 rem_1">
                                                            {{labelName.Semester}} <span
                                                                class="text-danger">*</span></label>
                                                        <div class="col-md-8 inputGroupContainer">
                                                            <div class="input-group">
                                                                <strong>:</strong> &nbsp;
                                                                <select formControlName="semester_id"
                                                                    class="form-select form-select-sm shadow-sm rounded-lg">
                                                                    <option value="" selected>Select</option>
                                                                    <option *ngFor="let semester of semesterDropdown"
                                                                        [value]='semester.DICTIONARYID'>
                                                                        {{semester.DICTIONARYNAME}}</option>
                                                                </select>
                                                            </div>
                                                            <div *ngIf="myForm.get('semester_id').touched">
                                                                <span class="text-danger"
                                                                    *ngIf="myForm.get('semester_id').hasError('required')">
                                                                    This field is required.
                                                                </span>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row pt-4">
                                                        <label class="col-md-4 control-label line_2 rem_1">
                                                            {{labelName.Sectio}} <span
                                                                class="text-danger">*</span></label>
                                                        <div class="col-md-8 inputGroupContainer">
                                                            <div class="input-group">
                                                                <strong>:</strong> &nbsp;
                                                                <select formControlName="section_id"
                                                                    class="form-select form-select-sm shadow-sm rounded-lg">
                                                                    <option value="" selected>Select</option>
                                                                    <option *ngFor="let section of sectionDropdown"
                                                                        [value]='section.DICTIONARYID'>
                                                                        {{section.DICTIONARYNAME}}</option>
                                                                </select>
                                                            </div>
                                                            <div *ngIf="myForm.get('section_id').touched">
                                                                <span class="text-danger"
                                                                    *ngIf="myForm.get('section_id').hasError('required')">
                                                                    This field is required.
                                                                </span>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row pt-4">
                                                        <label class="col-md-4 control-label line_2 rem_1">
                                                            {{labelName.Periods}} <span
                                                                class="text-danger">*</span></label>
                                                        <div class="col-md-8 inputGroupContainer">
                                                            <div class="input-group">
                                                                <strong>:</strong> &nbsp;
                                                                <select formControlName='period'
                                                                    (change)="periodChange($event.target.value)"
                                                                    class="form-select form-select-sm shadow-sm rounded-lg">
                                                                    <option value="" [selected]="true">Select</option>
                                                                    <option *ngFor="let item of preriodDropdown"
                                                                        [value]="item">{{item}}</option>

                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row pt-4">
                                                        <label class="col-md-4 control-label line_2 rem_1">
                                                            {{labelName.PeriodsDuration}} <span class="text-danger"> *
                                                            </span></label>
                                                        <div class="col-md-8 inputGroupContainer">
                                                            <div class="input-group">
                                                                <strong>:</strong> &nbsp;
                                                                <select formControlName='period_duration'
                                                                    class="form-select form-select-sm shadow-sm rounded-lg">
                                                                    <option value="" selected>Select</option>
                                                                    <option *ngFor="let item of preriodDurationDropdown"
                                                                        [value]="item">{{item}}
                                                                    </option>

                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div *ngIf="viewTable" class="table-responsive mt-5"
                                                        formArrayName="periods">
                                                        <table id="patergraph" datatable
                                                            class=" table-striped table-bordered mt-2 pt-2 table-sm small display">
                                                            <thead class="top"
                                                                style="background-color: #624FD7; color:#ffffff;">
                                                                <tr>
                                                                    <th>Periods</th>
                                                                    <th>Monday</th>
                                                                    <th>Tuesday </th>
                                                                    <th>Wednesday</th>
                                                                    <th>Thursday</th>
                                                                    <th>Friday</th>
                                                                    <th>Saturday</th>
                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                <tr *ngFor="let item of myForm.controls.periods.controls;let i=index; trackBy:trackByFn"
                                                                    [formGroupName]="i">
                                                                    <td>
                                                                        <label for="">Start Time :</label>
                                                                        <input type="time"
                                                                            formControlName="period_start_time">
                                                                        <br>
                                                                        <label for="">End Time &nbsp; :</label>
                                                                        <input type="time"
                                                                            formControlName="period_end_time">
                                                                    </td>
                                                                    <td>
                                                                        <input readonly (click)="getIndex(i,'mon')"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#Aasign"
                                                                            formControlName="1" />
                                                                        <!-- <a  data-bs-toggle="modal"
                                                                            data-bs-target="#Aasign">
                                                                          
                                                                            <span formControlName="Monday"></span><br>
                                                                            
                                                                        </a> -->
                                                                    </td>
                                                                    <td>
                                                                        <input readonly (click)="getIndex(i,'tue')"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#Aasign"
                                                                            formControlName="2" />
                                                                        <!-- <a *ngIf="!Tuesday"
                                                                            (click)="getIndex(i,'tuesday')"
                                                                            href="javascript:;" data-bs-toggle="modal"
                                                                            data-bs-target="#Aasign">Assign</a>
                                                                        <a *ngIf="Tuesday" href="javascript:;"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#Aasign">
                                                                            <span>{{getTecher(i,'tuesday')}}</span><br>
                                                                            <span>{{getSubject(i,'tuesday')}}</span>
                                                                        </a> -->
                                                                    </td>
                                                                    <td>
                                                                        <input readonly (click)="getIndex(i,'wed')"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#Aasign"
                                                                            formControlName="3" />
                                                                    </td>
                                                                    <td>
                                                                        <input readonly (click)="getIndex(i,'thu')"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#Aasign"
                                                                            formControlName="4" />
                                                                    </td>
                                                                    <td>
                                                                        <input readonly (click)="getIndex(i,'fri')"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#Aasign"
                                                                            formControlName="5" />
                                                                    </td>
                                                                    <td>
                                                                        <input readonly (click)="getIndex(i,'sat')"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#Aasign"
                                                                            formControlName="6" />
                                                                    </td>

                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </fieldset>
                                            </form>
                                            <div class="text-center pt-4">
                                                <button type="submit" [disabled]="myForm.invalid"
                                                    (click)="onSubmit(myForm)"
                                                    class="btn btn-sm  btn-save">Save</button>

                                                &nbsp;&nbsp;
                                                <button type="button" class="btn btn-sm btn-danger" (click)="close()"
                                                    id="close">cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




<div class="modal fade" id="Aasign" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header py-2">
                <h6 class="modal-title w-100">{{headingName.AssignSubject}}</h6>
                <!-- <h6 *ngIf="isEdit" class="modal-title w-100"> Edit Visitor</h6> -->
                <button type="button" (click)="close1()" class="close" data-bs-dismiss="modal" aria-bs-hidden="true"><i
                        class="fas fa-times"></i></button>
            </div>


            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForms" (ngSubmit)="Submit(myForms,currentIndex)">
                    <fieldset>


                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName.Teachers}}  <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName="teacher_id"
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option *ngFor='let item of trainer' [value]="item.userid">
                                            {{item.trainer_name}}
                                        </option>
                                    </select>
                                </div>
                                <!-- <div *ngIf="myForm.get('teacher_id').touched">
                                    <span class="text-danger" *ngIf="myForm.get('teacher_id').hasError('required')">
                                        This field is required.
                                    </span>

                                </div> -->
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName.Subject}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName='subject'
                                        class="form-control form-control-sm shadow-sm rounded-lg ">
                                        <option value="" selected>select</option>
                                        <option *ngFor="let item of subjects" [value]='item.subject_id'>
                                            {{item.subject_name}}
                                        </option>
                                    </select>
                                </div>
                                <!-- <div *ngIf="myForm.get('subject').touched">
                                    <span class="text-danger" *ngIf="myForm.get('subject').hasError('required')">
                                        This field is required.
                                    </span>

                                </div> -->
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName.Rooms}}  <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName="room_id"
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option *ngFor='let item of rooms' [value]="item.room_id">
                                            {{item.room_number}}
                                        </option>
                                    </select>
                                </div>
                                <!-- <div *ngIf="myForm.get('teacher_id').touched">
                                    <span class="text-danger" *ngIf="myForm.get('teacher_id').hasError('required')">
                                        This field is required.
                                    </span>

                                </div> -->
                            </div>
                        </div>

                    </fieldset>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-save btn-sm" (click)="Submit(myForms,currentIndex)"
                    [disabled]="myForms.invalid">Save</button>

                <button type="button" class="btn btn-danger btn-sm" id='md_close' (click)="close1()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>