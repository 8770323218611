<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0"> {{headingName.IssueInformation}} </h5>
                                    </div>
                                    <div class="card body">
                                        <div class="table">
                                            <fieldset>
                                                <div *ngIf="table.length">
                                                    <table id="patreggraph" datatable
                                                        class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                        style="width:100%">
                                                        <thead class="top"
                                                            style="background-color: #624FD7; color:#ffffff;">
                                                            <tr>
                                                                <th>Book Name </th>
                                                                <th>Issue Date</th>
                                                                <th>Due Date</th>
                                                                <th>Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let item of table">
                                                                <td>{{item.book_name}}</td>
                                                                <td>{{item.book_std_date_of_issue | date:'dd/MM/yyyy'}}</td>
                                                                <td>{{item.due_date | date:'dd/MM/yyyy'}}</td>
                                                                <td>{{item.book_status}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                </div>
                                                <div class="text-center" *ngIf="!table.length">
                                                    No Records to display
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>