import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { BaseComponent } from 'src/app/pages/base.component';
@Component({
  selector: 'app-class-list',
  templateUrl: './class-list.component.html',
  styleUrls: ['./class-list.component.css']
})
export class ClassListComponent extends BaseComponent implements OnInit {
  table: Array<any> = [];
  isEdit: boolean = false;
  myForm!: FormGroup;
  editData: any;
  courceId: string = '';
  courses: Array<any> = [];
  academics: Array<any> = [];
  shedules: Array<any> = [];
  courseObjectives: any;
  feeTypes: Array<any> = [];
  feeModeType: any;
  tempArray = [];
  flag: boolean;
  termList: Array<any> = [];
  payments: boolean = false;
  payments1: boolean = false;
  payments3: any;
  feeTypeMode: number;
  labelName: any = {};
  headingName: any = {};

  constructor(private fb: FormBuilder,  CommonService: CommonService,  toastr: ToastrService) {
    super(CommonService, toastr);
    this.GetList();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      CURRICULUM_NAME: ['', Validators.required],
      CURRICULUM_COURSE_ID: ['', Validators.required],
      CURRICULUM_NO_OF_PAYMENTS: ['', [Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      CURRICULUM_ACADEMIC_ID: ['', Validators.required],
      fee_type: [''],
      amount: ['', [Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      CURRICULUM_DESCRIPTION: ['', [Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      CURRICULUM_STATUS: [1, Validators.required,],

    })
    this.loadlabels();
    this.getCourses();
    this.getAcademic();
    this.feeTypeDropdown();
    this.loadHeadings();

  }
  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/4219/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }


  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/4219/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  GetList() {
    this.activeSpinner()
    let payLoad = { "TNT_CODE": this.tId || localStorage.getItem('TenantCode') }
    this.CommonService.postCall('Curriculum/GetList', payLoad).subscribe((res: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = res;
      }, 10)
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner(), console.log(e) })
  }
  getCourses() {
    this.activeSpinner()
    this.CommonService.getAdminCourses(this.tId == '' ? undefined : this.tId).subscribe((res: any) => {
      this.courses = res;
      this.deactivateSpinner()
    }, e => {
      this.deactivateSpinner()
    })
  }
  courseChange() {
    let payload = {
      COURSE_OBJECTIVE_COURSE_ID: this.courceId
    };
    let data = {
      "CourseId": this.courceId
    }
    this.activeSpinner()
    this.CommonService.getAdminCourseSchedule(data).subscribe((res: any) => {
      this.deactivateSpinner()
      this.shedules = res;
    }, e => { this.deactivateSpinner() });
    this.CommonService.postCall('CourseObjectiveByCourseId', payload).subscribe((res: any) => {
      this.courseObjectives = res;
    }, err => {

    });
    this.GetList()
  }
  getAcademic() {
    this.activeSpinner()
    this.CommonService.getCall('Academic/GetAcademicDropDownList/' + localStorage.getItem('TenantCode')).subscribe((res: any) => {
      this.academics = res;
      this.deactivateSpinner()
    }, e => {
      this.deactivateSpinner()
    })
  }
  feeTypeDropdown() {

    let payLoad = { "TNT_CODE": localStorage.getItem('TenantCode') }
    this.CommonService.postCall('Feestype/FeeTypeDropdown', payLoad).subscribe((res: any) => {
      this.feeTypes = res;



    })
  }
  numofPaments(event: any) {
    let feeTypeId = event.target.value;
    let myObj = this.feeTypes.find(obj => obj.FEETYPE_ID == feeTypeId);
    this.feeTypeMode = myObj.feetype_mode;
    if (this.feeTypeMode == 2) {
      this.payments = true;
      this.payments1 = false;
    } else {
      this.payments1 = true;
      this.payments = false;
    }
    this.onChangePayments(1);
  }
  onSubmit(form: any) {
    let value: any = form.value;
    value.TNT_CODE = localStorage.getItem('TenantCode');
    let status: Boolean
    if (value.CURRICULUM_STATUS == 1) {
      status = true
    } else {
      status = false
    }
    let termArray = []
    this.termList.forEach(element => {
      let obj = { TERMFEE_FEEDATE: element.TERMFEE_FEEDATE }
      termArray.push(obj);
    });
    let payLoad = {
      "CURRICULUM_NAME": value.CURRICULUM_NAME,
      "CURRICULUM_DESCRIPTION": value.CURRICULUM_DESCRIPTION,
      "CURRICULUM_ACADEMIC_ID": value.CURRICULUM_ACADEMIC_ID,
      "CURRICULUM_COURSE_ID": value.CURRICULUM_COURSE_ID,
      "amount": value.amount,
      "TNT_CODE": localStorage.getItem('TenantCode'),
      "CURRICULUM_CREATED_BY": localStorage.getItem('UserId'),
      "CURRICULUM_STATUS": status,
      "CURRICULUM_NO_OF_PAYMENTS": value.CURRICULUM_NO_OF_PAYMENTS || 1,
      "fee_type": value.fee_type,
      "feetype_mode": this.feeTypeMode,
      "TERM_FEE_list": termArray
    }
    this.CommonService.postCall('Curriculum/Create', payLoad).subscribe((res: any) => {
      this.GetList();
      this.toastr.success("Curriculum Created Successfully");
      document.getElementById('md_close').click();
    }, err => {
      this.toastr.error(err.error ? err.error : 'Curriculum Not created')

    })


  }
  close() {
    this.isEdit = null;
    this.myForm.reset();
    this.editData = {};
  }
  onChangePayments(event: number) {
    this.termList = [];
    let termArray = []
    termArray = this.termList;
    if (event == termArray.length) {
      termArray = this.termList;
    } else if (event > termArray.length) {
      let n = event - termArray.length;
      for (let index = 0; index < n; index++) {
        if (n <= 6) {
          termArray.push({ TERMFEE_ID: 0, TERMFEE_FEEDATE: 0 });
        }
      }
      this.termList;
    }
    this.termList = termArray;
    // else if(event<termArray.length){
    //   let n = termArray.length-event;
    //   termArray.splice(-1)
    // }
    if (event > 6) {
      this.flag = true;
    } else {
      this.flag = false;
    }

  }

  handlePayment(event) {

  }
}
