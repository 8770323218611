import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { add } from 'date-fns';

@Component({
  selector: 'app-news-letter',
  templateUrl: './news-letter.component.html',
  styleUrls: ['./news-letter.component.css']
})
export class NewsLetterComponent implements OnInit {
myForm:FormGroup;

  constructor(private fb:FormBuilder) { }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      CAMPUS:[''],
      COURSE:[''],
      COURSE_SCHEDULE:[''],
      MONTH:[''],
      DESIGNATION:[''],
      NEWS_LETTER_NAME:[''],
      NEWS_LETTER_FORMAT:['']

    })
  }
  add(){

  } 
  onSubmit(form:FormGroup){

  } 
  close(){

  }
  }


