<div class="custom_container">
    <form class="well form-horizontal" [formGroup]="myForm">
        <fieldset>
            <div class="form-group row">
                <label class="col-md-4 control-label mt-2">
                    {{labelName.Campus}} </label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group">
                        <strong>:</strong> &nbsp;
                        <select formControlName="campus_id" (change)="campusChange($any($event).target.value)"
                            class="form-select form-select-sm shadow-sm rounded-lg">
                            <option value="" [selected]="true">Select</option>
                            <option value="" *ngFor="let item of campus" [value]='item.campus_id'>
                                {{item.campus_name}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>&nbsp;

            <div class="form-group row">
                <label class="col-md-4 control-label mt-2">
                    {{labelName.Block}} </label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group">
                        <strong>:</strong> &nbsp;
                        <select formControlName="block_id" (change)="blockChange($any($event).target.value)"
                            class="form-select form-select-sm shadow-sm rounded-lg">
                            <option value="" selected>Select</option>
                            <option value="" *ngFor="let item of block" [value]='item.block_id'>
                                {{item.block_name}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>&nbsp;

            <div class="form-group row">
                <label class="col-md-4 control-label mt-2">
                    {{labelName.Floor}} </label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group">
                        <strong>:</strong> &nbsp;
                        <select formControlName='floor_id' (change)="floorChange($any($event).target.value)"
                            class="form-select form-select-sm shadow-sm rounded-lg">
                            <option value="" selected>Select</option>
                            <option value="" *ngFor="let item of floor" [value]='item.floor_id'>
                                {{item.floor_name}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>&nbsp;

            <div class="form-group row">
                <label class="col-md-4 control-label mt-2">
                    {{labelName.Room}} </label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group">
                        <strong>:</strong> &nbsp;
                        <select formControlName='room_id' (change)="roomChange($any($event).target.value)"
                            class="form-select form-select-sm shadow-sm rounded-lg">
                            <option value="" selected>Select</option>
                            <option value="" *ngFor="let item of room" [value]='item.room_id'>
                                {{item.room_type_name}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>&nbsp;


            <div class="form-group row">
                <label class="col-md-4 control-label mt-2">
                    {{labelName.JoinDate}}</label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group">
                        <strong>:</strong> &nbsp;
                        <input type="date" formControlName="joined_date" class="form-control " />
                    </div>
                </div>
            </div>
            &nbsp;

            <div class="form-group row">
                <label class="col-md-4 control-label mt-2">
                    {{labelName.Amount}} </label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group">
                        <strong>:</strong> &nbsp;
                        <input type="number" formControlName="amount" class="form-control " />
                    </div>
                </div>
            </div>

        </fieldset>
    </form>
</div>