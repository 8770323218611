import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-fine',
  templateUrl: './fine.component.html',
  styleUrls: ['./fine.component.css']
})
export class FineComponent implements OnInit {
  myForm: FormGroup;
  courses: Array<any> = [];
  Schedules: Array<any> = []
  students: Array<any> = [];
  chapters: any[];
  sheduleId: string;
  courceId: any;
  isTable: boolean = false;
  table: Array<any> = [];
  labelName: any = {};
  headingName: any = {};
  workItemId: any;
  studentId: any;
  qrCode: Array<any> = [];
  singleData: any = {};
  studentIds: any = {};
  courseYear: any = {};
  courseId: any = {};
  fineAmount: any = {};
  bookId: any;
  inventoryId: any;
 
  constructor(private toastr: ToastrService, private commonService: CommonService, private fb: FormBuilder, private active: ActivatedRoute, private route: Router,) {
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
    this.getLabels();
    this.loadHeadings();

  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      // courseName: ['', Validators.required],
      // courseSchedule: ['', Validators.required],
      // department: ['', Validators.required],
      // student: ['', Validators.required],
    })
    // this.loadCourse();

  }
  activeSpinner() {
    this.commonService.activateSpinner();
  }

  deactivateSpinner() {
    this.commonService.deactivateSpinner()
  }
  getLabels() {
    this.labelName = {};
    this.commonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.commonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }


  scanQr(StuId: any) {
    if (StuId == "") {
      this.qrCode = [];
      return;
    }
    this.studentId = StuId;
    let payload = {
      "enrollment_no": StuId
    }
    this.commonService.postCall('LibraryManagement/Getfinedetails', payload).subscribe((res: any) => {
      console.log(res);
      this.qrCode = res;
      this.studentIds = res[0].book_std_student_id;
      this.courseYear = res[0].course_year_id;
      this.courseId = res[0].courseid;
      // this.fineAmount=res[].fine_amount;
      this.bookId = res[0].book_id;
      this.inventoryId = res[0].book_inventory_id;

    })
  }

  payAmount(event: any, id: any, fId: any) {
    let payload = {
      book_std_id: id,
      FEERECEIVABLE_STUDENT_ID: this.studentIds,
      FEERECEIVABLE_COURSE_ID: this.courseId,
      FEERECEIVABLE_PAIDAMOUNT: fId,
      book_id: this.bookId,
      course_year_id: this.courseYear,
      book_inventory_id: this.inventoryId,
      // semester_id:
      FEERECEIVABLE_ACADEMIC_ID: 1,
      FEERECEIVABLE_CURRICULUM_ID: 36,
      FEERECEIVABLE_FEEDESC_ID: 71,
      FEERECEIVABLE_FEETYPE_ID: 40,
      FEERECEIVABLE_CREATED_BY: localStorage.getItem('UserId'),
      FEERECEIVABLE_MODIFIED_BY: localStorage.getItem('UserId'),
      TNT_CODE: localStorage.getItem('TenantCode')
    }
    this.commonService.postCall('LibraryManagement/Bookfinepay', payload).subscribe((res: any) => {
      this.toastr.success('Payment Successfull');
      window.location.reload();
    })
  }
  onSubmit(form) {

  }
  // loadCourse() {
  //   this.activeSpinner();
  //   this.commonService.getAdminCourses().subscribe((res: any) => {
  //     this.courses = res;
  //     this.deactivateSpinner();
  //   }, e => {
  //     this.deactivateSpinner();
  //   })

  // }

  // courseChange(event) {

  //   this.chapters = [];
  //   this.sheduleId = '';
  //   let data = {
  //     "CourseId": event.target.value
  //   }
  //   this.commonService.getAdminCourseSchedule(data).subscribe((res: any) => {
  //     this.deactivateSpinner()
  //     this.Schedules = res;
  //   }, e => { this.deactivateSpinner() })
  // }

  // sheduleChange(event) {
  //   this.activeSpinner();
  //   let payLoad = {
  //     "COURSESHD_ID": event.target.value
  //   }
  //   this.commonService.postCall('Account/usersbycourseShedule', payLoad).subscribe((res: any) => {
  //     this.deactivateSpinner();
  //     this.students = res;
  //   }, e => {
  //     this.deactivateSpinner();
  //   });
  // }

  // loadTable(userid) {

  //   this.isTable = true;
  //   this.commonService.getCall(`LibraryManagement/LoadBooksByStudents/${userid}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
  //     this.table = res;
  //   })
  // }

  // onSubmit(form) {

  // }

}
