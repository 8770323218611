import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { ItemsComponent } from '../items/items.component';
@Component({
  selector: 'app-room-types',
  templateUrl: './room-types.component.html',
  styleUrls: ['./room-types.component.css']
})
export class RoomTypesComponent implements OnInit {
  myForm: FormGroup;
  dropdownSettings: any = {}
  dropdownListLanguage: any[] = [];
  selectedCategory!: { item_id: number; item_name: string; room_types_details_id: number }[];
  table: Array<any> = [];
  Category: Array<any> = [];
  // isData: boolean;
  isEdit: boolean = false;
  dropdownListCategory: { item_id: number; item_name: string; }[] = [];
  editData: any;
  contentId: string = '';
  item_id: any;
  isDisable: boolean = false;
  labelName: any = {};
  headingName: any = {};
  workItemId: any;
  constructor(private fb: FormBuilder, private CommonService: CommonService, private toastr: ToastrService,private active : ActivatedRoute ) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadlabels();
    this.loadRoomType();
    this.categoryDropdown();
    this.loadHeadings();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      name: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      category_id: ['', Validators.required,],
      items: ['', Validators.required,],
    })

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }
  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  loadRoomType() {
    this.table = [];
    this.CommonService.getCall(`EmsRoomTypesDetails/GetList/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.table = res;
    })
  }
  categoryDropdown() {
    this.CommonService.getCall(`EmsRoomTypesDetails/ItemsCategoryDropDownList/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.Category = res;
    })
  }
  // categoryChange(){
  //   this.CommonService.getCall(`EmsRoomTypesDetails/RoomItemsDropDownList/1/`).subscribe((res: any) => {
  //     this.Category = res;
  //   }) 

  // }
  categoryChange(id) {
    this.CommonService.getCall(`EmsRoomTypesDetails/RoomItemsDropDownList/${id}`).subscribe((res: any) => {
      this.dropdownListCategory = res;
      // res.map(item => {
      //   let obj = {
      //     item_id: item.item_id,
      //     item_name: item.item_name
      //   }
      //   this.dropdownListCategory.push(obj)
      // })
      // console.log(this.dropdownListCategory);

      setTimeout(() => { }, 10)
      if (this.isEdit) this.setSelected()
    }, e => {
    })
  }
  setSelected() {
    let value: string = this.editData['room_items']
    let list: any = []
    value?.split(',').map((id: any) => {

      let index = this.dropdownListCategory.findIndex(data => data.item_id == id);

      if (index > 1) {
        list.push(this.dropdownListCategory[index])
      }
    })
    this.selectedCategory = list;
  }

  onItemSelect(e) { }
  onSelectAll(e) { }
  onSubmit(form: FormGroup) {
    let value: any = form.value;
    let payload = {

      "name": value.name,
      "category_id": value.category_id,
      "created_by": localStorage.getItem('UserId'),
      "modified_by": localStorage.getItem('UserId'),
      "tnt_code": localStorage.getItem('TenantCode'),
      "items": this.selectedCategory,
      // "items" :  this.dropdownListCategory

    }


    if (this.isEdit) {
      value.modified_by = localStorage.getItem('UserId');
      value['room_type_id'] = this.editData.room_type_id;

      this.CommonService.postCall('EmsRoomTypesDetails/Update', payload).subscribe((res: any) => {
        this.loadRoomType();
        this.toastr.success("Updated Successfully");
        document.getElementById('md_close')?.click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Not Updated')
      })
    } else {
      this.CommonService.postCall('EmsRoomTypesDetails/Create', payload).subscribe((res: any) => {
        this.loadRoomType();
        this.toastr.success(" Created Successfully");
        document.getElementById('md_close')?.click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Not created')

      })
    }

  }

  add() {
    this.editData = {};
    this.isEdit = false;
  }
  edit(roomId) {
    this.isEdit = true;
    this.myForm.reset();
    let payLoad = {
      "room_type_id": roomId
    }
    this.CommonService.postCall('EmsRoomTypesDetails/Get', payLoad).subscribe((res: any) => {
      if (res instanceof Array && res.length) {
        this.editData = res[0];
        this.dataTransform();
      } else {
        this.editData = res;
        this.dataTransform()
      }
      this.categoryChange(this.editData.category_id)
      this.dataTransform();

    })
  }
  dataTransform() {

    let data = this.editData;
    let ctrl = this.myForm.controls
    ctrl['name'].setValue(data['name'])
    ctrl['category_id'].setValue(data['category_id']);
    ctrl['items'].setValue(data['items']);

    data.items.foreach(item => {
      let obj = {
        item_id: item.item_id,
        item_name: item.item_name,
        room_types_details_id: item.room_types_details_id
      }
      this.selectedCategory.push(obj);
    })
    //ctrl['items'].setValue(this.selectedCategory);

    // this.selectedCategory.push(obj)
    // this.selectedCategory=[..(data.items)];
  }

  close() {
    this.myForm.reset();
    this.isEdit = null;
    this.editData = {};
  }
}

