import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-dropoff-students',
  templateUrl: './dropoff-students.component.html',
  styleUrls: ['./dropoff-students.component.css']
})
export class DropoffStudentsComponent implements OnInit {
  table: Array<any> = [];
  myForm: FormGroup;
  campusDropdown: Array<any> = [];
  cources: Array<any> = [];
  scheduls: Array<any> = [];
  courceId: any = '';
  labelName: any = {};
  headingName: any = {};
  workItemId: any;


  constructor(private fb: FormBuilder,private active : ActivatedRoute, private CommonService: CommonService, private toastr: ToastrService, private route: Router) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadlabels();
    this.loadHeadings();
    this.loadCampusDropdown();
    this.getCourses();
    this.loadTimeTable();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      // searchKeyWord : ['',Validators.required],
      // campus_id : ['',Validators.required],
      class_id: ['', Validators.required],
      section_id: ['', Validators.required],
      // student : [''],
      // contains : ['',Validators.required],
      // transport : ['',Validators.required],
      // date: ['',Validators.required],
    })
  }

  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }


  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  loadCampusDropdown() {
    this.campusDropdown = [];
    this.CommonService.postCall('EmsCampus/CampusDropDownList', { 'tnt_code': localStorage.getItem('TenantCode') }).subscribe((res: any) => {
      this.campusDropdown = res;
    })
  }
  getCourses() {
    this.activeSpinner()
    this.CommonService.getAdminCourses().subscribe((res: any) => {
      this.cources = res;
      this.deactivateSpinner()
    }, e => {
      this.deactivateSpinner()
    })
  }

  courceChange(id: any) {
    let data = {
      "CourseId": id
    }
    this.activeSpinner()
    this.CommonService.getAdminCourseSchedule(data).subscribe((res: any) => {
      this.deactivateSpinner()
      this.scheduls = res;
    }, e => { this.deactivateSpinner() })
  }

  loadTimeTable() {

    this.CommonService.getCall(`TimeTable/DropOffStudents/${localStorage.getItem('TenantCode')}/${-1}/${-1}`).subscribe((res: any) => {
      this.table = res;
    })
  }

  onSubmit(form: any) {
    let value = form.value;
    let payload={
      "class_id":value.class_id,
      "section_id":value.section_id,
      "tnt_code":localStorage.getItem('TenantCode'),
      "user_id":localStorage.getItem('UserId'),
      "role_name":localStorage.getItem('RoleName')
    }
    this.CommonService.postCall('TimeTable/DropOffStudents',payload).subscribe((res:any)=>{
      this.table = res;
    })
   
  }


}
