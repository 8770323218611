import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-define-template',
  templateUrl: './define-template.component.html',
  styleUrls: ['./define-template.component.css']
})
export class DefineTemplateComponent implements OnInit {
  isVertical: boolean;
  isHorizontal: boolean;

  constructor(private fb: FormBuilder, private CommonService: CommonService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.drops();
  }


  //    drag_start(event) {
  //   var style = window.getComputedStyle(event.target, null);
  //   var str = (parseInt(style.getPropertyValue("left")) - event.clientX) + ',' + (parseInt(style.getPropertyValue("top")) - event.clientY) + ',' + event.target.id;
  //   event.dataTransfer.setData("Text", str);
  // }

  //  drop(event) {
  //   var offset = event.dataTransfer.getData("Text").split(',');
  //   var dm = document.getElementById(offset[2]);
  //   dm.style.left = (event.clientX + parseInt(offset[0], 10)) + 'px';
  //   dm.style.top = (event.clientY + parseInt(offset[1], 10)) + 'px';
  //   event.preventDefault();
  //   return false;
  // }

  //  drag_over(event) {
  //   event.preventDefault();
  //   return false;
  // }
  vertical(event:any){
    let val = event.target.value;
    if(val == "option1"){
      this.isVertical = true;
      this.isHorizontal = false;
    }else if(val == "option2"){
      this.isVertical = false;
      this.isHorizontal = true;
    }
  }

  drag_start(event) {
    var style = window.getComputedStyle(event.target, null);
    event.dataTransfer.setData("text/plain", (parseInt(style.getPropertyValue("left"), 10) - event.clientX) + ',' + (parseInt(style.getPropertyValue("top"), 10) - event.clientY) + ',' + event.target.getAttribute('data-item'));
  }

  drag_over(event) {
    event.preventDefault();
    return false;
  }

  drop(event)  {
    var offset = event.dataTransfer.getData("text/plain").split(',');
    var dm: any = document.getElementsByClassName('dragme');
    dm[parseInt(offset[2])].style.left = (event.clientX + parseInt(offset[0], 10)) + 'px';
    dm[parseInt(offset[2])].style.top = (event.clientY + parseInt(offset[1], 10)) + 'px';
    event.preventDefault();
    return false;
  }

  drops() {
    var dm : any = document.getElementsByClassName('dragme');
    for (var i = 0; i < dm.length; i++) {
      dm[i].addEventListener('dragstart', this.drag_start, false);
      document.body.addEventListener('dragover', this.drag_over, false);
      document.body.addEventListener('drop', this.drop, false);
    }
  }





}
