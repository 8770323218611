import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-employee-sales-report',
  templateUrl: './employee-sales-report.component.html',
  styleUrls: ['./employee-sales-report.component.css']
})
export class EmployeeSalesReportComponent implements OnInit {
  table: Array<any> = [];
  headingName: any = {};
  workItemId: any;
  constructor(private active: ActivatedRoute,private CommonService: CommonService) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadEmployee();
    this.loadHeadings();
  }

  ngOnInit(): void {
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }


  loadEmployee() {
    let payLoad = {
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('EmsEmployeeSales/GetEmployeeSalesReport', payLoad).subscribe((res: any) => {
      this.table = res;
    })
  }
}
