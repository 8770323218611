<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.ClientPaymentsDetails}}</h5>
                                    </div>
                                    <div class="card-body">
                                       
                                        <div *ngIf="table.length" class="pt-4">
                                            <table id="patreggraph" datatable
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small">
                                                <thead class="top "
                                                    style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th> S.no</th>
                                                        <th> Tenant Name</th>
                                                        <th>Payment Date</th>
                                                        <th>Amount</th>
                                                       
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table; let i = index;">
                                                        <td>{{i+1}}</td>
                                                        <td>{{item.tnt_name}}</td>
                                                        <td>{{item.createddate | date : 'dd/MM/yyyy'}}</td>
                                                        <td>{{item.amount}}</td>                                                      

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>