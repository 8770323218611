


<div class="default_class">
    <div class="content-wrapper p-0">
  
  <div class="container-fluid">
  
    <div class="row">
        <div class="col-xl-12">
            <div class="card rounded shadow-lg" >
  
  
           
            <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
              <h5 class="mb-0"> Time Tracker</h5> 
            </div>
  
            <div class="card-body">
             
            
            
              <table *ngIf="results.length" id="patreggraph"  datatable  class="table table-striped table-bordered mt-2 pt-2 table-sm small display" style="width:100%">
                <thead class="top bg-info">
                    <tr>
                      <th >Course Name</th>
                      <th>	Material  Type</th>
                      <th >	Material Name</th>
                      <th>	Time (HH:MM:SS)</th>           
                              
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of results; let i=index">
                        <td >{{item.COURSE_NAME}}</td>
                        <td>{{item.MATERIAL_TYPE}}</td>
                        <td>{{item.Material_Name}}</td>
                        <td>{{item.Read_Time}}</td>
                              
                     </tr>
                   </tbody>
            </table> 
            <p *ngIf="!results.length" class="text-center" ></p>
            </div>
  
        </div>
        </div>
    </div>
  
    </div>
    </div>
    </div>
  
   
  