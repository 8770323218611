<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.RoomType}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right mb-2">
                                            <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal"
                                                data-bs-target="#myModal">Add Room-Type</button>
                                        </div>
                                        <div *ngIf="table.length">
                                            <table id="patreggraph" datatable
                                                class="table table-striped table-bordered mt-2 pt-2 table-sm small display"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624fd7; color:#ffffff;">
                                                    <tr>
                                                        <th>Room Type Name </th>
                                                        <th>Item Names</th>
                                                        <th>Created Date</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.name}}</td>
                                                        <td>{{item.itemname}}</td>
                                                        <td>{{item.created_on |date:"dd/MM/yyyy"}}</td>



                                                        <td style="display: flex; cursor: pointer;">
                                                            <span data-bs-toggle="modal" data-bs-target="#myModal"
                                                                src="" class="edit_icon"
                                                                (click)="edit(item.room_type_id)"> <i
                                                                    class="fas fa-pen pt-1 mt-1"></i></span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog  modal-lg">
        <div class="modal-content">
            <div class="modal-header py-2">
                <h6 *ngIf="!isEdit" class="modal-title w-100"> {{headingName.AddRoomType}}</h6>
                <h6 *ngIf="isEdit" class="modal-title w-100"> {{headingName.EditRoomType}}</h6>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>

            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName.RoomType}}<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <input [attr.disabled]="isEdit?true:null" type="text" class="form-control"
                                        formControlName="name"onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">    </div>
                                <div *ngIf="myForm.get('name').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('name').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName.ItemsCategory}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select [attr.disabled]="isEdit?true:null" formControlName="category_id"
                                        (change)="categoryChange($any($event.target).value)"
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" [selected]="true">Select</option>
                                        <option value="" *ngFor="let item of Category"
                                            [value]='item.items_categories_id'>
                                            {{item.name}}
                                        </option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('category_id').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('category_id').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName.RoomsItems}}<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                   
                                    <ng-multiselect-dropdown style="width:96%"
                                    formControlName="items"
                                    [placeholder]="'select'"
                                    [settings]="dropdownSettings" [data]="dropdownListCategory"
                                    (onSelect)="onItemSelect($any($event.target).value)"
                                    (onSelectAll)="onSelectAll($any($event.target).value)"
                                    [(ngModel)]='selectedCategory'>
                                </ng-multiselect-dropdown>
                                </div>
                            </div>
                        </div>


                    </fieldset>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-save btn-sm" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-save btn-sm" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-danger  btn-sm" id='md_close' (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>