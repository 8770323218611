<div class="page-wrapper">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">

                    <div class="container-fluid">

                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.StudentSalesReportList}}</h5>
                                    </div>
                                    <div class="card-body">
                                     <!-- <div class="text-right mb-2">
                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                                data-bs-target="#myModal">Add Class Items Sales</button>
                                        </div> -->
                                        <div *ngIf="table.length">
                                        <table id="patreggraph" datatable
                                            class="table table-striped table-bordered mt-2 pt-2 table-sm small display"
                                            style="width:100%">
                                            <thead class="top" style="background-color: #624fd7; color:#ffffff;">
                                                <tr>
                                                    <!-- <th> Transaction No. </th> -->
                                                    <th> Student Name</th>
                                                    <th>Campus</th>
                                                    <th> Course </th>
                                                    <th> Course Schedule </th>
                                                    <th> Discount(%) </th>
                                                    <th> Paid Amount  </th>
                                                    <th> Paid On</th>
                                                    <!-- <th>Order By</th> -->
                                                    
                                                    
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of table">
                                                    <!-- <td></td> -->
                                                    <td>{{item.student_name}}</td>
                                                    <td>{{item.campus}}</td>
                                                    <td>{{item.class_name}}</td>
                                                    <td>{{item.section}}</td>
                                                    <td>{{item.discount}}</td>
                                                    <td>{{item.paid_amount}}</td>
                                                    <td>{{item.paid_on}}</td>
                                                    <!-- <td>{{item.}}</td> -->
                                                </tr>
                                                <!-- <tr >
                                                    <td style="display: flex; text-align: center;">
                                                        <a data-bs-toggle="modal" data-bs-target="#myModal"
                                                            (click)="edit()"><i
                                                                class="zmdi zmdi-edit zmdi-hc-2x"> </i></a>
                                                    </td>
                                                </tr> -->
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="text-center" *ngIf="!table.length">
                                        No Records to display
                                    </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
