import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { getWeekYearWithOptions } from 'date-fns/fp';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { FileuploadService } from 'src/app/services/fileupload.service';
import { BaseComponent } from '../base.component';
import { environment } from './../../../environments/environment'

@Component({
  selector: 'app-upload-template',
  templateUrl: './upload-template.component.html',
  styleUrls: ['./upload-template.component.css']
})
export class UploadTemplateComponent extends BaseComponent implements OnInit {
  fileUrl = environment.downloadUrl
  // courses: Array<any> = [
  //   {
  //     COURSE_ID: 'CourseCategory',
  //     COURSE_NAME: 'Course Category'
  //   },
  //   {
  //     COURSE_ID: 'Course',
  //     COURSE_NAME: 'Course'
  //   },
  //   {
  //     COURSE_ID: 'CourseSchedule',
  //     COURSE_NAME: 'Course Schedule'
  //   },
  //   {
  //     COURSE_ID: 'ProgramOutcomes',
  //     COURSE_NAME: 'Program Outcomes'
  //   },
  //   {
  //     COURSE_ID: 'TrainerRegistration',
  //     COURSE_NAME: 'Trainer Registration'
  //   },
  //   {
  //     COURSE_ID: 'AssessmentQuestionaire',
  //     COURSE_NAME: 'Assessment Questionaire'
  //   },
  //   {
  //     COURSE_ID: 'AssignTrainerCourse',
  //     COURSE_NAME: 'Assign Trainer - Course'
  //   },
  //   {
  //     COURSE_ID: 'CourseScheduleTrainer',
  //     COURSE_NAME: 'Course - Schedule Trainer'
  //   },
  //   {
  //     COURSE_ID: 'ExamResults',
  //     COURSE_NAME: 'Exam Results'
  //   }
  // ]
  courses: Array<any> = [];
  headingName: any = {};
  labelName: any = {};
  workItemId: any;
  tnt_code = localStorage.getItem('TenantCode')
  downloadUrl: any;
  constructor(CommonService: CommonService, toastr: ToastrService, private FileuploadService: FileuploadService, private active: ActivatedRoute) {
    super(CommonService, toastr);
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
    this.loadHeadings();
    this.loadlabels();
    this.excelTemplateDropdown();
  }

  ngOnInit(): void {
  }


  courseChange(utId: any) {
    this.downloadUrl = this.courses.find(e => e.uploadtemplate_id == utId)?.uploadtemplate_url;
  }

  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }
  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  excelTemplateDropdown() {
    this.courses = [];
    this.CommonService.getCall(`ExcelUpload/GetTemplateList/${this.tnt_code}`).subscribe((res: any) => {
      this.courses = res;
    })
  }

  changeFile(event) {
    if (!this.courseId) {
      this.toastr.warning("Please select Excel Template");
      event.target.value = ''
      return
    }
    if (event.target.files.length > 0) {
      let file: File = event.target.files[0];
      let name = file.name;
      let filetype = name.split('.').pop()
      let types: Array<any> = ['xls', 'xlr', 'xlsx',]
      let check = types.includes(filetype);
      if (check) {
        this.file = file;
        return

      }
      else {
        // alert(' Please upload pdf and doc file formats only.')
        this.toastr.warning('Please upload Xls,xlsx file formats only.')
        event.target.value = ''
      }
    }
  }


  upload() {
    const formData = new FormData();
    formData.append('file', this.file);
    formData.append('ClientDocs', 'ClientDocs');
    formData.append('Course', this.courseId);
    this.activeSpinner();
    this.FileuploadService.upload(formData, 'AssessmentQuestionarie/UploadAssessmentQuestionaries').subscribe((res: any) => {
      try {
        this.fileName = res.path;
        if (this.fileName) {
          this.deactivateSpinner()

        }
      } catch (e) {
        console.log(e)
      }

    }, err => { this.deactivateSpinner(); })
  }


  submit() {
    if (!this.courseId || !this.file)
      this.activeSpinner();
    let type = this.courses.find(e => e.uploadtemplate_id == this.courseId)?.uploadtemplate_nametype;
    let formDataType = this.courses.find(e => e.uploadtemplate_id == this.courseId)?.uploadtemplate_name;
    // let type = this.courseId;
    let tenantCode = this.TenantCode;
    let userId = this.userId;
    let payload = {
      file: this.file
    }
    const formData = new FormData();
    formData.append('file', this.file);
    formData.append('DIRECTORY_NAME', formDataType);
    let url = 'ExcelUpload/TemplateType/' + type + '/' + tenantCode + '/' + userId;
    this.CommonService.postCall(url, formData).subscribe((res) => {
      this.deactivateSpinner();
      this.toastr.success("Successfully Uploaded")
    }, e => {
      this.deactivateSpinner();
      this.toastr.error(e.error ? e.error.text || e.error : e || 'Something is wrong! Please Try later')
    })


  }

  download() {
    if (this.downloadUrl == undefined) {
      return this.toastr.warning('Please select excel template');
    } else {
      let dUrl = `${this.fileUrl}/${this.downloadUrl}`;
      window.open(dUrl);
    }
  }

  downloadFlowDoc() {
    let dUrl = `${this.fileUrl}/home/Commonfiles/FLOW%20FOR%20BULK%20UPLOAD%20DOCUMENTS.pdf`;
    window.open(dUrl);
  }
}
