import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { BaseComponent } from 'src/app/pages/base.component';
@Component({
  selector: 'app-attendance-report-ems',
  templateUrl: './attendance-report-ems.component.html',
  styleUrls: ['./attendance-report-ems.component.css']
})
export class AttendanceReportEmsComponent extends BaseComponent implements OnInit {
  table: Array<any> = [];
  myForm: FormGroup;
  campusDropdown: Array<any> = [];
  cources: Array<any> = [];
  scheduls: Array<any> = [];
  courceId: any = '';
  labelName: any = {};
  headingName: any = {};
  dtOptions: { dom: string; buttons: { extend: string; filename: string; }[]; order: any[]; };
  workItemId: any;
  constructor(private fb: FormBuilder, private active: ActivatedRoute, CommonService: CommonService, toastr: ToastrService, private route: Router) {
    super(CommonService, toastr);
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
    this.loadlabels();
    this.loadCampusDropdown();
    this.getCourses();
    this.loadHeadings();
    this.loadReportDtOptions();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      date: ['', Validators.required],
      campus_id: ['', Validators.required],
      class_id: ['', Validators.required],
      section_id: ['', Validators.required],
      status: ['', Validators.required],
    })
  }


  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  loadReportDtOptions() {
    this.dtOptions = {
      dom: 'Bfrtip',
      buttons: [
        {
          extend: "excel",
          filename: 'Attendance Report List',
        }
      ],
      order: []
    }
  }

  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }


  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  loadCampusDropdown() {
    this.campusDropdown = [];
    this.CommonService.postCall('EmsCampus/CampusDropDownList', { 'tnt_code': this.tId || localStorage.getItem('TenantCode') }).subscribe((res: any) => {
      this.campusDropdown = res;
    })
    this.getCourses();
  }

  campusChange() {
    this.table = [];
  }

  sectionChange() {
    this.table = [];
  }

  attendanceChange() {
    this.table = [];
  }

  getCourses() {
    this.activeSpinner()
    this.CommonService.getAdminCourses(this.tId == '' ? undefined : this.tId).subscribe((res: any) => {
      this.cources = res;
      this.deactivateSpinner()
    }, e => {
      this.deactivateSpinner()
    })
  }

  courceChange(id: any) {
    this.table = [];
    let data = {
      "CourseId": id
    }
    this.activeSpinner()
    this.CommonService.getAdminCourseSchedule(data).subscribe((res: any) => {
      this.deactivateSpinner()
      this.scheduls = res;
    }, e => { this.deactivateSpinner() })
  }

  loadTimeTable() {
    let payload = {
      "sections_id": 1,
      "student_day_wise_status": "Active",
      "tnt_code": this.tId || localStorage.getItem('TenantCode'),
      "ROLE_NAME": localStorage.getItem('RoleName'),
      "USER_ID": localStorage.getItem('UserId')
    }
    this.CommonService.postCall('TimeTable/GetAttendanceReportList', payload).subscribe((res: any) => {
      this.table = res;
    })
  }

  onSubmit(form: any) {
    let value = form.value;
    let payload = {
      "date": value.date,
      "sections_id": value.section_id,
      "student_day_wise_status": value.status,
      "tnt_code": localStorage.getItem('TenantCode'),
      "ROLE_NAME": localStorage.getItem('RoleName'),
      "USER_ID": localStorage.getItem('UserId')
    }
    this.CommonService.postCall('TimeTable/GetAttendanceReportList', payload).subscribe((res: any) => {
      this.table = res;
    })
  }


}
