<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 text-center">{{headingName.Payment}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="form-group ">
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <label for="sel1">{{labelName.TenantType}} : </label>
                                                    <select [(ngModel)]='sId ' (change)="change()"
                                                        class="form-control form-control-sm shadow-sm rounded-lg"
                                                        id="sel1">
                                                        <option value='1'>Subscribed Tenants</option>
                                                        <option value="2">Unsubscribed Tenants</option>
                                                    </select>
                                                </div>

                                            </div>


                                        </div>

                                        <div class="pt-4" *ngIf="table.length">
                                            <table id="patreggraph" datatable
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th> Tenant </th>
                                                        <th>Subscription</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.TNT_NAME}}</td>
                                                        <td *ngIf='sId=="1"'>{{item.SUBSCRIPTIONTYPENAME}}</td>
                                                        <td *ngIf='sId=="2"'>
                                                            <select class="form-control"
                                                                [(ngModel)]='item.SUBSCRIPTIONID'>
                                                                <option selected>Select</option>
                                                                <option *ngFor='let data of subscriptions'
                                                                    [value]='data.SUBSCRIPTIONID'>
                                                                    {{data.SUBSCRIPTIONTYPENAME}}
                                                                </option>
                                                            </select>
                                                        </td>
                                                        <td *ngIf='sId=="1"' class=" text-center pointer"
                                                            (click)='deActivate(item)'>
                                                            De-Activate
                                                        </td>
                                                        <td *ngIf='sId=="2"' class=" text-center pointer"
                                                            (click)='assign(item)'>
                                                            Assign
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>