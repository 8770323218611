<div class="custom_container">
    <form class="well form-horizontal" [formGroup]="myForm">
        <fieldset>
            <div class="form-group row">
                <label class="col-md-4 control-label mt-2">
                    {{labelName.MotherName}}  <span class="text-danger"></span></label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group">
                        <strong>:</strong> &nbsp;
                        <input type="text" formControlName="mother_name" class="form-control " />
                    </div>
                </div>
            </div>&nbsp;

            <div class="form-group row">
                <label class="col-md-4 control-label mt-2">
                    {{labelName.Nationality}}   <span class="text-danger"></span></label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group">
                        <strong>:</strong> &nbsp;
                        <input type="text" formControlName="nationality" class="form-control " />
                    </div>
                </div>
            </div>&nbsp;

            <div class="form-group row">
                <label class="col-md-4 control-label mt-2">
                    {{labelName.PlaceofBirth}}  <span class="text-danger"></span></label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group">
                        <strong>:</strong> &nbsp;
                        <input type="text" formControlName="place_of_birth" class="form-control " />
                    </div>
                </div>
            </div>&nbsp;


            <div class="form-group row">
                <label class="col-md-4 control-label mt-2">
                    {{labelName.Caste}} </label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group"> <strong>:</strong> &nbsp;
                        <select class="form-select form-select-sm shadow-sm rounded-lg" formControlName='caste_id' 
                        (change)="casteCategoryDropdown($any($event.target).value)">
                            <option value="" [selected]="true">select</option>
                            <option [value]='item.caste_id' *ngFor='let item of caste'>{{item.caste_name}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>&nbsp;

            <div class="form-group row">
                <label class="col-md-4 control-label mt-2">
                    {{labelName.CasteCategory}}</label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group"> <strong>:</strong> &nbsp;
                        <select class="form-select form-select-sm shadow-sm rounded-lg" formControlName='caste_category_id'>
                            <option value="" [selected]="true">select</option>
                            <option [value]='item.caste_category_id' *ngFor='let item of casteCategory'>{{item.category_name}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>&nbsp;

            <div class="form-group row">
                <label class="col-md-4 control-label mt-2">
                    {{labelName.LastInstituteAttended}}   <span class="text-danger"></span></label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group">
                        <strong>:</strong> &nbsp;
                        <input type="text" formControlName="last_institute_attended" class="form-control " />
                    </div>
                </div>
            </div>&nbsp;


            <div class="form-group row">
                <label class="col-md-4 control-label mt-2">
                    {{labelName.DateofLeavingInstitution}} <span class="text-danger"></span></label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group">
                        <strong>:</strong> &nbsp;
                        <input type="date" formControlName="dateofleavinginstitution" class="form-control" />
                    </div>
                </div>
            </div>
            &nbsp;

            <div class="form-group row">
                <label class="col-md-4 control-label mt-2">
                    {{labelName.Standardinwhichstudying}}  <span class="text-danger"></span></label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group">
                        <strong>:</strong> &nbsp;
                        <input type="text" formControlName="standardinwhichstudying" class="form-control " />
                    </div>
                </div>
            </div>
            &nbsp;
            <div class="form-group row">
                <label class="col-md-4 control-label mt-2">
                    {{labelName.ConductCharacter}} <span class="text-danger"></span></label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group">
                        <strong>:</strong> &nbsp;
                        <input type="text" formControlName="conduct_character" class="form-control " />
                    </div>
                </div>
            </div>
            &nbsp;
            <!-- <div class="form-group row">
                <label class="col-md-4 control-label mt-2">
                    {{labelName.ReasonofLeavingtheCollege}}   <span class="text-danger"></span></label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group">
                        <strong>:</strong> &nbsp;
                        <input type="text" formControlName="reason_of_leavingthecollege" class="form-control " />
                    </div>
                </div>
            </div>
            &nbsp; -->

            <div class="form-group row">
                <label class="col-md-4 control-label mt-2">
                    {{labelName.ConductProgress}} <span class="text-danger"></span></label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group">
                        <strong>:</strong> &nbsp;
                        <input type="text" formControlName="conduct_progress" class="form-control " />
                    </div>
                </div>
            </div>
            &nbsp;
            <div class="form-group row">
                <label class="col-md-4 control-label mt-2">
                    {{labelName.Remarks}}    <span class="text-danger"></span></label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group">
                        <strong>:</strong> &nbsp;
                        <input type="text" formControlName="remarks" class="form-control " />
                    </div>
                </div>
            </div>
        </fieldset>
    </form>
</div>