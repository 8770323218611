import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-health',
  templateUrl: './health.component.html',
  styleUrls: ['./health.component.css']
})
export class HealthComponent implements OnInit {
  myForm: FormGroup;
  table:Array<any>=[];
  isTable: Boolean = false;
  headingName: any = {};
  labelName:any={};
  workItemId: any;
  constructor(private active : ActivatedRoute,private CommonService:CommonService) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.getLabels();
    this.loadHeadings();
   }

  ngOnInit(): void {
  }

  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  onSubmit(myForm) {
    this.isTable = false;
  }
}
