<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.TransportSlabs}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right mb-2">
                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                                data-bs-target="#myModal">Add slab</button>
                                        </div>
                                        <div *ngIf="table.length">
                                            <table datatable id="DataTables_Table_0"
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small display"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #0d9bc4; color:#ffffff;">
                                                    <tr>
                                                        <th>
                                                            From</th>
                                                        <th> To</th>
                                                        <th>Price</th>
                                                        <th>Created On</th>
                                                        <th>Created By</th>
                                                        <th>Status</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.transport_slabs_from}}</td>
                                                        <td>{{item.transport_slabs_to}}</td>
                                                        <td>{{item.price}}</td>
                                                        <td>{{item.created_on |date:"dd/MM/yyyy"}}</td>
                                                        <td>{{item.name}}</td>
                                                        <td>{{item.status == true ? 'Active' : 'Inactive'}}</td>


                                                        <td style="display: flex;cursor: pointer;">
                                                            <span data-bs-toggle="modal" data-bs-target="#myModal"
                                                                src="" class="edit_icon"
                                                                (click)="edit(item.transport_slabs_id)"> <i
                                                                    class="fas fa-pen pt-1 mt-1"></i></span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="modal" id="myModal" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog ">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header py-2">
                <h6 *ngIf="!isEdit" class="modal-title w-100">{{headingName.AddSlab1}}</h6>
                <h6 *ngIf="isEdit" class="modal-title w-100">{{headingName.EditSlab}}</h6>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label ">{{labelName?.From}}<span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <input type="number" placeholder="" class="form-control"
                                        formControlName="transport_slabs_from" pattern="^[1-9][0-9]*$" required
                                        oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';">
                                </div>
                                <div *ngIf="myForm.get('transport_slabs_from').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('transport_slabs_from').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>

                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label ">{{labelName?.To}}<span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <input type="number" placeholder="" class="form-control"
                                        formControlName="transport_slabs_to" pattern="^[1-9][0-9]*$" required
                                        oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';">
                                </div>
                                <div *ngIf="myForm.get('transport_slabs_to').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('transport_slabs_to').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label ">{{labelName?.Price}}<span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <input type="number" placeholder="" class="form-control" formControlName="price"
                                        pattern="^[1-9][0-9]*$" required
                                        oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';">
                                </div>
                                <div *ngIf="myForm.get('price').touched">
                                    <span class="text-danger" *ngIf="myForm.get('price').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label mt-2">
                                {{labelName?.Status}} </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                        formControlName='status'>
                                        <option value="" selected>Select</option>
                                        <option value=true>Active</option>
                                        <option value=false>Inactive</option>
                                    </select>
                                </div>

                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" (click)="onSubmit(myForm)" class="btn btn-save btn-sm"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" (click)="onSubmit(myForm)" class="btn btn-save btn-sm"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-danger btn-sm" id='md_close' (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>