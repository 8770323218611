<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.Emails}}</h5>
                                    </div>
                                    <div class="card-body">

                                        <div class="row">
                                            <div *ngIf='isSuperAdmin' class="col-md-3 pb-4">
                                                <label for="sel1">{{labelName.TenantName}} : </label>
                                                <select [(ngModel)]='tId ' (change)="load()"
                                                    class="form-control form-control-sm shadow-sm rounded-lg" id="sel1">
                                                    <option value="" [selected]="true">select</option>
                                                    <option *ngFor="let item of tenanates" [value]='item.TNT_CODE'>
                                                        {{item.TNT_NAME}}</option>
                                                </select>

                                            </div>
                                        </div>
                                        <div *ngIf="table.length">
                                            <table id="patergraph" datatable
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th>S.No </th>
                                                        <th>Type</th>
                                                        <th>To</th>
                                                        <th>Subject</th>
                                                        <th>Sent Count</th>
                                                        <th>Sent Date</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table;let i = index;">
                                                        <td>{{i+1}}</td>
                                                        <td>{{item.email_type}}</td>
                                                        <td>{{item.email_to}}</td>
                                                        <td>{{item.email_subject}}</td>
                                                        <td>{{item.sent_count}}</td>
                                                        <td>{{item.created_on | date : 'dd/MM/yyyy'}}</td>
                                                        <td>{{item.email_status}}</td>
                                                        <td style="display: flex; cursor: pointer;">
                                                            <!-- <button class="btn btn-warning" type="button"
                                                                (click)="resendMail(item.email_queue_id,'Approved')">Resend
                                                                Mail</button> &nbsp;&nbsp; -->
                                                            <button class="btn btn-warning" type="button"
                                                                data-bs-toggle="modal" data-bs-target="#myModal"
                                                                (click)="view(item.email_queue_id,'Approved')">View
                                                                Mail</button>
                                                        </td>
                                                    </tr>
                                                </tbody>

                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="modal" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog  modal-lg">
        <div class="modal-content">

            <div class="modal-header py-2">
                <h4 class="modal-title w-100"> {{headingName.ViewMail}}</h4>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>
            <div class="modal-body">
                <div>
                    <h4>{{viewMailData.email_subject}}</h4>
                    <div id="htmldiv" class="html" [innerHTML]="viewMailData.email_body"></div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-danger" id='md_close' (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>