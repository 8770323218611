import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-student-conveyance',
  templateUrl: './student-conveyance.component.html',
  styleUrls: ['./student-conveyance.component.css']
})
export class StudentConveyanceComponent implements OnInit {

  table: Array<any> = [];
  myForm: FormGroup;
  isEdit: boolean = false;
  campus: Array<any> = [];
  courses: Array<any> = [];
  scheduls: Array<any> = [];
  students: Array<any> = [];
  route: Array<any> = [];
  stop: Array<any> = [];
  bus: Array<any> = [];
  slabs: Array<any> = [];
  type: Array<any> = [];
  courceId: string = '';
  price: any;
  from: any;
  to: any;
  distance: any;
  amount: any;
  editData: any = {};
  slabId: any;
  transport_slab_id: any;

  labelName: any = {};
  headingName: any = {};
  workItemId: any;


  constructor(private fb: FormBuilder,private active : ActivatedRoute, private CommonService: CommonService, private toastr: ToastrService) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadStudent();
    this.loadCampus();
    this.getCourses();
    this.loadRoutes();
    this.loadSlabs();
    this.getLabels();
    this.loadHeadings();

  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.myForm = this.fb.group({
      // campus_id: ['',],
      course_id: ['', Validators.required],
      courseshd_id: ['', Validators.required],
      student_id: ['', Validators.required],
      route_id: ['', Validators.required],
      bus_no: ['', Validators.required],
      route_stop_id: ['', Validators.required],
      transport_slab_id: ['', Validators.required],
      conveyance_type: [''],
      distance: [''],
      amount: [''],
    });
  }
  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  loadStudent() {
    this.CommonService.postCall('EmsStudentConveyance/GetList', { "tnt_code": localStorage.getItem('TenantCode') }).subscribe((res: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = res;
      }, 10);
    })
  }

  loadCampus() {
    this.CommonService.postCall('EmsCampus/CampusDropDownList', { "tnt_code": localStorage.getItem('TenantCode') }).subscribe((res: any) => {
      this.campus = res;
    })
  }

  getCourses() {

    this.CommonService.getAdminCourses().subscribe((res: any) => {
      this.courses = res;

    })
  }
  courceChange(id) {
    let data = {
      "CourseId": id
    }
    this.CommonService.getAdminCourseSchedule(data).subscribe((res: any) => {
      this.scheduls = res;
    })
  }
  scheduleChange(id) {
    let payLoad = {
      "courseShdId": id,
      "tnt_code": localStorage.getItem('TenantCode'),
      "student_conveyance_id": this.editData['student_conveyance_id'] || 0
    }
    this.CommonService.postCall('EmsStudentConveyance/GetTransportApprovalStudent', payLoad).subscribe((res: any) => {
      this.students = res;
    })
  }

  loadRoutes() {
    this.CommonService.getCall(`Transport/RouteDropDownList/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.route = res;
    })
  }


  loadStops(rId: any) {

    this.CommonService.getCall(`Transport/RouteStopDropDownList/${rId}`).subscribe((res: any) => {
      this.stop = res;
    })
  }


  loadBus(rId: any) {
    this.CommonService.getCall(`Transport/RouteBusesDropDownList/${rId}`).subscribe((res: any) => {
      this.bus = res;
    })
  }

  loadSlabs() {
    this.CommonService.getCall(`Transport/TransportSlabsDropDown/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.slabs = res;
    })
  }

  changeSlabs(e: any) {
    this.slabId = e;
    this.CommonService.postCall('Transport/GetTransportSlabs', { 'transport_slabs_id': this.slabId }).subscribe((res: any) => {
      this.price = res.price;
      this.from = res.transport_slabs_from;
      this.to = res.transport_slabs_to
    })
  }



  onSubmit(form: FormGroup) {
    let value = this.myForm.value;
    let payload = {
      // "campus_id":value.campus_id,
      "course_id": value.course_id,
      "courseshd_id": value.courseshd_id,
      "student_id": value.student_id,
      "route_id": value.route_id,
      "route_stop_id": value.route_stop_id,
      "amount": this.price,
      "transport_slab_id": value.transport_slab_id,
      "conveyance_type": value.conveyance_type,
      "bus_no": value.bus_no,
      "distance": `${this.from} - ${this.to}`,
      "created_by": localStorage.getItem('UserId'),
      "modified_by": localStorage.getItem('UserId'),
      "tnt_code": localStorage.getItem('TenantCode'),
    }
    console.log(payload);
    // return
    if (this.isEdit) {
      payload['student_conveyance_id'] = this.editData.student_conveyance_id;
      this.CommonService.postCall('EmsStudentConveyance/Update', payload).subscribe((res: any) => {
        this.loadStudent();
        this.toastr.success("Student Conveyance Updated Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Student Conveyance Not Updated')
      })
    } else {
      this.CommonService.postCall('EmsStudentConveyance/Create', payload).subscribe((res: any) => {
        this.loadStudent();
        this.toastr.success("Student Conveyance Created Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Student Conveyance Not created')

      })
    }
  }


  edit(scId: any) {
    this.isEdit = true;
    this.CommonService.postCall('EmsStudentConveyance/Get', { "student_conveyance_id": scId }).subscribe((res: any) => {
      this.editData = res;
      this.loadStops(this.editData['route_id']);
      this.loadBus(this.editData['route_id']);
      this.courceChange(this.editData['course_id']);
      this.scheduleChange(this.editData['courseshd_id']);
      this.changeSlabs(this.editData['transport_slab_id']);
      setTimeout(() => {
        this.dataTransfer(res);
      }, 100);

    })
  }


  dataTransfer(data) {

    this.myForm.patchValue({
      course_id: data['course_id'],
      courseshd_id: data['courseshd_id'],
      student_id: data['student_id'],
      route_id: data['route_id'],
      bus_no: data['bus_no'],
      route_stop_id: data['route_stop_id'],
      transport_slab_id: data['transport_slab_id'],
      conveyance_type: data['conveyance_type'],
      distance: data['distance'],
      amount: data['amount'],
    });
  }


  inactive(scId: any, status: boolean) {
    let sts;
    let stst;
    if (status == true) {
      sts = false;
      stst = "Active";
    } else if (status == false) {
      sts = true;
      stst = "Inactive";
    }
    if (confirm(`Are you sure you want to ${stst} Status`)) {
      this.CommonService.postCall('EmsStudentConveyance/Delete', { "student_conveyance_id": scId, "status": sts }).subscribe((res: any) => {
        this.loadStudent();
        if (sts == true) {
          this.toastr.success("Student Conveyance Inactivated Successfully");
        } else if (sts == false) {
          this.toastr.success("Student Conveyance Activated Successfully");
        }
      })
    }
  }


  close() {
    this.initForm();
    this.isEdit = null;
    this.editData = {};
    this.myForm.reset();
  }

}
