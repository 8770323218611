import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-recevied-quotation-list',
  templateUrl: './recevied-quotation-list.component.html',
  styleUrls: ['./recevied-quotation-list.component.css']
})
export class ReceviedQuotationListComponent implements OnInit {
  table: Array<any> = [];
  myForm: FormGroup;
  vendors: Array<any> = [];
  quotationId: any;
  vendorsList: Array<any> = [];
  isTable: boolean = false;
  SelectedVendors:Array<any> = [];
  headingName: any = {};
  workItemId: any;

  constructor(private fb: FormBuilder,private active : ActivatedRoute, private route: Router, private CommonService: CommonService, private toastr: ToastrService) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadRecivedQuotation();
    this.loadHeadings();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      "approve": ['']
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  loadRecivedQuotation() {
    this.CommonService.getCall(`QuotationReceive/LoadQuotationReceived/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = res;
      }, 10)
    })
  }

  getVendors(qId: any) {
    this.quotationId = qId
    this.CommonService.getCall(`QuotationReceive/GetVendorsByQuotationid/${qId}`).subscribe((res: any) => {
      console.log(res);
      this.vendors = res;
    })
  }

  getVendorList(vId: any) {
    this.vendorsList = [];
    this.isTable = true;
    this.CommonService.getCall(`QuotationReceive/GetVendorsByVendorid/${this.quotationId}/${vId}`).subscribe((res: any) => {
      console.log(res);
      this.vendorsList = res;
    })
  }

  checkVendors(event:any,vId){
    let vendorId = vId;    
        console.log(vId,event);
    console.log(event.target.checked);
    let obj = {
      vendor_id: vendorId
    }
    if (event.target.checked) {
      this.SelectedVendors.push(obj);
    } else {
      this.SelectedVendors = this.SelectedVendors.filter(m => m.vendor_id != vendorId);
    }
    console.log(this.SelectedVendors);
  }

  Approve(form: any) {
    let payload = {
      "quotation_id":this.quotationId,
      "vendors": this.SelectedVendors,
    }
    console.log(payload)
    this.CommonService.postCall('QuotationReceive/ApprovedStatus', payload).subscribe((res: any) => {
      this.toastr.success("Quotation Approved Successfully");
      this.loadRecivedQuotation();
      document.getElementById('md_close').click();
    }, err => {
      this.toastr.error(err.error ? err.error : 'Quotation Not Approved')

    })
  }

  recivedItems(QuotationId : any , QuotationNo : any){
    this.route.navigate(['home/receiveItems'], {queryParams : { qId : QuotationId,qNo : QuotationNo,wId:this.workItemId}} )
  }

  payAmount(QuotationId : any , QuotationNo : any){
    this.route.navigate(['home/vendorPayments'], {queryParams : { qId : QuotationId,qNo : QuotationNo,wId:this.workItemId}} )
  }

  close() {
    this.vendorsList = [];
    this.SelectedVendors = [];
   }

}
