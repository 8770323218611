import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.css']
})
export class StaffComponent extends BaseComponent implements  OnInit {
  myForm: FormGroup;
  table: Array<any> = [];
  roles: Array<any> = [];
  tId: string = '';
  isTable: Boolean = false;
  payload: {};
  labelName: any = {};
  headingName: any = {};
  workItemId: any;

  constructor(private fb: FormBuilder, CommonService: CommonService,private active : ActivatedRoute,toastr:ToastrService) {
    super(CommonService,toastr);
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.getRoles();
    this.getLabels();
    this.loadHeadings();

  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      role_id: ['']
    })
  }

  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/4322/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  getRoles() {
    this.activeSpinner();
    this.CommonService.postCall('Role/GetStaffRoles', { RoleName:localStorage.getItem('RoleName'), TENANT_CODE: this.tId || localStorage.getItem('TenantCode') }).subscribe(
      (res: any) => {
        this.roles = res;
        this.deactivateSpinner();
      }, error => {
        this.deactivateSpinner();
      }
    )
  }
  onSubmit(form: any) {
    let val = form.value;
    this.isTable = false;
    this.payload = {
      "role_id": val.role_id
    }
    this.CommonService.getCall(`EmsReports/StaffReport/${val.role_id || 0}/${this.tId ||localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.table = res;
    })
  }
}



