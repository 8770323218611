import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Logger } from 'html2canvas/dist/types/core/logger';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { dataDictionary } from 'src/app/dataDictionary';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-add-time-table',
  templateUrl: './add-time-table.component.html',
  styleUrls: ['./add-time-table.component.css']
})
export class AddTimeTableComponent implements OnInit {
  myForm: FormGroup;
  myForms: FormGroup;
  table: Array<any> = [];
  campusDropdown: Array<any> = [];
  cources: Array<any> = [];
  scheduls: Array<any> = [];
  ArrayList: Array<any> = [];
  subjects: Array<any> = [];
  rooms: Array<any> = [];
  trainer: Array<any> = [];
  teacherName: Array<any> = [];
  sub: Array<any> = [];
  room: Array<any> = [];
  courceId: any = '';
  isEdit: boolean = false;
  viewTable: boolean = false;
  isAssign: boolean = false;
  Monday: boolean = false;
  Tuesday: boolean = false;
  Wed: boolean = false;
  Thu: boolean = false;
  Fri: boolean = false;
  Sat: boolean = false;
  editData: any;
  preriodDropdown = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  preriodDurationDropdown = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60];
  currentIndex: any;
  currentDay: any;
  periods: Array<any> = [];
  labelName: any = {};
  academicYearDropdown: Array<any> = [];
  courseYearDropdown: Array<any> = [];
  semesterDropdown: Array<any> = [];
  sectionDropdown: Array<any> = [];
  headingName: any = {};
  workItemId: any;

  constructor(private fb: FormBuilder, private active: ActivatedRoute, private CommonService: CommonService, private toastr: ToastrService, private route: Router, private ref: ChangeDetectorRef) {
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
    this.loadlabels();
    this.loadCampusDropdown();
    this.getCourses();
    this.getDropdown();
    this.loadHeadings();
    this.getRooms();
    // this.getTrainer();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      campus_id: ['', Validators.required],
      // class_id: ['',Validators.required],
      course_id: ['', Validators.required],
      academic_year_id: ['', Validators.required],
      course_year: ['', Validators.required],
      semester_id: ['', Validators.required],
      section_id: ['', Validators.required],
      // courseshd_id: ['', Validators.required],
      period: ['', Validators.required],
      period_duration: ['', Validators.required],
      periods: this.fb.array([])
    });
    this.myForms = this.fb.group({
      teacher_id: ['', Validators.required],
      subject: ['', Validators.required],
      room_id: ['', Validators.required]
    })
  }

  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }


  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  loadCampusDropdown() {
    this.campusDropdown = [];
    this.CommonService.postCall('EmsCampus/CampusDropDownList', { 'tnt_code': localStorage.getItem('TenantCode') }).subscribe((res: any) => {
      this.campusDropdown = res;
    })
  }
  getCourses() {
    this.activeSpinner()
    this.CommonService.getAdminCourses().subscribe((res: any) => {
      this.cources = res;
      this.deactivateSpinner()
    }, e => {
      this.deactivateSpinner()
    })
  }

  courceChange(id: any) {
    let data = {
      "CourseId": id
    }
    this.activeSpinner()
    this.CommonService.getAdminCourseSchedule(data).subscribe((res: any) => {
      this.deactivateSpinner()
      this.scheduls = res;
      this.getTrainer(id);
      this.getSubjectsbyCourse(id);
    }, e => { this.deactivateSpinner() })
  }

  async getTrainer(course_id: number) {
    // let payLoad = {
    //   "TenantCode": localStorage.getItem('TenantCode'),
    //   "objUserinrole": { "RoleId": localStorage.getItem('RoleId') || 0 }
    // }
    // this.CommonService.postCall('UserRolesChange', payLoad).subscribe((res: any) => {
    //   this.trainer = res;
    //   console.log(this.trainer)
    // })
    await this.CommonService.getCall(`Registration/LoadTrainersByCourse/${course_id}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.trainer = res;
    });
  }

  async getSubjectsbyCourse(id: any) {
    // let payLoad = {
    //   COURSE_ID: id,
    //   TNT_CODE: localStorage.getItem('TenantCode')
    // }
    // this.activeSpinner();
    // this.CommonService.postCall('GetSubjects', payLoad).subscribe(
    //   (res: any) => {
    //     this.subjects = res;
    //     this.deactivateSpinner();
    //   }, err => {
    //     this.deactivateSpinner();
    //   }
    // )
    this.activeSpinner();
    // await this.CommonService.getCall(`Subject/LoadSubjectsByCourseshd/${id}`).subscribe((res: any) => {
    await this.CommonService.getCall(`Subject/LoadSubjectsByCourse/${id}`).subscribe((res: any) => {
      this.subjects = res;
      this.deactivateSpinner();
    },
      err => { this.deactivateSpinner(); });
  }

  async getRooms() {
    let payload = {
      "tnt_code": localStorage.getItem('TenantCode')
    }
    // let payLoad = {
    //   COURSE_ID: id,
    //   TNT_CODE: localStorage.getItem('TenantCode')
    // }
    // this.activeSpinner();
    // this.CommonService.postCall('GetSubjects', payLoad).subscribe(
    //   (res: any) => {
    //     this.subjects = res;
    //     this.deactivateSpinner();
    //   }, err => {
    //     this.deactivateSpinner();
    //   }
    // )
    this.activeSpinner();
    // await this.CommonService.getCall(`Subject/LoadSubjectsByCourseshd/${id}`).subscribe((res: any) => {
    await this.CommonService.postCall('EmsRoom/GetList', payload).subscribe((res: any) => {
      this.rooms = res;
      this.deactivateSpinner();
    },
      err => { this.deactivateSpinner(); });
  }


  getPeriodList() {
    let newPeriod = this.fb.group({
      period_start_time: '',
      period_end_time: '',
      "1": 'Assign',
      "2": 'Assign',
      "3": 'Assign',
      "4": 'Assign',
      "5": 'Assign',
      "6": 'Assign'
    })
    return newPeriod
  }

  ngAfterViewChecked() {
    this.ref.detectChanges();
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  periodChange(event: any) {
    this.myForm.controls['periods'] = this.fb.array([]);
    this.viewTable = true;
    const arrayControl = <FormArray>this.myForm.controls['periods'];
    for (let index = 0; index < event; index++) {
      let grp: FormGroup = this.getPeriodList();
      arrayControl.push(grp);
    }
  }


  edit() {

  }

  onSubmit(form: FormGroup) {
    let value = form.value;
    let campusName = this.campusDropdown.filter(x => parseInt(x.campus_id) === parseInt(value.campus_id));
    let couse = this.cources.filter(x => parseInt(x.COURSE_ID) === parseInt(value.course_id));
    let section = this.sectionDropdown.filter(x => parseInt(x.DICTIONARYID) === parseInt(value.section_id));
    let payload = {
      "campus_id": value.campus_id,
      "campus_name": campusName[0].campus_name,
      "course_id": value.course_id,
      "class_name": couse[0].COURSE_NAME,
      // "courseshd_id": value.courseshd_id,
      section_id: value.section_id,
      "section_name": section[0].DICTIONARYNAME,
      "course_year": value.course_year,
      "semester_id": value.semester_id,
      "academic_year_id": value.academic_year_id,
      "total_periods": value.period,
      "period_duration": value.period_duration,
      "created_by": localStorage.getItem('UserId'),
      "tnt_code": localStorage.getItem('TenantCode'),
      "periods": []
    };
    let periodArr: Array<any> = form.controls?.periods?.value;
    periodArr.forEach((e, i) => {
      this.periods.forEach((m, index) => {
        if (i + 1 == m.period) {
          m.period_start_time = e.period_start_time;
          m.period_end_time = e.period_end_time;
        }
      });
    });

    payload.periods = this.periods;
    console.log('save payload', payload);
    if (this.isEdit) {
      this.CommonService.postCall('TimeTable/CreateUpdateTimeTable', payload).subscribe((res: any) => {
        this.close();
        this.toastr.success("Time Table Updated Succuessfully");
      }, err => {
        this.toastr.error(err.error ? err.error : 'Time Table Not Updated');
      })
    } else {
      this.CommonService.postCall('TimeTable/CreateUpdateTimeTable', payload).subscribe((res: any) => {
        this.close();
        this.toastr.success("Time Table Created Succuessfully");
      }, err => {
        this.toastr.error(err.error ? err.error : 'Time Table Not created');
      })
    }
  }



  Submit(forms: FormGroup, index: any) {
    console.log(index)
    // if (this.currentDay == 'monday' && this.currentIndex == index) {
    //   this.Monday = true;
    // } else {
    //   this.Monday = false;
    // }
    this.teacherName = this.trainer.filter(x => parseInt(x.userid) === parseInt(forms.value.teacher_id));
    this.sub = this.subjects.filter(x => parseInt(x.subject_id) === parseInt(forms.value.subject));
    this.room = this.rooms.filter(x => parseInt(x.room_id) === parseInt(forms.value.room_id));
    // console.log(forms.value);
    // console.log(this.myForm.value);
    let weekDay = ''

    if (this.currentDay == 'mon') {
      //  this.Monday = true; 
      ((this.myForm.get('periods') as FormArray).at(index) as FormGroup).get('1').patchValue(this.teacherName[0].trainer_name + ',' + this.sub[0].subject_name + ',' + this.room[0].room_type_name);
      weekDay = "1";
    } else if (this.currentDay == 'tue') {
      ((this.myForm.get('periods') as FormArray).at(index) as FormGroup).get('2').patchValue(this.teacherName[0].trainer_name + ',' + this.sub[0].subject_name + ',' + this.room[0].room_type_name);
      weekDay = "2";
    } else if (this.currentDay == 'wed') {
      ((this.myForm.get('periods') as FormArray).at(index) as FormGroup).get('3').patchValue(this.teacherName[0].trainer_name + ',' + this.sub[0].subject_name + ',' + this.room[0].room_type_name);
      weekDay = "3";
    } else if (this.currentDay == 'thu') {
      ((this.myForm.get('periods') as FormArray).at(index) as FormGroup).get('4').patchValue(this.teacherName[0].trainer_name + ',' + this.sub[0].subject_name + ',' + this.room[0].room_type_name);
      weekDay = "4";
    } else if (this.currentDay == 'fri') {
      ((this.myForm.get('periods') as FormArray).at(index) as FormGroup).get('5').patchValue(this.teacherName[0].trainer_name + ',' + this.sub[0].subject_name + ',' + this.room[0].room_type_name);
      weekDay = "5";
    } else if (this.currentDay == 'sat') {
      ((this.myForm.get('periods') as FormArray).at(index) as FormGroup).get('6').patchValue(this.teacherName[0].trainer_name + ',' + this.sub[0].subject_name + ',' + this.room[0].room_type_name);
      weekDay = "6";
    }

    // this.myForm.value['periods'][index]['teacher_id'] = forms.value.teacher_id;
    // this.myForm.value['periods'][index]['teacher_name'] = this.teacherName[0].FIRSTNAME;
    // this.myForm.value['periods'][index]['subject_id'] = forms.value.subject;
    // this.myForm.value['periods'][index]['subject_name'] = this.sub[0].SUBJECT_NAME;
    // console.log(this.myForm.value);
    let tempArray = [];
    let i = Object.assign(index);
    let obj = {
      subject_id: forms.value.subject,
      subject_name: this.sub[0].subject_name,
      teacher_id: forms.value.teacher_id,
      teacher_name: this.teacherName[0].trainer_name,
      room_id: forms.value.room_id,
      room_type_name: this.room[0].room_type_name,
      week_day: weekDay,
      period: +i + 1,
    }

    this.periods.push(obj);
    console.log(this.periods);
    this.ref.detectChanges();
    document.getElementById('md_close').click();

  }

  getIndex(i: any, day: any) {
    this.currentIndex = i;
    this.currentDay = day;
  }

  // getTecher(i: any, day: any) {
  //   console.log(day)
  //   if (day === 'monday' && i == this.currentIndex) {
  //     console.log(day)
  //     this.Monday = true;
  //     return this.teacherName[0].FIRSTNAME;
  //   }  if (day === "tuesday" && i == this.currentIndex) {
  //     this.Tuesday = true;
  //     return this.teacherName[0].FIRSTNAME;
  //   }  if (day === "wed" && i == this.currentIndex) {
  //     this.Wed = true;
  //     return this.teacherName[0].FIRSTNAME;
  //   }  if (day === "thu" && i == this.currentIndex) {
  //     this.Thu = true;
  //     return this.teacherName[0].FIRSTNAME;
  //   }  if (day === "fri" && i == this.currentIndex) {
  //     this.Fri = true;
  //     return this.teacherName[0].FIRSTNAME;
  //   }  if (day === "sat" && i == this.currentIndex) {
  //     this.Sat = true;
  //     return this.teacherName[0].FIRSTNAME;
  //   }
  // }

  // getSubject(i: any, day: any) {
  //   console.log(day);
  //   if (day === 'monday' && i == this.currentIndex) {
  //     this.Monday = true;
  //     return this.sub[0].SUBJECT_NAME;
  //   }  if (day == 'tuesday' && i == this.currentIndex) {
  //     this.Tuesday = true;
  //     return this.sub[0].SUBJECT_NAME;
  //   }  if (day == 'wed' && i == this.currentIndex) {
  //     this.Wed = true;
  //     return this.sub[0].SUBJECT_NAME;
  //   }  if (day == 'thu' && i == this.currentIndex) {
  //     this.Thu = true;
  //     return this.sub[0].SUBJECT_NAME;
  //   }  if (day == 'fri' && i == this.currentIndex) {
  //     this.Fri = true;
  //     return this.sub[0].SUBJECT_NAME;
  //   }  if (day == 'sat' && i == this.currentIndex) {
  //     this.Sat = true;
  //     return this.sub[0].SUBJECT_NAME;
  //   }
  // }

  close() {
    this.route.navigate([`home/time-table/${this.workItemId}`]);
  }

  close1() {
    this.myForms.reset()
  }

  getDropdown() {
    this.activeSpinner()
    let academicYear = this.CommonService.getCall(`Academic/GetAcademicDropDownList/${localStorage.getItem('TenantCode')}`);
    let courseYear = this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.CourseYear });
    let semister = this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.Semester });
    let section = this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.Sections });
    let dropdowns = ['academicYearDropdown', 'courseYearDropdown', 'semesterDropdown', 'sectionDropdown']
    forkJoin([academicYear, courseYear, semister, section]).subscribe((res) => {
      dropdowns.map((key, index) => {
        this[key] = res[index]
      });
      this.deactivateSpinner()
    }, err => { this.deactivateSpinner() });
  }

}
