<br>
<div class="page-wrapper">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">

                    <div class="container-fluid">

                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">News Letter List</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right mb-2">
                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                                data-bs-target="#myModal"(click)="add()">News Letter</button>
                                        </div>
                                        <table id="patreggraph" datatable
                                            class="table table-striped table-bordered mt-2 pt-2 table-sm small display"
                                            style="width:100%">
                                            <thead class="top" style="background-color: #624fd7; color:#ffffff;">
                                                <tr>
                                                    <th> Name </th>
                                                    <th> Campus Name </th>
                                                    <th> Course </th>
                                                    <th> Courseschedule </th>
                                                    <th> Month </th>
                                                  
                                                 
                                                    <th> Status </th>
                                                    <th>   Created On </th>
                                                    <th> Actions</th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog  modal-lg">
        <div class="modal-content">

            <div class="modal-header py-2">
                <h4 class="modal-title">News Letter</h4>
                <!-- <h4 *ngIf="isEdit" class="modal-title"> </h4> -->
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal">&times;</button>
            </div>


            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                               Campus <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select  formControlName="CAMPUS"
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <!-- <option *ngFor='let item of block' >{{BLOCK}}</option> -->
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Course<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName='COURSE'
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <!-- <option *ngFor='let item of floor' >{{FLOOR}}</option> -->
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                               Courseschedule <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName='COURSE_SCHEDULE'
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <!-- <option *ngFor='let item of student' >{{STUDENT}}</option> -->
                                    </select>
                                </div>
                            </div>
                        </div>
                       
                       
                      
                        
                       <div class="form-group row pt-4">
                        <label class="col-md-4 control-label line_2 rem_1">
                           Month<span class="text-danger">*</span></label>
                        <div class="col-md-8 inputGroupContainer">
                            <div class="input-group">
                                <strong>:</strong> &nbsp;
                                <input type="date" formControlName="MONTH" class="form-control " />
                            </div>
                        </div>
                    </div>


                    <div class="form-group row pt-4">
                        <label class="col-md-4 control-label line_2 rem_1">
                           Designation <span class="text-danger">*</span></label>
                        <div class="col-md-8 inputGroupContainer">
                            <div class="input-group">
                                <strong>:</strong> &nbsp;
                                <select formControlName='DESIGNATION'
                                    class="form-control form-control-sm shadow-sm rounded-lg">
                                    <option value="" selected>Select</option>
                                    <!-- <option *ngFor='let item of student' >{{STUDENT}}</option> -->
                                </select>
                            </div>
                        </div>
                    </div>

                          
                    <div class="form-group row pt-4">
                        <label class="col-md-4 control-label line_2 rem_1">
                           News Letter Name<span class="text-danger">*</span></label>
                        <div class="col-md-8 inputGroupContainer">
                            <div class="input-group">
                                <strong>:</strong> &nbsp;
                                <input type="text" formControlName="NEWS_LETTER_NAME" class="form-control " />
                            </div>
                        </div>
                    </div>


                          
                    <div class="form-group row pt-4">
                        <label class="col-md-4 control-label line_5 rem_1">
                           News Letter Format<span class="text-danger">*</span></label>
                        <div class="col-md-8 inputGroupContainer">
                            <div class="input-group">
                                <strong>:</strong> &nbsp;
                                <input type="text" formControlName="NEWS_LETTER_FORMAT" class="form-control " />
                            </div>
                        </div>
                    </div>
                 
                    </fieldset>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-save" (click)="onSubmit(myForm)"
                 >Save</button>
                <button type="button"  class="btn btn-sm btn-save" (click)="onSubmit(myForm)"
                   >Update</button>
                <button type="button" class="btn btn-sm btn-danger" id='md_close' (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
