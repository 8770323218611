<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.EventsList1}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div *ngIf='isSuperAdmin' class="col-md-3">
                                                <label for="sel1">{{labelName.TenantName}} : </label>
                                                <select [(ngModel)]='tId ' (change)="loadevents()"
                                                    class="form-control form-control-sm shadow-sm rounded-lg"
                                                    id="sel1">
                                                    <option value="" [selected]="true">select</option>
                                                    <option *ngFor="let item of tenanates" [value]='item.TNT_CODE'>
                                                        {{item.TNT_NAME}}</option>
                                                </select>

                                            </div>
                                            </div>

                                        <div class="text-right mb-2">
                                            <!-- <button [routerLink]="['/home/addParticipants']" type="button"
                                                class="btn btn-primary">Add Participants</button>
                                                &nbsp;
                                            <button [routerLink]="['/home/eventRegistration']" type="button"
                                                class="btn btn-primary">Add Registration</button>
                                                &nbsp; -->
                                            <button  (click)="add()" type="button"
                                                class="btn btn-primary">Add Event</button>
                                        </div>
                                        <div *ngIf="table.length" class="table-responsive pt-2">
                                            <table id="patreggraph" datatable
                                                class="table table-striped table-bordered mt-2 pt-2 table-sm small display">
                                                <thead class="top" style="background-color: #624fd7; color:#ffffff;">
                                                    <tr>
                                                        <th> S.No </th>
                                                        <th> Category </th>
                                                        <th> Title </th>
                                                        <th> Name </th>
                                                        <th> From </th>
                                                        <th> To </th>
                                                        <th> Type </th>
                                                        <!-- <th> Time </th> -->
                                                        <th> Eligibility </th>
                                                        <th> Sponsor </th>
                                                        <th> Organizer </th>
                                                        <th> Status </th>
                                                        <th> Action </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table;let i = index">
                                                        <td>{{i+1}}</td>
                                                        <td>{{item.event_category_name}}</td>
                                                        <td>{{item.event_title}}</td>
                                                        <td>{{item.event_name}}</td>
                                                        <td>{{item.event_from_timestamp | date:'dd/MM/yyyy'}}</td>
                                                        <td>{{item.event_to_timestamp | date:'dd/MM/yyyy'}}</td>
                                                        <td>{{item.event_type == 1 ? 'Internal' : 'External'}}</td>
                                                        <!-- <td>{{item.event_start_time}} - {{item.event_end_time}}</td> -->
                                                        <td>{{item.event_eligibility}}</td>
                                                        <td>{{item.event_sponsor}}</td>
                                                        <td>{{item.event_organizer}}</td>
                                                        <td>{{item.event_status}}</td>
                                                        <!-- <td style="display: flex; text-align: center;cursor: pointer;">
                                                            <a data-bs-toggle="modal" data-bs-target="#myModal"
                                                                (click)="edit(item.route_buses_id)"><i
                                                                    class="zmdi zmdi-edit zmdi-hc-2x ">
                                                                </i></a>
                                                        </td> -->
                                                        <td>
                                                            <div class="btn-group"
                                                                *ngIf="!(item.event_status=='Closed' || item.event_status=='Rejected')">
                                                                <button type="button"
                                                                    class="btn btn-warning dropdown-toggle"
                                                                    data-bs-toggle="dropdown" aria-haspopup="true"
                                                                    aria-expanded="false">
                                                                    Action
                                                                </button>
                                                                <div class="dropdown-menu">
                                                                    <a class="dropdown-item" href="javascript:;"
                                                                        *ngIf="item.event_status == 'Approved'"
                                                                        (click)="statusChange(item.event_id,'Closed')">Close
                                                                        Event</a>
                                                                    <!-- <a class="dropdown-item"
                                                                        [routerLink]="['/home/eventRegistration']">Add
                                                                        Registration</a>
                                                                    <a class="dropdown-item"
                                                                        [routerLink]="['/home/eventRegistrationsList']"
                                                                        *ngIf="item.event_status == 'Approved'">Registrations</a>
                                                                    <a class="dropdown-item"
                                                                        [routerLink]="['/home/addParticipants']">Add
                                                                        Participants</a>
                                                                    <a class="dropdown-item"
                                                                        [routerLink]="['/home/eventParticipantsList']"
                                                                        *ngIf="item.event_status == 'Approved'">Participants</a> -->
                                                                    <a class="dropdown-item"
                                                                        *ngIf="item.event_status == 'Inprogress'"
                                                                        href="javascript:;"
                                                                        (click)="statusChange(item.event_id,'Approved')">Approve</a>
                                                                    <a class="dropdown-item"
                                                                        *ngIf="item.event_status == 'Inprogress'"
                                                                        href="javascript:;"
                                                                        (click)="statusChange(item.event_id,'Rejected')">Reject</a>
                                                                    <a class="dropdown-item"
                                                                        *ngIf="item.event_status == 'Inprogress'"
                                                                        href="javascript:;"
                                                                        (click)="editEvent(item.event_id)">Edit</a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>