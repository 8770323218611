import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { extend } from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { combineLatest } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';
import { FileuploadService } from 'src/app/services/fileupload.service';
import { BaseComponent } from '../base.component';
@Component({
  selector: 'app-routes',
  templateUrl: './routes.component.html',
  styleUrls: ['./routes.component.css']
})
export class RoutesComponent extends BaseComponent implements OnInit {
  table: Array<any> = [];
  routeName: Array<any> = [];
  myForm: FormGroup;
  routeForm: FormGroup;
  list: FormArray;
  isEdit: boolean = false;
  editData: any = {};
  routeData: any = {};
  getRouteRes: Array<any> = [];
  routeId: any;
  routeStopId: Array<any> = [];
  labelName: any = {};
  headingName: any = {};
  workItemId: any;

  constructor(private ref: ChangeDetectorRef, private fb: FormBuilder, private route: Router, CommonService: CommonService, toastr: ToastrService, private active: ActivatedRoute, private FileuploadService: FileuploadService) {
    super(CommonService, toastr);
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.load();
    this.loadRoute();
    this.getLabels();
    this.loadHeadings();

  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      route_name: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      status: ['', Validators.required],
      route_status: ['']
    })
    this.routeForm = this.fb.group({
      route_id: ['', Validators.required],
      list: this.fb.array([]),
    })
  }
  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  getOptionGroup(data) {
    let newRoute = this.fb.group({
      route_stop_id: 0,
      route_id: [data.route_id ? data.route_id : ''],
      stop_name: [data.stop_name ? data.stop_name : '', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      pickup_time: [data.pickup_time ? data.pickup_time : '', Validators.required],
      drop_time: [data.drop_time ? data.drop_time : '', Validators.required],
      latitude: [data.latitude ? data.latitude : '', Validators.required],
      longitude: [data.longitude ? data.longitude : '', Validators.required],
    });
    return newRoute
  }


  add(data = {}) {
    const arrayControl = <FormArray>this.routeForm.controls['list'];
    let grp: FormGroup = this.getOptionGroup(data)
    arrayControl.push(grp)
  }


  delete() {
    const arrayControl = <FormArray>this.routeForm.controls['list'];
    let index = arrayControl.length;
    arrayControl.removeAt(index - 1)
    // this.isEdit=true
  }


  isVisable() {
    let arrayControl = <FormArray>this.routeForm.controls['list'];
    if (arrayControl.length && arrayControl.length > 0) {
      return true
    }
    else {
      return false
    }
  }


  isAdd() {

    let arrayControl = <FormArray>this.routeForm.controls['list'];
    if (arrayControl.length && arrayControl.length > 25) {
      return false
    }
    else {
      return true
    }

  }

  load() {
    this.CommonService.getCall(`Transport/GetRouteList/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = res;
      }, 10);
    })
  }
  // this.renderDataTable();

  loadRoute() {
    this.routeName = [];
    this.CommonService.getCall(`Transport/RouteDropDownList/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.routeName = res;
    })

  }

  onSubmit(form: FormGroup) {
    let value: any = form.value;
    value.tnt_code = localStorage.getItem('TenantCode');
    value.created_by = localStorage.getItem('UserId');
    value.route_status = 'Active';

    if (this.isEdit) {
      value.modified_by = localStorage.getItem('UserId');
      value['route_id'] = this.editData.route_id;

      this.CommonService.postCall('Transport/UpdateRoute', value).subscribe((res: any) => {
        this.load();
        this.toastr.success("Updated Successfully");
        document.getElementById('md_close')?.click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Not Updated')
      })
    }
    else {
      this.CommonService.postCall('Transport/CreateRoute', value).subscribe((res: any) => {
        this.load();
        this.toastr.success("Created Successfully");
        document.getElementById('md_close')?.click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Not Created')
      })
    }
  }
  edit(routeID) {
    this.routeId = routeID;
    this.isEdit = true;
    this.myForm.reset();
    this.CommonService.getCall('Transport/GetRoute/' + routeID).subscribe((res: any) => {
      if (res instanceof Array && res.length) {
        this.editData = res[0];
        this.dataTransForm()
      } else {
        this.editData = res;
        this.dataTransForm()
      }
      this.dataTransForm();
    }), err => { }


  }

  dataTransForm() {
    let ctrls = this.myForm.controls
    Object.keys(ctrls).map((key) => {
      let ctrl: AbstractControl = ctrls[key];
      ctrl.setValue(this.editData[key]);
    });
    ctrls['status'].setValue(this.editData.status ? true : false);
  }


  getAddstops(routeId: any) {
    this.getRouteRes = [];
    this.CommonService.getCall(`Transport/GetRouteStops/${routeId}`).subscribe((res: any) => {
      this.getRouteRes = res;
      // if (res.route_id === 0) {
      //  // this.add();
      //   (<FormGroup>this.routeForm.get('route_id')).patchValue(routeId);
      // } else {
      //   (<FormGroup>this.routeForm.get('route_id')).patchValue(res.route_id);
      // }
      this.loadRoute();
      (<FormGroup>this.routeForm.get('route_id')).patchValue(routeId);
      if (res.stops != null && res.stops.length > 0) {
        res.stops.map(x => {
          let obj = this.fb.group({
            route_stop_id: x.route_stop_id,
            stop_name: this.fb.control(x.stop_name),
            pickup_time: this.fb.control(x.pickup_time),
            drop_time: this.fb.control(x.drop_time),
            latitude: this.fb.control(x.latitude),
            longitude: this.fb.control(x.longitude),
          });
          (<FormArray>this.routeForm.get('list')).push(obj);

        })
      }

    })
  }

  routeSubmit(routeForm: any) {
    let value = routeForm.value;
    let validate: Boolean = false;
    value.list.forEach((m, i) => Object.assign(m, { "order_stop": i + 1, "drop_order_stop": i + 1, "route_stop_status": "Active" }));
    value.list.forEach(e => {
      if (e.stop_name.trim() == '' || e.pickup_time == '' || e.drop_time == '' || e.latitude == '' || e.longitude == '') {
        validate = true;
      }
    });
    if (validate) {
      this.toastr.warning("Please enter mandatory fields");
      return;
    }
    let payload = {
      "route_id": value.route_id,
      "stops": value.list,
      "created_on": moment().format('MM-DD-YYYY H:mm:ss '),
      "created_by": localStorage.getItem('UserId'),
      "modified_on": moment().format('MM-DD-YYYY H:mm:ss '),
      "modified_by": localStorage.getItem('UserId'),
      "tnt_code": localStorage.getItem('TenantCode'),
    }
    this.CommonService.postCall('Transport/CreateRouteStop', payload).subscribe((res: any) => {
      this.load();
      this.toastr.success("Stop Added Successfully");
      document.getElementById('close')?.click();
    }, err => {
      this.toastr.error(err.error ? err.error : 'Not Added')
    })
  }


  close() {
    this.myForm.reset();
    this.editData = {};
    this.isEdit = null;
  }

  close1() {
    // this.routeForm.controls['list'] = this.fb.array([]);
    // this.routeForm.reset();
    this.clearFormArray((<FormArray>this.routeForm.get('list')))
    this.getRouteRes = [];
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }

}
