import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { combineLatest } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { startWith } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-employee-sales',
  templateUrl: './employee-sales.component.html',
  styleUrls: ['./employee-sales.component.css']
})
export class EmployeeSalesComponent implements OnInit {
  table: Array<any> = [];
  myForm: FormGroup;
  designation: Array<any> = [];
  employee: Array<any> = [];
  updateList: Array<any> = [];
  isEdit: boolean = false;
  isCampus: boolean = false;
  employees: Array<any> = [];
  items: Array<any> = [];
  ucost: any;
  addItem: any = {
    item: 0,
    cost: 0,
    quantity: 0,
    total_amount: 0
  };
  editData: any;
  total_cost: any;
  empId: any;
  labelName: any = {};
  headingName: any = {};
  workItemId: any;

  constructor(private active: ActivatedRoute,private ref: ChangeDetectorRef, private CommonService: CommonService, private toastr: ToastrService, private fb: FormBuilder) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadEmployee();
    this.designationDropdown();
    this.loadItems();
    this.getLabels();
    this.loadHeadings();
  }


  ngOnInit(): void {
    this.myForm = this.fb.group({
      designation_id: ['', Validators.required],
      item_id: [''],
      employee_id: ['', Validators.required],
      description: ['', Validators.required],
      amounts: ['', Validators.required],
      list: this.fb.array([]),

    });
    this.add();
  }
  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  loadEmployee() {
    let payLoad = {
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('EmsEmployeeSales/GetList', payLoad).subscribe((res: any) => {
      this.table = res;
    })
  }

  designationDropdown() {
    let payLoad = {
      "TENANT_CODE": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('Registration/GetRolesByTenantCode', payLoad).subscribe((res: any) => {
      this.designation = res;
    })
  }


  changeDesignation(id) {
    let payLoad = {
      "TenantCode": localStorage.getItem('TenantCode'),
      "objUserinrole": { "RoleId": id }
    }
    this.CommonService.postCall('Registration/UserRolesChange', payLoad).subscribe((res: any) => {
      this.employee = res;
    })
  }


  employeeTable(eId: any) {

    this.empId = eId;
    this.isCampus = true;
    let payload = {
      "employee_id": eId
    }
    this.CommonService.postCall('EmsConsumption/GetItemsBasedOnEmployeeId', payload).subscribe((res: any) => {
      this.employees = res;
    })
  }



  // employeeTable(eId:any){
  //   this.empId = eId;

  //   this.isCampus = true;
  //   let payLoad={
  //     "employee_id":eId
  //   }
  //   this.CommonService.postCall('EmsConsumption/GetItemsBasedOnEmployeeId',payLoad).subscribe((res:any)=>{
  //     this.employees=res;
  //   })
  // }


  getOptionGroup(data) {
    let newGroup = this.fb.group({
      item_id: [data.item_id ? data.item_id : Number, Validators.required],
      cost: [data.cost ? data.cost : Number, Validators.required],
      quantity: [data.quantity ? data.quantity : Number, Validators.required],
      total_amount: [data.total_amount ? data.total_amount : Number, Validators.required],
    });

    const priceCtrl = newGroup.get('cost');
    const quantityCtrl = newGroup.get('quantity');
    const amountCtrl = newGroup.get('total_amount');
    const TotalCtrl = this.myForm.get('total_cost');
    combineLatest(priceCtrl.valueChanges.pipe(startWith(priceCtrl.value)),
      quantityCtrl.valueChanges.pipe(startWith(quantityCtrl.value))).subscribe(([cost, quantity]) =>
        amountCtrl.setValue(cost * quantity))

    if (this.myForm.get('list')) {
      this.myForm.get('list').valueChanges.subscribe(values => {
        this.total_cost = 0;
        const ctrl = <FormArray>this.myForm.controls['list']
        ctrl.controls.forEach(x => {
          // let parsed = parseInt(x.get('estimatedTotal').value)
          this.total_cost += x.get('total_amount').value;
          this.ref.detectChanges()
        })
      })
    }
    return newGroup

  }

  initItemRows() {
    return this.fb.group({})
  }

  add(data = {}) {
    const arrayControl = <FormArray>this.myForm.controls['list'];
    let grp: FormGroup = this.getOptionGroup(data)
    arrayControl.push(grp)
  }

  delete() {
    const arrayControl = <FormArray>this.myForm.controls['list'];
    let index = arrayControl.length;
    arrayControl.removeAt(index - 1)
    // this.isEdit=true
  }


  isVisable() {
    let arrayControl = <FormArray>this.myForm.controls['list'];
    if (arrayControl.length && arrayControl.length > 0) {
      return true
    }
    else {
      return false
    }
  }


  isAdd() {

    let arrayControl = <FormArray>this.myForm.controls['list'];
    if (arrayControl.length && arrayControl.length > 25) {
      return false
    }
    else {
      return true
    }

  }


  ItemSum(sum: any) {

  }


  loadItems() {
    this.items = [];
    this.CommonService.postCall('InvetoryItems/InventoryItemsCampus', { "tnt_code": localStorage.getItem('TenantCode') }).subscribe((res: any) => {
      this.items = res;
    })
  }
  priceAmount(event) {
    // let ovj  = this.items.filter(x => x.item_id == event.target.value);
    //  ovj.forEach(e => {
    //   this.ucost = e.unit_price
    //  });

    //  console.log(ovj);

  }

  onSubmit(myForm: any) {
    let value: any = myForm.value;
    let list = [];
    myForm.value.list.forEach(e => {
      let item: any = '';
      Object.keys(e).map((key, i) => {
        let value = e[key];
        if (i > 0) {
          if (value) {
            item = item ? item + ',' + i : i;
          }
        }
      })
      let obj = {
        "item_id": e.item_id,
        "cost": e.cost,
        "quantity": e.quantity,
        "total_amount": e.total_amount
        // "floor_name": e.floor_name,
        // "amenities": item
      }
      list.push(obj);
    });
    let payload = {


      "designation_id": value.designation_id,
      "employee_id": value.employee_id,
      "description": value.description,
      "total_amount": value.amounts,
      "SalesEmployeeDetails": list,
      "tnt_code": localStorage.getItem('TenantCode'),
      "created_by": localStorage.getItem('UserId'),
    }
    if (this.isEdit) {
      value.modified_by = localStorage.getItem('UserId');
      // value['room_type_id'] = this.editData.room_type_id;
      payload['sales_employee_id'] = this.editData.sales_employee_id;

      this.CommonService.postCall('EmsEmployeeSales/Update', payload).subscribe((res: any) => {
        this.loadEmployee();
        this.toastr.success("Updated Successfully");
        document.getElementById('md_close').click();


      }, err => {
        this.toastr.error(err.error ? err.error : 'Not Updated')
      })
    } else {

      this.CommonService.postCall('EmsEmployeeSales/Create', payload).subscribe((res: any) => {
        this.loadEmployee();
        this.toastr.success("EmployeeSales Created Successfully");
        document.getElementById('md_close').click();


      }, err => {
        this.toastr.error(err.error ? err.error : 'Campuslist Not created')

      })

    }
  }

  edit(employeeid) {
    this.editData = employeeid;
    this.isEdit = true;
    this.myForm.reset();
    let payLoad = {
      "sales_employee_id": employeeid
    }
    this.CommonService.postCall('EmsEmployeeSales/Get', payLoad).subscribe((res: any) => {
      this.updateList = res.SalesEmployeeDetails;
      this.total_cost;
      if (res instanceof Array && res.length) {
        this.editData = res[0];
        this.dataTransfer(res[0]);
      } else {
        this.editData = res;
        this.dataTransfer(res)
      }
      // this.campusTable(this.editData.campus_id)
      this.changeDesignation(this.editData.designation_id);

    })
  }

  dataTransfer(data) {
    let ctrls = this.myForm.controls;
    // Object.keys(ctrls).map(key => {
    //   let ctrl = ctrls[key];
    //   ctrl?.setValue(data[key])
    // })
    ctrls['designation_id'].setValue(data['designation_id']);
    ctrls['employee_id'].setValue(data['employee_id']);
    ctrls['description'].setValue(data['description']);
  }

  close() {
    this.isEdit = false;
    this.myForm.reset();
    this.editData = {};
    this.isCampus = null;
  }
}
