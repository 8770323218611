<div class="page-wrapper">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-8 pe-lg-4 ps-lg-1">
                    <div *ngFor="let item of cources">
                        <div class="p-3 custom-breadcrumb">
                            <nav aria-label="breadcrumb ">
                                <ol class="breadcrumb mb-0 p-0">
                                    <li class="breadcrumb-item"><a href="javascript:;" style="color:#5A5A5A     "><i
                                                class="fas fa-long-arrow-alt-left me-2"></i> Enrolled Courses</a>
                                    </li>
                                    <li class="breadcrumb-item active"
                                        style="font-size: 15px; font-weight: 500; color:#4c5258" aria-current="page">
                                        {{item.COURSE_NAME}}</li>
                                </ol>
                            </nav>
                        </div>
                        <div class=" p-3 pt-0">
                            <div class="d-flex justify-content-between" style="font-size:13px;font-weight: 500 ;">
                                <span class="pb-2">Course : {{item.COURSE_NAME}} </span>
                                <span class="text-primary pb-2">70%</span>
                            </div>
                            <div class="progress" style="height: 0.5rem;">
                                <div class="progress-bar" role="progressbar" style="width: 75%;" aria-valuenow="75"
                                    aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-12 mb-3">
                            <div class="card">
                                <div class="card-body p-0">
                                    <video width="100%" height="100%" controls>
                                        <!-- <source src="movie.mp4" type="video/mp4"> -->
                                    </video>



                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="card enrolled-course">
                                <div class="card-body px-0 pb-0 pt-0">
                                    <ul class="nav nav-pills" id="pills-tab" role="tablist"
                                        style="justify-content: space-between;">
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link px-4 py-2 active" id="pills-content-tab"
                                                data-bs-toggle="pill" data-bs-target="#pills-content" type="button"
                                                role="tab" aria-controls="pills-content"
                                                aria-selected="true">Content</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link px-4 py-2" id="pills-faqs-tab" data-bs-toggle="pill"
                                                data-bs-target="#pills-faqs" type="button" role="tab"
                                                aria-controls="pills-faqs" aria-selected="false">FAQ’s</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link px-4 py-2" id="pills-forums-tab"
                                                data-bs-toggle="pill" data-bs-target="#pills-forums" type="button"
                                                role="tab" aria-controls="pills-forums"
                                                aria-selected="false">Forums</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link px-4 py-2" id="pills-assignments-tab"
                                                data-bs-toggle="pill" data-bs-target="#pills-assignments" type="button"
                                                role="tab" aria-controls="pills-assignments"
                                                aria-selected="false">Assignments</button>
                                        </li>
                                        <li class="nav-item" role="presentation" *ngIf="false">
                                            <button class="nav-link px-4 py-2" id="pills-notes-tab"
                                                data-bs-toggle="pill" data-bs-target="#pills-notes" type="button"
                                                role="tab" aria-controls="pills-notes"
                                                aria-selected="false">Notes</button>
                                        </li>
                                    </ul>
                                    <!--tabs content start-->
                                    <div class="tab-content" id="pills-tabContent">
                                        <div class="tab-pane fade show active p-3" id="pills-content" role="tabpanel"
                                            aria-labelledby="pills-content-tab">
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <label for="sel1">Course Name : </label>
                                                    <select [(ngModel)]='courceId ' id="sel1" [disabled]="isParam"
                                                        class="form-control form-control-sm shadow-sm rounded-lg"
                                                        (change)="courceChange()">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let course of cources"
                                                            [value]='course.COURSE_ID'>
                                                            {{course.COURSE_NAME}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-md-3">
                                                    <label for="sel1">Course Schedule :</label>
                                                    <select [(ngModel)]='schedulId ' [disabled]="isParam" id="sel1"
                                                        class="form-control form-control-sm shadow-sm rounded-lg"
                                                        (change)="schedulChange1()">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let schedul of scheduls"
                                                            [value]='schedul.COURSESHD_ID'>
                                                            {{schedul.COURSESHD_NAME}}</option>
                                                    </select>
                                                </div>

                                            </div>
                                            <div class="accordion" id="accordionExample">
                                                <div class="accordion-item">
                                                    <br>

                                                    <div *ngIf="courseNames.length">
                                                        <h2 class="accordion-header" [attr.id]="'headingOne'+ i"
                                                            *ngFor="let item of courseNames;let i=index">
                                                            <button
                                                                class="accordion-button      d-flex justify-content-between"
                                                                type="button" data-bs-toggle="collapse"
                                                                [attr.data-bs-target]="'#collapse_'+i"
                                                                aria-expanded="true"
                                                                [attr.aria-controls]="'collapse_'+i"
                                                                (click)="selectChapter(item.ChapterId,i)">

                                                                <span class="ms-4 ps-2 title"> <i
                                                                        class="fas fa-check-circle me-2 check-circle"></i>{{item.ChapterName}}</span>

                                                            </button>
                                                            <div [attr.id]="'collapse_'+i" class=" collapse show"
                                                                [attr.aria-labelledby]="'headingOne'+i"
                                                                data-bs-parent="#accordionExample">
                                                                <div class="accordion-body align-middle "
                                                                    *ngIf="Materialnames.length">

                                                                    <a href="javascript:;"
                                                                        *ngFor="let item of Materialnames">
                                                                        <div>
                                                                            <span class="ms-4 ps-2 text"> <i
                                                                                    class="fas fa-check-circle me-2 check-circle"></i>
                                                                                {{item.MATERIAL_NAME}}</span>
                                                                            <span class="title float-end">
                                                                                <button
                                                                                    *ngIf="item.ACTIONTEXT=='Download'"
                                                                                    (click)="download(item)"
                                                                                    class="btn btn-sm btn-outline-primary pl-3 pr-3">Download
                                                                                </button>
                                                                                <button
                                                                                    *ngIf="item.ACTIONTEXT!='Download'"
                                                                                    (click)="action(item)"
                                                                                    class="btn btn-sm btn-outline-success">{{item.ACTIONTEXT}}
                                                                                </button>
                                                                            </span>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </h2>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                        <div class="tab-pane fade pt-3 px-0" id="pills-faqs" role="tabpanel"
                                            aria-labelledby="pills-faqs-tab">
                                            <div class="text-right" id="faqButton" *ngIf="roleId==2">
                                                <i class="Faq">Click to Create a new FAQ'S</i> <button class="btn btn-sm btn-save" data-bs-toggle="modal"
                                                data-bs-target="#myModalFaq"> Create +</button>
                                                <hr class="line">
                                            </div>
                                         

                                            <div *ngIf="roleId==2||roleId==3" class="accordion accordion-flush"
                                                id="accordionFlushExample">
                                                <div class="accordion-item" *ngFor="let item of loadFaq">
                                                    <h2 class="accordion-header" id="flush-headingOne">
                                                        <button class="accordion-button collapsed " type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#flush-collapseOne" aria-expanded="false"
                                                            aria-controls="flush-collapseOne">
                                                            <span> {{item.HEADER}}</span>
                                                        </button>
                                                    </h2>
                                                    <div id="flush-collapseOne" class="accordion-collapse collapse"
                                                        aria-labelledby="flush-headingOne"
                                                        data-bs-parent="#accordionFlushExample">
                                                        <div class="accordion-body">{{item.Text}}</div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>



                                      




                                        <div class="tab-pane fade p-3" id="pills-forums" role="tabpanel"
                                            aria-labelledby="pills-forums-tab">
                                            <div class="row " *ngFor='let item of events'>
                                                <div class="col-xl-12 px-4 mb-4" data-bs-toggle="modal"
                                                    data-bs-target="#discussionModal"
                                                    (click)="getDiscussion(item.ForumId )">
                                                    <div class="card shadow-sm px-0 px-md-4"
                                                        style="background-color: #F8FCFF;">
                                                        <div class="card-body pb-0">
                                                            <span>{{item.TOPIC}}</span>
                                                            <p class="mt-3">{{item.DESCRIPTION}}.</p>
                                                            <hr class="mb-2">
                                                            <div class="card-user">
                                                                <ul class="list-inline" style="list-style-type:none;">
                                                                    <li class="list-inline-item me-5"><a href="#"><img
                                                                                src="../../../assets/img/01.jpg"
                                                                                class="img-rounded me-1"><span
                                                                                class="user-txt ms-2"> </span>
                                                                            <span class="name"> </span></a>
                                                                    </li>
                                                                    <li class="list-inline-item text-center "><span
                                                                            class="user-txt-1 ms-5 ms-xl-0"></span>
                                                                    </li>
                                                                    <li class="list-inline-item float-end mt-xl-1">
                                                                        <i class="far fa-comment-alt user-txt-1 "></i>
                                                                        <span class="user-txt-1"> </span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>




                                        </div>
                                        <div class="tab-pane fade pt-3 pb-3" id="pills-assignments" role="tabpanel"
                                            aria-labelledby="pills-assignments-tab">
                                            <div class="row">
                                                <div class="col-xl-12">
                                                    <nav>
                                                        <div class="nav nav-tabs mx-4 border-0 assignment-tab"
                                                            id="nav-tab" role="tablist">
                                                            <button class="nav-link active" id="nav-home-tab"
                                                                data-bs-toggle="tab" data-bs-target="#nav-home"
                                                                type="button" role="tab" aria-controls="nav-home"
                                                                aria-selected="true">Recently Assigned</button>
                                                            <button class="nav-link" id="nav-profile-tab"
                                                                data-bs-toggle="tab" data-bs-target="#nav-profile"
                                                                type="button" role="tab" aria-controls="nav-profile"
                                                                aria-selected="false">Previous Assignments</button>

                                                        </div>
                                                    </nav>
                                                    <br>
                                                    <div class="tab-content" id="nav-tabContent">
                                                        <div class="tab-pane fade show active" id="nav-home"
                                                            role="tabpanel" aria-labelledby="nav-home-tab">
                                                            <div class="row" style="padding-left: 33px;">
                                                                <div class="col-md-3">
                                                                    <label for="sel1">Course Name : </label>
                                                                    <select [(ngModel)]='courceId ' id="sel1"
                                                                        (change)="courceChange()"
                                                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                                                        <option value="" [selected]="true">select
                                                                        </option>
                                                                        <option *ngFor="let course of cources"
                                                                            [value]='course.COURSE_ID'>
                                                                            {{course.COURSE_NAME}}
                                                                        </option>

                                                                    </select>
                                                                </div>
                                                                <div class="col-md-3">
                                                                    <label for="sel1">Course Schedule :</label>
                                                                    <select [(ngModel)]='schedulId ' id="sel1"
                                                                        (change)="schedulChange()"
                                                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                                                        <option value="" [selected]="true">select
                                                                        </option>
                                                                        <option *ngFor="let schedul of scheduls"
                                                                            [value]='schedul.COURSESHD_ID'>
                                                                            {{schedul.COURSESHD_NAME}}</option>

                                                                    </select>
                                                                </div>
                                                                <div class="col-xl-12 mt-4" *ngFor="let item of table">
                                                                    <div class="card  shadow-sm">
                                                                        <div class="card-body mx-4">
                                                                            <span>{{item.ASSIGNMENT_COURSE_SCHEDULE}}</span>
                                                                            <p class="mb-1 mt-2">
                                                                                {{item.ASSIGNMENT_NAME}}</p>
                                                                            <!-- <li class="list-inline-item me-5 mb-0"><a
                                                                                    href="javascript:;"
                                                                                    class="far fa-file-pdf text-danger"
                                                                                    (click)="download(item.ASSIGNMENT_UPLOAD)">Uploaded
                                                                                    Assignment </a></li> -->

                                                                                    <ul class="list-inline mb-0"
                                                                                    style="list-style-type:none;">
    
                                                                                    <li class="list-inline-item me-5"><a
                                                                                            *ngIf="item.EV_ASSIGNMENT_UPLOAD"
                                                                                            href="javascript:;"
                                                                                            (click)="download1(item.ASSIGNMENT_UPLOAD)"
                                                                                            class="far fa-file-pdf text-danger">
                                                                                            Uploaded Assignment</a>
                                                                                    </li>
                                                                                    <li class="list-inline-item me-5"><a
                                                                                            *ngIf="item.EV_ASSIGNMENT_UPLOAD"
                                                                                            href="javascript:;"
                                                                                            (click)="downloadEvaluateAssignment(item)"
                                                                                            class="far fa-file-pdf text-danger">
                                                                                            Evaluated Assignment</a>
                                                                                    </li>
    
                                                                                    <li class="list-inline-item me-5"
                                                                                        href="javascript:;"><span
                                                                                            class="far fa-file-pdf text-danger"
                                                                                            *ngIf="item.AS_UPLOAD=='Pending'"
                                                                                            data-bs-toggle="modal"
                                                                                            data-bs-target="#uploadModal"
                                                                                            (click)="edit(item)"><a href="javascript:;">Upload Your Assignment</a>
                                                                                            
                                                                                        </span>
    
                                                                                    </li>
    
                                                                                    <li
                                                                                        class="list-inline-item text-center ">
                                                                                        <span
                                                                                            class="user-txt-2 ms-0 ms-xl-5">Deadline
                                                                                            :
                                                                                            {{item.ASSIGNMENT_END_DATE
                                                                                            |date:'dd-MM-yyyy'}}</span>
                                                                                        &nbsp;
                                                                                        <span>{{item.AS_UPLOAD}}</span>
                                                                                    </li>
    
                                                                                    <br><br>
    
    
    
                                                                                    <!-- <li class="list-inline-item me-5">
                                                                                        <span class="user-txt-2 ms-0 ms-xl-5" *ngIf="item.AS_UPLOAD=='Pending'" data-bs-toggle="modal"
                                                                                        data-bs-target="#myModal" (click)="edit(item)">
                                                                                        Upload Your Assignment
                                                                                        </span>
                                                                                    </li> -->
    
                                                                                </ul>
                                                                            <br>


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="tab-pane fade" id="nav-profile" role="tabpanel"
                                                            aria-labelledby="nav-profile-tab">
                                                            <div class="row">
                                                                <div class="col-xl-12 mt-4">
                                                                    <div class="card  shadow-sm">
                                                                        <div class="card-body mx-4">
                                                                            <span>Assignment for Algebra (Internal
                                                                                Exam)</span>
                                                                            <p class="mb-1 mt-2">by Prof.Ramya</p>
                                                                            <p>Download and fill your answers in the
                                                                                document attached below. Each
                                                                                question
                                                                                carries 10 marks, total 50
                                                                                marks.Late
                                                                                submission of assignment is
                                                                                considered
                                                                                as absent </p>
                                                                            <ul class="list-inline mb-0"
                                                                                style="list-style-type:none;">
                                                                                <li class="list-inline-item me-5"><a
                                                                                        href="#"><i
                                                                                            class="far fa-file-pdf text-danger"></i>
                                                                                        Assignment_documentword</a>
                                                                                </li>
                                                                                <li
                                                                                    class="list-inline-item text-center ">
                                                                                    <span
                                                                                        class="user-txt-2 ms-0 ms-xl-5">Deadline
                                                                                        : 18-04-2022 06:00
                                                                                        PM*</span>
                                                                                </li>

                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-xl-12 mt-4 mb-4">
                                                                    <div class="card  shadow-sm">
                                                                        <div class="card-body mx-4">
                                                                            <span>Assignment for Algebra (Internal
                                                                                Exam)</span>
                                                                            <p class="mb-1 mt-2">by Prof.Ramya</p>
                                                                            <p>Download and fill your answers in the
                                                                                document attached below. Each
                                                                                question
                                                                                carries 10 marks, total 50
                                                                                marks.Late
                                                                                submission of assignment is
                                                                                considered
                                                                                as absent </p>
                                                                            <ul class="list-inline mb-0"
                                                                                style="list-style-type:none;">
                                                                                <li class="list-inline-item me-5"><a
                                                                                        href="#"><i
                                                                                            class="far fa-file-pdf text-danger"></i>
                                                                                        Assignment_documentword</a>
                                                                                </li>
                                                                                <li
                                                                                    class="list-inline-item text-center ">
                                                                                    <span
                                                                                        class="user-txt-2 ms-0 ms-xl-5">Deadline
                                                                                        : 18-04-2022 06:00
                                                                                        PM*</span>
                                                                                </li>

                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="tab-pane fade p-3" id="pills-notes" role="tabpanel"
                                            aria-labelledby="pills-notes-tab">
                                            <div class="row">
                                                <div class="col-xl-12 ">
                                                    <div class="card border-0 bg-white">
                                                        <div class="card-body mx-2">
                                                            <div class="py-2"><i
                                                                    class="fas fa-file-word text-primary me-2"></i>
                                                                Uploaded files or noted notes will be shown here
                                                            </div>
                                                            <div class="py-2"><i
                                                                    class="fas fa-file-pdf text-warning  me-2"></i>
                                                                Uploaded files or noted notes will be shown here
                                                            </div>
                                                            <div class="py-2"><i
                                                                    class="fas fa-file-alt me-2 text-primary"></i>
                                                                Uploaded files or noted notes will be shown here
                                                            </div>

                                                        </div>

                                                        <div class="card-footer bg-white border-0">
                                                            <div class="input-group mb-3">
                                                                <span class="input-group-text" id="basic-addon1"><a
                                                                        href="#"><i
                                                                            class="far fa-grin me-3 text-secondary"></i></a><a
                                                                        href="#"> <i
                                                                            class="fas fa-paperclip text-secondary"></i></a></span>
                                                                <input type="text" class="form-control"
                                                                    placeholder="Type a message" aria-label="Username"
                                                                    aria-describedby="basic-addon1">
                                                                <span class="input-group-text"><i
                                                                        class="fas fa-microphone text-secondary"></i></span>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <!--tabs content end-->
                                </div>
                            </div>
                        </div>

                    </div>



                </div>


                <div class="col-xl-4 bg-white px-0">
                    <div class="mx-3 mt-4">
                        <span class="align-middle"><i class="far fa-folder fa-2x me-2"></i></span> <span
                            class="resouces-title">Resources</span>
                    </div>
                    <div class="accordion mt-3" id="accordionExample-1">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne-1" aria-expanded="true" aria-controls="collapseOne-1">
                                    1. Fundamental characteristics of Modern web standards
                                </button>
                            </h2>
                            <div id="collapseOne-1" class="accordion-collapse collapse show"
                                aria-labelledby="headingOne" data-bs-parent="#accordionExample-1">
                                <div class="accordion-body  pt-0">
                                    <ul class=" mb-0"
                                        style="list-style-type:none; line-height: 1.5rem; padding-left: 1rem;">
                                        <li class=" "><i class="far fa-file-alt me-2 text-primary"></i><a
                                                href="javascript:;">1.1
                                                Resource_name.pdf</a></li>
                                        <li class=""><i class="far fa-file-alt me-2 text-primary"></i><a
                                                href="javascript:;">1.1
                                                Resource_name.pdf</a> </li>

                                    </ul>


                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingTwo">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo-2" aria-expanded="false" aria-controls="collapseTwo-2">
                                    2. Modern problems with Artificial Intelligence
                                </button>
                            </h2>
                            <div id="collapseTwo-2" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                data-bs-parent="#accordionExample-1">
                                <div class="accordion-body pt-0">
                                    <ul class=" mb-0"
                                        style="list-style-type:none; line-height: 1.5rem; padding-left: 1rem;">
                                        <li class=" "><i class="far fa-file-alt me-2 text-primary"></i><a
                                                href="javascript:;">1.1
                                                Resource_name.pdf</a></li>
                                        <li class=""><i class="far fa-file-alt me-2 text-primary"></i><a
                                                href="javascript:;">1.1
                                                Resource_name.pdf</a> </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingThree">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree-3" aria-expanded="false"
                                    aria-controls="collapseThree-3">
                                    3. Fundamental characteristics of Modern web standards
                                </button>
                            </h2>
                            <div id="collapseThree-3" class="accordion-collapse collapse" aria-labelledby="headingThree"
                                data-bs-parent="#accordionExample-1">
                                <div class="accordion-body pt-0">
                                    <ul class=" mb-0"
                                        style="list-style-type:none; line-height: 1.5rem; padding-left: 1rem;">
                                        <li class=" "><i class="far fa-file-alt me-2 text-primary"></i><a
                                                href="javascript:;">1.1
                                                Resource_name.pdf</a></li>
                                        <li class=""><i class="far fa-file-alt me-2 text-primary"></i><a
                                                href="javascript:;">1.1
                                                Resource_name.pdf</a> </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--end page wrapper -->
    <!--start overlay-->

</div>



<div class="modal fade" id="discussionModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Discussion</h5>
                <button type="button" (click)="close()" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>


            <!-- Modal body -->
            <div class="modal-body">
                <div *ngIf='isDiscussion'>
                    <div class="container">
                        <div class="row col-12">
                            <div class="col-12">
                                <h3>{{discussion.TOPIC}}</h3>
                                <h5 class="description">{{discussion.DESCRIPTION}}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer">

                <button type="button" class="btn btn-danger" id='md_close' (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>





<div class="modal fade" id="uploadModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Upload Assignment</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <input type="file" (change)="change($event)" accept=".doc,.docx,.pdf">
                <br>
                <span>
                    Please upload pdf and doc file formats only.
                </span>
            </div>
            <div class="modal-footer">
                <button type="button" id="md_close" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" (click)="save()" class="btn btn-primary">Save</button>
            </div>
        </div>
    </div>
</div>






<span data-bs-toggle="modal" data-bs-target="#myModal" id='openpopup'></span>
<div class="modal fade" id="myModal" role="dialog" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog  modal-lg">

        <!-- Modal content-->
        <div class="modal-content modal_class">
            <div class="modal-header py-2">
                <h4 class="modal-title w-100"></h4>
                <button type="button" class="close" data-bs-dismiss="modal" (click)="close()"><i
                        class="fas fa-times"></i></button>
            </div>
            <div class="modal-body">
                <div *ngIf="iframe">
                    <iframe class="iframe" [src]="iframe | safe" title="Dhanush"></iframe>
                </div>
                <div>
                    <video *ngIf="videoLink" width="320" height="240" controls>
                        <!-- <source *ngIf="videoLink" src="{{videoLink}}" type="video/mp4"> -->
                        <source [src]="videoLink">
                    </video>
                    <audio *ngIf="audio" controls>
                        <source [src]="audio">
                    </audio>
                </div>
                <div *ngIf='visualContent'>
                    <div [innerHTML]='visualContent'></div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-danger" data-bs-dismiss="modal" id="md_close"
                    (click)="close()">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="myModalFaq" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header py-2">
                <h6  class="modal-title w-100"> Create FAQs</h6>
                <!-- <h6 *ngIf="isEdit" class="modal-title w-100"> Edit FAQ</h6> -->
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"
                    aria-label="Close"><i class="fas fa-times"></i></button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Question <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <textarea placeholder="" class="form-control" formControlName="Header"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Answer <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <textarea placeholder="" class="form-control"
                                        formControlName="ContentText"></textarea>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button"  class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                <!-- <button type="button" *ngIf="isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button> -->
                <button type="button" class="btn btn-danger btn-sm" id='md_close' (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>

    </div>
</div>
