<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.BookIssueReturnReport}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="form-group">
                                            <div class="row">

                                                <div class="col-md-3">
                                                    <label for="sel1"> {{labelName.Date}}<span
                                                            class="text-danger">*</span></label>
                                                    <div class="input-group">
                                                        <input [max]="Date" type="date" placeholder=""
                                                            class="form-control" [(ngModel)]="date"
                                                            (change)="loadTable()">
                                                    </div>
                                                </div>

                                                <div class="col-md-3">
                                                    <label for="sel1"> {{labelName.Course}}</label>
                                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                        id="sel1" [(ngModel)]="courseId">
                                                        <option value="" selected>Select
                                                        </option>
                                                        <option *ngFor="let course of courses"
                                                            [value]='course.COURSE_ID'>
                                                            {{course.COURSE_NAME}}
                                                        </option>
                                                    </select>
                                                </div>


                                                <div class="col-md-3">
                                                    <label for="sel1">{{labelName.ReportType}}</label>
                                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                        [(ngModel)]="type" id="sel1" (change)="loadTable()">
                                                        <option value="" selected>Select </option>
                                                        <option value="1">Issue Date</option>
                                                        <option value="2">Return Date</option>
                                                    </select>
                                                </div>


                                                <div *ngIf="table.length" class="table-responsive pt-4">
                                                    <table id="patergraph" datatable [dtOptions]="dtOptions"
                                                        class=" table-striped table-bordered mt-2 pt-2 table-sm small display"
                                                        style="width:100%">
                                                        <thead class="top"
                                                            style="background-color: #624FD7; color:#ffffff;">
                                                            <tr>
                                                                <th>Student enrollment number</th>
                                                                <th>Student Name</th>
                                                                <th>Accession Number</th>
                                                                <th>Book Title</th>
                                                                <th>Subject</th>
                                                                <th>Issue/Return Date</th>
                                                            </tr>
                                                        </thead>


                                                        <tbody>
                                                            <tr *ngFor="let item of table">
                                                                <td>{{item.enrollment_no}}</td>
                                                                <td>{{item.student_name}}</td>
                                                                <td>{{item.accession_text}}</td>
                                                                <td>{{item.book_title}}</td>
                                                                <td>{{item.subject_name}}</td>
                                                                <td>{{item.issue_return_date}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>