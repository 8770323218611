import { Component, Input, OnInit } from '@angular/core';
import { EditUserRegistrationComponent } from 'src/app/pages/edit-user-registration/edit-user-registration.component';
import { FileuploadService } from 'src/app/services/fileupload.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service'

@Component({
  selector: 'app-about-me',
  templateUrl: './about-me.component.html',
  styleUrls: ['./about-me.component.css']
})
export class AboutMeComponent implements OnInit {
  @Input() parent: EditUserRegistrationComponent;
  @Input() editData: any = {};
  changed: boolean = false;
  file: File
  fileName: string;
  imageName:string;
  personalData:any={};
  userId: number;
  labelName:any={};
  constructor(private FileuploadService:FileuploadService,private CommonService: CommonService, private toastr: ToastrService) { 
    this.getLabels();
  }

  ngOnInit(): void {
    this.parent.childs['aboutMe']=this
  }
  ngOnChanges(){
    if(Object.keys(this.editData).length&&!this.changed){
      this.changed=true;
      this.personalData=this.editData['Personal'].length&&this.editData['Personal'][0];
      this.fileName = this.editData['UserImage']?.userfrimages;
      this.userId=this.personalData['UserId'];
    }
  }

  
  getLabels() {
    this.labelName ={};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${localStorage.getItem('regWorkItem')}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName=res;
    })
  }
  changeFile(event) {
    if (event.target.files.length > 0) {
      let file: File = event.target.files[0];
      let name = file.name;
      let filetype = name.split('.').pop()
      let types: Array<any> = ['png', 'jpg', 'PNG', 'jpeg', 'gif', "JPEG"]
      let check = types.includes(filetype);
      if (check) {
        this.file = file;
      }
      else {
        // alert(' Please upload pdf and doc file formats only.')
        this.parent.toastr.warning('Please upload image file formats only.')
        event.target.value = ''
      }
    }
  }
  
  upload() {
   
    if(!this.file) return
    let  base64String:any;
    var reader = new FileReader();
    console.log("next");
      let self=this;
    reader.onload = function () {
      base64String = reader.result;       
        self.fileName=base64String;

        let payLoad = {
          "tenantcode": localStorage.getItem('TenantCode'),
        "userfrimages": self.fileName,
        "USERID": self.userId,
        }
        console.log(payLoad)
        self.CommonService.postCall('Registration/InsertUserImage', payLoad).subscribe((res: any) => {
          self.toastr.success("Image Saved Succuessfully");
        }, err => {
          self.toastr.error(err.error ? err.error : 'Image Not Updated')
        })
             
    }
    reader.readAsDataURL(this.file);
   
  
   


    
  }
  getImage(){
    if(!this.fileName) return ''
    if(this.fileName.includes('data:image')){
      return this.fileName
    }else{
      return 'data:image/png;base64,'+this.fileName
    }
  }
}