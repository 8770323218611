<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">Fetch Assigned Books</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="form-group ">
                                            <form class="well form-horizontal" [formGroup]="myForm"
                                                (ngSubmit)="onSubmit(myForm)">
                                                <fieldset>
                                                    <div class="row">
                                                        <div class="col-md-3">
                                                            <label for="sel1"> Course Name<span
                                                                    class="text-danger">*</span><strong>:</strong></label>
                                                            <select name="courseName" id="courseName"
                                                                (change)="courseChange($event)"
                                                                formControlName="courseName"
                                                                class="form-control form-control-sm shadow-sm rounded-lg ">
                                                                <option value="" [selected]="true">Select</option>
                                                                <option *ngFor="let course of courseNameOption"
                                                                    [value]="course.COURSE_ID">{{course.COURSE_NAME}}
                                                                </option>
                                                            </select>
                                                        </div>

                                                        <div class="col-md-3">
                                                            <label for="sel1"> Course Schedule<span
                                                                    class="text-danger">*</span><strong>:</strong></label>
                                                            <select name="courseSchedule" id="courseSchedule"
                                                                (change)="sheduleChange($event)"
                                                                formControlName="courseSchedule"
                                                                class="form-control form-control-sm shadow-sm rounded-lg ">
                                                                <option value="" [selected]="true">Select</option>
                                                                <option
                                                                    *ngFor="let courseSchedule of courseScheduleOption"
                                                                    [value]="courseSchedule.COURSESHD_ID">
                                                                    {{courseSchedule.COURSESHD_NAME}}</option>
                                                            </select>
                                                        </div>

                                                        <div class="col-md-3">
                                                            <label for="sel1">Student<span
                                                                    class="text-danger">*</span><strong>:</strong></label>
                                                            <select name="student" id="student"
                                                                formControlName="student"
                                                                (change)="handleDepartment($event)"
                                                                class="form-control form-control-sm shadow-sm rounded-lg ">
                                                                <option value="" [selected]="true">Select</option>
                                                                <option *ngFor="let student of studentOption"
                                                                    [value]="student.USERID">{{student.FIRSTNAME}}
                                                                </option>
                                                            </select>
                                                        </div>

                                                        <div class="col-md-3">
                                                            <label for="sel1"> Department<span
                                                                    class="text-danger">*</span><strong>:</strong></label>
                                                            <select name="department" id="department"
                                                                formControlName="department"
                                                                class="form-control form-control-sm shadow-sm rounded-lg ">
                                                                <option value="" [selected]="true">Select</option>
                                                                <option *ngFor="let department of departmentOption"
                                                                    [value]="department.DEPARTMENT_ID">
                                                                    {{department.DEPARTMENT_NAME}}</option>
                                                            </select>
                                                        </div>


                                                    </div>


                                                </fieldset>
                                                <br><br>

                                                <div class="text-center">
                                                    <button type="submit" [disabled]="myForm.invalid"
                                                        class="btn btn-sm btn-save">Submit</button>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>