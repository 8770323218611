<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 "> {{headingName.HealthReports}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <form class="well form-horizontal" [formGroup]="myForm"
                                            (ngSubmit)="onSubmit(myForm)">
                                            <fieldset>
                                                <div class="row">
                                                    <div class="form-group col-md-3">

                                                        <div class=" inputGroupContainer">
                                                            <div class="input-group">
                                                                <input placeholder="Select Month"
                                                                    class="form-control form-control-sm shadow-sm rounded-lg"
                                                                    placeholder="Select YYYY/MM"
                                                                    onfocus="(this.type='month')" type="text"
                                                                    formControlName="month">
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-group col-md-3">
                                                        <div class="input-group">
                                                            <select
                                                                class="form-select form-select-sm shadow-sm rounded-lg"
                                                                formControlName="campus_id">
                                                                <option value="" selected>Select</option>
                                                                <!-- <option *ngFor="let item of campusDropdown"
                                                                        [value]="item.campus_id">
                                                                        {{item.campus_name}}</option> -->
                                                            </select>

                                                        </div>
                                                    </div>

                                                    <div class="form-group col-md-3">


                                                        <div class="input-group">
                                                            <select formControlName="class_id"
                                                                class="form-select form-select-sm shadow-sm rounded-lg">
                                                                <option value='' selected>Select </option>
                                                                <!-- <option *ngFor="let course of cources"
                                                                        [value]='course.COURSE_ID'>
                                                                        {{course.COURSE_NAME}}
                                                                    </option> -->
                                                            </select>
                                                        </div>

                                                    </div>



                                                    <div class="form-group col-md-3">


                                                        <div class="input-group">
                                                            <select formControlName="class_id"
                                                                class="form-select form-select-sm shadow-sm rounded-lg">
                                                                <option value='' selected>Select </option>
                                                                <!-- <option *ngFor="let course of cources"
                                                                        [value]='course.COURSE_ID'>
                                                                        {{course.COURSE_NAME}}
                                                                    </option> -->
                                                            </select>
                                                        </div>

                                                    </div>

                                                    <div class="form-group col-md-3 pt-4">
                                                        <div class="input-group">
                                                            <input placeholder="Search To Name"
                                                                class="form-control form-control-sm shadow-sm rounded-lg"
                                                                type="text" formControlName="name">
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3 pt-4">
                                                        <button type="button" 
                                                            (click)="onSubmit(myForm)" class="btn btn-sm  btn-save"><i
                                                                class="fas fa-search"></i></button>
                                                    </div>

                                                </div>
                                            </fieldset>
                                        </form>
                                        <br>

                                        <div class="text-center">
                                            <p *ngIf="!isTable" style="color: red;">
                                                Search for Fees Reports</p>
                                        </div>

                                        <div >
                                            <table id="addStudent" datatable 
                                           
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                      
                                                        <th>Campus Name</th>
                                                        <th>Student Name</th>
                                                        <th> Class </th>
                                                        <th> Section </th>
                                                        <th> Blood Group </th>
                                                        <th> Doctor Name </th>
                                                        <th> Admission </th>
                                                        <th> Created Date </th>
                                                        <th> Status </th>
                                                    </tr>
                                                </thead>


                                                <tbody>
                                                    <tr *ngFor="let item of table;let i =index">
                                                        <td ></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td ></td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>

                                            </table>
                                        </div>


                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
