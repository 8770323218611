import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-vehicle-service',
  templateUrl: './vehicle-service.component.html',
  styleUrls: ['./vehicle-service.component.css']
})
export class VehicleServiceComponent implements OnInit {
  myForm: FormGroup;
  vehicleList: Array<any> = [];
  inspectedByDropdown: Array<any> = [];
  vehicle_id: number;
  rno: any;
  editData: any;
  labelName: any = {};
  headingName: any = {};
  workItemId: any;

  constructor(private route: Router, private fb: FormBuilder, private CommonService: CommonService, private toastr: ToastrService, private active: ActivatedRoute) {
    active.queryParams.subscribe((res) => {
      this.vehicle_id = res.id;
      this.rno = res.rno;
      this.workItemId = res.wId;
    })
    this.getVehicleService();
    this.getInspectedBy();
    this.getLabels();
    this.loadHeadings();

  }


  ngOnInit(): void {
    this.myForm = this.fb.group({
      inspection_month: ['', Validators.required],
      inspected_by: ['', Validators.required],
      // status: ['', Validators.required],
      // inspection_date: ['', Validators.required]

    })
  }
  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  getVehicleService() {
    this.vehicleList = [];
    this.CommonService.postCall('EmsVehicleService/GetList', {}).subscribe((res: any) => {
      res.forEach(e => {
        let obj = {
          // code_comments: "",
          // inspection_date: "",
          // status: false,
          vechile_service_id: e.vechile_service_id,
          vechile_service_name: e.vechile_service_name
        }
        this.vehicleList.push(obj);
      });
    })
  }
  getInspectedBy() {
    this.CommonService.getCall(`EmsVehicleService/GetVechileInspecter/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.inspectedByDropdown = res;
    })
  }

  isflag() {
    this.getVehicleService();
    let ele = [];
    this.CommonService.getCall(`EmsVehicleService/Get/${this.myForm.value.inspection_month}/${this.vehicle_id}`).subscribe((res: any) => {
      res.GetServices.forEach(e => {
        ele = this.vehicleList.filter(x => parseInt(x.vechile_service_id) === parseInt(e.inspection_code));
        ele[0].code_comments = e.code_comments;
        ele[0].inspection_date = moment(e.inspection_date).format('yyyy-MM-DD');
        ele[0].status = e.status;
        ele[0].inspection_id = e.inspection_id;
      });
      this.myForm.patchValue({
        inspected_by: res['inspected_by']
      })
    })
  }

  onSubmit(form: any) {
    let val = form.value;
    let getService = [];
    getService = this.vehicleList.filter(a => a.status == true);
    getService.map(e => e.inspection_code = e.vechile_service_id);
    if (this.vehicleList.filter(a => a.status == true)) { }
    let payload = {
      "inspection_month": val.inspection_month,
      "vehicles_id": this.vehicle_id,
      "inspected_by": val.inspected_by,
      "reported_to": 0,
      "created_by": localStorage.getItem('UserId'),
      "modified_by": localStorage.getItem('UserId'),
      "tnt_code": localStorage.getItem('TenantCode'),
      // "GetServices": getService,
      "GetServices": this.vehicleList
    }
    if (this.vehicleList[0].status == true) {
      this.CommonService.postCall(`EmsVehicleService/Create`, payload).subscribe((res: any) => {
        window.history.back();
        this.toastr.success("Vehicle Services Updated Successfully");
      }, err => {
        this.toastr.error(err.error ? err.error : 'Vehicle Services Not Updated')
      })
    } else {
      this.toastr.warning('please check atleast one service');
    }

  }

  close() {
    window.history.back();
  }

}