<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0"> {{headingName.ViewIDCard}} </h5>
                                        <!-- <h5 *ngIf="isEdit" class="mb-0">Edit Quotation</h5> -->
                                    </div>
                                    <div class="card-body">
                                        <!-- <div class="text-right mb-2">
                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                                data-bs-target="#myModal">View Template </button>
                                        </div> -->
                                        <div class="custom_container">
                                            <div class="row">
                                                <div class="col-xl-8 offset-xl-2">
                                                    <form class="well form-horizontal" [formGroup]="myForm"
                                                        (ngSubmit)="onSubmit1(myForm);onSubmit2(myForm);onSubmit3(myForm);onSubmit4(myForm)">

                                                        <fieldset>
                                                            <div class="form-group row pt-4">
                                                                <label class="col-md-4 control-label">
                                                                    {{labelName.Campus}} <span
                                                                        class="text-danger">*</span></label>
                                                                <div class="col-md-8 inputGroupContainer">
                                                                    <div class="input-group"> <strong>:</strong> &nbsp;
                                                                        <select
                                                                            class="form-select form-select-sm shadow-sm rounded-lg"
                                                                            formControlName="campus_id">
                                                                            <option value="" selected>Select</option>
                                                                            <option *ngFor="let item of campus"
                                                                                [value]='item.campus_id'>
                                                                                {{item.campus_name}}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                    <!-- <div *ngIf="myForm.get('campus_id').touched">
                                                                        <span class="text-danger" *ngIf="myForm.get('campus_id').hasError('required')">
                                                                            This field is required.
                                                                        </span>
                                    
                                                                    </div> -->
                                                                </div>
                                                            </div>
                                                            <div class="form-group row pt-4">
                                                                <label class="col-md-4 control-label">
                                                                    {{labelName.Course}} <span
                                                                        class="text-danger">*</span></label>
                                                                <div class="col-md-8 inputGroupContainer">
                                                                    <div class="input-group"> <strong>:</strong> &nbsp;
                                                                        <select
                                                                            class="form-select form-select-sm shadow-sm rounded-lg"
                                                                            formControlName="course_id"
                                                                            (change)="courseSchedule($any($event).target.value)">
                                                                            <option value="" [selected]="true">Select
                                                                            </option>
                                                                            <option value=""
                                                                                *ngFor="let item of Courses"
                                                                                [value]="item.COURSE_ID">
                                                                                {{item.COURSE_NAME}}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                    <!-- <div *ngIf="myForm.get('campus_id').touched">
                                                                        <span class="text-danger" *ngIf="myForm.get('campus_id').hasError('required')">
                                                                            This field is required.
                                                                        </span>
                                    
                                                                    </div> -->
                                                                </div>
                                                            </div>


                                                            <div class="form-group row pt-4">
                                                                <label class="col-md-4 control-label">
                                                                    {{labelName.CourseSchedule}} <span
                                                                        class="text-danger">*</span></label>
                                                                <div class="col-md-8 inputGroupContainer">
                                                                    <div class="input-group"> <strong>:</strong> &nbsp;
                                                                        <select formControlName="courseschedule_id"
                                                                            (change)="sheduleChange($event)"
                                                                            class="form-select form-select-sm shadow-sm rounded-lg">
                                                                            <option value="" [selected]="true">Select
                                                                            </option>
                                                                            <option value=""
                                                                                *ngFor="let item of courseschedule"
                                                                                [value]="item.COURSESHD_ID">
                                                                                {{item.COURSESHD_NAME}}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                    <!-- <div *ngIf="myForm.get('courseschedule_id').touched">
                                                                        <span class="text-danger" *ngIf="myForm.get('courseschedule_id').hasError('required')">
                                                                            This field is required.
                                                                        </span>
                                    
                                                                    </div> -->
                                                                </div>
                                                            </div>


                                                            <!-- <div class="form-group row pt-4">
                                                                <label class="col-md-4 control-label mt-1 ">
                                                                   {{labelName.Students}}<span class="text-danger">*</span></label>
                                                                  <div class="col-md-8 inputGroupContainer">
                                                                  <div class="form-check" *ngFor="let item of checkboxs">
                                                                    <input class="form-check-input" type="checkbox" id="defaultCheck1" formControlName="student" (change)="changeCheck($event)"
                                                                    [ngModelOptions]="{standalone: true}" [value]="item.USERID" >
                                                                    {{item.FIRSTNAME}}
                                                                  </div>
                                                                </div>
                                                              </div> -->

                                                            <!-- <div class="form-group row pt-4">
                                                                <label class="col-md-4 control-label mt-1 ">
                                                                    {{labelName.Students}}<span
                                                                        class="text-danger">*</span></label>
                                                                <div class="col-md-8 inputGroupContainer"
                                                                    *ngIf="ishide">
                                                                    <div class="form-check">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            id="defaultCheck1" formControlName="student"
                                                                            (change)="checkAll($event)">
                                                                        Select All
                                                                    </div>
                                                                    <div class="form-check"
                                                                        *ngFor="let item of checkboxs">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            id="defaultCheck1" formControlName="student"
                                                                            (change)="changeCheck($event)"
                                                                            [ngModelOptions]="{standalone: true}"
                                                                            [value]="item.USERID"
                                                                            [checked]="item.checked">
                                                                        {{item.FIRSTNAME}}
                                                                    </div>
                                                                </div>
                                                            </div> -->



                                                            <div class="form-group row pt-4">
                                                                <label class="col-md-4 control-label line_2 rem_1">
                                                                    {{labelName.Students}}<span
                                                                        class="text-danger">*</span></label>
                                                                <div class="col-md-8 inputGroupContainer">
                                                                    <div class="input-group">
                                                                        <strong>:</strong> &nbsp;

                                                                        <ng-multiselect-dropdown style="width:96%"
                                                                            formControlName="student"
                                                                            [placeholder]="'select'"
                                                                            [settings]="dropdownSettings"
                                                                            [data]="dropdownListCategory"
                                                                            (onSelect)="onItemSelect($any($event.target).value)"
                                                                            (onSelectAll)="onSelectAll($any($event.target).value)"
                                                                            [(ngModel)]='listOfMembers'>
                                                                        </ng-multiselect-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div class="form-group row pt-4">
                                                                <label class="col-md-4 control-label">
                                                                    {{labelName.SelectPrincipalSignature}}<span
                                                                        class="text-danger">*</span></label>
                                                                <div class="col-md-8 inputGroupContainer">
                                                                    <div class="input-group"> <strong>:</strong> &nbsp;
                                                                        <select formControlName="principal"
                                                                            class="form-select form-select-sm shadow-sm rounded-lg">>
                                                                            <option [selected]="true">Select</option>
                                                                            <option value=true>Gajanan Potbhare</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    </form>
                                                    <div class="text-danger text-center pt-4">
                                                        <p><b>Please select paper size as Letter</b></p>
                                                    </div>
                                                    <div class="text-center pt-4">
                                                        <button type="button" (click)="onSubmit1(myForm)"
                                                            class="btn btn-sm  btn-save" [disabled]="isDisable"
                                                            [disabled]="myForm.invalid">Template1</button>&nbsp;
                                                        <button type="button" (click)="onSubmit2(myForm)"
                                                            class="btn btn-sm  btn-save" [disabled]="isDisable"
                                                            [disabled]="myForm.invalid">Template2</button>&nbsp;
                                                        <button type="button" (click)="onSubmit3(myForm)"
                                                            class="btn btn-sm  btn-save" [disabled]="isDisable"
                                                            [disabled]="myForm.invalid">Template3</button>&nbsp;
                                                        <button type="button" (click)="onSubmit4(myForm)"
                                                            class="btn btn-sm  btn-save" [disabled]="isDisable"
                                                            [disabled]="myForm.invalid">NIT POLYTECHNIC</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




<div class="modal" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog  modal-lg">
        <div class="modal-content">

            <div class="modal-header py-2">
                <h4 class="modal-title w-100"> ID Card Template</h4>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>
        </div>
    </div>
</div>