<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.PrintIDCard1}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right mb-1">
                                            <button type="button" class="btn btn-primary" (click)="Print()">
                                                Print</button> &nbsp;
                                            <button type="button" class="btn btn-danger" (click)="back()">
                                                Back</button>
                                        </div>
                                        <div id="print">
                                            <div class="row" *ngFor="let item of idCardList">


                                                <!-- front card start -->

                                                <table class="bg" width="638px" height="1004px" border="0"
                                                    cellpadding="0" cellspacing="0"
                                                    background="http://3.137.183.21/admin/assets/img/student_id_card_img/bg_new.png"
                                                    align="center">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <table width="638px" height="1004px" border="0"
                                                                    align="center" cellpadding="0" cellspacing="0"
                                                                    style="border:1px solid #e6e7e8;">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td height="55" colspan="3">&nbsp;</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td height="210" colspan="3" valign="top">
                                                                                <table width="100%" cellpadding="0"
                                                                                    cellspacing="0" border="0">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td width="45">
                                                                                            </td>
                                                                                            <td width="240"
                                                                                                style="text-align: center; vertical-align: middle;">

                                                                                                <img src="assets/img/qr-code.png"
                                                                                                    style="border: 1px solid #E6E7E8; width: 178px; padding: 10px; background-color: #fff;">
                                                                                            </td>
                                                                                            <td width="68">
                                                                                            </td>
                                                                                            <td align="right"
                                                                                                width="240">
                                                                                                <img src="assets/img/gaudiumlogo.png"
                                                                                                    width="240">
                                                                                            </td>
                                                                                            <td width="45">
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td height="20" colspan="3">&nbsp;</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="3" align="center"
                                                                                style="line-height:37px;">
                                                                                <span
                                                                                    style="font-family:RobotoSlab-Regular; font-size:37px; color:#be1e2d;">Hi,
                                                                                    I
                                                                                    am</span><br>
                                                                                    <span
                                                                                    style="font-family:RobotoSlab-Regular; font-size:37px; color:#be1e2d;">the Parent of</span><br>

                                                                                <span
                                                                                    style="font-family:RobotoSlab-Regular; font-size:46px; font-weight: bold; color:#be1e2d;">{{item.name}}
                                                                                </span><br>
                                                                                <span
                                                                                    style="font-family:RobotoSlab-Regular; font-size:37px; color:#be1e2d;">Of
                                                                                    <span
                                                                                        style="font-weight: bold;">{{item.course_name}}</span></span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td width="79"> </td>
                                                                            <td align="center" valign="middle"
                                                                                height="480" width="430"
                                                                                style="background-color:#fff; box-shadow:10px 8px 38px rgba(0, 0, 0, 0.5); -webkit-box-shadow:10px 8px 38px rgba(0, 0, 0, 0.5); -moz-box-shadow:10px 8px 38px rgba(0, 0, 0, 0.5); -m-box-shadow:10px 8px 38px rgba(0, 0, 0, 0.5);">
                                                                                <img [src]="item.userfrimages"
                                                                                    height="437" width="420"
                                                                                    style="margin-top:5px; margin-left:-5px;">
                                                                            </td>
                                                                            <td width="79"> </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="3" height="65"></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                <!-- front cardend -->
                                                <!-- back card start -->
                                                <table class="bg" width="638" height="1004" border="0" cellpadding="0"
                                                    cellspacing="0"
                                                    background="http://3.137.183.21/admin/assets/img/student_id_card_img/bg_new.png"
                                                    align="center">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <table width="638" height="1004" border="0"
                                                                    align="center" cellpadding="0" cellspacing="0"
                                                                    style="border:1px solid #e6e7e8;">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td valign="top">
                                                                                <table width="538" border="0"
                                                                                    align="center" cellpadding="0"
                                                                                    cellspacing="0">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td height="75"
                                                                                                align="center">    <span
                                                                                                style="font-family:RobotoSlab-Regular; font-size:37px; color:#be1e2d;">PARENT CARD</span></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td
                                                                                                style="font-family: 'RobotoSlab-Regular'; font-size:30px; line-height:32px; padding-top:20px; color:#646565;">
                                                                                                Section:
                                                                                                <strong>A</strong><br>
                                                                                                Roll No:
                                                                                                <strong>{{item.rollnumber}}</strong><br>
                                                                                                Blood Group:
                                                                                                <strong>B+ve</strong>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td
                                                                                                style="font-family: 'RobotoSlab-Regular'; font-size:30px; line-height:32px; padding-top:20px; color:#646565;">
                                                                                                Parent:
                                                                                                <strong>{{item.parentname}}</strong><br>
                                                                                                Contact:
                                                                                                <strong>{{item.parent_mobile_number}}</strong>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td
                                                                                                style="font-family: 'RobotoSlab-Regular'; font-size:30px; line-height:32px; padding-top:20px; color:#646565;">
                                                                                                Route No:
                                                                                                <strong>{{item.bus_no}}</strong><br>
                                                                                                Stop Name:
                                                                                                <strong>{{item.stop_name}}</strong>
                                                                                            </td>
                                                                                        </tr>

                                                                                        <tr>
                                                                                            <td align="right"
                                                                                                style="font-family: 'RobotoSlab-Regular'; font-size:30px; line-height:32px; color:#646565; padding-top:75px;">
                                                                                                <img src="assets/img/sign.png"
                                                                                                    height="80"><br>
                                                                                                <strong> Ram</strong>
                                                                                            </td>
                                                                                        </tr>

                                                                                        <tr>
                                                                                            <td align="center"
                                                                                                style="font-family: 'RobotoSlab-Regular'; font-size:26px; line-height:28px; color:#646565; padding-top:125px;">
                                                                                                In case found, please
                                                                                                return to:<br>
                                                                                                <span
                                                                                                    style="color:#be1e2d; font-weight:bold;">The
                                                                                                    Gaudium
                                                                                                    School</span><br>
                                                                                                # 1-18/GS,
                                                                                                Nanakramguda<br>
                                                                                                Near Wipro Junction<br>
                                                                                                Hyderabad 500008<br>
                                                                                                T: +91.7337000100<br>
                                                                                                E:
                                                                                                info@thegaudium.com<br>

                                                                                                <span
                                                                                                    style="color:#959595; font-size:50px; font-weight:bold; margin-top:35px; display: block;">
                                                                                                    www.TheGaudium.com</span>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td height="75"
                                                                                                align="center"> </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <!-- back card end -->
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>