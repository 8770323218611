import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-return-items',
  templateUrl: './return-items.component.html',
  styleUrls: ['./return-items.component.css']
})
export class ReturnItemsComponent implements OnInit {
  myForm: FormGroup;
  checkboxs: Array<any> = [];
  isChecked: boolean = false;
  isClicked: boolean = false;
  Id: number;
  formBuilder: any;
  labelName: any = {};
  headingName: any = {};
  workItemId: any;

  constructor(private active: ActivatedRoute, private route: Router, private fb: FormBuilder, private CommonService: CommonService, private toastr: ToastrService) {
    active.queryParams.subscribe((res) => {
      this.Id = res.id;
      this.workItemId = res.wId
      console.log(this.Id);
    })
    this.loadData();
    this.getLabels();
    this.loadHeadings();

  }

  ngOnInit(): void {
    this.myForm = this.fb.group({ formArray: this.fb.array([]) });
  }
  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  loadData() {
    this.CommonService.postCall('EmsHostelStudents/ReturnItemsLoad', {}).subscribe((res: any) => {
      this.checkboxs = res;
      const arrayControl = <FormArray>this.myForm.controls['formArray'];
      this.checkboxs.map(a => {
        let grp: FormGroup = this.getGroup(a);
        arrayControl.push(grp);
      });
    });
  }

  isChange(event: any, item) {
    if (event.target.checked)
      item.value.isChecked = true;
    else
      item.value.isChecked = false;
  }

  isChanging(event: any) {
    if (event.target.value)
      this.isClicked = true;
    else
      this.isClicked = false;
  }

  onSubmit(form: FormGroup) {
    let value: any = form.value;
    let list: Array<any> = [];
    list = value.formArray;
    console.log('value', list)
    // let payload={
    //  items:this.checkboxs
    // }
    let payload = {
      items: list.filter(m => m.isChecked == true)
    }
    console.log('payload', payload);
    this.CommonService.postCall('EmsHostelStudents/UpdateItems', payload).subscribe((res: any) => {
      this.route.navigate(['home/hostel-students']);
      window.history.back();
      this.toastr.success(" Created Successfully");
    }, err => {
      this.toastr.error(err.error ? err.error : 'Not created')
    });
  }

  close() {
    this.route.navigate([`home/hostel-students/${this.workItemId}`]);
  }



  getGroup(data) {
    return this.fb.group({
      item_id: [data.item_id],
      return_date: [''],
      condition_on_return: [''],
      hostel_items_description: [''],
      isChecked: [false],
      name: [data.item_name]
    });
  }

}
