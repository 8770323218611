import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-drop',
  templateUrl: './drop.component.html',
  styleUrls: ['./drop.component.css']
})
export class DropComponent implements OnInit {
  myForm: FormGroup;
  table: Array<any> = [];
  dropby: Array<any> = [];
  students: Array<any> = [];
  cources: Array<any> = [];
  scheduls: Array<any> = [];
  courceId: string = '';
  isEdit: boolean = false;
  editData: any;
  labelName: any = {};
  headingName: any = {};
  workItemId: any;

  constructor(private fb: FormBuilder,private active : ActivatedRoute, private CommonService: CommonService, private toastr: ToastrService) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadDrop();
    this.getCourses();
    this.getLabels();
    this.loadHeadings();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      course_id: ['', Validators.required],
      course_schedule_id: ['', Validators.required],
      person_type: ['', Validators.required],
      student_id: ['', Validators.required],
      purpose: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],

    })

  }
  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }


  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  loadDrop() {
    let payLoad = {
      "tenant_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('latedrop/GetList', payLoad).subscribe((res: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = res;
      }, 10);
    })
  }
  getCourses() {
    this.activeSpinner()
    this.CommonService.getAdminCourses().subscribe((res: any) => {
      this.cources = res;
      this.deactivateSpinner()
    }, e => {
      this.deactivateSpinner()
    })
  }
  courceChange(id) {
    let data = {
      "CourseId": id
    }
    this.activeSpinner()
    this.CommonService.getAdminCourseSchedule(data).subscribe((res: any) => {
      this.deactivateSpinner()
      this.scheduls = res;
    }, e => { this.deactivateSpinner() })
  }
  scheduleChange(id) {
    let payLoad = {
      "COURSESHD_ID": id
    }
    this.CommonService.postCall('Account/usersbycourseShedule', payLoad).subscribe((res: any) => {
      this.deactivateSpinner();
      this.students = res;
    }, e => {
      this.deactivateSpinner();
    });
  }
  onSubmit(form: FormGroup) {
    let value: any = form.value;
    value.tenant_code = localStorage.getItem('TenantCode');
    value.created_by = localStorage.getItem('UserId');

    if (this.isEdit) {

      value.modified_by = localStorage.getItem('UserId')
      value['latedrop_id'] = this.editData.latedrop_id;
      this.CommonService.postCall('latedrop/Update', value).subscribe((res: any) => {
        this.loadDrop();
        this.toastr.success(" Updated Successfully");
        document.getElementById('md_close')?.click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Not Updated')
      })
    }
    else {

      this.CommonService.postCall('latedrop/Create', value).subscribe((res: any) => {
        this.loadDrop();
        this.toastr.success(" Created Successfully");
        document.getElementById('md_close')?.click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Not created')

      })
    }
  }
  edit(dropId) {
    this.editData = dropId;
    this.isEdit = true;
    this.myForm.reset();
    let payLoad = {
      "latedrop_id": dropId
    }
    this.CommonService.postCall('latedrop/Get', payLoad).subscribe((res: any) => {
      if (res instanceof Array && res.length) {
        this.editData = res[0];
        this.dataTransForm()
      } else {
        this.editData = res;
        this.dataTransForm()
      }
      this.courceChange(this.editData.course_id);
      this.scheduleChange(this.editData.course_schedule_id)
      this.dataTransForm();

    }), err => { }
  }

  dataTransForm() {
    let ctrls = this.myForm.controls
    Object.keys(ctrls).map((key) => {
      let ctrl: AbstractControl = ctrls[key];
      ctrl.setValue(this.editData[key])

    });
  }
  close() {
    this.myForm.reset();

  }
  add() {
    this.editData = {};
    this.isEdit = false;
  }
}
