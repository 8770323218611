import { Component, OnInit } from '@angular/core';
import { PolsService } from './../../services/pols.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-pols',
  templateUrl: './pols.component.html',
  styleUrls: ['./pols.component.css']
})
export class PolsComponent implements OnInit {
  polls: [] = []
  headingName: any = {};
  workItemId: any;
  constructor(private pollService: PolsService, private active : ActivatedRoute,private route: Router, private CommonService: CommonService) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.getPolls();
    this.loadHeadings();
  }

  ngOnInit(): void {
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  getPolls() {

    this.CommonService.activateSpinner();
    this.pollService.getPolls().subscribe((res: any) => {
      this.CommonService.deactivateSpinner()
      this.polls = res
    }, (err) => {
      this.CommonService.deactivateSpinner()
    })
  }

  takePoll(data) {
    data['wId'] = this.workItemId
    this.route.navigate(['home/takepoll'], { queryParams: data })
  }

}
