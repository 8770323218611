<br>

<div class="page-wrapper">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">

                    <div class="container-fluid">

                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.StudentConveyance}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right mb-2">
                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                                data-bs-target="#myModal">Add Student Conveyance</button>
                                        </div>
                                        <div *ngIf="table.length" class="table-responsive">
                                            <table id="patreggraph" datatable
                                                class="table table-striped table-bordered mt-2 pt-2 table-sm small display">
                                                <thead class="top" style="background-color: #624fd7; color:#ffffff;">
                                                    <tr>
                                                        <th> ADMN No. </th>
                                                        <th> Course </th>
                                                        <th>Course Schedule</th>
                                                        <th> Student Name </th>
                                                        <th> Route Name </th>
                                                        <th>Bus No.</th>
                                                        <th> Stop Name </th>
                                                        <th> Fees </th>
                                                        <th> Distance </th>
                                                        <th> status </th>
                                                        <th> action </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.rollnumber }}</td>
                                                        <td>{{item.course_name}}</td>
                                                        <td>{{item.courseshd_name }}</td>
                                                        <td>{{item.student_name}}</td>
                                                        <td>{{item.route_name}}</td>
                                                        <td>{{item.bus_no}}</td>
                                                        <td>{{item.route_name }}</td>
                                                        <td>{{item.amount}}</td>
                                                        <td>{{item.distance}}</td>
                                                        <td>{{item.status == true ? "Active" : "Inactive"}}</td>
                                                        <td style="display: flex; text-align: center;cursor: pointer;">
                                                            <a data-bs-toggle="modal" data-bs-target="#myModal"
                                                                (click)="edit(item.student_conveyance_id)"><i
                                                                    class="zmdi zmdi-edit zmdi-hc-2x">
                                                                </i></a>&nbsp;
                                                            <button class="btn btn-warning" *ngIf="item.status == true"
                                                                (click)="inactive(item.student_conveyance_id,item.status)">Inactive</button>

                                                            <button class="btn btn-warning"
                                                                *ngIf="item.status == false"
                                                                (click)="inactive(item.student_conveyance_id,item.status)">Active</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div *ngIf="!table.length" class="text-center">
                                                No Records To Display
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog  modal-lg">
        <div class="modal-content">

            <div class="modal-header py-2">
                <h5 *ngIf="!isEdit" class="modal-title w-100">{{headingName.AddStudentConveyance}} </h5>
                <h5 *ngIf="isEdit" class="modal-title w-100">{{headingName.EditStudentConveyance}} </h5>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>

            <div class="modal-body">
                <form id="form" class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <!-- <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                campus <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName="campus_id"
                                    class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option *ngFor='let item of campus' value="item.campus_id">{{item.campus_name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>&nbsp;&nbsp; -->
                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.Course}}<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName="course_id" [(ngModel)]='courceId '
                                        (change)="courceChange($any($event).target.value)"
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option *ngFor="let course of courses" [value]='course.COURSE_ID'>
                                            {{course.COURSE_NAME}}
                                        </option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('course_id').touched">
                                    <span class="text-danger" *ngIf="myForm.get('course_id').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>

                            </div>
                        </div>&nbsp;&nbsp;
                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.CourseSchedule}}<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName="courseshd_id"
                                        (change)="scheduleChange($any($event).target.value)"
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option *ngFor="let schedul of scheduls" [value]='schedul.COURSESHD_ID'>
                                            {{schedul.COURSESHD_NAME}}</option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('courseshd_id').touched">
                                    <span class="text-danger" *ngIf="myForm.get('courseshd_id').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>

                            </div>
                        </div>&nbsp;&nbsp;
                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.Student}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select name="student" id="student" formControlName="student_id"
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" [selected]="true">Select</option>
                                        <option *ngFor="let student of students" [value]="student.id">
                                            {{student.name}}
                                        </option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('student_id').touched">
                                    <span class="text-danger" *ngIf="myForm.get('student_id').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>

                            </div>
                        </div>&nbsp;&nbsp;


                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.Route}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName='route_id'
                                        (change)="loadStops($event.target.value);loadBus($event.target.value)"
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option *ngFor='let item of route' [value]="item.route_id">{{item.route_name}}
                                        </option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('route_id').touched">
                                    <span class="text-danger" *ngIf="myForm.get('route_id').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>

                            </div>
                        </div>&nbsp;&nbsp;


                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.Stop}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName='route_stop_id'
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option *ngFor='let item of stop' [value]="item.route_stop_id">
                                            {{item.stop_name}}
                                        </option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('route_stop_id').touched">
                                    <span class="text-danger" *ngIf="myForm.get('route_stop_id').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>

                            </div>
                        </div>&nbsp;&nbsp;

                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.Bus}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName='bus_no'
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option *ngFor='let item of bus' [value]="item.bus_no">{{item.bus_no}}
                                        </option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('bus_no').touched">
                                    <span class="text-danger" *ngIf="myForm.get('bus_no').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>

                            </div>
                        </div>&nbsp;&nbsp;

                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.Slabs}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName='transport_slab_id'
                                        (change)="changeSlabs($event.target.value)"
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option *ngFor='let item of slabs' [value]="item.transport_slabs_id">
                                            {{item.transport_slabs_from}} - {{item.transport_slabs_to}}</option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('transport_slab_id').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('transport_slab_id').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>

                            </div>
                        </div>&nbsp;&nbsp;

                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.TypePickUpandDrop}} <span class="text-danger"></span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName="conveyance_type"
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option value="1">Pick Up</option>
                                        <option value="2">Drop</option>
                                        <option value="3">Both</option>
                                    </select>
                                </div>


                            </div>
                        </div>&nbsp;&nbsp;
                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.Distance}} </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group text-center">
                                    <strong>:</strong> &nbsp;
                                    <input placeholder="" disabled class="form-control" formControlName="distance"
                                        value="{{from}} - {{to}}" />
                                </div>
                            </div>
                        </div>&nbsp;&nbsp;
                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.Amount}} </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group text-center">
                                    <strong>:</strong> &nbsp;
                                    <input placeholder="" disabled class="form-control" formControlName="amount"
                                        value="{{price}}" />
                                </div>
                            </div>
                        </div>

                    </fieldset>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-success" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-success" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-danger" id='md_close' (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>