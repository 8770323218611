import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-student-idcard-histroylist',
  templateUrl: './student-idcard-histroylist.component.html',
  styleUrls: ['./student-idcard-histroylist.component.css']
})
export class StudentIdcardHistroylistComponent implements OnInit {
  table: Array<any> = [];
  headingName:any= {};
  workItemId: any;
  constructor(private commonService: CommonService,private active : ActivatedRoute) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.StudentIdCardList();
    this.loadHeadings();
  }

  ngOnInit(): void {
  }

  loadHeadings() {
    this.headingName = {};
    this.commonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  StudentIdCardList() {
    let payload = {
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.commonService.postCall('EmsGenerateIDCards/StudentIdCardList', payload).subscribe((res: any) => {
      this.table = res;
    })
  }

}
