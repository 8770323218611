<br>

<div class="page-wrapper">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.AssignedPeriods}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right mb-1">
                                            <button type="button" [disabled]="!isPrint" class="btn btn-primary btn-sm"
                                                (click)="Print()">
                                                Print</button>
                                        </div>
                                        <div class="row">
                                        <div class="col-md-3">
                                            <label for="sel1"> {{labelName.Course}}<span class="text-danger">*</span></label>
                                            <select [(ngModel)]='courceId '
                                                class="form-select form-select-sm shadow-sm rounded-lg"
                                                id="sel1" (change)="courceChange($any($event).target.value)">
                                                <option value="" selected>Select
                                                </option>
                                                <option *ngFor="let course of cources"
                                                    [value]='course.COURSE_ID'>
                                                    {{course.COURSE_NAME}}
                                                </option>
                                            </select>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="sel1">{{labelName.CourseSchedule}}<span class="text-danger">*</span></label>
                                            <select [(ngModel)]="sheduleId"
                                                class="form-select form-select-sm shadow-sm rounded-lg"
                                                id="sel1" (change)="getTeachers($any($event).target.value)" >
                                                <option value="" selected>Select </option>
                                                <option *ngFor="let schedul of scheduls"
                                                    [value]='schedul.COURSESHD_ID'>
                                                    {{schedul.COURSESHD_NAME}}</option>
                                            </select>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="sel1">{{labelName.Teachers}}<span class="text-danger">*</span></label>
                                            <select
                                                class="form-select form-select-sm shadow-sm rounded-lg"
                                                id="sel1" (change)="loadAssignedPeriods($event)" >
                                                <option value="" [selected]="true">select</option>
                                                        <option  *ngFor='let item of teachers' [value]='item.COURSE_TRAINER_TRAINER_ID'>
                                                            {{item.USER_FIRSTNAME}}
                                                        </option>
                                            </select>
                                        </div>


                                </div>
                                        <!-- <div class="form-group row pt-2">
                                            <label class="col-md-2 control-label mt-1 ms-2">
                                                {{labelName.Teachers}} <span class="text-danger">*</span></label>
                                            <div class="col-md-5 inputGroupContainer">
                                                <div class="input-group"> <strong>:</strong> &nbsp;
                                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                        (change)="loadAssignedPeriods($event)">
                                                        <option value="" [selected]="true">select</option>
                                                        <option [value]='item.USERID' *ngFor='let item of teachers'>
                                                            {{item.FIRSTNAME}}
                                                        </option>
                                                    </select>
                                                </div>

                                            </div>
                                        </div> -->
                                        <br><br>
                                        <div id="print" *ngIf="table.length" class="table-responsive">
                                            <table class="table table-bordered">
                                                <thead class="top">
                                                    <tr>
                                                        <th>Day</th>
                                                        <th *ngFor="let item of thead; let i = index">Period_{{i+1}}
                                                        </th>
                                                        <!-- <th *ngFor="let item of metaData">{{item}}</th> -->
                                                        <!-- <th>Day</th> -->

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.day}}</td>
                                                        <td *ngFor="let d of finalNum"><span *ngIf="item[d]">Class :
                                                                {{item[d].class_name}}
                                                                <br>Year : {{item[d].course_year_name}}<br>Samester :
                                                                {{item[d].semester_name}} <br> Section :
                                                                {{item[d].section_name}} <br> Subject :
                                                                {{item[d].subject_name}} <br> Room No. :
                                                                {{item[d].room_number}}
                                                            </span> </td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records To Display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>