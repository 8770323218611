<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">Edit Time Table</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="custom_container">
                                            <form class="well form-horizontal" [formGroup]="myForm"
                                                (ngSubmit)="onSubmit(myForm)">
                                                <fieldset>
                                                    <div class="form-group row">
                                                        <label class="col-md-4 control-label ">Add more periods<span
                                                                class="text-danger">*</span></label>
                                                        <div class="col-md-8 inputGroupContainer">
                                                            <div class="input-group"> <strong>:</strong> &nbsp;
                                                                <select
                                                                    class="form-control form-control-sm shadow-sm rounded-lg"
                                                                    formControlName='selectPeriod'>
                                                                    <option [value]="0" [selected]="true">Select
                                                                        Period</option>
                                                                    <option [value]="1">1</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>



                                                    <div class="form-group table-responsive row pt-4" *ngIf="viewTable"
                                                        formArrayName='periods'>
                                                        <table
                                                            class="table table-striped table-bordered table-responsive">
                                                            <thead class="top"
                                                                style="background-color: #624fd7; color:#ffffff;">
                                                                <tr>
                                                                    <th>Periods</th>
                                                                    <th>Monday</th>
                                                                    <th>Tuesday </th>
                                                                    <th>Wednesday</th>
                                                                    <th>Thursday</th>
                                                                    <th>Friday</th>
                                                                    <th>Saturday</th>
                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                <tr *ngFor="let item of myForm.controls.periods.controls;let i=index; trackBy:trackByFn"
                                                                    [formGroupName]="i">
                                                                    <td>
                                                                        <label for="">Start Time :</label>
                                                                        <input type="time"
                                                                            formControlName="periodStart">
                                                                        <br>
                                                                        <label for="">End Time &nbsp; :</label>
                                                                        <input type="time" formControlName="periodEnd">
                                                                    </td>
                                                                    <td>
                                                                        <input readonly (click)="getIndex(i,'mon')"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#Aasign"
                                                                            formControlName="1" />

                                                                    </td>
                                                                    <td>
                                                                        <input readonly (click)="getIndex(i,'tue')"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#Aasign"
                                                                            formControlName="2" />

                                                                    </td>
                                                                    <td>
                                                                        <input readonly (click)="getIndex(i,'wed')"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#Aasign"
                                                                            formControlName="3" />
                                                                    </td>
                                                                    <td>
                                                                        <input readonly (click)="getIndex(i,'thu')"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#Aasign"
                                                                            formControlName="4" />
                                                                    </td>
                                                                    <td>
                                                                        <input readonly (click)="getIndex(i,'fri')"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#Aasign"
                                                                            formControlName="5" />
                                                                    </td>
                                                                    <td>
                                                                        <input readonly (click)="getIndex(i,'sat')"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#Aasign"
                                                                            formControlName="6" />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </fieldset>
                                            </form>
                                            <div class="text-center pt-4">

                                                <button type="button" [disabled]="myForm.invalid"
                                                    (click)="onSubmit(myForm)"
                                                    class="btn btn-sm  btn-save">Update</button>
                                                &nbsp;&nbsp;
                                                <button type="button" class="btn btn-sm btn-danger" (click)="close()"
                                                    id="close">cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>















<div class="modal fade" id="Aasign" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header py-2">
                <h6 class="modal-title w-100"> Assign Subject</h6>
                <!-- <h6 *ngIf="isEdit" class="modal-title w-100"> Edit Visitor</h6> -->
                <button type="button" (click)="close1()" class="close" data-bs-dismiss="modal" aria-bs-hidden="true"><i
                        class="fas fa-times"></i></button>
            </div>


            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForms" (ngSubmit)="Submit(myForms,currentIndex)">
                    <fieldset>


                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Teachers <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName="teacher_id"
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option *ngFor='let item of trainer' [value]="item.USERID">
                                            {{item.FIRSTNAME}}
                                        </option>
                                    </select>
                                </div>
                                <!-- <div *ngIf="myForm.get('teacher_id').touched">
                                    <span class="text-danger" *ngIf="myForm.get('teacher_id').hasError('required')">
                                        This field is required.
                                    </span>

                                </div> -->
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Subject <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName='subject'
                                        class="form-control form-control-sm shadow-sm rounded-lg ">
                                        <option value="" [selected]="true">select</option>
                                        <option *ngFor="let item of subjects" [value]='item.SUBJECT_ID'>
                                            {{item.SUBJECT_NAME}}
                                        </option>
                                    </select>
                                </div>
                                <!-- <div *ngIf="myForm.get('subject').touched">
                                    <span class="text-danger" *ngIf="myForm.get('subject').hasError('required')">
                                        This field is required.
                                    </span>

                                </div> -->
                            </div>
                        </div>


                    </fieldset>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-save btn-sm" (click)="Submit(myForms,currentIndex)"
                    [disabled]="myForms.invalid">Save</button>

                <button type="button" class="btn btn-danger btn-sm" id='md_close' (click)="close1()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>