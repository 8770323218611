<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.StudentPromotion}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="custom_container">
                                            <form class="well form-horizontal" [formGroup]="myForm"
                                                (ngSubmit)="onSubmit(myForm)">
                                                <fieldset>
                                                    <div class="form-group row pt-4">
                                                        <label class="col-md-4 control-label line_2 rem_1">
                                                            {{labelName.Course}}<span
                                                                class="text-danger">*</span></label>
                                                        <div class="col-md-8 inputGroupContainer">
                                                            <div class="input-group">
                                                                <strong>:</strong> &nbsp;
                                                                <select [(ngModel)]='courceId' id="sel1"
                                                                (change)="courceChange()" formControlName="course_id"
                                                                class="form-select form-select-sm shadow-sm rounded-lg">
                                                                <option value="" [selected]="true">select</option>
                                                                <option *ngFor="let course of cources"
                                                                    [value]='course.COURSE_ID'>
                                                                    {{course.COURSE_NAME}}
                                                                </option>
        
                                                            </select>
                                                            </div>
                                                            <div *ngIf="myForm.get('course_id').touched">
                                                                <span class="text-danger"
                                                                    *ngIf="myForm.get('course_id').hasError('required')">
                                                                    This field is required.
                                                                </span>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-group row pt-4">
                                                        <label class="col-md-4 control-label line_2 rem_1">
                                                            {{labelName.earOfRegistration}} <span
                                                                class="text-danger">*</span></label>
                                                        <div class="col-md-8 inputGroupContainer">
                                                            <div class="input-group">
                                                                <strong>:</strong> &nbsp;
                                                                <select (change)="chageYear()" [(ngModel)]='yearId' id="sel1" formControlName="year_of_registratiomn"
                                                                    class="form-select form-select-sm shadow-sm rounded-lg">
                                                                    <option value="" [selected]="true">select</option>
                                                                    <option *ngFor="let year of years"
                                                                        [value]='year.YearId'>
                                                                        {{year.Year}}</option>

                                                                </select>
                                                            </div>
                                                            <div *ngIf="myForm.get('year_of_registratiomn').touched">
                                                                <span class="text-danger"
                                                                    *ngIf="myForm.get('year_of_registratiomn').hasError('required')">
                                                                    This field is required.
                                                                </span>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-group row pt-4">
                                                        <label class="col-md-4 control-label line_2 rem_1">
                                                           {{labelName.FromCourseYear}} <span
                                                                class="text-danger">*</span></label>
                                                        <div class="col-md-8 inputGroupContainer">
                                                            <div class="input-group">
                                                                <strong>:</strong> &nbsp;
                                                                <select formControlName="from_course_year"
                                                                    class="form-select form-select-sm shadow-sm rounded-lg">
                                                                    <option value="" selected>Select</option>
                                                                    <option *ngFor="let course of courseYear"
                                                                        [value]='course.DICTIONARYID'>
                                                                        {{course.DICTIONARYNAME}}</option>
                                                                </select>
                                                            </div>
                                                            <div *ngIf="myForm.get('from_course_year').touched">
                                                                <span class="text-danger"
                                                                    *ngIf="myForm.get('from_course_year').hasError('required')">
                                                                    This field is required.
                                                                </span>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-group row pt-4">
                                                        <label class="col-md-4 control-label line_2 rem_1">
                                                           {{labelName.ToCourseYear}} <span
                                                                class="text-danger">*</span></label>
                                                        <div class="col-md-8 inputGroupContainer">
                                                            <div class="input-group">
                                                                <strong>:</strong> &nbsp;
                                                                <select formControlName="to_course_year"
                                                                    class="form-select form-select-sm shadow-sm rounded-lg">
                                                                    <option value="" selected>Select</option>
                                                                    <option *ngFor="let course of courseYear"
                                                                        [value]='course.DICTIONARYID'>
                                                                        {{course.DICTIONARYNAME}}</option>
                                                                </select>
                                                            </div>
                                                            <div *ngIf="myForm.get('to_course_year').touched">
                                                                <span class="text-danger"
                                                                    *ngIf="myForm.get('to_course_year').hasError('required')">
                                                                    This field is required.
                                                                </span>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </form>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-save btn-sm" (click)="onSubmit(myForm)"
                                            [disabled]="myForm.invalid">Save</button>
                        
                                        <button type="button" class="btn btn-danger btn-sm" id='md_close' (click)="close()"
                                            data-bs-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

                                                    


                                                