import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { dataDictionary } from 'src/app/dataDictionary';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-collage-feedback-list',
  templateUrl: './collage-feedback-list.component.html',
  styleUrls: ['./collage-feedback-list.component.css']
})
export class CollageFeedbackListComponent extends BaseComponent implements OnInit {
  table: Array<any> = [];
  myForm: FormGroup;
  courseYear: Array<any> = [];
  semester: Array<any> = [];
  labelName: any = {};
  countData: Array<any> = []
  reportData: Array<any> = [];
  courseYearId: any;
  semesterId: any;
  public barChartOptions1 = {
    scaleShowVerticalLines: false,
    responsive: true
  };
  // public barChartLabels = ['Excellent %', 'Good %', 'Average %', 'Poor %', 'Very Poor %'];
  public barChartLabels = [];
  public barChartType = 'bar';
  public barChartLegend = true;
  public barChartData = [{ data: [0, 0, 0, 0, 0], label: 'Course Wise Report' }];
  CourseName: any;
  headingName: any = {};
  workItemId: any;

  constructor(private active : ActivatedRoute,private fb: FormBuilder,  CommonService: CommonService,  toastr: ToastrService, private route: Router) {
    super(CommonService, toastr);
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadHeadings();
    this.getLabels();
  }

  ngOnInit(): void {
    this.loadCourseYear();
  }

  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  loadCourseYear() {
    this.courseYear = [];
    this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.CourseYear }).subscribe((res: any) => {
      this.courseYear = res;
    })


  }

  changeCourseYear(event: any) {
    this.semester = [];
    this.courseYearId = event.target.value;
    this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.Semester }).subscribe((res: any) => {
      this.semester = res;
    })
  }

  changeSemester(event: any) {
    this.table = [];
    this.semesterId = event.target.value;
    let payload = {
      "role_id": localStorage.getItem('RoleId'),
      "semester_id": this.semesterId,
      "course_year": this.courseYearId,
      "tnt_code": this.tId || localStorage.getItem('TenantCode'),
    }
    this.CommonService.postCall('EmsFeedback/GetStudentFeedbackListByTrainers', payload).subscribe((res: any) => {
      setTimeout(() => {
        this.table = res;
      }, 10);
    })
  }

  clickReport(id: any, cName: any) {
    this.reportData = [];
    this.CourseName = cName;
    let payload = {
      "tnt_code": localStorage.getItem('TenantCode'),
      "course_id": id,
      "course_year": this.courseYearId,
      "semester_id": this.semesterId
    }
    this.CommonService.postCall('EmsFeedback/GetReportByCourse', payload).subscribe((res: any) => {
      this.barChartLabels = res.map((e) => e.ratingname);
      this.countData = res.map((e) => e.per);
      this.barChartData[0].data = this.countData
      // this.barChartLabels = ansArr;
      // this.countData = countArr;

    })
  }

  clickView(id: any, semid: any, courseYear: any) {
    this.route.navigate(['home/questionsFeedbackList'], { queryParams: { "courseId": id, 'semId': this.semesterId, 'courseYear': this.courseYearId } });
  }

  onSubmit(form: FormGroup) {

  }

  close() {
  }

}
