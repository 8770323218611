import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { isThisSecond } from 'date-fns';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-event-participants-list',
  templateUrl: './event-participants-list.component.html',
  styleUrls: ['./event-participants-list.component.css']
})
export class EventParticipantsListComponent extends BaseComponent implements OnInit {
  table: Array<any> = [];
  headingName: any = {};
  workItemId:any;
  labelName: any = {};
  constructor(private route:Router,private fb: FormBuilder,private active:ActivatedRoute,  CommonService: CommonService,  toastr: ToastrService) {
    super(CommonService, toastr);
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
    this.loadParticipantsList();
    this.loadHeadings();
    this.getLabels();
  }

  ngOnInit(): void {
  }
  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }
  loadParticipantsList() {
    this.CommonService.postCall(`EmsEventsCategories/ParticipantsGetList`, { "tnt_code":this.tId || localStorage.getItem('TenantCode') }).subscribe((res: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = res;
      }, 10);
    })
  }
  add(){
    this.route.navigate(['/home/addParticipants'],{queryParams:{wId:this.workItemId}});
  }
}
