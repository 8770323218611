import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
@Component({
  selector: 'app-list-of-books',
  templateUrl: './list-of-books.component.html',
  styleUrls: ['./list-of-books.component.css']
})
export class ListOfBooksComponent implements OnInit {
  [x: string]: any;
  myForm: FormGroup;
  table: Array<any> = [];
  isassign: Array<any> = [];
  isEdit: boolean = null;
  studentId: string;
  departmentId: string;
  checked: Boolean = false;
  minDate: any = moment().format('yyyy-MM-DD')
  constructor(private fb: FormBuilder, private CommonService: CommonService, active: ActivatedRoute, private toastr: ToastrService) {
    this.load();
    active.queryParams.subscribe((res) => {
      if (res.studentId && res.departmentId) {
        this.studentId = res.studentId;
        this.departmentId = res.departmentId;
        this.workItemId = res.wId;
      }
    })
  }
  ngOnInit(): void {
    this.myForm = this.fb.group({
      BOOK_STD_STUDENT_ID: ['', Validators.required],
      BOOK_STD_BOOK_ID: ['', Validators.required],
      BOOK_STD_DATE_OF_ISSUE: [''],
      BOOK_STD_RETURN_DATE: ['', Validators.required],
      BOOK_STD_BRANCH_ID: ['', Validators.required],
      BOOK_STD_AMOUNT: [''],
      BOOK_STD_REMARKS: [''],
      BOOK_STD_ASSIGN: [''],
      BOOK_STD_BARCODE: [''],
      BOOK_STD_QRCODE: [''],

    })
    this.load();
  }
  load() {
    let payLoad = {
      "BOOK_STD_STUDENT_ID": this.studentId,
      "BOOK_DEPARTMENT_ID": this.departmentId
    }
    this.CommonService.postCall('LibraryManagement/GetBooksAllocation', payLoad).subscribe((res: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = res;
      }, 10)
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner(), console.log(e) })
  }
  activiceSpinner() {
    this.CommonService.activateSpinner();
  }
  deactivateSpinner() {
    this.CommonService.deactivateSpinner();
  }
  onSubmit(isChecked) {
    let payLoad = this.table
    this.activiceSpinner();
    this.deactivateSpinner();
    this.CommonService.postCall('LibraryManagement/CreateBooksAllocation', payLoad).subscribe((res: any) => {
      this.load();
      // window.location.reload();
      this.toastr.success(" Assigned Successfully");

      this.deactivateSpinner();
    }, err => {
      this.toastr.error(err.error ? err.error : 'Not Assigned')
    })

  }
  isChecked(event, item: any, i, bId) {

    if (event.target.checked) {
      item.BOOK_STD_ASSIGN = true;
      item.BOOK_STD_STUDENT_ID = this.studentId;
      item.BOOK_STD_TNT_CODE = +localStorage.getItem('TenantCode');
      item.BOOK_STD_BOOK_ID = item.BOOK_ID;
      item.BOOK_STD_BRANCH_ID = item.DEPARTMENT_ID;
    }else{
      item.BOOK_STD_ASSIGN = false;
      item.BOOK_STD_STUDENT_ID = this.studentId;
      item.BOOK_STD_TNT_CODE = +localStorage.getItem('TenantCode');
      item.BOOK_STD_BOOK_ID = item.BOOK_ID;
      item.BOOK_STD_BRANCH_ID = item.DEPARTMENT_ID;
    }

    if (event.target.checked) {
      this.isassign.push(item);
    } else {
      this.isassign = this.isassign.filter(m => m.BOOK_ID != bId);
    }
    console.log(this.isassign);


    if (this.isassign.length > 0){
      this.checked = true;
    }else{
      this.checked = false;
    }


  }

}
