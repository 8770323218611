<br>

<div class="page-wrapper">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">

                    <div class="container-fluid">

                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.AddStaffConveyance}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right mb-2">
                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                                data-bs-target="#myModal">Add Staff Conveyance</button>
                                        </div>
                                        <div *ngIf="table.length">
                                            <table id="patreggraph" datatable
                                                class="table table-striped table-bordered mt-2 pt-2 table-sm small display"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624fd7; color:#ffffff;">
                                                    <tr>
                                                        <th> Staff Name </th>
                                                        <th> Pickup Route Name </th>
                                                        <th> Pickup Stop Name </th>
                                                        <th>Pickup Bus No </th>
                                                        <th> Drop Route Name </th>
                                                        <th> Drop Stop Name </th>
                                                        <th>Drop Bus No </th>
                                                        <th> Status </th>
                                                        <th> Action </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.staff_name}}</td>
                                                        <td>{{item.pick_route_Name}}</td>
                                                        <td>{{item.pick_route_stop_name}}</td>
                                                        <td>{{item.pick_bus_no}}</td>
                                                        <td>{{item.drop_route_Name}}</td>
                                                        <td>{{item.drop_route_stop_name}}</td>
                                                        <td>{{item.drop_bus_no}}</td>
                                                        <td>{{item.conveyance_status}}</td>
                                                        <td style="display: flex; text-align: center; cursor: pointer;" >
                                                            <a data-bs-toggle="modal" data-bs-target="#myModal"
                                                                (click)="edit(item.staff_conveyance_id)"><i
                                                                    class="zmdi zmdi-edit zmdi-hc-2x">
                                                                </i></a>&nbsp;
                                                            <div *ngIf="item.conveyance_status == 'Pending'">
                                                                <button class="btn btn-warning"
                                                                    (click)="inactive(item.staff_conveyance_id,item.conveyance_status)">Inactive</button>
                                                                <button class="btn btn-warning"
                                                                    (click)="inactive(item.staff_conveyance_id,item.conveyance_status)">Active</button>
                                                            </div>
                                                            <button class="btn btn-warning"
                                                                *ngIf="item.conveyance_status == 'Active'"
                                                                (click)="inactive(item.staff_conveyance_id,item.conveyance_status)">Inactive</button>
                                                            <button class="btn btn-warning"
                                                                *ngIf="item.conveyance_status == 'Inactive' || item.conveyance_status == 'Rejected'"
                                                                (click)="inactive(item.staff_conveyance_id,item.conveyance_status)">Active</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div *ngIf="!table.length" class="text-center">
                                            No Records To Display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog  modal-lg">
        <div class="modal-content">

            <div class="modal-header py-2">
                <h5 *ngIf="!isEdit" class="modal-title w-100">{{headingName.AddStaffConveyance}} </h5>
                <h5 *ngIf="isEdit" class="modal-title w-100">{{headingName.EditStaffConveyance}} </h5>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>


            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.Staff}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName="staff_id"
                                    class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option *ngFor='let item of trainer' [value]="item.id">{{item.name}}
                                        </option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('staff_id').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('staff_id').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>&nbsp;&nbsp;
                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.TypePickUpandDrop}}<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName='conveyance_type' (change)="transportType=$event.target.value"
                                    class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="both">Both</option>
                                        <option value="pickup">Pick Up</option>
                                        <option value="drop">Drop</option>
                                    </select>
                                </div>
                            </div>
                        </div>&nbsp;&nbsp;

                        <div *ngIf="transportType=='pickup'||transportType=='both'">
                            <div class="form-group row">
                                <label class="col-md-4 control-label line_2 rem_1">
                                    {{labelName?.PickUpRoutes}}  <span class="text-danger"></span></label>
                                <div class="col-md-8 inputGroupContainer">
                                    <div class="input-group">
                                        <strong>:</strong> &nbsp;
                                        <select formControlName='pick_route_id'
                                            (change)="loadPickUpStops($event.target.value);loadPickUpBus($event.target.value)"
                                            class="form-select form-select-sm shadow-sm rounded-lg">
                                            <option value="" selected>Select</option>
                                            <option *ngFor='let item of pickupRoutes' [value]="item.route_id">
                                                {{item.route_name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div *ngIf="myForm.get('pick_route_id').touched">
                                        <span class="text-danger"
                                            *ngIf="myForm.get('pick_route_id').hasError('required')">
                                            This field is required.
                                        </span>
    
                                    </div>
                                </div>
                            </div>&nbsp;&nbsp;


                            <div class="form-group row">
                                <label class="col-md-4 control-label line_2 rem_1">
                                    {{labelName?.PickUpStop}} <span class="text-danger"></span></label>
                                <div class="col-md-8 inputGroupContainer">
                                    <div class="input-group">
                                        <strong>:</strong> &nbsp;
                                        <select formControlName='pick_route_stop_id'
                                            class="form-select form-select-sm shadow-sm rounded-lg">
                                            <option value="" selected>Select</option>
                                            <option *ngFor='let item of pickupStop' [value]="item.route_stop_id">
                                                {{item.stop_name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div *ngIf="myForm.get('pick_route_stop_id').touched">
                                        <span class="text-danger"
                                            *ngIf="myForm.get('pick_route_stop_id').hasError('required')">
                                            This field is required.
                                        </span>
    
                                    </div>
                                </div>
                            </div>&nbsp;&nbsp;

                            <div class="form-group row">
                                <label class="col-md-4 control-label line_2 rem_1">
                                    {{labelName?.PickUpBus}}  <span class="text-danger"></span></label>
                                <div class="col-md-8 inputGroupContainer">
                                    <div class="input-group">
                                        <strong>:</strong> &nbsp;
                                        <select formControlName='pick_bus_no'
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                            <option value="" selected>Select</option>
                                            <option *ngFor='let item of pickupBus' [value]="item.bus_no">
                                                {{item.bus_no}}
                                            </option>
                                        </select>
                                    </div>
                                    <div *ngIf="myForm.get('pick_bus_no').touched">
                                        <span class="text-danger"
                                            *ngIf="myForm.get('pick_bus_no').hasError('required')">
                                            This field is required.
                                        </span>
    
                                    </div>
                                </div>
                            </div>&nbsp;&nbsp;
                        </div>
                        <div *ngIf="transportType=='drop'||transportType=='both'">

                            <div class="form-group row">
                                <label class="col-md-4 control-label line_2 rem_1">
                                    {{labelName?.Droproutes}}  <span class="text-danger"></span></label>
                                <div class="col-md-8 inputGroupContainer">
                                    <div class="input-group">
                                        <strong>:</strong> &nbsp;
                                        <select formControlName='drop_route_id'
                                            (change)="loadDropStops($event.target.value);loadDropBus($event.target.value)"
                                            class="form-select form-select-sm shadow-sm rounded-lg">
                                            <option value="" selected>Select</option>
                                            <option *ngFor='let item of dropRoutes' [value]="item.route_id">
                                                {{item.route_name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div *ngIf="myForm.get('drop_route_id').touched">
                                        <span class="text-danger"
                                            *ngIf="myForm.get('drop_route_id').hasError('required')">
                                            This field is required.
                                        </span>
    
                                    </div>
                                </div>
                            </div>&nbsp;&nbsp;


                            <div class="form-group row">
                                <label class="col-md-4 control-label line_2 rem_1">
                                    {{labelName?.DropStop}} <span class="text-danger"></span></label>
                                <div class="col-md-8 inputGroupContainer">
                                    <div class="input-group">
                                        <strong>:</strong> &nbsp;
                                        <select formControlName='drop_route_stop_id'
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                            <option value="" selected>Select</option>
                                            <option *ngFor='let item of dropStop' [value]="item.route_stop_id">
                                                {{item.stop_name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div *ngIf="myForm.get('drop_route_stop_id').touched">
                                        <span class="text-danger"
                                            *ngIf="myForm.get('drop_route_stop_id').hasError('required')">
                                            This field is required.
                                        </span>
    
                                    </div>
                                </div>
                            </div>&nbsp;&nbsp;

                            <div class="form-group row">
                                <label class="col-md-4 control-label line_2 rem_1">
                                    {{labelName?.DropBus}} <span class="text-danger"></span></label>
                                <div class="col-md-8 inputGroupContainer">
                                    <div class="input-group">
                                        <strong>:</strong> &nbsp;
                                        <select formControlName='drop_bus_no'
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                            <option value="" selected>Select</option>
                                            <option *ngFor='let item of dropBus' [value]="item.bus_no">
                                                {{item.bus_no}}
                                            </option>
                                        </select>
                                    </div>
                                    <div *ngIf="myForm.get('drop_bus_no').touched">
                                        <span class="text-danger"
                                            *ngIf="myForm.get('drop_bus_no').hasError('required')">
                                            This field is required.
                                        </span>
    
                                    </div>
                                </div>
                            </div>
                        </div>&nbsp;&nbsp;
                    </fieldset>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-success" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-success" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-danger" id='md_close' (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>