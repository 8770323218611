import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-staff-attendance-report',
  templateUrl: './staff-attendance-report.component.html',
  styleUrls: ['./staff-attendance-report.component.css']
})
export class StaffAttendanceReportComponent implements OnInit {
  myForm: FormGroup;
  table: Array<any> = [];
  isTable: Boolean = false;
  headingName: any = {};
  workItemId: any;
  constructor(private commonService: CommonService,private active : ActivatedRoute) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadStaffAttendance();
    this.loadHeadings();
  }

  ngOnInit(): void {
  }
  loadHeadings() {
    this.headingName = {};
    this.commonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  loadStaffAttendance() {
    let payload = {

    }
    this.commonService.getCall(`EmsReports/StaffAttendenceReport/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.table = res;
    })
  }
  onSubmit(myForm) {

  }
}
