import { Component, OnInit } from '@angular/core';
import { CommonService } from './../../services/common.service';
import { ToastrService } from 'ngx-toastr';
import { dataDictionary } from 'src/app/dataDictionary';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-promotion',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.css']
})
export class PromotionComponent implements OnInit {
  courceId: string;
  cources: Array<any> = [];
  yearId: string;
  courseId: string;
  years: Array<any> = [];
  labelName: any = {};
  courseYear: Array<any> = [];
  myForm: FormGroup;
  headingName: any = {};
  workItemId:any;

  constructor(private fb: FormBuilder,private active:ActivatedRoute, private CommonService: CommonService, private toastr: ToastrService, private route: Router) {
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
    this.loadcourses();
    this.getLabels();
    this.loadHeadings();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      course_id: ['', Validators.required],
      year_of_registratiomn: ['', Validators.required],
      from_course_year: ['', Validators.required],
      to_course_year: ['', Validators.required],

    })
  }

  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }
  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }


  loadcourses() {
    // LoadCourseSchedule
    // Loadyear Loadcourses
    this.activeSpinner()
    let payaload = {
      "TENANT_CODE": localStorage.getItem('TenantCode')
    }
    this.CommonService.getAdminCourses().subscribe((res: any) => {
      this.cources = res;
      this.deactivateSpinner();
    }, e => this.deactivateSpinner());
  }

  courceChange() {
    this.activeSpinner()
    let payload = {
    }
    this.CommonService.postCall('Loadyear', payload).subscribe((res: any) => {
      this.years = res;
      this.deactivateSpinner();
    }, e => this.deactivateSpinner());
  }

  chageYear() {
    this.courseYear = [];
    this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.CourseYear }).subscribe((res: any) => {
      this.courseYear = res;
    })
  }

  onSubmit(form: any) {
    let value = form.value;
    let payload = {
      "courseid": value.course_id,
      "yearofregistration": value.year_of_registratiomn,
      "from_course_year": value.from_course_year,
      "to_course_year": value.to_course_year,
    }
    this.CommonService.postCall('Registration/PramotStudent', payload).subscribe((res: any) => {
      this.toastr.success("Promoted successfully");
      this.myForm.reset();
    }, err => {
      this.toastr.error(err.error ? err.error : 'Not Promoted');
    })
  }

  close() {
    this.route.navigate(['home/dashboard']);
  }

}
