import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-faculty-feedback-list',
  templateUrl: './faculty-feedback-list.component.html',
  styleUrls: ['./faculty-feedback-list.component.css']
})
export class FacultyFeedbackListComponent extends BaseComponent implements OnInit {
  facultyList: Array<any> = [];
  feedbackList: Array<any> = [];
  headingName: any = {};
  workItemId: any;
  labelName: any = {};
  constructor(private active : ActivatedRoute, CommonService: CommonService,toastr: ToastrService) {
    super(CommonService, toastr);
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.getList();
    this.loadHeadings();
    this.getLabels();
  }

  ngOnInit(): void {
  }
  getList() {
    let payload = { "tnt_code": localStorage.getItem('TenantCode'), "user_id": localStorage.getItem('UserId'),"role_name":localStorage.getItem('RoleName') }
    this.CommonService.postCall('EmsFeedback/GetFacultyFeedbackByAdmin', payload).subscribe((response: any) => {
      this.facultyList = response;
    })
  }
  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  viewGetList(course: any, academic: any, semester: any, facultyId: any,stuId:any) {
    let payload = {
      "course_id": course,
      "academic_year_id": academic,
      "semester_id": semester,
      "faculty_id": facultyId,
      "student_id":stuId,
      "tnt_code":this.tId || localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('EmsFeedback/GetFacultyFeedback', payload).subscribe((response: any) => {
      this.feedbackList = response;
    })
  }
  close() { }
}
