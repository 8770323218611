<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.TransferCertificate}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="form-group">
                                            <div class="row">

                                                <div *ngIf="roleId=='4' || roleId=='5'" class="col-md-3 p-4">
                                                    <label for="sel1">{{labelName.TenantName}} : </label>
                                                    <select [(ngModel)]='tId '
                                                        (change)="getCourses($any($event.target).value)"
                                                        class="form-control form-control-sm shadow-sm rounded-lg"
                                                        id="sel1">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let item of tenanates" [value]='item.TNT_CODE'>
                                                            {{item.TNT_NAME}}</option>
                                                    </select>

                                                </div>
                                                <div class="col-md-3 pt-4">
                                                    <label for="sel1">{{labelName.Course}}: </label>
                                                    <select (change)="courceChange($event)"
                                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let course of cources"
                                                            [value]='course.COURSE_ID'>
                                                            {{course.COURSE_NAME}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <!-- <div class="col-md-3 pt-4">
                                                    <label for="sel1">{{labelName.Students}}: </label>
                                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                        (change)="getUsersData($event)">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let item of students" [value]='item.userid'>
                                                            {{item.name}}
                                                        </option>
                                                    </select>
                                                </div> -->

                                                <!-- <div class="col-md-3 pt-4">
                                                    <mat-form-field class="example-full-width" appearance="fill">
                                                        <mat-label>{{labelName.Students}}:</mat-label>
                                                        <input type="text" placeholder="Pick one" aria-label="Number" matInput
                                                            [formControl]="myControl" [matAutocomplete]="auto">
                                                        <mat-autocomplete #auto="matAutocomplete">
                                                            <mat-option *ngFor="let option of students | async"
                                                            [value]='option.userid'>
                                                            {{option.name}}
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                    </mat-form-field>
                                                </div> -->

                                                <!-- <div class="col-md-3 pt-4">
                                                    <div class="inputbox">
                                                        <label for="sel1">{{labelName.Students}}: </label>
                                                        <input type="text" title="Search in box" (click)="show = !show"
                                                            class="form-control form-control-sm"
                                                            [(ngModel)]="currentValue" [placeholder]="'Enter Value'"
                                                            (keyup.enter)="submit()" (keyup.esc)="clear()" /> -->
                                                        <!-- <button class="btn btn-save btn-sm"
                                                            (click)="show = !show">Select</button> -->
                                                        <!--focus & blur to control display-->
                                                    <!-- </div> -->
                                                    <!--below is options list-->
                                                  
                                                <!-- </div> -->

                                                <div class="col-md-3 pt-4">
                                                    <label for="sel1">{{labelName.ReasonofLeavingtheCollege}}:</label>
                                                    <input class="form-control form-control-sm" [(ngModel)]="reason" />
                                                </div>
                                                <div class="col-md-3 pt-4">
                                                    <label for="sel1">{{labelName.Remarks}}:</label>
                                                    <input class="form-control form-control-sm" [(ngModel)]="remarks"
                                                        #name />
                                                </div>
                                                <div class="col-md-3 pt-4">
                                                    <label for="sel1">{{labelName.EnrollmentNumber}}
                                                        <strong>:</strong></label>
                                                    <input class="form-control form-control-sm"
                                                        (change)="enrollNumber($event)" [disabled]="bonafied"
                                                        #EnrollmentNumber [(ngModel)]="Enrollment_Number" />
                                                </div>

                                                <div class="col-md-3 pt-4">
                                                    <div class="inputbox">
                                                        <label for="sel1">{{labelName.Students}}: </label>
                                                        <input type="text" title="Search in box" (click)="show = !show"
                                                            class="form-control form-control-sm"
                                                            [(ngModel)]="currentValue" [placeholder]="'Enter Value'"
                                                            (keyup.enter)="submit()" (keyup.esc)="clear()" />
                                                        <!-- <button class="btn btn-save btn-sm"
                                                            (click)="show = !show">Select</button> -->
                                                        <!--focus & blur to control display-->
                                                    </div>
                                                    <!--below is options list-->
                                                  
                                                </div>

                                                <div class="inputbox col-md-3" *ngIf="show">
                                                    <!-- <div class="overflow-auto" style="cursor: pointer;"
                                                        *ngFor="let option of stuSearchArr | searchPipe:currentValue "
                                                        (click)="optionselected(option)">
                                                        {{option}}
                                                    </div> -->
                                                    <!-- <div class="scrolling-wrapper">
                                                        <div class="cardblock" style="cursor: pointer;"
                                                            *ngFor="let option of stuSearchArr | searchPipe:currentValue "
                                                            (click)="optionselected(option)">
                                                            {{option}}
                                                        </div>

                                                    </div> -->
                                                    <div class="cardblock">
                                                        <div class="card-body">
                                                          <!-- <h5 class="card-title">Students List</h5> -->
                                                          <div class="scrollable">
                                                            <p class="card-text" *ngFor="let option of stuSearchArr | searchPipe:currentValue "
                                                            (click)="optionselected(option)" style="cursor: pointer;">{{option}}</p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    <!-- <div class="inputbox--display" style="cursor: pointer;"
                                                        *ngFor="let option of stuSearchArr | searchPipe:currentValue "
                                                        (click)="optionselected(option)">{{option}}</div> -->
                                                    <!-- <select class="form-select form-select-sm shadow-sm rounded-lg">
                                                        <option value="" [selected]="true">select</option>
                                                        <option (click)="optionselected(option)"
                                                            *ngFor="let option of stuSearchArr | searchPipe:currentValue ">
                                                            {{option}}
                                                        </option>
                                                    </select> -->

                                                </div>

                                            </div>
                                            <div class="pt-4">
                                                <button class="btn btn-save btn-sm " (click)="generateBonafied()"
                                                    [disabled]="isDisabled(+EnrollmentNumber.value.length,name.value.length)">Generate
                                                    Transfer Certificate</button>&nbsp;
                                            </div>

                                            <div *ngIf="receipt" class="certificate-container" id="printtable">
                                                <div class="certificate-header">
                                                    <div class="logo1" style="width: 15%; text-align: center;">
                                                        <img src="../../../assets/img/logo1.jpg" alt="" width="70px">
                                                    </div>
                                                    <div style="width: 70%; text-align: center;">
                                                        <p style="margin-bottom: 0;">Shri Sai Shikshan Sanstha's</p>
                                                        <h2 style="font-size: 2.3em; margin-bottom: 0;">NIT
                                                            Polytechnic
                                                        </h2>
                                                        <h4
                                                            style="font-size: 14px; font-weight: normal; margin-bottom: 0;">
                                                            (All Programmes NBA Accredited)</h4>
                                                    </div>
                                                    <div class="logo2" style="width: 15%; text-align: center;">
                                                        <img src="../../../assets/img/logo2.jpg" alt="" width="70px">

                                                    </div>
                                                </div>
                                                <div class="certificate-header" style="padding: 0; text-align: center;">
                                                    <div class="logo1" style="width: 15%; text-align: center;">
                                                    </div>
                                                    <div>
                                                        <p
                                                            style="text-align: left; font-size: 11px; margin: 3px 0; text-align: center;">
                                                            (An ISO 9001-2015 and 14001-2015 certified Institute)
                                                        </p>
                                                        <p style="text-align: center; font-size: 11px;">(Approved
                                                            by:
                                                            AICTE,
                                                            DTE Mumbal, Govt. of Maharashtra and Affiliated to MSBTE
                                                            Mumbai)
                                                        </p>
                                                        <p
                                                            style="margin-top: 3px; text-align: center; font-size: 11px;">
                                                            <b>Campus :</b> Survey No. 13/2. Mahurzari, Near Fetri,
                                                            Katol
                                                            Road, Nagpur-441 501<br /><b>Mobile :</b> 9545525450,
                                                            9545557700, 7972515445, 8007777170<br /> <b>Website
                                                                :</b>
                                                            www.nitpoly.edu.in,&nbsp;&nbsp;<b>E-mail :</b>
                                                            principainitpoly@nitpoly.edu.in<br /><b>Corporate Office
                                                                :</b>
                                                            2nd Floor, Midas Heights, Central Bazar Road,
                                                            Ramdaspeth,
                                                            Nagpur-10<br /><b>Ph.:</b> (0712) 2462268.
                                                        </p>

                                                    </div>
                                                    <div class="logo1" style="width: 15%; text-align: center;">
                                                    </div>
                                                </div>
                                                <div style="font-size: 15px; margin-top: 10px; padding: 0 20px;">
                                                    <span style="float: left;">Principal: Prof. G, F.
                                                        Potbhare</span>
                                                    <span style="float: right;">Secretary: Salil Deshmukh</span>
                                                    <div style="clear: both;"></div>
                                                </div>
                                                <hr style="margin-top: 0; margin-bottom: 0" />

                                                <div>

                                                    <div style="font-size: 15px; margin-top: 10px; padding: 0 20px;">
                                                        <span style="float: left;">Institute DTE Code :- 4248</span>
                                                        <span style="float: right;">Institute MSBTE Code :-
                                                            1199</span>
                                                        <div style="clear: both;"></div>
                                                    </div>
                                                    <h2
                                                        style="margin: 10px auto; text-align: center; font-size: 1.8em;">
                                                        <i>Leaving Certificate</i>
                                                    </h2>
                                                    <div style="font-size: 15px; margin-top: 10px; padding: 0 20px;">
                                                        <span style="float: left;">Ref. No.:-
                                                            {{usersData.reference_number}}</span>
                                                        <span style="float: right;">MSBTE Enrolment No. :
                                                            {{Enrollment_Number}}</span>
                                                        <div style="clear: both;"></div>
                                                        <p><br />College Admission No.:
                                                            <span
                                                                style="text-decoration: underline">{{usersData.admission_number}}</span>
                                                        </p>
                                                    </div>



                                                    <p style="font-size: 13px; padding: 3px 10px; margin-bottom: 0">
                                                        (No
                                                        changes in any entry in this certificate shall be made
                                                        except by
                                                        the
                                                        issuing authority and any infringement of this requirement
                                                        is
                                                        liable
                                                        to involve the imposition of a penalty including
                                                        rustication.)
                                                    </p>
                                                    <hr style="margin-top: 0; margin-bottom: 0" />

                                                    <table style="width: 100%; font-size: 12px;" cellpadding="2">
                                                        <tr>
                                                            <td style="text-align: right;">1.</td>
                                                            <td>Student College ID</td>
                                                            <td>:&nbsp;&nbsp;NPOLY19408</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="text-align: right;">2.</td>
                                                            <td>Name Of Student - Shri/Smt/ Ku</td>
                                                            <td>:&nbsp;&nbsp;{{usersData.name}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="text-align: right;">3.</td>
                                                            <td>Father's Name</td>
                                                            <td>:&nbsp;&nbsp;{{usersData.parentname}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="text-align: right;">4.</td>
                                                            <td>Mother's Name</td>
                                                            <td>:&nbsp;&nbsp;{{usersData.mother_name}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="text-align: right;">5.</td>
                                                            <td>Nationality</td>
                                                            <td>:&nbsp;&nbsp;INDIAN</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="text-align: right;">6.</td>
                                                            <td>Religion / Caste</td>
                                                            <td>:&nbsp;&nbsp;{{usersData.caste_name}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="text-align: right;">7.</td>
                                                            <td>Date of Birth</td>
                                                            <td>:&nbsp;&nbsp;{{usersData.dob | date:'dd/MM/yyyy'}}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="text-align: right;">8.</td>
                                                            <td>Place of Birth</td>
                                                            <td>:&nbsp;&nbsp;{{usersData.place_of_birth}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="text-align: right;">9.</td>
                                                            <td>Date Of Admission to the College</td>
                                                            <td>:&nbsp;&nbsp;{{usersData.date}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="text-align: right;">10.</td>
                                                            <td>Last Institute Attended</td>
                                                            <td>:&nbsp;&nbsp;{{usersData.last_institute_attended}}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="text-align: right;">11.</td>
                                                            <td>Date of Leaving Institution</td>
                                                            <td>:&nbsp;&nbsp;{{usersData.dateofleavinginstitution |
                                                                date:'dd/MM/yyyy'}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="text-align: right;">12.</td>
                                                            <td>Standard in which studing</td>
                                                            <td>:&nbsp;&nbsp;{{usersData.course_name}},{{usersData.course_year}}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="text-align: right;">13.</td>
                                                            <td>Conduct &amp; Character</td>
                                                            <td>:&nbsp;&nbsp;GOOD</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="text-align: right;">14.</td>
                                                            <td>Conduct &amp; Progress</td>
                                                            <td>:&nbsp;&nbsp;SATISFACTORY</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="text-align: right;">15.</td>
                                                            <td>Reason of Leaving the College</td>
                                                            <td>:&nbsp;&nbsp;{{reason}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="text-align: right;">16.</td>
                                                            <td>Remarks</td>
                                                            <!-- <td>:&nbsp;&nbsp;PASSED FINAL SEMESTER IN SUMMER 2022 EXAM</td> -->
                                                            <td>:&nbsp;&nbsp;{{remarks}}</td>
                                                        </tr>
                                                    </table>

                                                    <hr style="margin-top: 0; margin-bottom: 0" />
                                                    <p style="text-align: center">Certified that the above
                                                        information
                                                        is in
                                                        accordance with the Institute Register</p>
                                                </div>

                                                <br />
                                                <div style="margin-top: 10px;">
                                                    <span style="float: left"><b>Date:</b> {{currentDate | date :
                                                        "dd/MM/yyyy"}}<br /><b>Place:</b>
                                                        NIT
                                                        Polytechnic, Nagpur</span>
                                                    <span style="float: right; margin-right: 20px;">Principal</span>
                                                </div>
                                                <div style="clear: both;"></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="footer text-center pb-2" *ngIf="receipt">
                                        <button class="btn btn-save btn-sm" (click)="convetToPDF()">Download</button>
                                        &nbsp;
                                        <button class="btn btn-save btn-sm" (click)="print()">Print</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>