<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.BooksList}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right mb-1">
                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                                data-bs-target="#myModal">Add Book</button>
                                        </div>
                                        <!-- <div *ngIf="table.length">
                                            <table id="patergraph" datatable
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th>Book Name </th>
                                                       
                                                        <th>Author</th>
                                                        <th>Description</th>
                                                        <th>Action</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.BOOK_NAME}}</td>
                                                       
                                                        <td>{{item.BOOK_AUTHOR}}</td>
                                                        <td>{{item.BOOK_DESCRIPTION}}</td>

                                                        <td style="display: flex;">
                                                            <span data-bs-toggle="modal" data-bs-target="#myModal"
                                                                src="" class="edit_icon" (click)="edit(item.BOOK_ID)">
                                                                <i class="fas fa-pen pt-1 mt-1"></i></span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div> -->


                                        <!-- <div *ngIf="table.length">
                                            <table id="patergraph" datatable
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr> -->

                                        <div *ngIf="table.length" class="table-responsive">
                                            <table id="patergraph" datatable
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small display">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th>Book Name </th>
                                                        <th>Book Author</th>
                                                        <th>Course Name</th>
                                                        <th>Subject</th>
                                                        <th>Language</th>
                                                        <th>Book Publication</th>
                                                        <th>Book Edition</th>
                                                        <th>No.Of Copies</th>
                                                        <th>Book Price</th>
                                                        <th>Status</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.BOOK_NAME}}</td>
                                                        <td>{{item.BOOK_AUTHOR}}</td>
                                                        <td>{{item.course_name}}</td>
                                                        <td>{{item.subject_name}}</td>
                                                        <td>{{item.language_name}}</td>
                                                        <td>{{item.BOOK_PUBLICATION}}</td>
                                                        <td>{{item.BOOK_EDITION}}</td>
                                                        <td>{{item.BOOK_NO_OF_COPIES}}</td>
                                                        <td>{{item.BOOK_PRICE}}</td>
                                                        <td style="display: flex;">
                                                            <span data-bs-toggle="modal" data-bs-target="#myModal"
                                                                src="" class="edit_icon" (click)="edit(item.book_id)">
                                                                <i class="fas fa-pen pt-1 mt-1"></i></span>&nbsp;&nbsp;
                                                            <button type="button" class="btn btn-primary mb-1"
                                                                (click)="addAssessment(item.book_id)">Add Book
                                                                Copies</button>
                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog  modal-xl">
        <div class="modal-content">

            <div class="modal-header py-2">
                <h4 *ngIf="!isEdit" class="modal-title w-100"> {{headingName.AddLibraryManagementSystem}}</h4>
                <h4 *ngIf="isEdit" class="modal-title w-100"> {{headingName.EditLibraryManagementSystem}}</h4>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>


            <!-- <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row">
                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label">{{labelName.AccessionRegistration}}<span
                                            class="text-danger">*</span></label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"><strong>:</strong> &nbsp;
                                            <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                formControlName="accession_registration"
                                                (change)="changeAccession($any($event).target.value)">
                                                <option value="" [selected]="true">select</option>
                                                <option value="1">Yes</option>
                                                <option value="2">No</option>
                                            </select>
                                        </div>
                                        <div *ngIf="myForm.get('accession_registration').touched">
                                            <span class="text-danger"
                                                *ngIf="myForm.get('accession_registration').hasError('required')">
                                                This field is required.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName.AccessionNo}}</label>
                                    <div class="col-md-6 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="text" placeholder="" class="form-control"
                                                formControlName="accession_text">
                                        </div>
                                        <div *ngIf="myForm.get('accession_text').touched">
                                            <span class="text-danger"
                                                *ngIf="myForm.get('accession_text').hasError('required')">
                                                This field is required.
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-2 inputGroupContainer">
                                        <div class="input-group"> <strong></strong> &nbsp;
                                            <input type="text" placeholder="" class="form-control"
                                                [(ngModel)]="counting" [ngModelOptions]="{standalone: true}" disabled>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName.AccessionDate}}</label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="date" placeholder="" class="form-control"
                                                formControlName="accession_date">
                                        </div>
                                        <div *ngIf="myForm.get('accession_date').touched">
                                            <span class="text-danger"
                                                *ngIf="myForm.get('accession_date').hasError('required')">
                                                This field is required.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label">{{labelName.Language}}<span
                                            class="text-danger">*</span></label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"><strong>:</strong> &nbsp;
                                            <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                formControlName="language_id">
                                                <option value="" selected>Select Language</option>
                                                <option *ngFor="let item of language" [value]='item.DICTIONARYID'>
                                                    {{item.DICTIONARYNAME}}
                                                </option>
                                            </select>
                                        </div>
                                        <div *ngIf="myForm.get('language_id').touched">
                                            <span class="text-danger"
                                                *ngIf="myForm.get('language_id').hasError('required')">
                                                This field is required.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label">{{labelName.MaterialType}}<span
                                            class="text-danger">*</span></label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"><strong>:</strong> &nbsp;
                                            <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                formControlName="material_type">
                                                <option value="" [selected]="true">select</option>
                                                <option [value]="1">Book</option>
                                                <option [value]="2">Non-book</option>
                                            </select>
                                        </div>
                                        <div *ngIf="myForm.get('material_type').touched">
                                            <span class="text-danger"
                                                *ngIf="myForm.get('material_type').hasError('required')">
                                                This field is required.
                                            </span>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label"> {{labelName.SubMaterialType}}<span
                                            class="text-danger">*</span></label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"><strong>:</strong> &nbsp;
                                            <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                formControlName="sub_material_type">
                                                <option value="" [selected]="true">select</option>
                                             
                                                <option value="2">E-Book</option>
                                                <option value="3">Text</option>
                                                <option value="3">CBB</option>
                                                <option [value]="1">Paper Back</option>
                                                <option [value]="2">Spiral</option>
                                                <option [value]="3">Hard Cover</option>
                                            </select>
                                        </div>
                                        <div *ngIf="myForm.get('sub_material_type').touched">
                                            <span class="text-danger"
                                                *ngIf="myForm.get('sub_material_type').hasError('required')">
                                                This field is required.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName?.BookName}} <span
                                            class="text-danger">*</span></label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="text" placeholder="" class="form-control"
                                                formControlName="BOOK_NAME"
                                                onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">
                                        </div>
                                        <div *ngIf="myForm.get('BOOK_NAME').touched">
                                            <span class="text-danger"
                                                *ngIf="myForm.get('BOOK_NAME').hasError('required')">
                                                This field is required.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName?.Author}} <span
                                            class="text-danger">*</span></label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="text" placeholder="" class="form-control"
                                                formControlName="BOOK_AUTHOR">
                                        </div>
                                        <div *ngIf="myForm.get('BOOK_AUTHOR').touched">
                                            <span class="text-danger"
                                                *ngIf="myForm.get('BOOK_AUTHOR').hasError('required')">
                                                This field is required.
                                            </span>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName.Editor}} </label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="text" placeholder="" class="form-control"
                                                formControlName="editor">
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName.ClassNo}}</label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="text" placeholder="" class="form-control"
                                                formControlName="class_no">
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label">{{labelName?.CourseName}}<span
                                            class="text-danger">*</span></label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"><strong>:</strong> &nbsp;
                                            <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                formControlName="BOOK_DEPARTMENT_ID"
                                                (change)="getSubject($any($event).target.value)">
                                                <option value="" [selected]="true">select</option>
                                                <option *ngFor="let course of courses" [value]='course.COURSE_ID'>
                                                    {{course.COURSE_NAME}}
                                                </option>
                                            </select>
                                        </div>
                                        <div *ngIf="myForm.get('BOOK_DEPARTMENT_ID').touched">
                                            <span class="text-danger"
                                                *ngIf="myForm.get('BOOK_DEPARTMENT_ID').hasError('required')">
                                                This field is required.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label">{{labelName.Subject}}<span
                                            class="text-danger">*</span></label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"><strong>:</strong> &nbsp;
                                            <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                formControlName="subject">
                                                <option value="" [selected]="true">select</option>
                                                <option *ngFor="let item of subjects" [value]='item.subject_id'>
                                                    {{item.subject_name}}
                                                </option>
                                            </select>
                                        </div>
                                        <div *ngIf="myForm.get('subject').touched">
                                            <span class="text-danger"
                                                *ngIf="myForm.get('subject').hasError('required')">
                                                This field is required.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName?.Edition}} </label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="text" placeholder="" class="form-control"
                                                formControlName="BOOK_EDITION">
                                        </div>
                                    </div>
                                </div>
                            </div>
`
                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName?.BookIDISBN}} <span
                                            class="text-danger">*</span></label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="text" placeholder="" class="form-control"
                                                formControlName="BOOK_CODE"
                                                onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">
                                        </div>
                                        <div *ngIf="myForm.get('BOOK_CODE').touched">
                                            <span class="text-danger"
                                                *ngIf="myForm.get('BOOK_CODE').hasError('required')">
                                                This field is required.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName?.Publication}} </label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="text" placeholder="" class="form-control"
                                                formControlName="BOOK_PUBLICATION">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName.PlaceOfPublication}} </label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="text" placeholder="" class="form-control"
                                                formControlName="place_of_publication">
                                        </div>
                                        <div *ngIf="myForm.get('place_of_publication').touched">
                                            <span class="text-danger"
                                                *ngIf="myForm.get('place_of_publication').hasError('required')">
                                                This field is required.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName.YearOfPublication}} </label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="text" placeholder="" class="form-control"
                                                formControlName="year_of_publication">
                                        </div>
                                        <div *ngIf="myForm.get('year_of_publication').touched">
                                            <span class="text-danger"
                                                *ngIf="myForm.get('year_of_publication').hasError('required')">
                                                This field is required.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName.PageNo}}</label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="text" placeholder="" class="form-control"
                                                formControlName="PAGE_NO">
                                        </div>
                                        <div *ngIf="myForm.get('place_of_publication').touched">
                                            <span class="text-danger"
                                                *ngIf="myForm.get('place_of_publication').hasError('required')">
                                                This field is required.
                                            </span>
                                        </div> 
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName?.NumberOfCopiesAvailable}} <span
                                            class="text-danger">*</span></label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="text" placeholder="" class="form-control"
                                                formControlName="BOOK_NO_OF_COPIES"
                                                onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">
                                        </div>
                                        <div *ngIf="myForm.get('BOOK_NO_OF_COPIES').touched">
                                            <span class="text-danger"
                                                *ngIf="myForm.get('BOOK_NO_OF_COPIES').hasError('required')">
                                                This field is required.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label"> {{labelName.Currency}}<span
                                            class="text-danger">*</span></label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"><strong>:</strong> &nbsp;
                                            <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                formControlName="currency">
                                                <option value="" [selected]="true">select</option>
                                                <option value="1">USD</option>
                                                <option value="2">Rupee</option>
                                            </select>
                                        </div>
                                    
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName?.Price}} <span
                                            class="text-danger">*</span></label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="text" placeholder="" class="form-control"
                                                formControlName="BOOK_PRICE">
                                        </div>
                                        <div *ngIf="myForm.get('BOOK_PRICE').touched">
                                            <span class="text-danger"
                                                *ngIf="myForm.get('BOOK_PRICE').hasError('required')">
                                                This field is required.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label">{{labelName.Vendor}}<span
                                            class="text-danger">*</span></label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"><strong>:</strong> &nbsp;
                                            <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                formControlName="vendor_id">
                                                <option value="" [selected]="true">select</option>
                                                <option value="" *ngFor="let item of vendors" [value]='item.vendor_id'>
                                                    {{item.vendor_name}}
                                                </option>
                                            </select>
                                        </div>
                                        <div *ngIf="myForm.get('vendor_id').touched">
                                            <span class="text-danger"
                                                *ngIf="myForm.get('vendor_id').hasError('required')">
                                                This field is required.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName.InvoiceNumber}}<span
                                            class="text-danger">*</span></label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="text" placeholder="" class="form-control"
                                                formControlName="invoice_number">
                                        </div>
                                        <div *ngIf="myForm.get('invoice_number').touched">
                                            <span class="text-danger"
                                                *ngIf="myForm.get('invoice_number').hasError('required')">
                                                This field is required.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName.InvoiceDate}}<span
                                            class="text-danger">*</span></label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="date" placeholder="" class="form-control"
                                                formControlName="invoice_date">
                                        </div>
                                        <div *ngIf="myForm.get('invoice_date').touched">
                                            <span class="text-danger"
                                                *ngIf="myForm.get('invoice_date').hasError('required')">
                                                This field is required.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName.OrderNumber}}</label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="text" placeholder="" class="form-control"
                                                formControlName="order_no">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName.OrderDate}} </label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="date" placeholder="" class="form-control"
                                                formControlName="order_date">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName.KeyWords}}<span
                                        class="text-danger">*</span></label>
                                      
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="text" placeholder="" class="form-control"
                                                formControlName="BOOK_DESCRIPTION"
                                                onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">
                                        </div>
                                        <div *ngIf="myForm.get('BOOK_DESCRIPTION').touched">
                                            <span class="text-danger"
                                                *ngIf="myForm.get('BOOK_DESCRIPTION').hasError('required')">
                                                This field is required.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName.BookLocation}}</label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="text" placeholder="" class="form-control"
                                                formControlName="book_location">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName?.BarCodeId}} <span
                                            class="text-danger">*</span></label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="text" placeholder="" class="form-control"
                                                formControlName="BAR_CODE_ID" >
                                        </div>
                                        <div *ngIf="myForm.get('BAR_CODE_ID').touched">
                                            <span class="text-danger"
                                                *ngIf="myForm.get('BAR_CODE_ID').hasError('required')">
                                                This field is required.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </fieldset>
                </form>
            </div> -->


            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row">

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label">{{labelName?.BookRack}}</label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"><strong>:</strong> &nbsp;
                                            <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                formControlName="RACK_ID"
                                                (change)="getShelf($any($event).target.value)">
                                                <option [value]=0 >select</option>
                                                <option *ngFor="let item of rack" [value]='item.br_id'>
                                                    {{item.br_name}}
                                                </option>
                                            </select>
                                        </div>
                                        <!-- <div *ngIf="myForm.get('RACK_ID').touched">
                                            <span class="text-danger"
                                                *ngIf="myForm.get('RACK_ID').hasError('required')">
                                                This field is required.
                                            </span>
                                        </div> -->
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label">{{labelName?.BookShelf}}</label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"><strong>:</strong> &nbsp;
                                            <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                formControlName="SHELF_ID">
                                                <option [value]=0 >select</option>
                                                <option *ngFor="let item of shelf" [value]='item.bs_id'>
                                                    {{item.bs_name}}
                                                </option>
                                            </select>
                                        </div>
                                        <!-- <div *ngIf="myForm.get('SHELF_ID').touched">
                                            <span class="text-danger"
                                                *ngIf="myForm.get('SHELF_ID').hasError('required')">
                                                This field is required.
                                            </span>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName?.BookCode}}<span
                                            class="text-danger">*</span></label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="text" placeholder="" class="form-control"
                                                formControlName="BOOK_CODE">
                                        </div>
                                        <div *ngIf="myForm.get('BOOK_CODE')?.touched">
                                            <span class="text-danger"
                                                *ngIf="myForm.get('BOOK_CODE').hasError('required')">
                                                This field is required.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName?.BookTitle}}<span
                                            class="text-danger">*</span></label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="text" placeholder="" class="form-control"
                                                formControlName="BOOK_NAME">
                                        </div>
                                        <div *ngIf="myForm.get('BOOK_NAME')?.touched">
                                            <span class="text-danger"
                                                *ngIf="myForm.get('BOOK_NAME').hasError('required')">
                                                This field is required.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName.Description}}<span
                                            class="text-danger">*</span></label>

                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="text" placeholder="" class="form-control"
                                                formControlName="BOOK_DESCRIPTION"
                                                onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">
                                        </div>
                                        <div *ngIf="myForm.get('BOOK_DESCRIPTION').touched">
                                            <span class="text-danger"
                                                *ngIf="myForm.get('BOOK_DESCRIPTION').hasError('required')">
                                                This field is required.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName?.Author}} <span
                                            class="text-danger">*</span></label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="text" placeholder="" class="form-control"
                                                formControlName="BOOK_AUTHOR">
                                        </div>
                                        <div *ngIf="myForm.get('BOOK_AUTHOR').touched">
                                            <span class="text-danger"
                                                *ngIf="myForm.get('BOOK_AUTHOR').hasError('required')">
                                                This field is required.
                                            </span>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName.BookPublication}}
                                    </label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="text" placeholder="" class="form-control"
                                                formControlName="BOOK_PUBLICATION">
                                        </div>
                                        <div *ngIf="myForm.get('BOOK_PUBLICATION')?.touched">
                                            <span class="text-danger"
                                                *ngIf="myForm.get('BOOK_PUBLICATION').hasError('required')">
                                                This field is required.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName?.BookEdition}} <span
                                            class="text-danger"></span></label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="text" placeholder="" class="form-control"
                                                formControlName="BOOK_EDITION"
                                                onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <!-- <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName?.NoOfCopies}} <span
                                            class="text-danger">*</span></label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="text" placeholder="" class="form-control"
                                                formControlName="BOOK_NO_OF_COPIES">
                                        </div>
                                        <div *ngIf="myForm.get('BOOK_NO_OF_COPIES')?.touched">
                                            <span class="text-danger"
                                                *ngIf="myForm.get('BOOK_NO_OF_COPIES').hasError('required')">
                                                This field is required.
                                            </span>

                                        </div>
                                    </div>
                                </div>
                            </div> -->

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName.BookPrice}} </label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="text" placeholder="" class="form-control"
                                                formControlName="BOOK_PRICE">
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label">{{labelName?.CourseName}}<span
                                            class="text-danger">*</span></label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"><strong>:</strong> &nbsp;
                                            <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                formControlName="BOOK_DEPARTMENT_ID"
                                                (change)="getSubject($any($event).target.value)">
                                                <option value="" [selected]="true">select</option>
                                                <option *ngFor="let course of courses" [value]='course.COURSE_ID'>
                                                    {{course.COURSE_NAME}}
                                                </option>
                                            </select>
                                        </div>
                                        <div *ngIf="myForm.get('BOOK_DEPARTMENT_ID').touched">
                                            <span class="text-danger"
                                                *ngIf="myForm.get('BOOK_DEPARTMENT_ID').hasError('required')">
                                                This field is required.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label">{{labelName.Subject}}<span
                                            class="text-danger">*</span></label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"><strong>:</strong> &nbsp;
                                            <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                formControlName="subject">
                                                <option [value]="" >select</option>
                                                <option *ngFor="let item of subjects" [value]='item.subject_id'>
                                                    {{item.subject_name}}
                                                </option>
                                            </select>
                                        </div>
                                        <div *ngIf="myForm.get('subject').touched">
                                            <span class="text-danger"
                                                *ngIf="myForm.get('subject').hasError('required')">
                                                This field is required.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label">{{labelName.Language}}<span
                                            class="text-danger">*</span></label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"><strong>:</strong> &nbsp;
                                            <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                formControlName="language_id">
                                                <option value="" selected>Select Language</option>
                                                <option *ngFor="let item of language" [value]='item.DICTIONARYID'>
                                                    {{item.DICTIONARYNAME}}
                                                </option>
                                            </select>
                                        </div>
                                        <div *ngIf="myForm.get('language_id').touched">
                                            <span class="text-danger"
                                                *ngIf="myForm.get('language_id').hasError('required')">
                                                This field is required.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label">{{labelName.MaterialType}}<span
                                            class="text-danger">*</span></label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"><strong>:</strong> &nbsp;
                                            <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                formControlName="material_type">
                                                <option [value]="" [selected]="true">select</option>
                                                <option [value]="1">Book</option>
                                                <option [value]="2">Non-book</option>
                                            </select>
                                        </div>
                                        <div *ngIf="myForm.get('material_type').touched">
                                            <span class="text-danger"
                                                *ngIf="myForm.get('material_type').hasError('required')">
                                                This field is required.
                                            </span>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label"> {{labelName.SubMaterialType}}<span
                                            class="text-danger">*</span></label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"><strong>:</strong> &nbsp;
                                            <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                formControlName="sub_material_type">
                                                <option [value]="" [selected]="true">select</option>

                                                <option [value]="1">E-Book</option>
                                                <option [value]="2">Text</option>
                                                <option [value]="3">CBB</option>
                                                <option [value]="4">Paper Back</option>
                                                <option [value]="5">Spiral</option>
                                                <option [value]="6">Hard Cover</option>
                                            </select>
                                        </div>
                                        <div *ngIf="myForm.get('sub_material_type').touched">
                                            <span class="text-danger"
                                                *ngIf="myForm.get('sub_material_type').hasError('required')">
                                                This field is required.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName.ClassNo}}</label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="text" placeholder="" class="form-control"
                                                formControlName="class_no">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName.PlaceOfPublication}}<span
                                            class="text-danger">*</span></label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="text" placeholder="" class="form-control"
                                                formControlName="place_of_publication">
                                        </div>
                                        <div *ngIf="myForm.get('place_of_publication').touched">
                                            <span class="text-danger"
                                                *ngIf="myForm.get('place_of_publication').hasError('required')">
                                                This field is required.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName.YearOfPublication}} <span
                                            class="text-danger">*</span></label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="text" placeholder="" class="form-control"
                                                formControlName="year_of_publication">
                                        </div>
                                        <div *ngIf="myForm.get('year_of_publication').touched">
                                            <span class="text-danger"
                                                *ngIf="myForm.get('year_of_publication').hasError('required')">
                                                This field is required.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName.PageNo}}</label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="text" placeholder="" class="form-control"
                                                formControlName="page_no">
                                        </div>
                                        <!-- <div *ngIf="myForm.get('place_of_publication').touched">
                                            <span class="text-danger"
                                                *ngIf="myForm.get('place_of_publication').hasError('required')">
                                                This field is required.
                                            </span>
                                        </div> -->
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label"> {{labelName.Currency}}<span
                                            class="text-danger">*</span></label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"><strong>:</strong> &nbsp;
                                            <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                formControlName="currency">
                                                <option [value]="" [selected]="true">select</option>
                                                <option value="1">USD</option>
                                                <option value="2">Rupee</option>
                                            </select>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label">{{labelName.Vendor}}<span
                                            class="text-danger">*</span></label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"><strong>:</strong> &nbsp;
                                            <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                formControlName="vendor_id">
                                                <option value="" [selected]="true">select</option>
                                                <option value="" *ngFor="let item of vendors" [value]='item.vendor_id'>
                                                    {{item.vendor_name}}
                                                </option>
                                            </select>
                                        </div>
                                        <div *ngIf="myForm.get('vendor_id').touched">
                                            <span class="text-danger"
                                                *ngIf="myForm.get('vendor_id').hasError('required')">
                                                This field is required.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName.InvoiceNumber}}<span
                                            class="text-danger">*</span></label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="text" placeholder="" class="form-control"
                                                formControlName="invoice_number">
                                        </div>
                                        <div *ngIf="myForm.get('invoice_number').touched">
                                            <span class="text-danger"
                                                *ngIf="myForm.get('invoice_number').hasError('required')">
                                                This field is required.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName.InvoiceDate}}<span
                                            class="text-danger">*</span></label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="date" placeholder="" class="form-control"
                                                formControlName="invoice_date">
                                        </div>
                                        <div *ngIf="myForm.get('invoice_date').touched">
                                            <span class="text-danger"
                                                *ngIf="myForm.get('invoice_date').hasError('required')">
                                                This field is required.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName.OrderNumber}}</label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="text" placeholder="" class="form-control"
                                                formControlName="order_no">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName.OrderDate}} </label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="date" placeholder="" class="form-control"
                                                formControlName="order_date">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName.BookLocation}}</label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="text" placeholder="" class="form-control"
                                                formControlName="book_location">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName.AccessionRegister}}</label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                formControlName="ACCESSION_REGISTER">
                                                <option [value]="" [selected]="true">select</option>
                                                <option [value]="1">Yes</option>
                                                <option [value]="2">No</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName.DateOfEntry}}</label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="date" placeholder="" class="form-control"
                                                formControlName="DATE_OF_ENTRY">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label">{{labelName.AcademicYear}}</label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"><strong>:</strong> &nbsp;
                                            <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                formControlName="ACADEMIC_YEAR">
                                                <option value="" [selected]="true">select</option>
                                                <option *ngFor="let academic of academics"
                                                    [value]='academic.ACADEMIC_ID'>
                                                    {{academic.ACADEMIC_NAME}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label">{{labelName.FinancialYear}}</label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"><strong>:</strong> &nbsp;
                                            <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                formControlName="FINANCIAL_YEAR">
                                                <option value="" [selected]="true">select</option>
                                                <option *ngFor="let academic of academics"
                                                    [value]='academic.ACADEMIC_ID'>
                                                    {{academic.ACADEMIC_NAME}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName.Volume}}</label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="text" placeholder="" class="form-control"
                                                formControlName="VOLUME">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group row pt-4">
                                    <label class="col-md-4 control-label ">{{labelName.Discount}}</label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="text" placeholder="" class="form-control"
                                                formControlName="DISCOUNT">
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </fieldset>
                </form>
            </div>




            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-sm btn-danger" id='md_close' (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>