import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-book-category',
  templateUrl: './book-category.component.html',
  styleUrls: ['./book-category.component.css']
})
export class BookCategoryComponent implements OnInit {
myForm:FormGroup;
table:Array<any>=[];
isEdit: boolean = false;
labelName: any = {};
headingName: any = {};
workItemId: any;
category:Array<any>=[];
editData: any;
categoryId:any;
  constructor(private toastr: ToastrService,private CommonService:CommonService,private fb: FormBuilder,private active: ActivatedRoute,) { 
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })

    this.loadlabels();
    this.loadHeadings();
    this.load();
    // this.categoryDropdown();

    
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      book_category_name:['',Validators.required],
      book_category_description:['',Validators.required],
      status:['',Validators.required]

    })
  }

  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  load() {
    this.table = [];
    let payload = {
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('EmsBookCategory/GetList', payload).subscribe((res: any) => [
      this.table = res
    ])
  }


  // categoryDropdown() {
  //   this.category = [];
  //   let payLoad = {
  //     "tnt_code": localStorage.getItem('TenantCode')
  //   }
  //   this.CommonService.postCall('EmsBookCategory/GetList', payLoad).subscribe((res: any) => {
  //     this.category = res;
  //   })
  // }

  add() {
    this.editData = {};
    this.isEdit = false;
    this.myForm.reset();
  }
  onSubmit(form){
 let value: any = form.value;
    value['tnt_code'] = localStorage.getItem('TenantCode');
    value['book_category_createdby'] = localStorage.getItem('UserId');
    value['book_category_modifiedby'] = localStorage.getItem('UserId');
    if (this.isEdit) {
      value['book_category_modifiedby'] = localStorage.getItem('UserId');
      value['book_category_id'] = this.categoryId;
      this.CommonService.postCall('EmsBookCategory/Update', value).subscribe((res: any) => {
        this.load();
        this.toastr.success("Book Category Updated Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Book Category Not Updated')
      })
    } else {
      this.CommonService.postCall('EmsBookCategory/Create', value).subscribe((res: any) => {
        this.load();
        this.toastr.success("Book Category Created Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Book Category Not created')
      })

    }
  }


  
  edit(bId: any) {
   this.categoryId = bId;
    console.log(bId);
    this.isEdit = true;
    let payLoad = {
      "book_category_id": bId
    }
    this.CommonService.postCall('EmsBookCategory/Get', payLoad).subscribe((res: any) => {
      this.editData = res;
      setTimeout(() => {
        this.dataTransfer(res);
      }, 10);
    })
  }
  
  dataTransfer(data) {
    this.myForm.patchValue({
      book_category_name: data['book_category_name'],
      book_category_description:data['book_category_description'],
      status: data['status'],
    })
  }
  close(){

  }
}
