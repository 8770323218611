<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 "> {{headingName.ReceivedQuotationsList}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div *ngIf="table.length">
                                            <table id="patreggraph" datatable
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #0d9bc4; color:#ffffff;">
                                                    <tr>
                                                        <th> Quotation Number </th>
                                                        <th> Quoted Total </th>
                                                        <th> Request Date </th>
                                                        <!-- <th> Response Date </th>
                                                        <th> Approval Date </th> -->
                                                        <th> Status </th>
                                                        <th> Actions </th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.quotation_number}}</td>
                                                        <td>{{item.quoted_total}}</td>
                                                        <td>{{item.request_date | date:'dd/MM/yyyy'}}</td>
                                                        <!-- <td>{{item.response_date}}</td>
                                                        <td>{{item.approval_date}}</td> -->
                                                        <td>{{item.quotation_status}}</td>
                                                        <!-- *ngIf="item.quotation_status == 'Quotation Recived'" 
                                                        *ngIf="item.quotation_status == 'pending'"         -->
                                                        <td>
                                                            <button *ngIf="item.quotation_status == 'Received'"
                                                                data-bs-toggle="modal" data-bs-target="#myModal"
                                                                (click)="getVendors(item.quotation_id)"
                                                                class="btn btn-sm btn-ai">Approve Quotation</button>
                                                            <button class="btn btn-sm btn-ai"
                                                                *ngIf="item.quotation_status == 'Quotation Approved'"
                                                                (click)="recivedItems(item.quotation_id,item.quotation_number)">Recieved
                                                                Items</button> &nbsp;
                                                            <button class="btn btn-sm btn-ai"
                                                                *ngIf="item.quotation_status == 'Quotation Approved'"
                                                                (click)="payAmount(item.quotation_id,item.quotation_number)">Pay
                                                                Amount</button>
                                                            <button class="btn btn-sm btn-ai"
                                                                *ngIf="item.quotation_status == 'Quotation Closed'"
                                                                (click)="payAmount(item.quotation_id,item.quotation_number)">Pay
                                                                Amount</button>
                                                        </td>
                                                    </tr>

                                                </tbody>

                                            </table>
                                        </div>
                                        <div *ngIf="!table.length" class="text-center">
                                            No Records to display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




<div class="modal" id="myModal" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <div class="modal-header py-2">
                <h5 class=" modal-title w-100">Send Quotation</h5>

                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>


            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="Approve(myForm)">
                    <fieldset>
                        <!-- <div class="row form-group mb-2">
                            <label class="col-md-4 form-label"> Select</label>
                            <label class="col-md-4 form-label"> Vendors</label>
                            <div class="row">
                                <div class="col-md-8 input-group">
                                   <input type="checkbox" formControlName="approve" >
                                </div>
                                <div class="col-md-8 input-group">
                                    <a href="">Vendor Name</a>
                                </div>

                            </div>
                        </div> -->
                        <div class="row form-group mb-2">
                            <label class="col-md-6 form-label"> Select</label>
                            <label class="col-md-6 form-label"> Vendors</label>
                        </div>
                        <div class="row form-group " *ngFor="let item of vendors">
                            <div class="input-group">
                                <input class="col-md-6" (change)="checkVendors($event,item.vendor_id)" type="checkbox"
                                    formControlName="approve">
                                <a href="javascript:;" (click)="getVendorList(item.vendor_id)"
                                    class="col-md-6">{{item.vendor_name}}</a>
                            </div>

                        </div>
                        <br>
                        <br>
                        <div *ngIf="isTable">
                            <table class="table">
                                <thead>
                                    <tr>

                                        <th scope="col">Item</th>
                                        <th scope="col">Quantity</th>
                                        <th scope="col">Quotated Cost</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of vendorsList">
                                        <td>{{item.item_name}}</td>
                                        <td>{{item.quotated_quantity}}</td>
                                        <td>{{item.quoted_cost}}</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </fieldset>
                </form>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-sm  btn-save" (click)="Approve(myForm)"
                    [disabled]="myForm.invalid">Update</button>

                <button type="button" class="btn btn-sm btn-danger" id='md_close' (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>


        </div>

    </div>

</div>