<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.BookTransfer}}</h5>
                                    </div>

                                    <div class="card-body">
                                        <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="Update(myForm)">
                                            <fieldset>
                                                <div class="form-group row pt-4">
                                                    <label class="col-md-4 control-label mt-2">
                                                        {{labelName.FromRack}}<span
                                                            class="text-danger">*</span></label>
                                                    <div class="col-md-8 inputGroupContainer">
                                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                                            <select (change)="ChangeFromRack($any($event.target).value)"
                                                                class="form-select form-select-sm shadow-sm rounded-lg"
                                                                formControlName='FromRack'>
                                                                <option value="" [selected]="true">select</option>
                                                                <option [value]='item.br_id'
                                                                    *ngFor='let item of fromRackList'>
                                                                    {{item.br_name}}</option>
                                                            </select>
                                                        </div>
                                                        <div *ngIf="myForm.get('FromRack').touched">
                                                            <span class="text-danger"
                                                                *ngIf="myForm.get('FromRack').hasError('required')">
                                                                This field is required.
                                                            </span>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group row pt-4">
                                                    <label class="col-md-4 control-label mt-2">
                                                        {{labelName.FromShelf}}<span
                                                            class="text-danger">*</span></label>
                                                    <div class="col-md-8 inputGroupContainer">
                                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                                            <select (change)="ChangefromShelf($any($event.target).value)"
                                                                class="form-select form-select-sm shadow-sm rounded-lg"
                                                                formControlName='FromShelf'>
                                                                <option value="" [selected]="true">select</option>
                                                                <option [value]='item.bs_id'
                                                                *ngFor='let item of fromShelfList'>
                                                                {{item.bs_name}}</option>
                                                            </select>
                                                        </div>
                                                        <div *ngIf="myForm.get('FromShelf').touched">
                                                            <span class="text-danger"
                                                                *ngIf="myForm.get('FromShelf').hasError('required')">
                                                                This field is required.
                                                            </span>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group row pt-4">
                                                    <label class="col-md-4 control-label mt-2">
                                                        {{labelName.Books}}<span
                                                            class="text-danger">*</span></label>
                                                    <div class="col-md-8 inputGroupContainer">
                                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                                            <select
                                                                class="form-select form-select-sm shadow-sm rounded-lg"
                                                                formControlName='Books'>
                                                                <option value="" [selected]="true">select</option>
                                                                <option [value]='item.book_id'
                                                                *ngFor='let item of booksList'>
                                                                {{item.BOOK_NAME}}</option>
                                                            </select>
                                                        </div>
                                                        <div *ngIf="myForm.get('Books').touched">
                                                            <span class="text-danger"
                                                                *ngIf="myForm.get('Books').hasError('required')">
                                                                This field is required.
                                                            </span>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group row pt-4">
                                                    <label class="col-md-4 control-label mt-2">
                                                        {{labelName.ToRack}}<span
                                                            class="text-danger">*</span></label>
                                                    <div class="col-md-8 inputGroupContainer">
                                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                                            <select (change)="changeToRack($any($event.target).value)"
                                                                class="form-select form-select-sm shadow-sm rounded-lg"
                                                                formControlName='ToRack'>
                                                                <option value="" [selected]="true">select</option>
                                                                <option [value]='item.br_id'
                                                                *ngFor='let item of toRackList'>
                                                                {{item.br_name}}</option>
                                                            </select>
                                                        </div>
                                                        <div *ngIf="myForm.get('ToRack').touched">
                                                            <span class="text-danger"
                                                                *ngIf="myForm.get('ToRack').hasError('required')">
                                                                This field is required.
                                                            </span>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group row pt-4">
                                                    <label class="col-md-4 control-label mt-2">
                                                        {{labelName.ToShelf}}<span
                                                            class="text-danger">*</span></label>
                                                    <div class="col-md-8 inputGroupContainer">
                                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                                            <select 
                                                                class="form-select form-select-sm shadow-sm rounded-lg"
                                                                formControlName='ToShelf'>
                                                                <option value="" [selected]="true">select</option>
                                                                <option [value]='item.bs_id'
                                                                *ngFor='let item of toShelfList'>
                                                                {{item.bs_name}}</option>
                                                            </select>
                                                        </div>
                                                        <div *ngIf="myForm.get('ToShelf').touched">
                                                            <span class="text-danger"
                                                                *ngIf="myForm.get('ToShelf').hasError('required')">
                                                                This field is required.
                                                            </span>

                                                        </div>
                                                    </div>
                                                </div>

                                            </fieldset>
                                        </form>
                                    </div>
                                    <div class="card-footer" style="text-align: center;">
                                        <button type="button" [disabled]="myForm.invalid" (click)='Update(myForm)'
                                            class="btn btn-sm  btn-save">Update</button>

                                        &nbsp; <button type="button" (click)="back()"
                                            class="btn btn-sm btn-danger">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>