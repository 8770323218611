import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { combineLatest } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-item-category',
  templateUrl: './item-category.component.html',
  styleUrls: ['./item-category.component.css']
})
export class ItemCategoryComponent implements OnInit {
  myForm: FormGroup
  table: Array<any> = [];
  isEdit: boolean = false;
  itemCategoryId: any;
  editData = {};
  labelName: any = {};
  headingName: any = {};
  workItemId: any;
  constructor(private ref: ChangeDetectorRef, private fb: FormBuilder, private route: Router, private CommonService: CommonService, private toastr: ToastrService, private active: ActivatedRoute,) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadCategory();
    this.loadlabels();
    this.loadHeadings();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      name: ['', [Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      status: ['', Validators.required],
    });
  }
  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  loadCategory() {
    let payload =
    {
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.table = [];
    this.CommonService.postCall('EmsItemCategory/GetList', payload).subscribe((res: any) => {
      this.table = res;
    })

  }
  onSubmit(form: FormGroup) {
    let value = form.value;
    value['tnt_code'] = localStorage.getItem('TenantCode');
    value['created_by'] = localStorage.getItem('UserId');
    value['modified_by'] = localStorage.getItem('UserId');
    console.log(value)
    if (this.isEdit) {
      value['modified_by'] = localStorage.getItem('UserId');
      value['items_categories_id'] = this.itemCategoryId;
      this.CommonService.postCall('EmsItemCategory/Update', value).subscribe((res: any) => {
        this.loadCategory();
        this.toastr.success("Item Category Updated Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Item category Not Updated')
      })
    } else {
      this.CommonService.postCall('EmsItemCategory/Create', value).subscribe((res: any) => {
        this.loadCategory();
        this.toastr.success("Item Category Created Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Item Category Not created')

      })
    }
  }

  edit(icId: any) {
    this.itemCategoryId = icId;
    console.log(icId);
    this.isEdit = true;
    this.CommonService.postCall('EmsItemCategory/Get', { "items_categories_id": icId }).subscribe((res: any) => {
      this.editData = res;
      setTimeout(() => {
        this.dataTransfer(res);
      }, 10);
    })
  }

  dataTransfer(data) {
    this.myForm.patchValue({
      name: data['name'],
      description: data['description'],
      status: data['status'],
    })
  }
  close() {
    this.isEdit = null;
    this.editData = {};
    this.myForm.reset();
  }
}
