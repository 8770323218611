<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.EditTimeTable}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="custom_container">
                                            <fieldset>
                                                <div class="form-group row">
                                                    <label
                                                        class="col-md-4 control-label ">{{labelName?.Addmoreperiods}}<span
                                                            class="text-danger">*</span></label>
                                                    <div class="col-md-8 inputGroupContainer">
                                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                                            <select
                                                                class="form-select form-select-sm shadow-sm rounded-lg"
                                                                (change)="addNewPeriod($event)">
                                                                <option [value]="0">Select Period</option>
                                                                <option [value]="1">1</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row pt-4">
                                                    <div class="col-md-4 control-label"><label>{{labelName?.Class}}
                                                            :</label></div>
                                                    <div class="col-md-8" style="text-align:left;">
                                                        <span>{{editData?.course_name}}</span>
                                                    </div>
                                                </div>
                                                <!-- <div class="row pt-4">
                                                    <div class="col-md-4 control-label"><label>Section :</label>
                                                    </div>
                                                    <div class="col-md-8" style="text-align:left;">
                                                        <span>{{editData?.courseshd_name}}</span>
                                                    </div>
                                                </div> -->


                                                <div class="form-group row pt-4" *ngIf="viewTable">
                                                    <table class="table table-striped table-bordered table-responsive">
                                                        <thead class="top"
                                                            style="background-color: #624fd7; color:#ffffff; width: 100%;">
                                                            <tr>
                                                                <th>Periods</th>
                                                                <th>Monday</th>
                                                                <th>Tuesday </th>
                                                                <th>Wednesday</th>
                                                                <th>Thursday</th>
                                                                <th>Friday</th>
                                                                <th>Saturday</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                *ngFor="let item of periodList;let i=index; trackBy:trackByFn">
                                                                <td>
                                                                    <span>Period {{i+1}}</span><br />
                                                                    <label for="">Start Time :</label>
                                                                    <input type="time"
                                                                        [(ngModel)]="item[0].period_start_time">
                                                                    <br>
                                                                    <label for="">End Time &nbsp; :</label>
                                                                    <input type="time"
                                                                        [(ngModel)]="item[0].period_end_time">
                                                                </td>
                                                                <td>
                                                                    <a *ngIf="item[0].teacher_id>0 && item[0].subject_id>0"
                                                                        href="javascript: void(0)"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#editTimeTable"
                                                                        (click)="assignTeacher(item[0],i)">
                                                                        <div>
                                                                            <span>Subject:<strong>{{item[0].subject_name}}</strong>
                                                                            </span><br />
                                                                            <span>Teacher:<strong>{{item[0].teacher_name}}</strong>
                                                                            </span>
                                                                        </div>
                                                                    </a>
                                                                    <a *ngIf="item[0].teacher_id==0 && item[0].subject_id==0"
                                                                        href="javascript: void(0)"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#newAssignPeriod"
                                                                        (click)="assignTeacher(item[0],i)">
                                                                        <div>
                                                                            <span>assign</span>
                                                                        </div>
                                                                    </a>
                                                                </td>
                                                                <td>
                                                                    <a *ngIf="item[1].teacher_id>0 && item[1].subject_id>0"
                                                                        href="javascript: void(0)"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#editTimeTable"
                                                                        (click)="assignTeacher(item[1],i)">
                                                                        <div>
                                                                            <span>Subject:<strong>{{item[1].subject_name}}</strong>
                                                                            </span><br />
                                                                            <span>Teacher:<strong>{{item[1].teacher_name}}</strong>
                                                                            </span>
                                                                        </div>
                                                                    </a>
                                                                    <a *ngIf="item[1].teacher_id==0 && item[1].subject_id==0"
                                                                        href="javascript: void(0)"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#newAssignPeriod"
                                                                        (click)="assignTeacher(item[1],i)">
                                                                        <div>
                                                                            <span>assign</span>
                                                                        </div>
                                                                    </a>
                                                                    <!-- <input readonly (click)="getIndex(i,'tue')"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#Aasign"
                                                                            formControlName="2" /> -->

                                                                </td>
                                                                <td>
                                                                    <a *ngIf="item[2].teacher_id>0 && item[2].subject_id>0"
                                                                        href="javascript: void(0)"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#editTimeTable"
                                                                        (click)="assignTeacher(item[2],i)">
                                                                        <div>
                                                                            <span>Subject:<strong>{{item[2].subject_name}}</strong>
                                                                            </span><br />
                                                                            <span>Teacher:<strong>{{item[2].teacher_name}}</strong>
                                                                            </span>
                                                                        </div>
                                                                    </a>
                                                                    <a *ngIf="item[2].teacher_id==0 && item[2].subject_id==0"
                                                                        href="javascript: void(0)"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#newAssignPeriod"
                                                                        (click)="assignTeacher(item[2],i)">
                                                                        <div>
                                                                            <span>assign</span>
                                                                        </div>
                                                                    </a>
                                                                    <!-- <input readonly (click)="getIndex(i,'wed')"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#Aasign"
                                                                            formControlName="3" /> -->
                                                                </td>
                                                                <td>
                                                                    <a *ngIf="item[3].teacher_id>0 && item[3].subject_id>0"
                                                                        href="javascript: void(0)"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#editTimeTable"
                                                                        (click)="assignTeacher(item[3],i)">
                                                                        <div>
                                                                            <span>Subject:<strong>{{item[3].subject_name}}</strong>
                                                                            </span><br />
                                                                            <span>Teacher:<strong>{{item[3].teacher_name}}</strong>
                                                                            </span>
                                                                        </div>
                                                                    </a>
                                                                    <a *ngIf="item[3].teacher_id==0 && item[3].subject_id==0"
                                                                        href="javascript: void(0)"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#newAssignPeriod"
                                                                        (click)="assignTeacher(item[3],i)">
                                                                        <div>
                                                                            <span>assign</span>
                                                                        </div>
                                                                    </a>
                                                                    <!-- <input readonly (click)="getIndex(i,'thu')"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#Aasign"
                                                                            formControlName="4" /> -->
                                                                </td>
                                                                <td>
                                                                    <a *ngIf="item[4].teacher_id>0 && item[4].subject_id>0"
                                                                        href="javascript: void(0)"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#editTimeTable"
                                                                        (click)="assignTeacher(item[4],i)">
                                                                        <div>
                                                                            <span>Subject:<strong>{{item[4].subject_name}}</strong>
                                                                            </span><br />
                                                                            <span>Teacher:<strong>{{item[4].teacher_name}}</strong>
                                                                            </span>
                                                                        </div>
                                                                    </a>
                                                                    <a *ngIf="item[4].teacher_id==0 && item[4].subject_id==0"
                                                                        href="javascript: void(0)"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#newAssignPeriod"
                                                                        (click)="assignTeacher(item[4],i)">
                                                                        <div>
                                                                            <span>assign</span>
                                                                        </div>
                                                                    </a>
                                                                    <!-- <input readonly (click)="getIndex(i,'fri')"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#Aasign"
                                                                            formControlName="5" /> -->
                                                                </td>
                                                                <td>
                                                                    <a *ngIf="item[5].teacher_id>0 && item[5].subject_id>0"
                                                                        href="javascript: void(0)"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#editTimeTable"
                                                                        (click)="assignTeacher(item[5],i)">
                                                                        <div>
                                                                            <span>Subject:<strong>{{item[5].subject_name}}</strong>
                                                                            </span><br />
                                                                            <span>Teacher:<strong>{{item[5].teacher_name}}</strong>
                                                                            </span>
                                                                        </div>
                                                                    </a>
                                                                    <a *ngIf="item[5].teacher_id==0 && item[5].subject_id==0"
                                                                        href="javascript: void(0)"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#newAssignPeriod"
                                                                        (click)="assignTeacher(item[5],i)">
                                                                        <div>
                                                                            <span>assign</span>
                                                                        </div>
                                                                    </a>
                                                                    <!-- <input readonly (click)="getIndex(i,'sat')"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#Aasign"
                                                                            formControlName="6" /> -->
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </fieldset>
                                            <div class="text-center pt-4">

                                                <button type="button" (click)="onSubmit()"
                                                    class="btn btn-sm  btn-save">Update</button>
                                                &nbsp;&nbsp;
                                                <button type="button" class="btn btn-sm btn-danger" (click)="close()"
                                                    id="close">cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="newAssignPeriod" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header py-2">
                <h6 class="modal-title w-100"> {{headingName.AssignSubject}}</h6>
                <button type="button" (click)="closeAssignSubject()" class="close" data-bs-dismiss="modal"
                    aria-bs-hidden="true"><i class="fas fa-times"></i></button>
            </div>


            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myAssignSubjectForms"
                    (ngSubmit)="SubmitEdit(myAssignSubjectForms)">
                    <fieldset>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Teachers <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName="teacher_id"
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option *ngFor='let item of trainer' [value]="item.userid">
                                            {{item.trainer_name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Subject <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName='subject'
                                        class="form-control form-control-sm shadow-sm rounded-lg ">
                                        <option value="" [selected]="true">select</option>
                                        <option *ngFor="let item of subjects" [value]='item.subject_id'>
                                            {{item.subject_name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4" >
                            <label class="col-md-4 control-label line_2 rem_1">
                               Rooms <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName="room_id"
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option *ngFor='let item of rooms' [value]="item.room_id">
                                            {{item.room_number}}
                                        </option>
                                    </select>
                                </div>
                                <!-- <div *ngIf="myForm.get('teacher_id').touched">
                                    <span class="text-danger" *ngIf="myForm.get('teacher_id').hasError('required')">
                                        This field is required.
                                    </span>

                                </div> -->
                            </div>
                        </div>
                    
                        
                    </fieldset>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-save btn-sm" (click)="SubmitEdit(myAssignSubjectForms)"
                    [disabled]="myAssignSubjectForms.invalid">Save</button>

                <button type="button" class="btn btn-danger btn-sm" id='assignSub_close' (click)="closeAssignSubject()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="editTimeTable" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header py-2">
                <h6 class="modal-title w-100">{{headingName.EditTimeTable}}</h6>
                <button type="button" (click)="closeEdit()" class="close" data-bs-dismiss="modal"
                    aria-bs-hidden="true"><i class="fas fa-times"></i></button>
            </div>


            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="editTimeTableForms"
                    (ngSubmit)="SubmitEditTimeTable(editTimeTableForms)">
                    <fieldset>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Type <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <select formControlName="type" class="form-control form-control-sm shadow-sm rounded-lg"
                                    (change)="editType=$event.target.value">
                                    <option value="" selected>Select Type</option>
                                    <option value="Rescheduled">Rescheduled</option>
                                    <option value="Suspended">Suspended</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Date <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <input type="date" placeholder="Select Date" formControlName="date"
                                    class="form-control " />
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Reason <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <textarea placeholder="Please specify the reason" formControlName="reason"
                                    class="form-control "></textarea>
                            </div>
                        </div>
                        <div class="form-group row pt-4" *ngIf="editType=='Rescheduled'">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Teachers </label>
                            <div class="col-md-8 inputGroupContainer">
                                <select formControlName="teacher"
                                    class="form-control form-control-sm shadow-sm rounded-lg">
                                    <option value="" selected>Select</option>
                                    <option *ngFor='let item of trainer' [value]="item.userid">
                                        {{item.trainer_name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row pt-4" *ngIf="editType=='Rescheduled'">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Subject </label>
                            <div class="col-md-8 inputGroupContainer">
                                <select formControlName='subject'
                                    class="form-control form-control-sm shadow-sm rounded-lg ">
                                    <option value="" [selected]="true">select</option>
                                    <option *ngFor="let item of subjects" [value]='item.subject_id'>
                                        {{item.subject_name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    

                    
                    </fieldset>
                </form>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-save btn-sm" (click)="SubmitEditTimeTable(editTimeTableForms)"
                    [disabled]="editTimeTableForms.invalid">Save</button>

                <button type="button" class="btn btn-danger btn-sm" id='md_close' (click)="closeEdit()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>