<br>
<div class="page-wrapper">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.ApprovalList}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <!-- <div class="text-right">
                                <button type="button" class="btn btn-primary mb-1"  data-toggle="modal"
                                    data-target="#myModal" (click)="add()" >Add</button>      
                            </div> -->
                                        <!-- <div >
                                            <table id="approvalList" datatable [dtOptions]="dtOptions"
                                            [dtTrigger]="dtTrigger"
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #0d9bc4; color:#ffffff;">
                                                    <tr> -->

                                                        <div id="approvalList" *ngIf="table.length" class="table-responsive">
                                                            <table id="patergraph" datatable
                                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small display">
                                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                                    <tr>
                                                        
                                                        <th> Roll Number </th>
                                                        <th>  Name </th>
                                                        <th> Father Name </th>
                                                        <th> Mobile Number </th>
                                                        <th> Present Address </th>
                                                        <th> Roll Name </th>
                                                        <th> Route Name </th>
                                                        <th> Class Name</th>                                                       
                                                        <th> Approved Status </th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>


                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.rollnumber }}</td>
                                                        <td>{{item.student_name }}</td>
                                                        <td>{{item.parentname}}</td>
                                                        <td>{{item.mobileno }}</td>
                                                        <td>{{item.addressdetails }}</td>
                                                        <td>{{item.role_name}}</td>
                                                        <td>{{item.route_name}}</td>
                                                        <td>{{item.course_name }}</td>                                                       
                                                        <td>{{item.transport_approve_status ? 'Yes' : 'No'}}</td>
                                                        <td style="display: flex; text-align: center;">
                                                            <!-- <a data-bs-toggle="modal" data-bs-target="#myModal"
                                                                (click)="edit(item.staff_conveyance_id)"><i
                                                                    class="zmdi zmdi-edit zmdi-hc-2x">
                                                                </i></a>&nbsp; -->
                                                            <button class="btn btn-warning"
                                                                *ngIf="item.transport_approve_status"
                                                                (click)="reject(item.userid ,!item.transport_approve_status)">Reject</button>
                                                            <button class="btn btn-warning"
                                                                *ngIf="!item.transport_approve_status" 
                                                                (click)="reject(item.userid ,!item.transport_approve_status)">Approve</button>
                                                        </td>
                                                    </tr>


                                                </tbody>

                                            </table>
                                        </div>
                                        <!-- <div  class="text-center">
                                            No Records to display
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>