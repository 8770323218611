import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { dataDictionary } from 'src/app/dataDictionary';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-library-management-system',
  templateUrl: './library-management-system.component.html',
  styleUrls: ['./library-management-system.component.css']
})
export class LibraryManagementSystemComponent implements OnInit {
  table: Array<any> = [];
  courses: Array<any> = [];
  myForm: FormGroup;
  isEdit: boolean = null;
  editData: any;
  BOOK_DEPARTMENT_ID: any;
  labelName: any = {};
  counting: number = 0;
  language: Array<any> = [];
  vendors: Array<any> = [];
  subjects: Array<any> = [];
  accession_no: any;
  headingName: any = {};
  workItemId: any;
  academics: Array<any> = [];
  rack:Array<any>=[];
 shelf:Array<any>=[];
  constructor(private route: Router,private fb: FormBuilder,private active : ActivatedRoute, private CommonService: CommonService, private toastr: ToastrService) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.load();
    this.getLabels();
    this.loadCourse();
    this.getLanguage();
    // this.getLanguage();
    this.vendorDropdown();
    this.loadHeadings();
    this.getAcademic();
    this.rackDropdown();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      RACK_ID:[0],
      SHELF_ID:[0],
      BOOK_CODE: ['',Validators.required],
      BOOK_NAME: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      BOOK_DESCRIPTION: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      BOOK_TITLE:[''],
      BOOK_AUTHOR: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      BOOK_PUBLICATION:[''],
      BOOK_EDITION:[''],
      // BOOK_NO_OF_COPIES: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      BOOK_PRICE:[0],
      BOOK_AVAILABILITY_STATUS:[''],
      BOOK_DEPARTMENT_ID:['',Validators.required],
      subject:['',Validators.required],
      language_id:['',Validators.required],
      material_type:['',Validators.required],
      sub_material_type:['',Validators.required],
      class_no:[''],
      place_of_publication: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      year_of_publication: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      page_no:[''],
      currency: [''],
      vendor_id:['',Validators.required],
      invoice_number:['',Validators.required],
      invoice_date:['',Validators.required],
      order_no:[''],
      order_date:[null],
      book_location:[''],
      ACCESSION_REGISTER:[0],
      DATE_OF_ENTRY:[null],
      ACADEMIC_YEAR:[0],
      FINANCIAL_YEAR:[0],
      VOLUME:[''],
      DISCOUNT:[0]
    })
  }

    
      // accession_registration: ['', Validators.required],
      // accession_date: ['', Validators.required],
      // material_type: ['', Validators.required],
      // language_id: ['', Validators.required],
      // sub_material_type: ['', Validators.required],
      // editor: [''],
      // subject: ['', Validators.required],
      // year_of_publication: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      // place_of_publication: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      // class_no: [''],
      // vendor_id: ['', Validators.required],
      // order_date: [''],
      // order_number: [''],
      // invoice_date: ['', Validators.required],
      // invoice_number: ['', Validators.required],
      // accession_text: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      // BOOK_CODE: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      // BOOK_NAME: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      // BOOK_AUTHOR: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      // BOOK_PUBLICATION: [''],
      // BOOK_DEPARTMENT_ID: ['', Validators.required],
      // BOOK_EDITION: [''],
      // BOOK_DESCRIPTION: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      // BOOK_NO_OF_COPIES: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      // BOOK_PRICE: ['', Validators.required],
      // PAGE_NO: ['', Validators.required],
      // currency: [''],
      // book_location: ['', Validators.required],
      // BAR_CODE_ID:['',Validators.required]
  
    // this.categorydropdown();
  
  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  loadCourse() {
    this.activiceSpinner();
    this.CommonService.getAdminCourses().subscribe((res: any) => {
      this.courses = res;
      this.deactivateSpinner();
    }, e => {
      this.deactivateSpinner();
    })

  }
  getSubject(id: any) {
    this.subjects = [];
    this.CommonService.getCall(`Subject/LoadSubjectsByCourse/${id}`).subscribe((res: any) => {
      this.subjects = res;
    })
  }

  getLanguage() {
    this.language = [];
    this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.Language }).subscribe((res: any) => {
      this.language = res;
    }, e => { });

  }

   vendorDropdown() {
    let payload = {
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('Vendor/VendorDropDownList', payload).subscribe((res: any) => {
      this.vendors = res;
    })
  }

  getAcademic() {
    this.CommonService.getCall('Academic/GetAcademicDropDownList/' + localStorage.getItem('TenantCode')).subscribe((res: any) => {
      this.academics = res;
      this.deactivateSpinner()
    }, e => {
      this.deactivateSpinner()
    })
  }
rackDropdown(){
  this.rack=[];
  let payload={
    "br_tnt_code": localStorage.getItem('TenantCode')
  }
  this.CommonService.postCall('BookRackMaster/GetList', payload).subscribe((res: any) => {
    this.rack=res;
  })
}


  getShelf(rack_id:any){
    this.shelf=[];
this.CommonService.getCall(`BookShelf/LoadShelfsByRack/${rack_id}/${localStorage.getItem('TenantCode')}`).subscribe((res:any)=>{
this.shelf=res
})
  }
  load() {
    this.activiceSpinner();
    let payLoad: any = {
      BOOK_TNT_CODE: localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('LibraryManagement/GetList', payLoad).subscribe((res: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = res;
      }, 10)
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner(), console.log(e) })
  }

  activiceSpinner() {
    this.CommonService.activateSpinner();
  }
  deactivateSpinner() {
    this.CommonService.deactivateSpinner();
  }

  onSubmit(form) {
    let value: any = form.value;  
    let payload={
      "RACK_ID":value.RACK_ID == "" || value.RACK_ID == null ? 0 :value.RACK_ID,
      "SHELF_ID":value.SHELF_ID == "" || value.SHELF_ID == null ? 0 :value.SHELF_ID,
      "BOOK_CODE":value.BOOK_CODE,
      "BOOK_NAME":value.BOOK_NAME,
      "BOOK_DESCRIPTION":value.BOOK_DESCRIPTION,
      "BOOK_TITLE":value.BOOK_TITLE,
      "BOOK_AUTHOR":value.BOOK_AUTHOR,
      "BOOK_PUBLICATION":value.BOOK_PUBLICATION,
      "BOOK_EDITION":value.BOOK_EDITION,
      "BOOK_NO_OF_COPIES":value.BOOK_NO_OF_COPIES,
      "BOOK_PRICE":value.BOOK_PRICE == null || value.BOOK_PRICE == '' ? 0 : value.BOOK_PRICE,
      "BOOK_AVAILABILITY_STATUS":true,
      "BOOK_DEPARTMENT_ID":value.BOOK_DEPARTMENT_ID,
       "subject":value.subject,
       "language_id":value.language_id,
       "material_type":value.material_type,
       "sub_material_type":value.sub_material_type,
       "class_no":value.class_no,
       "place_of_publication":value.place_of_publication,
       "year_of_publication":value.year_of_publication,
       "page_no":value.page_no,
       "currency":value.currency,
       "vendor_id":value.vendor_id,
       "invoice_number":value.invoice_number,
       "invoice_date":value.invoice_date,
       "order_number":value.order_no,
       "order_date":value.order_date == "" || value.order_date == "Invalid date"? null: value.order_date,
       "book_location":value.book_location,
       "ACCESSION_REGISTER":value.ACCESSION_REGISTER == null || value.ACCESSION_REGISTER == '' ? 0 : value.ACCESSION_REGISTER,
       "DATE_OF_ENTRY":value.DATE_OF_ENTRY == "" || value.DATE_OF_ENTRY == "Invalid date" ? null :value.DATE_OF_ENTRY,
       "ACADEMIC_YEAR":value.ACADEMIC_YEAR == null || value.ACADEMIC_YEAR == '' ? 0 : value.ACADEMIC_YEAR,
       "FINANCIAL_YEAR":value.FINANCIAL_YEAR == null || value.FINANCIAL_YEAR == '' ? 0 : value.FINANCIAL_YEAR,
       "VOLUME":value.VOLUME,
       "DISCOUNT":value.DISCOUNT== null || value.DISCOUNT== ''? 0 : value.DISCOUNT,
      "BOOK_CREATED_BY":localStorage.getItem('UserId'),
      "BOOK_TNT_CODE":localStorage.getItem('TenantCode')
    }
  
    if (this.isEdit) {
      payload['BOOK_ID'] = this.editData.book_id;
      this.CommonService.postCall('LibraryManagement/Update', payload).subscribe((res: any) => {
        this.load();
        this.toastr.success("Book Updated Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Book Not Updated')
      })
    } else {

      this.CommonService.postCall('LibraryManagement/Create', payload).subscribe((res: any) => {
        this.load();
        this.toastr.success("Book Created Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Book Not created')

      })
    }
  }


  edit(bookId) {
    this.editData = bookId;
    this.isEdit = true;
    this.myForm.reset();
    let payLoad = {
      "BOOK_ID": bookId
    }
    this.CommonService.postCall('LibraryManagement/Get', payLoad).subscribe((res: any) => {
        this.editData = res;
        this.getSubject(this.editData['BOOK_DEPARTMENT_ID']);
        this.getShelf(this.editData['RACK_ID']);
        setTimeout(() => {
          this.dataTransForm();
        }, 100) 
    }
    )
  }

  addAssessment(cId: any) {
    this.route.navigate(['home/bookMasters'], { queryParams: { id: cId, wId: this.workItemId } });
  }

  dataTransForm(){
    setTimeout(() => {
      let ctrls = this.myForm.controls
      Object.keys(ctrls).map((key) => {
        let ctrl: AbstractControl = ctrls[key];
        ctrl.setValue(this.editData[key])
      });
      ctrls['invoice_date'].setValue(moment(this.editData['invoice_date']).format('yyyy-MM-DD'));
      ctrls['order_date'].setValue(moment(this.editData['order_date']).format('yyyy-MM-DD'));
      ctrls['DATE_OF_ENTRY'].setValue(moment(this.editData['DATE_OF_ENTRY']).format('yyyy-MM-DD'));
    }, 200);
    // ctrls['RACK_ID'].setValue(this.editData['RACK_ID']);
    // ctrls['SHELF_ID'].setValue(this.editData['SHELF_ID']);
    // ctrls['BOOK_CODE'].setValue(this.editData['BOOK_CODE']);
    // ctrls['BOOK_NAME'].setValue(this.editData['BOOK_NAME']);
    // ctrls['BOOK_DESCRIPTION'].setValue(this.editData['BOOK_DESCRIPTION']);
    // ctrls['BOOK_TITLE'].setValue(this.editData['BOOK_TITLE']);
    // ctrls['BOOK_AUTHOR'].setValue(this.editData['BOOK_AUTHOR']);
    // ctrls['BOOK_PUBLICATION'].setValue(this.editData['BOOK_PUBLICATION']);
    // ctrls['BOOK_EDITION'].setValue(this.editData['BOOK_EDITION']);
    // ctrls['BOOK_NO_OF_COPIES'].setValue(this.editData['BOOK_NO_OF_COPIES']);
    // ctrls['BOOK_PRICE'].setValue(this.editData['BOOK_PRICE']);
    // ctrls['BOOK_AVAILABILITY_STATUS'].setValue(this.editData['BOOK_AVAILABILITY_STATUS']);
    // ctrls['BOOK_DEPARTMENT_ID'].setValue(this.editData['BOOK_DEPARTMENT_ID']);
    // ctrls['subject']?.setValue(this.editData['subject']);
    // ctrls['language_id'].setValue(this.editData['language_id']);
    // ctrls['invoice_date'].setValue(moment(this.editData['invoice_date']).format('yyyy-MM-DD'));
    // ctrls['order_date'].setValue(moment(this.editData['order_date']).format('yyyy-MM-DD'));
    // ctrls['order_number']?.setValue(this.editData['order_no']);
    // ctrls['DATE_OF_ENTRY'].setValue(moment(this.editData['DATE_OF_ENTRY']).format('yyyy-MM-DD'));
  }

  close() {
        this.isEdit = null;
    this.myForm.reset();
    this.counting = 0;
    this.editData = {};
    this.getSubject(this.editData['BOOK_DEPARTMENT_ID']);
    this.getShelf(this.editData['RACK_ID']);

  
  }
}


  // getLanguage() {

  //   this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.Language }).subscribe((res: any) => {
  //     this.language = res;
  //   }, e => { });
   
  // }

  // changeAccession(id: any) {

  //   this.CommonService.postCall(`LibraryManagement/GetaccessorValue`, {}).subscribe((res: any) => {
  //     console.log(res);
  //     if (id == '1') {
  //       this.counting = res['accession_no'] + 1;
  //       console.log(this.counting);
  //     } else {
  //       this.counting = res['accession_no'];

  //     }
  //   })

    // if (event.target.value == '1') {
    //   this.counting++
    //   console.log(this.counting);
    // } else {
    //   this.counting--
    //   console.log(this.counting);
    // }

  // }


  // vendorDropdown() {
  //   let payload = {
  //     "tnt_code": localStorage.getItem('TenantCode')
  //   }
  //   this.CommonService.postCall('Vendor/VendorDropDownList', payload).subscribe((res: any) => {
  //     this.vendors = res;
  //   })
  // }

  // loadCourse() {
  //   this.activiceSpinner();
  //   this.CommonService.getAdminCourses().subscribe((res: any) => {
  //     this.courses = res;
  //     this.deactivateSpinner();
  //   }, e => {
  //     this.deactivateSpinner();
  //   })

  // }

  // getSubject(id: any) {
  //   this.subjects = [];
  //   this.CommonService.getCall(`Subject/LoadSubjectsByCourse/${id}`).subscribe((res: any) => {
  //     this.subjects = res;
  //   })
  // }

  // onSubmit(form: FormGroup) {
  //   let value: any = form.value;
  //   value.TNT_CODE = localStorage.getItem('TenantCode');
  //   let status: Boolean
  //   if (value.BOOK_STATUS == 1) {
  //     status = true
  //   } else {
  //     status = false
  //   }
  //   let payload = {
  //     "accession_registration": value.accession_registration,
  //     "accession_date": value.accession_date,
  //     "accession_text": value.accession_text,
  //     "accession_no": this.counting,
  //     "material_type": value.material_type,
  //     "language_id": value.language_id,
  //     "sub_material_type": value.sub_material_type,
  //     "editor": value.editor,
  //     "year_of_publication": value.year_of_publication,
  //     "place_of_publication": value.place_of_publication,
  //     "subject": value.subject,
  //     "class_no": value.class_no,
  //     "vendor_id": value.vendor_id,
  //     "order_date": value.order_date,
  //     "order_number": value.order_number,
  //     "invoice_date": value.invoice_date,
  //     "invoice_number": value.invoice_number,
  //     "book_location": value.book_location,
  //     "BAR_CODE_ID":value.BAR_CODE_ID,
  //     "currency": value.currency,
  //     "PAGE_NO": value.PAGE_NO,
  //     "BOOK_CODE": value.BOOK_CODE,
  //     "BOOK_NAME": value.BOOK_NAME,
  //     "BOOK_DESCRIPTION": value.BOOK_DESCRIPTION,
  //     "BOOK_TITLE": value.BOOK_TITLE,
  //     "BOOK_AUTHOR": value.BOOK_AUTHOR,
  //     "BOOK_PUBLICATION": value.BOOK_PUBLICATION,
  //     "BOOK_EDITION": value.BOOK_EDITION,
  //     "BOOK_NO_OF_COPIES": value.BOOK_NO_OF_COPIES,
  //     "BOOK_PRICE": value.BOOK_PRICE,
  //     "BOOK_AVAILABILITY_STATUS": true,
  //     "BOOK_DEPARTMENT_ID": value.BOOK_DEPARTMENT_ID,
  //     "BOOK_CREATED_BY": localStorage.getItem('UserId'),
  //     "BOOK_MODIFIED_BY": localStorage.getItem('UserId'),
  //     "BOOK_TNT_CODE": localStorage.getItem('TenantCode'),

  //   }
  //   if (this.isEdit) {
  //     payload['BOOK_ID'] = this.editData.BOOK_ID;
  //     this.CommonService.postCall('LibraryManagement/Update', payload).subscribe((res: any) => {
  //       this.load();
  //       this.toastr.success("LibraryManagement Updated Successfully");
  //       document.getElementById('md_close').click();
  //     }, err => {
  //       this.toastr.error(err.error ? err.error : 'LibraryManagement Not Updated')
  //     })
  //   } else {

  //     this.CommonService.postCall('LibraryManagement/Create', payload).subscribe((res: any) => {
  //       this.load();
  //       this.toastr.success("LibraryManagement Created Successfully");
  //       document.getElementById('md_close').click();
  //     }, err => {
  //       this.toastr.error(err.error ? err.error : 'LibraryManagement Not created')

  //     })
  //   }
  // }

  // edit(bookId) {
  //   this.editData = bookId;
  //   this.isEdit = true;
  //   this.myForm.reset();
  //   let payLoad = {
  //     "BOOK_ID": bookId
  //   }
  //   this.CommonService.postCall('LibraryManagement/Get', payLoad).subscribe((res: any) => {
  //     if (res instanceof Array && res.length) {
  //       this.editData = res[0];
  //        this.getSubject(this.editData['BOOK_DEPARTMENT_ID'])

  //       this.dataTransForm()
  //     } else {
  //       this.editData = res;
  //        this.getSubject(this.editData['BOOK_DEPARTMENT_ID'])
  //       this.dataTransForm()
  //     }
  //     this.getSubject(this.editData['BOOK_DEPARTMENT_ID'])
  //     this.dataTransForm();
  //   }), err => { }


  // }

  // dataTransForm() {
  //   let ctrls = this.myForm.controls
  //   Object.keys(ctrls).map((key) => {
  //     let ctrl: AbstractControl = ctrls[key];
  //     ctrl.setValue(this.editData[key])
  //   });

  //   ctrls['BOOK_CODE'].setValue(this.editData['BOOK_CODE']);
  //   ctrls['BOOK_NAME'].setValue(this.editData['BOOK_NAME']);
  //   ctrls['BOOK_DESCRIPTION'].setValue(this.editData['BOOK_DESCRIPTION']);
  //   // ctrls['BOOK_TITLE'].setValue(this.editData['BOOK_TITLE']);
  //   ctrls['BOOK_DEPARTMENT_ID'].setValue(this.editData['BOOK_DEPARTMENT_ID']);
  //   ctrls['BOOK_AUTHOR'].setValue(this.editData['BOOK_AUTHOR']);
  //   ctrls['BOOK_PUBLICATION'].setValue(this.editData['BOOK_PUBLICATION']);
  //   ctrls['BOOK_EDITION'].setValue(this.editData['BOOK_EDITION']);
  //   ctrls['BOOK_NO_OF_COPIES'].setValue(this.editData['BOOK_NO_OF_COPIES']);
  //   ctrls['BOOK_PRICE'].setValue(this.editData['BOOK_PRICE']);
  //   ctrls['accession_registration'].setValue(this.editData['accession_registration']);
  //   ctrls['accession_date'].setValue(moment(this.editData['accession_date']).format('yyyy-MM-DD'))
  //   ctrls['order_date'].setValue(moment(this.editData['order_date']).format('yyyy-MM-DD'))
  //   ctrls['invoice_date'].setValue(moment(this.editData['invoice_date']).format('yyyy-MM-DD'))
  //   ctrls['material_type'].setValue(this.editData['material_type']);
  //   ctrls['language'].setValue(this.editData['language']);
  //   ctrls['sub_material_type'].setValue(this.editData['sub_material_type']);
  //   ctrls['editor'].setValue(this.editData['editor']);
  //   ctrls['year_of_publication'].setValue(this.editData['year_of_publication']);
  //   ctrls['place_of_publication'].setValue(this.editData['place_of_publication']);
  //   ctrls['subject'].setValue(this.editData['subject_name']);
  //   ctrls['class_no'].setValue(this.editData['class_no']);
  //   ctrls['vendor_id'].setValue(this.editData['vendor_id']);
  //   ctrls['order_number'].setValue(this.editData['order_number']);
  //   ctrls['invoice_number'].setValue(this.editData['invoice_number']);
  //   ctrls['book_location'].setValue(this.editData['book_location']);
  //   ctrls['currency'].setValue(this.editData['currency']);
  //   ctrls['PAGE_NO'].setValue(this.editData['PAGE_NO']);
  //   ctrls['BAR_CODE_ID'].setValue(this.editData['BAR_CODE_ID'])
  //   // ctrls['BOOK_STATUS'].setValue(this.editData.BOOK_STATUS ? 1 : 0);



  // }


//   close() {
//     this.getSubject(this.editData['BOOK_DEPARTMENT_ID']);
//     this.isEdit = null;
//     this.myForm.reset();
//     this.counting = 0;
//     this.editData = {};
  
//   }
// }
