import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from './services/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Lmsapp';

  constructor(private route:Router,private spinner: NgxSpinnerService,private commonService:CommonService){
    commonService.appComponent=this
    if(!localStorage.getItem('UserId')){
      this.route.navigate(['/login'])
   }
  //  this.spinner.show()
  }
  activateSpinner(){
    this.spinner.show();
  }
  deactivateSpinner(){
    this.spinner.hide();
  }
}
