import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AboutMeComponent } from 'src/app/components/userregistration/about-me/about-me.component';
import { AddressComponent } from 'src/app/components/userregistration/address/address.component';
import { ContactDetailsComponent } from 'src/app/components/userregistration/contact-details/contact-details.component';
import { EducationComponent } from 'src/app/components/userregistration/education/education.component';
import { LanguagesComponent } from 'src/app/components/userregistration/languages/languages.component';
import { PersonalDataComponent } from 'src/app/components/userregistration/personal-data/personal-data.component';
import { ProjectsComponent } from 'src/app/components/userregistration/projects/projects.component';
import { SkillsComponent } from 'src/app/components/userregistration/skills/skills.component';
import { WorkExperienceComponent } from 'src/app/components/userregistration/work-experience/work-experience.component';
import { CommonService } from 'src/app/services/common.service';
import { HostelItemsComponent } from '../hostel-items/hostel-items.component';
import { HostelRoomComponent } from '../hostel-room/hostel-room.component';

@Component({
  selector: 'app-edit-user-registration',
  templateUrl: './edit-user-registration.component.html',
  styleUrls: ['./edit-user-registration.component.css']
})
export class EditUserRegistrationComponent implements OnInit, OnDestroy {
  case: number = 1;
  token: string;
  editData: any = {};
  childs: any = {}
  roleId=localStorage.getItem('RoleId')
  isTabsDisabled: boolean = (sessionStorage.isTabsDisabled || 'true') == 'true';
  workItemId:any;
  rId: any;
  constructor(public CommonService: CommonService, private fb: FormBuilder, public toastr: ToastrService, private active: ActivatedRoute) {
    active.queryParams.subscribe(
      (res) => {
        if (res.token) {
          this.token = res.token;
          this.workItemId = res.wId;
          this.rId=res.rId
          localStorage.setItem('regWorkItem', this.workItemId );
          this.edit();
        } else {
          window.history.back();
        }
      })
  }
  ngOnDestroy(): void {
    sessionStorage.isTabsDisabled = true;
  }

  ngOnInit(): void {
  }
  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  selectTab(tab) {
    this.case = tab;
  }

  edit() {
    this.activeSpinner();
    this.CommonService.postCall('EditRegistration', { VerificationToken: this.token }).subscribe(
      (res: any) => {
        this.editData = res;
        this.deactivateSpinner()
      }, err => {
        this.deactivateSpinner()
      }
    )
  }
  save() {
    let sRoomId: any;
    let sTcId: any;
    let prControl: FormGroup = this.childs['UserProfileData'].myform;
    let hrControl: FormGroup = this.childs['HostelStudent'].myForm;
    let adControl: FormGroup = this.childs['ems_transfer_certificate'].myForm;
    // let hrControl: FormGroup = this.childs['HostelStudentData'].myForm
    let hrValue = hrControl.value;
    let prValue = prControl.value;
    let adValue = adControl.value;

    if (!prControl.valid) {
      let controls = prControl.controls
      Object.keys(controls).map(key => {
        controls[key].markAsTouched();
      });
      this.toastr.warning('Please Enter Mandatory Fields')
      return
    }
    if (this.editData.HostelStudent == null) {
      sRoomId = 0;
    } else {
      sRoomId = this.editData.HostelStudent.student_room_id;
    }
    if (this.editData.ems_transfer_certificate == null) {
      sTcId = 0;
    } else {
      sTcId = this.editData.ems_transfer_certificate.ems_transfer_certificate_id;
    }
    let payLoad: any = {
      UserProfileData: {},
      ContactDetailsList: [],
      EducationTypeList: (this.childs['EducationTypeList'] as EducationComponent).educationData,
      SkillsList: (this.childs['SkillsList'] as SkillsComponent).skillData,
      AddressList: (this.childs['AddressList'] as AddressComponent).Address,
      LanguagesList: (this.childs['LanguagesList'] as LanguagesComponent).lanuageData,
      ProjectsList: (this.childs['ProjectsList'] as ProjectsComponent).projectData,
      WorkExperienceList: (this.childs['WorkExperienceList'] as WorkExperienceComponent).WorkExperienceData,
      UserImageUrl: (this.childs['aboutMe'] as AboutMeComponent).fileName,

      HostelStudent: (this.childs['HostelStudent'].myform),
      ems_transfer_certificate: (this.childs['ems_transfer_certificate'].myform)
    }
    let personalData = (this.childs['UserProfileData'] as PersonalDataComponent).personalData
    let contactDetails = (this.childs['ContactDetailsList'] as ContactDetailsComponent).personalData;

    let array = [];
    contactDetails.map(item => {
      let obj = {
        type: item['TYPE'],
        CommunicationId: item['COMMUNICATIONID'],
        EmailId: item['EMAILID'],
        MobileNo: item['MOBILENO'],
        IsPrimary: item['ISPRIMARY'],
        IsConfirmed: item['IsConfirmed']
      }
      array.push(obj)
    })
    payLoad.UserProfileData = {
      UserImage: personalData['USERIMAGE'],
      TenantCode: personalData['TenantCode'],
      CreatedBy: personalData['UserId'],
      Status: prValue['status'],
      Title: prValue['Title'],
      FirstName: prValue['FirstName'],
      LastName: prValue['LastName'],
      UserId: personalData['UserId'],
      objUserpersonal: {
        DOB: prValue['dob'],
        Gender: prValue['Gender'],
        MartialStatus: prValue['MartialStatus'],
        ProfileSummary: personalData['ProfileSummary'] || ''
      },
      is_dropoff: prValue['is_dropoff'],
      is_transport_required: prValue['is_transport_required'],
      YearOfRegistration: prValue['YearOfRegistration'] || personalData['YearOfRegistration'],
      course_year_id:prValue['course_year_id'],
      CourseId: prValue['Branch'],
      RollNumber: prValue['idNumber'],
      RoleId:this.rId,
      enrollment_no:prValue['enrollment_no'],
      "ParentName": prValue['ParentName'] || personalData['ParentName'] || "",
      "ParentMobileNumber": prValue['ParentMobileNumber'] || personalData['Parent_Mobile_Number'] || "",
      "ParentRelationShip": prValue['ParentRelationShip'] || personalData['ParentRelationShip'] || null,
      "reference_number":prValue['reference_number'],
      "admission_number":prValue['admission_number']
    }
    payLoad.ContactDetailsList = array;

    payLoad.HostelStudent = {
      // student_room_id: +hrValue['student_room_id'],
      student_room_id: sRoomId,
      student_id: personalData['UserId'],
      campus_id: +hrValue['campus_id'],
      block_id: +hrValue['block_id'],
      floor_id: +hrValue['floor_id'],
      room_id: +hrValue['room_id'],
      amount: hrValue['amount'],
      joined_date: hrValue['joined_date']
    }

    payLoad.ems_transfer_certificate = {
      ems_transfer_certificate_id: sTcId,
      mother_name: adValue['mother_name'],
      nationality: adValue['nationality'],
      place_of_birth: adValue['place_of_birth'],
      caste_id:adValue['caste_id'] == "" ? 0 : adValue['caste_id'],
      caste_category_id:adValue['caste_category_id'] == "" ? 0 : adValue['caste_category_id'],
      last_institute_attended: adValue['last_institute_attended'],
      dateofleavinginstitution: adValue['dateofleavinginstitution'],
      standardinwhichstudying: adValue['standardinwhichstudying'],
      conduct_character: adValue['conduct_character'],
      conduct_progress: adValue['conduct_progress'],
      reason_of_leavingthecollege: adValue['reason_of_leavingthecollege'],
      remarks: adValue['remarks'],
      created_by: localStorage.getItem('UserId'),
      modified_by: localStorage.getItem('UserId'),
      tnt_code: localStorage.getItem('TenantCode')

    }

    // return
    this.activeSpinner();
    this.CommonService.postCall('UpdateRegistration', payLoad).subscribe((res) => {
      this.deactivateSpinner();
      this.toastr.success('User Updated Successfully');
      this.close();
    }, err => {
      this.deactivateSpinner();
      this.toastr.warning(err.error ? err.error : 'user not updated')
    })

  }
  close() {
    window.history.back();
  }
}
