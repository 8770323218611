<br>
<div class="page-wrapper">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">

                    <div class="container-fluid">

                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.StudentSalesList}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right mb-2">
                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                                data-bs-target="#myModal">Add Student Sales</button>
                                        </div>
                                        <div *ngIf="table.length">
                                            <table id="patreggraph" datatable
                                                class="table table-striped table-bordered mt-2 pt-2 table-sm small display"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624fd7; color:#ffffff;">
                                                    <tr>
                                                        <th> Student Name </th>
                                                        <th> Campus Name </th>
                                                        <th> Course </th>
                                                        <th> Course Schedule </th>
                                                         <th> NO Of Items </th>
                                                        <th> Total Amount </th>
                                                        <th> Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.student_name}}</td>
                                                        <td>{{item.campus_name }}</td>
                                                        <td>{{item.class_name}}</td>
                                                        <td>{{item.section}}</td>
                                                         <td>{{item.noofitems}}</td>
                                                        <td>{{item.total_amount}}</td>
                                                        <td style="display: flex;">
                                                            <span data-bs-toggle="modal" data-bs-target="#myModal"
                                                                src="" class="edit_icon" (click)="edit(item.sales_student_id)">
                                                                <i class="fas fa-pen pt-1 mt-1"></i></span>
                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog  modal-lg">
        <div class="modal-content">

            <div class="modal-header py-2">
                <h6 *ngIf="!isEdit" class=" modal-title w-100">{{headingName.AddStudentSales}}</h6>
                <h6 *ngIf="isEdit" class=" modal-title w-100"> {{headingName.EditStudentSales}}</h6>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal">&times;</button>
            </div>


            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row pt-4">
                            <label class="col-md-3 control-label line_2 rem_1">
                                {{labelName?.Campus}} <span class="text-danger">*</span></label>
                            <div class="col-md-6 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName="campus_id"
                                    class="form-select form-select-sm shadow-sm rounded-lg">
                                    <option value="" selected>Select</option>
                                        <option *ngFor="let item of campus" [value]='item.campus_id'>
                                            {{item.campus_name}}
                                        </option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('campus_id').touched">
                                    <span class="text-danger" *ngIf="myForm.get('campus_id').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                     


                        <div class="form-group row pt-4">
                            <label class="col-md-3 control-label line_2 rem_1">
                                {{labelName?.Course}} <span class="text-danger">*</span></label>
                            <div class="col-md-6 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName="course_id"
                                    (change)="courseSchedule($any($event).target.value)"
                                    class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" [selected]="true">Select</option>
                                         <option value="" *ngFor="let item of Courses" [value]="item.COURSE_ID">
                                           {{item.COURSE_NAME}}
                                        </option> 
                                    </select>
                                </div>
                                 <div *ngIf="myForm.get('course_id').touched">
                                    <span class="text-danger" *ngIf="myForm.get('course_id').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>


                        <div class="form-group row pt-4">
                            <label class="col-md-3 control-label line_2 rem_1">
                                {{labelName?.CourseSchedule}} <span class="text-danger">*</span></label>
                            <div class="col-md-6 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName="courseschedule_id"
                                    (change)="scheduleStudent($any($event).target.value)"
                                    class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" [selected]="true">Select</option>
                                         <option value="" *ngFor="let item of courseschedule" [value]="item.COURSESHD_ID">
                                          {{item.COURSESHD_NAME}}
                                        </option> 
                                    </select>
                                </div>
                                 <div *ngIf="myForm.get('courseschedule_id').touched">
                                    <span class="text-danger" *ngIf="myForm.get('courseschedule_id').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-3 control-label line_2 rem_1">
                                {{labelName?.Student}} <span class="text-danger">*</span></label>
                            <div class="col-md-6 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName="student_id" (change)="studentTable($any($event).target.value)"
                                    class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" [selected]="true">Select</option>
                                         <option value="" *ngFor="let item of student" [value]="item.userid">
                                        {{item.name}}
                                        </option> 
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('student_id').touched">
                                    <span class="text-danger" *ngIf="myForm.get('student_id').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4" *ngIf="!isEdit">
                            <table *ngIf="isCampus" id="patreggraph" class=" table-striped table-bordered mt-2 pt-2 table-sm small" >
                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                <tr>
                                    <th>Item </th>
                                    <th> Price </th>
                                    <th> Amount</th>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            <th>Issued On</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of students">
                                        <td>{{item.item_name}}</td>  
                                        <td>{{item.cost}}</td> 
                                        <td>{{item.total_amount}}</td> 
                                      <td>{{item.issued_on}}</td> 
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                         <div class="form-group row pt-4" *ngIf="isEdit">
                            <table id="patreggraph" class=" table-striped table-bordered mt-2 pt-2 table-sm small" >
                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                <tr>
                                    <th>Item </th>
                                    <th> Price </th>
                                    <th> Amount</th>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            <th>Issued On</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of updateList">
                                        <td>{{item.item_name}}</td>  
                                        <td>{{item.cost}}</td> 
                                        <td>{{item.total_amount}}</td> 
                                      <td>{{item.issued_on}}</td> 
                                    </tr>
                                </tbody>
                            </table>
                        </div>
 

                        <div *ngIf="myForm.controls['list']">
                            <div formArrayName='list'>
                                <div class="form-group row pt-4">
                                    <table class="col-md-6 offset-md-3 text-center">
                                        <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                            <tr>
                                                <th>Item</th>
                                                <th>Price</th>
                                                <th>Amount</th>
                                                <th>Quantity</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let item of myForm.controls['list'].controls;let i=index"
                                            [formGroupName]="i">
                                            <tr>
                                                <td><select class="form-control form-control-sm"
                                                        formControlName="item_id" id="item"(change)="priceAmount($event,i)">
                                                        <option value="" [selected]="0">Select</option>
                                                        <option *ngFor="let item of items" [value]="item.item_id">
                                                            {{item.item_name}}
                                                        </option>


                                                    </select></td>
                                                <td> <input placeholder="Price" formControlName="cost"
                                                        class="form-control form-control-sm" type="number" id="cost"
                                                        >
                                                </td>
                                                <td> <input placeholder="Amount" formControlName="total_amount"
                                                        class="form-control form-control-sm" type="number" id="total_amount"
                                                        [(ngModel)]="item.total_amount" disabled>
                                                </td>
                                                <td> <input placeholder="Quantity" formControlName="quantity"
                                                        class="form-control form-control-sm" [(ngModel)]="item.quantity"
                                                        type="number" id="quantity">

                                                </td>


                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="row"  *ngIf="!isEdit">
                                <div class="col-md-6 offset-md-3 mt-4">
                                    <div class="float-end">
                                        <button class="btn btn-di float-end" *ngIf="isVisable()" (click)="delete()"type='button'><i
                                                class="fas fa-trash"></i></button>
                                        <button class="btn btn-ai float-end" *ngIf="isAdd()" (click)="add()"type='button'>Add
                                            Item</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row ">
                            <label class="col-md-2 offset-md-3 control-label mb-4 mt-4">
                                {{labelName?.Amount}}<span class="text-danger">*</span></label>
                            <div class="col-md-4 mb-4 inputGroupContainer  mt-4">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <input placeholder="Amount" [(ngModel)]="total_cost" disabled
                                        class="form-control form-control-sm" formControlName="amounts" type="test">
                                </div>
                                <div *ngIf="myForm.get('amounts').touched">
                                    <span class="text-danger" *ngIf="myForm.get('amounts').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row ">
                            <label class="col-md-2 offset-md-3 control-label mb-4 mt-4">
                                {{labelName?.TotalDiscount}}<span class="text-danger"></span></label>
                            <div class="col-md-4 mb-4 inputGroupContainer  mt-4">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <input placeholder="Total Discount" (change)="discountChange($any($event).target.value)" [(ngModel)]="total_discount"
                                        class="form-control form-control-sm" formControlName="total_discounts"
                                        type="test">
                                </div>
                            </div>

                        </div>

                        <div class="form-group row ">
                            <label class="col-md-2 offset-md-3 control-label mb-4 mt-4">
                                {{labelName?.FinalAmount}}<span class="text-danger">*</span></label>
                            <div class="col-md-4 mb-4 inputGroupContainer  mt-4">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <input placeholder="Final Amount" [(ngModel)]="final_amount" disabled
                                        class="form-control form-control-sm" formControlName="final_amounts"
                                        type="test">
                                </div>
                                <div *ngIf="myForm.get('final_amounts').touched">
                                    <span class="text-danger" *ngIf="myForm.get('final_amounts').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-3 control-label line_2 rem_1">
                                {{labelName?.PaymentType}} <span class="text-danger">*</span></label>
                            <div class="col-md-6 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName="payment_type"
                               
                                    class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="0" [selected]="true">Select</option>
                                        <option value="1" [selected]="true">Cash</option>
                                    
                                    </select>
                                </div>
                             <div *ngIf="myForm.get('payment_type').touched">
                                    <span class="text-danger" *ngIf="myForm.get('payment_type').hasError('required')">
                                        This field is required.
                                    </span>

                                </div> 
                            </div>
                        </div>
                    </fieldset>
                </form>
         
                <div class="modal-footer">
                    <button type="button" *ngIf="!isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                        [disabled]="myForm.invalid">Save</button>
                    <button type="button" *ngIf="isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                        [disabled]="myForm.invalid">Update</button>
                    <button type="button" class="btn btn-sm btn-danger" id="md_close" (click)="close()"
                        data-bs-dismiss="modal">Close</button>
                </div>
        </div>
        </div>
    </div>
</div>