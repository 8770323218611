<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.StaffReports}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div *ngIf='isSuperAdmin' class="col-md-3 pb-4">
                                                <label for="sel1">{{labelName.TenantName}} : </label>
                                                <select [(ngModel)]='tId ' (change)="getRoles()"
                                                    class="form-control form-control-sm shadow-sm rounded-lg" id="sel1">
                                                    <option value="" [selected]="true">select</option>
                                                    <option *ngFor="let item of tenanates" [value]='item.TNT_CODE'>
                                                        {{item.TNT_NAME}}</option>
                                                </select>

                                            </div>
                                        </div>

                                        <form class="well form-horizontal" [formGroup]="myForm"
                                            (ngSubmit)="onSubmit(myForm)">
                                            <fieldset>
                                                <div class="row">
                                                    <div class="col-md-3 pb-4">
                                                        <label for="sel1">{{labelName?.SelectRoles}}<span
                                                                class="text-danger">*</span></label>
                                                        <select formControlName="role_id"
                                                            class="form-select form-select-sm shadow-sm rounded-lg">
                                                            <option value="" [selected]="true">select</option>
                                                            <option *ngFor="let item of roles" [value]='item.ROLE_ID'>
                                                                {{item.ROLE_NAME}}</option>
                                                        </select>

                                                    </div>



                                                </div>
                                                <div class="col-md-3 ">
                                                    <button type="button" (click)="onSubmit(myForm)"
                                                        class="btn btn-sm  btn-save"><i
                                                            class="fas fa-search"></i></button>
                                                </div>
                                            </fieldset>
                                        </form>
                                        <br>

                                        <div *ngIf="!isTable" class="text-center">
                                            <p style="color: red;">
                                                Search for Staff Reports</p>
                                        </div>

                                        <div>
                                            <table id="addStudent" datatable *ngIf="table.length"
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th> Staff Name</th>
                                                        <th> Mobile Number </th>
                                                        <!-- <th> Salary </th> -->
                                                        <!-- <th> Join Date </th> -->
                                                        <!-- <th> Branch Name </th> -->
                                                        <th> Designation </th>
                                                        <!-- <th> Qualification </th> -->
                                                        <th> Email </th>
                                                        <th> Status </th>
                                                    </tr>
                                                </thead>


                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.staffname}}</td>
                                                        <td>{{item.mobileno}}</td>
                                                        <!-- <td></td> -->
                                                        <!-- <td></td> -->
                                                        <!-- <td></td> -->
                                                        <td>{{item.role_name}}</td>
                                                        <!-- <td> </td> -->
                                                        <td>{{item.emailid}}</td>
                                                        <td>{{item.status == true? "Active":"InActive"}}</td>

                                                    </tr>
                                                </tbody>

                                            </table>
                                        </div>


                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>