<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 "> {{headingName.AssessmentResult}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="form-group ">
                                            <div class="row">
                                                <div class="col-md-3" *ngIf='roleId=="4"'>
                                                    <label for="sel1">{{labelName.TenantName}} : </label>
                                                    <select [(ngModel)]='tId ' (change)="changeTname()"
                                                        class="form-select form-select-sm shadow-sm rounded-lg"
                                                        id="sel1">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let item of tenanates" [value]='item.TNT_CODE'>
                                                            {{item.TNT_NAME}}</option>
                                                    </select>
                                                </div>
                                                <div class="col-md-3">
                                                    <label for="sel1"> {{labelName.UserName}} : </label>
                                                    <select [(ngModel)]='uId'
                                                        class="form-select form-select-sm shadow-sm rounded-lg"
                                                        id="sel1" (change)='userChange()'>
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let course of users" [value]='course.USERID'>
                                                            {{course.FIRSTNAME}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-md-3" *ngIf='false'>
                                                    <label for="sel1">{{labelName.AssessmentType}} : </label>
                                                    <select [(ngModel)]='aId'
                                                        class="form-select form-select-sm shadow-sm rounded-lg"
                                                        (change)='typeChange()' id="sel1">
                                                        <option value="" [selected]="true">select</option>
                                                        <option value="2">Pre Assessment</option>
                                                        <option value="1">Post Assessment</option>
                                                        <option value="3">Final Exam</option>
                                                        <option value="4">Internal Assessment</option>

                                                    </select>
                                                </div>
                                                <div class="col-md-3">
                                                    <label for="sel1">{{labelName.CourseName}} : </label>
                                                    <select [(ngModel)]='courseId'
                                                        class="form-select form-select-sm shadow-sm rounded-lg"
                                                        id="sel1">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let course of courses"
                                                            [value]='course.COURSE_ID'>
                                                            {{course.COURSE_NAME}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-md-2">
                                                    <label></label>
                                                    <br>
                                                    <button class="btn btn-sm  btn-save" (click)="submit()">submit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="table.length">
                                            <table id="patreggraph" datatable
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th>S No</th>
                                                        <th>Assessment Name</th>
                                                        <th>Status </th>
                                                        <th> Attempts Left</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table;let i=index ">
                                                        <td>{{i+1}}</td>
                                                        <td>{{item.ASSESSMENT_ASSESSMENT_NAME}}</td>
                                                        <td>{{item.RESULT_STATUS}}</td>
                                                        <td><input [(ngModel)]='item.RESULT_ATTEMPTSLEFT' type="text">
                                                        </td>

                                                        <td><button class="btn btn-sm  btn-save"
                                                                (click)='update(item)'>Update</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div *ngIf="!table.length" class="pt-4">
                                            <p class="text-center">No Records To Display</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>