import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-item-unit',
  templateUrl: './item-unit.component.html',
  styleUrls: ['./item-unit.component.css']
})
export class ItemUnitComponent implements OnInit {
  myForm: FormGroup
  table: Array<any> = [];
  isEdit: boolean = false;
  editData: any;
  labelName: any = {};
  headingName: any = {};
  constructor(private fb: FormBuilder, private CommonService: CommonService, private toastr: ToastrService) {
    this.load();
    this.loadlabels();
    this.loadHeadings();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      item_unit_name: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      item_unit_desciption: [''],
      status: ['', Validators.required],
    })
  }

  activiceSpinner() {
    this.CommonService.activateSpinner();
  }
  deactivateSpinner() {
    this.CommonService.deactivateSpinner();
  }
  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/4342/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/4342/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  load() {
    this.activiceSpinner();
    this.CommonService.postCall('EmsItemUnit/GetList', { "tnt_code": localStorage.getItem('TenantCode') }).subscribe((res: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = res;
      }, 10)
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner(), console.log(e) })
  }

  edit(unitId: any) {
    this.isEdit = true;
    this.myForm.reset();
    this.CommonService.postCall('EmsItemUnit/Get', { "item_unit_id": unitId }).subscribe((res: any) => {
      if (res instanceof Array && res.length) {
        this.editData = res[0];
        this.dataTransForm()
      } else {
        this.editData = res;
        this.dataTransForm()
      }
      this.dataTransForm();
    }), err => { }


  }

  dataTransForm() {
    let ctrls = this.myForm.controls
    Object.keys(ctrls).map((key) => {
      let ctrl: AbstractControl = ctrls[key];
      ctrl.setValue(this.editData[key]);
    });
    // ctrls['ACADEMIC_NAME'].setValue(this.editData['ACADEMIC_NAME'])
    // ctrls['ACADEMIC_DESCRIPTION'].setValue(this.editData['ACADEMIC_DESCRIPTION'])
    // ctrls['STATUS'].setValue(this.editData.STATUS ? 1 : 0);
  }


  onSubmit(form: FormGroup) {
    let value: any = form.value;
    let payload = {
      "item_unit_name": value.item_unit_name,
      "item_unit_desciption": value.item_unit_desciption,
      "created_by": localStorage.getItem('UserId'),
      "modified_by": localStorage.getItem('UserId'),
      "status": value.status,
      "tnt_code": localStorage.getItem('TenantCode'),
    }
    if (this.isEdit) {
      payload['item_unit_id'] = this.editData.item_unit_id;
      this.CommonService.postCall('EmsItemUnit/Update', payload).subscribe((res: any) => {
        this.load();
        this.toastr.success("Item Unit Updated Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Item Unit Not Updated')
      })
    } else {

      this.CommonService.postCall('EmsItemUnit/Create', payload).subscribe((res: any) => {
        this.load();
        this.toastr.success("Item Unit Created Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Item Unit Not created')

      })
    }


  }

  close() {
    this.isEdit = null;
    this.myForm.reset();
    this.editData = {};
  }
}
