<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0"> {{headingName.ReceivedStudentBookRequest}} </h5>
                                    </div>
                                    <div class="card body">
                                        <div class="table">
                                            <fieldset>
                                                <div *ngIf="table.length">
                                                    <table id="patreggraph" datatable
                                                        class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                        style="width:100%">
                                                        <thead class="top"
                                                            style="background-color: #624FD7; color:#ffffff;">
                                                            <tr>
                                                                <th>Enrollment No</th>
                                                                <th> Student Name</th>
                                                                <th>Course Name</th>
                                                                <th> Book Name</th>
                                                                <th>Status</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let item of table">
                                                                <td>{{item.enrollment_no}}</td>
                                                                <td>{{item.student_name}}</td>
                                                                <td>{{item.course_name}}</td>
                                                                <td>{{item.book_name}}</td>
                                                                <td>{{item.book_status}}</td>
                                                                <td class="text-center">
                                                                    <button type="button"
                                                                        (click)="approveBook($event,item.student_book_req_id)"
                                                                        class="btn btn-primary mb-1" [attr.disabled]="item.book_status=='Approved'?true:null " >Approve</button>&nbsp;
                                                                    <button type="button" class="btn btn-danger mb-1" [disabled]="item.book_status=='Reject'?true:null"
                                                                        (click)="RejectBook($event,item.student_book_req_id)">Reject</button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                </div>
                                                <div class="text-center" *ngIf="!table.length">
                                                    No Records to display
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>