import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-book-issue-return-report',
  templateUrl: './book-issue-return-report.component.html',
  styleUrls: ['./book-issue-return-report.component.css']
})
export class BookIssueReturnReportComponent implements OnInit {
  table: Array<any> = [];
  courses: Array<any> = [];
  labelName: any = {};
  headingName: any = {};
  workItemId: any;
  type: string = '';
  courseId: string = '';
  issueTable: boolean = false;
  returnTable: boolean = false;
  value: string;
  dtOptions: { dom: string; buttons: { extend: string; filename: string; }[]; order: any[]; };
  Date = moment(new Date()).format('YYYY-MM-DD');
  date: any;
  constructor(private commonService: CommonService, private active: ActivatedRoute) {
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
    this.getLabels();
    this.loadHeadings();
    this.loadCourse();
    this.loadReportDtOptions();
  }

  ngOnInit(): void {
  }

  getLabels() {
    this.labelName = {};
    this.commonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.commonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  activiceSpinner() {
    this.commonService.activateSpinner();
  }
  deactivateSpinner() {
    this.commonService.deactivateSpinner();
  }


  loadReportDtOptions() {
    this.dtOptions = {
      dom: 'Bfrtip',
      buttons: [
        {
          extend: "excel",
          filename: 'Books Issue Return Report',
        }
      ],
      order: []
    }
  }
  loadCourse() {
    this.activiceSpinner();
    this.commonService.getAdminCourses().subscribe((res: any) => {
      this.courses = res;
      this.deactivateSpinner();
    }, e => {
      this.deactivateSpinner();
    })

  }

  loadTable() {
    let payload = {
      "date": this.date,
      "type": this.type == "" ? 0 : this.type,
      "course_id": this.courseId == "" ? 0 : this.courseId,
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.commonService.postCall('LibraryManagement/LoadBooksIssueorReturnReports', payload).subscribe((res: any) => {
      this.table = res;
    })
  }

}
