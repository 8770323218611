import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-hostel-visitors',
  templateUrl: './hostel-visitors.component.html',
  styleUrls: ['./hostel-visitors.component.css']
})
export class HostelVisitorsComponent implements OnInit {
  table: Array<any> = [];
  myForm: FormGroup;
  isEdit: boolean = false;
  block: Array<any> = [];
  floor: Array<any> = [];
  campus: Array<any> = [];
  students: Array<any> = [];
  relation: Array<any> = [];
  editData: any;
  maxDate: any = moment().format('yyyy-MM-DD');
  campusId: string = '';
  labelName: any = {};
  headingName: any = {};
  workItemId: any;

  constructor(private fb: FormBuilder,private active : ActivatedRoute, private CommonService: CommonService, private toastr: ToastrService) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.load();
    this.getLabels();
    this.loadHeadings();

    this.campusDropdown();
    this.studentDropdown();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      campus_id: ['', Validators.required],
      block_id: ['', Validators.required],
      floor_id: ['', Validators.required],
      visitor_student_id: ['', Validators.required],
      visitor_name: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      visitor_out_time: [null],
      visitor_in_time: ['', Validators.required],
      visitor_student_relation: ['', Validators.required],

    });
  }

  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  load() {
    let payload = {
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('EmsHostelVisitor/GetList', payload).subscribe((res: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = res;
      }, 10);
    })
  }

  campusDropdown() {
    let payload = {
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('EmsCampus/CampusDropDownList', payload).subscribe((res: any) => {
      this.campus = res;
    })
  }
  campusChange(id) {
    let payload = {
      "campus_id": id
    }
    this.CommonService.postCall('EmsBlock/BlockDropDownList', payload).subscribe((res: any) => {
      this.block = res;
    })
  }
  blockChange(id) {
    let payLoad = {
      "block_id": id
    }
    this.CommonService.postCall('EmsFloor/FloorDropDownList', payLoad).subscribe((res: any) => {
      this.floor = res;
    })
  }
  studentDropdown() {
    let payLoad = {
      "objUserinrole":
      {
        "RoleId": 3
      },
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('Registration/UserRolesChange', payLoad).subscribe((res: any) => {
      this.students = res;
    }, e => {

    });
  }
  add() {
    this.editData = {};
    this.isEdit = false;
  }



  onSubmit(form: FormGroup) {
    let value: any = form.value;
    value.created_by = localStorage.getItem('UserId');
    value.tnt_code = localStorage.getItem('TenantCode');
    if (this.isEdit) {
      value.modified_by = localStorage.getItem('UserId');
      value['hostel_visitor_id'] = this.editData.hostel_visitor_id;
      this.CommonService.postCall('EmsHostelVisitor/Update', value).subscribe((res: any) => {
        this.load();
        this.toastr.success('Updated Successfully');
        document.getElementById('md_close')?.click();

      }, err => {
        this.toastr.warning(err.error ? err.error : 'Not updated')
      })
    } else {
      this.CommonService.postCall('EmsHostelVisitor/Create', value).subscribe((res: any) => {
        this.load()
        this.toastr.success('Created Successfully');
        document.getElementById('md_close')?.click();
      }, err => {
        this.toastr.warning(err.error ? err.error : 'Not Created')
      })
    }
  }

  edit(vivitorId) {
    this.isEdit = true;
    let payLoad = {
      "hostel_visitor_id": vivitorId
    }
    this.CommonService.postCall('EmsHostelVisitor/Get', payLoad).subscribe((res: any) => {
      this.editData = res;
      Promise.all([
        this.campusChange(this.editData['campus_id']),
        this.blockChange(this.editData['block_id']),
      ]).then(() => {
        this.dataTransform(res);
      })
    })
  }


  dataTransform(data: any) {
    // let ctrls = this.myForm.controls;
    // Object.keys(ctrls).map((key) => {
    //   let ctrl: AbstractControl = ctrls[key];
    //   ctrl.setValue(this.editData[key])
    // })
    // ctrls['visitor_out_time'].setValue(moment(this.editData.visitor_out_time).format('yyyy-MM-DD hh:mm:ss'));
    // ctrls['visitor_in_time'].setValue(moment(this.editData.visitor_in_time).format('yyyy-MM-DD hh:mm:ss'));
    this.myForm.patchValue({
      campus_id: this.editData['campus_id'],
      block_id: this.editData['block_id'],
      floor_id: this.editData['floor_id'],
      visitor_student_id: this.editData['visitor_student_id'],
      visitor_name: this.editData['visitor_name'],
      visitor_in_time: this.editData['visitor_in_time'],
      visitor_out_time: this.editData['visitor_out_time'],
      visitor_student_relation: this.editData['visitor_student_relation'],
    })
  }

  close() {
    this.myForm.reset();
    this.isEdit = false;
    this.editData = {};
    this.campusChange(this.editData['campus_id']);
    this.blockChange(this.editData['block_id']);
  }

}
