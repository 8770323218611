<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.StudentDrops}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right mb-2">
                                            <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal"
                                                data-bs-target="#myModal">Add Drop</button>
                                        </div>
                                        <div class="pt-2" *ngIf="table.length">
                                            <table datatable id="DataTables_Table_0"
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #0d9bc4; color:#ffffff;">
                                                    <tr>
                                                        <th>
                                                            Admission No </th>
                                                        <th>Student Name </th>
                                                        <th>Drop By</th>
                                                        <th>USID</th>
                                                        <th>Drop Date</th>
                                                        <!-- <th>Action</th> -->
                                                        <!-- <th>Drop Date</th> -->
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.rollnumber}}</td>
                                                        <td>{{item.name}}</td>
                                                        <td>{{item.person_type}}</td>
                                                        <td>{{item.student_id}}</td>
                                                        <td>{{item.drop_date}}</td>
                                                        <!-- <td></td>
                                                        <td></td> -->

                                                        <td  style="display: flex;" *ngIf="false"> 
                                                            <span data-bs-toggle="modal" data-bs-target="#myModal"
                                                            src=""
                                                            class="edit_icon" (click)="edit(item.latedrop_id)"> <i class="fas fa-pen pt-1 mt-1"></i></span >
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false">
                <div class="modal-dialog  modal-lg">
                    <div class="modal-content">

                        <div class="modal-header py-2">
                            <h6 *ngIf="!isEdit" class="modal-title w-100"> {{headingName.AddDrop}}</h6>
                            <h6 *ngIf="isEdit" class="modal-title w-100"> {{headingName.EditDrop}}</h6>

                            <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i
                                    class="fas fa-times"></i></button>
                        </div>


                        <div class="modal-body">
                            <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                                <fieldset>
                                    <div class="form-group row pt-4">
                                        <label class="col-md-4 control-label line_2 rem_1">
                                            {{labelName?.Course}} <span class="text-danger">*</span></label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group">
                                                <strong>:</strong> &nbsp;
                                                <select formControlName="course_id" [(ngModel)]='courceId '
                                                    (change)="courceChange($any($event).target.value)"
                                                    class="form-select form-select-sm shadow-sm rounded-lg">
                                                    <option value="" selected>Select</option>
                                                    <option *ngFor="let course of cources" [value]='course.COURSE_ID'>
                                                        {{course.COURSE_NAME}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div *ngIf="myForm.get('course_id').touched">
                                                <span class="text-danger"
                                                    *ngIf="myForm.get('course_id').hasError('required')">
                                                    This field is required.
                                                </span>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row pt-4">
                                        <label class="col-md-4 control-label line_2 rem_1">
                                            {{labelName?.CourseSchedule}} <span class="text-danger">*</span></label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group">
                                                <strong>:</strong> &nbsp;
                                                <select formControlName="course_schedule_id"
                                                    (change)="scheduleChange($any($event).target.value)"
                                                    class="form-select form-select-sm shadow-sm rounded-lg">
                                                    <option value="" selected>Select</option>
                                                    <option *ngFor="let schedul of scheduls"
                                                        [value]='schedul.COURSESHD_ID'>
                                                        {{schedul.COURSESHD_NAME}}</option>
                                                </select>
                                            </div>
                                            <div *ngIf="myForm.get('course_schedule_id').touched">
                                                <span class="text-danger"
                                                    *ngIf="myForm.get('course_schedule_id').hasError('required')">
                                                    This field is required.
                                                </span>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row pt-4">
                                        <label class="col-md-4 control-label line_2 rem_1">
                                            {{labelName?.DropBy}} <span class="text-danger">*</span></label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group">
                                                <strong>:</strong> &nbsp;
                                                <select formControlName="person_type"
                                                class="form-select form-select-sm shadow-sm rounded-lg">
                                                    <option value="" selected>Select</option>
                                                    <option value="Parent">Parent</option>
                                                    <option value="Guardian">Guardian</option>
                                                </select>
                                            </div>
                                            <div *ngIf="myForm.get('person_type').touched">
                                                <span class="text-danger"
                                                    *ngIf="myForm.get('person_type').hasError('required')">
                                                    This field is required.
                                                </span>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row pt-4">
                                        <label class="col-md-4 control-label line_2 rem_1">
                                            {{labelName?.StudentName}}<span class="text-danger">*</span></label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group">
                                                <strong>:</strong> &nbsp;
                                                <select name="student" id="student" formControlName="student_id"
                                                class="form-select form-select-sm shadow-sm rounded-lg">
                                                    <option value="" [selected]="true">Select</option>
                                                    <option *ngFor="let student of students" [value]="student.USERID">
                                                        {{student.FIRSTNAME}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div *ngIf="myForm.get('student_id').touched">
                                                <span class="text-danger"
                                                    *ngIf="myForm.get('student_id').hasError('required')">
                                                    This field is required.
                                                </span>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row pt-4">
                                        <label class="col-md-4 control-label ">{{labelName?.Description}}<span
                                                class="text-danger">*</span></label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group"> <strong>:</strong> &nbsp;
                                                <input type="text" placeholder="Late Drop Purpose" class="form-control"
                                                    formControlName="purpose" onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">
                                            </div>
                                            <div *ngIf="myForm.get('purpose').touched">
                                                <span class="text-danger"
                                                    *ngIf="myForm.get('purpose').hasError('required')">
                                                    This field is required.
                                                </span>

                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                        <div class="modal-footer">
                            <button type="button" *ngIf="!isEdit" class="btn btn-save btn-sm" (click)="onSubmit(myForm)"
                                [disabled]="myForm.invalid">Save</button>
                            <button type="button" *ngIf="isEdit" class="btn btn-save btn-sm" (click)="onSubmit(myForm)"
                                [disabled]="myForm.invalid">Update</button>
                            <button type="button" class="btn btn-danger  btn-sm" id='md_close' (click)="close()"
                                data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>