<div class="page-wrapper">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">Department</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right">
                                            <button type="button" class="btn btn-primary mb-1" data-bs-toggle="modal"
                                                data-bs-target="#myModal" (click)="add()">Add</button>
                                        </div>
                                        <div *ngIf="table.length">
                                            <table id="patreggraph" datatable
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #0d9bc4; color:#ffffff;">
                                                    <tr>
                                                        <th> Department Name </th>
                                                        <th>Description</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.DEPARTMENT_NAME}}</td>
                                                        <td>{{item.DEPARTMENT_DESCRIPTION}}</td>
                                                        <td>{{item.DEPARTMENT_STATUS}}</td>
                                                        <td style="display: flex; text-align: center;cursor: pointer;">
                                                            <a data-bs-toggle="modal" data-bs-target="#myModal"
                                                                (click)="edit(item.DEPARTMENT_ID)"><i
                                                                    class="zmdi zmdi-edit zmdi-hc-2x ">
                                                                </i></a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog ">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h5 *ngIf="!isEdit" class="modal-title w-100"> Add Department</h5>
                <h5 *ngIf="isEdit" class="modal-title w-100"> Edit Department</h5>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row ">
                            <label class="col-md-5 control-label line_2 rem_1">
                                Department Name <span class="text-danger">*</span></label>
                            <div class="col-md-7 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;<input type="text" placeholder="" class="form-control"
                                        formControlName="DEPARTMENT_NAME"onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));" >

                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-5 control-label line_2 rem_1">
                                Description <span class="text-danger">*</span></label>
                            <div class="col-md-7 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <textarea placeholder="" class="form-control"
                                        formControlName="DEPARTMENT_DESCRIPTION"onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));" ></textarea>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-5 control-label">Status <span class="text-danger">*</span></label>
                            <div class="col-md-7 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp; <select
                                        class="form-control form-control-sm shadow-sm rounded-lg" id="sel1"
                                        formControlName="DEPARTMENT_STATUS">
                                        <option [ngValue]='1'>Active</option>
                                        <option [ngValue]='0'>Inactive</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                    </fieldset>
                </form>

            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-success" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-success" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-danger" id="md_close" (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>