<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.EmployeeConsumptionList}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="card-body">
                                            <div class="text-right mb-1">
                                                <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                                    data-bs-target="#myModal">Add Employee Consumption</button>
                                            </div>
                                        <div *ngIf="table.length">
                                            <table datatable id="DataTables_Table_0"
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #0d9bc4; color:#ffffff;">
                                                    <tr>
                                                        <th>
                                                            Employee Name</th>
                                                         <th>No.Of Items</th>
                                                        <th> Designation</th>
                                                        <th>Description</th>
                                                        <th>Action</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.employee_name}}</td>
                                                        <td>{{item.issued_quantity}}</td> 
                                                        <td>{{item.designation }}</td>
                                                        <td>{{item.description }}</td>
                                                        <td style="display: flex;">
                                                            <span data-bs-toggle="modal" data-bs-target="#myModal"
                                                                src="" class="edit_icon" (click)="edit(item.inventory_employee_id)">
                                                                <i class="fas fa-pen pt-1 mt-1"></i></span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length" >
                                            No Records to display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog  modal-lg">
        <div class="modal-content">

            <div class="modal-header py-2">
                <h6 *ngIf="!isEdit" class="modal-title w-100"> {{headingName.AddEmployeeConsumption}}</h6>
                <h6 *ngIf="isEdit" class="modal-title w-100"> {{headingName.EditEmployeeConsumption}}</h6>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>

            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                         <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.Campus}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName="campus_id"
                                    class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" [selected]="true">Select</option>
                                        <option value="" *ngFor="let item of campus" [value]='item.campus_id'>
                                            {{item.campus_name}}
                                        </option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('campus_id').touched">
                                    <span class="text-danger" *ngIf="myForm.get('campus_id').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.Designation}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select  formControlName="designation_id"(change)="changeDesignation($any($event).target.value)"
                                    class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" [selected]="true">Select</option>
                                        <option value="" *ngFor="let item of designation" [value]='item.ROLE_ID'>
                                            {{item.ROLE_NAME}}
                                        </option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('designation_id').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('designation_id').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.Employee}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select  formControlName="employee_id"(change)="employeeTable($any($event).target.value)"
                                    class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" [selected]="true">Select</option>
                                     <option value="" *ngFor="let item of employee" [value]='item.USERID'>
                                            {{item.FIRSTNAME}}
                                        </option> 
                                    </select>
                                </div>
                                 <div *ngIf="myForm.get('employee_id').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('employee_id').hasError('required')">
                                        This field is required.
                                    </span>

                                </div> 
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.Description}}<span class="text-danger">*</span></label>
                                <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <input placeholder="Late Drop purpose"  
                                        class="form-control form-control-sm" formControlName="description" type="test">
                                </div>
                                <div *ngIf="myForm.get('description').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('description').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                                <!-- <div *ngIf="myForm.get('purpose').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('purpose').hasError('required')">
                                        This field is required.
                                    </span>

                                </div> -->
                            <!-- </div>
                        </div> -->


                        <div  class="form-group row pt-4" *ngIf="!isEdit">
                            <table *ngIf="isCampus"   id="patreggraph" class=" table-striped table-bordered mt-2 pt-2 table-sm small">
                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                <tr>
                                    <th>Item </th>
                                    <th> Price </th>
                                    <th> Amount</th>   
                                    <th> Quantity</th>  
                                    <!-- <th>Quantity Used</th>  -->
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              <th>Issued On</th>
                                </tr>
                            </thead>
                                <tbody>
                                    <tr *ngFor="let item of employees">
                                        <td>{{item.item_name}}</td>  
                                        <td>{{item.cost}}</td> 
                                        <td>{{item.total_amount}}</td> 
                                        <td>{{item.quantity}}</td> 
                                      <td>{{item.created_on}}</td> 
                                      <!-- <td>
                                        <input type="text" (change)="commsQounty($any($event.target).value)" formControlName="qounty" [value]="item.inventory_employee_details_id" >
                                      </td> -->
                                    </tr>
                                </tbody>
                            </table>
                        </div>


                        
                        <div  class="form-group row pt-4" *ngIf="isEdit">
                            <table   id="patreggraph" class=" table-striped table-bordered mt-2 pt-2 table-sm small">
                            <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                <tr>
                                    <th>Item </th>
                                    <th> Issued Quantity </th>
                                    <th>Quantity In Hand</th>   
                                    <th> Quantity Used</th>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                <th>Issued On</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of updateList">
                                        <td>{{item.item_name}}</td>  
                                        <td>{{item.issued_quantity}}</td> 
                                        <td>{{item.quantity_on_hand}}</td> 
                                        <td>
                                            <input type="text" (change)="comQuantity(item.inventory_employee_details_id)" formControlName="quantityy"  >
                                          </td>
                                        <td>{{item.issued_on}}</td> 
                                      
                                    </tr>
                                </tbody>
                            </table>
                        </div>



                        <div *ngIf="myForm.controls['list']">
                            <div formArrayName='list'>
                                <div class="form-group row pt-4">
                                    <table class="col-md-6 offset-md-3 text-center" *ngIf="!isEdit">
                                        <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                            <tr>
                                                <th>Item</th>
                                                <th>Quantity</th>
                                            </tr>
                                        </thead>
                                        <tbody
                                            *ngFor="let item of myForm.controls['list'].controls;let i=index"
                                            [formGroupName]="i">
                                            <tr>
                                                <td><select class="form-control form-control-sm"
                                                    formControlName="item_id" id="item"(change)="priceAmount($event)">
                                                    <option value="" [selected]="0">Select</option>
                                                    <option *ngFor="let item of items" [value]="item.item_id">
                                                        {{item.item_name}}
                                                    </option>
                                                </select></td>
                                                <td> <input placeholder="Quantity"
                                                        formControlName="quantity"
                                                        class="form-control form-control-sm"
                                                        type="number" id="Quantity" [(ngModel)]="item.quantity">
                                                </td>
                                                  <td>
                                                    <button class="btn btn-di float-end"
                                                    *ngIf="isVisable()" (click)="delete()"><i
                                                        class="fas fa-trash" style="color:red ;"></i></button>
                                                  </td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div class="row" *ngIf="!isEdit">
                                <div class="col-md-6 offset-md-3 mt-4">
                                    <div class="float-end">
                                        <button class="btn btn-ai float-end btn btn-save btn-sm" *ngIf="isAdd()"
                                            (click)="add()"type="button">Add
                                            Item</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                      
                    </fieldset>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-save btn-sm" (click)="onSubmit(myForm)"
                [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-save btn-sm" (click)="onSubmit(myForm)"
                [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-danger  btn-sm" id='md_close' (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>