import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-consumption',
  templateUrl: './consumption.component.html',
  styleUrls: ['./consumption.component.css']
})
export class ConsumptionComponent implements OnInit {
  table: Array<any> = [];
  isEdit: boolean = false;
  editData: any;
  myForm: FormGroup;
  isCampus: boolean = false;
  campus: Array<any> = [];
  designation: Array<any> = [];
  employee: Array<any> = [''];
  items: Array<any> = [];
  employees: Array<any> = [];
  updatedata: Array<any> = [];
  updateList: Array<any> = [];
  ucost: any;
  labelName: any = {};
  headingName: any = {};
  workItemId: any;

  constructor(private active: ActivatedRoute,private CommonService: CommonService, private toastr: ToastrService, private fb: FormBuilder) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadConsumption();
    this.campusDropdown();
    this.designationDropdown();
    this.loadItems();
    this.getLabels();
    this.loadHeadings();

  }


  ngOnInit(): void {
    this.myForm = this.fb.group({
      campus_id: ['', Validators.required],
      designation_id: ['', Validators.required],
      employee_id: ['', Validators.required],
      description: ['', Validators.required],
      item_id: [''],
      quantity: [''],
      inventory_employee_id: [''],
      list: this.fb.array([]),
      quantityy: ['']
    });
    this.add();
  }
  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }
  
  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  loadConsumption() {
    let payLoad: any = {
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('EmsConsumption/GetList', payLoad).subscribe((res: any) => {
      this.table = res;
    })
  }
  campusDropdown() {
    let payload = {
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('EmsCampus/CampusDropDownList', payload).subscribe((res: any) => {
      this.campus = res;
    })
  }

  designationDropdown() {
    let payLoad = {
      "TENANT_CODE": localStorage.getItem('TenantCode')

    }
    this.CommonService.postCall('Registration/GetRolesByTenantCode', payLoad).subscribe((res: any) => {
      this.designation = res;
    })
  }

  changeDesignation(id) {
    let payLoad = {
      "TenantCode": localStorage.getItem('TenantCode'),
      "objUserinrole": { "RoleId": id }
    }
    this.CommonService.postCall('Registration/UserRolesChange', payLoad).subscribe((res: any) => {
      this.employee = res;
    })
  }


  getOptionGroup(data) {
    let newGroup = this.fb.group({
      item_id: [data.item_id ? data.item_id : Number, Validators.required],
      quantity: [data.quantity ? data.quantity : Number, Validators.required],

    });
    // const quantityCtrl = newGroup.get('quantity');
    return newGroup

  }
  add(data = {}) {
    const arrayControl = <FormArray>this.myForm.controls['list'];
    let grp: FormGroup = this.getOptionGroup(data)
    arrayControl.push(grp)
  }

  delete() {
    const arrayControl = <FormArray>this.myForm.controls['list'];
    let index = arrayControl.length;
    arrayControl.removeAt(index - 1)
    // this.isEdit=true
  }
  isVisable() {
    let arrayControl = <FormArray>this.myForm.controls['list'];
    if (arrayControl.length && arrayControl.length > 0) {
      return true
    }
    else {
      return false
    }
  }


  isAdd() {

    let arrayControl = <FormArray>this.myForm.controls['list'];
    if (arrayControl.length && arrayControl.length > 25) {
      return false
    }
    else {
      return true
    }

  }
  loadItems() {
    this.items = [];
    this.CommonService.postCall('InvetoryItems/InventoryItemsCampus', {}).subscribe((res: any) => {
      this.items = res;
    })
  }
  priceAmount(event) {
    let ovj = this.items.filter(x => x.item_id == event.target.value);
    ovj.forEach(e => {
      this.ucost = e.unit_price
    });
  }
  employeeTable(eId: any) {
    this.isCampus = true;
    let payLoad = {
      "employee_id": eId
    }
    this.CommonService.postCall('EmsConsumption/GetItemsBasedOnEmployeeId', payLoad).subscribe((res: any) => {
      this.employees = res;
    })
  }

  comQuantity(id: any) {
    let val = this.myForm.value;
    let obj = {
      inventory_employee_details_id: id,
      quantity_used: val.quantityy
    }
    this.updatedata.push(obj);
  }


  onSubmit(myForm: any) {
    let value: any = myForm.value;
    let list = [];
    myForm.value.list.forEach(e => {
      let item: any = '';
      Object.keys(e).map((key, i) => {
        let value = e[key];
        if (i > 0) {
          if (value) {
            item = item ? item + ',' + i : i;
          }
        }
      })
      let obj = {
        "item_id": e.item_id,
        "issued_quantity": e.quantity,
      }
      list.push(obj);
    });
    let payload = {
      "designation_id": value.designation_id,
      "employee_id": value.employee_id,
      "description": value.description,
      "created_by": localStorage.getItem('UserId'),
      "tnt_code": localStorage.getItem('TenantCode'),
      "ItemsEmployeeDetails": list,

    }

    if (this.isEdit) {
      value.modified_by = localStorage.getItem('UserId');

      // payload['inventory_employee_details_id'] = this.editData.inventory_employee_details_id;

      this.CommonService.postCall('EmsConsumption/Update', this.updatedata).subscribe((res: any) => {
        this.loadConsumption();
        this.toastr.success("Updated Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Not Updated')
      })
    } else {
      this.CommonService.postCall('EmsConsumption/Create', payload).subscribe((res: any) => {
        this.loadConsumption();
        this.toastr.success("Consumption Created Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Consumption Not created')
      })
    }
  }


  edit(employeeid) {
    // this.editData = employeeid;
    this.isEdit = true;
    this.myForm.reset();
    let payLoad = {
      "inventory_employee_id": employeeid
    }
    this.CommonService.postCall('EmsConsumption/Get', payLoad).subscribe((res: any) => {
      this.updateList = res.ItemsEmployeeDetails;
      if (res instanceof Array && res.length) {
        this.editData = res[0];
        this.dataTransfer(res[0]);
      } else {
        this.editData = res;
        this.dataTransfer(res)
      }
      // this.campusTable(this.editData.campus_id)
      this.changeDesignation(this.editData['designation_id']);

    })
  }

  dataTransfer(data) {
    let ctrls = this.myForm.controls;
    // Object.keys(ctrls).map(key => {
    //   let ctrl = ctrls[key];
    //   ctrl?.setValue(data[key])
    // })
    ctrls['campus_id'].setValue(data['campus_id']);
    ctrls['designation_id'].setValue(data['designation_id']);
    ctrls['description'].setValue(data['description']);
  }

  //     if (res instanceof Array && res.length) {
  //       this.editData = res[0];
  //       this.changeDesignation(this.editData.ROLE_ID)
  //       this.dataTransfer(res[0]);

  //     } else {
  //       this.editData = res;
  //       setTimeout(() => {
  //         this.dataTransfer(res)
  //       }, 1000);


  //     }



  //   })
  // }


  // dataTransfer(data){
  //   let ctrls = this.myForm.controls;
  //   // Object.keys(ctrls).map(key => {
  //   //   let ctrl = ctrls[key];
  //   //   ctrl.setValue(data[key])
  //   // })
  //   ctrls['campus_id'].setValue(data['campus_id']);
  //   ctrls['designation_id'].setValue(data['designation_id']); 
  //   ctrls['employee_id'].setValue(data['employee_id'])  
  //   ctrls['description'].setValue(data['description']); 

  // }
  close() {
    this.isEdit = false;
    this.myForm.reset();
    this.editData = {};
    this.isCampus = null;

  }

}
