import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-barcode-generate',
  templateUrl: './barcode-generate.component.html',
  styleUrls: ['./barcode-generate.component.css']
})
export class BarcodeGenerateComponent implements OnInit {
  barCode:any;
  headingName: any = {};
  workItemId: any;
  constructor( active: ActivatedRoute,private commonService: CommonService,) { 
    active.queryParams.subscribe((res) => {
      this.workItemId = res.wId,
      this.barCode = res.aNo
    })
  
    this.loadHeadings();
  }

  ngOnInit(): void {

  }


  loadHeadings() {
    this.headingName = {};
    this.commonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  
  Print(){
    var printContents: any = document.getElementById("barCode")?.innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  }

  back(){
    window.history.back();
  }
}
