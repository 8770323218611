import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-student-trip-attendance',
  templateUrl: './student-trip-attendance.component.html',
  styleUrls: ['./student-trip-attendance.component.css']
})
export class StudentTripAttendanceComponent implements OnInit {
  table : Array<any>=[];
  headingName:any= {};
  workItemId: any;
  constructor(private fb: FormBuilder,private active : ActivatedRoute, private CommonService: CommonService, private toastr: ToastrService) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadTrip();
    this.loadHeadings();
   }

  ngOnInit(): void {
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  loadTrip(){
    this.CommonService.getCall(`Transport/GetStudentTripAttendanceList/${localStorage.getItem('TenantCode')}`).subscribe((res : any)=>{
      this.table = res;
    })
  }

}
