<br>
<div class="page-wrapper">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">

                    <div class="container-fluid">

                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.TimeTable}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="row" [formGroup]="searchForm">
                                            <div class="form-group col-md-3">
                                                <label>Course </label>
                                                <div class="input-group">
                                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                        formControlName="course_id" (change)="onCourseChange($event)">
                                                        <option value="0" selected>Select</option>
                                                        <option *ngFor="let item of courseDropdown"
                                                            [value]="item.COURSE_ID">
                                                            {{item.COURSE_NAME}}</option>
                                                    </select>

                                                </div>
                                            </div>
                                            <!-- <div class="form-group col-md-3">
                                                <label>Course Schedule</label>
                                                <div class="input-group">
                                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                        formControlName="course_schedule_id">
                                                        <option value="0" selected>Select Course Schedule</option>
                                                        <option *ngFor="let item of courseScheduleDropdown"
                                                            [value]="item.COURSESHD_ID">
                                                            {{item.COURSESHD_NAME}}</option>
                                                    </select>
                                                </div>
                                            </div> -->
                                            <div class="form-group col-md-3">
                                                <label>Academic Year</label>
                                                <div class="input-group">
                                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                        formControlName="course_schedule_id">
                                                        <option value="0" selected>Select</option>
                                                        <option *ngFor="let item of academicYearDropdown"
                                                            [value]="item.ACADEMIC_ID">
                                                            {{item.ACADEMIC_NAME}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="form-group col-md-3">
                                                <label>Course Year</label>
                                                <div class="input-group">
                                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                        formControlName="course_schedule_id">
                                                        <option value="0" selected>Select</option>
                                                        <option *ngFor="let item of courseYearDropdown"
                                                            [value]="item.DICTIONARYID">
                                                            {{item.DICTIONARYNAME}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="form-group col-md-3">
                                                <label>Semester</label>
                                                <div class="input-group">
                                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                        formControlName="course_schedule_id">
                                                        <option value="0" selected>Select </option>
                                                        <option *ngFor="let item of semesterDropdown"
                                                            [value]="item.DICTIONARYID">
                                                            {{item.DICTIONARYNAME}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="form-group col-md-3">
                                                <label>Section</label>
                                                <div class="input-group">
                                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                        formControlName="course_schedule_id">
                                                        <option value="0" selected>Select</option>
                                                        <option *ngFor="let item of sectionDropdown"
                                                            [value]="item.DICTIONARYID">
                                                            {{item.DICTIONARYNAME}}</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="form-group col-md-2" style="padding-top: 21px;">

                                                <button type="button" (click)="onSearch(searchForm)"
                                                    class="btn btn-sm  btn-save"><i
                                                        class="fas fa-search fa-sm"></i></button>
                                            </div>
                                        </div>
                                        <div class="text-right mb-2">
                                            <button type="button" (click)="add()" class="btn btn-primary btn-sm">Add
                                                TimeTable</button>
                                        </div>
                                        <div *ngIf="table.length">
                                            <table id="patreggraph" datatable
                                                class="table table-striped table-bordered mt-2 pt-2 table-sm small display"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624fd7; color:#ffffff;">
                                                    <tr>
                                                        <th> Course </th>
                                                        <th> Academic Year </th>
                                                        <th> Course Year </th>
                                                        <th> Semester </th>
                                                        <th> Section </th>
                                                        <th> Total Periods</th>
                                                        <th> Status </th>
                                                        <th> Action </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.course_name}}</td>
                                                        <td>{{item.academic_name}}</td>
                                                        <td>{{item.course_year}}</td>
                                                        <td>{{item.semester}}</td>
                                                        <td>{{item.section}}</td>                                                        
                                                        <td>{{item.total_periods}}</td>
                                                        <td>{{item.status == true ? 'Active' : 'Inactive'}}</td>
                                                        <td style="display: flex; text-align: center;">
                                                            <a data-bs-toggle="modal" data-bs-target="#myModal"
                                                                (click)="edit(item.timetable_id)"><i
                                                                    class="zmdi zmdi-edit zmdi-hc-2x">
                                                                </i></a>&nbsp;&nbsp;
                                                            <button *ngIf="item.status == true"
                                                                class="btn btn-sm btn-ai"
                                                                (click)="active(item.timetable_id,item.status)">Inactive</button>
                                                            <button *ngIf="item.status == false"
                                                                class="btn btn-sm btn-ai"
                                                                (click)="active(item.timetable_id,item.status)">Active</button>
                                                        </td>
                                                    </tr>
                                                </tbody>

                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records To Dispaly
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>









<!-- <div class="modal fade" id="myModal"  data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header py-2">
                <h4 *ngIf="!isEdit" class="modal-title w-100"> Add TimeTable</h4>
                <h4 *ngIf="isEdit" class="modal-title w-100"> edit TimeTable</h4>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal" aria-bs-hidden="true"><i
                        class="fas fa-times"></i></button>
            </div>


            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row">
                            <label class="col-md-4 control-label ">Campus <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <select class="form-control form-control-sm shadow-sm rounded-lg"
                                        formControlName="campus_id">
                                        <option [selected]="true">Select Campus</option>
                                        <option *ngFor="let item of campusDropdown" [value]="item.campus_id">
                                            {{item.campus_name}}</option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('campus').touched">
                                            <span class="text-danger" *ngIf="myForm.get('campus').hasError('required')">
                                                This field is required.
                                            </span>
                                        </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Course <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName="class_id" [(ngModel)]='courceId '
                                        (change)="courceChange($any($event).target.value);getSubjectsbyCourse($any($event).target.value)"
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                        <option value="" [selected]="true">Select</option>
                                        <option *ngFor="let course of cources" [value]='course.COURSE_ID'>
                                            {{course.COURSE_NAME}}
                                        </option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('class_id').touched">
                                    <span class="text-danger" *ngIf="myForm.get('class_id').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Course Schedule <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName="section_id"
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option *ngFor="let schedul of scheduls" [value]='schedul.COURSESHD_ID'>
                                            {{schedul.COURSESHD_NAME}}</option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('section_id').touched">
                                    <span class="text-danger" *ngIf="myForm.get('section_id').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Periods <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName='period' (change)="periodChange($event.target.value)"
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                        <option value="" [selected]="true">Select</option>
                                        <option *ngFor="let item of preriodDropdown" [value]="item">{{item}}</option>

                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Period Duration (in minutes) <span class="text-danger"> * </span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName='period_duration'
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                        <option value="" [selected]="true">Select</option>
                                        <option *ngFor="let item of preriodDurationDropdown" [value]="item">{{item}}
                                        </option>

                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4" *ngIf="viewTable" formArrayName='periods'>
                            <table class="table table-striped table-bordered table-responsive">
                                <thead class="top" style="background-color: #624fd7; color:#ffffff;">
                                    <tr>
                                        <th>Periods</th>
                                        <th>Monday</th>
                                        <th>Tuesday </th>
                                        <th>Wednesday</th>
                                        <th>Thursday</th>
                                        <th>Friday</th>
                                        <th>Saturday</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngFor="let item of myForm.controls.periods.controls;let i=index"
                                        [formGroupName]="i">
                                        <td>
                                            <label for="">Start Time :</label>
                                            <input type="time" formControlName="periodStart">
                                            <br>
                                            <label for="">End Time &nbsp; :</label>
                                            <input type="time" formControlName="periodEnd">
                                        </td>
                                        <td>
                                            <a *ngIf="!isAssign" href="javascript:;" data-bs-toggle="modal"
                                                data-bs-target="#Aasign">Assign</a>
                                        </td>
                                        <td>
                                            <a *ngIf="!isAssign" href="javascript:;" data-bs-toggle="modal"
                                                data-bs-target="#Aasign">Assign</a>
                                        </td>
                                        <td>
                                            <a *ngIf="!isAssign" href="javascript:;" data-bs-toggle="modal"
                                                data-bs-target="#Aasign">Assign</a>
                                        </td>
                                        <td>
                                            <a *ngIf="!isAssign" href="javascript:;" data-bs-toggle="modal"
                                                data-bs-target="#Aasign">Assign</a>
                                        </td>
                                        <td>
                                            <a *ngIf="!isAssign" href="javascript:;" data-bs-toggle="modal"
                                                data-bs-target="#Aasign">Assign</a>
                                        </td>
                                        <td>
                                            <a *ngIf="!isAssign" href="javascript:;" data-bs-toggle="modal"
                                                data-bs-target="#Aasign">Assign</a>
                                        </td>

                                    </tr>

                                </tbody>
                            </table>
                        </div>

                    </fieldset>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-success" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-success" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-danger" id='md_close' (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div> -->