import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, AbstractControl, FormGroup, Validators } from '@angular/forms';
import { EditUserRegistrationComponent } from 'src/app/pages/edit-user-registration/edit-user-registration.component';
import { CommonService } from 'src/app/services/common.service';
import { forkJoin } from 'rxjs';
import { dataDictionary } from 'src/app/dataDictionary';

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.css']
})
export class EducationComponent implements OnInit {
  @Input() parent: EditUserRegistrationComponent;
  @Input() editData: any = {};
  changed: boolean = false;
  table: Array<any> = [];
  myForm: FormGroup;
  roleId: number;
  isEdit: boolean = false;
  educationTypes: Array<any> = [];
  educationModes: Array<any> = [];
  educationNames: Array<any> = [];
  EducationName: Array<any> = [];
  Specialization: Array<any> = [];
  Institutes: Array<any> = [];
  index: number;
  labelName: any = {};
  educationData: Array<any> = [];
  headingName: any = {};

  constructor(private fb: FormBuilder, private CommonService: CommonService) {
    //this.getAll()
    this.getLabels();
    this.loadHeadings();
    this.roleId = +localStorage.getItem('RoleId');
  }

  ngOnInit(): void {
    this.ngMyform();
    this.getEducationGroup();
    this.parent.childs['EducationTypeList'] = this
    // console.log(this.parent.childs['EducationTypeList'] = this)

  }
  ngMyform() {
    this.myForm = this.fb.group({
      EDUCATIONTYPE: [''],
      EDUCATIONMODE: [''],
      EDUCATIONNAME: [''],
      SPECIALIZATION: [''],
      UNIVERSITYINSTITUTE: [''],
      YEAROFPASSING: ['']
    });
  }

  ngOnChanges() {
    if (Object.keys(this.editData).length && !this.changed) {
      this.changed = true;
      this.educationData = this.editData['Education'].slice(0);
      this.dataTransform(this.editData['Education'].slice(0));

    }
  }
  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${localStorage.getItem('regWorkItem')}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${localStorage.getItem('regWorkItem')}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;

    })
  }

  getAll() {
    let educationType = this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.EducationType });//Eduction Type
    let educationNames = this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.EducationName });//Eduction Name

    forkJoin([educationType, educationNames]).subscribe((res) => {
      [this.educationTypes, this.EducationName] = [...res];
    });
  }
  dataTransform(data: Array<any>) {
    data.map(item => {

      this.table.push(item)
    });
  }
  getEducationGroup() {
    this.parent.CommonService.postCall('GetEducationGroup', {}).subscribe(
      (res: any) => {
        if (Object.keys(res).length) {
          [this.educationTypes, this.educationModes, this.EducationName, this.Specialization, this.Institutes] = [res.EducationType, res.EducationMode, res.EducationName, res.Specialization, res.UniversityInstitute]
        }
      }
    )
  }
  add() {
    this.isEdit = false;
  }
  edit(data, i) {
    this.index = i;
    this.isEdit = true;
    this.editData = data;
    this.assignDataForm(i);
  }
  assignDataForm(i) {
    let data = this.educationData[i];
    let ctrls = this.myForm.controls;
    Object.keys(ctrls).map((formControlName: string) => {
      let control: AbstractControl = ctrls[formControlName];
      control.setValue(data[formControlName]);
    })
    console.log('edit form value', this.myForm.value);
  }
  close() {

    this.ngMyform();
  }
  onSubmit(form: FormGroup) {
    let value = form.value;
    if (this.isEdit) {
      if (this.editData.TYPE == 'edit') {
        value.TYPE = 'update'
      } else {
        value.TYPE = 'insert'
      }
      let data = Object.assign({}, value);
      this.table[this.index] = data;
      this.educationData.push(data);
      console.log(this.educationData);
    } else {
      value.TYPE = 'insert'
      let data = Object.assign({}, value);
      this.table.push(data);
      this.educationData.push(data);
    }
    document.getElementById('md_close_education').click();
    this.parent.toastr.success('Updated successfully')
  }
  modeChange(value) {
    this.parent.activeSpinner();
    this.parent.CommonService.postCall('GetEducationName', { EducationModeId: value }).subscribe(
      (res: any) => {
        this.educationNames = res;
        this.parent.deactivateSpinner()
      }, err => {
        this.parent.deactivateSpinner()
      }
    )
  }

}
