<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.ConsolidatedMarkSheet}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="form-group">
                                            <div class="row">

                                                <div *ngIf="roleId=='4' || roleId=='5'" class="col-md-3">
                                                    <label for="sel1">{{labelName.TenantName}} : </label>
                                                    <select [(ngModel)]='tId ' (change)="getCourses($any($event.target).value)"
                                                        class="form-control form-control-sm shadow-sm rounded-lg"
                                                        id="sel1">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let item of tenanates" [value]='item.TNT_CODE'>
                                                            {{item.TNT_NAME}}</option>
                                                    </select>

                                                </div>
                                                
                                                <div class="col-md-3">
                                                    <label for="sel1"> {{labelName.Course}}</label>
                                                    <select [(ngModel)]='courceId '
                                                        class="form-select form-select-sm shadow-sm rounded-lg"
                                                        id="sel1" (change)="courceChange($any($event).target.value)">
                                                        <option value="" selected>Select
                                                        </option>
                                                        <option *ngFor="let course of cources"
                                                            [value]='course.COURSE_ID'>
                                                            {{course.COURSE_NAME}}
                                                        </option>
                                                    </select>
                                                </div>

                                                <div class="col-md-3">
                                                    <label for="sel1">{{labelName.CourseSchedule}}</label>
                                                    <select [(ngModel)]="sheduleId"
                                                        class="form-select form-select-sm shadow-sm rounded-lg"
                                                        id="sel1" (change)="loadTable()">
                                                        <option value="" selected>Select </option>
                                                        <option *ngFor="let schedul of scheduls"
                                                            [value]='schedul.COURSESHD_ID'>
                                                            {{schedul.COURSESHD_NAME}}</option>
                                                    </select>
                                                </div>

                                                <div *ngIf="table.length &&isTable" class="table-responsive pt-4">
                                                    <table id="patergraph" datatable
                                                        class=" table-striped table-bordered mt-2 pt-2 table-sm small display"
                                                        style="width:100%">
                                                        <thead class="top"
                                                            style="background-color: #624FD7; color:#ffffff;">
                                                            <tr style="white-space: nowrap;">
                                                                <th>S.No</th>
                                                                <th>Enrollment Number</th>
                                                                <th>Name Of Students</th>
                                                                <th>CT1</th>
                                                                <th>CT2</th>
                                                                <th>CT3</th>
                                                                <th>CT4</th>
                                                                <th>CT5</th>
                                                                <th>CT6</th>
                                                                <th>Average Of CT</th>
                                                                <th>% Of Marks Converted Of Average CT</th>
                                                                <th>SE1</th>
                                                                <th>SE2</th>
                                                                <th>Average Of SE</th>
                                                                <th>% Of Marks Converted Of Average SE</th>
                                                                <th>PBE</th>
                                                                <th>% Of PBE Marks</th>
                                                                <th>FE</th>
                                                                <th>% Of FE Marks</th>
                                                            </tr>
                                                        </thead>


                                                        <tbody>
                                                            <tr *ngFor="let item of table;let i = index">

                                                                <td>{{i+1}}</td>
                                                                <td>{{item.enrollmentno}}</td>
                                                                <td>{{item.name1}}</td>
                                                                <td>{{item.ct_1}}</td>
                                                                <td>{{item.ct_2}}</td>
                                                                <td>{{item.ct_3}}</td>
                                                                <td>{{item.ct_4}}</td>
                                                                <td>{{item.ct_5}}</td>
                                                                <td>{{item.ct_6}}</td>
                                                                <td>{{item.ctavg  == "AB" ? "AB" : item.ctavg | number : '1.2-2'}}</td>
                                                                <td>{{item.ct1per  == "AB" ? "AB" : item.ct1per | number : '1.2-2'}}</td>
                                                                <td>{{item.se_1}}</td>
                                                                <td>{{item.se_2}}</td>
                                                                <td>{{item.seavg  == "AB" ? "AB" : item.seavg | number : '1.2-2'}}</td>
                                                                <td>{{item.se1per == "AB" ? "AB" : item.se1per | number : '1.2-2' }}</td>
                                                                <td>{{item.pbe_1}}</td>
                                                                <td>{{item.pbe1per  == "AB" ? "AB" : item.pbe1per | number : '1.2-2'}}</td>
                                                                <td>{{item.fe_1}}</td>
                                                                <td>{{item.fe1per  == "AB" ? "AB" : item.fe1per | number : '1.2-2'}}</td>
                                                            </tr>

                                                            <tr style="font-weight:bold;">

                                                                <td class="text-center" colspan="3">No of Students Present</td>
                                                                <td>{{emsPerctgDetails.ct1_present}}</td>
                                                                <td>{{emsPerctgDetails.ct2_present}}</td>
                                                                <td>{{emsPerctgDetails.ct3_present}}</td>
                                                                <td>{{emsPerctgDetails.ct4_present}}</td>
                                                                <td>{{emsPerctgDetails.ct5_present}}</td>
                                                                <td>{{emsPerctgDetails.ct6_present}}</td>
                                                                <td>{{emsPerctgDetails.ct_present}}</td>
                                                                <td>{{emsPerctgDetails.ct_present}}</td>
                                                                <td>{{emsPerctgDetails.se1_present}}</td>
                                                                <td>{{emsPerctgDetails.se2_present}}</td>
                                                                <td>{{emsPerctgDetails.se_present}}</td>
                                                                <td>{{emsPerctgDetails.se_present}}</td>
                                                                <td>{{emsPerctgDetails.pb_present}}</td>
                                                                <td>{{emsPerctgDetails.pb_present}}</td>
                                                                <td>{{emsPerctgDetails.fe_present}}</td>
                                                                <td>{{emsPerctgDetails.fe_present}}</td>

                                                            </tr>

                                                            <tr style="font-weight:bold;">

                                                                <td class="text-center" colspan="3">No of Students Absent</td>
                                                                <td>{{emsPerctgDetails.ct1_absent}}</td>
                                                                <td>{{emsPerctgDetails.ct2_absent}}</td>
                                                                <td>{{emsPerctgDetails.ct3_absent}}</td>
                                                                <td>{{emsPerctgDetails.ct4_absent}}</td>
                                                                <td>{{emsPerctgDetails.ct5_absent}}</td>
                                                                <td>{{emsPerctgDetails.ct6_absent}}</td>
                                                                <td>{{emsPerctgDetails.ct_absent}}</td>
                                                                <td>{{emsPerctgDetails.ct_absent}}</td>
                                                                <td>{{emsPerctgDetails.se1_absent}}</td>
                                                                <td>{{emsPerctgDetails.se2_absent}}</td>
                                                                <td>{{emsPerctgDetails.se_absent}}</td>
                                                                <td>{{emsPerctgDetails.se_absent}}</td>
                                                                <td>{{emsPerctgDetails.pb_absent}}</td>
                                                                <td>{{emsPerctgDetails.pb_absent}}</td>
                                                                <td>{{emsPerctgDetails.fe_absent}}</td>
                                                                <td>{{emsPerctgDetails.fe_absent}}</td>

                                                            </tr>


                                                            <tr style="font-weight:bold;">

                                                                <td class="text-center" colspan="3">Students Performing
                                                                    Above Threshold</td>
                                                                <td>{{emsPerctgDetails.ct1trishold_value}}</td>
                                                                <td>{{emsPerctgDetails.ct2trishold_value}}</td>
                                                                <td>{{emsPerctgDetails.ct3_trisholdvalue}}</td>
                                                                <td>{{emsPerctgDetails.ct4trishold_value}}</td>
                                                                <td>{{emsPerctgDetails.ct5trishold_value}}</td>
                                                                <td>{{emsPerctgDetails.ct6trishold_value}}</td>
                                                                <td>{{emsPerctgDetails.cttrisholdoverallcount}}</td>
                                                                <td>{{emsPerctgDetails.cttrisholdoverallcount}}</td>
                                                                <td>{{emsPerctgDetails.se1trishold_value}}</td>
                                                                <td>{{emsPerctgDetails.se2trishold_value}}</td>
                                                                <td>{{emsPerctgDetails.setrisholdoverallcount}}</td>
                                                                <td>{{emsPerctgDetails.setrisholdoverallcount}}</td>
                                                                <td>{{emsPerctgDetails.pbe1trishold_value}}</td>
                                                                <td>{{emsPerctgDetails.pbe1trishold_value}}</td>
                                                                <td>{{emsPerctgDetails.fe1trishold_value}}</td>
                                                                <td>{{emsPerctgDetails.fe1trishold_value}}</td>

                                                            </tr>
                                                            <tr style="font-weight:bold;">

                                                                <td class="text-center" colspan="3">% of students
                                                                    performing above threshold </td>
                                                                <td>{{emsPerctgDetails.per_ct1trishold_value | number : '1.2-2'}}</td>
                                                                <td>{{emsPerctgDetails.per_ct2trishold_value | number : '1.2-2'}}</td>
                                                                <td>{{emsPerctgDetails.per_ct3_trisholdvalue | number : '1.2-2'}}</td>
                                                                <td>{{emsPerctgDetails.per_ct4trishold_value | number : '1.2-2'}}</td>
                                                                <td>{{emsPerctgDetails.per_ct5trishold_value | number : '1.2-2'}}</td>
                                                                <td>{{emsPerctgDetails.per_ct6trishold_value | number : '1.2-2'}}</td>
                                                                <td>{{emsPerctgDetails.cttrisholdoverallcount_per | number : '1.2-2'}}</td>
                                                                <td>{{emsPerctgDetails.cttrisholdoverallcount_per | number : '1.2-2'}}</td>
                                                                <td>{{emsPerctgDetails.per_se1trishold_value | number : '1.2-2'}}</td>
                                                                <td>{{emsPerctgDetails.per_se2trishold_value | number : '1.2-2'}}</td>
                                                                <td>{{emsPerctgDetails.setrisholdoverallcount_per | number : '1.2-2'}}</td>
                                                                <td>{{emsPerctgDetails.setrisholdoverallcount_per | number : '1.2-2'}}</td>
                                                                <td>{{emsPerctgDetails.per_pbe1trishold_value | number : '1.2-2'}}</td>
                                                                <td>{{emsPerctgDetails.per_pbe1trishold_value | number : '1.2-2'}}</td>
                                                                <td>{{emsPerctgDetails.per_fe1trishold_value | number : '1.2-2'}}</td>
                                                                <td>{{emsPerctgDetails.per_fe1trishold_value | number : '1.2-2'}}</td>
                                                            </tr>

                                                            <tr style="font-weight:bold;">
                                                                <td class="text-center" colspan="3">Students performance
                                                                    level</td>
                                                                <td>{{emsPerctgDetails.perlevel_ct1trishold_value}}</td>
                                                                <td>{{emsPerctgDetails.perlevel_ct2trishold_value}}</td>
                                                                <td>{{emsPerctgDetails.perlevel_ct3_trisholdvalue}}</td>
                                                                <td>{{emsPerctgDetails.perlevel_ct4trishold_value}}</td>
                                                                <td>{{emsPerctgDetails.perlevel_ct5trishold_value}}</td>
                                                                <td>{{emsPerctgDetails.perlevel_ct6trishold_value}}</td>
                                                                <td>{{emsPerctgDetails.cttrisholdoverallcount_perlevel}}</td>
                                                                <td>{{emsPerctgDetails.cttrisholdoverallcount_perlevel}}</td>
                                                                <td>{{emsPerctgDetails.perlevel_se1trishold_value}}</td>
                                                                <td>{{emsPerctgDetails.perlevel_se2trishold_value}}</td>
                                                                <td>{{emsPerctgDetails.setrisholdoverallcount_perlevel}}</td>
                                                                <td>{{emsPerctgDetails.setrisholdoverallcount_perlevel}}</td>
                                                                <td>{{emsPerctgDetails.perlevel_pbe1trishold_value}}</td>
                                                                <td>{{emsPerctgDetails.perlevel_pbe1trishold_value}}</td>
                                                                <td>{{emsPerctgDetails.perlevel_fe1trishold_value}}</td>
                                                                <td>{{emsPerctgDetails.perlevel_fe1trishold_value}}</td>

                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>