<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.RecivedQuotation}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="custom_container">

                                            <form class="well form-horizontal" [formGroup]="myForm"
                                                (ngSubmit)="onSubmit(myForm)">
                                                <fieldset>
                                                    <div *ngIf="myForm.controls['Quotation']">
                                                        <div formArrayName='Quotation'>
                                                            <div *ngFor="let item of myForm.controls['Quotation'].controls;let i=index"
                                                                [formGroupName]="i">
                                                                <div class="form-group row">
                                                                    <label
                                                                        class="col-md-2 offset-md-3 control-label mb-4">
                                                                       {{labelName.Vendors}}  <span
                                                                            class="text-danger">*</span></label>
                                                                    <div class="col-md-4 mb-4 inputGroupContainer">
                                                                        <div class="input-group"> <strong>:</strong>
                                                                            &nbsp;
                                                                            <select placeholder="Request Date"
                                                                                class="form-control form-control-sm"
                                                                                formControlName="vendor_id" type="date">
                                                                                <option [selected]="true">Select
                                                                                </option>
                                                                                <option value=""
                                                                                    *ngFor="let item of vendors"
                                                                                    [value]='item.vendor_id'>
                                                                                    {{item.vendor_name}}
                                                                                </option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row" formArrayName='list'>
                                                                    <table class="col-md-6 offset-md-3 text-center">
                                                                        <thead class="top"
                                                                            style="background-color: #624FD7; color:#ffffff;">
                                                                            <tr>
                                                                                <th>Item</th>
                                                                                <th>Quantity</th>
                                                                                <th>Estimated Unit Cost</th>
                                                                                <th>Estimated Total</th>
                                                                                <th>Quoted Unit Cost</th>
                                                                                <th>Quoted Total</th>

                                                                            </tr>
                                                                        </thead>

                                                                        <tbody>

                                                                            <tr *ngFor="let it of item.controls.list.controls;let in=index"
                                                                                [formGroupName]="in">
                                                                                <td><select
                                                                                        class="form-control form-control-sm"
                                                                                        formControlName="item_id">
                                                                                        <option value=""
                                                                                            [selected]="true">
                                                                                            Select
                                                                                        </option>
                                                                                        <option disabled
                                                                                            *ngFor="let item of items"
                                                                                            [value]="item.item_id">
                                                                                            {{item.item_name}}
                                                                                        </option>

                                                                                    </select>
                                                                                    <!-- <input placeholder="Quantity"
                                                                                    formControlName="item_id"
                                                                                    class="form-control form-control-sm"
                                                                                    type="number" id="Quantity" value="{{item_id}}" disabled
                                                                                    [(ngModel)]="item_id"> -->

                                                                                </td>
                                                                                <td> <input placeholder="Quantity"
                                                                                        formControlName="quantity"
                                                                                        class="form-control form-control-sm"
                                                                                        type="number" id="Quantity"
                                                                                        disabled>
                                                                                </td>
                                                                                <td> <input placeholder="Unit Cost"
                                                                                        formControlName="estimated_cost"
                                                                                        class="form-control form-control-sm"
                                                                                        type="number" id="unitCost"
                                                                                        disabled>
                                                                                </td>
                                                                                <td> <input
                                                                                        placeholder="Quoted Unit Cost"
                                                                                        formControlName="estimated_total"
                                                                                        class="form-control form-control-sm"
                                                                                        type="number"
                                                                                        id="estimatedTotal" disabled>

                                                                                </td>
                                                                                <td><input
                                                                                        placeholder="Estimated Totals"
                                                                                        formControlName="quoted_unit_cost"
                                                                                        class="form-control form-control-sm"
                                                                                        type="number"
                                                                                        id="estimatedTotal">
                                                                                </td>
                                                                                <td>
                                                                                    <input placeholder="Quoted Total"
                                                                                        formControlName="quoted_total"
                                                                                        class="form-control form-control-sm"
                                                                                        value="{{getTotal(i,in)}}"
                                                                                        type="number" id="quoted_total"
                                                                                        disabled>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div class="form-group row ">
                                                                    <label
                                                                        class="col-md-2 offset-md-3 control-label mb-2 mt-2">
                                                                       {{labelName.TotalQuotedCost}} <span
                                                                            class="text-danger">*</span></label>
                                                                    <div
                                                                        class="col-md-4 mb-4 inputGroupContainer  mt-2">
                                                                        <div class="input-group"> <strong>:</strong>
                                                                            &nbsp;
                                                                            <input placeholder="Total Cost"
                                                                                class="form-control form-control-sm"
                                                                                formControlName="total_amount"
                                                                                type="test" value="{{grandTotal(i)}}" disabled>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row ">
                                                                    <label
                                                                        class="col-md-2 offset-md-3 control-label mb-2 mt-2">
                                                                        {{labelName.AdminComments}} </label>
                                                                    <div
                                                                        class="col-md-4 mb-4 inputGroupContainer  mt-2">
                                                                        <div class="input-group"> <strong>:</strong>
                                                                            &nbsp;
                                                                            <input placeholder="Admin Comments"
                                                                                class="form-control form-control-sm"
                                                                                formControlName="admin_comments"
                                                                                type="test">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row ">
                                                                    <label
                                                                        class="col-md-2 offset-md-3 control-label mb-2 mt-2">
                                                                        {{labelName.VendorsComments}} </label>
                                                                    <div
                                                                        class="col-md-4 mb-4 inputGroupContainer  mt-2">
                                                                        <div class="input-group"> <strong>:</strong>
                                                                            &nbsp;
                                                                            <input placeholder="Vendor Comments"
                                                                                class="form-control form-control-sm"
                                                                                formControlName="vendor_comment"
                                                                                type="test">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </fieldset>
                                            </form>


                                        </div>

                                        <div class="row mb-2">
                                            <div class="col-md-6 offset-md-3 \">
                                                <div class="float-end">
                                                    <button class="btn btn-di float-end" *ngIf="isVisable()"
                                                        (click)="delete()"><i class="fas fa-trash"></i></button>
                                                    <button class="btn btn-ai float-end" *ngIf="isAdd()"
                                                        (click)="add()">Add
                                                        Item</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card-footer text-center pt-4">
                                        <!-- <button type="button" *ngIf="isEdit" class="btn btn-sm  btn-save"
                                                    [disabled]="myForm.invalid" (click)="onSubmit(myForm)"
                                                    type="submit">Update</button> -->

                                        <button type="button" class="btn btn-sm  btn-save" [disabled]="myForm.invalid"
                                            (click)="onSubmit(myForm)" type="submit">Save</button>
                                        &nbsp; <button class="btn btn-danger btn-sm" (click)='close()'>Close</button>

                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>