import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-visitor-monitoring',
  templateUrl: './visitor-monitoring.component.html',
  styleUrls: ['./visitor-monitoring.component.css']
})
export class VisitorMonitoringComponent implements OnInit {
  myForm: FormGroup;
  table: Array<any> = [];
  whomtomeet: Array<any> = [];
  students: Array<any> = [];
  visitortype: Array<any> = [];
  cources: Array<any> = [];
  scheduls: Array<any> = [];
  courceId: string = '';
  isEdit: boolean = false;
  editData: any;
  labelName: any = {};
  headingName: any = {};
  workItemId: any;

  constructor(private fb: FormBuilder,private active : ActivatedRoute, private CommonService: CommonService, private toastr: ToastrService) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadVisitors();
    this.getCourses();
    this.getLabels();
    this.loadHeadings();

  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      whom_to_meet: ['', Validators.required],
      people_id: ['', Validators.required],
      visitor_type: ['', Validators.required],
      visitor_name: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      visitor_mobile_number: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      purpose: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      course_id: ['', Validators.required],
      course_schedule_id: ['', Validators.required],
    })
  }

  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }
  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }
  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  loadVisitors() {
    let payLoad = {
      "tenant_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('Visitors/GetList', payLoad).subscribe((res: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = res;
      }, 10);
    })
  }
  getCourses() {
    this.activeSpinner()
    this.CommonService.getAdminCourses().subscribe((res: any) => {
      this.cources = res;
      this.deactivateSpinner()
    }, e => {
      this.deactivateSpinner()
    })
  }
  courceChange(id) {
    let data = {
      "CourseId": id
    }
    this.activeSpinner()
    this.CommonService.getAdminCourseSchedule(data).subscribe((res: any) => {
      this.deactivateSpinner()
      this.scheduls = res;
    }, e => { this.deactivateSpinner() })
  }

  scheduleChange(id) {
    let payLoad = {
      "COURSESHD_ID": id
    }
    this.CommonService.postCall('Account/usersbycourseShedule', payLoad).subscribe((res: any) => {
      this.deactivateSpinner();
      this.students = res;
    }, e => {
      this.deactivateSpinner();
    });
  }


  onSubmit(form: FormGroup) {
    let value: any = form.value;
    value.visitors_id = value.visitors_id;
    value.tenant_code = localStorage.getItem('TenantCode');
    value.created_by = localStorage.getItem('UserId');

    if (this.isEdit) {
      value.visitors_id = value.visitors_id;
      value.outtime = moment(new Date());
      value['visitors_id'] = this.editData.visitors_id;
      this.CommonService.postCall('Visitors/Update', value).subscribe((res: any) => {
        this.loadVisitors();
        this.toastr.success("Visitor Updated Successfully");
        document.getElementById('md_close')?.click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Not Updated')
      })
    } else {
      // value.whom_to_meet = value.whom_to_meet;
      this.CommonService.postCall('Visitors/Create', value).subscribe((res: any) => {
        this.loadVisitors();
        this.toastr.success("Visitor Created Successfully");
        document.getElementById('md_close')?.click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Not created')

      })
    }


  }

  outTimeUpdate(vId: any) {
    console.log(vId);
    if (confirm(`Is vistor Leaving ?? Do you really want to update Vistor Out time`)) {
      this.CommonService.postCall('Visitors/Update', { "visitors_id": vId, "modified_by": localStorage.getItem('UserId') }).subscribe((res: any) => {
        this.loadVisitors();
        this.toastr.success("Visitor Updated Successfully");
      }, err => {
        this.toastr.error(err.error ? err.error : 'Not Updated')
      })
    }
  }

  edit(visitorId) {
    this.editData = visitorId;
    this.isEdit = true;
    this.myForm.reset();
    let payLoad = {
      "visitors_id": visitorId
    }
    this.CommonService.postCall('Visitors/Get', payLoad).subscribe((res: any) => {
      if (res instanceof Array && res.length) {
        this.editData = res[0];
        this.dataTransForm()
      } else {
        this.editData = res;
        this.dataTransForm()
      }
      this.courceChange(this.editData.COURSE_ID);
      this.scheduleChange(this.editData.COURSESHD_ID)
      this.dataTransForm();

    }), err => { }
  }

  dataTransForm() {
    let ctrls = this.myForm.controls
    Object.keys(ctrls).map((key) => {
      let ctrl: AbstractControl = ctrls[key];
      ctrl.setValue(this.editData[key])

    });
  }
  close() {
    this.myForm.reset();

  }
  add() {
    this.editData = {};
    this.isEdit = false;
  }

}
