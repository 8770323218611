import { stringify } from '@angular/compiler/src/util';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { from } from 'rxjs';
import { EditUserRegistrationComponent } from 'src/app/pages/edit-user-registration/edit-user-registration.component';
import { CommonService } from 'src/app/services/common.service';
import { BaseComponent } from './../base.component'
@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.css']
})
export class SkillsComponent extends BaseComponent implements OnInit {
  @Input() parent: EditUserRegistrationComponent;
  @Input() editData: any = {};
  changed: boolean = false;
  table: Array<any> = [];
  myForm: FormGroup;
  isEdit: boolean = false;
  index: number;
  roleId: number;
  skillData: Array<any> = [];
  labelName: any = {};
  keys: object = {
    TYPE: 'type', SKILLID: 'SkillID', SKILLNAME: 'SkillName', VERSION: 'Version', LASTUSED: 'LastUsed', EXPERIANCE: 'Experience', MONTHS: 'Months'
  }
  headingName: any = {};
  constructor(private fb: FormBuilder, private CommonService: CommonService) {

    super()
    this.getLabels();
    this.loadHeadings();
    this.roleId = +localStorage.getItem('RoleId');
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      SkillName: ['', Validators.required],
      Version: ['', Validators.required],
      LastUsed: ['', Validators.required],
      Experience: ['', Validators.required],
      Months: ['', Validators.required],
    });
    this.parent.childs['SkillsList'] = this
  }
  ngOnChanges() {
    if (Object.keys(this.editData).length && !this.changed) {
      this.changed = true;
      this.skillDataFromat(this.editData['Skills'].slice(0));
      this.dataTransform(this.editData['Skills'].slice(0));
    }
  }

  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${localStorage.getItem('regWorkItem')}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;


    })
  }


  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${localStorage.getItem('regWorkItem')}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  skillDataFromat(skills: Array<any>) {

    skills.map(item => {
      let data: any = {}
      Object.keys(this.keys).map(k => {
        let key = this.keys[k];
        data[key] = item[k];
      })
      this.skillData.push(data)
    })
  }
  dataTransform(data) {
    this.table = data;
  }
  add() {
    this.isEdit = false;
  }
  edit(data, i) {
    this.index = i;
    this.isEdit = true;
    this.editData = data;
    this.assignDataForm(i);
  }
  assignDataForm(i) {
    let data = this.skillData[i];
    let ctrls = this.myForm.controls;
    Object.keys(ctrls).map((formControlName: string) => {
      let control: AbstractControl = ctrls[formControlName];
      control.setValue(data[formControlName]);
    })
  }
  close() {
    this.myForm.reset()
  }
  onSubmit(form: FormGroup) {
    let value = form.value;
    if (this.isEdit) {
      if (this.editData.TYPE == 'edit') {
        value.type = 'update'
      } else {
        value.type = 'insert'
      }
      let data = Object.assign({}, value);
      data['SkillID'] = this.editData['SKILLID']
      this.skillData[this.index] = data;
      this.changeTableFormat(data, false)
    } else {
      value.type = 'insert'
      let data = Object.assign({}, value);
      this.skillData.push(data);
      this.changeTableFormat(data, true)
    }
    document.getElementById('md_close_skill').click();
  }

  changeTableFormat(skill: object, type) {
    let data = {}
    Object.keys(this.keys).map(k => {
      let key = this.keys[k];
      data[k] = skill[key];
    })
    if (type) {
      this.table.push(data);

      this.parent.toastr.success('Added successfully')
    } else {
      this.table[this.index] = data;
      this.parent.toastr.success('Updated successfully')
    }

  }
  check(item) {
    if (item.TYPE == 'delete') {
      return true
    }
    else {
      return false
    }
  }
}
