<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.Routes}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right mb-2">
                                            <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal"
                                                data-bs-target="#myModal">Add Route</button>
                                        </div>
                                        <div *ngIf="table.length">
                                            <table datatable id="DataTables_Table_0"
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #0d9bc4; color:#ffffff;">
                                                    <tr>
                                                        <th>Route Name</th>
                                                        <th> No.Stops</th>
                                                        <th>Total Students</th>
                                                        <th>Status</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.route_name}}</td>
                                                        <td>{{item.number_stops}}</td>
                                                        <td>{{item.total_student}}</td>
                                                        <td>{{item.status == true ? 'Active' : 'Inactive'}}</td>
                                                        <td style="display: flex;cursor: pointer;">
                                                            <!-- <select class="form-control form-control-sm">
                                                                <option data-bs-target="#myModal" data-bs-toggle="modal"  >
                                                                    Edit</option>                                                                
                                                                <option (click)="getAddstops()"
                                                                data-bs-toggle="modal" data-bs-target="#addStop">Add Stops
                                                                </option>
                                                                <option>Inactive
                                                                </option>


                                                            </select> -->
                                                            <span data-bs-toggle="modal" data-bs-target="#myModal"
                                                                src="" class="edit_icon" (click)="edit(item.route_id)">
                                                                <i class="fas fa-pen pt-1 mt-1"></i></span>

                                                            <button type="button" (click)="getAddstops(item.route_id)"
                                                                data-bs-toggle="modal" data-bs-target="#addStop"
                                                                class="btn btn-save btn-sm " style="margin-left:25px">
                                                                Add
                                                                Stops</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            <p>No records to display</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




<div class="modal" id="myModal" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog ">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header py-2">
                <h6 *ngIf="!isEdit" class="modal-title w-100">{{headingName.AddRoute}} </h6>
                <h6 *ngIf="isEdit" class="modal-title w-100">{{headingName.EditRoute}}</h6>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label ">{{labelName?.RouteName}}<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <input type="text" placeholder="" class="form-control" formControlName="route_name"onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                   >
                                </div>
                                
                                <div *ngIf="myForm.get('route_name').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('route_name').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>

                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label mt-2">
                                {{labelName?.Status}} </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                        formControlName='status'>
                                        <option value="" selected>Select</option>
                                        <option value=true>Active</option>
                                        <option value=false>Inactive</option>
                                    </select>
                                </div>

                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" (click)="onSubmit(myForm)" class="btn btn-save btn-sm"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" (click)="onSubmit(myForm)" class="btn btn-save btn-sm"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-danger btn-sm" id='md_close' (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>




<div class="modal" id="addStop" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog  modal-xl">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header py-2">
                <h6 class="modal-title w-100">Add Stops </h6>

                <button type="button" (click)="close1()" class="close" data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="routeForm" (ngSubmit)="routeSubmit(routeForm)">
                    <fieldset>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label ">{{labelName?.RouteName}}<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <select formControlName="route_id" disabled
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                        <option [value]="" selected>Select</option>
                                        <option *ngFor='let item of routeName' [value]="item.route_id">{{item.route_name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="routeForm.controls['list']">
                            <div formArrayName='list'>
                                <div class="form-group row pt-4" *ngFor="let item of routeForm.controls['list'].controls;let i=index"
                                [formGroupName]="i">
                                    <label class="col-md-2 control-label ">{{labelName?.StopName}}<span
                                            class="text-danger">*</span></label>
                                    <div class="col-md-2 inputGroupContainer">
                                        <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input type="text" placeholder="Enter a Stop Name" class="form-control"
                                                formControlName="stop_name" onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">
                                        </div>
                                    </div>
                                    <div class="col-md-2 inputGroupContainer">
                                        <div class="input-group"> &nbsp;
                                            <input type="time" placeholder="Pickup Time"  type="text" onfocus="(this.type='time')" class="form-control textbox-n"
                                                formControlName="pickup_time">
                                        </div>
                                    </div>
                                    <div class="col-md-2 inputGroupContainer">
                                        <div class="input-group">  &nbsp;
                                            <input type="time" placeholder="Drop Time" type="text" onfocus="(this.type='time')" class="form-control textbox-n"
                                                formControlName="drop_time">
                                        </div>
                                    </div>
                                    <div class="col-md-2 inputGroupContainer">
                                        <div class="input-group"> &nbsp;
                                            <input type="number" placeholder="Latitude" class="form-control"
                                                formControlName="latitude">
                                        </div>
                                    </div>
                                    <div class="col-md-2 inputGroupContainer">
                                        <div class="input-group">  &nbsp;
                                            <input type="number" placeholder="Longitude" class="form-control"
                                                formControlName="longitude">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </fieldset>
                </form>
                <div class="row mt-4" >
                    <div class="col-md-12 ">
                        <div class="float-end">
                            <button class="btn btn-di float-end" *ngIf="isVisable()" (click)="delete()"><i
                                    class="fas fa-trash"></i></button>
                            <button class="btn btn-ai float-end" *ngIf="isAdd()" (click)="add()">Add
                                Stops</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">

                <button type="button" [disabled]="routeForm.invalid" (click)="routeSubmit(routeForm)" class="btn btn-save btn-sm">Update</button>
                <button type="button" class="btn btn-danger btn-sm" id='close' (click)="close1()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>