import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-trip-timings',
  templateUrl: './trip-timings.component.html',
  styleUrls: ['./trip-timings.component.css']
})
export class TripTimingsComponent implements OnInit {
  myForm: FormGroup;
  editData: any;
  disable: boolean = true;
  labelName: any = {};
  headingName: any = {};
  workItemId: any;

  constructor(private fb: FormBuilder,private active : ActivatedRoute, private CommonService: CommonService, private toastr: ToastrService) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.getTripTimings();
    this.getLabels();
    this.loadHeadings();

  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      pick_time: ['', Validators.required],
      drop_time: ['', Validators.required],

    });
  }
  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  getTripTimings() {
    this.CommonService.postCall('Transport/GetTripTimings', {}).subscribe((res: any) => {
      this.editData = res;
      setTimeout(() => {
        this.dataTransForm(res)
      }, 100);
    })

  }

  dataTransForm(res: any) {

    this.myForm.controls['pick_time'].setValue(res['pick_time']);
    this.myForm.controls['drop_time'].setValue(res['drop_time']);
  }

  onSubmit(form: any) {
    let value = form.value
    let payload = {
      "pick_time": parseInt(value.pick_time),
      "drop_time": parseInt(value.drop_time),
      "created_by": localStorage.getItem('UserId'),
      "modified_by": localStorage.getItem('UserId'),
      "tnt_code": localStorage.getItem('TenantCode'),
    }
    if (this.editData == null) {
      payload['tips_id'] = 0;
      this.CommonService.postCall('Transport/UpdateTripTimings', payload).subscribe((res: any) => {
        this.toastr.success("Trip Timings Saved Successfully");
        // location.reload();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Trip Timings Not Saved')
      })
    } else {
      payload['tips_id'] = this.editData.tips_id;
      this.CommonService.postCall('Transport/UpdateTripTimings', payload).subscribe((res: any) => {
        this.toastr.success("Trip Timings Updated Successfully");
        // location.reload();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Trip Timings Not Updated')
      })
    }
  }

  close() {
    location.reload();
  }

  setTwoNumberDecimal(event: any) {

    let val = event.target.value;
    if (val > 24) {
      this.disable = false;
      this.toastr.warning('please enter upto 24 only')
    } else {
      this.disable = true;
    }

  }

}
