import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-event-registration',
  templateUrl: './event-registration.component.html',
  styleUrls: ['./event-registration.component.css']
})
export class EventRegistrationComponent implements OnInit {
  myForm!: FormGroup;
  internalFields: boolean = false;
  externalFields: boolean = false;
  isEdit: boolean = false;
  events: Array<any> = [];
  eventId: any;
  editData: any;
  regFee: any;
  labelName: any = {};
  headingName: any = {};
  workItemId: any;
  constructor(private CommonService: CommonService,private active: ActivatedRoute, private toastr: ToastrService, private fb: FormBuilder, private route: Router) {
  
    // this.active.queryParams
    this.active.queryParams.subscribe((res) => {
      if (res.erId) {
        this.eventId = res.erId;
        this.workItemId =res.wId;        
        // this.edit()
      }
      this.workItemId =res.wId;
    })
    this.getLabels();
    this.loadHeadings();
    this.eventDropdown();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      event_id: ['', Validators.required],
      // registration_no:['',Validators.required],
      type: ['', Validators.required],
      usid: [''],
      name: ['',],
      parent_name: ['',],
      address: ['',],
      mobile: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      reference: [''],

    })
    if (this.eventId) {
      this.isEdit = true;
      this.edit()
    }
  }
  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  eventDropdown() {
    let payload = { "tnt_code": localStorage.getItem('TenantCode') }
    this.CommonService.postCall('EmsEventsCategories/EventDropDown', payload).subscribe((response: any) => {
      this.events = response;
    })
  }

  getFees(eventId: any) {
    this.regFee = this.events.find((a: any) => a.event_id == eventId).registration_fee;
  }

  RadioButtons(event: any) {
    let checked = event.target.value;
    if (checked == "internal") {
      this.internalFields = true;
      this.externalFields = false;
    } else {
      this.internalFields = false;
      this.externalFields = true;
    }

  }

  onSubmit(form: any) {
    let value = form.value;
    if (value.type == 'internal') {
      if (value.usid == '') {
        this.toastr.warning('Please enter all mandatory fields')
      } else {
        console.log(value);
        value.created_by = localStorage.getItem('UserId');
        value.modified_by = localStorage.getItem('UserId');
        value.tnt_code = localStorage.getItem('TenantCode');
        value.fee = this.regFee;
        if (this.isEdit) {
          value.event_registration_id = this.eventId;
          this.CommonService.postCall('EmsEventsCategories/UpdateEventRegistration', value).subscribe((response: any) => {
            this.toastr.success('Registration Done Successfull');
            this.route.navigate(['/home/eventRegistrationsList']);
          }, (error: any) => {
            this.toastr.warning(error.error ? error.error : 'Registration Unsuccessfull');
          })
        } else {
          this.CommonService.postCall('EmsEventsCategories/CreateEventsRegistration', value).subscribe((response: any) => {
            this.toastr.success('Registration Updated Successfull');
            this.route.navigate(['/home/eventRegistrationsList']);
          }, (error: any) => {
            this.toastr.warning(error.error ? error.error : 'Registration Unsuccessfull');
          })
        }
      }
    } else if (value.type == 'external') {
      if (value.name == '' || value.parent_name == '' || value.reference == '' || value.address == '') {
        this.toastr.warning('Please enter all mandatory fields')
      } else {
        console.log(value);
        value.created_by = localStorage.getItem('UserId');
        value.modified_by = localStorage.getItem('UserId');
        value.tnt_code = localStorage.getItem('TenantCode');
        value.fee = this.regFee;
        if (this.isEdit) {
          value.event_registration_id = this.eventId;
          this.CommonService.postCall('EmsEventsCategories/UpdateEventRegistration', value).subscribe((response: any) => {
            this.toastr.success('Registration Done Successfull');
            this.route.navigate(['/home/eventRegistrationsList']);
          }, (error: any) => {
            this.toastr.warning(error.error ? error.error : 'Registration Unsuccessfull');
          })
        } else {
          this.CommonService.postCall('EmsEventsCategories/CreateEventsRegistration', value).subscribe((response: any) => {
            this.toastr.success('Registration Updated Successfull');
            this.route.navigate(['/home/eventRegistrationsList']);
          }, (error: any) => {
            this.toastr.warning(error.error ? error.error : 'Registration Unsuccessfull');
          })
        }
      }
    }
  }


  edit() {
    this.isEdit = true;
    this.myForm.reset();
    let payLoad = {
      "event_registration_id": this.eventId
    }
    this.CommonService.postCall('EmsEventsCategories/RegistrationGetById', payLoad).subscribe((res: any) => {
      if (this.isEdit == true) {
        if (res['type'] == "internal") {
          this.internalFields = true; this.externalFields = false;
        } else { this.internalFields = false; this.externalFields = true; }
      }
      if (res instanceof Array && res.length) {
        this.editData = res[0];
        this.dataTransForm()
      } else {
        this.editData = res;
        this.dataTransForm()
      }
      this.dataTransForm();
      this.getFees(this.editData['event_id']);
    }), err => { }
  }

  dataTransForm() {
    let ctrls = this.myForm.controls
    Object.keys(ctrls).map((key) => {
      let ctrl: AbstractControl = ctrls[key];
      ctrl.setValue(this.editData[key])
    });
  }


  close() {
    this.route.navigate([ `/home/eventRegistrationsList/${this.workItemId}`]);
    this.isEdit = null;
    this.myForm.reset();
    this.editData = {};
  }

}
