import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { FileuploadService } from 'src/app/services/fileupload.service';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-circular-alert',
  templateUrl: './circular-alert.component.html',
  styleUrls: ['./circular-alert.component.css']
})
export class CircularAlertComponent extends BaseComponent implements OnInit {
  myForm: FormGroup;
  isEdit: boolean = null;
  isDisable: boolean = false;
  table: Array<any> = [];
  htmlContent: any = '';
  htmlContentWithOutHtml = '';
  roles: Array<any> = [];
  tId: string = '';
  subscription: any = {}
  TenantCode: string = localStorage.getItem('TenantCode');
  sms: boolean = false;
  eMail: boolean = false;
  notification: boolean = false;
  dropdownSettings: any = {};
  editData: any;
  selectedCategory!: { USERID: number; FIRSTNAME: string; }[];
  dropdownListCategory: { USERID: number; FIRSTNAME: string; }[] = [];
  file: File;
  fileName: any;
  documentType = "circularAlert";
  labelName: any = {};
  headingName: any = {};
  workItemId: any;
  stId: any;

  showHtml() {
    this.htmlContentWithOutHtml = document.getElementById('htmldiv')?.innerHTML;
  }

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold']
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };
  constructor(private active : ActivatedRoute,private fb: FormBuilder,  CommonService: CommonService,  toastr: ToastrService, private FileuploadService: FileuploadService) {
    super(CommonService, toastr);
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadlabels();
    this.getRoles(this.TenantCode);
    this.loadHeadings();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({

      message_type: ['', Validators.required],
      notification_type: ['', Validators.required],
      notification_subject: ['', Validators.required],
      message: ['', Validators.required],
      circular_upload: ['', Validators.required],
      notification_priority: ['', Validators.required],
      designation_id: ['', Validators.required],
      template_type: ['', Validators.required],
      staff: ['', Validators.required],
      start_date:[''],
      end_date:['']
    });

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'USERID',
      textField: 'FIRSTNAME',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

  }

  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }
  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  onItemSelect(e) { }
  onSelectAll(e) { }


  // categoryDropdown() {
  //   this.CommonService.postCall('EmsRoomTypesDetails/ItemsCategoryDropDownList', {}).subscribe((res: any) => {
  //     this.Category = res;
  //   })
  // }
  // categoryChange(){
  //   this.CommonService.getCall(`EmsRoomTypesDetails/RoomItemsDropDownList/1/`).subscribe((res: any) => {
  //     this.Category = res;
  //   }) 

  // }
  designationChange(id) {
    this.activeSpinner();
    let payLoad = {
      "TenantCode": localStorage.getItem('TenantCode'),
      "objUserinrole": { "RoleId": id || 0 }
    }
    this.CommonService.postCall('UserRolesChange', payLoad).subscribe(
      (res) => {
        this.dropdownListCategory = res;
        this.deactivateSpinner();
        // res.map(item => {
        //   let obj = {
        //     item_id: item.item_id,
        //     item_name: item.item_name
        //   }
        //   this.dropdownListCategory.push(obj)
        // })
        // console.log(this.dropdownListCategory);


        e => {
          this.deactivateSpinner()
        }
      })
  }


  // designationChange(id) {
  //   this.activeSpinner();
  //   let payLoad = {
  //     "TenantCode": localStorage.getItem('TenantCode'),
  //     "objUserinrole": { "RoleId": id || 0 }
  //   }
  //   this.CommonService.postCall('UserRolesChange', payLoad).subscribe(
  //     (res) => {
  //       this.table = [];
  //       setTimeout(() => { this.table = res }, 10)
  //       this.deactivateSpinner();

  //     },
  //     err => {
  //       this.deactivateSpinner()
  //     })
  // }




  getRoles(tId) {
    this.stId=tId
    this.activeSpinner();
    let payLoad = {
      TENANT_CODE:tId || this.TenantCode,
      "RoleName": "Admin",
    }
    this.CommonService.postCall('GetRolesByTenant', payLoad).subscribe(
      (res: any) => {
        this.roles = res.TenantBasdRoles;
        this.subscription = res.Subscription && res.Subscription[0]
        this.deactivateSpinner();
      }, err => {
        this.deactivateSpinner();
      }
    )
  }

  typeChange(val) {
    if (val == 'SMS') {
      this.sms = true;
      this.eMail = false;
      this.notification = false;
    } else if (val == 'Email') {
      this.eMail = true;
      this.sms = false;
      this.notification = false;
    } else if (val == 'Notification') {
      this.notification = true;
      this.sms = false;
      this.eMail = false;
    }
  }


  onSubmit(form) {
    let value = form.value;
    let user = [];
    let users = [];
    this.selectedCategory.forEach(e => {
      let obj = {
        "notification_to": e.USERID
      }
      user.push(obj);
    });
    this.selectedCategory.forEach(e => {
      let obj = {
        "people_id": e.USERID
      }
      users.push(obj);
    });
    console.log(user, users);
    console.log(this.selectedCategory);
    let PayloadNotification = {
      "message_type": value.message_type,
      "notification_type": value.notification_type,
      "notification_from": localStorage.getItem('UserId'),
      "user": user,
      "notification_code": '',
      "notification_subject": value.notification_subject,
      "notification_body": this.htmlContent,
      "notification_file": value.circular_upload,
      "notification_send_status": "",
      "notification_priority": value.notification_priority,
      "notification_read_status": 0,
      "notification_status": "",
      "designation_id": value.designation_id,
      "start_date":value.start_date,
      "end_date":value.end_date,
      "created_by": localStorage.getItem('UserId'),
      "modified_by": localStorage.getItem('UserId'),
      "tnt_code": this.stId || localStorage.getItem('TenantCode'),
      "attachment": this.fileName
    }
    let payLoadSms = {
      "message_type": value.message_type,
      "designation_id": value.designation_id,
      "email_type": value.template_type,
      "users": users,
      "message": value.message || this.htmlContent,
      "circular_upload": value.circular_upload,
      "created_by": localStorage.getItem('UserId'),
      "modified_by": localStorage.getItem('UserId'),
      "tnt_code": localStorage.getItem('TenantCode'),
      "attachment": this.fileName
    }

    // let payLoadEmail = {
    //   "message_type": value.message_type,
    //   "email_type" :  value.template_type,
    //   "email_from" : localStorage.getItem('UserId'),
    //   "email_to" :  value.email_to,
    //   "email_cc" :  value.email_cc,
    //   "email_subject" : value.message_type,
    //   "email_body" :   this.htmlContent,
    //   "email_code" :  value.email_code,
    //   "email_status" :  value.email_status,
    //   "sent_count" :  value.sent_count,
    //   "read_status" :  value.read_status,
    //   "notification_date_time" :  value.notification_date_time,
    //   "created_by": localStorage.getItem('UserId'),
    //   "modified_by": localStorage.getItem('UserId'),
    //   "tnt_code": localStorage.getItem('TenantCode'),
    // }
    console.log(payLoadSms);
    console.log(PayloadNotification);
    if (value.message_type == "SMS") {
      this.CommonService.postCall('EmsAlerts/StaffCircularAlert', payLoadSms).subscribe((res: any) => {
        this.toastr.success("Message alert has been sent");
        setTimeout(() => {
          window.location.reload();
        }, 200);
      }, err => {
        this.toastr.error(err.error ? err.error : 'Message alert has not been sent')
      })
    } else if (value.message_type == "Notification") {
      this.CommonService.postCall('EmsAlerts/StaffCircularAlert', PayloadNotification).subscribe((res: any) => {
        this.toastr.success("Notification has been sent");

        setTimeout(() => {
          window.location.reload();
        }, 200);
      }, err => {
        this.toastr.error(err.error ? err.error : 'Notification has not been sent')
      })
    } else if (value.message_type == "Email") {
      this.CommonService.postCall('EmsAlerts/StaffCircularAlert', payLoadSms).subscribe((res: any) => {
        this.toastr.success("Email has been sent");


        setTimeout(() => {
          window.location.reload();
        }, 200);

      }, err => { this.toastr.error(err.message ? err.message : err) })
    }
  }

  cancle() {
    window.history.back();
  }


  changeFile(event) {
    if (event.target.files.length > 0) {
      let file: File = event.target.files[0];
      let name = file.name;
      let filetype = name.split('.').pop()
      let types: Array<any> = ['doc', 'docx', 'pdf', 'jpg', 'gif', 'png', 'xlsx', 'xlr', 'ppt', 'pptx', 'jpeg']
      let check = types.includes(filetype);
      if (check) {
        this.file = file;
        this.upload();
      }
      else {
        // alert(' Please upload pdf and doc file formats only.')
        this.toastr.warning('Please upload pfc,doc and jpeg file formats only.')
        event.target.value = ''
      }
    }
  }

  upload() {
    let data =
    {
      "document_title": this.documentType,
      "document_type": this.documentType,
      "document_type_id": 44,
      "verified_by": localStorage.getItem('UserId'),
      "created_by": localStorage.getItem('UserId'),
      "modified_by": localStorage.getItem('UserId'),
      "tnt_code": localStorage.getItem('TenantCode')
    }



    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('attachment', this.file);
    this.FileuploadService.upload(formData, 'EmsCampus/UploadImage').subscribe((res: any) => {

      try {
        this.fileName = res.path;
        if (this.fileName) {
          this.myForm.controls['QuestionImage'].setValue(this.fileName)
        }
      } catch (e) {
        console.log(e)
      }

    }, err => { this.toastr.warning('Upload Failed') })
  }


}
