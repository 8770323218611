<div class="page-wrapper pt-4">
  <div class="page-content pt-0 pe-2 ps-2">
    <div class="container-fluid">
      <div class="default_class">
        <div class="content-wrapper p-0">
          <div class="container-fluid">
            <div class="row">
              <div class="col-xl-12">
                <div class="card rounded shadow-lg">
                  <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                    <h5 class="mb-0">{{headingName.ReturnItems}}</h5>
                  </div>
                  <div class="card-body">
                    <div class="custom_container">
                      <div class="row">
                        <div class="col-xl-8 offset-xl-2">
                          <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                            <fieldset>
                              <label class="col-md-4 control-label">
                                {{labelName?.ReturnItems}} <span class="text-danger">*</span></label>
                              <div *ngIf="myForm.controls['formArray']">
                                <div formArrayName='formArray'>
                                  <div class="form-group row pt-4"
                                    *ngFor="let item of myForm.controls['formArray'].controls;let i=index"
                                    [formGroupName]="i">
                                    <div class="col-md-8 inputGroupContainer">
                                      <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="defaultCheck_{{i}}"
                                          formControlName="isChecked" (change)="isChange($event,item)">
                                        {{item.value.name}}
                                      </div>
                                      <div class="form-group row pt-4" *ngIf="item.value.isChecked">
                                        <label class="col-md-4 control-label mt-1 ">
                                          {{labelName?.ReturnDate}}<span class="text-danger">*</span></label>
                                        <div class="col-md-8 inputGroupContainer">
                                          <div class="input-group"> <strong>:</strong> &nbsp;
                                            <input class="form-control form-control-sm" formControlName="return_date"
                                              type="date">
                                          </div>
                                        </div>
                                      </div>
                                      <div class="form-group row pt-4" *ngIf="item.value.isChecked">
                                        <label class="col-md-4 control-label mt-1 ">
                                          {{labelName?.Conditional}}<span class="text-danger">*</span></label>
                                        <div class="col-md-8 inputGroupContainer">
                                          <div class="radio">
                                            <div class="form-check-inline">
                                              <label class="form-check-label">
                                                <input value='0' type="radio" class="form-check-input"
                                                  name="condition_on_return"
                                                  formControlName="condition_on_return">Proper
                                              </label>
                                            </div>
                                            <div class="form-check-inline" (change)="isChanging($event,i)">
                                              <label class="form-check-label">
                                                <input value='1' type="radio" class="form-check-input"
                                                  name="condition_on_return" formControlName="condition_on_return">Un
                                                Conditional
                                              </label>
                                            </div>
                                            <div class="form-group row pt-4"
                                              *ngIf="item.get('condition_on_return').value=='1'">
                                              <label class="col-md-4 control-label mt-1 ">
                                                {{labelName?.Description}}<span class="text-danger">*</span></label>
                                              <div class="col-md-8 inputGroupContainer">
                                                <div class="input-group"> <strong>:</strong> &nbsp;
                                                  <input class="form-control form-control-sm"
                                                    formControlName="hostel_items_description" type="text">
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </fieldset>
                          </form>
                          <div class="text-center pt-4">
                            <button type="button" (click)="onSubmit(myForm)" class="btn btn-sm  btn-save"
                              [disabled]="myForm.invalid">Update</button> &nbsp;
                            <button type="button" class="btn btn-sm btn-danger" class="btn btn-danger  btn-sm"
                              id='md_close' (click)="close()">close</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>