<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">

        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.SGPAReport}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="form-group ">
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <label for="sel1">{{labelName?.Course}}: </label>
                                                    <select [(ngModel)]='courseId'
                                                        class="form-select form-select-sm shadow-sm rounded-lg"
                                                        id="sel1">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let course of courses"
                                                            [value]='course.COURSE_ID'>
                                                            {{course.COURSE_NAME}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-md-3">
                                                    <label for="sel1">{{labelName?.year}}: </label>
                                                    <select [(ngModel)]='yearId'
                                                        class="form-select form-select-sm shadow-sm rounded-lg"
                                                        id="sel1">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let item of courceYears"
                                                            [value]='item.DICTIONARYID'>{{item.DICTIONARYNAME}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-md-3">
                                                    <label for="sel1">{{labelName?.Semester}}: </label>
                                                    <select [(ngModel)]='semId'
                                                        class="form-select form-select-sm shadow-sm rounded-lg"
                                                        id="sel1">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let item of  semesters"
                                                            [value]='item.DICTIONARYID'>{{item.DICTIONARYNAME}}
                                                        </option>

                                                    </select>
                                                </div>

                                                <div class="col-md-2">
                                                    <label> </label><br>
                                                    <button class="btn btn-sm  btn-save"
                                                        [disabled]='!courseId||!semId||!yearId'
                                                        (click)='submit()'>submit </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="table.length">
                                            <table id="patreggraph" datatable [dtOptions]="dtOptions"
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>

                                                        <th>Subject</th>
                                                        <th>Credit</th>
                                                        <th>Grade</th>
                                                        <th>Grade Point</th>
                                                        <th>Score</th>


                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table;let i=index">
                                                        <!-- <td>{{i+1}}</td> -->
                                                        <td>{{item.SUBJECT_NAME}}</td>
                                                        <td>{{item.CREDITS}}</td>
                                                        <td>{{item.GRADE}}</td>
                                                        <td>{{item.GRADE_POINT}}</td>
                                                        <td>{{item.POINTS}}</td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>