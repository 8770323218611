import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-add-dynamic-labels',
  templateUrl: './add-dynamic-labels.component.html',
  styleUrls: ['./add-dynamic-labels.component.css']
})
export class AddDynamicLabelsComponent implements OnInit {
  table: Array<any> = [];
  modules: Array<any> = [];
  workItems: Array<any> = [];
  myForm: FormGroup;
  isDisable: boolean = false;
  labelName: any = {};
  headingName: any = {};
  constructor(private fb: FormBuilder, active: ActivatedRoute, private CommonService: CommonService, private toastr: ToastrService, private route: Router) {
    this.loadModules();
    this.loadlabels();
    this.loadHeadings();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      madule: ['', Validators.required],
      work_item: ['', Validators.required],
      Labels: this.fb.array([]),
    })
    this.add();
  }

  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner();
  }
  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/4343/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/4343/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  getOptionGroup(data) {
    let newGroup = this.fb.group({
      label_key: [data.label_key ? data.label_key : '', [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      label_value: [data.label_value ? data.label_value : '', [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
    });
    return newGroup
  }

  isAdd() {
    let arrayControl = <FormArray>this.myForm.controls['Labels'];
    if (arrayControl.length && arrayControl.length > 25) {
      return false
    }
    else {
      return true
    }
  }

  add(data = {}) {
    const arrayControl = <FormArray>this.myForm.controls['Labels'];
    let grp: FormGroup = this.getOptionGroup(data)
    arrayControl.push(grp)
  }

  delete() {
    const arrayControl = <FormArray>this.myForm.controls['Labels'];
    let index = arrayControl.length;
    arrayControl.removeAt(index - 1)
  }

  isVisable() {
    let arrayControl = <FormArray>this.myForm.controls['Labels'];
    if (arrayControl.length && arrayControl.length > 0) {
      return true
    }
    else {
      return false
    }
  }

  loadModules() {
    this.modules = [];
    this.activeSpinner();
    this.CommonService.postCall('TaskManager/LoadModules', {}).subscribe((res: any) => {
      this.modules = res;
      this.deactivateSpinner();
    }, e => {
      this.deactivateSpinner();
    })

  }


  modulesChange(event) {
    this.workItems = [];
    this.isDisable = true;
    this.activeSpinner();
    this.CommonService.getCall(`TaskManager/LoadWorkItemsByModule/${event.target.value}`).subscribe((res: any) => {
      this.deactivateSpinner();
      this.workItems = res;
    }, e => {
      this.deactivateSpinner();
    });
  }

  onSubmit(form: any) {
    this.activeSpinner()
    let value = form.value;
    let payload = {
      "module_id": value.madule,
      "workitem_id": value.work_item,
      "created_by": localStorage.getItem('UserId'),
      "modified_by": localStorage.getItem('UserId'),
      "tnt_code": localStorage.getItem('TenantCode'),
      "ems_labels": form.controls['Labels'].value
    }
    this.CommonService.postCall('TenantRegistration/InsertLables', payload).subscribe((res: any) => {
      this.deactivateSpinner();
      this.toastr.success("Label added Successfully");
      this.myForm.reset();
    }, err => {
      this.toastr.error(err.error ? err.error : 'Labels Not Updated')
      this.deactivateSpinner();
    })
    this.deactivateSpinner();
  }

  close() {
    this.myForm.reset();
    this.route.navigate(['home/dashboard']);
  }
}
