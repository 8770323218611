import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-staff-attendance',
  templateUrl: './staff-attendance.component.html',
  styleUrls: ['./staff-attendance.component.css']
})
export class StaffAttendanceComponent implements OnInit {
  table: Array<any> = [];
  headingName: any = {};
  labelName: any = {};
  workItemId: any;

  constructor(private fb: FormBuilder, private active : ActivatedRoute,private CommonService: CommonService, private toastr: ToastrService, private route: Router) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadHeadings();
    this.getLabels();
  }

  ngOnInit(): void {
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  getStaffList(event) {
    let payload = {
      "tnt_code": localStorage.getItem('TenantCode'),
      "date": `${event.target.value}-01`
    }
    this.CommonService.postCall('TimeTable/GetStaffAttendanceList', payload).subscribe((res: any) => {
      this.table = res;
    })
  }


  add() {
    this.route.navigate([`home/addStaffAttendance/${this.workItemId}`]);
  }

}
