import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-class-fee',
  templateUrl: './class-fee.component.html',
  styleUrls: ['./class-fee.component.css']
})
export class ClassFeeComponent implements OnInit {
  myForm: FormGroup;
  categories:Array<any>=[];
  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      Academicyear:[''],
      Course:[''],
      Courseschedule:[''],
      feetype:[''],
      Amount:['']
    })
  }
  loadClass(){
    
  }

onSubmit(form:FormGroup){

}
edit(){
  
}
close(){
  
}
}
