<br>

<div class="page-wrapper">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.DropOffStudentsList}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <form class="well form-horizontal" [formGroup]="myForm"
                                            (ngSubmit)="onSubmit(myForm)">
                                            <fieldset>
                                                <div class="row">

                                                    <!-- <div class="form-group col-md-3">

                                                        <div class=" inputGroupContainer">
                                                            <div class="input-group">
                                                                <input placeholder="Type Key Word To Search"
                                                                    class="form-control form-control-sm shadow-sm rounded-lg"
                                                                    type="text" formControlName="searchKeyWord">
                                                            </div>
                                                        </div>
                                                    </div> -->



                                                    <!-- <div class="form-group col-md-3">
                                                        <div class="input-group">
                                                            <select
                                                                class="form-control form-control-sm shadow-sm rounded-lg"
                                                                formControlName="campus_id">
                                                                <option value="" selected>Select Campus</option>
                                                                <option *ngFor="let item of campusDropdown"
                                                                    [value]="item.campus_id">
                                                                    {{item.campus_name}}</option>
                                                            </select>

                                                        </div>
                                                    </div> -->


                                                    <div class="form-group col-md-3">
                                                        <label>{{labelName.Course}} </label>
                                                        <div class="input-group">
                                                            <select  [(ngModel)]='courceId '
                                                                (change)="courceChange($any($event).target.value)"
                                                                class="form-select form-select-sm shadow-sm rounded-lg" formControlName="class_id">
                                                                <option value="" selected>Select</option>
                                                                <option *ngFor="let course of cources"
                                                                    [value]='course.COURSE_ID'>
                                                                    {{course.COURSE_NAME}}
                                                                </option>
                                                            </select>
                                                        </div>

                                                    </div>

                                                    <div class="form-group col-md-3">
                                                        <label>{{labelName.Section}}</label>

                                                        <div class="input-group">
                                                            <select formControlName="section_id"
                                                                class="form-select form-select-sm shadow-sm rounded-lg">
                                                                <option value="" selected>Select</option>
                                                                <option *ngFor="let schedul of scheduls"
                                                                    [value]='schedul.COURSESHD_ID'>
                                                                    {{schedul.COURSESHD_NAME}}</option>
                                                            </select>
                                                        </div>

                                                    </div>
                                                    <!-- <div class="form-group col-md-3 pt-4">

                                                        <div class="input-group">
                                                            <select formControlName="student"
                                                                placeholder="Select Attendance Type"
                                                                class="form-control form-control-sm shadow-sm rounded-lg">
                                                               
                                                                <option value="1" [selected]="true">Student
                                                                </option>
                                                            </select>
                                                        </div>

                                                    </div> -->

                                                    <!-- <div class="form-group col-md-3 pt-4">

                                                        <div class="input-group">
                                                            <select formControlName="contains"
                                                                placeholder="Select Attendance Type"
                                                                class="form-control form-control-sm shadow-sm rounded-lg">
                                                                <option value="" selected> Select Contains
                                                                </option>
                                                                <option value='1'>Start With</option>
                                                                <option value='2'>End With</option>
                                                            </select>
                                                        </div>

                                                    </div> -->

                                                    <!-- <div class="form-group col-md-3 pt-4">

                                                        <div class="input-group">
                                                            <select formControlName="transport"
                                                                placeholder="Select Attendance Type"
                                                                class="form-control form-control-sm shadow-sm rounded-lg">
                                                                <option value="" selected>Select Transport
                                                                </option>
                                                                <option value='Active'>Transport</option>
                                                                <option value='Inactive'>Own Transport</option>
                                                            </select>
                                                        </div>

                                                    </div> -->

                                                    <!-- <div class="form-group col-md-3 pt-4">

                                                        <div class=" inputGroupContainer">
                                                            <div class="input-group">
                                                                <input placeholder="Search Date"
                                                                    class="form-control form-control-sm shadow-sm rounded-lg"
                                                                    type="date" formControlName="date">
                                                            </div>
                                                        </div>
                                                    </div> -->
                                                    <div class="col-md-2 pt-4">
                                                        <button type="button" [disabled]="myForm.invalid"
                                                            (click)="onSubmit(myForm)" class="btn btn-sm  btn-save"><i
                                                                class="fas fa-search"></i></button>
                                                    </div>

                                                </div>
                                            </fieldset>
                                        </form>&nbsp;
                                         <div *ngIf="table.length">
                                            <table id="patergraph" datatable
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th>Admission No.</th>
                                                        <!-- <th>Campus Name</th> -->
                                                        <th>Course Name</th>
                                                        <th> Section</th>
                                                        <th>Full Name</th>                                                         -->
                                                        <th>Status</th>
                                                        <!-- <th> Date</th> -->
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.admission_no }}</td>
                                                        <!-- <td>{{item.campus_name}}</td> -->
                                                        <td>{{item.class_name }}</td>
                                                        <td>{{item.section }}</td>
                                                        <td>{{item.full_name }}</td>
                                                        <td>{{item.status == true ? 'Active' : 'Inactive' }}</td>
                                                        <!-- <td>{{item.date | date:'dd/MM/yyyy'}}</td> -->

                                                    </tr>
                                                </tbody>

                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>