<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">

                    <div class="container-fluid">

                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.StudentDailyDiaryList7}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="form-group row pt-4">

                                            <table class="table table-striped table-bordered table-responsive">
                                                <tbody class="top">
                                                    <tr class="text-center">
                                                        <th>Date</th>
                                                        <th colspan="5">{{dateView | date : 'dd-MM-yyyy'}}</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Subject</th>
                                                        <th>Class work</th>
                                                        <th>Home work</th>
                                                        <th>Class Activity</th>
                                                        <th>Home work submission date</th>
                                                    </tr>
                                                    <tr *ngFor="let item of subjectList">
                                                        <td>{{item.subject_name}}</td>
                                                        <td>{{item.class_work_description}}
                                                            <br>
                                                            <button class=" btn btn-warning btn-sm"><a href="javascript: void(0)" value="Newsletter" (click)="ViewFile2(item.class_work_attachment)">
                                                                Attachment
                                                            </a></button>
                                                        </td>
                                                        <td>{{item.home_work_description}}
                                                            <br>
                                                            <button class=" btn btn-warning btn-sm"><a href="javascript: void(0)" value="Newsletter" (click)="ViewFile2(item.home_work_attachment)">
                                                                Attachment
                                                            </a></button>
                                                        </td>
                                                        <td>{{item.class_activity_description}}
                                                            <br>
                                                            <button class=" btn btn-warning btn-sm"><a href="javascript: void(0)" value="Newsletter" (click)="ViewFile2(item.class_activity_attachment)">
                                                                Attachment
                                                            </a></button>
                                                        </td>
                                                        <td>{{item.home_work_submission_date | date:'dd-MM-yyyy'}}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Announcement's</th>
                                                        <td colspan="5">{{Announcement}}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Newsletter Attachment</th>
                                                        <td colspan="5">
                                                            <button class=" btn btn-warning btn-sm"><a href="javascript: void(0)" value="Newsletter" (click)="ViewFile($event)">
                                                                Attachment
                                                            </a></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>Monthly Event Attachment</th>
                                                        <td colspan="5">
                                                            <button class=" btn btn-warning btn-sm"><a href="javascript: void(0)" value="Newsletter" (click)="ViewFile1($event)">
                                                                Attachment
                                                            </a></button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                          
                                                <div class="text-end pt-4">
                                                    
                                                    &nbsp; <button class="btn btn-danger btn-sm"
                                                        (click)='close()'>Cancel</button>
    
                                                </div>
    
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>