<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row"> 
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.StudentReports}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div *ngIf='isSuperAdmin' class="col-md-3 pb-4">
                                                <label for="sel1">{{labelName.TenantName}} : </label>
                                                <select [(ngModel)]='tId ' (change)="getCourses()"
                                                    class="form-control form-control-sm shadow-sm rounded-lg" id="sel1">
                                                    <option value="" [selected]="true">select</option>
                                                    <option *ngFor="let item of tenanates" [value]='item.TNT_CODE'>
                                                        {{item.TNT_NAME}}</option>
                                                </select>

                                            </div>
                                        </div>
                                        <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)" >
                                            <fieldset>
                                                <div class="row">
                                                    <div class="form-group col-md-3 pb-4">
                                                       
                                                            <label for="sel1">{{labelName?.SelectCourse}}<span
                                                                class="text-danger">*</span></label>
                                                            <select formControlName="class_id"
                                                                class="form-select form-select-sm shadow-sm rounded-lg">
                                                                <option value='' selected>Select</option>
                                                                <option *ngFor="let course of cources"
                                                                        [value]='course.COURSE_ID'>
                                                                        {{course.COURSE_NAME}}
                                                                    </option>
                                                            </select>
                                                        </div>
                                                  

                                                 
                                                </div>
                                                <div class="col-md-3">
                                                    <button type="button"  [disabled]="myForm.invalid"
                                                        (click)="onSubmit(myForm)" class="btn btn-sm  btn-save"><i
                                                            class="fas fa-search"></i></button>
                                                </div>
                                            </fieldset>
                                        </form>
                                        <br>
                                        <div *ngIf="!isTable" class="text-center">
                                            <p style="color: red;">
                                                Search for Students Reports</p>
                                        </div>
                                        <div>
                                            <table id="addStudent" datatable *ngIf="table.length"
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <!-- <th> Campus Name </th> -->
                                                        <th> Student Name </th>
                                                        <th> USID </th>
                                                        <th> Father Name </th>
                                                        <th> Mobile Number </th>
                                                        <th> Enrolled </th>
                                                        <th> Admission </th>
                                                        <th> Class </th>
                                                        <!-- <th> Section </th> -->
                                                        <th> Status </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <!-- <td></td> -->
                                                        <td>{{item.studentname}}</td>
                                                        <td>{{item.userid}}</td>
                                                        <td>{{item.parentname}}</td>
                                                        <td>{{item.mobileno}}</td>
                                                        <td>{{item.enroll}}</td>
                                                        <td>{{item.rollnumber}}</td>
                                                        <td>{{item.course_name}}</td>
                                                        <!-- <td></td> -->
                                                        <td>{{item.status == true ? "Active" : "Inactive" }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
