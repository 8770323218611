<!-- <div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 text-center">Student Fee Recievable </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <label for="sel1">Academic Year <span
                                                            class="text-danger">*</span></label>
                                                    <select class="form-control form-control-sm shadow-sm rounded-lg"
                                                        [disabled]="isParam" (change)="courceChange($event)"
                                                        [(ngModel)]='academicId'>
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let academic of academics"
                                                            [value]='academic.ACADEMIC_ID'>
                                                            {{academic.ACADEMIC_NAME}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-md-3">
                                                    <label for="sel1">Curriculum Name<span
                                                            class="text-danger">*</span></label>
                                                    <select class="form-control form-control-sm shadow-sm rounded-lg"
                                                        [disabled]="isParam" (change)="Change($event)"
                                                        [(ngModel)]='curriculumId'>
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let Curriculum of Curriculums"
                                                            [value]='Curriculum.CURRICULUM_ID'>
                                                            {{Curriculum.CURRICULUM_NAME}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-md-3">
                                                    <label for="sel1">User Name <span
                                                            class="text-danger">*</span></label>
                                                    <select class="form-control form-control-sm shadow-sm rounded-lg"
                                                        [disabled]="isParam" (change)="load($event)">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let user of users" [value]='user.USERID'>
                                                            {{user.USERNAME}}
                                                        </option>
                                                    </select>
                                                </div>                                               
                                            </div>
                                        </div>
                                        <div *ngIf="table.length">
                                            <table id="patreggraph" datatable
                                                class="table table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top bg-info">
                                                    <tr>
                                                        <th> UserName</th>
                                                        <th>Fee Type</th>
                                                        <th>Term Name</th>
                                                        <th>Actual Amount</th>
                                                        <th>Term Amount</th>
                                                        <th>Paid Amount</th>
                                                        <th>Paid Date</th>


                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">

                                                        <td>{{item.FEERECEIVABLE_STUDENT_ID}}</td>
                                                        <td>{{item.FEETYPE_NAME}}</td>
                                                        <td> {{item.TERMNAME}}</td>
                                                        <td>{{item.FEERECEIVABLE_ACTUALAMOUNT}}</td>
                                                        <td>{{item.FEERECEIVABLE_TERM_AMOUNT}}</td>
                                                        <td>{{item.FEERECEIVABLE_PAIDAMOUNT}}</td>
                                                        <td>{{item.FEERECEIVABLE_PAIDDATE}}</td>

                                                </tbody>
                                            </table>

                                        </div>
                                        <div class="text-center" *ngIf="!table.length">No Records to display</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->


<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.StudentFeeRecievables}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="form-group">
                                            <div class="row">
                                                <div *ngIf='isSuperAdmin' class="col-md-3">
                                                    <label for="sel1">{{labelName.TenantName}} : </label>
                                                    <select [(ngModel)]='tId ' (change)="loadCourse()"
                                                        class="form-control form-control-sm shadow-sm rounded-lg"
                                                        id="sel1">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let item of tenanates" [value]='item.TNT_CODE'>
                                                            {{item.TNT_NAME}}</option>
                                                    </select>
    
                                                </div>
                                                <div class="col-md-3">
                                                    <label for="sel1">{{labelName?.CourseName}}<span
                                                            class="text-danger">*</span></label>
                                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                        [disabled]="isParam" (change)="courceChange($event)"
                                                        [(ngModel)]='academicId'>
                                                        <option value="" [selected]="true">Select</option>
                                                        <option *ngFor="let course of courses"
                                                            [value]='course.COURSE_ID'>
                                                            {{course.COURSE_NAME}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-md-3">
                                                    <label for="sel1">{{labelName?.CourseSchedule}}<span
                                                            class="text-danger">*</span></label>
                                                    <select name="courseSchedule" id="courseSchedule"
                                                        (change)="sheduleChange($event)"
                                                        formControlName="courseSchedule"
                                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                                        <option value="" [selected]="true">Select</option>
                                                        <option *ngFor="let courseSchedule of Schedules"
                                                            [value]="courseSchedule.COURSESHD_ID">
                                                            {{courseSchedule.COURSESHD_NAME}}</option>
                                                    </select>
                                                </div>
                                                <div class="col-md-3">
                                                    <label for="sel1">{{labelName?.UserName}} <span
                                                            class="text-danger">*</span></label>
                                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                        [disabled]="isParam" (change)="load($event)">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let user of users" [value]='user.USERID'>
                                                            {{user.FIRSTNAME}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <!-- <div class="col-md-3">
                                        <label for="sel1">Number Of Terms <span class="text-danger">*</span></label>
                                        <select class="form-control form-control-sm shadow-sm rounded-lg"
                                        [disabled]="isParam"  formControlName="NUMBER_OF_TERMS">
                                            <option value="" [selected]="true">select</option>
                                            <option value="">1</option>
                                            <option value="">2</option>
                                            <option value="">3</option>
                                       
                                        </select>
                                    </div> -->
                                            </div>
                                        </div>
                                        <br>
                                        <div *ngIf="table.length" class="pt-4">
                                            <table id="patreggraph" datatable 
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small display"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <!-- <th> User Name</th> -->
                                                        <th>Fee Type</th>
                                                        <th>Terms</th>
                                                        <!-- <th>Course</th> -->
                                                        <th>Amount</th>
                                                        <!-- <th>Total Fee Amount</th> -->
                                                        <th>Concession</th>
                                                        <th>Due Amount</th>
                                                        <th>Paid Amount</th>
                                                        <!-- <th>Pay Amount</th>
                                                        <th>Action</th> -->

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table;let i=index">

                                                        <!-- <td>{{item.USERNAME}}</td> -->
                                                        <td>
                                                            <span
                                                                *ngIf="item.FEERECEIVABLE_TERM_ID==1">{{item.FEETYPE_NAME}}</span>
                                                        </td>
                                                        <td>TERM {{item.FEERECEIVABLE_TERM_ID}}</td>
                                                        <!-- <td>{{item.COURSE_NAME}}</td> -->
                                                        <td> {{item.FEERECEIVABLE_TERM_AMOUNT}}</td>
                                                        <!-- <td>{{item.FEERECEIVABLE_ACTUALAMOUNT}}</td> -->
                                                        <td>{{item.fee_concession_term_amount}}</td>
                                                        <th style="color: red;">{{item.Due_amount < 0 ? 0 :
                                                                item.Due_amount}}</th>
                                                        <th style="color: green;">{{item.FEERECEIVABLE_PAIDAMOUNT}}</th>
                                                        <!-- <td>
                                                            <label *ngIf="item.checked">
                                                                <input type="number" placeholder=""
                                                                    (input)="ValidatePayAmount($event,item.Due_amount)"
                                                                    [(ngModel)]="item.TNT_CODE" #amount="ngModel"
                                                                    class="form-control">
                                                            </label>
                                                        </td>


                                                        <td>
                                                            <div class="form-check"
                                                                *ngIf="!(item.Due_amount == 0||item.Due_amount < 0)">
                                                                <input class="form-check-input" type="checkbox"
                                                                    [(ngModel)]="item.checked" value=""
                                                                    id="flexCheckDefault">
                                                                <label class="form-check-label" for="flexCheckDefault">
                                                                    Pay
                                                                </label>
                                                            </div>
                                                        </td> -->

                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center pt-4" *ngIf="!table.length">No Records to display</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>