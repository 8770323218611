<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.ScheduleFeedback}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <form action="" class="well form-horizontal" [formGroup]="myForm"
                                            (ngSubmit)="onSubmit(myForm)">
                                            <fieldset>
                                                <div class="form ">
                                                    <!-- <input type="checkbox" value="1" (change)="ScheduleChange($event)"> Faculty Feedback Form -->
                                                    <label for="faculty">{{labelName.FacultyFeedbackForm}}</label>
                                                </div>
                                                <div class="form-group row pt-4">
                                                    <label class="col-md-2 control-label">{{labelName.StartDate}}<span
                                                            class="text-danger">*</span> </label>
                                                    <div class="col-md-4 inputGroupContainer">
                                                        <div class="input-group">
                                                            <!-- <input type="date" 
                                                           
                                                                formControlName="faculty_feedback_start_date"> -->
                                                                <input type="date"  min="{{minDate}}" [ngModel]="facultyFeedbackStartDate| date:'yyyy-MM-dd'"
                                                               
                                                                    formControlName="faculty_feedback_start_date">
                                                        </div>
                                                    </div>
                                                    <label class="col-md-2 control-label">{{labelName.EndDate}}<span
                                                            class="text-danger">*</span> </label>
                                                    <div class="col-md-4 inputGroupContainer">
                                                        <div class="input-group">
                                                            <input type="date"  min="{{minDate}}" [ngModel]="facultyFeedbackEndDate | date:'yyyy-MM-dd'"
                                                            
                                                                formControlName="faculty_feedback_end_date">
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="form pt-4">
                                                    <!-- <input type="checkbox" value="2" (change)="ScheduleChange($event)"> College Feedback Form -->
                                                    <label for="College">{{labelName.CollegeFeedbackForm}}</label>

                                                </div>
                                                <div class="form-group row pt-4">
                                                    <label class="col-md-2 control-label">{{labelName.StartDate}}<span
                                                            class="text-danger">*</span> </label>
                                                    <div class="col-md-4 inputGroupContainer">
                                                        <div class="input-group">
                                                            <input type="date"  min="{{minDate}}"
                                                             [ngModel]="facilityFeedbackStartDate | date:'yyyy-MM-dd'"
                                                                formControlName="facility_feedback_startdate">
                                                        </div>
                                                    </div>
                                                    <label class="col-md-2 control-label pb-4">{{labelName.EndDate}}<span
                                                            class="text-danger">*</span> </label>
                                                    <div class="col-md-4 inputGroupContainer">
                                                        <div class="input-group">
                                                            <input type="date"  min="{{minDate}}" [ngModel]="facilityFeedbackEndDate | date:'yyyy-MM-dd'"
                                                                formControlName="facility_feedback_enddate">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="modal-footer justify-content-center">
                                                    <button class="btn btn-save btn-sm" type="button"
                                                        (click)="onSubmit(myForm)" [disabled]="myForm.invalid">Submit</button>
                                                </div>
                                            </fieldset>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>