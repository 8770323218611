<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.Room}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right mb-2">
                                            <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal"
                                                data-bs-target="#myModal">Add Room</button>
                                        </div>
                                        <div *ngIf="table.length" class="pt-2">
                                            <table id="rooms" datatable 
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #0d9bc4; color:#ffffff;">
                                                    <tr >
                                                        <th>Campus Name </th>
                                                        <th>Block Name </th>
                                                        <th>Floor Name</th>
                                                        <th>Room Type</th>
                                                        <th>Room Name</th>
                                                        <th>Room Capacity</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.campus_name}}</td>
                                                        <td>{{item.block_name}}</td>
                                                        <td>{{item.floor_name}}</td>
                                                        <td>{{item.room_type_name}}</td>
                                                        <td>{{item.room_number}}</td>
                                                        <td>{{item.room_capacity}}</td>
                                                        <td  style="display: flex; cursor: pointer;"> 
                                                            <span data-bs-toggle="modal" data-bs-target="#myModal"
                                                            src=""
                                                            class="edit_icon" (click)="edit(item.room_id)"> <i class="fas fa-pen pt-1 mt-1"></i></span >
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div *ngIf="!table.length">
                                                No Records To Display
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog  modal-lg">
        <div class="modal-content">

            <div class="modal-header py-2">
                <h6 *ngIf="!isEdit" class="modal-title w-100"> {{headingName.AddRoom}}</h6>
                <h6 *ngIf="isEdit" class="modal-title w-100"> {{headingName.EditRoom}}</h6>

                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>


            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>

                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName.Campus}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName="campus_id" (change)="campusChange($any($event).target.value)"
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" [selected]="true">Select</option>
                                        <option value="" *ngFor="let item of campus"[value]='item.campus_id'>
                                            {{item.campus_name}}
                                        </option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('campus_id').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('campus_id').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName.Block}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName="block_id" (change)="blockChange($any($event).target.value)"
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option value="" *ngFor="let item of block" [value]='item.block_id'>
                                            {{item.block_name}}
                                        </option>
                                   
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('block_id').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('block_id').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName.FloorName}}<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select name="student" id="student" formControlName="floor_id"
                                        class="form-select form-select-sm shadow-sm rounded-lg ">
                                        <option value="" [selected]="true">Select</option>
                                        <option value="" *ngFor="let item of floor" [value]="item.floor_id">
                                           {{item.floor_name}}
                                        </option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('floor_id').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('floor_id').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName.RoomArea}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <input type="text" class="form-control" formControlName="room_area"onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));" >sq.ft
                                </div>
                                <div *ngIf="myForm.get('room_area').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('room_area').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName.RoomNoName}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <input type="text" class="form-control" formControlName="room_number"onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));" >
                                </div>
                                <div *ngIf="myForm.get('room_number').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('room_number').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName.RoomType}}<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select name="student" id="student" formControlName="room_type_name"
                                        class="form-select form-select-sm shadow-sm rounded-lg ">
                                        <option value="" [selected]="true">Select</option>
                                        <option value="" *ngFor="let item of roomtype" [value]="item.room_type_id">
                                           {{item.name}}
                                        </option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('room_type_name').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('room_type_name').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>


                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label ">{{labelName.RoomCapacity}}<span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <input type="number" placeholder="Room Capacity" class="form-control"
                                        formControlName="room_capacity">
                                       
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-save btn-sm" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                    <button type="button" *ngIf="isEdit" class="btn btn-save btn-sm" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-danger  btn-sm" id='md_close' (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>