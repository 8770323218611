import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { resolveCname } from 'dns';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-courcesessions',
  templateUrl: './courcesessions.component.html',
  styleUrls: ['./courcesessions.component.css']
})
export class CourcesessionsComponent extends BaseComponent implements OnInit {
  courses: [] = [];
  courseId: string = ''
  scheduleId: any = '';
  schedules: [] = []
  table: any = [];
  isActive: boolean = false;
  params: any = {};
  chapters: Array<any> = [];
  trainers: Array<any> = [];
  planData: any = {};
  eventMyForm: FormGroup;
  startTime: string = null;
  Date: any;
  eventData: any;
  labelName: any = {};
  chapterId: any;
  sub: Array<any> = [];
  myFormSession!: FormGroup
  headingName: any = {};
  workItemId: any;
  editdatalist: any;
  fromDate: any;
  toDate: any;
  isSearch: boolean = false;
  dtOptions: { dom: string; buttons: { extend: string; filename: string; }[]; order: any[]; };
  constructor(private router: Router, CommonService: CommonService, toastr: ToastrService, private active: ActivatedRoute, private fb: FormBuilder) {
    super(CommonService, toastr);
    active.queryParams.subscribe(
      (res) => {
        console.log(res);
        this.workItemId = res.wId;
        if (Object.keys(res).length) {
          this.workItemId = res.wId;
          this.courseId = res.cId;
          this.scheduleId = res.csId;
          this.params = res;
          this.courseChange();
          this.loadSessions();
          this.isActive = true;
          this.loadTrainersAndChapters();

        }
      })
    this.getCourses();
    this.getLabels();
    this.loadHeadings();
    this.loadReportDtOptions();
    // this.chapterChange1();
    // this.subChaptersDropdown();

  }

  ngOnInit(): void {
    this.createForm();
    this.createEventForm();
    // this.dtOptions = {
    //   order: []
    // }
    this.myFormSession = this.fb.group({

      CS_DATE: [''],
      start_time: ['', Validators.required],
      end_time: ['', Validators.required],
      CS_CHAPTER_ID: ['', Validators.required],
      CS_TRAINER_ID: ['', Validators.required],
      sub_chapter_id: ['', Validators.required],
      CS_ONLINE_OFFLINE: [''],
      // CS_SCHEDULE_DELAY: [''],
      COURSESHD_SESSION_NAME: ['']
    })
  }
  loadReportDtOptions() {
    this.dtOptions = {
      dom: 'Bfrtip',
      buttons: [
        {
          extend: "excel",
          filename: 'Lesson Plan',
        }
      ],
      order: []
    }
  }
  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  createForm() {
    this.myForm = this.fb.group(
      {
        courseSh: [''],
        CSS_TITLE: ['', Validators.required],
        CSS_DESCRIPTION: ['', Validators.required],
        CSS_ACTIVITIES: [''],
        CSS_REFERENCE: ['']
      }
    )
  }
  createEventForm() {
    this.eventMyForm = this.formBuilder.group({
      COURSE_ID: ['', Validators.required],
      COURSESCHEDULE_ID: ['', Validators.required],
      APPOINTMENT_NAME: ['', Validators.required],
      APPOINTMENT_DATE: ['', Validators.required],
      APPOINTMENT_STARTTIME: ['', Validators.required],
      APPOINTMENT_ENDTIME: [{ value: '', }, [Validators.required]],
      APPOINTMENT_DESCRIPTION: ['', Validators.required]
    })
  }

  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  getCourses() {
    this.activeSpinner()
    this.CommonService.getAdminCourses().subscribe((res: any) => {
      this.deactivateSpinner();
      this.courses = res
    }, e => { this.deactivateSpinner(); })
  }

  courseChange() {
    this.activeSpinner();
    let data = {
      "CourseId": this.courseId
    }
    this.CommonService.getAdminCourseSchedule(data).subscribe((res: any) => {
      this.deactivateSpinner();
      if (res.length) {
        this.schedules = res;
      } else {
        this.toastr.warning(res.message)
      }
    }, e => { this.deactivateSpinner(); })
  }
  schedulChange() {
    this.activeSpinner();
    this.CommonService.getCourseScheduleSession(this.scheduleId).subscribe((res: any) => {
      this.deactivateSpinner();
      this.table = [];
      setTimeout(() => {
        this.table = res;
        this.table.map((item) => {
          item.CS_SCHEDULE_DELAY = item.CS_SCHEDULE_DELAY || 2;
        })
      }, 10)

    }, err => {
      this.deactivateSpinner();
    })
  }
  onSearch() {
    this.activeSpinner();
    this.isSearch = true;
    this.table = [];
    let payLoad = {
      CS_COURSESHD_ID: this.scheduleId,
      sessions_start_date: this.fromDate,
      sessions_end_date: this.toDate
    }
    this.CommonService.postCall('CourseSchedule/LoadCourseScheduleSessionfilter', payLoad).subscribe((res: any) => {
      this.deactivateSpinner();
      setTimeout(() => {
        this.table = res;
      }, 10)
      this.renderDataTable()
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner() })

  }
  onClear() {
    this.fromDate = null;
    this.toDate = null;
    this.schedulChange();
    this.isSearch = false;
  }
  submit() {
    let array: Array<any> = [];
    this.table.map((item: any) => {
      let data = {
        CsId: item.CS_ID,
        SessionUpdate: item.CS_SCHEDULE_DELAY,
        ChapterId: item.CS_CHAPTER_ID,
        OffLineOnline: item.CS_ONLINE_OFFLINE,
        TrainerId: item.CS_TRAINER_ID,
        sub_chapter_id: item.sub_chapter_id,
        TenantCode: localStorage.getItem('TenantCode')
      }
      array.push(data);
    })
    this.CommonService.setCourseScheduleSessions({ data: array }).subscribe((res: any) => {
      this.toastr.success("Successfully Updated");
      setTimeout(() => { location.reload() }, 200)
      window.history.back()
    }, (e: any) => {

    })
  }

  back() {
    this.router.navigate([`home/courseSchedule/${this.workItemId}`]);
  }


  loadSessions() {
    let payLoad = {
      COURSESHD_ID: this.scheduleId,
      STARTDATE: this.params.sDate,
      ENDDATE: this.params.eDate,
      USERID: localStorage.getItem('UserId'),
      TENANT_CODE: localStorage.getItem('TenantCode'),
    }
    this.activeSpinner();
    this.CommonService.postCall('LoadCourseScheduleSession', payLoad).subscribe(
      (res) => {
        this.table = res;
        this.table.forEach(e => {
          this.chapterChange(e.CHAPTER_ID)
        });
        this.deactivateSpinner();
      },
      err => {
        console.log(err);
        this.deactivateSpinner();
      }
    )

  }

  loadTrainersAndChapters() {
    let cPayload = {
      CHAPTER_CS_ID: this.scheduleId
    };
    let tPayload = {
      COURSE_TRAINER_COURSESHD_ID: this.scheduleId
    }

    let chapters = this.CommonService.postCall('LoadChaptersByCourseSchedule', cPayload);
    let trainers = this.CommonService.postCall('LoadAssignTrainers', tPayload);

    forkJoin([chapters, trainers]).subscribe(res => {
      this.chapters = res[0];
      this.chapterId = res[0].CHAPTER_ID;
      this.trainers = res[1];
    }, err => {
      console.log(err)
    })
  }

  chapterChange(chapId: any) {
    this.sub = [];
    let payload = {
      chapter_id: chapId
    }
    this.CommonService.postCall('EmsSubChapters/DropDown', payload).subscribe((res: any) => {
      this.sub = res;
    })
  }

  // chapterChange1() {
  //   this.sub = [];
  //   let payload = {
  //     chapter_id: this.chapterId
  //   }
  //   this.CommonService.postCall('EmsSubChapters/DropDown', payload).subscribe((res: any) => {
  //     this.sub = res;
  //   })
  // }


  viewPlan(data) {
    this.planData = data;
  }

  close() {
    this.planData = {};
    this.myForm.reset();
    this.isEdit = false;
  }
  onSubmit(form: FormGroup) {
    let payLoad = form.getRawValue();
    payLoad['CSS_COURSE_SCHEDULE_ID'] = this.planData['CS_COURSESHD_ID'] || this.scheduleId;
    payLoad['CSS_CS_SESSION_ID'] = this.planData['CS_ID'];
    payLoad['CSS_TENANTCODE'] = this.TenantCode;
    payLoad['CSS_CREATED_BY'] = this.userId;
    payLoad['CSS_STATUS'] = true;
    this.activeSpinner();
    this.CommonService.postCall('SaveLessionPlan', payLoad).subscribe(
      (res: any) => {
        this.deactivateSpinner();
        this.toastr.success('Information Saved Successfully');
        document.getElementById('md_close').click();
      }, err => {
        this.deactivateSpinner();
      }
    )

  }
  riseEvent(data) {
    this.eventData = data;
  }
  eventclose() {
    this.eventMyForm.reset();
  }
  timeChange(endTime) {
    let controls = this.eventMyForm.controls;
    let stime: any = controls['APPOINTMENT_STARTTIME'].value;
    let econtrol = controls['APPOINTMENT_ENDTIME'];

    if (!stime) {
      this.toastr.warning('Please selece start time')
      econtrol.setValue(null)
      return
    }
    var start = moment.utc(stime, "HH:mm");
    var end = moment.utc(endTime, "HH:mm");

    var d = moment.duration(end.diff(start));
    if (d['_milliseconds'] > 0) {

    } else {
      this.toastr.warning("End Time should be more than start time")
      econtrol.setValue(null)
    }

  }
  eventOnSubmit(form: FormGroup) {
    let payload = form.value;
    payload.TENANT_CODE = this.TenantCode;
    this.activeSpinner();
    if (this.isEdit) {
      payload.LASTMDFBY = this.userId;
      payload.APPOINTMENT_ID = this.editData.APPOINTMENT_ID
      this.CommonService.postCall('UpdateEvent', payload).subscribe((res) => {
        this.deactivateSpinner();
        this.toastr.success(" Event Updated Successfully");
        document.getElementById('md_close1').click()
        // this.loadGrid();
      }, err => { this.deactivateSpinner(); this.toastr.error(err.error ? err.error : "Event not updated ") })
    } else {
      payload.CREATEDBY = this.userId;
      // ContentType=100
      this.CommonService.postCall('CreateEvent', payload).subscribe((res) => {
        this.deactivateSpinner();
        this.toastr.success("Event Created Successfully");
        document.getElementById('md_close1').click()
        // this.loadGrid()
      }, err => { this.deactivateSpinner(); this.toastr.error(err.error ? err.error : "Event not created ") })
    }
  }

  onSubmitSession(form: FormGroup) {
    let value: any = form.value;
    let payload = {
      "CS_COURSESHD_ID": this.scheduleId,
      "CS_DATE": value.CS_DATE,
      "start_time": value.start_time,
      "end_time": value.end_time,
      "CS_CHAPTER_ID": value.CS_CHAPTER_ID,
      "CS_TRAINER_ID": value.CS_TRAINER_ID,
      "sub_chapter_id": value.sub_chapter_id,
      "CS_ONLINE_OFFLINE": value.CS_ONLINE_OFFLINE,
      // "CS_SCHEDULE_DELAY":value.CS_SCHEDULE_DELAY,
      "CS_CREATED_BY": localStorage.getItem('UserId'),
      "CS_MODIFIED_BY": localStorage.getItem('UserId'),
      "TNT_CODE": localStorage.getItem('TenantCode'),
      "COURSESHD_SESSION_NAME": value.COURSESHD_SESSION_NAME
    }

    if (this.isEdit) {
      let updatePayload = {
        CsId: this.editdatalist['CS_ID'],
        SessionUpdate: this.editdatalist.CS_SCHEDULE_DELAY,
        start_time: value.start_time,
        end_time: value.end_time,
        ChapterId: value.CS_CHAPTER_ID,
        OffLineOnline: value.CS_ONLINE_OFFLINE,
        TrainerId: value.CS_TRAINER_ID,
        sub_chapter_id: value.sub_chapter_id,
        TenantCode: localStorage.getItem('TenantCode')
      }
      this.CommonService.setCourseScheduleSessions({ data: [updatePayload] }).subscribe((res: any) => {
        this.toastr.success("Session Updated Successfully");
        document.getElementById('md_cancel').click();
        this.loadSessions();
        this.cancel();
        location.reload();
      })
    } else {
      this.CommonService.postCall('CourseSchedule/CreateSessions', payload).subscribe((res: any) => {
        this.toastr.success("Session Created Successfully");
        document.getElementById('md_cancel').click();
        this.loadSessions();
        this.cancel();
        location.reload();
      })
    }
  }

  add() {
    this.isEdit = false;
  }

  // edit(csId: any) {
  //   this.isEdit = true;
  //   let ctrls = this.myFormSession.controls;
  //   let payLoad = {
  //     "cs_id": csId
  //   }
  //   this.CommonService.postCall('CourseSchedule/GetSessionsById', payLoad).subscribe((res: any) => {
  //     this.editdatalist = res;
  //     setTimeout(() => {
  //       this.chapterChange(res.CS_CHAPTER_ID);
  //     }, 200);
  //     this.myFormSession.patchValue({
  //       CS_CHAPTER_ID: res.CS_CHAPTER_ID,
  //       CS_TRAINER_ID: res.CS_TRAINER_ID,
  //       sub_chapter_id: res.sub_chapter_id,
  //       CS_ONLINE_OFFLINE: res.CS_ONLINE_OFFLINE,
  //       COURSESHD_SESSION_NAME: res.COURSESHD_SESSION_NAME,
  //     })
  //     ctrls['CS_DATE'].setValue(moment(res['CS_DATE']).format('yyyy-MM-DD'))

  //   })
  // }

  edit(csId) {
    this.isEdit = true;
    this.myForm.reset();
    let payLoad = {
      "cs_id": csId
    }
    this.CommonService.postCall('CourseSchedule/GetSessionsById', payLoad).subscribe((res: any) => {
      this.editdatalist = res;
      setTimeout(() => {
        this.chapterChange(res.CS_CHAPTER_ID);
      }, 100);
      this.dataTransForm();
    }), err => { }

  }

  dataTransForm() {
    let ctrls = this.myFormSession.controls
    Object.keys(ctrls).map((key) => {
      let ctrl: AbstractControl = ctrls[key];
      ctrl.setValue(this.editdatalist[key])
    });
    ctrls['sub_chapter_id'].setValue(this.editdatalist['sub_chapter_id']);
    ctrls['CS_DATE'].setValue(moment(this.editdatalist['CS_DATE']).format('yyyy-MM-DD'));
  }

  cancel() {
    this.myFormSession.reset();
    this.isEdit = false;
  }

}

