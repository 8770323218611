import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { BaseComponent } from '../base.component';
@Component({
  selector: 'app-leaves',
  templateUrl: './leaves.component.html',
  styleUrls: ['./leaves.component.css']
})
export class LeavesComponent extends BaseComponent implements OnInit {
  myForm: FormGroup;
  table: Array<any> = [];
  headingName: any = {};
  workItemId: any;
  labelName: any = {};
  constructor(private active : ActivatedRoute,private commonService: CommonService,toastr:ToastrService) {
    super(commonService,toastr);
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadLeaves();
    this.loadHeadings();
    this.getLabels();
  }

  ngOnInit(): void {
  }
  loadHeadings() {
    this.headingName = {};
    this.commonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  getLabels() {
    this.labelName = {};
    this.commonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  } 
  loadLeaves() {
    let payload = {

    }
    this.commonService.getCall(`EmsReports/LeavesReport/${this.tId ||localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.table = res;
    })
  }
  onSubmit(myForm) {

  }
}
