<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">HOD Feedback</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right mb-1">
                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                                data-bs-target="#myModal">Add</button>
                                        </div>
                                        <div *ngIf="table.length">
                                            <table id="patreggraph" datatable
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th>
                                                            Course </th>
                                                        <th>
                                                            Semester
                                                        </th>
                                                        <th>Academic Year</th>



                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <!-- <td>{{item.SUBJECT_NAME}}</td>
                                                        <td>{{item.SUBJECT_DESCRIPTION}}</td>
                                                        <td>{{item.COURSE_NAME}}</td>
                                                        <td>{{item.CREDITS}}</td>
                                                        <td>{{item.SubjectStatus}}</td> -->


                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="myModal" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header py-2">
                <h6 class="modal-title w-100"> Add Feedback</h6>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>
            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row">
                            <label class="col-md-4 control-label">Course</label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select class="form-control form-control-sm shadow-sm rounded-lg"
                                        formControlName="">
                                        <option value="" [selected]="true">select</option>
                                        <option *ngFor="let item of courses" [value]="item.COURSE_ID">
                                            {{item.COURSE_NAME}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label">Academic Year</label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select formControlName="academic_year_id"
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option *ngFor="let academic of academicYearDropdown"
                                            [value]='academic.ACADEMIC_ID'>
                                            {{academic.ACADEMIC_NAME}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label">Semester</label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select formControlName=""
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option *ngFor="let semester of semesterDropdown"
                                            [value]='semester.DICTIONARYID'>
                                            {{semester.DICTIONARYNAME}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="row pt-4">
                            <div class="col-xl-4">
                                <div><span class="text-danger">*</span>Q1. Punctuality and discipline
                                </div>
                                <div style="margin-left: 30px;">
                                    <input type="radio"> &nbsp; <label for="">Excellent</label> <br>
                                    <input type="radio">&nbsp; <label for="">Very Good</label> <br>
                                    <input type="radio">&nbsp;<label for="">Good</label> <br>
                                    <input type="radio">&nbsp;<label for="">Poor</label> <br>
                                    <input type="radio">&nbsp; <label for="">Very poor</label>

                                </div>
                            </div>
                            <div class="col-xl-4">
                                <div><span class="text-danger">*</span>Q2. Domain Knowledge
                                </div>
                                <div style="margin-left: 30px;">
                                    <input type="radio"> &nbsp; <label for="">Excellent</label> <br>
                                    <input type="radio">&nbsp; <label for="">Very Good</label> <br>
                                    <input type="radio">&nbsp;<label for="">Good</label> <br>
                                    <input type="radio">&nbsp;<label for="">Poor</label> <br>
                                    <input type="radio">&nbsp; <label for="">Very poor</label>

                                </div>
                            </div>
                            <div class="col-xl-4">
                                <div><span class="text-danger">*</span>Q3. Presentation required skills and interaction
                                    with the students
                                </div>
                                <div style="margin-left: 30px;">
                                    <input type="radio"> &nbsp; <label for="">Excellent</label> <br>
                                    <input type="radio">&nbsp; <label for="">Very Good</label> <br>
                                    <input type="radio">&nbsp;<label for="">Good</label> <br>
                                    <input type="radio">&nbsp;<label for="">Poor</label> <br>
                                    <input type="radio">&nbsp; <label for="">Very poor</label>

                                </div>
                            </div>
                        </div>
                        <div class="row pt-4">
                            <div class="col-xl-4">
                                <div><span class="text-danger">*</span>Q4. Able to resolve difficulties
                                </div>
                                <div style="margin-left: 30px;">
                                    <input type="radio"> &nbsp; <label for="">Excellent</label> <br>
                                    <input type="radio">&nbsp; <label for="">Very Good</label> <br>
                                    <input type="radio">&nbsp;<label for="">Good</label> <br>
                                    <input type="radio">&nbsp;<label for="">Poor</label> <br>
                                    <input type="radio">&nbsp; <label for="">Very poor</label>

                                </div>
                            </div>

                            <div class="col-xl-4">
                                <div><span class="text-danger">*</span>Q5. Effective use of teaching aids
                                </div>
                                <div style="margin-left: 30px;">
                                    <input type="radio"> &nbsp; <label for="">Excellent</label> <br>
                                    <input type="radio">&nbsp; <label for="">Very Good</label> <br>
                                    <input type="radio">&nbsp;<label for="">Good</label> <br>
                                    <input type="radio">&nbsp;<label for="">Poor</label> <br>
                                    <input type="radio">&nbsp; <label for="">Very poor</label>

                                </div>
                            </div>
                            <div class="col-xl-4">
                                <div><span class="text-danger">*</span>Q6. Faculty member completes the syllabus in
                                    time?
                                </div>
                                <div style="margin-left: 30px;">
                                    <input type="radio"> &nbsp; <label for="">Excellent</label> <br>
                                    <input type="radio">&nbsp; <label for="">Very Good</label> <br>
                                    <input type="radio">&nbsp;<label for="">Good</label> <br>
                                    <input type="radio">&nbsp;<label for="">Poor</label> <br>
                                    <input type="radio">&nbsp; <label for="">Very poor</label>

                                </div>
                            </div>

                        </div>

                        <div class="row pt-4">
                            <div class="col-xl-4">
                                <div><span class="text-danger">*</span>Q7. Teacher offer assistance on counselling to
                                    needy students
                                </div>
                                <div style="margin-left: 30px;">
                                    <input type="radio"> &nbsp; <label for="">Excellent</label> <br>
                                    <input type="radio">&nbsp; <label for="">Very Good</label> <br>
                                    <input type="radio">&nbsp;<label for="">Good</label> <br>
                                    <input type="radio">&nbsp;<label for="">Poor</label> <br>
                                    <input type="radio">&nbsp; <label for="">Very poor</label>

                                </div>
                            </div>
                            <div class="col-xl-4">
                                <div><span class="text-danger">*</span>Q8. Teacher ensures learners activity and problem
                                    solving ability in class?
                                </div>
                                <div style="margin-left: 30px;">
                                    <input type="radio"> &nbsp; <label for="">Excellent</label> <br>
                                    <input type="radio">&nbsp; <label for="">Very Good</label> <br>
                                    <input type="radio">&nbsp;<label for="">Good</label> <br>
                                    <input type="radio">&nbsp;<label for="">Poor</label> <br>
                                    <input type="radio">&nbsp; <label for="">Very poor</label>

                                </div>
                            </div>
                            <div class="col-xl-4">
                                <div><span class="text-danger">*</span>Q9. Teacher is capable of keeping class under
                                    discipline and control
                                </div>
                                <div style="margin-left: 30px;">
                                    <input type="radio"> &nbsp; <label for="">Excellent</label> <br>
                                    <input type="radio">&nbsp; <label for="">Very Good</label> <br>
                                    <input type="radio">&nbsp;<label for="">Good</label> <br>
                                    <input type="radio">&nbsp;<label for="">Poor</label> <br>
                                    <input type="radio">&nbsp; <label for="">Very poor</label>

                                </div>
                            </div>
                        </div>


                        <div class="row pt-4">
                            <div class="col-xl-4">
                                <div><span class="text-danger">*</span>Q10. Knowledge of faculty beyond the syllabus
                                </div>
                                <div style="margin-left: 30px;">
                                    <input type="radio"> &nbsp; <label for="">Excellent</label> <br>
                                    <input type="radio">&nbsp; <label for="">Very Good</label> <br>
                                    <input type="radio">&nbsp;<label for="">Good</label> <br>
                                    <input type="radio">&nbsp;<label for="">Poor</label> <br>
                                    <input type="radio">&nbsp; <label for="">Very poor</label>

                                </div>
                            </div>
                            <div class="col-xl-4">
                                <div><span class="text-danger">*</span>Q11. Quality of self develop Videos by the
                                    Faculty
                                </div>
                                <div style="margin-left: 30px;">
                                    <input type="radio"> &nbsp; <label for="">Excellent</label> <br>
                                    <input type="radio">&nbsp; <label for="">Very Good</label> <br>
                                    <input type="radio">&nbsp;<label for="">Good</label> <br>
                                    <input type="radio">&nbsp;<label for="">Poor</label> <br>
                                    <input type="radio">&nbsp; <label for="">Very poor</label>

                                </div>
                            </div>

                            <div class="col-xl-4">
                                <div><span class="text-danger">*</span>Q12. Quality of online teaching, Theory and
                                    Tutorials during Pandemic
                                </div>
                                <div style="margin-left: 30px;">
                                    <input type="radio"> &nbsp; <label for="">Excellent</label> <br>
                                    <input type="radio">&nbsp; <label for="">Very Good</label> <br>
                                    <input type="radio">&nbsp;<label for="">Good</label> <br>
                                    <input type="radio">&nbsp;<label for="">Poor</label> <br>
                                    <input type="radio">&nbsp; <label for="">Very poor</label>

                                </div>
                            </div>
                        </div>


                        <div class="row pt-4">
                            <div class="col-xl-4">
                                <div><span class="text-danger">*</span>Q13. Quality of practical explain in online mode
                                    during Pandemic.
                                </div>
                                <div style="margin-left: 30px;">
                                    <input type="radio"> &nbsp; <label for="">Excellent</label> <br>
                                    <input type="radio">&nbsp; <label for="">Very Good</label> <br>
                                    <input type="radio">&nbsp;<label for="">Good</label> <br>
                                    <input type="radio">&nbsp;<label for="">Poor</label> <br>
                                    <input type="radio">&nbsp; <label for="">Very poor</label>

                                </div>
                            </div>
                            <div class="col-xl-4">
                                <div><span class="text-danger">*</span>Q14. Effectiveness of Mentoring provided
                                </div>
                                <div style="margin-left: 30px;">
                                    <input type="radio"> &nbsp; <label for="">Excellent</label> <br>
                                    <input type="radio">&nbsp; <label for="">Very Good</label> <br>
                                    <input type="radio">&nbsp;<label for="">Good</label> <br>
                                    <input type="radio">&nbsp;<label for="">Poor</label> <br>
                                    <input type="radio">&nbsp; <label for="">Very poor</label>

                                </div>
                            </div>
                            <div class="col-xl-4">
                                <div><span class="text-danger">*</span>Q15. Overall rating of the faculty
                                </div>
                                <div style="margin-left: 30px;">
                                    <input type="radio"> &nbsp; <label for="">Excellent</label> <br>
                                    <input type="radio">&nbsp; <label for="">Very Good</label> <br>
                                    <input type="radio">&nbsp;<label for="">Good</label> <br>
                                    <input type="radio">&nbsp;<label for="">Poor</label> <br>
                                    <input type="radio">&nbsp; <label for="">Very poor</label>

                                </div>
                            </div>
                        </div>

                    </fieldset>
                </form>
            </div>
            <div class="modal-footer">
                <button class="btn btn-save btn-sm" type="button">Save</button>
                <button class="btn btn-danger btn-sm" type="button" id='md_close' data-bs-dismiss="modal"
                    (click)="close()">Close</button>

            </div>
        </div>
    </div>
</div>