import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { FileuploadService } from 'src/app/services/fileupload.service';
@Component({
  selector: 'app-create-super-admin',
  templateUrl: './create-super-admin.component.html',
  styleUrls: ['./create-super-admin.component.css']
})
export class CreateSuperAdminComponent implements OnInit {
  myform: FormGroup;
  isEdit: boolean = false;
  tnt_code: string;
  editData: any = {}
  tenants: Array<any> = [];
  file: File;
  fileName: string;
  labelName: any = {};
  selectedOrganizationType: Array<any> = [];
  selectedBoards: Array<any> = [];
  OrganizationType = [{ id: '1', name: 'Primary', }, { id: '2', name: 'Secondary', }, { id: '3', name: 'Higher Secondary', }, { id: '4', name: 'Undergraduate', }, { id: '5', name: 'Postgraduate', },];
  Boards = [{ id: '1', name: 'SSC', }, { id: '2', name: 'CBSE', }, { id: '3', name: 'ICSE', }, { id: '4', name: 'IPE', }, { id: '5', name: 'University', },];
  headingName: any = {};
  constructor(private CommonService: CommonService, private fb: FormBuilder, private active: ActivatedRoute, private toastr: ToastrService, private FileuploadService: FileuploadService) {
    active.queryParams.subscribe((res) => {
      this.tnt_code = res.tntCode
    })
    this.getTenantData();
    this.loadlabels();
    this.loadHeadings();
  }

  ngOnInit(): void {
    this.myform = this.fb.group({
      TNT_NAME: ['', Validators.required],
      TNT_URL: ['', Validators.required],
      TNT_STATUS: ['', Validators.required],
      TNT_PARENT_CODE: [''],
      TNT_DESCRIPTION: ['', Validators.required],
      STARTDATE: ['', Validators.required],
      ENDDATE: ['', Validators.required],
      TNT_CONCURRENT_AVSESSIONS: ['', Validators.required],
      TNT_USERSPERSESSION: ['', Validators.required],
      TenantLogoUrl: [''],
      SHOWLOGO: [false],
      allow_proctoring: [false],
      allow_exampad: [false],
      REGISTRATION_NO: ['', Validators.required],
      REGISTRATION_DATE: ['', Validators.required],
      ORGANIZATION_TYPEID: ['', Validators.required],
      BOARDS_ID: ['', Validators.required],
      BRANCH_LIMIT: ['', Validators.required],
      // startdate : [''],
      // enddate: [''],   
    });

    if (this.tnt_code) {
      this.isEdit = true;
      this.getData();
    }
  }

  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner();
  }

  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/4290/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/4290/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  changeBoards(event: any) {
    let itemId = event.target.value;
    let obj = {
      item_id: itemId
    }
    if (event.target.checked) {
      this.selectedBoards.push(obj);
    } else {
      this.selectedBoards = this.selectedBoards.filter(m => m.item_id != itemId);
    }
  }

  changeOgtype(event: any) {
    let itemId = event.target.value;
    let obj = {
      item_id: itemId
    }
    if (event.target.checked) {
      this.selectedOrganizationType.push(obj);
    } else {
      this.selectedOrganizationType = this.selectedOrganizationType.filter(m => m.item_id != itemId);
    }
  }

  getData() {
    this.activeSpinner();
    this.CommonService.postCall('EditTenant', { TNT_CODE: this.tnt_code }).subscribe(
      (res: any) => {
        this.deactivateSpinner()
        if (res instanceof Array) {
          this.editData = res[0];
          this.dataTransfer(res[0])
        } else {
          this.editData = res;
          this.dataTransfer(res);
        }
      }
    )
  }
  dataTransfer(data) {
    let ctrls = this.myform.controls;
    Object.keys(ctrls).map(key => {
      let ctrl = ctrls[key];
      ctrl.setValue(data[key])
    })
    //   var amenities:Array<any> = this.editData['BOARDS_ID'].length>0?this.editData['BOARDS_ID'].split(','):[];
    //   var localdata ={
    //   "BOARDS_ID":amenities.includes("1")?1:0,
    //   "BOARDS_ID":amenities.includes("2")?2:0,
    //   "BOARDS_ID":amenities.includes("3")?3:0,
    //   "amenities4":amenities.includes("4")?4:0,
    //   "amenities5":amenities.includes("5")?5:0
    // };
    // ctrls['TNT_KEY'].setValue(moment(data['TNT_KEY']).format('yyyy-MM-DD'));
    ctrls['REGISTRATION_DATE'].setValue(moment(data['REGISTRATION_DATE']).format('yyyy-MM-DD'));
    ctrls['STARTDATE'].setValue(moment(data['STARTDATE']).format('yyyy-MM-DD'));
    ctrls['ENDDATE'].setValue(moment(data['ENDDATE']).format('yyyy-MM-DD'));
  }
  onSubmit(form: FormGroup) {
    let value = Object.assign({}, form.value);
    let selectedboards: any = [];
    let selectedorganizationtype: any = [];

    this.selectedBoards.forEach(m => {
      selectedboards.push(m.item_id);
    });
    this.selectedOrganizationType.forEach(m => {
      selectedorganizationtype.push(m.item_id);
    })
    value['TNT_CREATEDBY'] = localStorage.getItem('UserId');
    value['TNT_PARENT_CODE'] = localStorage.getItem('TenantCode');
    value['BOARDS_ID'] = selectedboards.toString();
    value['ORGANIZATION_TYPEID'] = selectedorganizationtype.toString();
    value['TenantLogoUrl'] = this.fileName || '';
    this.activeSpinner();
    // console.log(value);
    if (this.isEdit) {
      value['TNT_CODE'] = this.tnt_code;
      this.CommonService.postCall('TenantRegistration/updateSuperAdmin', value).subscribe(
        (res: any) => {
          this.toastr.success('Client Updated Successfully');
          this.deactivateSpinner();
          this.close();
        }, err => {
          this.deactivateSpinner();
          this.toastr.error(err.error ? err.error : 'Client not Updated')
        }
      )
    } else {
      this.CommonService.postCall('TenantRegistration/createSuperAdmin', value).subscribe(
        (res: any) => {
          this.toastr.success('Client Created  Successfully');
          this.deactivateSpinner();
          this.close();
        }, err => {
          this.deactivateSpinner();
          this.toastr.error(err.error ? err.error : 'Client not Created')
        }
      )
    }

  }
  close() {
    window.history.back()
  }
  getTenantData() {
    let payLoad = {
      RoleId: localStorage.getItem('RoleId'),
      TNT_CODE: localStorage.getItem('TenantCode')
    }
    this.activeSpinner();
    this.CommonService.postCall('LoadTenantByRoleId', payLoad).subscribe(
      (res: any) => {
        this.deactivateSpinner()
        this.tenants = res;
      }, err => {
        this.deactivateSpinner()
      }
    )
  }

  changeFile(event) {
    if (event.target.files.length > 0) {
      let file: File = event.target.files[0];
      let name = file.name;
      let filetype = name.split('.').pop()
      let types: Array<any> = ['png', 'jpg', 'PNG', 'jpeg', 'gif', "JPEG"]
      let check = types.includes(filetype);
      if (check) {
        this.file = file;
        this.upload()
      }
      else {
        // alert(' Please upload pdf and doc file formats only.')
        this.toastr.warning('Please upload image file formats only.')
        event.target.value = ''
      }
    }
  }

  upload() {
    if (!this.file) return
    const formData = new FormData();
    formData.append('file', this.file);
    formData.append('ClientDocs', 'ClientDocs');

    this.activeSpinner();
    this.FileuploadService.upload(formData, 'UploadTenant').subscribe((res: any) => {
      this.fileName = res.FilePath;
      if (this.fileName && res.FilePath) {
        // this.file = null;
        this.deactivateSpinner();
        this.myform.controls['TenantLogoUrl'].setValue(this.fileName || '')
      }
    }, err => {
      this.toastr.warning(err.error ? err.error : 'file not uploaded')
      this.deactivateSpinner();
    })
  }
}
