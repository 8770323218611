import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-schedule-feedback',
  templateUrl: './schedule-feedback.component.html',
  styleUrls: ['./schedule-feedback.component.css']
})
export class ScheduleFeedbackComponent implements OnInit {
  myForm: FormGroup;
  feedbackResponse: any;
  facultyFeedbackStartDate: string = '';
  facultyFeedbackEndDate: string = '';
  facilityFeedbackStartDate: any;
  facilityFeedbackEndDate: any;
  minDate: any = moment(new Date()).format('yyyy-MM-DD')
  headingName: any = {};
  labelName: any = {};
  workItemId: any;

  constructor(private active : ActivatedRoute,private CommonService: CommonService, private fb: FormBuilder, private toastr: ToastrService) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.getFeedback();
    this.getLabels();
    this.loadHeadings();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      faculty_feedback_start_date: ['', Validators.required],
      faculty_feedback_end_date: ['', Validators.required],
      facility_feedback_startdate: ['', Validators.required],
      facility_feedback_enddate: ['', Validators.required],
    })
  }


  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }


  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  // ScheduleChange(event: any) {
  //   let value = event.target.value;
  //   let obj = {
  //     CheckedValue: value
  //   }
  //   if (event.target.checked) {
  //     this.isChecked.push(obj);
  //   } else {
  //     this.isChecked = this.isChecked.filter((e: any) => e.CheckedValue != value);

  //   }

  // }
  getFeedback() {
    this.CommonService.getCall(`EmsFeedback/GetFeedbackSettings/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {

      this.feedbackResponse = res.feedback_settings_id;
      this.facultyFeedbackStartDate = res.faculty_feedback_start_date;
      this.facultyFeedbackEndDate = res.faculty_feedback_end_date;
      this.facilityFeedbackStartDate = res.facility_feedback_startdate;
      this.facilityFeedbackEndDate = res.facility_feedback_enddate;

    })
  }


  onSubmit(form: FormGroup) {
    let value = form.value;
    value.created_by = localStorage.getItem('UserId');
    value.modified_by = localStorage.getItem('UserId');
    value.tnt_code = localStorage.getItem('TenantCode');
    value.feedback_settings_id = this.feedbackResponse;
    this.CommonService.postCall('EmsFeedback/CreateFeedbackSettings', value).subscribe((response: any) => {
      this.toastr.success('Submitted Successfully');
      this.getFeedback();
    })
  }
}
