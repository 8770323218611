import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { isThisSecond } from 'date-fns';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-bus-mapping',
  templateUrl: './bus-mapping.component.html',
  styleUrls: ['./bus-mapping.component.css']
})
export class BusMappingComponent implements OnInit {
  table: Array<any> = [];
  myForm: FormGroup;
  isEdit: boolean = false;
  vehicle: Array<any> = [];
  routeName: Array<any> = [];
  editData = {};
  labelName: any = {};

  routeBusesId: any;
  headingName: any = {};
  workItemId: any;
  constructor(private fb: FormBuilder,private active : ActivatedRoute, private CommonService: CommonService, private toastr: ToastrService) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadBusMapping();
    this.loadRoute();
    this.loadVehicles();
    this.getLabels();
    this.loadHeadings();

  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      route_id: ['', Validators.required],
      vehicle_id: ['', Validators.required],
      bus_no: ['', [Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      status: ['', Validators.required],
    });
  }
  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  loadBusMapping() {
    this.CommonService.getCall(`Transport/GetRouteBusesList/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = res;
      }, 10);
    })
  }

  loadRoute() {
    this.routeName = [];
    this.CommonService.getCall(`Transport/RouteDropDownList/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.routeName = res;
    })
  }

  loadVehicles() {
    this.vehicle = [];
    this.CommonService.getCall(`Transport/GetVehicleDropDown/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.vehicle = res;
    })
  }

  onSubmit(form: FormGroup) {
    let value = form.value;
    value['tnt_code'] = localStorage.getItem('TenantCode');
    value['created_by'] = localStorage.getItem('UserId');
    value['modified_by'] = localStorage.getItem('UserId');
    console.log(value)
    if (this.isEdit) {
      value['modified_by'] = localStorage.getItem('UserId');
      value['route_buses_id'] = this.routeBusesId;
      this.CommonService.postCall('Transport/UpdateRouteBus', value).subscribe((res: any) => {
        this.loadBusMapping();
        this.toastr.success("Bus Number Updated Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Bus Number Not Updated')
      })
    } else {
      this.CommonService.postCall('Transport/CreateRouteBus', value).subscribe((res: any) => {
        this.loadBusMapping();
        this.toastr.success("Bus Number Created Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Bus Number Not created')

      })
    }
  }


  edit(bmId: any) {
    this.routeBusesId = bmId;
    console.log(bmId);
    this.isEdit = true;
    this.CommonService.postCall('Transport/GetRouteBus', { "route_buses_id": bmId }).subscribe((res: any) => {
      this.editData = res;
      setTimeout(() => {
        this.dataTransfer(res);
      }, 10);
    })
  }
  dataTransfer(data) {
    this.myForm.patchValue({
      route_id: data['route_id'],
      vehicle_id: data['vehicle_id'],
      bus_no: data['bus_no'],
      status: data['status'],
      route_buses_status: ['']
    })
  }

  close() {
    this.isEdit = null;
    this.editData = {};
    this.myForm.reset();
  }

}
