<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.QuotationList}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right mb-1">
                                            <button type="button" class="btn btn-primary" (click)="add()">Add</button>
                                        </div>
                                        <div *ngIf="table.length">
                                            <table id="patergraph" datatable
                                                class=" table-striped table-bordered  table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <!-- <th>S.No </th> -->
                                                        <th>Quotation Number</th>
                                                        <th>Quotation Amount</th>
                                                        <th>Request Date</th>
                                                        <th>Status</th>
                                                        <th>Action</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <!-- <td></td> -->
                                                        <td>{{item.quotation_number}}</td>
                                                        <td>{{item.quoted_total}}</td>
                                                        <td>{{item.request_date | date:'yyyy-MM-dd'}}</td>
                                                        <td>{{item.quotation_status}}</td>
                                                        <td><button *ngIf="item.quotation_status == 'Quotation Sent'"
                                                                class="btn btn-sm btn-ai"
                                                                (click)="reciQuto(item.quotation_id)">Recived
                                                                Quotation</button>
                                                            <button *ngIf="item.quotation_status == 'pending'"
                                                                class="btn btn-sm btn-ai" data-bs-toggle="modal"
                                                                data-bs-target="#myModal"
                                                                (click)="getVendors(item.quotation_id)">Send
                                                                Quotation</button>

                                                            <!-- <button *ngIf="item.quotation_status == 'Received'" 
                                                            class="btn btn-sm btn-ai" data-bs-toggle="modal"
                                                                data-bs-target="#myModal" (click)="editQuotation(item.quotation_receive_id)">Edit
                                                                Received Quotation
                                                            </button> -->

                                
                                                        </td>
                                                        <!-- <td style="display: flex;">
                                                            <span data-bs-toggle="modal" data-bs-target="#myModal"
                                                                src="" class="edit_icon" (click)="edit(item.quotation_id)">
                                                                <i class="fas fa-pen pt-1 mt-1"></i></span>
                                                        </td> -->

                                                    </tr>
                                                </tbody>

                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




<div class="modal" id="myModal" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <div class="modal-header py-2">
                <h5 class=" modal-title w-100">Send Quotation</h5>

                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>


            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="row form-group mb-2">
                            <label class="col-md-4 form-label"> Vendors</label>
                            <div class="">
                                <div class="col-md-8 input-group">
                                    <ng-multiselect-dropdown style="width:100%" formControlName="vendor_id"
                                        *ngIf="!isData" [placeholder]="'select Vendors'" [settings]="dropdownSettings"
                                        [data]="dropdownListVendors" (onSelect)="onSelectOne($event)"
                                        (onSelectAll)="onSelectAll($event)" [(ngModel)]='selectedVendors'>
                                    </ng-multiselect-dropdown>
                                </div>

                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>

                <button type="button" class="btn btn-sm btn-danger" id='md_close' (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>


        </div>

    </div>

</div>