<br>
<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid ">
                        <div class="text-right mb-1">
                            <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                <h5 class="text-left mb-0 ">{{headingName.PrintIDCard2}}</h5>
                            </div>&nbsp;
                            <div>
                                <button type="button" class="btn btn-primary" (click)="Print()">
                                    Print</button> &nbsp;
                                <button type="button" class="btn btn-danger" (click)="back()">
                                    Back</button>
                            </div>
                        </div>
                        <div id="print">
                            <div class="id-1" *ngFor="let item of idCardList">
                                <div class="bg-img-3">
                                    <div class="certificate-container">
                                        <div style="display: flex; padding:15px ">
                                            <div style="padding-right:15px ;">
                                                <div style="text-align: center;"><img
                                                        src="../../../assets/img/Samvaad.png" alt="" width="70px"
                                                        height="auto"></div>

                                                <h2
                                                    style="color: #17144D; font-size: 13px; font-weight: bolder; word-spacing: 1; text-transform: uppercase; margin: 0px; padding-top: 3px;">
                                                    SURYODAYA HIGH SCHOOL</h2>
                                                <h4
                                                    style="color: #17144D; font-size: 11px; font-weight: bold; padding: 0px; margin: 0px; text-align: center;">
                                                    Begumpet, Hyderabad</h4>
                                            </div>
                                            <div style="padding-right: 10px;border-radius: 5px; ">
                                                <img src="../../../assets/img/sample-photo.jpg" width="90px"
                                                    height="90px" alt=""
                                                    style="border:2px solid #FFF9E7; border-radius:50%">

                                            </div>

                                        </div>
                                        <div>
                                            <div style="padding: 0px 13px;">
                                                <table style="width: 100%; ">

                                                    <tr>
                                                        <td style="width:40%">
                                                            <p
                                                                style="color: #17144D; font-weight: 600;font-size: 12px; margin: 0px;">
                                                                <span>Name</span>
                                                            </p>
                                                        </td>
                                                        <td style="width:40%">
                                                            <p
                                                                style="color: #17144D; font-weight: 600;font-size: 12px; margin: 0px;">
                                                                <span>:</span><span> {{item.name}}</span>
                                                            </p>
                                                        </td>
                                                        <td rowspan="4" style="vertical-align: bottom; width: 20%; ">
                                                            <!-- <div style="text-align: right;  padding-right: 2px;"><img
                                                                    src="../../../assets/img/qr.png" alt=""
                                                                    style="height: 50px; width: 50px; margin-top: -13px;">
                                                            </div> -->
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 90px;">
                                                            <p
                                                                style="color: #17144D; font-weight: 600;font-size: 12px; margin: 0px;">
                                                                <span>Class &
                                                                    Section</span>
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p
                                                                style="color: #17144D; font-weight: 600;font-size: 12px; margin: 0px;">
                                                                <span>:</span><span> {{item.course_name}}</span>
                                                            </p>
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td style="vertical-align: top;">
                                                            <p
                                                                style="color: #17144D; font-weight: 600;font-size: 12px; margin: 0px;">
                                                                <span></span>
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p
                                                                style="color: #17144D; font-weight: 600;font-size: 12px;  margin: 0px;">
                                                                <span></span><span> </span>
                                                            </p>
                                                        </td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p
                                                                style="color: #17144D; font-weight: 600;font-size: 12px; margin: 0px;">
                                                                <span></span>
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p
                                                                style="color:#17144D; font-weight: 600;font-size: 12px; margin: 0px;">
                                                                <span></span><span> </span>
                                                            </p>
                                                        </td>

                                                    </tr>
                                                </table>
                                                <table>
                                                    <tr>

                                                        <td>

                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <div>

                                            </div>

                                        </div>
                                    </div>




                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>