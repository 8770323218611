import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { dataDictionary } from './../../dataDictionary';
declare var $: any;

@Component({
  selector: 'app-time-table',
  templateUrl: './time-table.component.html',
  styleUrls: ['./time-table.component.css']
})
export class TimeTableComponent implements OnInit {

  searchForm: FormGroup;
  table: Array<any> = [];
  courseDropdown: Array<any> = [];
  courseScheduleDropdown: Array<any> = [];
  academicYearDropdown: Array<any> = [];
  courseYearDropdown: Array<any> = [];
  semesterDropdown: Array<any> = [];
  sectionDropdown: Array<any> = [];
  headingName: any = {};
  workItemId: any;


  constructor(private fb: FormBuilder,private activeParam : ActivatedRoute, private CommonService: CommonService, private toastr: ToastrService, private route: Router) {
    this.activeParam.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadTimeTable();
  }

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      course_id: [0],
      course_schedule_id: [0]
    });
    this.loadCourse();
    this.getDropdown();
    this.loadHeadings();
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }


  loadTimeTable(course_id: number = 0, course_schedule_id: number = 0) {
    let payload = {
      "tnt_code": localStorage.getItem('TenantCode'),
      "class_id": +course_id,
      "section_id": +course_schedule_id,
      "ROLE_NAME":localStorage.getItem('RoleName'),
      "USER_ID":localStorage.getItem('UserId')
    }
    this.CommonService.postCall('TimeTable/GetTimeTableList', payload).subscribe((res: any) => {
      this.table = res;
    })
  }

  active(tId: any, status: any) {
    if (status == true) {
      if (confirm("Are you sure you want to Inactive")) {
        this.CommonService.getCall(`TimeTable/DeleteTimeTable/${tId}/false`).subscribe((res: any) => {
          this.loadTimeTable();
          this.toastr.success('Time Table Inactived')
        })
      }
    } else if (status == false) {
      if (confirm("Are you sure you want to Active")) {
        this.CommonService.getCall(`TimeTable/DeleteTimeTable/${tId}/true`).subscribe((res: any) => {
          this.loadTimeTable();
          this.toastr.success('Time Table Actived')
        })
      }
    }

  }

  add() {
    this.route.navigate([`home/addTimeTable/${this.workItemId}`],);
  }

  edit(tTd: any) {
    this.route.navigate(["home/editTimeTable"], { queryParams: { "timeTableId": tTd ,"wId":this.workItemId} });
  }

  onCourseChange(event: any) {
    let id = event.target.value;
    this.activeSpinner()
    this.CommonService.getAdminCourseSchedule({ "CourseId": id }).subscribe((res: any) => {
      this.deactivateSpinner()
      this.courseScheduleDropdown = res;
    }, e => { this.deactivateSpinner() })
  }

  getDropdown() {
    this.activeSpinner()
    let academicYear = this.CommonService.getCall(`Academic/GetAcademicDropDownList/${localStorage.getItem('TenantCode')}`);
    let courseYear = this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.CourseYear });
    let semister = this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.Semester });
    let section = this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.Sections });
    let dropdowns = ['academicYearDropdown', 'courseYearDropdown', 'semesterDropdown', 'sectionDropdown']
    forkJoin([academicYear, courseYear, semister, section]).subscribe((res) => {
      dropdowns.map((key, index) => {
        this[key] = res[index]
      });
      this.deactivateSpinner()
    }, err => { this.deactivateSpinner() });
  }

  onSearch(form: FormGroup) {
    if (form.value.course_id == 0) {
      this.toastr.warning('Please select Course.');
      return;
    }
    console.log(form);
    this.loadTimeTable(form.value.course_id, form.value.course_schedule_id);
  }

  loadCourse() {
    this.activeSpinner()
    this.CommonService.getAdminCourses().subscribe((res: any) => {
      this.courseDropdown = res;
      this.deactivateSpinner()
    }, e => {
      this.deactivateSpinner()
    })
  }
  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

}
