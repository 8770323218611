import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-complaints',
  templateUrl: './complaints.component.html',
  styleUrls: ['./complaints.component.css']
})
export class ComplaintsComponent implements OnInit {
  myForm: FormGroup;
  table: Array<any> = [];
  isEdit: boolean = null;
  editData: any = {};
  complainton: Array<any> = [];
  complaintto: Array<any> = [];
  labelName: any = {};
  headingName: any = {};
  workItemId: any;

  constructor(private fb: FormBuilder, private active: ActivatedRoute, private CommonService: CommonService, private toastr: ToastrService) {
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
    this.loadComplaints();
    this.getLabels();
    this.loadHeadings();

  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      complaint_on: ['', Validators.required],
      complaint_description: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      complaint_to: ['', Validators.required]
    })
  }
  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }
  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  loadComplaints() {
    this.CommonService.postCall('EmsComplaint/GetList', { "tnt_code": localStorage.getItem('TenantCode') }).subscribe((res: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = res;
      }, 10);
    })
  }

  onSubmit(myForm: FormGroup) {
    let value: any = myForm.value
    value['tnt_code'] = localStorage.getItem('TenantCode');
    value['created_by'] = localStorage.getItem('UserId');
    console.log(value)
    if (this.isEdit) {
      value['complaint_id'] = this.editData.complaint_id;
      this.CommonService.postCall('EmsComplaint/Update', value).subscribe((res: any) => {
        this.toastr.success("Complaint Updated Successfully");
        this.loadComplaints();
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Complaints Not Updated')
      })
    } else {
      this.CommonService.postCall('EmsComplaint/Create', value).subscribe((res: any) => {
        this.toastr.success("Complaint Created Successfully");
        this.loadComplaints();
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Complaints Not created')

      })
    }
  }

  edit(cId: any) {
    this.isEdit = true;
    this.CommonService.postCall("EmsComplaint/Get", { "complaint_id": cId }).subscribe((res: any) => {
      if (res instanceof Array) {
        this.editData = res[0];
        this.dataTransfer(res[0])
      } else {
        this.editData = res;
        this.dataTransfer(res);
      }
      console.log(res, this.editData)
    })
  }

  dataTransfer(data) {
    let ctrls = this.myForm.controls;
    Object.keys(ctrls).map(key => {
      let ctrl = ctrls[key];
      ctrl.setValue(data[key])
    })

  }
  close() {
    this.isEdit = null;
    this.myForm.reset();
    this.editData = {};
  }

  GetComplaintname(id) {
    let name = '';
    if (id == '1')
      name = 'Staff';
    else if (id == '2')
      name = 'Student';
    else if (id == '3')
      name = 'Facilities';
    return name;
  }
}
