import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-book-shelf',
  templateUrl: './book-shelf.component.html',
  styleUrls: ['./book-shelf.component.css']
})
export class BookShelfComponent implements OnInit {
  myForm: FormGroup;
  table: Array<any> = [];
  isEdit: boolean = false;
  labelName: any = {};
  headingName: any = {};
  workItemId: any;
  rack: Array<any> = [];
  editData:any;
  shelfId: any;
  shelf:Array<any>=[];
  constructor(private toastr: ToastrService, private fb: FormBuilder, private active: ActivatedRoute, private CommonService: CommonService) {
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
    this.loadlabels();
    this.loadHeadings();
    this.loadShelf();
    this.rackDropdown();
   
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      br_id:['',Validators.required],
      bs_name: ['',Validators.required],
      bs_desciption: ['',Validators.required],
      status:['',Validators.required]
    })
  }

  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  rackDropdown(){
    this.rack=[];
    let payload={
      "br_tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('BookRackMaster/GetList', payload).subscribe((res: any) => {
      this.rack=res;
    })
  }

  getShelf(rack_id:any){
    this.shelf=[];
    this.CommonService.getCall(`BookShelf/LoadShelfsByRack/${rack_id}/${localStorage.getItem('TenantCode')}`).subscribe((res:any)=>{
     this.shelf=res
    })
  }

  loadShelf() {
    let payload = {
      "bs_tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('BookShelf/GetList', payload).subscribe((res: any) => {
      this.table = res;
    })
  }

  add() {
    this.editData = {};
    this.isEdit = false;
    this.myForm.reset();
  }
  onSubmit(form: FormGroup) {
    let value: any = form.value;
    value['bs_tnt_code'] = localStorage.getItem('TenantCode');
    value['created_by'] = localStorage.getItem('UserId');
    value['modified_by'] = localStorage.getItem('UserId');

    if (this.isEdit) {
      value['modified_by'] = localStorage.getItem('UserId');
      value['bs_id'] = this.shelfId;
      this.CommonService.postCall('BookShelf/Update', value).subscribe((res: any) => {
        this.loadShelf();
        this.toastr.success("Book Rack Updated Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Book Rack Not Updated')
      })
    } else {
    this.CommonService.postCall('BookShelf/Create', value).subscribe((res: any) => {
      this.loadShelf();
      this.toastr.success("Book Rack Created Successfully");
      document.getElementById('md_close').click();
    }, err => {
      this.toastr.error(err.error ? err.error : 'Book Rack Not created')
    })

    }
    }



  edit(bsId: any) {
    this.shelfId = bsId;
    //  console.log(bId);
    this.isEdit = true;
    let payLoad = {
      "bs_id": bsId
    }
    this.CommonService.postCall('BookShelf/Get', payLoad).subscribe((res: any) => {
      this.editData = res;
      setTimeout(() => {
        this.dataTransfer(res);
      }, 10);
    })
  }

  dataTransfer(data) {
    this.myForm.patchValue({
      br_id: data['br_id'],
      bs_name: data['bs_name'],
      bs_desciption: data['bs_desciption'],
      status: data['status']
    })
  }

  close() {


  }

}
