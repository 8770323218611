import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

import * as moment from 'moment';
@Component({
  selector: 'app-view-student-diary',
  templateUrl: './view-student-diary.component.html',
  styleUrls: ['./view-student-diary.component.css']
})
export class ViewStudentDiaryComponent implements OnInit {
  date: Date;
  sId: any;
  csId: any;
  studentDiaryData: any = {};
  subjectList: Array<any> = [];
  Announcement: any;
  Newsletter: File;
  Monthly: File;
  dateView: any;
  maxDate: any = moment().format('yyyy-MM-DD');
  fileUrl: string = environment.fileUrl;
  headingName: any = {};
  constructor(private route: Router, private fb: FormBuilder, private CommonService: CommonService, private toastr: ToastrService, private active: ActivatedRoute) {
    active.queryParams.subscribe((res: any) => {
      this.date = res.date;
      this.sId = res.sId;
      this.csId = res.csId;
    })
    if (location.hostname != "localhost") {
      this.fileUrl = `${location.origin}/api`;
    }
    this.getStudentDiary();
    this.loadHeadings();
  }

  ngOnInit(): void {

  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/4350/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  getStudentDiary() {
    let payload = {
      "student_id": parseInt(this.sId),
      "date": this.date,
      "section_id": parseInt(this.csId)
    }
    this.CommonService.postCall(`TimeTable/ViewStudentDailyDiarybyId`, payload).subscribe((res: any) => {
      this.studentDiaryData = res;
      this.subjectList = res.subjects;
      this.Announcement = res.addtional_remarks;
      this.Newsletter = res.newsletter_attachment;
      this.Monthly = res.monthly_event_attachment;
      this.dateView = res.date;
    })
  }

  ViewFile(item: any) {
    let prefix = this.studentDiaryData.newsletter_attachment?.replace('~', '');
    window.open(`${this.fileUrl}/TimeTable/FileView${prefix}`, 'name', 'height=250,width=550,toolbar=0,menubar=0,location=0');
  }

  ViewFile1(item: any) {
    let prefix = this.studentDiaryData.monthly_event_attachment?.replace('~', '');
    // let link = this.CommonService.fileUrl + '/common/Getfile' + prefix;
    window.open(`${this.fileUrl}/TimeTable/FileView${prefix}`, 'name', 'height=250,width=550,toolbar=0,menubar=0,location=0');

  }


  ViewFile2(file: any) {
    let prefix = file?.replace('~', '');
    window.open(`${this.fileUrl}/TimeTable/FileView${prefix}`, 'name', 'height=250,width=550,toolbar=0,menubar=0,location=0');
  }



  close() {
    window.history.back();
  }



}
