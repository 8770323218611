import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-super-admin-creation',
  templateUrl: './super-admin-creation.component.html',
  styleUrls: ['./super-admin-creation.component.css']
})
export class SuperAdminCreationComponent implements OnInit {

  table:Array<any>=[];
  headingName:any= {};
  
  constructor(private route: Router, private CommonService: CommonService,private toastr: ToastrService) {
    this.getTenantData();
    this.loadHeadings();
   }

  ngOnInit(): void {
  }
  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/4290/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  add() {
    this.route.navigate(['/home/createSuperAdmin/add'])
  }
  edit(item) {
    let params = {
      tntCode: item.TNT_CODE,
    }
    this.route.navigate(['/home/createSuperAdmin/edit'], { queryParams: params });
  }
  getTenantData(){
    let payLoad={
      RoleId:localStorage.getItem('RoleId'),
      TNT_CODE:localStorage.getItem('TenantCode')
    }
    this.activeSpinner();
    this.CommonService.postCall('LoadTenantByRoleId',payLoad).subscribe(
      (res:any)=>{
        this.deactivateSpinner()
        this.table=res;
      },err=>{
        this.deactivateSpinner()
      }
    )
  }

}
