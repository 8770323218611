<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0"> {{headingName.BookIssue}}</h5>
                                        <!-- <h5 class="mb-0"> {{headingName.LibraryBooksAllocation}} Book Issue</h5> -->
                                    </div>
                                    <div class="card-body">
                                        <!-- <div class="text-right mb-1">
                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                                data-bs-target="#myModal">Add Book</button>
                                        </div> -->
                                        <div class="col-xl-12">
                                            <div class="table well form-horizontal">
                                                <form class="well form-horizontal">
                                                    <fieldset>
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group row">
                                                                    <label class="col-md-4 control-label line_2 rem_1">
                                                                        {{labelName?.StudentId}}<span
                                                                            class="text-danger">*</span></label>
                                                                    <div class="col-md-8 inputGroupContainer">
                                                                        <div class="input-group"> <strong>:</strong>
                                                                            &nbsp;
                                                                            <input type="text" placeholder=""
                                                                                class="form-control"
                                                                                [(ngModel)]="studentIdss"
                                                                                [ngModelOptions]="{standalone: true}"
                                                                                (change)=scanQr($event.target.value)>

                                                                        </div>
                                                                        <p style="color:black">* please select the
                                                                            textbox and scan the bar code</p>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>&nbsp;&nbsp;
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group row">
                                                                    <label class="col-md-4 control-label line_2 rem_1">
                                                                        {{labelName?.StudentName}}<span
                                                                            class="text-danger">*</span></label>
                                                                    <div class="col-md-8 inputGroupContainer">
                                                                        <div class="input-group"> <strong>:</strong>
                                                                            &nbsp;
                                                                            <!-- <input type="text" placeholder="" class="form-control"
                                                                            formControlName="accession_text"> -->
                                                                            <p><b>{{singleData?.name}}</b></p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-md-6">
                                                                <div class="form-group row">
                                                                    <label class="col-md-4 control-label line_2 rem_1">
                                                                        {{labelName?.Program}}<span
                                                                            class="text-danger">*</span></label>
                                                                    <div class="col-md-8 inputGroupContainer">
                                                                        <div class="input-group"> <strong>:</strong>
                                                                            &nbsp;
                                                                            <!-- <input type="text" placeholder="" class="form-control"
                                                                            formControlName="accession_text"> -->
                                                                            <p><b>{{singleData?.course_name}}</b></p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>&nbsp;&nbsp;


                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group row">
                                                                    <label class="col-md-4 control-label line_2 rem_1">
                                                                        {{labelName?.year}}<span
                                                                            class="text-danger">*</span></label>
                                                                    <div class="col-md-8 inputGroupContainer">
                                                                        <div class="input-group"> <strong>:</strong>
                                                                            &nbsp;
                                                                            <!-- <input type="text" placeholder="" class="form-control"
                                                                            formControlName="accession_text"> -->
                                                                            <p><b>{{singleData?.course_year}}</b> </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                    <br><br>
                                                    <!-- <div class="text-center">
                                                        <button type="submit" [disabled]="myForm.invalid"
                                                        class="btn btn-sm  btn-save">Submit</button>
                                                    </div> -->
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card-body">
                                        <div class="text-right mb-1">
                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                                data-bs-target="#myModal">Issue Book</button>
                                        </div>
                                        <div class="pt-4" *ngIf="qrCode.length">
                                            <table datatable id="DataTables_Table_0"
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th> Book Accession No </th>
                                                        <th> Book ISBN </th>
                                                        <th> Book Name </th>
                                                        <th> Issue Date </th>
                                                        <th> Return Date</th>
                                                        <th>Author</th>
                                                        <th>Fine</th>
                                                        <th>Book Status</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of qrCode">
                                                        <!-- <td>{{item.book_id}}</td> -->
                                                        <td>{{item.bar_code_id}}</td>
                                                        <td>{{item.book_isdn}}</td>
                                                        <td>{{item.book_name}}</td>
                                                        <td>{{item.book_std_date_of_issue | date:'dd/MM/yyyy'}}</td>
                                                        <td>{{item.book_std_return_date | date:'dd/MM/yyyy'}}</td>
                                                        <td>{{item.book_author}}</td>
                                                        <td>{{item.fine}}</td>
                                                        <td>{{item.book_status}}</td>



                                                    </tr>
                                                </tbody>
                                            </table>
                                            <!-- </div> -->

                                            <div class="text-center pt-4 text-red" *ngIf="!qrCode.length">
                                                No Records to display
                                            </div>

                                            <!-- <div class="text-center pt-2">
                                                    <button type="button" (click)="ok()"
                                                    class="btn btn-danger btn-sm">Close</button>
                                                </div> -->

                                            <!-- </div> -->
                                        </div>
                                    </div>








                                    <div class="modal" id="myModal" data-backdrop="static" data-keyboard="false">
                                        <div class="modal-dialog  modal-lg">
                                            <div class="modal-content">

                                                <!-- Modal Header -->
                                                <div class="modal-header py-2">
                                                    <h6 *ngIf="!isEdit" class="modal-title w-100">
                                                        {{headingName.IssueBooks}}</h6>
                                                    <!-- <h6 *ngIf="isEdit" class="modal-title w-100"> {{headingName.EditSubject}}</h6> -->
                                                    <button type="button" (click)="close()" class="close"
                                                        data-bs-dismiss="modal"><i class="fas fa-times"></i></button>
                                                </div>

                                                <!-- Modal body -->
                                                <div class="modal-body">
                                                    <form class="well form-horizontal" [formGroup]="myForm"
                                                        (ngSubmit)="onSubmit(myForm)">
                                                        <fieldset>
                                                            <div class="form-group row pt-4">
                                                                <label class="col-md-4 control-label line_2 rem_1">
                                                                    {{labelName?.BookAccessionNo}} <span
                                                                        class="text-danger">*</span></label>
                                                                <div class="col-md-8 inputGroupContainer">
                                                                    <div class="input-group">
                                                                        <input placeholder="" class="form-control"
                                                                            formControlName="issueBook"
                                                                            (change)=issueBooks($event.target.value)
                                                                            onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">
                                                                    </div>
                                                                    <p style="color:black">* please select the textbox
                                                                        and scan the bar code</p>
                                                                    <!-- <div *ngIf="myForm.get('SUBJECT_NAME').touched">
                                                                        <span class="text-danger" *ngIf="myForm.get('SUBJECT_NAME').hasError('required')">
                                                                            This field is required.
                                                                        </span>
                                    
                                                                    </div> -->
                                                                </div>
                                                            </div>

                                                            <div class="form-group row pt-4">
                                                                <label class="col-md-4 control-label line_2 rem_1">
                                                                    {{labelName?.AvailableBooks}}<span
                                                                        class="text-danger">*</span></label>
                                                                <div class="col-md-8 inputGroupContainer">
                                                                    <div class="input-group">
                                                                        <input placeholder="" class="form-control"
                                                                            [(ngModel)]="availableBooks" disabled
                                                                            formControlName="availableBooks"
                                                                            (blur)=issueBooks($event.target.value)
                                                                            onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">
                                                                    </div>

                                                                    <!-- <div *ngIf="myForm.get('SUBJECT_NAME').touched">
                                                                        <span class="text-danger" *ngIf="myForm.get('SUBJECT_NAME').hasError('required')">
                                                                            This field is required.
                                                                        </span>
                                    
                                                                    </div> -->

                                                                </div>
                                                            </div>
                                                            <div class="form-group row pt-4">
                                                                <label class="col-md-4 control-label line_2 rem_1">
                                                                    {{labelName?.IssueDate}}<span
                                                                        class="text-danger">*</span></label>
                                                                <div class="col-md-8 inputGroupContainer">
                                                                    <div class="input-group">
                                                                        <input placeholder="IssueDate"
                                                                            class="form-control" min="{{minDate}}"
                                                                            formControlName="IssueDate" type="date"
                                                                            onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">
                                                                    </div>
                                                                    <!-- <div *ngIf="myForm.get('SUBJECT_DESCRIPTION').touched">
                                                                        <span class="text-danger"
                                                                            *ngIf="myForm.get('SUBJECT_DESCRIPTION').hasError('required')">
                                                                            This field is required.
                                                                        </span>
                                    
                                                                    </div> -->
                                                                </div>
                                                            </div>


                                                            <div class="form-group row pt-4">
                                                                <label class="col-md-4 control-label line_2 rem_1">
                                                                    {{labelName?.DueDate}}<span
                                                                        class="text-danger">*</span></label>
                                                                <div class="col-md-8 inputGroupContainer">
                                                                    <div class="input-group">
                                                                        <input type="number" min="1" max="20"
                                                                            placeholder="" class="form-control"
                                                                            formControlName="dueDate" type="date">
                                                                    </div>
                                                                    <!-- <div *ngIf="myForm.get('CREDITS').touched">
                                                                        <span class="text-danger" *ngIf="myForm.get('CREDITS').hasError('required')">
                                                                            This field is required.
                                                                        </span>
                                    
                                                                    </div> -->
                                                                </div>
                                                            </div>


                                                            <div class="form-group row pt-4">
                                                                <label
                                                                    class="col-md-4 control-label">{{labelName?.FineAfterDueDate}}</label>
                                                                <div class="col-md-8 inputGroupContainer">

                                                                    <div class="input-group">
                                                                        <input placeholder="" class="form-control"
                                                                            formControlName="FineAmount"
                                                                            onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    </form>
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-sm  btn-save"
                                                        (click)="onSubmit(myForm)"
                                                        [disabled]="myForm.invalid">Save</button>
                                                    <!-- <button type="button" *ngIf="isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                                                        [disabled]="myForm.invalid">Update</button> -->
                                                    <button type="button" class="btn btn-danger btn-sm" id='md_close'
                                                        (click)="close()" data-bs-dismiss="modal">Close</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="card-body">
                                        <div class="form-group ">
                                            <form class="well form-horizontal" [formGroup]="myForm"
                                                (ngSubmit)="onSubmit(myForm)">
                                                <fieldset>
                                                    <div class="row">
                                                        <div class="col-md-3 ">
                                                            <label for="sel1">{{labelName?.CourseName}} <span
                                                                    class="text-danger">*</span>:
                                                            </label>
                                                            <select
                                                                class="form-control form-control-sm shadow-sm rounded-lg"
                                                                id="sel1" (change)="courseChange($event)"
                                                                formControlName="courseName">
                                                                <option value="" [selected]="true">select</option>
                                                                <option *ngFor="let course of courses"
                                                                    [value]='course.COURSE_ID'>
                                                                    {{course.COURSE_NAME}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div class="col-md-3 ">
                                                            <label for="sel1"> {{labelName?.CourseSchedule}}<span
                                                                    class="text-danger">*</span>:
                                                            </label>
                                                            <select name="courseSchedule" id="courseSchedule"
                                                                (change)="sheduleChange($event)"
                                                                formControlName="courseSchedule"
                                                                class="form-control form-control-sm shadow-sm rounded-lg ">
                                                                <option value="" [selected]="true">Select</option>
                                                                <option *ngFor="let courseSchedule of Schedules"
                                                                    [value]="courseSchedule.COURSESHD_ID">
                                                                    {{courseSchedule.COURSESHD_NAME}}</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <label for="sel1">{{labelName?.StudentName}}<span
                                                                    class="text-danger">*</span>:</label>
                                                            <select name="student" id="student"
                                                                formControlName="student"
                                                                class="form-control form-control-sm shadow-sm rounded-lg ">
                                                                <option value="" [selected]="true">Select</option>
                                                                <option *ngFor="let student of students"
                                                                    [value]="student.USERID">{{student.FIRSTNAME}}
                                                                </option>
                                                            </select>
                                                        </div> -->

                                    <!-- <div class="col-md-3 ">
                                                            <label for="sel1"> Department<span
                                                                    class="text-danger">*</span>:
                                                            </label>
                                                            <select name="department" id="department"
                                                                formControlName="department"
                                                                class="form-control form-control-sm shadow-sm rounded-lg ">
                                                                <option value="" [selected]="true">Select</option>
                                                                <option *ngFor="let department of departments"
                                                                    [value]="department.DEPARTMENT_ID">
                                                                    {{department.DEPARTMENT_NAME}}</option>
                                                            </select>
                                                        </div>  -->
                                    <!-- </div>
                                                </fieldset>
                                                <br><br>
                                                <div class="text-center">
                                                    <button type="submit" [disabled]="myForm.invalid"
                                                    class="btn btn-sm  btn-save">Submit</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div> -->

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>