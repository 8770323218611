import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-college-feedback-list',
  templateUrl: './college-feedback-list.component.html',
  styleUrls: ['./college-feedback-list.component.css']
})
export class CollegeFeedbackListComponent implements OnInit {

  trainerList: Array<any> = [];
  feedbackList: Array<any> = [];
  headingName: any = {};
  constructor(private CommonService: CommonService) {
    this.getList();
    this.loadHeadings();
  }

  ngOnInit(): void {
  }
  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/4366/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  getList() {
    let payload = { "tnt_code": localStorage.getItem('TenantCode'), "role_id": localStorage.getItem('RoleId') }
    this.CommonService.postCall('EmsFeedback/GetStudentFeedbackListByTrainers', payload).subscribe((response: any) => {
      this.trainerList = response;
    })
  }
  viewGetList(course: any, academic: any, semester: any, studentId: any) {
    let payload = {
      "course_id": course,
      "academic_year_id": academic,
      "semester_id": semester,
      "student_id": studentId
    }
    this.CommonService.postCall('EmsFeedback/GetStudentFeedback', payload).subscribe((response: any) => {
      this.feedbackList = response;
    })
  }
  close() { }
}

