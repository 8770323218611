import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-book-rack',
  templateUrl: './book-rack.component.html',
  styleUrls: ['./book-rack.component.css']
})
export class BookRackComponent implements OnInit {
  myForm: FormGroup;
  table: Array<any> = [];
  isEdit: boolean = false;
  labelName: any = {};
  headingName: any = {};
  workItemId: any;
  editData: any;
  rackId: any;
  constructor(private toastr: ToastrService, private fb: FormBuilder, private active: ActivatedRoute, private CommonService: CommonService) {
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
    this.loadlabels();
    this.loadHeadings();
    this.loadRack();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      br_name: ['',Validators.required],
      br_description: ['',Validators.required],
      br_status: ['',Validators.required]
    })
  }

  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  loadRack() {
    let payload = {
      "br_tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('BookRackMaster/GetList', payload).subscribe((res: any) => {
      this.table = res;
    })
  }
  add() {
    this.editData = {};
    this.isEdit = false;
    this.myForm.reset();
  }
  onSubmit(form: FormGroup) {
    let value: any = form.value;
    value['br_tnt_code'] = localStorage.getItem('TenantCode');
    value['br_created_by'] = localStorage.getItem('UserId');
    value['br_mopdified_by'] = localStorage.getItem('UserId');

    if (this.isEdit) {
      value['br_mopdified_by'] = localStorage.getItem('UserId');
      value['br_id'] = this.rackId;
      this.CommonService.postCall('BookRackMaster/Update', value).subscribe((res: any) => {
        this.loadRack();
        this.toastr.success("Book Rack Updated Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Book Rack Not Updated')
      })
    } else {
      this.CommonService.postCall('BookRackMaster/Create', value).subscribe((res: any) => {
        this.loadRack();
        this.toastr.success("Book Rack Created Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Book Rack Not created')
      })

    }
  }



  edit(brId: any) {
    this.rackId = brId;
    //  console.log(bId);
    this.isEdit = true;
    let payLoad = {
      "br_id": brId
    }
    this.CommonService.postCall('BookRackMaster/Get', payLoad).subscribe((res: any) => {
      this.editData = res;
      setTimeout(() => {
        this.dataTransfer(res);
      }, 10);
    })
  }


  dataTransfer(data) {
    this.myForm.patchValue({
      br_name: data['br_name'],
      br_description: data['br_description'],
      br_status: data['br_status'],
    })
  }

  close() {

  }
}
