import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { FileuploadService } from 'src/app/services/fileupload.service';


@Component({
  selector: 'app-add-event',
  templateUrl: './add-event.component.html',
  styleUrls: ['./add-event.component.css']
})
export class AddEventComponent implements OnInit {
  myForm: FormGroup;
  labelName: any = {};
  table: Array<any> = [];
  isEdit: boolean = false;
  editData: any = {}
  id: string;
  minDate: any = moment().format('yyyy-MM-DD');
  categoryDropdown: Array<any> = []
  file: File;
  fileName: any;
  headingName: any = {};
  workItemId:any;
  constructor(private fb: FormBuilder, private CommonService: CommonService, active: ActivatedRoute, private toastr: ToastrService, private route: Router, private FileuploadService: FileuploadService) {
    active.queryParams.subscribe((res) => {
      if (res.eId) {
        this.id = res.eId;
        this.workItemId =res.wId;

        // this.edit()
      }
      this.workItemId =res.wId;

    })
    this.getLabels();
    this.loadHeadings();
    this.loadCategory();
    this.loadevents();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      event_category_id: ['', Validators.required],
      event_title: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      event_name: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      event_description: ['',],
      event_from_timestamp: ['', Validators.required],
      event_to_timestamp: ['', Validators.required],
      event_type: ['', Validators.required],
      event_participation_type: ['', Validators.required],
      event_eligibility: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      event_location: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      event_sponsor: [''],
      event_organizer: [''],
      event_organizer_contact_name: [''],
      event_organizer_email: ['', [Validators.email]],
      event_organizer_mobile: [''],
      registration_start_timestamp: ['', Validators.required],
      registration_end_timestamp: ['', Validators.required],
      registration_fee: ['', Validators.required],
      event_logo: ['']
    })

    if (this.id) {
      this.isEdit = true;
      this.edit()
    }
  }
  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  loadevents() {
    this.CommonService.postCall(`EmsEventsCategories/EventGetList`, { "tnt_code": localStorage.getItem('TenantCode') }).subscribe((res: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = res;
      }, 10);
    })
  }

  loadCategory() {
    this.categoryDropdown = [];
    let PayLoad = {
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('EmsEventsCategories/CategoriesDropDown', PayLoad).subscribe((res: any) => {
      this.categoryDropdown = res;
    })
  }

  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  changeFile(event) {
    if (event.target.files.length > 0) {
      let file: File = event.target.files[0];
      let name = file.name;
      let filetype = name.split('.').pop()
      let types: Array<any> = ['jpg', 'png', 'jpeg']
      let check = types.includes(filetype);
      if (check) {
        this.file = file;
        this.upload();
      }
      else {
        // alert(' Please upload pdf and doc file formats only.')
        this.toastr.warning('Please upload png , jpg and jpeg file formats only.')
        event.target.value = ''
      }
    }
  }

  upload() {
    const formData = new FormData();
    formData.append('attachment', this.file);
    this.FileuploadService.upload(formData, 'EmsEventsCategories/UploadImage').subscribe((res: any) => {

      try {
        this.fileName = res.path;
        if (this.fileName) {
          this.myForm.controls['event_logo'].setValue(this.fileName)
        }
      } catch (e) {
        console.log(e)
      }

    }, err => { this.toastr.warning('Upload Failed') })
  }

  onSubmit(form: any) {
    let value = form.value;
    value['tnt_code'] = localStorage.getItem('TenantCode');
    value['created_by'] = localStorage.getItem('UserId');
    value['modified_by'] = localStorage.getItem('UserId');
    value['academic_year_id'] = 0;
    value['event_logo'] = this.fileName;
    this.activeSpinner();
    if (this.isEdit) {
      value['event_id'] = this.id;
      value['tnt_code'] = localStorage.getItem('TenantCode');
      value['created_by'] = localStorage.getItem('UserId');
      value['modified_by'] = localStorage.getItem('UserId');
      this.CommonService.postCall('EmsEventsCategories/UpdateEvents', value).subscribe(() => {
        this.deactivateSpinner();
        this.toastr.success('Event updated successfully')
        window.history.back()
      }, err => {
        this.toastr.error(err.error ? err.error : ' Not Updated');
        this.deactivateSpinner();
      })
    } else {
      if (this.table.find((e: any) => e.event_title == value.event_title || this.table.find((e: any) => e.event_name == value.event_name))) {
        this.toastr.warning('Event Title and Event Name Already Exists');
        this.deactivateSpinner();
        return;
      }
      this.CommonService.postCall('EmsEventsCategories/CreateEvents', value).subscribe((res: any) => {
        this.deactivateSpinner();
        this.route.navigate(['home/event']);
        // this.toastr.success(" Created Successfully");
        this.toastr.success("Add Event Created Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Add Event Not created')
        this.deactivateSpinner();

      })
    }
  }

  edit() {
    this.isEdit = true;
    let payload = {
      "event_id": this.id
    }

    this.CommonService.postCall('EmsEventsCategories/GetById', payload).subscribe((res: any) => {
      this.fileName = res.event_logo;
      if (res instanceof Array) {
        this.editData = res[0];
        this.dataTransfer(res[0])
      } else {
        this.editData = res;
        this.dataTransfer(res);
      }
    }
    )

  }

  dataTransfer(data) {
    this.myForm.patchValue({
      event_category_id: this.editData['event_category_id'],
      event_title: this.editData['event_title'],
      event_name: this.editData['event_name'],
      event_description: this.editData['event_description'],
      event_from_timestamp: moment(this.editData['event_from_timestamp']).format('yyyy-MM-DD'),
      event_to_timestamp: moment(this.editData['event_to_timestamp']).format('yyyy-MM-DD'),
      event_type: this.editData['event_type'],
      event_participation_type: this.editData['event_participation_type'],
      event_eligibility: this.editData['event_eligibility'],
      event_location: this.editData['event_location'],
      event_sponsor: this.editData['event_sponsor'],
      event_organizer: this.editData['event_organizer'],
      event_organizer_contact_name: this.editData['event_organizer_contact_name'],
      event_organizer_email: this.editData['event_organizer_email'],
      event_organizer_mobile: this.editData['event_organizer_mobile'],
      registration_fee: this.editData['registration_fee'],
      registration_start_timestamp: moment(this.editData['registration_start_timestamp']).format('yyyy-MM-DD'),
      registration_end_timestamp: moment(this.editData['registration_end_timestamp']).format('yyyy-MM-DD'),
      event_logo: this.editData['event_logo']

    });
  }
  close() {
    this.myForm.reset();
    this.route.navigate([`home/event/${this.workItemId}`], );
  }
}
