import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from '../base.component';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-createpol',
  templateUrl: './createpol.component.html',
  styleUrls: ['./createpol.component.css']
})
export class CreatepolComponent extends BaseComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
  headingName: any = {};
  labelName: any = {};
  workItemId: any;
  constructor(private route: Router, private active: ActivatedRoute, CommonService: CommonService, toastr: ToastrService) {
    super(CommonService, toastr)
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
    this.getPolls();
    this.loadHeadings();
    this.loadlabels();
  }

  ngOnInit(): void {
  }
  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  add() {
    this.route.navigate(['/home/addPoll'], { queryParams: { wId: this.workItemId } })
  }
  edit(item) {
    let params = {
      edit: item.PollID,
      wId: this.workItemId
    }
    this.route.navigate(['/home/addPoll'], { queryParams: params });
  }

  delete(data) {
    let params = {
      "PollId": data.PollID
    }
    var c = confirm("Are you sure, you want to delete record?")
    if (c) {
      this.CommonService.postCall('deletepoll', params).subscribe((res) => {
        this.toastr.success("poll deleted Successfully");
        this.getPolls()
      }, err => { })
    } else {

    }
  }

  getPolls() {
    this.activeSpinner();
    let data = {
      TENANT_CODE: this.tId || this.TenantCode,
      IsTrainer: false,
      UserId: localStorage.getItem('UserId'),
    }
    this.CommonService.postCall('loadpoll', data).subscribe(res => {
      this.table = res;
      this.renderDataTable();
      this.deactivateSpinner()
    }, err => {
      this.deactivateSpinner();
    })
  }
  changeTname() {
    this.getPolls()
  }
  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }
}
