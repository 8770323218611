<div class="page-wrapper">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">

                    <div class="container-fluid">

                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.TransportReports}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right mb-2">
                                        <!-- <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                                data-bs-target="#myModal">Add Vehicle</button> -->
                                        </div>
                                        <div *ngIf="table.length" class="table-responsive">
                                            <table id="patergraph" datatable
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small display">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <!-- <th>  Branch Name</th> -->
                                                        <th> Route Name </th>
                                                        <th> Stop Name </th>
                                                        <th> Bus Number  </th>
                                                        <th> Vehicle Number  </th>
                                                        <th>  Vehicle Type </th>
                                                        <th>  Driver Name </th>
                                                        <th>  Vehicle Status </th>
                                                        <th>  Route Status </th>
                                                    </tr>
                                                </thead>


                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <!-- <td></td> -->
                                                        <td>{{item.route_name}}</td>
                                                        <td>{{item.stop_name}}</td>
                                                        <td>{{item.bus_no}}</td>
                                                        <td>{{item.registration_number}}</td>
                                                        <td>{{item.vehicle_type}}</td>
                                                        <td>{{item.drivername}}</td>
                                                        <td>{{item.route_status  }}</td>
                                                        <td>{{item.status == true ? "Active" : "Inactive" }}</td>
                                                       
                                                    </tr>
                                                </tbody>

                                            </table>
                                        </div>


                                        <div class="text-center"*ngIf="!table.length" >
                                            No Records to display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
