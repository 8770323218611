<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.Courses}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right mb-1">
                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                                (click)="add()" data-bs-target="#myModal">Add</button>
                                        </div>
                                        <div class="form-group ">
                                            <div class="row">
                                                <div *ngIf='isSuperAdmin' class="col-md-3">
                                                    <label for="sel1">{{labelName.TenantName}} : </label>
                                                    <select [(ngModel)]='tId ' (change)="changeTname()"
                                                        class="form-control form-control-sm shadow-sm rounded-lg"
                                                        id="sel1">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let item of tenanates" [value]='item.TNT_CODE'>
                                                            {{item.TNT_NAME}}</option>
                                                    </select>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="pt-4">
                                            <table datatable id="DataTables_Table_0" [dtOptions]="dtOptions"
                                                [dtTrigger]="dtTrigger"
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th>
                                                            Course Name </th>
                                                        <th>
                                                            Status
                                                        </th>
                                                        <th>Duration</th>
                                                        <th>Fees</th>
                                                        <th>PreAssessment</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.COURSE_NAME}}</td>
                                                        <td>{{item.STATUS_DESCRIPTION}}</td>
                                                        <td>{{item.COURSE_DURATION}}</td>
                                                        <td>{{item.COURSE_FEES}}</td>
                                                        <td>{{item.IS_PREASSESSMENT}}</td>
                                                        <td style="display: flex;"> <span data-bs-toggle="modal"
                                                                data-bs-target="#myModal" src="" class="edit_icon"
                                                                (click)="edit(item)">
                                                                <i class="fas fa-pen pt-1 mt-1"></i></span>
                                                            <button class="btn"><i class="fa fa-desktop"
                                                                    (click)="navigate(item,'course-programOutcome')"
                                                                    style='font-size:24px' aria-hidden="true"> <span
                                                                        class="span">Program Outcome</span>
                                                                </i></button> <button class="btn"><i
                                                                    class='fa fa-user-plus'
                                                                    (click)="navigate(item,'course-assignTrainer')"
                                                                    style='font-size:24px'> <span class="span">Add
                                                                        Trainers</span> </i> </button>
                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="false">
                                            No Records to display
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="modal" id="myModal" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg ">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header py-2">
                <h6 *ngIf="!isEdit" class="modal-title w-100"> {{headingName.AddCourse}} </h6>
                <h6 *ngIf="isEdit" class="modal-title w-100"> {{headingName.EditCourse}} </h6>
                <button type="button" (click)="close()" class="close " data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row">
                            <label class="col-md-4 control-label"> {{labelName?.CourseCategory}} <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select class="form-select form-select-sm shadow-sm rounded-lg" id="sel1"
                                        formControlName="COURSE_CATEGORY_ID">
                                        <option *ngFor="let item of courses" [value]="item.COURSE_CATEGORY_ID">
                                            {{item.COURSE_CATEGORY_NAME}}
                                        </option>
                                    </select>
                                </div>

                                <div *ngIf="myForm.get('COURSE_CATEGORY_ID').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('COURSE_CATEGORY_ID').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.CourseName}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="" class="form-control" formControlName="COURSE_NAME"
                                        onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">
                                </div>
                                <div *ngIf="myForm.get('COURSE_NAME').touched">
                                    <span class="text-danger" *ngIf="myForm.get('COURSE_NAME').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.Duration}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder=""
                                        onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                        class="form-control" formControlName="Duration">
                                </div>
                                <div *ngIf="myForm.get('Duration').touched">
                                    <span class="text-danger" *ngIf="myForm.get('Duration').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.CourseFee}}<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder=""
                                        onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                        class="form-control" formControlName="COURSE_FEES">
                                </div>
                                <div *ngIf="myForm.get('COURSE_FEES').touched">
                                    <span class="text-danger" *ngIf="myForm.get('COURSE_FEES').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.Description}} </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <textarea placeholder="" class="form-control"
                                        formControlName="COURSE_DESCRIPTION"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.CourseDesignedFor}}<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="" class="form-control" formControlName="COURSE_DESIGNED_FOR"
                                        onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">
                                </div>
                                <div *ngIf="myForm.get('COURSE_DESIGNED_FOR').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('COURSE_DESIGNED_FOR').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label"> {{labelName?.PreAssessment}}</label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select class="form-select form-select-sm shadow-sm rounded-lg" id="sel1"
                                        formControlName="ISPREASSESSMENT">
                                        <option [ngValue]=false>Optional</option>
                                        <option [ngValue]=true>Mandatory </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label"> {{labelName?.InternalAssessment}}</label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select class="form-select form-select-sm shadow-sm rounded-lg" id="sel1"
                                        formControlName="ISINTERNALEXAM">
                                        <option [ngValue]=false>Optional</option>
                                        <option [ngValue]=true>Mandatory </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.IsStudyDurationRequired}} </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="" type="checkbox" formControlName="ISTUDYTIMEREQUIRED">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label">{{labelName?.CourseStatus}}</label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select class="form-select form-select-sm shadow-sm rounded-lg" id="sel1"
                                        formControlName="COURSE_STATUS">
                                        <option value=true>Active</option>
                                        <option value=false>InActive </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" (click)="onSubmit(myForm)" class="btn btn-sm  btn-save"
                    [disabled]="!myForm.valid">Save</button>
                <button type="button" *ngIf="isEdit" (click)="onSubmit(myForm)" class="btn btn-sm btn-save"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-sm btn-danger " id='md_close' (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>