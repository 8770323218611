<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">

                    <div class="container-fluid">

                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.Vehicles}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right mb-2">
                                            <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal"
                                                data-bs-target="#myModal">Add Vehicle</button>
                                        </div>
                                        <div *ngIf="table.length" class="table-responsive">
                                            <table id="patergraph" datatable
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small display">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th> Registration No.</th>
                                                        <th> Engine No. </th>
                                                        <th> Model No. </th>
                                                        <th> Color </th>
                                                        <th> Seating Capacity </th>
                                                        <th> Insurance No. </th>
                                                        <th> Insurance Expiry Date </th>
                                                        <th> Vehicle Type </th>
                                                        <th> Status </th>
                                                        <th> Action </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.registration_number}}</td>
                                                        <td>{{item.engine_number}}</td>
                                                        <td>{{item.model_number}}</td>
                                                        <td>{{item.color}}</td>
                                                        <td>{{item.seating_capacity}}</td>
                                                        <td>{{item.insurance_number}}</td>
                                                        <td>{{item.insurance_end_date | date : 'dd-MM-yyyy'}}</td>
                                                        <td>{{item.vehicle_type == 1 ? 'Owned' : 'Rented'}}</td>
                                                        <td>{{item.status}}</td>

                                                        <td style="display: flex; text-align: center;cursor: pointer;">
                                                            <!-- <input type="image"
                                                    src="./../../../assets/images/sprites/edit.svg" class="edit_icon"
                                                    data-bs-toggle="modal" data-bs-target="#myModal" (click)="edit(item.FEETYPE_ID)">  -->
                                                            <a data-bs-toggle="modal" data-bs-target="#myModal"
                                                                (click)="edit(item.vehicles_id,item.document_type)"><i
                                                                    class="zmdi zmdi-edit zmdi-hc-2x">
                                                                </i></a>&nbsp;&nbsp;
                                                            <button class="btn btn-sm btn-ai"
                                                                (click)="assign(item.vehicles_id)">Assign</button>&nbsp;&nbsp;

                                                            <button class="btn btn-sm btn-ai"
                                                                (click)="vehicleService(item.vehicles_id,item.registration_number)">Vehicle Service</button>&nbsp;&nbsp;
                                                            <!-- <button 
                                                                    class="btn btn-sm btn-ai"
                                                                    (click)="deleteVehicle(item.vehicles_id)">Delete</button>      -->
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog  modal-lg">
        <div class="modal-content">

            <div class="modal-header py-2">
                <h5 *ngIf="!isEdit" class="modal-title w-100"> {{headingName.AddVehicle}}</h5>
                <h5 *ngIf="isEdit" class="modal-title w-100"> {{headingName.EditVehicle}}</h5>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>


            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="row ">
                            <div class="col-md-6 ">
                                <div class="form-outline">
                                    <label class="form-label" for="fullName">{{labelName?.RegistrationNumber}}<span
                                            class="text-danger">*</span></label>
                                    <input placeholder="Please enter registration number " class="form-control"
                                        formControlName="registration_number"  style="text-transform:uppercase"
                                        onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));" />
                                </div>
                                <div *ngIf="myForm.get('registration_number').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('registration_number').hasError('required')">
                                        This field is required.
                                    </span>
                                    <span class="text-danger"
                                    *ngIf="myForm.get('registration_number').errors?.pattern">
                                    Please enter Valid Registration number
                                </span>


                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-outline">
                                    <label class="form-label" for="emailAddress">{{labelName?.EngineNumber}}<span
                                            class="text-danger">*</span></label>
                                    <input type="text" min="1" placeholder="Please enter engine number"
                                        formControlName="engine_number" class="form-control "
                                        onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));" />
                                </div>
                                <div *ngIf="myForm.get('engine_number').touched">
                                    <span class="text-danger" *ngIf="myForm.get('engine_number').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-md-6">
                                <div class="form-outline">
                                    <label class="form-label">{{labelName?.MakeModel}}<span class="text-danger">*</span></label>
                                    <input type="text" min="1" placeholder="Please enter model number"
                                        formControlName="model_number" class="form-control "
                                        onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));" />
                                </div>
                                <div *ngIf="myForm.get('model_number').touched">
                                    <span class="text-danger" *ngIf="myForm.get('model_number').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-outline">
                                    <label class="form-label">{{labelName?.Color}}<span class="text-danger">*</span></label>
                                    <input type="text" placeholder="Please enter color" formControlName="color"
                                        class="form-control " onkeypress="return /[a-zA-Z]/i.test(event.key)">
                                </div>
                                <div *ngIf="myForm.get('color').touched">
                                    <span class="text-danger" *ngIf="myForm.get('color').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-md-6">
                                <div class="form-outline">
                                    <label class="form-label">{{labelName?.ActualSeatingCapacity}}<span
                                            class="text-danger">*</span></label>
                                    <input type="number" pattern="^[1-9][0-9]*$" required
                                        oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
                                        placeholder="Please enter seating capacity of the vehicle"
                                        formControlName="seating_capacity" class="form-control " />
                                </div>
                                <div *ngIf="myForm.get('seating_capacity').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('seating_capacity').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-outline">
                                    <label class="form-label" for="emailAddress">{{labelName?.SantionedSeatingCapacity}}<span
                                            class="text-danger"></span></label>
                                    <input type="text" min="1" placeholder="Please enter engine number"
                                        formControlName="sanctioned_capacity" class="form-control " />
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-md-6">
                                <div class="form-outline">
                                    <label class="form-label">Sanctioned Seating Capacity</label>
                                    <input type="number" pattern="^[1-9][0-9]*$" required oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" placeholder="Please enter sanctioned seating of the vehicle"
                                        formControlName="sanctioned_capacity" class="form-control " />
                                </div>
                            </div> -->
                        <!-- </div> -->

                        <div class="row mt-2">
                            <div class="col-md-6">
                                <div class="form-outline">
                                    <label class="form-label">{{labelName?.ChassicNumber}}<span class="text-danger">*</span></label>
                                    <input type="text" placeholder="Please enter chassis number"
                                        formControlName="chassis_number"
                                        onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));"
                                        class="form-control " />
                                </div>
                                <div *ngIf="myForm.get('chassis_number').touched">
                                    <span class="text-danger" *ngIf="myForm.get('chassis_number').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-outline">
                                    <label class="form-label">{{labelName?.InsuranceNumber}}<span class="text-danger">*</span></label>
                                    <input type="text" min="1" placeholder="Please enter insurance number"
                                        formControlName="insurance_number"
                                        onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));"
                                        class="form-control " />
                                </div>
                                <div *ngIf="myForm.get('insurance_number').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('insurance_number').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-md-6">
                                <div class="form-outline">
                                    <label class="form-label">{{labelName?.InsuranceStartDate}}<span
                                            class="text-danger">*</span></label>
                                    <!-- <input placeholder="Please select insurance start date" onfocus="(this.type='date')" formControlName="insuranceStartdate" class="form-control " /> -->

                                    <input type="date" placeholder="Please select insurance start date"
                                        formControlName="insurance_start_date" class="form-control " />
                                </div>
                                <div *ngIf="myForm.get('insurance_start_date').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('insurance_start_date').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-outline">
                                    <label class="form-label">{{labelName?.InsuranceEndDate}}<span
                                            class="text-danger">*</span></label>
                                    <input type="date" placeholder="Please select insurance end date"
                                        formControlName="insurance_end_date" class="form-control " />
                                </div>
                                <div *ngIf="myForm.get('insurance_end_date').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('insurance_end_date').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-md-6">
                                <div class="form-outline">
                                    <label class="form-label">{{labelName?.Remarks}}</label>
                                    <input type="text" placeholder="Please enter remarks" formControlName="remarks"
                                        class="form-control " />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-outline">
                                    <label class="form-label">{{labelName?.vehicleType}}<span class="text-danger">*</span></label>
                                    <select formControlName="vehicle_type" class="form-select">
                                        <option value="" [selected]="true">select</option>
                                        <option [value]="1">Owned</option>
                                        <option [value]="2">Rented</option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('vehicle_type').touched">
                                    <span class="text-danger" *ngIf="myForm.get('vehicle_type').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-6">
                                <div class="form-outline">
                                    <label class="form-label">{{labelName?.FitnessStartDate}}<span
                                            class="text-danger"></span></label>
                                    <!-- <input placeholder="Please select insurance start date" onfocus="(this.type='date')" formControlName="insuranceStartdate" class="form-control " /> -->

                                    <input type="date" placeholder="Please select insurance start date"
                                        formControlName="fitness_start_date" class="form-control " />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-outline">
                                    <label class="form-label">{{labelName?.FitnessEndDate}}<span class="text-danger"></span></label>
                                    <input type="date" placeholder="Please select insurance end date"
                                        formControlName="fitness_end_date" class="form-control " />
                                </div>
                            </div>
                        </div>


                        <div class="row mt-2">
                            <div class="col-md-6">
                                <div class="form-outline">
                                    <label class="form-label">{{labelName?.RoadtaxStartDate}}<span
                                            class="text-danger"></span></label>
                                    <!-- <input placeholder="Please select insurance start date" onfocus="(this.type='date')" formControlName="insuranceStartdate" class="form-control " /> -->

                                    <input type="date" placeholder="Please select insurance start date"
                                        formControlName="road_tax_start_date" class="form-control " />
                                </div>

                            </div>
                            <div class="col-md-6">
                                <div class="form-outline">
                                    <label class="form-label">{{labelName?.RoadtaxEndDate}}<span class="text-danger"></span></label>
                                    <input type="date" placeholder="Please select insurance end date"
                                        formControlName="road_tax_end_date" class="form-control " />
                                </div>

                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-md-6">
                                <div class="form-outline">
                                    <label class="form-label">{{labelName?.PermitStartDate}}<span class="text-danger"></span></label>
                                    <!-- <input placeholder="Please select insurance start date" onfocus="(this.type='date')" formControlName="insuranceStartdate" class="form-control " /> -->

                                    <input type="date" placeholder="Please select insurance start date"
                                        formControlName="permit_start_date" class="form-control " />
                                </div>

                            </div>
                            <div class="col-md-6">
                                <div class="form-outline">
                                    <label class="form-label">{{labelName?.PermitEndDate}}<span class="text-danger"></span></label>
                                    <input type="date" placeholder="Please select insurance end date"
                                        formControlName="permit_end_date" class="form-control " />
                                </div>

                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-6">
                                <div class="form-outline">
                                    <label class="form-label">{{labelName?.FileToUpload}}<span class="text-danger">*</span></label>
                                    <input (change)='changeFile($event)' type="file"
                                    #materialFile id="materialFile1" placeholder=""
                                    class="form-control">
                                    <p *ngIf="isEdit">{{attachment}}</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-outline">
                                    <label class="form-label">{{labelName?.Status}}<span class="text-danger">*</span></label>
                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                        formControlName='status'>
                                        <option value="" selected>Select</option>
                                        <option value=true>Active</option>
                                        <option value=false>Inactive</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-success" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-success" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-danger" id='md_close' (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>