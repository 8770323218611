import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment'
import { constants } from '../constants';
//const url = environment.serviceUrl
@Injectable({
  providedIn: 'root'
})
export class PolsService {
  url = environment.serviceUrl
  userId = localStorage.getItem('UserId');
  constructor(private http: HttpClient) {
    if (location.hostname != "localhost")
      this.url = `${location.origin}/api/`;
  }

  getPolls() {
    let apiUrl = constants['Getpolls'] || 'Getpolls'
    let uri = this.url + apiUrl + '/' + this.userId;
    return this.http.get(uri)
  }

  getPollQuestions(id) {
    let apiUrl = constants['GetPollQuestions'] || 'GetPollQuestions'
    let uri = this.url + apiUrl + '/' + this.userId + '/' + id
    return this.http.get(uri)
  }
  savePolls(data) {
    let apiUrl = constants['SavePolls'] || 'SavePolls'
    let uri = this.url + apiUrl;
    data.UserId = this.userId
    return this.http.post(uri, data)
  }

  ///////////////////////////survey///////////////////////////
  getSurveys() {
    let apiUrl = constants['GetSurveys'] || 'GetSurveys'
    let uri = this.url + apiUrl + '/' + this.userId;
    return this.http.get(uri)
  }

  getSurveyQuestions(id) {
    let apiUrl = constants['GetSurveyQuestions'] || 'GetSurveyQuestions'
    let uri = this.url + apiUrl + '/' + this.userId + '/' + id
    return this.http.get(uri)
  }

  setSurveys(data) {
    // SetSurveys
    let apiUrl = constants['SetSurveys'] || 'SetSurveys'
    let uri = this.url + apiUrl;
    data.UserId = this.userId
    return this.http.post(uri, data)
  }
  ///////////////////////////survey///////////////////////////



}
