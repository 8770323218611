import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-print-employee-idcard',
  templateUrl: './print-employee-idcard.component.html',
  styleUrls: ['./print-employee-idcard.component.css']
})
export class PrintEmployeeIdcardComponent implements OnInit {

  idCardList : Array<any>=[];
  headingName: any={};
  workItemId: any;
  idCardPayload: any = {};
  constructor(private toster: ToastrService,private CommonService:CommonService,private active : ActivatedRoute) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadHeadings();
   }

  ngOnInit(): void {
    if (sessionStorage.idcardData) {
      this.idCardPayload = JSON.parse(sessionStorage.idcardData);
    }
    this.getIdCards();
  }

    activeSpinner() {
    this.CommonService.activateSpinner();
  }
  
  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  getIdCards() {
    this.activeSpinner();
    this.idCardList = [];
    this.CommonService.postCall('EmsGenerateIDCards/CreateStudents', this.idCardPayload).subscribe((res: any) => {
      this.idCardList = res;
      this.deactivateSpinner();
      // sessionStorage.idcardData = JSON.stringify(this.idcardData);
      // this.router.navigate([`home/nitIdCard/${this.workItemId}`]);
    }, err => {
      this.toster.error(err.error ? err.error : 'Not created')
      this.deactivateSpinner();
    });
    // this.deactivateSpinner();
  }

  Print() {
    var printContents: any = document.getElementById("print")?.innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    // window.history.back();

    // const printContent = document.getElementById("print");
    // const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
    // WindowPrt.document.write(printContent.innerHTML);
    // WindowPrt.document.close();
    // WindowPrt.focus();
    // WindowPrt.print();
    // WindowPrt.close();
  }
  
  back(){
    window.history.back();
  }

}