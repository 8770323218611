import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-room',
  templateUrl: './room.component.html',
  styleUrls: ['./room.component.css']
})
export class RoomComponent extends BaseComponent implements OnInit {
  myForm: FormGroup;
  table: Array<any> = [];
  isEdit: boolean = false;
  editData: any;
  block: Array<any> = [];
  campus: Array<any> = [];
  roomtype: Array<any> = [];
  floor: Array<any> = [];
  labelName: any = {};
  headingName: any = {};
  workItemId: any;
  constructor(private fb: FormBuilder,private active : ActivatedRoute, CommonService: CommonService, toastr: ToastrService) {
    super(CommonService, toastr)
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadlabels();
    this.loadRoom();
    this.campusDropdown();
    this.roomType();
    this.loadHeadings();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      campus_id: ['', [Validators.required,]],
      block_id: ['', [Validators.required,]],
      floor_id: ['', [Validators.required,]],
      room_area: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      room_number: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      room_type_name: ['', [Validators.required,]],
      room_capacity: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],

    })
  }

  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  loadRoom() {
    let payload = {
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('EmsRoom/GetList', payload).subscribe((res: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = res;
      }, 10)
    })
  }

  campusDropdown() {
    let payload = {
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('EmsCampus/CampusDropDownList', payload).subscribe((res: any) => {
      this.campus = res;
    })
  }
  campusChange(id) {
    let payload = {
      "campus_id": id
    }
    this.CommonService.postCall('EmsBlock/BlockDropDownList', payload).subscribe((res: any) => {
      this.block = res;
    })
  }
  blockChange(id) {
    let payLoad = {
      "block_id": id
    }
    this.CommonService.postCall('EmsFloor/FloorDropDownList', payLoad).subscribe((res: any) => {
      this.floor = res;
    })
  }
  roomType() {
    let payload = {

    }
    this.CommonService.getCall(`EmsRoomTypesDetails/EmsRoomTypesDropDownList/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.roomtype = res;
    })
  }
  onSubmit(form: FormGroup) {
    let value: any = form.value;
    value.tnt_code = localStorage.getItem('TenantCode');
    value.created_by = localStorage.getItem('UserId');
    if (this.isEdit) {
      value.modified_by = localStorage.getItem('UserId');
      value['room_id'] = this.editData.room_id;
      this.CommonService.postCall('EmsRoom/Update', value).subscribe((res: any) => {
        this.loadRoom();
        this.toastr.success("Updated Successfully");
        document.getElementById('md_close')?.click();

      }, err => {
        this.toastr.error(err.error ? err.error : 'Not Updated')
      })
    } else {
      this.CommonService.postCall('EmsRoom/Create', value).subscribe((res: any) => {
        this.loadRoom();
        this.toastr.success(" Created Succuessfully");
        document.getElementById('md_close')?.click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Not created')

      })
    }
  }
  edit(roomId) {
    this.isEdit = true;
    this.myForm.reset();
    let payLoad = {
      "room_id": roomId
    }
    this.CommonService.postCall('EmsRoom/Get', payLoad).subscribe((res: any) => {
      if (res instanceof Array && res.length) {
        this.editData = res[0];
        this.dataTransform();
      } else {
        this.editData = res;
        this.dataTransform()
      }
      this.campusChange(this.editData['campus_id']);
      this.blockChange(this.editData['block_id']);
      this.dataTransform();
    })
  }

  dataTransform() {
    let ctrls = this.myForm.controls
    Object.keys(ctrls).map((key) => {
      let ctrl: AbstractControl = ctrls[key];
      ctrl.setValue(this.editData[key])
    })
  }
  close() {
    this.myForm.reset();
    this.isEdit = null;
    this.editData = {};
    this.campusChange(this.editData['campus_id']);
    this.blockChange(this.editData['block_id']);
  }
}
