<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">

                    <div class="container-fluid">

                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.StaffAttendence}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <form class="well form-horizontal" [formGroup]="myForm"
                                            (ngSubmit)="onSubmit(myForm)">
                                            <fieldset>
                                                <div class="row">
                                                    <div class="form-group col-md-3">
                                                        <label>{{labelName.SelectDate}}</label>
                                                            <div class="input-group">
                                                                <input placeholder="Select Month" (change)="getStaffList($event.target.value)"
                                                                    class="form-control form-control-sm shadow-sm rounded-lg" [max]="todayDate"
                                                                    type="date" formControlName="date">
                                                          
                                                        </div>
                                                    </div>



                                                    <!-- <div class="col-md-3 pt-4">
                                                        <button type="button" [disabled]="myForm.invalid" (change)="getStaffList($event)"
                                                            (click)="onSubmit(myForm)" class="btn btn-sm  btn-save"><i
                                                                class="fas fa-search"></i></button>
                                                    </div> -->
                                                </div>
                                            </fieldset>
                                        </form>
                                        <br>

                                        <div class="text-center">
                                            <p *ngIf="!isTable" style="color: red;">
                                                Search for Staff to post attendance!</p>
                                        </div>

                                        <div *ngIf="table.length">
                                            <table id="addStudent" datatable 
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th><input class="form-check-input" type="checkbox" value="" *ngIf="!selectAll"
                                                                (change)="checkAll($event)" id=""></th>
                                                        <th> Name </th>
                                                        <th> Designation </th>
                                                        <th> Status </th>
                                                        <th> Action </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table; let i = index">
                                                        <td >  <input class="form-check-input" [(ngmodel)]="item.checked" *ngIf="!item.checkstatus"
                                                            [checked]="item.checked"
                                                            (change)="checkAttendance(item,$event,i)"
                                                            type="checkbox" value="{{item.people_id}}" id=""></td>
                                                        <td>{{item.person_name}}</td>
                                                        <td>{{item.person_designation}}</td>
                                                        <td>{{item.attendance_status }}</td>
                                                        <td> <button *ngIf="item.attendance_status == 'Present'"
                                                                class="btn btn-sm btn-ai"
                                                                (click)="changeAttendance(item.people_id,item.staff_attendance_id,'Absent')">Absent</button>
                                                            <button *ngIf="item.attendance_status == 'Absent'"
                                                                class="btn btn-sm btn-ai"
                                                                (click)="changeAttendance(item.people_id,item.staff_attendance_id,'Present')">Present</button>
                                                        </td>

                                                    </tr>
                                                </tbody>

                                            </table>
                                        </div>

                                      

                                        <div class="float-end pt-4">
                                            <button type="button" (click)="onSubmit(myForm)" *ngIf="isCheck"
                                                class="btn btn-sm  btn-save">Submit</button>
                                            &nbsp;&nbsp;
                                            <button type="button" class="btn btn-sm btn-danger" (click)="close()"
                                                id="close">cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>