<br>
<div class="page-wrapper">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.DriverHealthconfiguration}} </h5>
                                    </div>
                                    <div class="card-body">
                                        <div id="test-l-3" class="content text-center">

                                            <!-- <div class="row">
                                                <div class="col-xl-8 offset-xl-2">
                                                    <table class="table-1 table table-bodered w-100">

                                                        <tr>
                                                            <th> Max Alcohol Level </th>

                                                            <th> Max Temparature Level </th>


                                                        </tr>

                                                    </table>
                                                </div>
                                            </div> -->

                                            <form class="well form-horizontal" [formGroup]="myForm"
                                                (ngSubmit)="onSubmit(myForm)">
                                                <fieldset>

                                                    <table class="table-data"
                                                        style=" width:95%;  margin-left: auto;  margin-right: auto; ">
                                                        <tr class="text-center">
                                                            <th style="padding: 0.5rem;">{{labelName?.MaxAlcholLevel}}</th>
                                                            <th style="text-align:center; padding: 0.5rem;">{{labelName?.MaxTemperatureLevel}}</th>

                                                        </tr>
                                                        <tr class="text-center">
                                                            <td
                                                                style="border-right: 1px solid #5E5E5E; padding: 0.5rem; font-weight: 500; color: #5E5E5E;">
                                                                <input type="text" class="form-control"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                                                    formControlName="max_alcohol_level">
                                                                    <div *ngIf="myForm.get('max_alcohol_level').touched">
                                                                        <span class="text-danger"
                                                                            *ngIf="myForm.get('max_alcohol_level').hasError('required')">
                                                                            This field is required.
                                                                        </span>
                        
                                                                    </div>
                                                            </td>

                                                            <td
                                                                style="text-align:center;border-right: 1px solid #5E5E5E; padding: 0.5rem; font-weight: 500; color: #5E5E5E;">
                                                                <input type="text" class="form-control"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                                                    formControlName="max_temparature_level">
                                                                    <div *ngIf="myForm.get('max_temparature_level').touched">
                                                                        <span class="text-danger"
                                                                            *ngIf="myForm.get('max_temparature_level').hasError('required')">
                                                                            This field is required.
                                                                        </span>
                        
                                                                    </div>
                                                            </td>

                                                        </tr>



                                                    </table>
                                                </fieldset>
                                            </form>
                                        </div>
                                        <div class="float-end mb-1 pt-4">
                                            <button type="button" (click)="onSubmit(myForm)" class="btn btn-sm  btn-save" [disabled]="myForm.invalid">Update</button>&nbsp;
                                            <button type="button" (click)="close()" class="btn btn-sm btn-danger"
                                                id='md_close'>Cancel</button>
                                        </div>


                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</div>