import { Component, OnInit } from '@angular/core';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'app-bonafied-certificate',
  templateUrl: './bonafied-certificate.component.html',
  styleUrls: ['./bonafied-certificate.component.css']
})
export class BonafiedCertificateComponent implements OnInit {
  cources: Array<any> = [];
  students: Array<any> = [];
  usersData: any = {};
  users: Array<any> = [];
  receipt: boolean = false;
  certificate: boolean = false;
  purpose: any;
  remarks: any;
  labelName: any = {};
  fileName: any = '';
  myDate = Date.now();
  headingName: any = {};
  workItemId: any;
  bonafied: boolean = false;
  userId: any;
  Enrollment_Number: string = "";
  enroll: any;
  tId:any
  dobInWords: string;
  tenanates:Array<any>=[];
  roleId = localStorage.getItem('RoleId');
  tenantCode = localStorage.getItem('TenantCode');

  
  myControl = new FormControl('');
  show: boolean = false;
  currentValue: string = "";
  stuSearchArr: Array<any> = [];
  selectedUid: Array<any> = [];
  // options:string[]=["truck","motorcycle","car","jeep","excavator","airplane","train","cycle"];

  constructor(private CommonService: CommonService, private active: ActivatedRoute) {
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
    // this.loadcourses();
    this.loadlabels();
    this.loadHeadings();
    this.tntLoad();
    // this.dateFormat();
    if (this.roleId == '1') {
      this.getCourses(this.tenantCode);
    }
  }

  ngOnInit(): void {

  }
  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  // dateFormat() {
  //   var wDays = ['first', 'second', 'third', 'fourth', 'fifth', 'sixth', '	seventh', 'eighth', 'ninth', 'tenth', 'eleventh', 'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth', 'twentieth', 'twenty-first', 'twenty-second', 'twenty-third', 'twenty-fourth', 'twenty-fifth', 'twenty-sixth', 'twenty-seventh', 'twenty-eighth', 'twenty-ninth', 'thirtieth', 'thirty-first']

  //   var wMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  //   var wNumbers = ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen', 'twenty', 'twentyone']
  //   var date: any = new Date(this.usersData.dob);
  //   var day = parseInt(date.getUTCDate());
  //   var month = parseInt(date.getUTCMonth());
  //   var year = date.getUTCFullYear().toString();

  //   var x = year.charAt(0)
  //   var xx = year.charAt(1)
  //   var xxx = year.charAt(2)
  //   var xxxx = year.charAt(3)


  //   var a = parseInt(x + xx) - 1
  //   var b = parseInt(xxx) - 1
  //   var c = parseInt(xxxx) - 1
  //   this.dobInWords = wDays[day] + ' ' + wMonths[month] + ' ' + wNumbers[a] + ' ' + wNumbers[b] + ' ' + wNumbers[c]
  //   console.log(wDays[day] + ' ' + wMonths[month] + ' ' + wNumbers[a] + ' ' + wNumbers[b] + ' ' + wNumbers[c])
  // }


  dateFormat() {
    var th = ['', 'thousand', 'million', 'billion', 'trillion'];
    // uncomment this line for English Number System
    // var th = ['','thousand','million', 'milliard','billion'];

    var dg = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
    var tn = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
    var tw = ['twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

    function toWords(s) {
      s = s.toString();
      s = s.replace(/[\, ]/g, '');
      if (s != parseFloat(s)) return 'not a number';
      var x = s.indexOf('.');
      if (x == -1) x = s.length;
      if (x > 15) return 'too big';
      var n = s.split('');
      var str = '';
      var sk = 0;
      for (var i = 0; i < x; i++) {
        if ((x - i) % 3 == 2) {
          if (n[i] == '1') {
            str += tn[Number(n[i + 1])] + ' ';
            i++;
            sk = 1;
          } else if (n[i] != 0) {
            str += tw[n[i] - 2] + ' ';
            sk = 1;
          }
        } else if (n[i] != 0) {
          str += dg[n[i]] + ' ';
          if ((x - i) % 3 == 0) str += 'hundred ';
          sk = 1;
        }
        if ((x - i) % 3 == 1) {
          if (sk) str += th[(x - i - 1) / 3] + ' ';
          sk = 0;
        }
      }
      if (x != s.length) {
        var y = s.length;
        str += 'point ';
        for (var i: number = x + 1; i < y; i++) str += dg[n[i]] + ' ';
      }
      return str.replace(/\s+/g, ' ');
    }

    var stu = this.usersData.dob.split(' ')[0].split('-');
    var wMonths = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']

    var final = toWords(stu[2]) + " " + wMonths[parseInt(stu[1]) - 1] + " " + toWords(stu[0])
    this.dobInWords = final
    console.log(final);
    console.log(this.usersData.dob.toLocaleString());
    
  }






  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }


  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }



 
  tntLoad() {
    let payload = {
      "RoleId": this.roleId,
      "TENANT_CODE": this.tenantCode
    }
    this.CommonService.postCall('Registration/GetTenantByRoleId', payload).subscribe((res: any) => {
      this.tenanates = res;
    })
  }
  // getCourses() {
  //   this.activeSpinner()
  //   this.CommonService.getAdminCourses().subscribe((res: any) => {
  //     this.cources = res;
  //     this.deactivateSpinner()
  //   }, e => {
  //     this.deactivateSpinner()
  //   })
  // }
  getCourses(tId: any) {
    this.activeSpinner()
    this.CommonService.getCall(`Courses/GetAdminCourses/${localStorage.getItem('UserId')}/${localStorage.getItem('RoleId')}/${tId || localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.cources = res;
      this.deactivateSpinner()
    }, e => {
      this.deactivateSpinner()
    })
  }

  courceChange(event: any) {
    let courseId = event.target.value;
    this.students = [];
    this.stuSearchArr = [];
    let payload = {
      "courseid": courseId,
      "tenantcode":this.tId||localStorage.getItem('TenantCode'),
    }
    this.CommonService.postCall('Registration/LoadStudentsByCourse', payload).subscribe((response: any) => {
      this.students = response;
      this.stuSearchArr = this.students.map(a => a.name);
    })
  }

  generateBonafied() {
    this.receipt = true;
    this.certificate = false;
    this.CommonService.getCall(`Registration/LoadUserDetails/${this.userId}`).subscribe((response: any) => {
      this.usersData = response;
      this.fileName = response.userfrimages;
      this.getImage();
      // this.dateFormat();
      this.dateFormat();
    })
  }

  duplicateBonafied() {
    this.certificate = true;
    this.receipt = false;
    this.CommonService.getCall(`Registration/LoadUserDetails/${this.userId}`).subscribe((response: any) => {
      this.usersData = response;
      this.fileName = response.userfrimages;
      this.getImage();
    })
  }


  getImage() {
    if (!this.fileName) return ''
    if (this.fileName.includes('data:image')) {
      return this.fileName
    } else {
      return 'data:image/png;base64,' + this.fileName
    }
  }
  getUsersData(event: any) {
    this.bonafied = true
    // this.userId = event.target.value;
    this.Enrollment_Number = event.target.value;
  }

  isDisabled(val1: number, val2: number): boolean {
    if ((val1 > 0) || (val2 > 0))
      return false;
    // else if (val2 > 0)
    //   return false;
    // else if (val1 == 0 && val2 == 0)
    //   return true;
    else
      return true;


  }

  enrollNumber(event: any) {
    this.enroll = event.target.value;
    this.CommonService.getCall(`Registration/LoadUserDetailsByEnrollmentNo/${this.enroll}`).subscribe((res: any) => {
      this.usersData = res;
      this.dateFormat();
    })
   
  }
  convetToPDF() {
    var data: any = document.getElementById('printtable');
    html2canvas(data).then((canvas: any) => {
      // Few necessary setting options
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;

      const contentDataURL = canvas.toDataURL('image/png');
      let pdf: any = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      pdf.save('bonafied.pdf'); // Generated PDF
      // window.close();
    });

  }
  print() {
    var printContents: any = document.getElementById("printtable")?.innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  }



  convetToPDF1() {
    var data: any = document.getElementById('printtable1');
    html2canvas(data).then((canvas: any) => {
      // Few necessary setting options
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;

      const contentDataURL = canvas.toDataURL('image/png');
      let pdf: any = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      pdf.save('bonafied.pdf'); // Generated PDF
      // window.close();
    });

  }
  print1() {
    var printContents: any = document.getElementById("printtable1")?.innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  }

  
  optionselected(option: string) {
    console.log(option);
    this.currentValue = option;
    this.userId = this.students.find(a => a.name == option).userid
    this.show = false;
  }
  handleFocusIn() {
    this.show = true;
    console.log("status show" + this.show);
  }
  submit() { }
  clear() { }

}
