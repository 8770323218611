<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.LeaveList}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right mb-1">
                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                                data-bs-target="#myModal">Add Apply Leave</button>
                                        </div>
                                        <div class="row">
                                            <div *ngIf='isSuperAdmin' class="col-md-3">
                                                <label for="sel1">{{labelName.TenantName}} : </label>
                                                <select [(ngModel)]='tId ' (change)="load()"
                                                    class="form-control form-control-sm shadow-sm rounded-lg"
                                                    id="sel1">
                                                    <option value="" [selected]="true">select</option>
                                                    <option *ngFor="let item of tenanates" [value]='item.TNT_CODE'>
                                                        {{item.TNT_NAME}}</option>
                                                </select>

                                            </div>
                                        <div *ngIf="table.length">
                                            <table id="patergraph" datatable
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th>S.No </th>
                                                        <th>Name</th>
                                                        <th>Designation</th>
                                                        <th>Leave Type</th>
                                                        <th>From Date </th>
                                                        <th>To Date</th>
                                                        <th>Status</th>
                                                        <th>Reason</th>
                                                        <th>Action</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table;let i = index;">
                                                        <td>{{i+1}}</td>
                                                        <td>{{item.name}}</td>
                                                        <td>{{item.role_name}}</td>
                                                        <td>{{item.leavetypes_leavename }}</td>
                                                        <td>{{item.from_date | date : 'dd/MM/yyyy'}}</td>
                                                        <td>{{item.to_date | date : 'dd/MM/yyyy'}}</td>
                                                        <td>{{item.leaves_status}}</td>
                                                        <td>{{item.title}}</td>
                                                        <td style="display: flex;" *ngIf="leaves_status == Pending">
                                                            <span data-bs-toggle="modal" data-bs-target="#myModal"
                                                                src="" class="edit_icon" 
                                                                (click)="edit(item.leaves_id)">
                                                                <i  class="fas fa-pen pt-1 mt-1"></i></span>                                                           
                                                        </td>
                                                    </tr>
                                                </tbody>

                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




<div class="modal" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog  modal-lg">
        <div class="modal-content">

            <div class="modal-header py-2">
                <h5 *ngIf="!isEdit" class=" modal-title w-100">{{headingName.AddApplyLeaves}}</h5>
                <h5 *ngIf="isEdit" class=" modal-title w-100">{{headingName.EditApplyLeaves}}</h5>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>


            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row">
                            <label class="col-md-4 control-label ">{{labelName.LeaveTypes}}<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <select class="form-control form-control-sm shadow-sm rounded-lg"
                                        formControlName="leave_type_id">
                                        <option value="" selected>Select</option>
                                        <option *ngFor="let item of leaveType" value="{{item.leavetypes_id}}">{{item.leavetypes_leavename}} </option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('leave_type_id').touched">
                                    <span class="text-danger" *ngIf="myForm.get('leave_type_id').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label ">{{labelName.Title}}<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <input type="text" placeholder="" class="form-control" formControlName="title"
                                    onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">
                                </div>
                                <div *ngIf="myForm.get('title').touched">
                                    <span class="text-danger" *ngIf="myForm.get('title').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label ">{{labelName.Description}}<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <textarea type="text" placeholder="" class="form-control" rows="3" formControlName="description"></textarea>
                                </div>
                                <div *ngIf="myForm.get('description').touched">
                                    <span class="text-danger" *ngIf="myForm.get('description').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label ">{{labelName.FromDate}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <input type="date" min="{{minDate}}" placeholder="" class="form-control" formControlName="from_date" />
                                </div>
                                <div *ngIf="myForm.get('from_date').touched">
                                    <span class="text-danger" *ngIf="myForm.get('from_date').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label ">{{labelName.ToDate}}<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <input type="date" min="{{minDate}}" placeholder="" class="form-control" formControlName="to_date">
                                </div>
                                <div *ngIf="myForm.get('to_date').touched">
                                    <span class="text-danger" *ngIf="myForm.get('to_date').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>

                    </fieldset>
                </form>
            </div>

            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn  btn-sm btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-sm btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-sm btn-danger" id='md_close' (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>