import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-items-report',
  templateUrl: './items-report.component.html',
  styleUrls: ['./items-report.component.css']
})
export class ItemsReportComponent implements OnInit {
  table: Array<any> = [];
  constructor(private CommonService: CommonService) {
    this.load();
  }

  ngOnInit(): void {
  }
  load() {
    let payload = {

    }
    this.CommonService.postCall('', payload).subscribe((res: any) => {
      this.table = res;
    })
  }
}
