import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-transport-slabs',
  templateUrl: './transport-slabs.component.html',
  styleUrls: ['./transport-slabs.component.css']
})
export class TransportSlabsComponent implements OnInit {
  myForm: FormGroup;
  table: Array<any> = [];
  isEdit: boolean = false;
  editData: any;
  labelName: any = {};

  slabId: any;
  headingName: any = {};
  workItemId: any;
  constructor(private fb: FormBuilder,private active : ActivatedRoute, private CommonService: CommonService, private toastr: ToastrService) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.load();
    this.getLabels();
    this.loadHeadings();

  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      transport_slabs_from: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      transport_slabs_to: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      price: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      status: ['', Validators.required]
    })
  }
  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  load() {
    let payload = {
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('Transport/GetTransportSlabsList', payload).subscribe((res: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = res;
      }, 10);
    })
  }
  onSubmit(form: FormGroup) {
    let value: any = form.value;
    value.tnt_code = localStorage.getItem('TenantCode');
    value.created_by = localStorage.getItem('UserId');
    if (this.isEdit) {
      value.modified_by = localStorage.getItem('TenantCode');
      value['transport_slabs_id'] = this.editData.transport_slabs_id;
      this.CommonService.postCall('Transport/UpdateTransportSlabs', value).subscribe((res: any) => {
        this.load();
        this.toastr.success("Updated Successfully");
        document.getElementById('md_close')?.click();
      }, err => {
        this.toastr.warning(err.error ? err.error : 'Not Updated')
      })

    }
    else {
      this.CommonService.postCall('Transport/CreateTransportSlabs', value).subscribe((res: any) => {
        this.load()
        this.toastr.success("Created Successfully");
        document.getElementById('md_close')?.click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Not Created')
      })
    }
  }


  edit(slabID: any) {
    this.slabId = slabID;
    this.isEdit = true;
    let payload = {
      "transport_slabs_id": slabID
    }
    this.CommonService.postCall('Transport/GetTransportSlabs', payload).subscribe((res: any) => {

      this.editData = res;
      this.dataTransfer(res);
    })
  }

  dataTransfer(data) {
    this.myForm.patchValue({
      transport_slabs_from: this.editData['transport_slabs_from'],
      transport_slabs_to: this.editData['transport_slabs_to'],
      price: this.editData['price'],
      status: this.editData['status']
    })
  }
  close() {
    this.myForm.reset();
    this.isEdit = false;
    this.editData = {};
  }
}
