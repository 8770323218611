<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">Block/Buildings</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right mb-1">
                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                                data-bs-target="#myModal">Add</button>
                                        </div>
                                        <div *ngIf="table.length">
                                            <table id="patergraph" datatable
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <!-- <th>S.No </th> -->
                                                        <th>Campus</th>
                                                        <th>Block Name</th>
                                                        <th> In-Charge</th>
                                                        <th>Status</th>
                                                        <th>Action</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <!-- <td></td> -->
                                                        <td>{{item.campus_name}}</td>
                                                        <td>{{item.block_name}}</td>
                                                        <td>{{item.incharge}}</td>
                                                        <td>{{item.block_status == true ? "Active" : "Inactive"}}</td>
                                                        <td style="display: flex; cursor: pointer;">
                                                            <span data-bs-toggle="modal" data-bs-target="#myModal"
                                                                src="" class="edit_icon" (click)="edit(item.block_id)">
                                                                <i class="fas fa-pen pt-1 mt-1"></i></span>
                                                        </td>

                                                    </tr>
                                                </tbody>

                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




<div class="modal" id="myModal" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <div class="modal-header py-2">
                <h6 *ngIf="!isEdit" class=" modal-title w-100">Add Block/Buldings</h6>
                <h6 *ngIf="isEdit" class=" modal-title w-100"> Edit Block/Buldings</h6>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>


            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row">
                            <label class="col-md-4 control-label ">{{labelName.Campus}}<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <select class="form-select form-select-sm shadow-sm rounded-lg" formControlName="campus_id">
                                        <option value='' [selected]="true">Select</option>
                                        <option *ngFor="let item of campusDropdown" [value]="item.campus_id">{{item.campus_name}}</option>                                        
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('campus_id').touched">
                                            <span class="text-danger" *ngIf="myForm.get('campus_id').hasError('required')">
                                                This field is required.
                                            </span>
                                        </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label">
                                {{labelName.BlockName}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <input class="form-control" type="text" formControlName="block_name"onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));" >
                                </div>
                                <div *ngIf="myForm.get('block_name').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('block_name').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label">
                                {{labelName.Role}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <select class="form-select form-select-sm shadow-sm rounded-lg" formControlName="designation_id" (change)="inCharge($any($event.target).value)">
                                     
                                        <option value='' [selected]="true">Select</option>
                                        <option *ngFor="let item of loadDesignationDropdown" [value]="item.ROLE_ID">{{item.ROLE_NAME}}</option> 
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('designation_id').touched">
                                        <span class="text-danger" *ngIf="myForm.get('designation_id').hasError('required')">
                                            This field is required.
                                        </span>
                                    </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label">{{labelName.InCharge}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <select class="form-select form-select-sm shadow-sm rounded-lg" formControlName="incharge_id">
                                        <option value=''  [selected]="true">Select</option>
                                        <option *ngFor="let item of incharge" [value]="item.USERID">{{item.FIRSTNAME}}</option> 
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('incharge_id').touched">
                                    <span class="text-danger" *ngIf="myForm.get('incharge_id').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4"> 
                            <label class="col-md-4 control-label">{{labelName.Status}}<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                        formControlName="block_status">
                                        <option value='' [selected]="true">Select</option>
                                        <option value=true>Active</option>
                                        <option value=false>InActive </option>
                                    </select>
                                </div>
                            </div>
                        </div>





                    </fieldset>
                </form>
            </div>

            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-sm btn-danger" id='md_close' (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>


        </div>

    </div>

</div>