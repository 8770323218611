<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.Items}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right mb-2">
                                            <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal"
                                                data-bs-target="#myModal" (click)="add()">Add Item</button>
                                        </div>
                                        <div class="pt-2" *ngIf="table.length">
                                            <table datatable id="DataTables_Table_0"
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #0d9bc4; color:#ffffff;">
                                                    <tr>
                                                        <th>Item Name </th>
                                                        <th>Quantity Hand </th>
                                                        <th>Quantity In Request</th>
                                                        <th>Category</th>
                                                        <th>Unit</th>
                                                        <th>Item Type</th>
                                                        <th>Item Nature </th>
                                                        <th>Item Code</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.item_name}}</td>
                                                        <td>{{item.quantity_hand}}</td>
                                                        <td>{{item.request_quantity}}</td>
                                                        <td>{{item.course_category_name}}</td>
                                                        <td>{{item.unit}}</td>
                                                        <td>{{item.item_type}}</td>
                                                        <td>{{item.item_nature}}</td>
                                                        <td>{{item.item_code}}</td>
                                                        <td style="display: flex;">
                                                            <span data-bs-toggle="modal" data-bs-target="#myModal"
                                                                src="" class="edit_icon" (click)="edit(item.item_id)">
                                                                <i class="fas fa-pen pt-1 mt-1"></i></span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog  modal-lg">
        <div class="modal-content">

            <div class="modal-header py-2">
                <h6 *ngIf="!isEdit" class="modal-title w-100"> {{headingName.AddItems}}</h6>
                <h6 *ngIf="isEdit" class="modal-title w-100"> {{headingName.EditItems}}</h6>

                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>
            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <!-- <div>
                            <div class="form-group row pt-4">
                                <label class="col-md-4 control-label line_2 rem_1">
                                    Select Item <span class="text-danger">*</span></label>
                                <div class="col-md-8 inputGroupContainer">
                                    <div class="input-group">
                                        <strong>:</strong> &nbsp;
                                        <select 
                                          (change)="Items()"
                                            class="form-control form-control-sm shadow-sm rounded-lg">
                                            <option value="" [selected]=true>Select Item</option>
                                          <option value="0">Create New Item</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div> -->

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label ">{{labelName.Itemname}}<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <input type="text" placeholder="" class="form-control" formControlName="item_name" 
                                    onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">
                                </div>
                                <div *ngIf="myForm.get('item_name').touched">
                                    <span class="text-danger" *ngIf="myForm.get('item_name').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label ">{{labelName.Description}}<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <input type="text" placeholder="" class="form-control"
                                        formControlName="description" onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">
                                </div>
                                <div *ngIf="myForm.get('description').touched">
                                    <span class="text-danger" *ngIf="myForm.get('description').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName.Category}}<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select (change)="category()" formControlName="category_id"
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" [selected]=true>Select</option>
                                       <option [value]="item.items_categories_id " *ngFor="let item of categoryDropdown">{{item.name}}</option>

                                    </select>
                                </div>
                                <div *ngIf="myForm.get('category_id').touched">
                                    <span class="text-danger" *ngIf="myForm.get('category_id').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>

                        <!-- <div class="form-group row pt-4" *ngIf="isCategory">
                            <label class="col-md-4 control-label ">New Category<span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <input type="text" placeholder="" class="form-control"
                                    formControlName="category_id">
                                </div>
                            </div>
                        </div> -->

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName.SelectUnit}}<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select (change)="unit()" formControlName="unit"
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                       <option [value]="item.item_unit_id" *ngFor="let item of unitDropdown">{{item.item_unit_name }}</option>

                                    </select>
                                </div>
                                <div *ngIf="myForm.get('unit').touched">
                                    <span class="text-danger" *ngIf="myForm.get('unit').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>

                        <!-- <div class="form-group row pt-4" *ngIf="isUnit">
                            <label class="col-md-4 control-label ">New Unit<span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <input type="text" placeholder="" class="form-control"
                                    formControlName="unit" >
                                </div>
                            </div>
                        </div> -->

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName.ItemNature}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName="item_nature"
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select </option>
                                        <option value="Fixed">Fixed</option>
                                        <option value="Floating">Floating</option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('item_nature').touched">
                                    <span class="text-danger" *ngIf="myForm.get('item_nature').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName.ItemType}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName="item_type"
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select </option>
                                        <option value="Consumable">Consumable</option>
                                        <option value="Reusable">Reusable</option>
                                        <option value="Dead Stock">Dead Stock</option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('item_type').touched">
                                    <span class="text-danger" *ngIf="myForm.get('item_type').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName.UsagePlace}}   <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName="usage_place"
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select </option>
                                        <option value="Campus">Campus</option>
                                        <option value="Hostel">Hostel</option>
                                        <option value="Both">Both</option>

                                    </select>
                                </div>
                                <div *ngIf="myForm.get('usage_place').touched">
                                    <span class="text-danger" *ngIf="myForm.get('usage_place').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label ">{{labelName.ItemCode}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <input type="text" placeholder="" class="form-control" formControlName="item_code"onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">
                                </div>
                                <div *ngIf="myForm.get('item_code').touched">
                                    <span class="text-danger" *ngIf="myForm.get('item_code').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>


                    </fieldset>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-save btn-sm" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-save btn-sm" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-danger  btn-sm" id='md_close' (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>