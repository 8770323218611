import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from '../base.component';
import { DataTableDirective } from 'angular-datatables';
@Component({
  selector: 'app-createsurvey',
  templateUrl: './createsurvey.component.html',
  styleUrls: ['./createsurvey.component.css']
})
export class CreatesurveyComponent extends BaseComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
  headingName: any = {};
  labelName: any = {};
  workItemId: any;
  constructor(private route: Router, private active: ActivatedRoute, CommonService: CommonService, toastr: ToastrService) {
    super(CommonService, toastr);
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
    this.getSurvey();
    this.loadHeadings();
    this.loadlabels();
  }

  ngOnInit(): void {
  }
  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }
  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  add() {
    this.route.navigate(['/home/addSurvey'],{ queryParams: {wId:this.workItemId} })

  }
  // surveyQuestion
  addQuestion(item) {
    let params = {
      id: item.SURVEYID,
      wId: this.workItemId
    }
    this.route.navigate(['/home/surveyQuestion'], { queryParams: params })
  }

  getSurvey() {
    this.activeSpinner();
    let data = {
      TENANT_CODE: this.tId || this.TenantCode,
      UserId: this.userId
    }
    this.CommonService.postCall('loadSurvey', data).subscribe(res => {
      this.table = res;
      this.renderDataTable();
      this.deactivateSpinner()
    }, err => {
      this.deactivateSpinner()
    })
  }
  edit(data) {
    let params = {
      edit: data.SURVEYID,
    }
    this.route.navigate(['/home/addSurvey'], { queryParams: params })
  }
  delete(data) {
    var c = confirm("Are you sure, you want to delete record?");
    let payLoad = {
      SurveyId: data.SURVEYID,

    }
    if (c) {
      this.CommonService.postCall('deleteSurvey', payLoad).subscribe((res: any) => {
        this.toastr.success(" Survey deleted Successfully");
        this.getSurvey()
      }, err => {
        console.log(err)
        this.toastr.error(err.error ? err.error : 'failure')
      })
    }
    else {

    }
  }
  publish(data) {
    var c = confirm("Are you sure, you want to Publish the Survey?");
    let payLoad = {
      SurveyId: data.SURVEYID,
      UserId: localStorage.getItem('UserId')
    }
    if (c) {
      this.CommonService.postCall('PublishSurvey', payLoad).subscribe((res: any) => {
        this.toastr.success("Survey published Successfully")
        this.getSurvey()
      }, err => {
        console.log(err)
      })
    }
    else {

    }
  }
  changeTname() {
    this.getSurvey()
  }
  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }
}
