<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <!-- -------------------Heading------------------------->
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.ParentAlert}}</h5>
                                    </div>

                                    <!--------------------//-Heading--//----------------------->

                                    <div class="card-body">
                                        <form class="well form-horizontal" [formGroup]="myForm"
                                            (ngSubmit)="onSend(myForm)">
                                            <fieldset>
                                                <!-- //---------------------------- Radiobuttons------------------// -->
                                                <div class="col-md-12">
                                                    <label
                                                        class="col-md-2 control-label">{{labelName.Messagesendingtype}}
                                                        :</label>
                                                    <div class="form-check form-check-inline"
                                                        style="padding-right: 200px;">
                                                        <input class="form-check-input" type="radio" name="sending_type"
                                                            id="SMS" value="SMS" formControlName="sending_type"
                                                            (change)="sms($event)">
                                                        <label class="form-check-label" for="SMS">SMS</label>
                                                    </div>
                                                    <div class="form-check form-check-inline"
                                                        style="padding-right: 200px;">
                                                        <input class="form-check-input" type="radio"
                                                            (change)="sms($event)" name="sending_type" id="Email"
                                                            value="Email" formControlName="sending_type">
                                                        <label class="form-check-label" for="Email">Email</label>
                                                    </div>
                                                    <div class="form-check form-check-inline" *ngIf="false">
                                                        <input class="form-check-input" type="radio"
                                                            (change)="sms($event)" name="sending_type" id="voipcall"
                                                            value="VoipCall" formControlName="sending_type">
                                                        <label class="form-check-label" for="voipcall">Voip-Call</label>
                                                    </div>
                                                </div>
                                                <!-- //---------------------------- Radiobuttons------------------// -->

                                                <!-- -------------------SMS INPUTS--------------- -->
                                                <div>
                                                    <div class="pt-3"
                                                        *ngIf="smsInputs || emailInputs || voipcallInputs">
                                                        <div class="form-group row">
                                                            <label
                                                                class="col-md-4 control-label">{{labelName.PrimarySecondary}}
                                                                :</label>
                                                            <div class="col-md-6 inputGroupContainer">
                                                                <div class="input-group">
                                                                    <select
                                                                        class="form-select  form-select-sm shadow-sm rounded-lg">
                                                                        <option value="" [selected]="true">select
                                                                        </option>
                                                                        <option value="1">Primary and Secondary</option>
                                                                        <!-- <option *ngFor="let item of courses" [value]="item.COURSE_ID">
                            {{item.COURSE_NAME}}</option> -->
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="pt-3"
                                                        *ngIf="smsInputs || emailInputs || voipcallInputs">
                                                        <div class="form-group row">
                                                            <label
                                                                class="col-md-4 control-label">{{labelName.EmailTemplate}}
                                                                :</label>
                                                            <div class="col-md-6 inputGroupContainer">
                                                                <div class="input-group">
                                                                    <select
                                                                        class="form-select  form-select-sm shadow-sm rounded-lg">
                                                                        <option value="" [selected]="true">select
                                                                        </option>
                                                                        <option value="1">Normal</option>
                                                                        <option value="2">Promotional1</option>
                                                                        <!-- <option *ngFor="let item of courses" [value]="item.COURSE_ID">
                            {{item.COURSE_NAME}}</option> -->
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="pt-3" *ngIf="smsInputs">
                                                        <div class="form-group row">
                                                            <label class="col-md-4 control-label">{{labelName.Message}}
                                                                :</label>
                                                            <div class="col-md-6 inputGroupContainer">
                                                                <div class="input-group">
                                                                    <textarea formControlName="message"
                                                                        class="form-control form-control-sm shadow-sm rounded-lg"
                                                                        name="" id=""></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div *ngIf='isSuperAdmin'>
                                                        <div class="pt-3" *ngIf='smsInputs || emailInputs'>
                                                            <div class="form-group row">
                                                                <label
                                                                    class="col-md-4 control-label">{{labelName.TenantName}}
                                                                    :</label>
                                                                <div class="col-md-6 inputGroupContainer">
                                                                    <div class="input-group">
                                                                        <select 
                                                                            (change)="campusdropDown($event.target.value)"
                                                                            class="form-control form-control-sm shadow-sm rounded-lg"
                                                                            id="sel1">
                                                                            <option value="" [selected]="true">select
                                                                            </option>
                                                                            <option *ngFor="let item of tenanates"
                                                                                [value]='item.TNT_CODE'>
                                                                                {{item.TNT_NAME}}</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>




                                                    <div class="pt-3"
                                                        *ngIf="smsInputs || emailInputs || voipcallInputs">
                                                        <div class="form-group row">
                                                            <label class="col-md-4 control-label">{{labelName.Campus}}
                                                                :</label>
                                                            <div class="col-md-6 inputGroupContainer">
                                                                <div class="input-group">
                                                                    <select (change)="campusChange()"
                                                                        class="form-select  form-select-sm shadow-sm rounded-lg">
                                                                        <option value="" [selected]="true">select
                                                                        </option>
                                                                        <option *ngFor="let item of campus"
                                                                            [value]="item.campus_id">
                                                                            {{item.campus_name}}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>



                                                    <div class="pt-3"
                                                        *ngIf="smsInputs || emailInputs || voipcallInputs">
                                                        <div *ngIf="selectCampus">
                                                            <div class="form-group row">
                                                                <label
                                                                    class="col-md-4 control-label">{{labelName.Course}}
                                                                    :</label>
                                                                <div class="col-md-6 inputGroupContainer">
                                                                    <div class="input-group">
                                                                        <select
                                                                            (change)="courseChange($any($event).target.value)"
                                                                            class="form-select  form-select-sm shadow-sm rounded-lg">
                                                                            <option value="" [selected]="true">select
                                                                            </option>
                                                                            <option *ngFor="let course of courses"
                                                                                [value]='course.COURSE_ID'>
                                                                                {{course.COURSE_NAME}}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div class="pt-3"
                                                        *ngIf="smsInputs || emailInputs || voipcallInputs">
                                                        <div *ngIf="selectCourse">
                                                            <div class="form-group row">
                                                                <label
                                                                    class="col-md-4 control-label">{{labelName.CourseSchedule}}
                                                                    :</label>
                                                                <div class="col-md-6 inputGroupContainer">
                                                                    <div class="input-group">
                                                                        <select
                                                                            (change)="ScheduleChange($any($event).target.value)"
                                                                            class="form-select  form-select-sm shadow-sm rounded-lg">
                                                                            <option value="" [selected]="true">select
                                                                            </option>
                                                                            <option *ngFor="let item of scheduls"
                                                                                [value]="item.COURSESHD_ID">
                                                                                {{item.COURSESHD_NAME}}</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <!-- <div class="pt-3"
                                                        *ngIf="smsInputs || emailInputs || voipcallInputs">
                                                        <div *ngIf="selectCourse">
                                                            <div class="form-group row">
                                                                <label class="col-md-4 control-label"></label>
                                                                <div class="col-md-6 inputGroupContainer">
                                                                    <div class="input-group">
                                                                        <input type="checkbox"
                                                                            (change)="isChecked($event)">&nbsp;&nbsp;
                                                                        For a Specific Student ?
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> -->

                                                    <div class="pt-3"
                                                        *ngIf="smsInputs || emailInputs || voipcallInputs">
                                                        <div>
                                                            <div class="form-group row">
                                                                <label
                                                                    class="col-md-4 control-label">{{labelName.Student}}
                                                                    :</label>
                                                                <div class="col-md-6 inputGroupContainer">
                                                                    <div class="input-group">
                                                                        <ng-multiselect-dropdown style="width:98%"
                                                                            *ngIf="isData"
                                                                            [placeholder]="'select Students'"
                                                                            [settings]="dropdownSettings"
                                                                            [data]="dropdownList"
                                                                            (onSelect)="onItemSelect($event)"
                                                                            [ngModelOptions]="{standalone: true}"
                                                                            (onSelectAll)="onSelectAll($event)"
                                                                            [(ngModel)]='selectedItems'>
                                                                        </ng-multiselect-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>

                                                <!-- -------------------SMS INPUTS--------------- -->


                                                <!-- -----------------------EMAIL INPUTS------------------ -->
                                                <div>
                                                    <div class="pt-3" *ngIf="emailInputs">
                                                        <div class="form-group row">
                                                            <label class="col-md-4 control-label">{{labelName.Subject}}
                                                                :</label>
                                                            <div class="col-md-6 inputGroupContainer">
                                                                <div class="input-group">
                                                                    <input type="text" class="form-control"
                                                                        formControlName="subject">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-group row pt-4" *ngIf="emailInputs">
                                                        <label class="col-md-4 control-label ">{{labelName.Message1}}
                                                            <span class="text-danger">*</span></label>
                                                        <div class="col-md-6 inputGroupContainer">
                                                            <div class="input-group editor"> &nbsp;
                                                                <angular-editor class="angular_editor"
                                                                    [(ngModel)]="htmlContent" (input)="showHtml()"
                                                                    [ngModelOptions]="{standalone: true}"
                                                                    [config]="config"
                                                                    [placeholder]="'Enter text here...'">
                                                                </angular-editor>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="pt-3" *ngIf="emailInputs">
                                                        <div class="form-group row">
                                                            <label
                                                                class="col-md-4 control-label">{{labelName.FileUpload}}
                                                                :</label>
                                                            <div class="col-md-6 inputGroupContainer">
                                                                <div class="input-group">
                                                                    <input (change)='changeFile($event)' type="file"
                                                                        #materialFile id="materialFile1" placeholder=""
                                                                        class="form-control">
                                                                </div>
                                                                <p>{{fileName}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- -----------------------EMAIL INPUTS------------------ -->

                                                <!-- ---------------------VOIP-CALL----------------- -->
                                                <div>

                                                    <div class="pt-3" *ngIf="voipcallInputs">
                                                        <div class="form-group row">
                                                            <label class="col-md-4 control-label">{{labelName.Message2}}
                                                                :</label>
                                                            <div class="col-md-6 inputGroupContainer">
                                                                <div class="input-group">
                                                                    <select
                                                                        class="form-select  form-select-sm shadow-sm rounded-lg">
                                                                        <option value="" [selected]="true">select
                                                                        </option>
                                                                        <!-- <option *ngFor="let item of courses" [value]="item.COURSE_ID">
                            {{item.COURSE_NAME}}</option> -->
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </form>

                                    </div>
                                    <!-- ---------------------VOIP-CALL----------------- -->


                                    <!-- --------------------------footer buttons---------------- -->
                                    <div class="modal-footer" style="text-align: center; padding-right:600px">
                                        <button class="btn btn-save btn-sm"
                                            (click)="onSend(myForm)">Send</button>&nbsp;&nbsp;
                                        <button class="btn btn-danger btn-sm" (click)="cancel()">Cancel</button>

                                    </div>
                                    <!-- --------------------------footer buttons---------------- -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>