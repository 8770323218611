import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-update-rack-cells',
  templateUrl: './update-rack-cells.component.html',
  styleUrls: ['./update-rack-cells.component.css']
})
export class UpdateRackCellsComponent implements OnInit {
  myForm: FormGroup;
  table: Array<any> = [];
  fromRackList: Array<any> = [];
  fromShelfList: Array<any> = [];
  booksList: Array<any> = [];
  toRackList: Array<any> = [];
  toShelfList: Array<any> = [];
  isEdit: boolean = false;
  labelName: any = {};
  headingName: any = {};
  workItemId: any;
  editData: any;
  rackId: any;
  constructor(private toastr: ToastrService, private fb: FormBuilder, private active: ActivatedRoute, private CommonService: CommonService) {
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
    this.loadlabels();
    this.loadHeadings();
    this.loadFromRack();
    this.loadToRack();
  }

  ngOnInit(): void {
    this.formInIt();
  }

  formInIt() {
    this.myForm = this.fb.group({
      FromRack: ['', Validators.required],
      FromShelf: ['', Validators.required],
      Books: ['', Validators.required],
      ToRack: ['', Validators.required],
      ToShelf: ['', Validators.required]
    })
  }

  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  loadFromRack() {
    this.fromRackList = [];
    let payload = {
      "br_tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('BookRackMaster/GetList', payload).subscribe((res: any) => {
      this.fromRackList = res;
    })
  }

  loadToRack() {
    this.toRackList = [];
    let payload = {
      "br_tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('BookRackMaster/GetList', payload).subscribe((res: any) => {
      this.toRackList = res;
    })
  }

  ChangeFromRack(frId: any) {
    this.fromShelfList = [];
    this.CommonService.getCall(`BookShelf/LoadShelfsByRack/${frId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.fromShelfList = res;
    })
  }

  ChangefromShelf(fsId: any) {
    this.booksList = [];
    this.CommonService.getCall(`BookShelf/LoadbooksByshelf/${fsId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.booksList = res;
    })
  }

  changeToRack(trId: any) {
    this.toShelfList = [];
    this.CommonService.getCall(`BookShelf/LoadShelfsByRack/${trId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.toShelfList = res;
    })
  }

  Update(form: any) {
    let value: any = form.value;
    let payLoad = {
      "SHELF_ID": value.ToShelf,
      "RACK_ID": value.ToRack,
      "BOOK_ID": value.Books
    }
    this.CommonService.postCall('BookShelf/UpdatebooksByRackorshelf', payLoad).subscribe((res: any) => {
      this.toastr.success("Book Transferd Successfully");
      this.myForm.reset();
      setTimeout(() => {
        this.formInIt()
      }, 100);
    }, err => {
      this.toastr.error(err.error ? err.error : 'Book Transferd Not created')
    })
  }

  back() {
    window.history.back();
  }

}
