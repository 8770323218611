import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-dynamic-labeling',
  templateUrl: './dynamic-labeling.component.html',
  styleUrls: ['./dynamic-labeling.component.css']
})
export class DynamicLabelingComponent implements OnInit {
  table: Array<any> = [];
  modules: Array<any> = [];
  workItems: Array<any> = [];
  myForm: FormGroup;
  isDisable: boolean = false;
  labelName: any = {};
  headingName: any = {};
  workItemId: any;
  constructor( private active: ActivatedRoute,private fb: FormBuilder, private CommonService: CommonService, private toastr: ToastrService, private route: Router) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadModules();
    this.loadlabels();
    this.loadHeadings();
  }

  ngOnInit(): void {
  }

  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner();
  }
  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  loadModules() {
    this.modules = [];
    this.activeSpinner();
    this.CommonService.postCall('TaskManager/LoadModules', {}).subscribe((res: any) => {
      this.modules = res;
      this.deactivateSpinner();
    }, e => {
      this.deactivateSpinner();
    })

  }


  modulesChange(event) {
    this.workItems = [];
    this.isDisable = true;
    this.activeSpinner();
    this.CommonService.getCall(`TaskManager/LoadWorkItemsByModule/${event.target.value}`).subscribe((res: any) => {
      this.deactivateSpinner();
      this.workItems = res;
    }, e => {
      this.deactivateSpinner();
    });
  }

  workItemsChange(event) {
    this.activeSpinner();
    let payLoad = {
      "workitem_id": event.target.value,
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('TenantRegistration/LoadLablesByWorkItem', payLoad).subscribe((res: any) => {
      this.table = [];
      this.table = res;
      // this.table.map(a => a.checked = false);
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner(), console.log(e) });
  }

  Update() {
    this.activeSpinner();
    let payload = {
      ems_labels: this.table
    }
    this.CommonService.postCall('TenantRegistration/UpdateLables', payload).subscribe((res: any) => {
      this.toastr.success("Label changed Successfully");
      this.deactivateSpinner();
      this.table = [];
      this.workItems = [];
      this.loadModules();
    }, err => {
      this.toastr.error(err.error ? err.error : 'Label Not changed Updated');
      this.deactivateSpinner();
    })
    this.deactivateSpinner();
  }

  back() {
    window.history.back();
  }

}
