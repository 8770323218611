

<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.AddAssessment}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right">
                                            <button type="button" class="btn btn-primary mb-1" data-bs-toggle="modal"
                                                data-bs-target="#myModal" (click)="add()">Add</button>
                                        </div>
                                        <div *ngIf="table.length">
                                            <table id="patreggraph" datatable
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th>Exam Type</th>
                                                        <th>Exam Name</th>
                                                        <th>
                                                            Specific Exam Name
                                                        </th>
                                                      
                                                        <th>Threshold Percentage</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table;let i=index">
                                                        <td>{{item.exam_type}}</td>
                                                        <td>{{getName(item.exam_names)}}</td>
                                                        <td>{{item.specific_exam_name}}</td>
                                                    <td>{{item.threshold_percentage}}</td>
                                                        <td style="display: flex;">
                                                            <span data-bs-toggle="modal" data-bs-target="#myModal"
                                                                src="" class="edit_icon" (click)="edit(item.exam_type_id)">
                                                                <i class="fas fa-pen pt-1 mt-1"></i></span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    
                                         <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                        <div  class="text-center">
                                            <button class="btn btn-danger" (click)='back()'> Back</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog ">
        <div class="modal-content">


            <!-- Modal Header -->
            <div class="modal-header py-2">
                <h6 *ngIf="!isEdit" class="modal-title w-100"> {{headingName.AddAssessment}}</h6>
                <h6 *ngIf="isEdit" class="modal-title w-100"> {{headingName.AddAssessment}}</h6>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label mt-1 ">
                                {{labelName.ExamType1}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                        formControlName="exam_type">
                                        <option [value]="" [selected]="true">select
                                        </option>
                                        <option value="1">Online</option>
                                        <option value="2">Offline</option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('exam_type').touched">
                                    <span class="text-danger" *ngIf="myForm.get('exam_type').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label mt-1 ">
                                {{labelName.ExamNames1}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                        formControlName="exam_names" (change)="loadSpecificExam($event.target.value)">
                                        <option [value]="0" [selected]="true">select
                                        </option>
                                        <option value="1">CT</option>
                                        <option value="2">SE</option>
                                        <option value="3">PBE</option>
                                        <option value="4">FE</option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('exam_names').touched">
                                    <span class="text-danger" *ngIf="myForm.get('exam_names').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>


                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label"> {{labelName?.SpecificExam1}} <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select class="form-select form-select-sm shadow-sm rounded-lg" id="sel1"
                                        formControlName="specific_exam_name">

                                        <option *ngFor="let item of specificExam" [value]="item.specificname_id">
                                            {{item.name}}
                                        </option>
                                    </select>
                                </div>

                                <div *ngIf="myForm.get('specific_exam_name').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('specific_exam_name').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <!-- <div class="form-group row pt-4">
                            <label class="col-md-4 control-label ">{{labelName.SpecificExam1}}<span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <input type="text" placeholder="" class="form-control form-control-sm"
                                        formControlName="specific_exam_name">
                                </div>
                                <div *ngIf="myForm.get('specific_exam_name').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('specific_exam_name').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div> -->




                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label ">{{labelName.Threshold}}<span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <input type="text" placeholder="" class="form-control form-control-sm"
                                        (keypress)="thresholdPercentage()" id="amount" name="amount"
                                        formControlName="threshold_percentage">%
                                </div>
                                <div *ngIf="myForm.get('threshold_percentage').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('threshold_percentage').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>



                        <div *ngIf="myForm.controls['ems_exam_questions']">
                            <div formArrayName='ems_exam_questions'
                                *ngFor="let item of myForm.controls['ems_exam_questions'].controls;let i=index">

                                <div class="row" [formGroupName]="i">

                                    <div class="form-group col-md-4 pt-4">
                                        <label class="col-md-12 control-label">
                                            {{labelName.Chapters1}} <span
                                                class="text-danger">*</span><strong>:</strong></label>
                                        <div class="col-md-12 inputGroupContainer">
                                            <div class="input-group">
                                                <strong></strong> &nbsp;
                                                <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                    formControlName="chapter" >
                                                    <option [value]="0" [selected]="true">select
                                                    </option>
                                                    <option *ngFor="let item of chap" [value]='item.CHAPTER_ID'>
                                                        {{item.CHAPTER_NAME}}
                                                </select>
                                            </div>


                                        </div>
                                    </div>
                                    <div class="form-group col-md-4 pt-4">
                                        <label class="col-md-12 control-label">
                                            {{labelName.Questions1}} {{i+1}} <span
                                                class="text-danger">*</span><strong>:</strong></label>
                                        <div class="col-md-12 inputGroupContainer">
                                            <div class="input-group">
                                                <strong></strong>
                                                &nbsp;
                                                <input class="form-control form-control-sm" type="text"
                                                    formControlName="question" 
                                                    onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">
                                            </div>

                                        </div>
                                    </div>

                                    <div class="form-group col-md-4 pt-4">
                                        <label class="col-md-12 control-label">
                                            {{labelName.Marks1}} <span
                                                class="text-danger">*</span><strong>:</strong></label>
                                        <div class="col-md-12 inputGroupContainer">
                                            <div class="input-group">
                                                <strong></strong>
                                                &nbsp;
                                                <input class="form-control form-control-sm" type="number"
                                                    formControlName="max_marks"  oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
                                                    onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                    </fieldset>
                </form>
                <div class="row mt-4" >
                    <div class="col-md-12 ">
                        <div class="float-end">
                            <button class="btn btn-di float-end" *ngIf="isVisable()"
                                (click)="delete()"><i class="fas fa-trash"></i></button>
                            <button class="btn btn-ai float-end" *ngIf="isAdd()"
                                (click)="add()">Add
                                Question & Chapters </button>
                        </div>
                    </div>
                </div>

            </div>
          
            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-danger btn-sm" id="md_close" (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>