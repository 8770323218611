import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-student-book-request',
  templateUrl: './student-book-request.component.html',
  styleUrls: ['./student-book-request.component.css']
})
export class StudentBookRequestComponent implements OnInit {
  table: Array<any> = [];
  headingName: any = {};
  workItemId: any;
  bookStatus = 'Approved'
  bookStatuss = 'Reject'
  studentBook: any = [];
  date='';
  today: any = moment(new Date).format('yyyy-MM-DD')
  constructor(private toastr: ToastrService,private CommonService: CommonService,private active : ActivatedRoute) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.load();
    this.loadHeadings();
  }

  ngOnInit(): void {
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  load() {
    this.table = [];
    let payload = {
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('LibraryManagement/GetStudentRequestDetails', payload).subscribe((res: any) => {
      this.table = res;
      this.studentBook = res[0].student_book_req_id
    })
  }


  approveBook(event: any,id:any) {
    let payload={
      book_status:this.bookStatus,
      approved_date:new Date(),
      student_book_req_id:id
    }
    if (confirm('Are you sure that You want to Approve?')) {
      this.CommonService.postCall('LibraryManagement/UpdateRequestBookStatus',payload).subscribe((res: any) => {
        this.toastr.success('Book Approved Successfully');
        window.location.reload();
      })
    } else {
     return;
    }
  }
  RejectBook(event: any,id:any) {
    let payload={
      book_status:this.bookStatuss,
      approved_date:null,
      student_book_req_id:id
    }
    if (confirm('Are you sure that You want to Reject?')) {
      this.CommonService.postCall('LibraryManagement/UpdateRequestBookStatus',payload).subscribe((res: any) => {
        window.location.reload();
      })
    } else {
     return;
    }
  }

}
