import { Component, OnInit } from '@angular/core';
import { FormArray, FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-add-client-details',
  templateUrl: './add-client-details.component.html',
  styleUrls: ['./add-client-details.component.less']
})
export class AddClientDetailsComponent implements OnInit {

  myForm!: FormGroup;
  isEdit: boolean = false;
  franchise:boolean = false;
  liveVersion:boolean = false;
  chequePayment:boolean = false;
  masterSelected:boolean = false;
  checklist:any;
  constructor(private fb: FormBuilder, private route: Router, private CommonService: CommonService, private toastr: ToastrService, private active: ActivatedRoute) {
    
  }

  ngOnInit(): void {
    
    this.checklist = [
      {id:1,value:'Dashboard',isSelected:false},
      {id:2,value:'Accesspermissions',isSelected:false},
      {id:3,value:'Requests',isSelected:false},
      {id:4,value:'Config',isSelected:false},
      {id:5,value:'Exams',isSelected:false},
      {id:6,value:'Tutor',isSelected:false},
      {id:7,value:'Leavemanagement',isSelected:false},
      {id:8,value:'Timetable',isSelected:false},
      {id:9,value:'Transport',isSelected:false},
      {id:10,value:'Library',isSelected:false},
      {id:11,value:'Transactions',isSelected:false},
      {id:12,value:'Hostel',isSelected:false},
      {id:13,value:'Inventory',isSelected:false},
      {id:14,value:'Reports',isSelected:false},
      {id:15,value:'Payroll',isSelected:false},
      {id:16,value:'Gallery',isSelected:false},
      {id:17,value:'Events ',isSelected:false},
      {id:18,value:'Midday Meals',isSelected:false},
      {id:19,value:'Frontoffice',isSelected:false},
    ];

    this.myForm = this.fb.group({
    });
  }

  getEditData():void{
    this.isEdit=true;
  }

  onSubmit(form: FormGroup) {

  }

  clickfranchise(event:any){
    if(event.target.checked){
    this.franchise=true;
    }
    else{
      this.franchise=false;
    }
  }

  onLive(event:any){
    if(event.target.value=='2'){
      this.liveVersion=true;
      }
      else{
        this.liveVersion=false;
        this.chequePayment=false;
      }
  }

  onChequePay(event:any){
    if(event.target.value=='2'){
      this.chequePayment=true;
      }
      else{
        this.chequePayment=false;
      }
  }

  // The master checkbox will check/ uncheck all items
  checkUncheckAll() {
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = this.masterSelected;
    }
  }

  // Check All Checkbox Checked
  isAllSelected() {
    this.masterSelected = this.checklist.every(function(item:any) {
      return item.isSelected == true;
    })
  }

}
