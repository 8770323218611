import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { FileuploadService } from 'src/app/services/fileupload.service';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-add-compus',
  templateUrl: './add-compus.component.html',
  styleUrls: ['./add-compus.component.css']
})
export class AddCompusComponent implements OnInit {
  myForm: FormGroup;
  isEdit: boolean = false;
  editData: any = {};
  file: File;
  fileName: any;
  fileName1: any;
  cId: string;
  documentType = "addCampus"
  documentType1 = "campus";
  labelname: any;
  labelName: any = {};
  path: any;
  uploadAtt: any = '';
  campusId: any;
  headingName: any = {};
  constructor(private fb: FormBuilder, private route: Router, private CommonService: CommonService, private toastr: ToastrService, private active: ActivatedRoute, private FileuploadService: FileuploadService) {
    active.queryParams.subscribe((res) => {
      if (res.cId) { this.cId = res.cId }
    })
    this.loadlabels();
    this.loadHeadings();
    // this.getLabels();

  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      campus_name: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      campus_code: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      campus_type: ['', Validators.required],
      // caste_id: ['', Validators.required],
      campus_address: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      campus_address_type: ['', Validators.required],
      // classification: ['', Validators.required],
      campus_sft: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      construction_area: ['', Validators.required],
      openland: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      activity_disciplines: ['', Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)],
      campus_photo: [''],
      rental_agreement: [''],
      campus_status: [true, Validators.required]

    })
    if (this.cId) {
      this.getCampus();
    }
  }

  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/4267/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }
  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/4267/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  onSubmit(myForm: any) {
    let value: any = myForm.value
    value['tnt_code'] = localStorage.getItem('TenantCode');
    value['created_by'] = localStorage.getItem('UserId');
    value['campus_photo'] = this.fileName;
    value['rental_agreement'] = this.fileName1;
    value['created_on'] = moment(new Date());
    console.log(value)

    if (this.isEdit) {
      value['campus_id'] = this.cId;
      value['document_type'] = this.documentType;
      value['attachment'] = this.uploadAtt || this.editData.attachment;
      this.CommonService.postCall('EmsCampus/Update', value).subscribe((res: any) => {
        this.toastr.success("campus Updated Successfully");
        this.route.navigate(['home/campus']);
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'campus Not Updated')
      })
    } else {
      this.CommonService.postCall('EmsCampus/Create', value).subscribe((res: any) => {
        this.toastr.success("campus Created Successfully");
        this.route.navigate(['home/campus']);
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'campus Not created')

      })
    }
  }

  getCampus() {
    this.isEdit = true;
    this.CommonService.postCall('EmsCampus/Get', { "campus_id": this.cId, "document_type": this.documentType, "document_type1": this.documentType1 }).subscribe((res: any) => {
      this.fileName = res.campus_photo;
      this.fileName1 = res.rental_agreement;
      if (res instanceof Array) {
        this.editData = res[0];
        this.dataTransfer(res[0])
      } else {
        this.editData = res;
        this.dataTransfer(res);
      }
    }
    )

  }

  dataTransfer(data) {
    let ctrls = this.myForm.controls;
    Object.keys(ctrls).map(key => {
      let ctrl = ctrls[key];
      ctrl.setValue(data[key])
    })
    ctrls['campus_status'].setValue(this.editData('campus_status'));

  }

  back() {
    this.route.navigate(['home/campus']);
  }


  changeFile(event) {
    if (event.target.files.length > 0) {
      let file: File = event.target.files[0];
      let name = file.name;
      let filetype = name.split('.').pop()
      let types: Array<any> = ['jpg', 'png', 'jpeg']
      let check = types.includes(filetype);
      if (check) {
        this.file = file;
        this.upload();

      }
      else {
        // alert(' Please upload pdf and doc file formats only.')
        this.toastr.warning('Please upload pfc,doc and jpeg file formats only.')
        event.target.value = ''
      }
    }
  }

  upload() {

    const formData = new FormData();
    formData.append('attachment', this.file);
    this.FileuploadService.upload(formData, 'EmsCampus/UploadImage').subscribe((res: any) => {

      try {
        this.fileName = res.path;
        if (this.fileName) {
          this.myForm.controls['QuestionImage'].setValue(this.fileName)
        }
      } catch (e) {
        console.log(e)
      }

    }, err => { this.toastr.warning('Upload Failed') })
  }

  changeFile1(event) {
    if (event.target.files.length > 0) {
      let file: File = event.target.files[0];
      let name = file.name;
      let filetype = name.split('.').pop()
      let types: Array<any> = ['doc', 'docx', 'pdf', 'jpg', 'gif', 'png', 'xlsx', 'xlr', 'ppt', 'pptx', 'jpeg']
      let check = types.includes(filetype);
      if (check) {
        this.file = file;
        this.upload1();
      }
      else {
        // alert(' Please upload pdf and doc file formats only.')
        this.toastr.warning('Please upload pfc,doc and jpeg file formats only.')
        event.target.value = ''
      }
    }
  }

  upload1() {

    const formData = new FormData();
    formData.append('attachment', this.file);
    this.FileuploadService.upload(formData, 'EmsCampus/UploadImage').subscribe((res: any) => {
      try {
        this.fileName1 = res.path;
        if (this.fileName1) {
          this.myForm.controls['QuestionImage'].setValue(this.fileName1)
        }
      } catch (e) {
        console.log(e)
      }

    }, err => { this.toastr.warning('Upload Failed') })
  }

  getLabels() {
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/4267/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      console.log(res);
      res.forEach(element => {
        if (element.dynamic_lable_id == 11) {
          this.labelname = element.label_value
          console.log(this.labelname);
        }


      });
    })
  }
}
