<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">Define Template</h5>
                                    </div>
                                    <div class="card-body" style="padding-bottom: 25%;">
                                        <div style="padding-left: 400px;">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="inlineRadioOptions"
                                                    id="inlineRadio1" value="option1" (change)="vertical($event)">
                                                <label class="form-check-label" for="inlineRadio1">Vertical</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="inlineRadioOptions"
                                                    id="inlineRadio2" value="option2" (change)="vertical($event)">
                                                <label class="form-check-label" for="inlineRadio2">Horizontal</label>
                                            </div>
                                            
                                                <label style="padding-left: 300px;" class="form-check-label">Preview</label>                                          
                                        </div>
                                       


                                        <div class="row">

                                            <!-- <ul>
                                                <li id="txt1" draggable="true" ondragstart="drag_start(event)"> Drag
                                                    this text </li><br>
                                                <li id="txt2" draggable="true" ondragstart="drag_start(event)"> Drag me
                                                </li>
                                            </ul>  -->

                                            <div class="card col-md-2" style="width: 13rem;">
                                                <div class="card-body bottom">

                                                    <aside draggable="true" class="dragme " data-item="0">QR Code</aside>
                                                    <aside draggable="true" class="dragme " data-item="1">Blood Group</aside>
                                                    <aside draggable="true" class="dragme" data-item="2">Photo</aside>
                                                    <aside draggable="true" class="dragme " data-item="3">Person Address</aside>
                                                    <aside draggable="true" class="dragme " data-item="4">Class & Section</aside>
                                                    <aside draggable="true" class="dragme" data-item="5">Person Name</aside>
                                                    <aside draggable="true" class="dragme " data-item="6">Address</aside>
                                                    <aside draggable="true" class="dragme " data-item="7">Insitution Name</aside>
                                                    <aside draggable="true" class="dragme" data-item="8">Logo</aside>
                                                </div>
                                            </div>
                                           


                                            <div class="container-fluid col-md-10" *ngIf="isVertical">
                                                <div class="row">
                                                    <div class="col-xl-6">
                                                        <div class="card">
                                                            <div class="card-body bottom" id="vertical">
                                                         
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-6" >

                                                        <div class="card">
                                                            <div class="card-body bottom">
                                                               
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="container-fluid col-md-10" *ngIf="isHorizontal">
                                                <div class="row">
                                                    <div class="col-xl-4">

                                                        <div class="card">
                                                            <div class="card-body bottom1">
                                                              
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-4">

                                                        <div class="card">
                                                            <div class="card-body bottom1">
                                                            
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>