import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/pages/base.component';
@Component({
  selector: 'app-consolidated-mark-sheet',
  templateUrl: './consolidated-mark-sheet.component.html',
  styleUrls: ['./consolidated-mark-sheet.component.css']
})
export class ConsolidatedMarkSheetComponent extends BaseComponent implements OnInit {
  courceId: string = '';
  sheduleId: string = '';
  cources: Array<any> = [];
  scheduls: Array<any> = [];
  isTable: boolean = false;
  table: Array<any> = [];
  labelName: any = {};
  headingName: any = {};
  workItemId: any;
  emsPerctgDetails: any;
  length: number;
  roleId = localStorage.getItem('RoleId');
  tenantCode = localStorage.getItem('TenantCode');
  constructor(CommonService: CommonService, toastr: ToastrService, private active: ActivatedRoute) {
    super(CommonService, toastr);
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
    this.loadlabels();
    this.loadHeadings();
    this.tntLoad();
    this.getCourses(this.tenantCode);
    if (this.roleId == '1') {
      this.getCourses(this.tenantCode);
    }
  }

  ngOnInit(): void {
  }


  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  tntLoad() {
    let payload = {
      "RoleId": this.roleId,
      "TENANT_CODE": this.tenantCode
    }
    this.CommonService.postCall('Registration/GetTenantByRoleId', payload).subscribe((res: any) => {
      this.tenanates = res;
    })
  }
  // getCourses() {
  //   this.activeSpinner()
  //   this.CommonService.getAdminCourses().subscribe((res: any) => {
  //     this.cources = res;
  //     this.deactivateSpinner()
  //   }, e => {
  //     this.deactivateSpinner()
  //   })
  // }
  getCourses(tId: any) {
    this.activeSpinner()
    this.CommonService.getCall(`Courses/GetAdminCourses/${localStorage.getItem('UserId')}/${localStorage.getItem('RoleId')}/${tId || localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.cources = res;
      this.deactivateSpinner()
    }, e => {
      this.deactivateSpinner()
    })
  }

  // changeTname(){
  // this.activeSpinner();
  //   this.table = [];
  //   let payLoad = {
  //     "RoleId": this.roleId,
  //     "COURSETRAINERID": this.userId,
  //     "TENANT_CODE": this.TenantCode,
  //     "USERID": this.userId,
  //     "COURSESHD_COURSE_ID": this.CourseId,
  //     "COURSESHD_COURSEYEAR": this.courseYearId,
  //     "COURSESHD_SEMESTER": this.semesterId,
  //     "COURSESHD_SUBJECT": this.subjectId,
  //   }

  //   this.CommonService.postCall('LoadCourseScheduleBatchPlan', payLoad).subscribe((res: any) => {
  //     this.deactivateSpinner();
  //     setTimeout(() => {
  //       this.table = res;
  //     }, 10)
  //     this.renderDataTable()
  //     this.deactivateSpinner();
  //   }, e => { this.deactivateSpinner() })

  // }
  courceChange(id) {
    this.sheduleId = '';
    let data = {
      "CourseId": id
    }
    this.activeSpinner()
    this.CommonService.getAdminCourseSchedule(data).subscribe((res: any) => {
      this.deactivateSpinner()
      this.scheduls = res;
    }, e => { this.deactivateSpinner() })
  }

  loadTable() {
    this.isTable = true;
    this.CommonService.getCall(`CourseSchedule/ConsolidateMarkSheet/${this.courceId}/${this.sheduleId}`).subscribe((res: any) => {
      this.table = res;
      this.emsPerctgDetails = this.table[0].ems_perctg_details[0];
    })

  }


}
