import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-fees',
  templateUrl: './fees.component.html',
  styleUrls: ['./fees.component.css']
})
export class FeesComponent extends BaseComponent implements OnInit {
  myForm: FormGroup;
  table: Array<any> = [];
  isTable: Boolean = false;
  headingName: any = {};
  workItemId: any;
  labelName: any = {};
  constructor(private active : ActivatedRoute,private commonService: CommonService,toastr: ToastrService) {
    super(commonService,toastr);
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadFees();
    this.loadHeadings();
    this.getLabels();
  }

  ngOnInit(): void {
  }

  loadHeadings() {
    this.headingName = {};
    this.commonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadFees() {
    let payload = {

    }
    this.commonService.getCall(`EmsReports/FeesReport/${this.tId || localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.table = res;
    })
  }
  onSubmit(myForm) {


  }
}
