import { Component, OnInit } from '@angular/core';
import { FormArray, FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { FileuploadService } from 'src/app/services/fileupload.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-vendors',
  templateUrl: './vendors.component.html',
  styleUrls: ['./vendors.component.css']
})
export class VendorsComponent implements OnInit {
  table: Array<any> = [];
  myForm: FormGroup;
  isEdit: boolean = false;
  editData: any = {};
  file: File;
  documentType = "Vendors";
  labelName: any = {};
  attachment: any;
  uploadAtt: any = '';
  fileName: any;
  vendorId: any;
  headingName: any = {};
  workItemId: any;
  constructor(private fb: FormBuilder, private CommonService: CommonService, private route: Router, private toastr: ToastrService, private active: ActivatedRoute, private FileuploadService: FileuploadService) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadVendor();
    this.getLabels();
    this.loadHeadings();

  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      vendor_name: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      email: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      pan: [''],
      phone_number: ['', Validators.required],
      landline: [''],
      fax_number: [''],
      website: [''],
      vat_number: [''],
      address: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      vendor_code: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      vendor_logo: [''],
      status: ['', Validators.required]
      // status:['']
    });
  }

  loadVendor() {
    this.CommonService.postCall('Vendor/GetList', { "tnt_code": localStorage.getItem('TenantCode') }).subscribe((res: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = res;
      }, 10);
    })
  }
  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  onSubmit(myForm: FormGroup) {
    let value: any = myForm.value
    value['tnt_code'] = localStorage.getItem('TenantCode');
    value['created_by'] = localStorage.getItem('UserId');
    console.log(value)
    if (this.isEdit) {
      value['vendor_id'] = this.editData.vendor_id;
      value['document_type'] = this.documentType;
      value['attachment'] = this.uploadAtt || this.editData.attachment;
      this.CommonService.postCall('Vendor/Update', value).subscribe((res: any) => {

        this.toastr.success("Vendor Updated Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Vendor Not Updated')
      })
    } else {
      this.CommonService.postCall('Vendor/Create', value).subscribe((res: any) => {
        this.vendorId = res.vendor_id;
        this.loadVendor();
        this.upload();
        this.toastr.success("Vendor Created Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Vendor Not created')

      })
    }
  }


  edit(vId: any) {
    this.isEdit = true;
    this.CommonService.postCall('Vendor/Get', { "vendor_id": vId, "document_type": this.documentType }).subscribe((res: any) => {
      this.attachment = res.attachment
      if (res instanceof Array) {
        this.editData = res[0];
        this.dataTransfer(res[0])
      } else {
        this.editData = res;
        this.dataTransfer(res);
      }
    }
    )

  }

  dataTransfer(data) {
    let ctrls = this.myForm.controls;
    Object.keys(ctrls).map(key => {
      let ctrl = ctrls[key];
      ctrl.setValue(data[key])
    })
    ctrls['status'].setValue(this.editData('status'));
  }


  close() {
    this.isEdit = null;
    this.myForm.reset();
    this.editData = {};
  }


  changeFile(event) {
    if (event.target.files.length > 0) {
      let file: File = event.target.files[0];
      let name = file.name;
      let filetype = name.split('.').pop()
      let types: Array<any> = ['doc', 'docx', 'pdf', 'jpg', 'gif', 'png', 'xlsx', 'xlr', 'ppt', 'pptx', 'jpeg']
      let check = types.includes(filetype);
      if (check) {
        this.file = file;
        if (this.isEdit == true) {
          this.upload();
        }

      }
      else {
        // alert(' Please upload pdf and doc file formats only.')
        this.toastr.warning('Please upload pfc,doc and jpeg file formats only.')
        event.target.value = ''
      }
    }
  }

  upload() {
    let data =
    {
      "document_title": this.documentType,
      "document_type": this.documentType,
      "document_type_id": this.vendorId,
      "verified_by": localStorage.getItem('UserId'),
      "created_by": localStorage.getItem('UserId'),
      "modified_by": localStorage.getItem('UserId'),
      "tnt_code": localStorage.getItem('TenantCode')
    }



    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('attachment', this.file);
    this.FileuploadService.upload(formData, 'Registration/UploadAttachments').subscribe((res: any) => {
      this.uploadAtt = res.attachment;
    })
    //   try {
    //     this.fileName = res.path;
    //     if (this.fileName) {
    //       this.myForm.controls['QuestionImage'].setValue(this.fileName)
    //     }
    //   } catch (e) {
    //     console.log(e)
    //   }

    // }, err => { this.toastr.warning('Upload Failed') })
  }

}

