import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
declare var $: any;
declare function googleTranslate(): any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  userName: string = localStorage.getItem('Username');
  table: Array<any> = [];
  viewMsg: Array<any> = [];
  message: Array<any> = [];
  levelUpdate: any;
  notifications: Array<any> = [];
  notification: Array<any> = [];
  viewNoti: Array<any> = [];
  OPERATION: any;
  ROLEID = localStorage.getItem('RoleId');
  myForm: FormGroup;
  oldPassword: any;
  constructor(private route: Router, private CommonService: CommonService, private fb: FormBuilder, private toastr: ToastrService) {

    this.loadMessage();
    this.loadNotification();
    this.getOldPassword();

  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      oldPassword: ['', [Validators.required, Validators.minLength(6)]],
      password: ['', [Validators.required, Validators.minLength(6), Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
    })
    // googleTranslate();
  }

  Signout() {
    localStorage.clear();
    this.route.navigate(['/login'])
  }
  menuClick() {
    $('#sidebar').toggleClass('slide');
  }
  loadMessage() {
    this.notifications = [];
    if (this.ROLEID == '1') {
      this.OPERATION = 'GETNOTIFICATIONS'
    } else if (this.ROLEID == '2') {
      this.OPERATION = 'TRAINERNOTIFICATIONS'
    } else if (this.ROLEID == '3') {
      this.OPERATION = 'STUDENTNOTIFICATIONS'
    }
    return
    this.CommonService.getCall(`Notification/GetNotification/${this.OPERATION}/${localStorage.getItem('TenantCode')}/${localStorage.getItem('UserId')}`).subscribe((res: any) => {
      // this.table = [];
      this.table = res;
    }, e => { console.log(e) })
  }

  loadNotification() {
    this.notification = [];
    this.CommonService.getCall(`EmsAlerts/GetNotificationsByUser/${localStorage.getItem('UserId')}`).subscribe((res: any) => {
      this.notification = res;
    })
  }

  viewNotification(event: any) {
    this.viewNoti = [];
    this.viewNoti = +event == 0 ? this.notification : this.notification.filter(e => e.internal_notification_id == event);

    this.CommonService.getCall(`EmsAlerts/UpdateNotificationreadStatus/${event}/${1}`).subscribe((res: any) => {

    })
  }

  viewMessage(event: any) {
    this.viewNoti = [];
    this.viewNoti = +event == 0 ? this.notification : this.notification.filter(e => e.ID == event);
  }

  delete(id: any, type: any, notificationid: any) {
    if (id) {
      type = 2;
    }
    else if (notificationid) {
      type = 1;
    }
    return
    this.CommonService.getCall(`Notification/DeleteNotification/${id}/${type}`).subscribe((res: any) => {

    })
  }

  close() {
    this.getUnViewdNotificationCount();
    // window.location.reload();
  }

  close1() {
    this.myForm.reset();
  }

  getOldPassword() {
    this.CommonService.postCall(`Registration/GetOldPassWord`, { "userid": localStorage.getItem('UserId') }).subscribe((res: any) => {
      this.oldPassword = res.password;
    })
  }


  passwordCheck() {
    let pControl = this.myForm.controls['password'];
    let cControl = this.myForm.controls['confirmPassword'];
    if (!pControl.value) {
      this.toastr.warning('Please enter the password');
      cControl.setValue(null);
      return;
    }
    if (pControl.value != cControl.value) {
      this.toastr.warning('New Password and Confirm Password Should be same');
      cControl.setValue(null);
    }
  }

  ChangePass(form: any) {
    let value = form.value;
    let payload = {
      "userid": localStorage.getItem('UserId'),
      "password": value.password
    }
    if (this.oldPassword == value.oldPassword) {
      this.CommonService.postCall(`Registration/UpdateUserPassWord`, payload).subscribe((res: any) => {
        this.toastr.success("Password Changed Successfully");
        document.getElementById('passPopupClose').click();
        this.Signout();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Password Not Changed');
      })
    } else {
      this.toastr.warning('Old Passowrd is Incorrect');
    }
  }

  getUnViewdNotificationCount1() {
    return this.table.filter(m => m.IsRead == false).length;
  }

  getUnViewdNotificationCount() {
    return this.notification.filter(m => m.notification_read_status == 0).length;
  }


  apply() {
    document.getElementById('Noti_close')?.click();
  }

  userManual() {
    let adminUrl = `${location.origin}/Admin/LMS%20Admin%20user%20manul%20updated%20new.pdf`;
    let trainerUrl = `${location.origin}/Admin/LMS%20Admin%20user%20manul%20updated%20new.pdf`;
    let memberUrl = `${location.origin}/Admin/Samvaad%20LMS%20Member%20User%20manual%20new%20(1).pdf`;
    if (this.ROLEID == '1') {
      window.open(adminUrl, '_blank');
    } else if (this.ROLEID == '2') {
      window.open(trainerUrl, '_blank');
    } else if (this.ROLEID == '3') {
      window.open(memberUrl, '_blank');
    } else {
      this.toastr.warning('There is no user manual');
    }
  }

  profileNavigate() {
    let id = localStorage.getItem('UserId');
    sessionStorage.isTabsDisabled = false;
    this.CommonService.postCall('EditRegistrationByUserId', { CREATEDBY: id }).subscribe(
      (res: any) => {
        this.route.navigate(['home/editUserRegistration'], { queryParams: { token: res.value } })
      }, err => {
      }
    )

  }

}
