<br>
<div class="page-wrapper pt-4">
  <div class="page-content pt-0 pe-2 ps-2">
    <div class="container-fluid">
      <div class="default_class">
        <div class="content-wrapper p-0">
          <div class="container-fluid ">
            <div class="text-right mb-1">
              <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                <h5 class="text-left mb-0 ">{{headingName.PrintIDCard3}}</h5>
              </div>&nbsp;
              <div>
                <button type="button" class="btn btn-primary" (click)="Print()">
                  Print</button> &nbsp;
                <button type="button" class="btn btn-danger" (click)="back()">
                  Back</button>
              </div>
            </div>
            <div id="print">
              <div class="id-1" *ngFor="let item of idCardList">

                <div class="bg-id-1">
                  <div class="certificate-container">
                    <div class="certificate-header">

                      <div style="width: 100%;  text-align: center;">
                        <img src="../../../assets/img/Samvaad.png" alt="" width="80px" height="auto">

                      </div>

                    </div>
                    <div class="" style="padding-bottom:10px;">

                      <h2
                        style="color: #17144D; font-size: 13px; font-weight: bolder; word-spacing: 1; text-transform: uppercase; text-align: center; margin-bottom: 0px; padding: 5px 5px 3px 5px;">
                        SURYODAYA HIGH SCHOOL</h2>
                      <h4
                        style="color: #17144D; font-size: 10px; font-weight: bold; text-align: center; margin: 0px 10px;">
                        Begumpet, Hyderabad</h4>



                    </div>


                  </div>

                  <div style="text-align: center;">
                    <div>
                      <img src="../../../assets/img/sample-photo.jpg" width="50px" height="auto" alt=""
                        style=" width: 70px; height: 70px;  border-radius:50% ;border:2px solid #FFFEFF;">
                      <!-- <img src="./img/sign.png" width="40px" height="15px" alt=""> -->
                    </div>
                  </div>
                  <div style=" font-size: 10px;">
                    <p style="text-align: center; margin-bottom: 3px;"> <span
                        style="color:#ffffff; font-size: 13px; font-weight: 600;">{{item.name}}</span></p>

                    <!-- <div style="text-align: center;  margin-bottom: 4px;  margin-top: 8px;">
                                    <div
                                      style="font-size: 10px; background: #131e3b; color: #fff; border-radius: 3px; padding: 3px; text-align: center; font-weight: bolder;">
                                      COMPUTER ENGG.</div>
                                  </div> -->

                  </div>
                  <div style="margin: 0px 10px; padding-bottom: 3px; ">
                    <table class="vertical-id-1">
                      <tr>
                        <td width="90px">
                          <p style="color: #ffffff; font-weight: 500;font-size: 10px;"><span>ID No</span></p>
                        </td>
                        <td>
                          <p style="color: #ffffff; font-weight: 500;font-size: 10px;"><span>:</span><span>
                              {{item.rollnumber}}</span></p>
                        </td>

                      </tr>
                      <tr>
                        <td>
                          <p style="color: #ffffff; font-weight: 500;font-size: 10px;"><span>class & Section</span></p>
                        </td>
                        <td>
                          <p style="color: #ffffff; font-weight: 500;font-size: 10px;"><span>:</span><span>
                              {{item.course_name}}</span></p>
                        </td>

                      </tr>

                      <tr>
                        <td style="vertical-align: top;">
                          <p style="color: #ffffff; font-weight: 500;font-size: 10px;"><span></span></p>
                        </td>
                        <td>
                          <p style="color: #ffffff; font-weight: 500;font-size: 10px;"><span></span><span> </span></p>
                        </td>

                      </tr>
                      <tr>
                        <td>
                          <p style="color: #ffffff; font-weight: 500;font-size: 10px;"><span>Contact</span></p>
                        </td>
                        <td>
                          <p style="color: #ffffff; font-weight: 500;font-size: 10px;"><span>:</span><span>
                              {{item.parent_mobile_number}}</span></p>
                        </td>

                      </tr>
                      <tr>
                        <td>
                          <p style="color: #ffffff; font-weight: 500;font-size: 10px;"><span></span></p>
                        </td>
                        <td>
                          <p style="color: #ffffff; font-weight: 500;font-size: 10px;"><span></span><span></span>
                          </p>
                        </td>

                      </tr>
                    </table>

                  </div>
                  <!-- <div style="text-align: center;padding: 7px 0px;"><img src="../../../assets/img/qr.png" alt=""
                      style="height: 55px; width: 55px;"></div> -->
                  <!-- <div style="text-align: right;"><img src="./img/sign.png" style="width: 30px; height: 15px; margin-right: 10px;"
                                    alt=""></div> -->
                  <!-- <div style="display: flex; justify-content: space-between; align-items: center; font-size: 10px;">
                                  <div style="color:#612c64">Session</div>
                                  <div style="float: left"><b>27/03/2022</b></div>
                                  <div style="float: right">Principal</div>
                                </div> -->
                  <div style="clear: both;"></div>
                </div>
                <div style="clear: both;"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>