<div class="page-wrapper pt-4">
  <div class="page-content pt-0 pe-2 ps-2">
    <div class="container-fluid">
      <div class="default_class">
        <div class="content-wrapper p-0">
          <div class="container-fluid">
            <div class="row">
              <div class="col-xl-12">
                <div class="card rounded shadow-lg">
                  <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                    <h5 class="mb-0">{{headingName.IssueItems}}</h5>
                    <!-- <h5 *ngIf="isEdit" class="mb-0">Edit Quotation</h5> -->
                  </div>
                  <div class="card-body">
                    <div class="custom_container">
                      <div class="row">
                        <div class="col-xl-8 offset-xl-2">
                          <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">

                            <fieldset>
                              <div class="form-group row pt-4">
                                <label class="col-md-4 control-label mt-1 ">
                                  {{labelName?.IssueItems}}<span class="text-danger">*</span></label>
                                  
                                  <div class="col-md-8 inputGroupContainer">
                                  <div class="form-check" *ngFor="let item of checkboxs">
                                    <input class="form-check-input" type="checkbox" id="defaultCheck1"
                                    [ngModelOptions]="{standalone: true}" [(ngModel)]="item.status" >
                                    {{item.item_name}}
                                  </div>
                                </div>
                              </div>
                           
                              <div class="form-group row pt-4">
                                <label class="col-md-4 control-label mt-1 ">
                                  {{labelName?.IssuedOn}}<span class="text-danger">*</span></label>
                                <div class="col-md-8 inputGroupContainer">
                                  <div class="input-group"> <strong>:</strong> &nbsp;
                                    <input class="form-control form-control-sm" formControlName="issued_on" type="date">
                                  </div>
                                </div>
                              </div>

                              <div class="form-group row pt-4">
                                <label class="col-md-4 control-label mt-1 ">
                                  {{labelName?.CreatedBy}} <span class="text-danger">*</span></label>
                                <div class="col-md-8 inputGroupContainer">
                                  <div class="input-group"> <strong>:</strong> &nbsp;
                                    <select formControlName="created_by" class="form-select">
                                      <option value="" [selected]="true">select</option>
                                      <option [value]="1">Sateesh</option>
                                      <option [value]="2">Sai Kumar</option>
                                    </select>
                                  </div>

                                </div>
                              </div>
                            </fieldset>
                          </form>

                          <div class="text-center pt-4">
                            <button type="button" (click)="onSubmit(myForm)" class="btn btn-sm  btn-save"
                              [disabled]="myForm.invalid">Save</button> &nbsp;
                            <button type="button" class="btn btn-sm btn-danger" class="btn btn-danger  btn-sm"
                              id='md_close' (click)="close()">close</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>