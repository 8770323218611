import { Component, OnInit } from '@angular/core';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
@Component({
  selector: 'app-transfer-certificate',
  templateUrl: './transfer-certificate.component.html',
  styleUrls: ['./transfer-certificate.component.css']
})
export class TransferCertificateComponent implements OnInit {
  cources: Array<any> = [];
  students: Array<any> = [];
  filteredOptions!: Observable<string[]>;
  receipt: boolean = false;
  usersData: Array<any> = [];
  labelName: any = {};
  reason: any;
  remarks: any;
  headingName: any = {};
  workItemId: any;
  enroll: any;
  Enrollment_Number: string = "";
  bonafied: boolean = false;
  userId: any;
  currentDate = new Date();
  tenanates: Array<any> = [];
  roleId = localStorage.getItem('RoleId');
  tenantCode = localStorage.getItem('TenantCode');
  tId: any;
  myControl = new FormControl('');


  show: boolean = false;
  currentValue: string = "";
  stuSearchArr: Array<any> = [];
  selectedUid: Array<any> = [];
  // options:string[]=["truck","motorcycle","car","jeep","excavator","airplane","train","cycle"];


  constructor(private CommonService: CommonService, private active: ActivatedRoute) {
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
    // this.loadcourses();
    this.loadlabels();
    this.loadHeadings();
    this.tntLoad();
    if (this.roleId == '1') {
      this.getCourses(this.tenantCode);
    }
  }

  ngOnInit(): void {

  }
  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }
  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  tntLoad() {
    let payload = {
      "RoleId": this.roleId,
      "TENANT_CODE": this.tenantCode
    }
    this.CommonService.postCall('Registration/GetTenantByRoleId', payload).subscribe((res: any) => {
      this.tenanates = res;
    })
  }



  // getCourses() {
  //   this.activeSpinner()
  //   this.CommonService.getAdminCourses().subscribe((res: any) => {
  //     this.cources = res;
  //     this.deactivateSpinner()
  //   }, e => {
  //     this.deactivateSpinner()
  //   })
  // }
  getCourses(tId: any) {
    this.activeSpinner()
    this.CommonService.getCall(`Courses/GetAdminCourses/${localStorage.getItem('UserId')}/${localStorage.getItem('RoleId')}/${tId || localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.cources = res;
      this.deactivateSpinner()
    }, e => {
      this.deactivateSpinner()
    })
  }

  courceChange(event: any) {
    let courseId = event.target.value;
    this.students = [];
    this.stuSearchArr = [];
    let payload = {
      "courseid": courseId,
      "tenantcode": this.tId || localStorage.getItem('TenantCode'),
    }
    this.CommonService.postCall('Registration/LoadStudentsByCourse', payload).subscribe((response: any) => {
      this.students = response;
      this.stuSearchArr = this.students.map(a => a.name);

    })
  }
  generateBonafied() {
    this.receipt = true;
    this.CommonService.getCall(`Registration/LoadUserDetails/${this.userId}`).subscribe((response: any) => {
      this.usersData = response;
    })
  }


  isDisabled(val1: number, val2: number): boolean {
    if ((val1 > 0) || (val2 > 0))
      return false;
    // else if (val2 > 0)
    //   return false;
    // else if (val1 == 0 && val2 == 0)
    //   return true;
    else
      return true;


  }
  getUsersData(event: any) {
    this.bonafied = true;
    // this.userId = event.target.value;
    this.Enrollment_Number = event.target.value;
    // this.CommonService.getCall(`Registration/LoadUserDetails/${this.userId}`).subscribe((response: any) => {
    //   this.usersData = response;
    // })
  }

  enrollNumber(event: any) {

    this.enroll = event.target.value;
    this.CommonService.getCall(`Registration/LoadUserDetailsByEnrollmentNo/${this.enroll}`).subscribe((res: any) => {
      this.usersData = res;
    })
  }
  convetToPDF() {
    var data: any = document.getElementById('printtable');
    html2canvas(data).then((canvas: any) => {
      // Few necessary setting options
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;

      const contentDataURL = canvas.toDataURL('image/png');
      let pdf: any = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      pdf.save('transferCertificate.pdf'); // Generated PDF
      // window.close();
    });

  }
  print() {
    var printContents: any = document.getElementById("printtable")?.innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  }

  optionselected(option: string) {
    console.log(option);
    this.currentValue = option;
    this.userId = this.students.find(a => a.name == option).userid
    this.show = false;
  }
  handleFocusIn() {
    this.show = true;
    console.log("status show" + this.show);
  }
  submit() { }
  clear() { }
}
