import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'src/app/pages/base.component';

@Component({
  selector: 'app-leave-types',
  templateUrl: './leave-types.component.html',
  styleUrls: ['./leave-types.component.css']
})
export class LeaveTypesComponent extends BaseComponent implements OnInit {
  myForm: FormGroup;
  isEdit: boolean = null;
  isDisable: boolean = false;
  table: Array<any> = [];
  editData: any;
  labelName: any = {};
  headingName: any = {};
  workItemId: any;
  constructor(private fb: FormBuilder, private active: ActivatedRoute, CommonService: CommonService, toastr: ToastrService) {
    super(CommonService, toastr);
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
    this.load();
    this.loadlabels();
    this.loadHeadings();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      leavetypes_leavename: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],

    });
  }

  activiceSpinner() {
    this.CommonService.activateSpinner();
  }
  deactivateSpinner() {
    this.CommonService.deactivateSpinner();
  }

  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  load() {
    this.activiceSpinner();
    this.CommonService.postCall(`EmsLeaveManagement/GetLeaveTypeList`, { "tnt_code": this.tId || localStorage.getItem('TenantCode') }).subscribe((res: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = res;
      }, 10)
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner(), console.log(e) })
  }


  onSubmit(form: any) {
    let value: any = form.value;
    value.TNT_CODE = localStorage.getItem('TenantCode');
    let payload = {
      "leavetypes_leavename": value.leavetypes_leavename,
      "tnt_code": localStorage.getItem('TenantCode'),
      "created_by": localStorage.getItem('UserId'),
    }
    if (this.isEdit) {
      payload['leavetypes_id'] = this.editData.leavetypes_id;
      this.CommonService.postCall('EmsLeaveManagement/UpdateLeaveType', payload).subscribe((res: any) => {
        this.load();
        this.toastr.success("Leave Type Updated Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Leave Type Not Updated');
      })
    } else {
      this.CommonService.postCall('EmsLeaveManagement/CreateLeaveType', payload).subscribe((res: any) => {
        this.load();
        this.toastr.success("Leave Type Created Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Leave Type Not created');
      })
    }
  }

  edit(leave_type_id) {
    this.isEdit = true;
    this.CommonService.postCall(`EmsLeaveManagement/Get`, { "leavetypes_id": leave_type_id }).subscribe((res: any) => {
      if (res instanceof Array && res.length) {
        this.editData = res[0];
        this.dataTransForm()
      } else {
        this.editData = res;
        this.dataTransForm()
      }
      this.dataTransForm();
    }), err => { }
  }

  dataTransForm() {
    let ctrls = this.myForm.controls
    Object.keys(ctrls).map((key) => {
      let ctrl: AbstractControl = ctrls[key];
      ctrl.setValue(this.editData[key])
    });


  }

  changeStatus(lId: any, status: string) {
    let payload = {
      "leavetypes_id": lId,
      "leavetypes_status": status
    }
    if (confirm(`Are you sure you want to ${status} status`)) {
      this.CommonService.postCall(`EmsLeaveManagement/Delete`, payload).subscribe((res: any) => {
        this.load();
        this.toastr.success(`Leave type ${status} Successfully`);
      })
    }
  }

  close() {
    this.isEdit = null;
    this.myForm.reset();
    this.editData = {};
  }

}
