import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-student-leaves',
  templateUrl: './student-leaves.component.html',
  styleUrls: ['./student-leaves.component.css']
})
export class StudentLeavesComponent implements OnInit {
  table: Array<any> = [];
  headingName:any= {};
  workItemId: any;
  constructor(private fb: FormBuilder,private active : ActivatedRoute, private CommonService: CommonService, private toastr: ToastrService) { 
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.load();
    this.loadHeadings();
  }

  ngOnInit(): void {
  }

  activiceSpinner() {
    this.CommonService.activateSpinner();
  }
  deactivateSpinner() {
    this.CommonService.deactivateSpinner();
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  load() {
    this.activiceSpinner();
    let payload={
      "tnt_code":localStorage.getItem('TenantCode'),
      "user_id":localStorage.getItem('UserId'),
      "role_name":localStorage.getItem('RoleName')
    }
    this.CommonService.postCall('EmsLeaveManagement/StudentLeaveReports' ,payload).subscribe((res: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = res;
      }, 10)
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner(), console.log(e) })
  }

  
  changeStatus(lId: any, status: string) {
    let payload = {
      "leavetypes_id": lId,
      "leavetypes_status": status
    }
    if (confirm(`Are you sure want to ${status} the leave?`)) {
      this.CommonService.getCall(`EmsLeaveManagement/UpdateLeaveStatus/${lId}/${status}`).subscribe((res: any) => {
        this.load();
        this.toastr.success(`Leave ${status} Successfully`);
      })
    }
  }

}
