import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-quotations-report',
  templateUrl: './quotations-report.component.html',
  styleUrls: ['./quotations-report.component.css']
})
export class QuotationsReportComponent implements OnInit {
  myForm: FormGroup;
  table: Array<any> = [];
  headingName: any = {};
  workItemId: any;
  constructor(private commonService: CommonService,private active : ActivatedRoute) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadQuotations();
    this.loadHeadings();
  }

  ngOnInit(): void {
  }
  loadHeadings() {
    this.headingName = {};
    this.commonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  loadQuotations() {
    let payload = {

    }
    this.commonService.getCall(`EmsReports/QuotationsReport/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.table = res;
    })
  }
  onSubmit(myForm) {

  }
}
