import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { isThisSecond } from 'date-fns';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.css']
})
export class EventsListComponent extends BaseComponent implements OnInit {
  table: Array<any> = [];
  headingName: any = {};
  workItemId: any;
  labelName: any = {};
  constructor(private fb: FormBuilder, private active: ActivatedRoute,  CommonService: CommonService,  toastr: ToastrService, private router: Router) {
    super(CommonService, toastr);
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
    this.loadevents();
    this.loadHeadings();
    this.getLabels();
  }

  ngOnInit(): void {
  }
  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }
  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  loadevents() {
    this.CommonService.postCall(`EmsEventsCategories/EventGetList`, { "tnt_code": this.tId || localStorage.getItem('TenantCode') }).subscribe((res: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = res;
      }, 10);
    })
  }

  editEvent(eId) {
    this.router.navigate(['home/addEvent'], { queryParams: { eId: eId, wId: this.workItemId } });
  }
  
  add() {
    this.router.navigate(['home/addEvent'], { queryParams: { wId: this.workItemId } });

  }
  statusChange(eId, status) {
    let sts;
    let sta1;
    if (status == 'Approved') {
      sts = 'Approved';
      sta1 = 'approve';
    } else if (status == 'Rejected') {
      sts = 'Rejected';
      sta1 = 'reject';
    }
    else if (status == 'Closed') {
      sts = 'Closed';
      sta1 = 'close';
    }
    if (confirm(`Are you sure you want to ${sta1} this event?`)) {
      this.CommonService.postCall(`EmsEventsCategories/UpdateStatus`, { "event_id": eId, "event_status": sts }).subscribe((res: any) => {
        this.loadevents()
        this.toastr.success(`Event ${sts} Successfully`);
      })
    }
  }

}
