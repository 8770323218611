<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.FeePayments}} </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="form-group">
                                            <div class="row">
                                                <div *ngIf='isSuperAdmin' class="col-md-3">
                                                    <label for="sel1">{{labelName.TenantName}} : </label>
                                                    <select [(ngModel)]='tId ' (change)="loadCourse()"
                                                        class="form-control form-control-sm shadow-sm rounded-lg"
                                                        id="sel1">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let item of tenanates" [value]='item.TNT_CODE'>
                                                            {{item.TNT_NAME}}</option>
                                                    </select>
    
                                                </div>
                                                <div class="col-md-3">
                                                    <label for="sel1">{{labelName?.CourseName}}<span
                                                            class="text-danger">*</span></label>
                                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                        [disabled]="isParam" (change)="courceChange($event)"
                                                        [(ngModel)]='academicId'>
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let course of courses"
                                                            [value]='course.COURSE_ID'>
                                                            {{course.COURSE_NAME}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-md-3">
                                                    <label for="sel1">{{labelName?.CourseSchedule}}<span
                                                            class="text-danger">*</span></label>
                                                    <select name="courseSchedule" id="courseSchedule"
                                                        (change)="sheduleChange($event)"
                                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                                        <option value="" [selected]="true">Select</option>
                                                        <option *ngFor="let courseSchedule of Schedules"
                                                            [value]="courseSchedule.COURSESHD_ID">
                                                            {{courseSchedule.COURSESHD_NAME}}</option>
                                                    </select>
                                                </div>
                                                <div class="col-md-3">
                                                    <label for="sel1">{{labelName?.UserName}} <span
                                                            class="text-danger">*</span></label>
                                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                        [disabled]="isParam" (change)="load($event)">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let user of users" [value]='user.USERID'>
                                                            {{user.FIRSTNAME}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <!-- <div class="col-md-3">
                                        <label for="sel1">Number Of Terms <span class="text-danger">*</span></label>
                                        <select class="form-control form-control-sm shadow-sm rounded-lg"
                                        [disabled]="isParam"  formControlName="NUMBER_OF_TERMS">
                                            <option value="" [selected]="true">select</option>
                                            <option value="">1</option>
                                            <option value="">2</option>
                                            <option value="">3</option>
                                       
                                        </select>
                                    </div> -->
                                            </div>
                                        </div>
                                        <br>
                                        <div *ngIf="table.length" class="pt-4">
                                            <table id="patreggraph" datatable [dtOptions]="dtOptions"
                                            [dtTrigger]="dtTrigger"
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small display"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <!-- <th> User Name</th> -->
                                                        <th>Fee Type</th>
                                                        <th>Terms</th>
                                                        <!-- <th>Course</th> -->
                                                        <th>Amount</th>
                                                        <!-- <th>Total Fee Amount</th> -->
                                                        <th>Concession</th>
                                                        <th>Due Amount</th>
                                                        <th>Paid Amount</th>
                                                        <th>Pay Amount</th>
                                                        <th>Action</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table ;let i=index">

                                                        <!-- <td>{{item.USERNAME}}</td> -->
                                                        <td>
                                                            <span *ngIf="item.FEERECEIVABLE_TERM_ID==1">{{item.FEETYPE_NAME}}</span>
                                                            </td>
                                                        <td>TERM {{item.FEERECEIVABLE_TERM_ID}}</td>
                                                        <!-- <td>{{item.COURSE_NAME}}</td> -->
                                                        <td> {{item.FEERECEIVABLE_TERM_AMOUNT}}</td>
                                                        <!-- <td>{{item.FEERECEIVABLE_ACTUALAMOUNT}}</td> -->
                                                        <td>{{item.fee_concession_term_amount}}</td>
                                                        <th style="color: red;">{{item.Due_amount < 0 ? 0 :
                                                                item.Due_amount}}</th>
                                                        <th style="color: green;">{{item.FEERECEIVABLE_PAIDAMOUNT}}</th>
                                                        <td>
                                                            <label *ngIf="item.checked">
                                                                <input type="number" placeholder=""
                                                                    (input)="ValidatePayAmount($event,item.Due_amount)"
                                                                    [(ngModel)]="item.TNT_CODE" #amount="ngModel"
                                                                    class="form-control">
                                                            </label>
                                                        </td>


                                                        <td>
                                                            <div class="form-check"
                                                                *ngIf="!(item.Due_amount == 0||item.Due_amount < 0)">
                                                                <input class="form-check-input" type="checkbox"
                                                                    [(ngModel)]="item.checked" value=""
                                                                    id="flexCheckDefault">
                                                                <label class="form-check-label" for="flexCheckDefault">
                                                                    Pay
                                                                </label>
                                                            </div>
                                                        </td>

                                                </tbody>
                                            </table>
                                            <br><br>
                                            <div class="row ">
                                                <div class="col-md-6">
                                                    <table class="table table-striped table-hover pt-4">
                                                        <tbody>
                                                            <tr>
                                                                <th>Total Fee</th>
                                                                <td>{{this.fTotal}}</td>

                                                            </tr>
                                                            <tr>
                                                                <th>Concession</th>
                                                                <td>{{this.cTotal}}</td>

                                                            </tr>
                                                            <tr>
                                                                <th>Grand Total </th>
                                                                <td>{{this.gTotal}}</td>

                                                            </tr>
                                                            <tr>
                                                                <th style="color: green;">
                                                                    <!-- <button type="button" class="btn btn-success">Paid</button> -->
                                                                    Paid Amount
                                                                </th>
                                                                <th style="color: green;"> {{this.pTotal}}</th>

                                                            </tr>
                                                            <tr>
                                                                <th style="color: red;">
                                                                    <!-- <button type="button" class="btn btn-danger">Due</button> -->
                                                                    Due Amount
                                                                </th>
                                                                <th style="color: red;"> {{this.dTotal}}</th>

                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>


                                            <div class="form-group row pt-4">
                                                <label class="col-md-2 control-label line_2 rem_1">
                                                    {{labelName?.DateofPayment}} <span class="text-danger">*</span></label>
                                                <div class="col-md-4 inputGroupContainer">
                                                    <div class="input-group">
                                                        <input type="date" placeholder="" class="form-control"
                                                            [(ngModel)]="dateOfPayment" />
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="form-group row pt-4">
                                                <label class="col-md-2 control-label line_2 rem_1">
                                                    {{labelName?.Modeofpayment}} <span class="text-danger">*</span></label>
                                                <div class="col-md-4 inputGroupContainer">
                                                    <div class="input-group">
                                                        <select
                                                            class="form-control form-control-sm shadow-sm rounded-lg"
                                                            (change)="mopChange($event)" [(ngModel)]="modeOfPayment">
                                                            <option value="" [selected]="true">select</option>
                                                            <option value=1>Cash</option>
                                                            <option value=2>Cheque</option>
                                                            <!-- <option value="PosMachine">Pos Machine</option> -->
                                                            <option value=3>Online</option>

                                                        </select>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="form-group row pt-4">
                                                <label class="col-md-2 control-label line_2 rem_1">
                                                   {{labelName?.PayableAmount}} <span class="text-danger">*</span></label>
                                                <div class="col-md-4 inputGroupContainer">
                                                    <div class="input-group">
                                                        <input placeholder="" class="form-control" type="number"
                                                            [(ngModel)]="totalAmount" disabled>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="form-group row pt-4" *ngIf="Online">
                                                <label class="col-md-2 control-label line_2 rem_1">
                                                    {{labelName?.Transactionnumber}}<span class="text-danger">*</span></label>
                                                <div class="col-md-4 inputGroupContainer">
                                                    <div class="input-group">
                                                        <input type="text" placeholder="" class="form-control"
                                                            type="number" [(ngModel)]="transactionNumber">
                                                    </div>

                                                </div>
                                            </div>


                                            <div class="form-group row pt-4" *ngIf="Cheque">
                                                <label class="col-md-2 control-label line_2 rem_1">
                                                    {{labelName?.Chequenumber}}<span class="text-danger">*</span></label>
                                                <div class="col-md-4 inputGroupContainer">
                                                    <div class="input-group">
                                                        <input placeholder="" class="form-control" type="number"
                                                            [(ngModel)]="chequeNumber" />
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="form-group row pt-4" *ngIf="Cheque">
                                                <label class="col-md-2 control-label line_2 rem_1">
                                                    {{labelName?.Chequedate}}<span class="text-danger">*</span></label>
                                                <div class="col-md-4 inputGroupContainer">
                                                    <div class="input-group">
                                                        <input type="date" placeholder="" class="form-control"
                                                            [(ngModel)]="chequeDate" />
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="form-group row pt-4" *ngIf="Cheque">
                                                <label class="col-md-2 control-label line_2 rem_1">
                                                    {{labelName?.Bankname}}<span class="text-danger">*</span></label>
                                                <div class="col-md-4 inputGroupContainer">
                                                    <div class="input-group">
                                                        <input type="text" placeholder="" class="form-control"
                                                            [(ngModel)]="bankName"
                                                            onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">
                                                    </div>

                                                </div>
                                            </div>

                                            <!-- <div class="form-group row pt-4" *ngIf="posMachine">
                                                <label class="col-md-2 control-label line_2 rem_1">
                                                    Reference number<span class="text-danger">*</span></label>
                                                <div class="col-md-4 inputGroupContainer">
                                                    <div class="input-group">
                                                        <input type="text" placeholder="" class="form-control" 
                                                            onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">
                                                    </div>
                    
                                                </div>
                                            </div>
                                            <div class="form-group row pt-4" *ngIf="posMachine">
                                                <label class="col-md-2 control-label line_2 rem_1">
                                                    Credit/Debit card<span class="text-danger">*</span></label>
                                                <div class="col-md-4 inputGroupContainer">
                                                    <div class="input-group">
                                                        <select class="form-control form-control-sm shadow-sm rounded-lg" (change)="mopChange($event)">
                                                            <option value="" [selected]="true">select</option>
                                                            <option value="creditCard">Credit Card</option>
                                                            <option value="debitCard">Debit Card</option>
                                                           
                                                       
                                                        </select>
                                                    </div>
                    
                                                </div>
                                            </div>
                                            <div class="form-group row pt-4" *ngIf="posMachine">
                                                <label class="col-md-2 control-label line_2 rem_1">
                                                    Card type<span class="text-danger">*</span></label>
                                                <div class="col-md-4 inputGroupContainer">
                                                    <div class="input-group">
                                                        <select class="form-control form-control-sm shadow-sm rounded-lg" (change)="mopChange($event)">
                                                            <option value="" [selected]="true">select</option>
                                                            <option value="visaCard">Visa Card</option>
                                                            <option value="masterCard">Master Card</option>
                                                            <option value="maestroCard"> Maestro Card</option>
                                                         
                                                       
                                                        </select>
                                                    </div>
                    
                                                </div>
                                            </div> -->






                                        </div>





                                        <div class="text-center pt-4">
                                            <button id='update' type="button" class="btn btn-sm  btn-save"
                                                [disabled]="!isDesable" (click)="Update()">Update</button>
                                        </div>

                                        <div class="text-center pt-4" *ngIf="!table.length">No Records to display</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>