<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.BookReturn}}</h5>
                                        <!-- <h5 class="mb-0">{{headingName.ListOfBooksAllocated}}Book Return</h5> -->
                                    </div>
                                    <div class="card-body">
                                        <!-- <div class="text-right mb-1">
                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                                data-bs-target="#myModal">Add Book</button>
                                        </div> -->
                                        <div class="col-xl-12">
                                            <div class="table">
                                                <form class="well form-horizontal" [formGroup]="myForm"
                                                    (ngSubmit)="onSubmit(myForm)">
                                                    <fieldset>
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group row">
                                                                    <label class="col-md-4 control-label line_2 rem_1">
                                                                        {{labelName?.StudentId}}<span
                                                                            class="text-danger">*</span></label>
                                                                    <div class="col-md-8 inputGroupContainer">
                                                                        <div class="input-group"> <strong>:</strong>
                                                                            &nbsp;
                                                                            <input type="text" placeholder=""
                                                                                class="form-control" (change)=scanQr($event.target.value) >
                                                                        </div>
                                                                        <p style="color:black">* please select the textbox and scan the bar code</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>&nbsp;&nbsp;




                                                    </fieldset>
                                                    <br><br>
                                                    <!-- <div class="text-center">
                                                        <button type="submit" [disabled]="myForm.invalid"
                                                        class="btn btn-sm  btn-save">Submit</button>
                                                    </div> -->
                                                </form>
                                                <!-- <button class="btn btn-primary"
                                                    type="button">Search</button>&nbsp;&nbsp;
                                                <button type="button" class="btn btn-danger">Cancel</button> -->
                                            </div>
                                        </div>

                                        <div class="pt-4" *ngIf="qrCode.length">
                                            <table id="patergraph" datatable
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th>Book Id </th>
                                                        <th>Issue Date</th>
                                                        <th>Due Date</th>
                                                        <th>Book Title</th>
                                                        <th>Accession No</th>
                                                        <th>Return Date</th>
                                                        <th>Action</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of qrCode">
                                                        <td>{{item.book_id}}</td>
                                                        <td>{{item.book_std_date_of_issue | date:'dd/MM/yyyy'}}</td>
                                                        <td>{{item.due_date | date:'dd/MM/yyyy'}}</td>
                                                        <td>{{item.book_name}}</td>
                                                        <td>{{item.bar_code_id}}</td>
                                                        <td>
                                                            <div class="col-md-8">
                                                                <input class="form-control" type="date"
                                                                    min="{{minDate}}"
                                                                    [(ngModel)]="bookStdId">
                                                            </div>
                                                        </td>
                                                      <!-- <td> <input type="button" class="btn btn-primary"(change)="calendarSelect($event,item.BOOK_STD_ID)">Return</td> -->
                                                      <td style="display: flex; text-align: center;">
                                                        <button type="button" class="btn btn-primary mb-1"
                                                        (click)="calendarSelect($event,item.book_std_id)">Return</button>
                                                    </td>
                                                    <!-- <td> <input type="date" id=""  min="{{minDate}}"  name="calendar"(change)="calendarSelect(item.return_date,item.book_std_id)"></td> -->
                                                              
                                                    </tr>
                                                </tbody>
                                            </table>
                                            
                                            <div class="text-center pt-4 text-red" *ngIf="!qrCode.length">
                                                No Records to display
                                            </div>  
                                        </div>
                                    </div>






                                    <!-- <div class="card-body">
                                        <div class="col-xl-12">
                                            <div class="table">
                                                <div *ngIf="returnDate.length">
                                                    <table id="patreggraph" datatable [dtOptions]="dtOptions"
                                                        class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                        style="width:100%">
                                                        <thead class="top"
                                                            style="background-color: #624FD7; color:#ffffff;">
                                                            <tr>
                                                                <th> Book Tittle </th>
                                                                <th> BookId/ISBN </th>
                                                                <th> Department </th>
                                                                <th> Name </th>
                                                                <th> Issue Date </th>
                                                                <th> Return Date </th>
                                                                <th> Actual Return Date</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let item of returnDate">
                                                                <td>{{item.BOOK_NAME}}</td>
                                                                <td>{{item.BOOK_CODE}}</td>
                                                                <td>{{item.DEPARTMENT_NAME}}</td>
                                                                <td>{{item.NAME}}</td>
                                                                <td>{{item.BOOK_STD_DATE_OF_ISSUE | date:'dd/MM/yyyy'}}</td>
                                                                <td>{{item.BOOK_STD_RETURN_DATE | date:'dd/MM/yyyy'}}</td>
                                                                <td> <input type="date" id=""  min="{{minDate}}"  name="calendar"(change)="calendarSelect($event,item.BOOK_STD_ID)"></td>
                                                              
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div class="text-center" *ngIf="!returnDate.length">
                                                    No Records to display
                                                </div>                                               

                                                <div class="text-center pt-2">
                                                    <button type="button" (click)="ok()"
                                                    class="btn btn-danger btn-sm">Close</button>
                                                </div>

                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>