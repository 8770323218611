import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';

import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/pages/base.component';
import { CommonService } from 'src/app/services/common.service';
import { FileuploadService } from 'src/app/services/fileupload.service';

@Component({
  selector: 'app-forum',
  templateUrl: './forum.component.html',
  styleUrls: ['./forum.component.css']
})
export class ForumComponent  extends BaseComponent implements OnInit {

  cources: Array<any> = [];
  courceId: string = '';
  labelName:any={};
  headingName:any= {};
  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
  workItemId: any;

  constructor(private active : ActivatedRoute,private fb: FormBuilder,  CommonService: CommonService,  toastr: ToastrService, private FileuploadService: FileuploadService) {
    
    
    super(CommonService,toastr)
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadlabels();
    this.loadForums()
    this.getCourses();
    this.loadHeadings();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      MNAME: [localStorage.getItem('Username'), Validators.required,],
      CourseId: ['', Validators.required],
      Description: ['', Validators.required],
      Topic: ['', Validators.required],
      Attachment: ['']
    })
  }

  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
     this.labelName = res;    
    })
  }
  
  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }



  getCourses() {
    
    if(this.roleId == '3'){
      this.activeSpinner()
      this.CommonService.getCourses().subscribe((res: any) => {
        this.cources = res;
        console.log(res,this.cources)
        this.deactivateSpinner()
      }, e => {
        this.deactivateSpinner()
      })

    }else{
    this.activeSpinner()
    this.CommonService.getAdminCourses().subscribe((res: any) => {
      this.cources = res;
      console.log(res,this.cources)
      this.deactivateSpinner()
    }, e => {
      this.deactivateSpinner()
    })
  }

  }


  loadForums() {
    this.activeSpinner()
    let payLoad = {
      TENANT_CODE: this.tId||this.TenantCode,
      RoleId:this.roleId
    }
    this.CommonService.postCall("LoadFourm", payLoad).subscribe((res: any) => {
      this.table = [];
      if(res instanceof Array){
        this.table = res;
       
      }
      this.renderDataTable()
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner() })
  }


  close() {
    this.myForm.reset();
    this.myForm.controls['MNAME'].setValue(localStorage.getItem('Username'))
  }
  // add() { }


  onSubmit(form: FormGroup) {
    let payLoad = form.value;
    payLoad['TenantCode'] = localStorage.getItem('TenantCode');
    payLoad['CREATEDBY'] = localStorage.getItem('UserId');
    payLoad['RoleId'] = localStorage.getItem('RoleId');
    this.activeSpinner();
    this.CommonService.postCall('AddTopic', payLoad).subscribe(
      (res: any) => {
        this.deactivateSpinner();
        this.loadForums();
        this.toastr.success('Forum topic created Successfully')
        document.getElementById('md_close').click()
      }, error => { this.deactivateSpinner(); this.toastr.error(error.message ? error.message : error) }
    )

  }

  changeFile(event) {
    if (!this.courceId) {
      this.toastr.warning('Please select course name');
      return
    }
    if (event.target.files.length > 0) {
      let file: File = event.target.files[0];
      let name = file.name;
      let filetype = name.split('.').pop()
      let types: Array<any> = ['doc', 'docx', 'pdf', 'jpg', 'gif', 'png', 'xlsx', 'xlr', 'ppt', 'pptx', 'jpeg', 'zip']
      let check = types.includes(filetype);
      if (check) {
        this.file = file;
        this.upload()
      }
      else {
        // alert(' Please upload pdf and doc file formats only.')
        this.toastr.warning('Please upload the' + JSON.stringify(types))
        event.target.value = ''
      }
    }


  }
  upload() {
    const formData = new FormData();
    formData.append('File', this.file);
    formData.append('ClientDocs', 'ClientDocs');
    formData.append('Course', this.courceId);

    this.activeSpinner();
    this.FileuploadService.upload(formData, 'UploadTopicFiles').subscribe((res: any) => {
      try {
        this.fileName = res.path;
        if (this.fileName) {
          this.deactivateSpinner()
          this.myForm.controls['Attachment'].setValue(this.fileName)
        }
      } catch (e) {
        console.log(e)
      }

    }, err => { this.deactivateSpinner(); })
  }
  changeTname() {
    this.loadForums()
  }
  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }
}