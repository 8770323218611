import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { VirtualTimeScheduler } from 'rxjs';
import { constants } from 'src/app/constants';
import { CommonService } from 'src/app/services/common.service';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-assessment-result',
  templateUrl: './assessment-result.component.html',
  styleUrls: ['./assessment-result.component.css']
})
export class AssessmentResultComponent extends BaseComponent implements OnInit {
  users: [] = [];
  aId: any = '1';
  uId: string;
  labelName: any = {};
  headingName: any = {};
  workItemId: any;
  constructor(CommonService: CommonService, toastr: ToastrService,private active : ActivatedRoute) {
    super(CommonService, toastr)
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadlabels();
    this.loadHeadings();
  }

  ngOnInit(): void {
    if (this.roleId != '4') {
      this.changeTname();
    }
  }

  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }


  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  changeTname() {
    this.courseId = '';
    // this.aId='';
    this.uId = '';
    let tnt_code = this.tId == '' ? this.TenantCode : this.tId || this.TenantCode;
    this.activeSpinner();
    this.CommonService.postCall('GetStudents', { TENANT_CODE: tnt_code }).subscribe(
      (res: any) => {
        this.deactivateSpinner();
        this.users = res;
      }, err => {
        this.deactivateSpinner();
      }
    )
  }
  userChange() {
    this.courseId = '';
    // this.aId='';
    this.typeChange();
  }
  typeChange() {
    this.courseId = '';
    if (this.uId && this.uId == '') return
    let id = this.uId == '' ? 0 : this.uId;
    this.activeSpinner();
    let url = constants['GetCourses'] || 'GetCourses'
    this.CommonService.getCall(url + '/' + id + '/3',).subscribe(
      (res) => {
        this.deactivateSpinner();
        this.courses = res;
      }, err => {
        this.deactivateSpinner();
      }
    )
  }
  submit() {
    if (this.courseId && this.courseId != '' && this.uId && this.uId != '' && this.aId && this.aId != '') {
      this.activeSpinner();
      let payload = {
        TENANT_CODE: this.tId ? this.tId : this.TenantCode,
        RESULT_STUDENT_ID: parseInt(this.uId),
        RESULT_ASSESSMENT_TYPE: parseInt(this.aId),
        RESULT_COURSE_ID: parseInt(this.courseId)
      }
      this.CommonService.postCall('GetAssessmentResult', payload).subscribe(
        (res) => {
          this.deactivateSpinner();
          this.table = res;
        }, err => {
          this.deactivateSpinner();
        }
      )
    } else {
      this.toastr.warning("Please Select Mandatory Fields");
    }
  }
  update(item) {
    if (item.RESULT_ATTEMPTSLEFT.trim() != '' && item.RESULT_ATTEMPTSLEFT > -1) {
      this.activeSpinner();
      let payload = {
        RESULT_ATTEMPTSLEFT: item.RESULT_ATTEMPTSLEFT,
        RESULT_ID: item.RESULT_ID
      }
      this.CommonService.postCall('UpdateAssessmentResult', payload).subscribe(res => {
        this.deactivateSpinner();
        this.toastr.success("Information Saved successfully");
      }, e => {
        this.deactivateSpinner();
        this.toastr.error(e.error ? e.error.text || e.error : "Information not updated")
      })
    } else {
      this.toastr.warning("Please Enter The Value")
    }
  }
}
