import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-assignments',
  templateUrl: './assignments.component.html',
  styleUrls: ['./assignments.component.css']
})
export class AssignmentsComponent implements OnInit {
  assignments: Array<any> = [];
  headingName: any = {};
  workItemId: any;

  constructor(private route: Router, private active: ActivatedRoute, private CommonService: CommonService) {
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
    this.getLoadAssignments();
    this.loadHeadings();
  }

  ngOnInit(): void {
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }


  add() {
    this.route.navigate(['home/addAssignments'], { queryParams: { wId: this.workItemId } })
  }
  getLoadAssignments() {
    this.activeSpinner();
    this.CommonService.loadAssignments().subscribe((res: any) => {
      this.deactivateSpinner();
      this.assignments = res;
    }, (err) => { this.deactivateSpinner(); })
  }

  edit(data) {
    let params = {
      id: data.ASSIGNMENT_ID,
      wId: this.workItemId,
    }
    this.route.navigate(['home/addAssignments'], { queryParams: params })
  }
}
