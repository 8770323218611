import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-my-posts',
  templateUrl: './my-posts.component.html',
  styleUrls: ['./my-posts.component.css']
})
export class MyPostsComponent implements OnInit {
  case = 1;
  headingName: any = {};
  workItemId: any;
  constructor(private active : ActivatedRoute,private CommonService: CommonService) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadHeadings();
    
  }

  ngOnInit(): void {
  }
  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  selectTab(value) {
    this.case = value
  }

}
