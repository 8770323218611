
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { EditUserRegistrationComponent } from '../edit-user-registration/edit-user-registration.component';
@Component({
  selector: 'app-hostel-room',
  templateUrl: './hostel-room.component.html',
  styleUrls: ['./hostel-room.component.css']
})
export class HostelRoomComponent implements OnInit {
  @Input() parent: EditUserRegistrationComponent;
  @Input() editData: any = {};
  isEdit: boolean = false;
  myForm: FormGroup;
  campus: Array<any> = [];
  block: Array<any> = [];
  floor: Array<any> = [];
  room: Array<any> = [];
  table: Array<any> = [];
  index: any;
  changed: boolean = false;
  hostelStudentData: any = {};
  labelName:any={};

  constructor(private fb: FormBuilder, private CommonService: CommonService, private toastr: ToastrService) {

    this.campusDropdown();
    this.getLabels();
    
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      campus_id: [0],
      block_id: [0],
      floor_id: [0],
      room_id: [0],
      joined_date: [null],
      amount: [0]
    });
    this.parent.childs['HostelStudent'] = this;
  }

  ngOnChanges() {
    // if (Object.keys(this.editData).length && !this.changed) {
    this.changed = true;
    this.hostelStudentData = this.editData['HostelStudent'];
    this?.dataTransform();
    // this.assignDataForm();
    //}
  }
  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  getLabels() {
    this.labelName ={};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${localStorage.getItem('regWorkItem')}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName=res;
     
      
    })
  }


  campusDropdown() {
    let payload = {
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('EmsCampus/CampusDropDownList', payload).subscribe((res: any) => {
      this.campus = res;
    })
  }

  campusChange(id) {
    let payload = {
      "campus_id": id
    }
    this.CommonService.postCall('EmsBlock/BlockDropDownList', payload).subscribe((res: any) => {
      this.block = res;
    })
  }

  blockChange(id) {
    let payLoad = {
      "block_id": id
    }
    this.CommonService.postCall('EmsFloor/FloorDropDownList', payLoad).subscribe((res: any) => {
      this.floor = res;
    })
  }

  floorChange(id) {
    let payLoad = {
      "floor_id": id
    }
    this.CommonService.postCall('EmsRoom/RoomDropDownListList', payLoad).subscribe((res: any) => {
      this.room = res;

    })
  }

  roomChange(event: any) {

  }




  dataTransform() {
    this.campusChange(this.hostelStudentData?.campus_id??0);
    this.blockChange(this.hostelStudentData?.block_id??0);
    this.floorChange(this.hostelStudentData?.floor_id??0);
    let controls=this.myForm?.controls;
    controls['campus_id']?.setValue(this.hostelStudentData?.campus_id??0);
    controls['block_id']?.setValue(this.hostelStudentData?.block_id??0);
    controls['floor_id']?.setValue(this.hostelStudentData?.floor_id??0);
    controls['room_id']?.setValue(this.hostelStudentData?.room_id??0);
    controls['amount']?.setValue(this.hostelStudentData?.amount);
    controls['joined_date']?.setValue(moment(this.hostelStudentData?.joined_date)?.format('yyyy-MM-DD'));  
  

  }
  close() {
    this.myForm.reset();
  }



}
