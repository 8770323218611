import { Component, OnInit } from '@angular/core';
import { CommonService } from './../../services/common.service';
import { ToastrService } from 'ngx-toastr';
import { dataDictionary } from 'src/app/dataDictionary';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-enroll',
  templateUrl: './enroll.component.html',
  styleUrls: ['./enroll.component.css']
})
export class EnrollComponent implements OnInit {

  table: Array<any> = [];
  courceId: string;
  schedulId: string;
  scheduls: Array<any> = [];
  cources: Array<any> = [];
  isParam: boolean = false;
  yearId: string;
  courseId: string;
  years: Array<any> = [];
  labelName: any = {};
  checkboxs: any;
  selectAllStudents: any;
  courseYear: Array<any> = [];
  headingName: any = {};
  workItemId: any;
  constructor(private active: ActivatedRoute, private CommonService: CommonService, private toastr: ToastrService) {
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
    this.loadcourses();
    this.getYear();
    this.getLabels();
    this.loadCourseYear();
    this.loadHeadings();

  }

  ngOnInit(): void {

  }

  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }
  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  loadcourses() {
    // LoadCourseSchedule
    // Loadyear Loadcourses
    this.activeSpinner()
    let payaload = {
      "TENANT_CODE": localStorage.getItem('TenantCode')
    }
    this.CommonService.getAdminCourses().subscribe((res: any) => {
      this.cources = res;
      this.deactivateSpinner();
    }, e => this.deactivateSpinner());
  }

  courceChange() {
    let payload = {
      CourseId: this.courceId
    }
    this.activeSpinner()
    this.CommonService.getAdminCourseSchedule(payload).subscribe((res: any) => {
      this.scheduls = res;
      this.deactivateSpinner();
    }, e => this.deactivateSpinner());

  }
  getYear() {
    this.activeSpinner()
    let payload = {


    }
    this.CommonService.postCall('Loadyear', payload).subscribe((res: any) => {
      this.years = res;
      this.deactivateSpinner();
    }, e => this.deactivateSpinner());
  }
  schedulChange() { }
  yearValue() { }


  loadCourseYear() {
    this.courseYear = [];
    this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.CourseYear }).subscribe((res: any) => {
      this.courseYear = res;
    })


  }
  yearChange() {
    this.activeSpinner();
    let payLoad = {
      "TENANT_CODE": localStorage.getItem('TenantCode'),
      "STUDENT_PAYMENT_COURSESHD_ID": this.schedulId,
      "COURSE_ID": this.courceId,
      "STUDENT_PAYMENT_CORPORATEID": this.yearId,
      "course_year_id": this.courseId,
    };
    this.CommonService.postCall('loadstudents', payLoad).subscribe((res) => {
      this.deactivateSpinner();
      this.table = [];
      setTimeout(() => {
        this.table = res;
      }, 10)
    }, e => { this.deactivateSpinner() })

  }

  checkAll(event: any) {
    this.table.forEach(e => {
      e.checked = event.target.checked
      if (event.target.checked == true) {
        this.table.forEach(a => a.Paymentstatus = true);
      } else {
        this.table.forEach(a => a.Paymentstatus = false);
      }
    })
  };


  enroll() {
    let array = this.table.filter((data) => { return data.Paymentstatus });
    if (!array.length) {
      this.toastr.warning('Please select atleast one user');
      return
    } else {
      this.activeSpinner();
      let payLoad = [];
      this.table.map((data) => {
        let object: any = {
          "CourseId": this.courceId,
          "CREATEDBY": localStorage.getItem('UserId'),
          "TNT_CODE": localStorage.getItem('TenantCode'),
          "UserId": data.UserId,
          "CourseScheduleId": this.schedulId,
          "STUDENT_PAYMENT_ID": data.student_payment_id,
          "Status": data.Paymentstatus == 1 ? true : false ,
          "ModifiedBy": localStorage.getItem('UserId'),
        }
        payLoad.push(object)
      });
      this.CommonService.postCall('CreateEnroll', payLoad).subscribe((res) => {
        this.deactivateSpinner();
        window.location.reload();
        this.toastr.success('Enroll created Successfully')
      }, e => { this.deactivateSpinner() })
    }

  }





}
