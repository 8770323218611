import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-issue-information',
  templateUrl: './issue-information.component.html',
  styleUrls: ['./issue-information.component.css']
})
export class IssueInformationComponent implements OnInit {
  table: Array<any> = [];
  headingName: any = {};
  workItemId: any;
  constructor(private active:ActivatedRoute,private commonService: CommonService) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.load();
    this.loadHeadings();
  }

  ngOnInit(): void {
  }
  loadHeadings() {
    this.headingName = {};
    this.commonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  load() {
    this.table = [];
    this.commonService.getCall(`LibraryManagement/AvailableBooksReportByStudent/${localStorage.getItem('UserId')}`).subscribe((res: any) => {
      this.table = res;
    })

  }
}
