import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { isThisSecond } from 'date-fns';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-event-registrations-list',
  templateUrl: './event-registrations-list.component.html',
  styleUrls: ['./event-registrations-list.component.css']
})
export class EventRegistrationsListComponent extends BaseComponent implements OnInit {
  table: Array<any> = [];
  headingName: any = {};
  workItemId: any;
  labelName: any = {};
  constructor(private fb: FormBuilder, private active: ActivatedRoute,  CommonService: CommonService,  toastr: ToastrService, private router: Router) {
    super(CommonService, toastr);
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
    this.loadeventsList();
    this.loadHeadings();
    this.getLabels();
  }

  ngOnInit(): void {
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadeventsList() {
    this.CommonService.postCall(`EmsEventsCategories/RegistrationGetList`, { "tnt_code":this.tId || localStorage.getItem('TenantCode') }).subscribe((res: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = res;
      }, 10);
    })
  }

  edit(id) {
    this.router.navigate(['home/eventRegistration'], { queryParams: { erId: id, wId: this.workItemId } })
  }
  add() {
    this.router.navigate(['/home/eventRegistration'], { queryParams: { wId: this.workItemId } });
  }
}