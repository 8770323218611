<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.Survey}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right">
                                            <button type="button" class="btn btn-primary mb-1" data-bs-toggle="modal"
                                                data-bs-target="#myModal" (click)="add()">Add</button>
                                        </div>
                                        <div class="form-group ">
                                            <div class="row">
                                                <div *ngIf='isSuperAdmin' class="col-md-3">
                                                    <label for="sel1">{{labelName.TenantName}} : </label>
                                                    <select [(ngModel)]='tId ' (change)="changeTname()"
                                                        class="form-control form-control-sm shadow-sm rounded-lg"
                                                        id="sel1">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let item of tenanates" [value]='item.TNT_CODE'>
                                                            {{item.TNT_NAME}}</option>
                                                    </select>

                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="table.length" class="table-responsive">
                                            <table id="patergraph" datatable
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small display">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th> SurveyTitle</th>
                                                        <th> Course Name </th>
                                                        <th>No .of Questions</th>
                                                        <th>Author</th>
                                                        <th>Start Date</th>
                                                        <th>End Date</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.SURVEYTITLE}}</td>
                                                        <td>{{item.CourseNames}}</td>
                                                        <td>{{item.SURVEYQUESTIONS}}</td>
                                                        <td>{{item.FIRSTNAME}}</td>
                                                        <td>{{item.STARTDATE}}</td>
                                                        <td>{{item.ENDDATE}}</td>


                                                        <td style="display: flex;">
                                                            <button [ngClass]="{'none_all':item.IsPublished=='True'}"
                                                                class="btn text-success rem_2"
                                                                [disabled]="item.IsPublished=='True'"
                                                                (click)="addQuestion(item)">+</button>
                                                            <span class="edit_icon" (click)="edit(item)">
                                                                <i class="fas fa-pen pt-1 mt-1"></i></span>
                                                            <button class="btn text-danger rem_1"
                                                                (click)="delete(item)">X</button>
                                                            <img src="./../../../assets/images/sprites/Right (1).png"
                                                                [disabled]="item.IsPublished=='True'"
                                                                [ngClass]="{'none_all':item.IsPublished=='True'}"
                                                                class="edit_icon publish_icon" (click)="publish(item)">
                                                        </td>

                                                        <!-- <td style="display: flex;"> <button
                                                                [ngClass]="{'none_all':item.IsPublished=='True'}"
                                                                class="btn text-success rem_2"
                                                                [disabled]="item.IsPublished=='True'"
                                                                (click)="addQuestion(item)">+</button> <input
                                                                type="image" [disabled]="item.IsPublished=='True'"
                                                                [ngClass]="{'none_all':item.IsPublished=='True'}"
                                                                src="./../../../assets/images/sprites/edit.svg"
                                                                class="edit_icon" (click)="edit(item)"> <button
                                                                class="btn text-danger rem_1"
                                                                (click)="delete(item)">X</button>
                                                            <img src="./../../../assets/images/sprites/Right (1).png"
                                                                [disabled]="item.IsPublished=='True'"
                                                                [ngClass]="{'none_all':item.IsPublished=='True'}"
                                                                class="edit_icon publish_icon" (click)="publish(item)">
                                                        </td> -->
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>