
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { FileuploadService } from 'src/app/services/fileupload.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.css']
})
export class VehiclesComponent implements OnInit {
  table: Array<any> = [];
  myForm: FormGroup;
  isEdit: boolean = false;
  editData: any;
  file: File;
  fileName: any;
  vehicleId: number;
  documentType = 'Vehicles';
  labelName: any = {};
  attachment: any;
  uploadAtt: any = '';
  headingName: any = {};
  workItemId: any;
  constructor(private fb: FormBuilder,private active :ActivatedRoute, private CommonService: CommonService, private toastr: ToastrService, private FileuploadService: FileuploadService, private route: Router) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadVehicles();
    this.getLabels();
    this.loadHeadings();

  }

  ngOnInit(): void {
    this.formInit();
  }
  formInit() {
    this.myForm = this.fb.group({
      registration_number: ['', [Validators.required, Validators.pattern(/^[A-Z|a-z]{2}\s?[0-9]{1,2}\s?[A-Z|a-z]{0,3}\s?[0-9]{4}$/)]],
      engine_number: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      model_number: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      color: ['', Validators.required],
      seating_capacity: ['', Validators.required],
      sanctioned_capacity: [0],
      chassis_number: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      insurance_number: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      insurance_start_date: ['', Validators.required],
      insurance_end_date: ['', Validators.required],
      remarks: [''],
      vehicle_type: ['', Validators.required],
      fitness_start_date: [null],
      fitness_end_date: [null],
      road_tax_start_date: [null],
      road_tax_end_date: [null],
      permit_start_date: [null],
      permit_end_date: [null],
      // Image: ['',],
      status: ['', Validators.required]
    });
  }
  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  
loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  loadVehicles() {
    this.CommonService.getCall(`Transport/GetVehicleList/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = res;
      }, 10);
    })
  }

  onSubmit(form: FormGroup) {
    let value = form.value;
    value['tnt_code'] = localStorage.getItem('TenantCode');
    value['created_by'] = localStorage.getItem('UserId');
    value['modified_by'] = localStorage.getItem('UserId');
    console.log(value);
    if (this.isEdit) {
      value['modified_by'] = localStorage.getItem('UserId');
      value['vehicles_id'] = this.editData.vehicles_id;
      value['document_type'] = this.documentType;
      value['attachment'] = this.uploadAtt || this.editData.attachment;
      this.CommonService.postCall('Transport/UpdateVehicle', value).subscribe((res: any) => {

        this.toastr.success("Vehicle Updated Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Vehicle Not Updated')
      })
    } else {

      this.CommonService.postCall('Transport/CreateVehicle', value).subscribe((res: any) => {
        this.vehicleId = res.vehicles_id;
        this.loadVehicles();
        this.upload();
        // this.upload();       
        this.toastr.success("Vehicle Created Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Vehicle Not created')

      })
    }
  }

  edit(vId: any) {
    console.log(vId);
    this.isEdit = true;
    this.CommonService.getCall(`Transport/GetVehicle/${vId}/${this.documentType}`).subscribe((res: any) => {
      this.attachment = res.attachment
      if (res instanceof Array) {
        this.editData = res[0];
        this.dataTransfer(res[0])
      } else {
        this.editData = res;
        this.dataTransfer(res);
      }
    }
    )

  }

  dataTransfer(data) {
    let ctrls = this.myForm.controls;
    Object.keys(ctrls).map(key => {
      let ctrl = ctrls[key];
      ctrl.setValue(data[key])
    })

    ctrls['insurance_start_date'].setValue(moment(this.editData['insurance_start_date']).format('yyyy-MM-DD'));
    ctrls['insurance_end_date'].setValue(moment(this.editData['insurance_end_date']).format('yyyy-MM-DD'));
    if (this.editData['fitness_start_date'] != null)
      ctrls['fitness_start_date'].setValue(moment(this.editData['fitness_start_date']).format('yyyy-MM-DD'));
    if (this.editData['fitness_end_date'] != null)
      ctrls['fitness_end_date'].setValue(moment(this.editData['fitness_end_date']).format('yyyy-MM-DD'));
    if (this.editData['fitness_start_date'] != null)
      ctrls['road_tax_start_date'].setValue(moment(this.editData['road_tax_start_date']).format('yyyy-MM-DD'));
    if (this.editData['road_tax_start_date'] != null)
      ctrls['road_tax_end_date'].setValue(moment(this.editData['road_tax_end_date']).format('yyyy-MM-DD'));
    if (this.editData['road_tax_end_date'] != null)
      ctrls['permit_start_date'].setValue(moment(this.editData['permit_start_date']).format('yyyy-MM-DD'));
    if (this.editData['permit_start_date'] != null)
      ctrls['permit_end_date'].setValue(moment(this.editData['permit_end_date']).format('yyyy-MM-DD'));
  }

  // changeFile(event: any) {
  //   if (event.target.files.length > 0) {
  //     let file: File = event.target.files[0];
  //     let name = file.name;
  //     let filetype = name.split('.').pop()

  //     // if (check) {
  //     this.file = file;

  //   }
  // }

  changeFile(event) {
    if (event.target.files.length > 0) {
      let file: File = event.target.files[0];
      let name = file.name;
      let filetype = name.split('.').pop()
      let types: Array<any> = ['doc', 'docx', 'pdf', 'jpg', 'gif', 'png', 'xlsx', 'xlr', 'ppt', 'pptx', 'jpeg']
      let check = types.includes(filetype);
      if (check) {
        this.file = file;
        if (this.isEdit == true) {
          this.upload();
        }

      }
      else {
        // alert(' Please upload pdf and doc file formats only.')
        this.toastr.warning('Please upload pfc,doc and jpeg file formats only.')
        event.target.value = ''
      }
    }
  }

  upload() {
    let data =
    {
      "document_title": this.documentType,
      "document_type": this.documentType,
      "document_type_id": this.vehicleId,
      "verified_by": localStorage.getItem('UserId'),
      "created_by": localStorage.getItem('UserId'),
      "modified_by": localStorage.getItem('UserId'),
      "tnt_code": localStorage.getItem('TenantCode')
    }
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('attachment', this.file);
    this.FileuploadService.upload(formData, 'Registration/UploadAttachments').subscribe((res: any) => {
      this.uploadAtt = res.attachment;
    })
    //   try {
    //     this.fileName = res.path;
    //     if (this.fileName) {
    //       this.myForm.controls['QuestionImage'].setValue(this.fileName)
    //     }
    //   } catch (e) {
    //     console.log(e)
    //   }

    // }, err => { this.toastr.warning('Upload Failed') })
  }
  assign(vId: any) {
    this.route.navigate(['home/assignDriver/add'], { queryParams: { id: vId ,wId:this.workItemId} });
  }

  vehicleService(vId: any, rno: any) {
    this.route.navigate(['home/vehicleService'], { queryParams: { id: vId, rno: rno,wId:this.workItemId } });
  }

  deleteVehicle(VId: any) {
    if (confirm("Are you sure to Delete Vehicle")) {
      this.CommonService.getCall(`Transport/DeleteVehicle/${VId}`).subscribe((res: any) => {
        this.loadVehicles();
      })
    }
  }

  close() {
    this.isEdit = null;
    this.formInit();
    this.editData = {};
  }

}