import { Component, OnInit } from '@angular/core';
import { CommonService } from './../../services/common.service'
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as moment from 'moment'
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/pages/base.component';

declare var $: any

@Component({
  selector: 'app-fee-description',
  templateUrl: './fee-description.component.html',
  styleUrls: ['./fee-description.component.css']
})
export class FeeDescriptionComponent extends BaseComponent implements OnInit {
  table: [] = [];
  yearId: string | number = '';
  academicYear: [] = [];
  data: any = {};
  TenantCode: any;
  isParam: boolean = false;
  headingName: any = {};
  labelName: any = {};
  workItemId: any;


  constructor(private active: ActivatedRoute, CommonService: CommonService, private sanitizer: DomSanitizer, toastr: ToastrService, private route: Router) {
    super(CommonService, toastr);
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
    this.getAcademicYear()
    this.loadlabels();
    this.loadHeadings();
  }

  ngOnInit(): void {
  }

  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  getAcademicYear() {
    this.activeSpinner();
    this.CommonService.getCall(`Academic/GetAcademicDropDownList/${this.tId || localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      //this.CommonService.getCall('Academic/GetAcademicDropDownList/71258324').subscribe((res: any) => { 
      this.deactivateSpinner();
      this.table = res
    }, e => { this.deactivateSpinner(); })

  }


  yearChange(event) {
    this.activeSpinner();
    let payLoad = {
      ACADEMIC_ID: this.yearId,
      tnt_code: 71258324,
    }
    this.CommonService.getCall('FeeDescription/GetFeeDescriptionList/' + event.target.value).subscribe((res: any) => {
      this.deactivateSpinner();
      this.academicYear = res;

      // this.init()
    }, (e) => { this.deactivateSpinner(); })

  }

  selectYear(id, index) {

  }

  onEdit(feedescription_curriculum_id, flag) {

    this.route.navigate(['/home/editFees/'], { queryParams: { dId: feedescription_curriculum_id, flag: flag } })

  }



}
