import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ThemeService } from 'ng2-charts';

@Component({
  selector: 'app-library-books-allocation',
  templateUrl: './library-books-allocation.component.html',
  styleUrls: ['./library-books-allocation.component.css']
})
export class LibraryBooksAllocationComponent implements OnInit {
  myForm: FormGroup;
  courses: Array<any> = [];
  Schedules: Array<any> = []
  // departments: Array<any> = [];
  students: Array<any> = [];
  chapters: any[];
  sheduleId: string;
  courceId: any;
  labelName: any = {};
  headingName: any = {};
  workItemId: any;
  isEdit: boolean = false;
  qrCode: Array<any> = [];
  singleData: any = {};
  availableData: any = {};
  availableBooks: any;
  studentId: any = {};
  bookId: any;
  minDate: any = moment().format('yyyy-MM-DD');
  bookStd: any;
  enrollmentNo: any;
  barCodeId: any;
  studentIdss:number=0
  
  constructor(private commonService: CommonService, private fb: FormBuilder, private active: ActivatedRoute, private route: Router,private toastr: ToastrService) {
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
    this.getLabels();
    this.loadHeadings();

  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      issueBook: ['', Validators.required],
      availableBooks: [''],
      IssueDate: ['', Validators.required],
      dueDate: ['', Validators.required],
      FineAmount: ['', Validators.required],
      // courseName: ['', Validators.required],
      // courseSchedule: ['', Validators.required],
      //  department: ['', Validators.required],
      // student: ['', Validators.required],

    })
    // this.loadCourse();
    // this.loadDepartment();
  }
  getLabels() {
    this.labelName = {};
    this.commonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.commonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  activeSpinner() {
    this.commonService.activateSpinner();
  }

  deactivateSpinner() {
    this.commonService.deactivateSpinner()
  }

  // loadCourse() {
  //   this.activeSpinner();
  //   this.commonService.getAdminCourses().subscribe((res: any) => {
  //     this.courses = res;
  //     this.deactivateSpinner();
  //   }, e => {
  //     this.deactivateSpinner();
  //   })

  // }

  // courseChange(event) {

  //   this.chapters = [];
  //   this.sheduleId = '';
  //   let data = {
  //     "CourseId": event.target.value
  //   }
  //   this.commonService.getAdminCourseSchedule(data).subscribe((res: any) => {
  //     this.deactivateSpinner()
  //     this.Schedules = res;
  //   }, e => { this.deactivateSpinner() })
  // }

  // sheduleChange(event) {
  //   this.activeSpinner();
  //   let payLoad = {
  //     "COURSESHD_ID": event.target.value
  //   }
  //   this.commonService.postCall('Account/usersbycourseShedule', payLoad).subscribe((res: any) => {
  //     this.deactivateSpinner();
  //     this.students = res;
  //   }, e => {
  //     this.deactivateSpinner();
  //   });
  // }

  // loadDepartment() {
  //   this.activeSpinner();
  //   let payload = {
  //     "TNT_CODE": localStorage.getItem('TenantCode')
  //   }
  //   this.commonService.postCall('Department/dropdown', payload).subscribe((res: any) => {
  //     this.departments = res;
  //     this.deactivateSpinner();
  //   }, e => {
  //     this.deactivateSpinner();
  //   })
  // }

  scanQr(StuId: any) {
    if(StuId==""){
      this.qrCode=[];
      this.singleData=[];
      return;
    }
    this.enrollmentNo = StuId;
    let payload = {
      "enrollment_no": StuId
    }
    this.commonService.postCall('LibraryManagement/StudentdetailsbyId', payload).subscribe((res: any) => {
      console.log(res);
      this.qrCode = res.book_issue;
      this.singleData = res;
      this.studentId = res.userid

    })
  }
  issueBooks(bookId: any) {
    this.bookId = bookId
    let payload = {
      "bar_code_id": bookId
    }
    this.commonService.postCall('LibraryManagement/BookdetailsbyId', payload).subscribe((res: any) => {
      this.availableData = res[0];
      this.availableBooks = res[0].available_books
      this.bookStd=res[0].book_id
      this.barCodeId=res[0].bar_code_id
    })
  }

  // onSubmit(form) {
  //   let studentObj = this.students.filter(x => x.USERID == form.value['student']);
  //   let departmentobj = this.courses.filter(x => x.COURSE_ID == form.value['courseName']);


  //   this.route.navigate(['/home/listofbooks'], { queryParams: { studentId: studentObj[0]['USERID'], departmentId: departmentobj[0]['COURSE_ID'], wId: this.workItemId } })

  // }
  onSubmit(form) {
    let val = form.value;
    let payLoad = {
      BOOK_STD_STUDENT_ID: this.studentId,
      BOOK_ID: this.bookStd,
      bar_code_id:this.barCodeId,
      BOOK_STD_DATE_OF_ISSUE: val.IssueDate,
      BOOK_STD_RETURN_DATE: val.dueDate,
      FINE_AMOUNT:val.FineAmount,
      BOOK_STD_ASSIGN:true,
      BOOK_STD_TNT_CODE:localStorage.getItem('TenantCode')
    }
  
    this.commonService.postCall('LibraryManagement/CreateBooksAllocation', [payLoad]).subscribe((res: any) => {
      this.studentIdss=0;
      this.close();
      this.qrCode = [];
      this.toastr.success("Book Assigned Successfully");
      window.location.reload();
      document.getElementById('md_close').click();
    }, err => {
      this.toastr.error(err.error ? err.error : 'Not Assigned')
    })

  }
  close() {
    this.myForm.reset();    
    this.availableData = {};
    this.availableBooks = ''
    this.studentId=''
    this.qrCode=[];
    this.singleData={};
    this.studentIdss=0;
   
  }
}


// departmentId: departmentobj[0]['DEPARTMENT_ID'], 