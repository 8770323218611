<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">

                    <div class="container-fluid">

                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.HostelVisitors}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right mb-2">
                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                                data-bs-target="#myModal">Add </button>
                                        </div>
                                        <div *ngIf="table.length">
                                            <table id="patreggraph" datatable
                                                class="table table-striped table-bordered mt-2 pt-2 table-sm small display"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624fd7; color:#ffffff;">
                                                    <tr>
                                                        <th> Student Name </th>
                                                        <th>Course</th>
                                                        <!-- <th> Course schedule </th> -->
                                                        <th> Visitor Name </th>
                                                        <th> Visitor In Time </th>
                                                        <th> Visitor Out Time </th>
                                                        <th> Relation </th>
                                                        <th> Action </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.studentname}}</td>
                                                        <td>{{item.course_name}}</td>
                                                        <!-- <td>{{item.courseshd_name}}</td> -->
                                                        <td>{{item.visitor_name}}</td>
                                                        <td>{{item.visitor_in_time | date:'dd/MM/yyyy HH:mm:ss'}}</td>
                                                        <td>{{item.visitor_out_time | date:'dd/MM/yyyy HH:mm:ss'}}</td>
                                                        <td>{{item.visitor_student_relation}}</td>
                                                        <td style="display: flex;">
                                                            <span data-bs-toggle="modal" data-bs-target="#myModal"
                                                                class="edit_icon"
                                                                (click)="edit(item.hostel_visitor_id)">
                                                                <i class="fas fa-pen pt-1 mt-1"></i></span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog  modal-lg">
        <div class="modal-content">

            <div class="modal-header py-2">
                <h6 *ngIf="!isEdit" class="modal-title w-100"> {{headingName.AddHostelVisitor}}</h6>
                <h6 *ngIf="isEdit" class="modal-title w-100"> {{headingName.EditHostelVisitor}}</h6>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>


            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.Campus}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName="campus_id"
                                        (change)="campusChange($any($event).target.value)"
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" [selected]="true">Select</option>
                                        <option value="" *ngFor="let item of campus" [value]='item.campus_id'>
                                            {{item.campus_name}}
                                        </option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('campus_id').touched">
                                    <span class="text-danger" *ngIf="myForm.get('campus_id').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.Block}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName="block_id" (change)="blockChange($any($event).target.value)"
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option value="" *ngFor="let item of block" [value]='item.block_id'>
                                            {{item.block_name}}
                                        </option>

                                    </select>
                                </div>
                                <div *ngIf="myForm.get('block_id').touched">
                                    <span class="text-danger" *ngIf="myForm.get('block_id').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.Floor}}<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName='floor_id'
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option value="" *ngFor="let item of floor" [value]="item.floor_id">
                                            {{item.floor_name}}
                                        </option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('floor_id').touched">
                                    <span class="text-danger" *ngIf="myForm.get('floor_id').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.Student}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName='visitor_student_id'
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option *ngFor="let student of students" [value]="student.USERID">
                                            {{student.FIRSTNAME}}
                                        </option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('visitor_student_id').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('visitor_student_id').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.VisitorName}}<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <input type="text" formControlName="visitor_name"
                                        onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));"
                                        class="form-control " />
                                </div>
                                <div *ngIf="myForm.get('visitor_name').touched">
                                    <span class="text-danger" *ngIf="myForm.get('visitor_name').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>


                        <!-- <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Visitor In Time<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <input type="datetime-local" formControlName="INTIME" class="form-control " />
                                </div>
                            </div>
                        </div> -->

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.VisitorInTime}}<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <input type="datetime-local" formControlName="visitor_in_time"
                                        class="form-control " />
                                </div>
                                <div *ngIf="myForm.get('visitor_in_time').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('visitor_in_time').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.VisitorOutTime}}<span class="text-danger"></span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <input type="datetime-local" formControlName="visitor_out_time"
                                        class="form-control " />
                                </div>
                                <!-- <div *ngIf="myForm.get('visitor_out_time').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('visitor_out_time').hasError('required')">
                                        This field is required.
                                    </span>

                                </div> -->
                            </div>
                        </div>


                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName?.Relation}} <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <strong>:</strong> &nbsp;
                                    <select formControlName='visitor_student_relation'
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option value="Parent">Parent</option>
                                        <option value="Guardian">Guardian</option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('visitor_student_relation').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('visitor_student_relation').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-save btn-sm" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-save btn-sm" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-danger btn-sm" id='md_close' (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>