<div class="default_class">
    <div class="content-wrapper p-0">

        <div class="container-fluid">

            <div class="row">
                <div class="col-xl-12">
                    <div>
                        <div>
                            <button *ngIf='isPrev' (click)='prev()'>Prev</button> <button (click)='next()'
                                *ngIf='isNext'>next</button>
                        </div>
                        <div>
                            <div *ngFor='let item of pages;let i=index' class="hide" [ngClass]='{"show":i==index}'>
                                <app-page [index]='i'></app-page>
                            </div>
                        </div>

                      
                        <footer class="bg-dark text-center text-white">
                            <div>
                                <a class="btn btn-outline-light btn-floating m-1"  role="button"
                                    (click)="downloadPDF()">
                                    <span> submit</span>
                                </a>
                            </div>
                        </footer>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>