import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Toast, ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-feedback-results',
  templateUrl: './feedback-results.component.html',
  styleUrls: ['./feedback-results.component.css']
})
export class FeedbackResultsComponent extends BaseComponent implements OnInit {
  users: Array<any> = [];
  userId: string = ''
  labelName: any = {};
  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
  headingName: any = {};
  constructor(CommonService: CommonService, toastr: ToastrService) {
    super(CommonService, toastr);
    this.loadlabels();
    this.loadUsers();
    this.loadHeadings();
  }

  ngOnInit(): void {
  }

  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/20/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/20/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  loadUsers() {
    let payload = {
      TENANT_CODE: this.tId || this.TenantCode
    }
    this.activeSpinner();
    this.CommonService.postCall('LoadUsers', payload).subscribe((res: any) => {
      this.users = res;
      this.deactivateSpinner()
    }, err => { this.deactivateSpinner() })
  }

  userChange() {
    let payload = {
      "EMP_ID": this.userId,
      "TENANT_CODE": localStorage.getItem('TenantCode')
    }
    this.activeSpinner()
    this.CommonService.postCall('LoadGrid', payload).subscribe((res: any) => {
      this.table = res;
      this.deactivateSpinner();
    }, e => {
      this.deactivateSpinner();
    })

  }
  changeTname() {
    this.loadUsers()
  }
  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }
}
