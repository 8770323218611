import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { dataDictionary } from 'src/app/dataDictionary';
import { EditUserRegistrationComponent } from 'src/app/pages/edit-user-registration/edit-user-registration.component';
import { CommonService } from 'src/app/services/common.service';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-work-experience',
  templateUrl: './work-experience.component.html',
  styleUrls: ['./work-experience.component.css']
})
export class WorkExperienceComponent extends BaseComponent implements OnInit {
  @Input() parent: EditUserRegistrationComponent;
  @Input() editData: any = {};
  changed: boolean = false;
  table: Array<any> = [];
  myForm: FormGroup;
  isEdit: boolean = false;
  index: number;
  roleId: number;
  empStatus: Array<any> = [];
  labelName: any = {};
  WorkExperienceData: Array<any> = []
  headingName: any = {};
  constructor(private fb: FormBuilder, private CommonService: CommonService) {
    super();
    this.getLabels();
    this.loadHeadings();
    this.roleId = +localStorage.getItem('RoleId');
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      EmployerName: ['',],
      EmployementStatus: ['',],
      DurationFrom: ['',],
      DurationTo: ['',],
      Designation: ['',],
      JobProfile: ['',],
      NoticePeriod: ['',],

    });
    this.getAll();
    this.parent.childs['WorkExperienceList'] = this;
  }
  ngOnChanges() {
    if (Object.keys(this.editData).length && !this.changed) {
      this.changed = true;
      this.WorkExperienceData = this.editData['WorkExperience'].slice(0);
      this.dataTransform(this.editData['WorkExperience'].slice(0));
    }
  }
  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${localStorage.getItem('regWorkItem')}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;


    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${localStorage.getItem('regWorkItem')}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  dataTransform(data: Array<any>) {
    data.map(item => {
      let obj = {
        type: item['type'],
        //UserWorkExpID: 1,
        EmployerName: item['EmployerName'],
        EmployementStatusName: item['EmployementStatus'],
        EmployementStatus: item['EmployementStatus'],
        DurationFrom: item['DurationFrom'] ? moment(item['DurationFrom']).format('yyyy-MM-DD') : '',
        DurationTo: item['DurationTo'] ? moment(item['DurationTo']).format('yyyy-MM-DD') : '',
        Designation: item['Designation'],
        JobProfile: item['JobProfile'],
        NoticePeriod: item['NoticePeriod']
      }
      this.table.push(obj);
    })
    // this.addIds()
  }
  getAll() {
    //GetEmployementStatus
    this.parent.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.EmployementStatus }).subscribe(
      (res: any) => {
        this.empStatus = res;
        // this.addIds();
      }
    );

  }
  add() {
    this.isEdit = false;
  }
  edit(data, i) {
    this.index = i;
    this.isEdit = true;
    this.editData = data;
    this.assignDataForm(i);
  }
  assignDataForm(i) {
    let data = this.WorkExperienceData[i];
    let ctrls = this.myForm.controls;
    Object.keys(ctrls).map((formControlName: string) => {
      let control: AbstractControl = ctrls[formControlName];
      control.setValue(data[formControlName]);
    })
  }
  close() {
    this.myForm.reset()
  }
  onSubmit(form: FormGroup) {
    let value = form.value;
    if (this.isEdit) {
      if (this.editData.TYPE == 'edit') {
        value.TYPE = 'update'
      } else {
        value.TYPE = 'insert'
      }
      //value['UserWorkExpID'] = this.editData['UserWorkExpID']
      let data = Object.assign({}, value);
      this.createAndUpdate(data, false);
      this.WorkExperienceData.push(data);
    } else {
      value.TYPE = 'insert'
      let data = Object.assign({}, value);
      this.createAndUpdate(data, true);
      // this.WorkExperienceData.push(data);
    }
    document.getElementById('md_close_work').click();
  }
  createAndUpdate(data, type) {
    let i = this.index;
    if (type) {
      this.table.push(data);
      let obj = Object.assign({}, data);
      this.WorkExperienceData.push(obj);
      this.parent.toastr.success('Added successfully')
    } else {
      this.table[i] = data;
      let obj = Object.assign({}, data);
      this.WorkExperienceData[i] = obj
      this.parent.toastr.success('Updated successfully')
    }
  }
}
