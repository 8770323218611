import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.css']
})
export class UserRegistrationComponent extends BaseComponent implements OnInit {
  table: Array<any> = [];
  rId: string = '';
  roles: Array<any> = [];
  tId: string = '';
  roleId: string = localStorage.getItem('RoleId');
  tenanates: Array<any> = []
  headingName: any = {};
  labelName: any = {};
  workItemId: any;
  constructor(public CommonService: CommonService, private active: ActivatedRoute, public toastr: ToastrService, private route: Router) {
    // this.getRoles();
    super(CommonService, toastr);
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
    if (this.roleId == '4') {
      this.getTennates()
    } else {
      this.getRoles()
    }
    this.change();
    this.loadHeadings();
    this.loadlabels();
  }
  ngOnInit(): void {
  }
  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  change() {
    this.activeSpinner();
    let tenantcode;
    if (this.roleId == '4') {
      tenantcode = this.tId || 0
    } else {
      tenantcode = localStorage.getItem('TenantCode')
    }
    let payLoad = {
      "TenantCode": tenantcode,
      "objUserinrole": { "RoleId": this.rId || 0 }
    }
    this.CommonService.postCall('UserRolesChange', payLoad).subscribe(
      (res) => {
        this.table = [];
        setTimeout(() => { this.table = res }, 10)
        this.deactivateSpinner();

      },
      err => {
        this.deactivateSpinner()
      })
  }
  changeTname() {
    this.getRoles();
  }
  edit(item) {
    let id = item.USERID;
    this.activeSpinner();
    this.CommonService.postCall('EditRegistrationByUserId', { CREATEDBY: id }).subscribe(
      (res: any) => {
        this.deactivateSpinner();
        this.route.navigate(['home/editUserRegistration'], { queryParams: { token: res.value, wId: this.workItemId, rId: this.rId } })
      }, err => {
        this.deactivateSpinner();
      }
    )

  }


  getRoles() {
    this.activeSpinner();
    this.CommonService.postCall('GetRolesByTenantCode', { TENANT_CODE: this.tId || localStorage.getItem('TenantCode') }).subscribe(
      (res: any) => {
        this.roles = res;
        this.deactivateSpinner();
      }, error => {
        this.deactivateSpinner();
      }
    )
  }
  getTennates() {
    this.activeSpinner();
    // this.CommonService.postCall('GetTenantByRoleId',{RoleId:this.roleId}).subscribe(
    this.CommonService.postCall('GetTenantByRoleId', {
      RoleId: this.roleId, TENANT_CODE: localStorage.getItem('TenantCode'),
    }).subscribe(
      (res) => {
        this.tenanates = res;
        this.deactivateSpinner()
      }, err => {
        this.deactivateSpinner()
      }
    )
  }
  add() {
    let payLoad = {
      TENANT_CODE: this.tId || localStorage.getItem('TenantCode'),
      CREATEDBY: localStorage.getItem('UserId'),
      RoleId: this.rId
    }
    this.activeSpinner();
    this.CommonService.postCall('AddRegistration', payLoad).subscribe(
      (res) => {
        this.deactivateSpinner();
        let params = {
          tcode: this.tId || this.TenantCode,
          uType: res.UserType,
          rId: res.RoleId,
          token: res.VerificationToken && res.VerificationToken[0],
          wId: this.workItemId
        }
        this.route.navigate(['/home/addUserRegistration'], { queryParams: params })
      }, err => {
        this.deactivateSpinner();
        this.toastr.warning(err.error)
      })
  }

}
