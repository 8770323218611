import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from './../../services/common.service';
import { DataTableDirective } from 'angular-datatables';
import { BaseComponent } from '../base.component';

import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-bus-fee-receipt',
  templateUrl: './bus-fee-receipt.component.html',
  styleUrls: ['./bus-fee-receipt.component.css']
})
export class BusFeeReceiptComponent extends BaseComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
  cources: Array<any> = [];
  students: Array<any> = [];
  usersData: Array<any> = [];
  paidstudents: Array<any> = [];
  receipt: boolean = false;
  printReceipt: boolean = false;
  totalAmount: number = 0;
  feename: any;
  words: any;
  num: any;
  labelName: any = {};
  headingName: any = {};
  workItemId: any;
  roleId = localStorage.getItem('RoleId');
  tenantCode = localStorage.getItem('TenantCode');
  constructor(CommonService: CommonService, toastr: ToastrService,private active : ActivatedRoute) {
    super(CommonService, toastr);
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    // this.loadcourses();
    this.loadlabels();
    this.loadHeadings();
    if (this.roleId == '1') {
      this.getCourses(this.tenantCode);
    }
  }


  ngOnInit(): void {
  }
  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }
  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }


  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }


  tntLoad() {
    let payload = {
      "RoleId": this.roleId,
      "TENANT_CODE": this.tenantCode
    }
    this.CommonService.postCall('Registration/GetTenantByRoleId', payload).subscribe((res: any) => {
      this.tenanates = res;
    })
  }
  // getCourses() {
  //   this.activeSpinner()
  //   this.CommonService.getAdminCourses().subscribe((res: any) => {
  //     this.cources = res;
  //     this.deactivateSpinner()
  //   }, e => {
  //     this.deactivateSpinner()
  //   })
  // }
  getCourses(tId: any) {
    this.activeSpinner()
    this.CommonService.getCall(`Courses/GetAdminCourses/${localStorage.getItem('UserId')}/${localStorage.getItem('RoleId')}/${tId || localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.cources = res;
      this.deactivateSpinner()
    }, e => {
      this.deactivateSpinner()
    })
  }
  courceChange(event: any) {
    let courseId = event.target.value;
    let payload = {
      "courseid": courseId,
      "tenantcode": this.tId||localStorage.getItem('TenantCode'),
    }
    this.CommonService.postCall('Registration/LoadStudentsByCourse', payload).subscribe((response: any) => {
      this.students = response;
    })
  }
  view() {
    this.printReceipt = true;
  }

  getUsersData(event: any) {
    this.totalAmount = 0;
    let userId = event.target.value;
    let payload = {
      "feereceivable_student_id": userId,
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('Registration/GetTransportFeePaidStudents', payload).subscribe((response: any) => {
      if (response == null) {
        this.toastr.warning('Receipt is not avilable for this student');
      } else {
        this.receipt = true;
        this.usersData = response;
        this.num = response.feereceivable_paidamount;
        this.words = this.numberToWords(this.num);
      }
    })
  }

  convetToPDF() {
    var data: any = document.getElementById('printtable');
    html2canvas(data).then((canvas: any) => {
      // Few necessary setting options
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png');
      let pdf: any = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      pdf.save('busfeereceipt.pdf'); // Generated PDF
      // window.close();
    });

  }
  print() {
    var printContents: any = document.getElementById("printtable")?.innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  }

  numberToWords(num: any) {

    let a = ['', 'ONE ', 'TWO ', 'THREE ', 'FOUR ', 'FIVE ', 'SIX ', 'SEVEN ', 'EIGHT ', 'NINE ', 'TEN ', 'ELEVEN ', 'TWELVE ', 'THIRTEEN ', 'FOURTEEN ', 'FIFTEEN ', 'SIXTEEN ', 'SEVENTEEN ', 'EIGHTEEN ', 'NINETEEN '];
    let b = ['', '', 'TWENTY', 'THIRTY', 'FORTY', 'FIFTY', 'SIXTY', 'SEVENTY', 'EIGHTY', 'NINETY'];

    if ((num = num?.toString())?.length > 9)
      return 'overflow';
    let n: any = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return '';
    let str = '';

    str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'CRORE ' : '';
    str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'LAKH ' : '';
    str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'THOUSAND ' : '';
    str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'HUNDRED ' : '';
    str += (n[5] != 0) ? ((str != '') ? 'AND ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'RUPEES ONLY ' : '';
    return str;

  }
}
