<div>
    <div class="text-right">
        <button [disabled]="roleId==3" type="button" class="btn btn-primary" (click)="add()" data-bs-toggle="modal"
            data-bs-target="#myModal_work">Add</button>

    </div>
    <div class="pt-2">
        <table *ngIf='table.length' class="table table-striped table-responsive-md btn-table table-bordered text-dark">
            <thead>
                <tr>
                    <th>
                        Employer  Name</th>
                    <th>Designation</th>
                    <th>	Employement Status</th>
                    <th>	Notice Period</th>
                    <th>	Duration From</th>
                    <th>	Duration To</th>
                    <th>Operation</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor='let item of table;let i=index'>
                    <td>{{item.EmployerName}}</td>
                    <td>{{item.Designation}}</td>
                    <td>{{item.EmployementStatus}}</td>
                    <td>{{item.NoticePeriod}}</td>
                    <td>{{item.DurationFrom}}</td>
                    <td>{{item.DurationTo}}</td>
                    <td style="display: flex;"> 
                        <span data-bs-toggle="modal" data-bs-target="#myModal_work"
                        src=""
                        class="edit_icon" (click)="edit(item,i)"> 
                        <i class="fas fa-pen pt-1 mt-1"></i></span ></td>
                   
                </tr>
            </tbody>
        </table>
        <p class="text-center" *ngIf='!table.length'>No Records</p>
    </div>

</div>

<div class="modal" id="myModal_work" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog  modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header py-2">
                <h6 *ngIf="!isEdit" class="modal-title w-100"> {{headingName.AddWorkExperience}} </h6>
                <h6 *ngIf="isEdit" class="modal-title w-100"> {{headingName.EditWorkExperience}}</h6>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i class="fas fa-times"></i></button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row">
                            <label class="col-md-4 control-label">
                              {{labelName.EmployerName }}   <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="Enter Employer Name " formControlName='EmployerName' type="text"
                                        class="form-control form-control-sm shadow-sm rounded-lg">

                                </div>
                                <div *ngIf="myForm.get('EmployerName').touched">
                                    <span class="text-danger" *ngIf="myForm.get('EmployerName').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label"> {{labelName.EmployementStatus1}}   <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select  formControlName='EmployementStatus'
                                    class="form-select form-select-sm shadow-sm rounded-lg">
                                    <option value="" selected>Select Employement Status</option>
                                    <option *ngFor='let item of empStatus' [value]='item.DICTIONARYNAME'>{{item.DICTIONARYNAME}}</option>
                                </select>

                                </div>
                                <div *ngIf="myForm.get('EmployementStatus').touched">
                                    <span class="text-danger" *ngIf="myForm.get('EmployementStatus').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label"> {{labelName.Duration1 }}   <span class="text-danger">*</span></label>
                            <div class="col-md-3 inputGroupContainer">
                                <div class="input-group">
                                    <input type="date" formControlName='DurationFrom'
                                        class="form-control form-control-sm shadow-sm rounded-lg">


                                </div>
                                <div *ngIf="myForm.get('DurationFrom').touched">
                                    <span class="text-danger" *ngIf="myForm.get('DurationFrom').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                            <label class="col-md-1 control-label">   {{labelName.To}}</label>
                            <div class="col-md-3 inputGroupContainer">
                                <div class="input-group">
                                    <input type="date" formControlName='DurationTo'
                                        class="form-control form-control-sm shadow-sm rounded-lg">


                                </div>
                                <div *ngIf="myForm.get('DurationTo').touched">
                                    <span class="text-danger" *ngIf="myForm.get('DurationTo').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>       
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName.Designation}}    <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="Enter Designation" class="form-control" formControlName="Designation">
                                   
                                </div>
                                <div *ngIf="myForm.get('Designation').touched">
                                    <span class="text-danger" *ngIf="myForm.get('Designation').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName.JobProfile }}  <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <textarea placeholder="Enter Job Profile" class="form-control" formControlName="JobProfile">
                                    </textarea>
                                </div>
                                <div *ngIf="myForm.get('JobProfile').touched">
                                    <span class="text-danger" *ngIf="myForm.get('JobProfile').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                {{labelName.NoticePeriod }}  <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="Enter Notice Period" type="number" min="1" class="form-control" formControlName="NoticePeriod">
                                   
                                </div>
                                <div *ngIf="myForm.get('NoticePeriod').touched">
                                    <span class="text-danger" *ngIf="myForm.get('NoticePeriod').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>
                      
                    </fieldset>
                </form>

            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-danger btn-sm" id="md_close_work" (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>