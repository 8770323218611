import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-student-sales-reports',
  templateUrl: './student-sales-reports.component.html',
  styleUrls: ['./student-sales-reports.component.css']
})
export class StudentSalesReportsComponent implements OnInit {
  table: Array<any> = [];
  headingName: any = {};
  workItemId: any;
  constructor(private active: ActivatedRoute,private CommonService: CommonService) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadStudent();
    this.loadHeadings();
  }

  ngOnInit(): void {
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  loadStudent() {
    this.CommonService.getCall(`EmsStudentSales/StudentSalesReports/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.table = res;
    })
  }
}
