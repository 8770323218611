import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FileuploadService } from '../../services/fileupload.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-addassignment',
  templateUrl: './addassignment.component.html',
  styleUrls: ['./addassignment.component.css']
})
export class AddassignmentComponent extends BaseComponent implements OnInit {
  myForm: FormGroup;
  id: string | number = null
  startDate: Date = null;
  isDisable: boolean = false
  file: File;
  fileName: string;
  progress: number = null;
  minDate: any = moment().format('yyyy-MM-DD');
  labelName: any = {};
  headingName: any = {};
  workItemId: any;
  constructor(private fb: FormBuilder, CommonService: CommonService, private active: ActivatedRoute, private route: Router, private FileuploadService: FileuploadService, toastr: ToastrService) {
    super(CommonService, toastr)
    active.queryParams.subscribe((res: any) => {
      this.workItemId = res.wId;
      if (res.id) {
        this.id = res.id;
        this.workItemId = res.wId;
        this.getData(res.id)
        this.isDisable = true
      }
    })
    this.loadlabels();
    this.loadHeadings();
    this.getCourses()
  }
  cources: [] = [];
  courceId: string = ''
  schedulId: string | number = '';
  scheduls: [] = [];
  data: any = {}
  assessments: Array<any> = []
  ngOnInit(): void {

    this.myForm = this.fb.group({
      ASSIGNMENT_NAME: ['', Validators.required,],
      ASSIGNMENT_COURSE: ['', Validators.required],
      ASSIGNMENT_COURSE_SCHEDULE: [''],
      ASSIGNMENTS_SCHEDULE_ID: [''],
      ASSIGNMENT_START_DATE: [''],
      ASSIGNMENT_END_DATE: [''],
      ASSIGNMENT_UPLOAD: [this.data.ASSIGNMENT_UPLOAD || '', [Validators.required,]],
      ASSIGNMENT_MAX_MARKS: ['', Validators.required],
      AssignmentStatus: [1]
    })

  }

  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }
  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }


  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  custom(type) {
    return function (control: FormControl) {
      const file = control.value;
      if (file) {
        const extension = file.split('.')[1].toLowerCase();
        if (type.toLowerCase() !== extension.toLowerCase()) {
          return {
            requiredFileType: true
          };
        }

        return null;
      }

      return null;
    };
  }

  getData(id) {
    this.activeSpinner()
    this.CommonService.getAssignmentsById(id).subscribe((res: any) => {
      this.deactivateSpinner()
      if (res instanceof Array) {
        this.data = res[0];
      } else {
        this.data = res;
      }
      if (res) {

        this.startDate = this.data.ASSIGNMENT_START_DATE;
        this.courceId = this.data.ASSIGNMENT_COURSE;
        this.fileName = this.data.ASSIGNMENT_UPLOAD
        this.myForm.controls['ASSIGNMENT_UPLOAD'].setValue(this.fileName)
        this.myForm.controls['AssignmentStatus'].setValue(this.data.ASSIGNMENT_STATUS ? 1 : 0)
        if (this.courceId) {
          this.courceChange()
        }
        this.schedulId = this.data.ASSIGNMENT_COURSE_SCHEDULE;
        if (this.schedulId) {
          this.schedulChange()
        }
      }
    }, err => {
      this.deactivateSpinner()
    })
  }

  getCourses() {
    this.activeSpinner()
    this.CommonService.getAdminCourses().subscribe((res: any) => {
      this.cources = res;
      this.deactivateSpinner()
    }, e => {
      this.deactivateSpinner()
    })
  }

  courceChange() {
    let data = {
      "CourseId": this.courceId
    }
    this.activeSpinner()
    this.CommonService.getAdminCourseSchedule(data).subscribe((res: any) => {
      this.deactivateSpinner()
      this.scheduls = res;
    }, e => { this.deactivateSpinner() })
  }
  schedulChange() {
    this.activeSpinner();
    this.CommonService.loadAssessmentDropdown(this.courceId, this.schedulId).subscribe((res: any) => {
      this.deactivateSpinner()
      this.assessments = res;
    }, e => { this.deactivateSpinner() })
  }

  onSubmit(data: FormGroup) {
    this.activeSpinner();
    let params = data.getRawValue();
    params['ASSIGNMENT_COURSE_SCHEDULE'] = parseInt(params['ASSIGNMENT_COURSE_SCHEDULE']);
    params['TENANT_CODE'] = localStorage.getItem('TenantCode');
    params.ASSIGNMENT_ID = this.data.ASSIGNMENT_ID || 0;
    if (this.id) {
      this.CommonService.postCall('updateAssignments', params).subscribe((res: any) => {
        //  alert('Information saved successfully.')
        this.deactivateSpinner();
        this.toastr.success('Information Updated successfully.')
        this.route.navigate(['home/assignments'])
      }, err => {
        this.deactivateSpinner();
        this.toastr.error(err.error ? err.error : err);
      })

    } else {
      this.CommonService.setAssignments(params).subscribe((res: any) => {
        //  alert('Information saved successfully.')
        this.deactivateSpinner();
        this.toastr.success('Information saved successfully.')
        this.route.navigate(['home/assignments'])
      }, err => {
        this.deactivateSpinner()
      })
    }
  }


  upload() {
    const formData = new FormData();
    formData.append('Yes', this.file);
    formData.append('ClientDocs', 'ClientDocs');
    formData.append('Course', this.courceId);
    this.activeSpinner();
    this.FileuploadService.upload(formData, 'Assignments/UploadAssignment').subscribe((res: any) => {
      // this.file = null
      this.progress = res.message || this.progress;
      this.fileName = res.path;
      if (this.fileName) this.deactivateSpinner()
      this.myForm.controls['ASSIGNMENT_UPLOAD'].setValue(this.fileName)
    }, err => { this.deactivateSpinner(); })
  }
  change(event) {
    if (event.target.files.length > 0) {
      let file: File = event.target.files[0];
      let name = file.name;
      let filetype = name.split('.').pop()
      let types: Array<any> = ['doc', 'docx', 'pdf']
      let check = types.includes(filetype);
      if (check) {
        this.file = file;
      }
      else {
        // alert(' Please upload pdf and doc file formats only.')
        this.toastr.warning('Please upload pdf and doc file formats only.')
        event.target.value = ''
      }
    }
  }
  endDateChange(eDate) {
    let sDate = this.myForm.get('ASSIGNMENT_START_DATE').value;
    if (!sDate) {
      this.toastr.warning('Please select strat Date')
      this.myForm.get('ASSIGNMENT_END_DATE').setValue(null)
      return
    }
    if (!moment(eDate).isSameOrAfter(sDate)) {
      this.toastr.warning('End date should be equal or more than start Date')
      this.myForm.get('ASSIGNMENT_END_DATE').setValue(null)
    }
  }
}
// [ngModel] ="dt | date:'yyyy-MM-dd'"