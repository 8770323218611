import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Logger } from 'html2canvas/dist/types/core/logger';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-edit-time-table',
  templateUrl: './edit-time-table.component.html',
  styleUrls: ['./edit-time-table.component.css']
})
export class EditTimeTableComponent implements OnInit {
  myForm: FormGroup;
  myForms: FormGroup;
  table: Array<any> = [];
  campusDropdown: Array<any> = [];
  cources: Array<any> = [];
  scheduls: Array<any> = [];
  ArrayList: Array<any> = [];
  subjects: Array<any> = [];
  trainer: Array<any> = [];
  teacherName: Array<any> = [];
  sub: Array<any> = [];
  courceId: any;
  isEdit: boolean = false;
  viewTable: boolean = false;
  isAssign: boolean = false;
  Monday: boolean = false;
  Tuesday: boolean = false;
  Wed: boolean = false;
  Thu: boolean = false;
  Fri: boolean = false;
  Sat: boolean = false;
  editData: any;
  preriodDropdown = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  preriodDurationDropdown = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60];
  currentIndex: any;
  currentDay: any;
  periods: Array<any> = [];
  timeTableId : any;
  constructor(private fb: FormBuilder, private active: ActivatedRoute,private CommonService: CommonService, private toastr: ToastrService, private route: Router, private ref: ChangeDetectorRef) {
    active.queryParams.subscribe((res) => {
        this.timeTableId = res.timeTableId;
        console.log(this.timeTableId);        
      })
    this.getCourses();
    this.getTrainer();
    this.editRes();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      selectPeriod: [''],      
      periods: this.fb.array([])
    });
    this.myForms = this.fb.group({
      teacher_id: [''],
      subject: ['']
    })
  }

  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  getCourses() {
    this.activeSpinner()
    this.CommonService.getAdminCourses().subscribe((res: any) => {
      this.cources = res;
      this.deactivateSpinner()
    }, e => {
      this.deactivateSpinner()
    })
  }

  courceChange(id: any) {
    let data = {
      "CourseId": id
    }
    this.activeSpinner()
    this.CommonService.getAdminCourseSchedule(data).subscribe((res: any) => {
      this.deactivateSpinner()
      this.scheduls = res;
    }, e => { this.deactivateSpinner() })
  }

  getTrainer() {
    let payLoad = {
      "TenantCode": localStorage.getItem('TenantCode'),
      "objUserinrole": { "RoleId": localStorage.getItem('RoleId') || 0 }
    }
    this.CommonService.postCall('UserRolesChange', payLoad).subscribe((res: any) => {
      this.trainer = res;
      // console.log(this.trainer)
    })
  }

  getSubjectsbyCourse(id: any) {
    let payLoad = {
      COURSE_ID: id,
      TNT_CODE: localStorage.getItem('TenantCode')
    }
    this.activeSpinner();
    this.CommonService.postCall('GetSubjects', payLoad).subscribe(
      (res: any) => {
        this.subjects = res;
        this.deactivateSpinner();
      }, err => {
        this.deactivateSpinner();
      }
    )
  }

  getPeriodList() {
    let newPeriod = this.fb.group({
      periodStart: '',
      periodEnd: '',
      "1": 'Assign',
      "2": 'Assign',
      "3": 'Assign',
      "4": 'Assign',
      "5": 'Assign',
      "6": 'Assign'
    })
    return newPeriod
  }

  ngAfterViewChecked() {
    this.ref.detectChanges();
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  periodChange(event: any) {
    //  this.myForm.controls['periods'] = this.fb.array([]);
    console.log(event);
    this.viewTable = true;
    const arrayControl = <FormArray>this.myForm.controls['periods'];
    for (let index = 0; index < event; index++) {
      let grp: FormGroup = this.getPeriodList()
      arrayControl.push(grp)
    }
    console.log(arrayControl)


  }



  editRes() {
    this.viewTable = true;
    this.CommonService.getCall(`TimeTable/GetTimeTable/${this.timeTableId}`).subscribe((res:any)=>{
      this.editData = res;    
      this.periodChange(res.total_periods);
      console.log(this.editData) ;
      // const arrayControl = <FormArray>this.myForm.controls['periods'];
      // let editlist: FormGroup = res.periods
      // arrayControl.push(editlist)
      // this.myForm.periods.add(res.periods);
    })
    
  }

  onSubmit(form: FormGroup) {
    let value = form.value;
    console.log(value)
    let campusName = this.campusDropdown.filter(x => parseInt(x.campus_id) === parseInt(value.campus_id));
    let couse = this.cources.filter(x => parseInt(x.COURSE_ID) === parseInt(value.class_id));
    let schedule = this.scheduls.filter(x => parseInt(x.COURSESHD_ID) === parseInt(value.section_id));
    console.log(this.periods)
    let payload = {
      "campus_id": value.campus_id,
      "campus_name": campusName[0].campus_name,
      "class_id": value.class_id,
      "class_name": couse[0].COURSE_NAME,
      "section_id": value.section_id,
      "section_name": schedule[0].COURSESHD_NAME,
      "total_periods": value.period,
      "period_duration": value.period_duration,
      "created_by": localStorage.getItem('UserId'),
      "tnt_code": localStorage.getItem('TenantCode'),
    }
    let periodArray = [];
    value.periods.forEach((e) => {
      console.log(e)
      this.periods.forEach(p => {

        // p['period'] =  p['period'];
        p['period_start_time'] = e.periodStart;
        p['period_end_time'] = e.periodEnd;
      })
    });
    // value.period.forEach(e => {

    // });

    payload['periods'] = this.periods;
    console.log(payload);

    if (this.isEdit) {

      this.CommonService.postCall('TimeTable/CreateUpdateTimeTable', payload).subscribe((res: any) => {
        this.route.navigate(['home/time-table']);
        this.toastr.success("Time Table Updated Succuessfully");
      }, err => {
        this.toastr.error(err.error ? err.error : 'Time Table Not Updated')
      })
    } else {
      this.CommonService.postCall('TimeTable/CreateUpdateTimeTable', payload).subscribe((res: any) => {
        this.route.navigate(['home/time-table']);
        this.toastr.success("Time Table Created Succuessfully");
      }, err => {
        this.toastr.error(err.error ? err.error : 'Time Table Not created')

      })
    }
  }



  Submit(forms: FormGroup, index: any) {
    console.log(index)
    this.teacherName = this.trainer.filter(x => parseInt(x.USERID) === parseInt(forms.value.teacher_id));
    this.sub = this.subjects.filter(x => parseInt(x.SUBJECT_ID) === parseInt(forms.value.subject));

    let weekDay = ''

    if (this.currentDay == 'mon') {
      //  this.Monday = true; 
      ((this.myForm.get('periods') as FormArray).at(index) as FormGroup).get('1').patchValue(this.teacherName[0].FIRSTNAME + ',' + this.sub[0].SUBJECT_NAME);
      weekDay = "1";
    } else if (this.currentDay == 'tue') {
      ((this.myForm.get('periods') as FormArray).at(index) as FormGroup).get('2').patchValue(this.teacherName[0].FIRSTNAME + ',' + this.sub[0].SUBJECT_NAME);
      weekDay = "2";
    } else if (this.currentDay == 'wed') {
      ((this.myForm.get('periods') as FormArray).at(index) as FormGroup).get('3').patchValue(this.teacherName[0].FIRSTNAME + ',' + this.sub[0].SUBJECT_NAME);
      weekDay = "3";
    } else if (this.currentDay == 'thu') {
      ((this.myForm.get('periods') as FormArray).at(index) as FormGroup).get('4').patchValue(this.teacherName[0].FIRSTNAME + ',' + this.sub[0].SUBJECT_NAME);
      weekDay = "4";
    } else if (this.currentDay == 'fri') {
      ((this.myForm.get('periods') as FormArray).at(index) as FormGroup).get('5').patchValue(this.teacherName[0].FIRSTNAME + ',' + this.sub[0].SUBJECT_NAME);
      weekDay = "5";
    } else if (this.currentDay == 'sat') {
      ((this.myForm.get('periods') as FormArray).at(index) as FormGroup).get('6').patchValue(this.teacherName[0].FIRSTNAME + ',' + this.sub[0].SUBJECT_NAME);
      weekDay = "6";
    }

    let tempArray = [];
    let i = Object.assign(index);
    let obj = {
      subject_id: forms.value.subject,
      subject_name: this.sub[0].SUBJECT_NAME,
      teacher_id: forms.value.teacher_id,
      teacher_name: this.teacherName[0].FIRSTNAME,
      week_day: weekDay,
      period: +i + 1,
    }

    this.periods.push(obj);
    console.log(this.periods);
    this.ref.detectChanges();
    document.getElementById('md_close').click();

  }

  getIndex(i: any, day: any) {
    this.currentIndex = i;
    this.currentDay = day;
  }


  close() {
    this.route.navigate(['home/time-table']);
  }

  close1() {
    this.myForms.reset()
  }

}
