<div class="page-wrapper">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.DailyAttendanceReports}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div *ngIf='isSuperAdmin' class="col-md-3 pb-4">
                                                <label for="sel1">{{labelName.TenantName}} : </label>
                                                <select [(ngModel)]='tId ' (change)="loadStaffDailyAttendance()"
                                                    class="form-control form-control-sm shadow-sm rounded-lg" id="sel1">
                                                    <option value="" [selected]="true">select</option>
                                                    <option *ngFor="let item of tenanates" [value]='item.TNT_CODE'>
                                                        {{item.TNT_NAME}}</option>
                                                </select>

                                            </div>
                                        </div>
                                        <div class="text-right mb-2">
                                        </div>
                                        <div *ngIf="table.length" class="table-responsive">
                                            <table id="patergraph" datatable
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small display">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th>Branch Name</th>
                                                        <th>Employee Name</th>
                                                        <th>Mobile Number</th>
                                                        <th>Image</th>
                                                        <th>Punch Time</th>
                                                        <th>Branch GPS Location</th>
                                                        <th>Network Location</th>
                                                        <th>Remarks</th>
                                                        <th>Distance(Meters)</th>
                                                    </tr>
                                                </thead>


                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.tnt_name}}</td>
                                                        <td>{{item.employeename}}</td>
                                                        <td>{{item.mobileno}}</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td ></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>

                                            </table>
                                        </div>


                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
