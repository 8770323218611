<div class="custom_container">
    <form class="well form-horizontal" [formGroup]="myform">
        <fieldset>
            <div class="form-group row">
                <label class="col-md-4 control-label mt-2">
                    {{labelName.Title}} <span class="text-danger">*</span></label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group"> <strong>:</strong> &nbsp;
                        <select class="form-select form-select-sm shadow-sm rounded-lg" formControlName='Title'
                            [attr.disabled]="isTabsDisabled==false?true:null">
                            <option value="" [selected]="true">select</option>
                            <option [value]='item.DICTIONARYID' *ngFor='let item of titles'>{{item.DICTIONARYNAME}}
                            </option>
                        </select>
                    </div>
                    <div *ngIf="myform.get('Title').touched">
                        <span class="text-danger" *ngIf="myform.get('Title').hasError('required')">
                            This field is required.
                        </span>

                    </div>
                </div>
            </div>
            <div class="form-group row pt-4">
                <label class="col-md-4 control-label">
                    {{labelName.FirstName}} <span class="text-danger">*</span></label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group"> <strong>:</strong> &nbsp;
                        <input class="form-control" formControlName='FirstName'
                            onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));"
                            type="text" [attr.disabled]="isTabsDisabled==false?true:null">
                    </div>
                    <div *ngIf="myform.get('FirstName').touched">
                        <span class="text-danger" *ngIf="myform.get('FirstName').hasError('required')">
                            This field is required.
                        </span>

                    </div>
                </div>
            </div>
            <div class="form-group row pt-4">
                <label class="col-md-4 control-label">
                    {{labelName.LastName}} <span class="text-danger">*</span></label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group"> <strong>:</strong> &nbsp;
                        <input class="form-control" formControlName='LastName'
                            onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));"
                            type="text" [attr.disabled]="isTabsDisabled==false?true:null">
                    </div>
                    <div *ngIf="myform.get('LastName').touched">
                        <span class="text-danger" *ngIf="myform.get('LastName').hasError('required')">
                            This field is required.
                        </span>

                    </div>
                </div>
            </div>
            <div class="form-group row pt-4">
                <label class="col-md-4 control-label">
                    {{labelName.DateOfBirth}} <span class="text-danger">*</span> </label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group"> <strong>:</strong> &nbsp;
                        <input class="form-control" max={{maxDate}} formControlName='dob' type="date" [attr.disabled]="isTabsDisabled==false?true:null">
                    </div>
                </div>
            </div>
            <div class="form-group row pt-4">
                <label class="col-md-4 control-label mt-2">
                    {{labelName.Gender}} <span class="text-danger">*</span></label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group"> <strong>:</strong> &nbsp;
                        <select class="form-select form-select-sm shadow-sm rounded-lg" formControlName='Gender' [attr.disabled]="isTabsDisabled==false?true:null">
                            <option value="" [selected]="true">select</option>
                            <option [value]='item.DICTIONARYID' *ngFor='let item of genders'>{{item.DICTIONARYNAME}}
                            </option>
                        </select>
                    </div>
                    <div *ngIf="myform.get('Gender').touched">
                        <span class="text-danger" *ngIf="myform.get('Gender').hasError('required')">
                            This field is required.
                        </span>
                    </div>
                </div>
            </div>
            <div class="form-group row pt-4">
                <label class="col-md-4 control-label mt-2">
                    {{labelName.MaritalStatus}} <span class="text-danger">*</span></label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group"> <strong>:</strong> &nbsp;
                        <select class="form-select form-select-sm shadow-sm rounded-lg" formControlName='MartialStatus' [attr.disabled]="isTabsDisabled==false?true:null">
                            <option value="" [selected]="true">select</option>
                            <option [value]='item.DICTIONARYID' *ngFor='let item of marriageStatus'>
                                {{item.DICTIONARYNAME}}
                            </option>
                        </select>
                    </div>
                    <div *ngIf="myform.get('MartialStatus').touched">
                        <span class="text-danger" *ngIf="myform.get('MartialStatus').hasError('required')">
                            This field is required.
                        </span>
                    </div>
                </div>
            </div>
            <div class="form-group row pt-4">
                <label class="col-md-4 control-label mt-2">
                    {{labelName.IDNumber}} <span class="text-danger">*</span></label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group"> <strong>:</strong> &nbsp;
                        <input class="form-control form-control-sm shadow-sm rounded-lg" formControlName='idNumber' [attr.disabled]="isTabsDisabled==false?true:null"
                            onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">

                    </div>
                    <div *ngIf="myform.get('idNumber').touched">
                        <span class="text-danger" *ngIf="myform.get('idNumber').hasError('required')">
                            This field is required.
                        </span>
                    </div>
                </div>
            </div>

            <div *ngIf='roleId==3'>
            <div class="form-group row pt-4">
                <label class="col-md-4 control-label mt-2">
                    {{labelName.EnrollmentNumber}} <span class="text-danger">*</span></label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group"> <strong>:</strong> &nbsp;
                        <input class="form-control form-control-sm shadow-sm rounded-lg" formControlName='enrollment_no' [attr.disabled]="isTabsDisabled==false?true:null"
                            onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">

                    </div>
                </div>
            </div>
            </div>
            <div class="form-group row pt-4">
                <label class="col-md-4 control-label mt-2">
                    {{labelName.Branch}} <span class="text-danger">*</span></label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group"> <strong>:</strong> &nbsp;
                        <select class="form-select form-select-sm shadow-sm rounded-lg" formControlName='Branch' [attr.disabled]="isTabsDisabled==false?true:null">
                            <option value="" [selected]="true">select</option>
                            <option [value]='item.COURSE_ID' *ngFor='let item of branchs'>{{item.COURSE_NAME}}
                            </option>
                        </select>
                    </div>
                    <div *ngIf="myform.get('Branch').touched">
                        <span class="text-danger" *ngIf="myform.get('Branch').hasError('required')">
                            This field is required.
                        </span>
                    </div>
                </div>
            </div>
            <div *ngIf='roleId==3'>
                <div class="form-group row pt-4">
                    <label class="col-md-4 control-label mt-2">
                        {{labelName.YearOfRegistration}} <span class="text-danger">*</span></label>
                    <div class="col-md-8 inputGroupContainer">
                        <div class="input-group"> <strong>:</strong> &nbsp;
                            <select class="form-select form-select-sm shadow-sm rounded-lg"
                                formControlName='YearOfRegistration' [attr.disabled]="isTabsDisabled==false?true:null">
                                <option value="" [selected]="true">select</option>
                                <option *ngFor="let year of years" [value]='year.YearId'>
                                    {{year.Year}}</option>
                            </select>
                        </div>
                        <div *ngIf="myform.get('YearOfRegistration').touched">
                            <span class="text-danger" *ngIf="myform.get('YearOfRegistration').hasError('required')">
                                This field is required.
                            </span>
                        </div>
                    </div>
                </div>

                <div *ngIf='roleId==3 '>
                    <div class="form-group row pt-4">
                        <label class="col-md-4 control-label mt-2">
                            Course Year <span class="text-danger">*</span></label>
                        <div class="col-md-8 inputGroupContainer">
                            <div class="input-group"> <strong>:</strong> &nbsp;
                                <select class="form-select form-select-sm shadow-sm rounded-lg"
                                    formControlName='course_year_id'  [attr.disabled]="isTabsDisabled==false?true:null">
                                    <option value="" [selected]="true">Select Course Year
                                    </option>
                                    <option *ngFor="let item of courseYear" [value]='item.DICTIONARYID'>
                                        {{item.DICTIONARYNAME}}
                                    </option>
                                </select>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group row pt-4">
                    <label class="col-md-4 control-label mt-2">
                        {{labelName.ParentName}} <span class="text-danger">*</span></label>
                    <div class="col-md-8 inputGroupContainer">
                        <div class="input-group"> <strong>:</strong> &nbsp;
                            <input class="form-control form-control-sm shadow-sm rounded-lg"
                                formControlName='ParentName' [attr.disabled]="isTabsDisabled==false?true:null"
                                onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">
                        </div>
                        <div *ngIf="myform.get('ParentName').touched">
                            <span class="text-danger" *ngIf="myform.get('ParentName').hasError('required')">
                                This field is required.
                            </span>
                        </div>
                    </div>
                </div>
                <div class="form-group row pt-4">
                    <label class="col-md-4 control-label mt-2">
                        {{labelName.MobileNumber}} <span class="text-danger">*</span></label>
                    <div class="col-md-8 inputGroupContainer">
                        <div class="input-group"> <strong>:</strong> &nbsp;
                            <input class="form-control form-control-sm shadow-sm rounded-lg" minlength="10" [attr.disabled]="isTabsDisabled==false?true:null"
                                maxlength="12" onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                formControlName='ParentMobileNumber'>
                        </div>
                        <div *ngIf="myform.get('ParentMobileNumber').touched">
                            <span class="text-danger" *ngIf="myform.get('ParentMobileNumber').hasError('required')">
                                This field is required.
                            </span>
                        </div>
                    </div>
                </div>
                <div class="form-group row pt-4">
                    <label class="col-md-4 control-label mt-2">
                        {{labelName.ParentRelationShip}} <span class="text-danger">*</span></label>
                    <div class="col-md-8 inputGroupContainer">
                        <div class="input-group"> <strong>:</strong> &nbsp;
                            <input class="form-control form-control-sm shadow-sm rounded-lg"
                                formControlName='ParentRelationShip' [attr.disabled]="isTabsDisabled==false?true:null"
                                onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || event.charCode == 32 || (event.charCode >= 48 && event.charCode <= 57));">
                        </div>
                        <div *ngIf="myform.get('ParentRelationShip').touched">
                            <span class="text-danger" *ngIf="myform.get('ParentRelationShip').hasError('required')">
                                This field is required.
                            </span>
                        </div>
                    </div>
                </div>

                <div class="form-group row pt-4">
                    <label class="col-md-4 control-label mt-2">
                        {{labelName.RefNo}} <span class="text-danger">*</span></label>
                    <div class="col-md-8 inputGroupContainer">
                        <div class="input-group"> <strong>:</strong> &nbsp;
                            <input class="form-control form-control-sm shadow-sm rounded-lg"
                                formControlName='reference_number' [attr.disabled]="isTabsDisabled==false?true:null"
                               >
                        </div>
                        <div *ngIf="myform.get('reference_number').touched">
                            <span class="text-danger" *ngIf="myform.get('reference_number').hasError('required')">
                                This field is required.
                            </span>
                        </div>
                    </div>
                </div>

                <div class="form-group row pt-4">
                    <label class="col-md-4 control-label mt-2">
                        {{labelName.CollegeAdmissionNo}} <span class="text-danger">*</span></label>
                    <div class="col-md-8 inputGroupContainer">
                        <div class="input-group"> <strong>:</strong> &nbsp;
                            <input class="form-control form-control-sm shadow-sm rounded-lg"
                                formControlName='admission_number' [attr.disabled]="isTabsDisabled==false?true:null"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'>
                        </div>
                        <div *ngIf="myform.get('admission_number').touched">
                            <span class="text-danger" *ngIf="myform.get('admission_number').hasError('required')">
                                This field is required.
                            </span>
                        </div>
                    </div>
                </div>

                <div class="form-group row pt-4">
                    <label class="col-md-4 control-label mt-2">
                        {{labelName.DropOff}} </label>
                    <div class="col-md-8 inputGroupContainer">
                        <div class="input-group"> <strong>:</strong> &nbsp;
                            <input class="form-check-input" type="checkbox" formControlName='is_dropoff' [attr.disabled]="isTabsDisabled==false?true:null">
                        </div>

                    </div>
                </div>


            </div>

            <div class="form-group row pt-4">
                <label class="col-md-4 control-label mt-2">
                    {{labelName.Transport}} </label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group"> <strong>:</strong> &nbsp;
                        <select class="form-select form-select-sm shadow-sm rounded-lg" 
                        [attr.disabled]="isTabsDisabled==false?true:null" formControlName='is_transport_required'>
                            <option [selected]="true" value="0">Select</option>
                            <option [ngValue]=true>yes</option>
                            <option [ngValue]=false>no</option>
                        </select>
                    </div>

                </div>
            </div>

            <div class="form-group row pt-4">
                <label class="col-md-4 control-label mt-2">
                    {{labelName.Status}} </label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group"> <strong>:</strong> &nbsp;
                        <select class="form-select form-select-sm shadow-sm rounded-lg" formControlName='status' [attr.disabled]="isTabsDisabled==false?true:null">
                            <option [ngValue]='1'>Active</option>
                            <option [ngValue]='0'>Inactive</option>
                        </select>
                    </div>

                </div>
            </div>
        </fieldset>
    </form>

</div>