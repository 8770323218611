<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.CourseSchedule}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right">
                                            <button type="button" class="btn btn-primary mb-1"
                                                (click)="add()">Add</button>
                                        </div>
                                        <div class="form-group ">
                                            <div class="row">
                                                <div *ngIf='isSuperAdmin' class="col-md-3">
                                                    <label for="sel1">{{labelName.TenantName}} : </label>
                                                    <select [(ngModel)]='tId ' (change)="changeTname()"
                                                        class="form-control form-control-sm shadow-sm rounded-lg"
                                                        id="sel1">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let item of tenanates" [value]='item.TNT_CODE'>
                                                            {{item.TNT_NAME}}</option>
                                                    </select>

                                                </div>


                                                <div class="col-md-3 ">
                                                    <label for="sel1">{{labelName.CourseName}} <span class="text-danger">*</span>:
                                                    </label>
                                                    <select class="form-control form-control-sm shadow-sm rounded-lg"
                                                        id="sel1" (change)="courseChange($event)">
                                                        <option value="" [selected]="true">Select</option>
                                                        <option *ngFor="let course of courses"
                                                            [value]='course.COURSE_ID'>
                                                            {{course.COURSE_NAME}}
                                                        </option>
                                                    </select>
                                                </div>



                                                <div class="form-group col-md-3">
                                                    <label> {{labelName.CourseYear}}</label>
                                                    <div class="input-group">
                                                        <select (change)="changeCourseYear($event)"
                                                            class="form-select form-select-sm shadow-sm rounded-lg">
                                                            <option value="" [selected]="true">Select 
                                                            </option>
                                                            <option *ngFor="let item of courseYear"
                                                                [value]='item.DICTIONARYID'>
                                                                {{item.DICTIONARYNAME}}
                                                            </option>
                                                        </select>

                                                    </div>
                                                </div>

                                                <div class="form-group col-md-3">
                                                    <label> {{labelName.Semester}}</label>
                                                    <div class="input-group">
                                                        <select (change)="changeSemester($event)"
                                                            class="form-select form-select-sm shadow-sm rounded-lg">
                                                            <option value="" [selected]="true">Select
                                                            </option>
                                                            <option *ngFor="let item of semester"
                                                                [value]='item.DICTIONARYID'>
                                                                {{item.DICTIONARYNAME}}
                                                            </option>
                                                        </select>

                                                    </div>
                                                </div>
                                                <div class="form-group col-md-3">
                                                    <label> {{labelName.Subject}}</label>
                                                    <div class="input-group">
                                                        <select (change)="changeSubject($event)"
                                                            class="form-select form-select-sm shadow-sm rounded-lg">
                                                            <option value="" [selected]="true">Select
                                                            </option>
                                                            <option *ngFor="let item of subjectsList"
                                                                [value]='item.subject_id'>
                                                                {{item.subject_name}}
                                                            </option>
                                                        </select>

                                                    </div>
                                                </div>
                                                <div class="col-md-3 pt-4" *ngIf="isSearch">
                                                    <button type="button" (click)="onSearch()"
                                                        class="btn btn-sm  btn-save"><i
                                                            class="fas fa-search"></i></button>
                                                </div>
                                            </div>

                                            <div class="pt-4" *ngIf=" table.length">
                                                <table datatable id="DataTables_Table_0" 
                                                    class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                    style="width:100%">
                                                    <thead class="top"
                                                        style="background-color: #624FD7; color:#ffffff;">
                                                        <tr>
                                                            <th>Course Name </th>
                                                            <th>
                                                                Batch Detail
                                                            </th>
                                                            <th> Start Date</th>
                                                            <th>End Date</th>
                                                            <th>Action</th>
                                                            <th>Sessions</th>
                                                            <th>Assessment</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let item of table">
                                                            <td>{{item.COURSESHD_COURSE_ID}}</td>
                                                            <td>{{item.COURSESHD_NAME}}</td>
                                                            <td>{{item.COURSESHD_STARTDATE |date:'dd-MM-yyyy'}}</td>
                                                            <td>{{item.COURSESHD_ENDDATE |date:'dd-MM-yyyy'}}</td>
                                                            <td style="display: flex; text-align: center;">
                                                                <a data-bs-toggle="modal" data-bs-target="#myModal"
                                                                    (click)="edit(item)"><i
                                                                        class="zmdi zmdi-edit zmdi-hc-2x">
                                                                    </i></a>


                                                                <button class="btn"><i class="fa fa-user"
                                                                        (click)="navigate(item,'courseSchedule-AssignUser')"
                                                                        style='font-size:24px'> <span
                                                                            class="span">Users</span> </i>
                                                                </button>
                                                                <button class="btn"><i class='fa fa-user-plus'
                                                                        (click)="navigate(item,'courseSchedule-AssignTrainer')"
                                                                        style='font-size:24px'> <span class="span">Add
                                                                            Trainers</span> </i> </button>
                                                            </td>
                                                            <td class="text-center"><input type="image"
                                                                    (click)='sessions(item)'
                                                                    src="./../../../assets/images/sprites/Sessions.png"
                                                                    class="  text-center"></td>
                                                            <td style="display: flex; text-align: center;">
                                                                <button type="button" class="btn btn-primary mb-1"
                                                                    (click)="addAssessment(item.COURSESHD_ID)">Add Assessment</button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="text-center pt-4 text-red" *ngIf="!table.length">
                                                No Records to display
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>