import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { FileuploadService } from 'src/app/services/fileupload.service';
@Component({
  selector: 'app-assign-driver',
  templateUrl: './assign-driver.component.html',
  styleUrls: ['./assign-driver.component.css']
})
export class AssignDriverComponent implements OnInit {
  myForm: FormGroup;
  dropdownSettings: any = {};
  driver: Array<any> = [];
  cleaner: Array<any> = [];
  staff: Array<any> = [];
  tnt_code: number;
  dropdownList: { id: number; name: string; }[] = [];
  selectedStaff: { id: number; name: string; }[] = [];
  isEdit: any;
  editData: any;
  vehicle_id: number;
  file: File;
  filetype: any;
  labelName: any = {};
  fileName: any;

  documentType = 'assignDriver'
  headingName: any = {};
  workItemId: any;
  constructor(private route: Router, private fb: FormBuilder, private CommonService: CommonService, private FileuploadService: FileuploadService, private toastr: ToastrService, private active: ActivatedRoute) {
    active.queryParams.subscribe((res) => {
      this.vehicle_id = res.id;
      this.workItemId = res.wId
      console.log(this.vehicle_id);
    })
    this.tnt_code = +localStorage.getItem('TenantCode');
    this.getLabels();
    this.loadHeadings();

    //  this.driverDropdown();
    //  this.cleanerDropdown();
    //  this.staffDropdown();
    //  this.getAssignDrivers();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      driver_id: ['', Validators.required],
      driving_licence_number: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      licence_expire_date: ['', Validators.required],
      licence_document: [''],
      cleaner_id: ['', Validators.required],
      staff_member: ['']
    })
    Promise.all([this.driverDropdown(), this.cleanerDropdown(), this.staffDropdown(), this.getAssignDrivers()]);
    // this.driverDropdown(),this.cleanerDropdown(),this.staffDropdown(),this.getAssignDrivers();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }
  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }


  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  getAssignDrivers() {
    this.driver = [];
    this.CommonService.getCall(`Transport/GetUnAssignDriverDropdown/${this.tnt_code}/${this.vehicle_id}`).subscribe((res: any) => {
      this.driver = res.filter(e => e != null);
    });
  }

  cleanerDropdown() {
    this.cleaner = [];
    this.CommonService.getCall(`Transport/GetUnAssignCleanerDropdown/${this.tnt_code}/${this.vehicle_id}`).subscribe((clinerresponse: any) => {
      this.cleaner = clinerresponse.filter(e => e != null);
    });
  }


  staffDropdown() {
    this.staff = [];
    this.CommonService.getCall(`Transport/GetStaffMember/${this.tnt_code}`).subscribe((staffres: any) => {
      this.staff = staffres.filter(e => e != null);
    });
  }


  onItemSelect(e) { }
  onSelectAll(e) { }
  onSubmit(form: FormGroup) {
    let value: any = form.value;
    let payload = {
      "vehicle_id": this.vehicle_id,
      "driver_id": value.driver_id,
      "cleaner_id": value.cleaner_id,
      "staff_member": this.selectedStaff.map(m => m.id).join(","),
      "licence_document": this.fileName,
      // "staff_member": this.selectedStaff,
      "driving_licence_number": value.driving_licence_number,
      "licence_expire_date": value.licence_expire_date,
      "tnt_code": localStorage.getItem('TenantCode'),
      "created_by": localStorage.getItem('UserId')
    };

    this.CommonService.postCall('Transport/AssignDriverToVehicle', payload).subscribe((res: any) => {

      this.route.navigate(['home/vehicles']);
      this.toastr.success(" Created Successfully");
    }, err => {
      this.toastr.error(err.error ? err.error : 'Not created')

    });
  }

  driverDropdown() {
    this.CommonService.getCall(`Transport/GetAssignDriverToVehicle/${this.vehicle_id}/${this.tnt_code}`).subscribe((res: any) => {
      console.log(res);
      this.fileName = res.licence_document;
      console.log(moment(res['licence_expire_date']).format('yyyy-MM-DD'));
      this.myForm.patchValue({
        driver_id: res['driver_id'],
        driving_licence_number: res['driving_licence_number'],
        licence_expire_date: moment(res['licence_expire_date']).format('yyyy-MM-DD'),
        upload_licence: res['upload_licence'],
        cleaner_id: res['cleaner_id'],
        staff_member: res['staffmembers']
      })
      res.staffmembers.forEach(e => {
        let obj = {
          id: e.Staff_member,
          name: e.name,
        }
        this.selectedStaff.push(obj)
        console.log(this.selectedStaff);
      });
      // this.staffDropdown();
      // let stfid = res['staff_member'].split(',');
      // stfid.forEach(element => {
      //   let name = this.staff?.find(a => a.id == element)?.name;
      //   let obj = {
      //     id: element,
      //     name: name
      //   }
      //   this.selectedStaff.push(obj);
      //   console.log(this.selectedStaff);
      // });


    })

  }

  close() {
    this.myForm.reset();
    this.route.navigate([`home/vehicles/${this.workItemId}`]);
  }

  changeFile(event) {
    if (event.target.files.length > 0) {
      let file: File = event.target.files[0];
      let name = file.name;
      let filetype = name.split('.').pop()
      let types: Array<any> = ['jpg', 'png', 'jpeg']
      let check = types.includes(filetype);
      if (check) {
        this.file = file;
        this.upload();

      }
      else {
        // alert(' Please upload pdf and doc file formats only.')
        this.toastr.warning('Please upload pfc,doc and jpeg file formats only.')
        event.target.value = ''
      }
    }
  }

  upload() {

    const formData = new FormData();
    formData.append('attachment', this.file);
    this.FileuploadService.upload(formData, 'EmsCampus/UploadImage').subscribe((res: any) => {

      try {
        this.fileName = res.path;
        if (this.fileName) {
          this.myForm.controls['QuestionImage'].setValue(this.fileName)
        }
      } catch (e) {
        console.log(e)
      }

    }, err => { this.toastr.warning('Upload Failed') })
  }

}

