import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../base.component';
@Component({
  selector: 'app-questions-feedback-list',
  templateUrl: './questions-feedback-list.component.html',
  styleUrls: ['./questions-feedback-list.component.css']
})
export class QuestionsFeedbackListComponent extends BaseComponent implements OnInit {
  table: Array<any> = [];
  courGoodseYear: Array<any> = [];
  semester: Array<any> = [];
  courseId: any;
  labelName: {};
  GraphView: any = {};
  public barChartOptions1 = {
    scaleShowVerticalLines: false,
    responsive: true
  };
  public barChartLabels = ['Excellent %', 'Very Good %', 'Good %', 'Poor %', 'Very Poor %'];
  public barChartType = 'bar';
  public barChartLegend = true;
  public barChartData = [
    { data: [0, 0, 0, 0, 0], label: 'Question Wise Report' },
  ];
  headingName: any = {};
  semesterId: any;
  courseYear: any;
  constructor(private fb: FormBuilder,  CommonService: CommonService,  toastr: ToastrService, private active: ActivatedRoute) {
    super(CommonService,toastr)
    active.queryParams.subscribe((res) => {
      this.courseId = res.courseId,
      this.semesterId = res.semId,
      this.courseYear = res.courseYear
    })
    this.LoadQuestionList();
    this.loadHeadings()

  }

  ngOnInit(): void {
    this.loadReportDtOptions();
  }

  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/4240/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/18/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  LoadQuestionList() {
    this.table = [];
    let payload = {
      'course_id' : this.courseId,
      'semester_id' : this.semesterId,
      'course_year' : this.courseYear,
      'tnt_code' : localStorage.getItem('TenantCode'),
    }
    this.CommonService.postCall(`EmsFeedback/GetReportByanswer`,payload).subscribe((res: any) => {
      setTimeout(() => {
        this.table = res;
        this.renderDataTable()
        this.loadReportDtOptions()
      }, 10);
    })
  }

  clickView(item: any) {
    this.GraphView = item;
    this.barChartData[0].data = [item.excellent_per, item.verygood_per, item.good_per, item.poor_per, item.verypoor_per]
  }


  close() {

  }

}
