import { Component, OnInit } from '@angular/core';
import { FormArray, FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { data } from 'jquery';

@Component({
  selector: 'app-hostel-students',
  templateUrl: './hostel-students.component.html',
  styleUrls: ['./hostel-students.component.css']
})
export class HostelStudentsComponent implements OnInit {
  table: Array<any> = [];
  myForm: any;
  sId: any;
  headingName: any = {};
  workItemId: any;
  constructor(private fb: FormBuilder, private route: Router, private CommonService: CommonService, private toastr: ToastrService, private active: ActivatedRoute) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadHostelStudent();
    this.loadHeadings();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      items: ['', Validators.required],
      issued_on: ['', Validators.required],
      created_by: ['', Validators.required]
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  async loadHostelStudent() {
    await this.CommonService.postCall('EmsHostelStudents/GetHostelStudentsList', { 'tnt_code': localStorage.getItem('TenantCode') }).subscribe((res: any) => {
      this.table = res;
    })
  }
  issue(Id: any, sId: any) {
    this.route.navigate(['home/issueItems/add'], { queryParams: { id: Id, stuId: sId, wId : this.workItemId} });

    let payload = {
      "student_id": sId,
    }

    this.CommonService.postCall('EmsHostelStudents/GetIssuedItems', payload).subscribe((res: any) => {
      this.datatransform();
    })
  }
  datatransform() {
    this.myForm.patchValue({
      items: data['items']
    })
  }
  // dataTransfer(data) {   
  //   this.myForm.patchValue({
  //     items: data['items'],
  //     issued_on: data['issued_on'],

  //   })
  // }

  //     let payload={
  //       "student_id":74566078
  //   }
  //     this.CommonService.postCall('EmsHostelStudents/GetIssuedItems',payload).subscribe((res:any)=>{

  //     })
  //   }

  //   dataTransfer(data) {   
  //     this.myForm.patchValue({
  //       route_id: data['route_id'],
  //       vehicle_id: data['vehicle_id'],
  //       bus_no: data['bus_no'], 
  //       status:data['status'],
  //       route_buses_status:['']    
  //     })
  // }
  return(Id: any) {
    this.route.navigate(['home/returnItems/add'], { queryParams: { id: Id ,wId : this.workItemId} });
  }

}
