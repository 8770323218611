<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.StudentAttendanceList2}}</h5>
                                    </div>
                                    <div class="card-body">                                        
                                        <form class="well form-horizontal" [formGroup]="myForm"
                                            (ngSubmit)="onSubmit(myForm)">
                                            <fieldset>
                                                <div class="row">

                                                    <div class="form-group col-md-3">
                                                        <label>{{labelName.SelectMonth}}</label>
                                                        <div class=" inputGroupContainer">
                                                            <div class="input-group">
                                                                <input placeholder="Select Month"
                                                                    class="form-control form-control-sm shadow-sm rounded-lg"
                                                                    placeholder="Select YYYY/MM" onfocus="(this.type='month')"
                                                                    type="text" formControlName="month">
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-group col-md-3">
                                                        <label>{{labelName.Campus}}  </label>
                                                        <div class="input-group">
                                                            <select
                                                                class="form-select form-select-sm shadow-sm rounded-lg"
                                                                formControlName="campus_id">
                                                                <option value="" selected>Select</option>
                                                                <option *ngFor="let item of campusDropdown"
                                                                    [value]="item.campus_id">
                                                                    {{item.campus_name}}</option>
                                                            </select>

                                                        </div>
                                                    </div>


                                                    <div class="form-group col-md-3">
                                                        <label>{{labelName.Course}}  </label>
                                                        <div class="input-group">
                                                            <select formControlName="class_id" [(ngModel)]='courceId'
                                                                (change)="courceChange($any($event).target.value)"
                                                                class="form-select form-select-sm shadow-sm rounded-lg">
                                                                <option value="" selected>Select</option>
                                                                <option *ngFor="let course of cources"
                                                                    [value]='course.COURSE_ID'>
                                                                    {{course.COURSE_NAME}}
                                                                </option>
                                                            </select>
                                                        </div>

                                                    </div>

                                                    <div class="form-group col-md-3">
                                                        <label>{{labelName.Section}}   </label>


                                                        <div class="input-group">
                                                            <select formControlName="section_id"
                                                                class="form-select form-select-sm shadow-sm rounded-lg">
                                                                <option value="" selected>Select</option>
                                                                <option *ngFor="let schedul of scheduls"
                                                                    [value]='schedul.COURSESHD_ID'>
                                                                    {{schedul.COURSESHD_NAME}}</option>
                                                            </select>
                                                        </div>

                                                    </div>
                                                    <div class="form-group col-md-3 pt-4">
                                                        <label>{{labelName.AttendanceType}}</label>

                                                        <div class="input-group">
                                                            <select formControlName="status"
                                                                placeholder="Select Attendance Type"
                                                                class="form-select form-select-sm shadow-sm rounded-lg">
                                                                <option value="" selected>Select
                                                                </option>
                                                                <!-- <option value='Period'>Period</option> -->
                                                                <option value='daily'>Day Wise</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3" style="padding-top:45px ;">
                                                        <button type="button" [disabled]="myForm.invalid"
                                                            (click)="onSubmit(myForm)" class="btn btn-sm  btn-save"><i
                                                                class="fas fa-search"></i></button>
                                                    </div>

                                                </div>
                                            </fieldset>
                                        </form>
                                        <!-- <div>
                                            <button type="button" [disabled]="myForm.invalid" (click)="onSubmit(myForm)"
                                                class="btn btn-sm  btn-save"><i class="fas fa-search"></i></button>
                                        </div> -->
                                        <div class="text-right mb-2">
                                            <button type="button" (click)="add()" class="btn btn-primary btn-sm" >{{headingName.PostStudentAttendance1}}</button>
                                        </div>
                                        <br>

                                        <div class="text-center">
                                            <p  *ngIf="!isTable" style="color: red;">Search for
                                                Class and Section to get the student attendance details!</p>
                                        </div>


                                        <div *ngIf="table.length">
                                            <table id="patergraph" datatable *ngIf="isTable"
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th>Student Name</th>
                                                        <th>Total Presents</th>
                                                        <th>Total Absents</th>
                                                        <!-- <th> Total Working Days</th> -->
                                                    </tr>
                                                </thead>
                                               
                                                
                                                <tbody >
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.student_name }}</td>
                                                        <td>{{item.total_presents}}</td>
                                                        <td>{{item.total_absents }}</td>                                                        
                                                        <!-- <td>{{item.totalworkingdays}}</td> -->
                                                    </tr>
                                                </tbody>

                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>