import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { dataDictionary } from 'src/app/dataDictionary';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-book-masters',
  templateUrl: './book-masters.component.html',
  styleUrls: ['./book-masters.component.css']
})
export class BookMastersComponent implements OnInit {
  myForm: FormGroup;
  labelName: any = {};
  headingName: any = {};
  workItemId: any;
  table: Array<any> = [];
  isEdit: boolean = null;
  editData: any;
  id: any;
  subjects: Array<any> = [];
  courses: Array<any> = [];
  language: Array<any> = [];
  codeId:string;
  barCode:any;
  accessionNo: any={};
  userData:any ={};
  category:Array<any>=[];
  name: any;
  constructor(private router: Router,private fb: FormBuilder, active: ActivatedRoute, private CommonService: CommonService, private toastr: ToastrService) {
    // active.params.subscribe(res => {
    //   this.workItemId = res.wId,
    //   this.id = this.id
    // })
    active.queryParams.subscribe((res) => {
      this.workItemId = res.wId,
        this.id = res.id
    })
    this.getLabels();
    this.loadHeadings();
    this.load();
    this.getLanguage();
    this.loadCourse();
    // this.accesionNo();
    this.categoryDropdown();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      BOOK_CATEGORY_ID:['',Validators.required],
      accession_no:[''],
      // BAR_CODE_ID: ['',Validators.required],
      BOOK_NO_OF_COPIES:['',Validators.required]
    })
  }

  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }


    categoryDropdown() {
    this.category = [];
    let payLoad = {
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('EmsBookCategory/GetList', payLoad).subscribe((res: any) => {
      this.category = res;
      // this.name=this.category.find(e=>e.book_category_id).book_category_name
    })
  }

  load() {
    this.activiceSpinner();
    let payLoad: any = {
      BOOK_TNT_CODE: localStorage.getItem('TenantCode'),
      BOOK_ID: this.id
    }
    this.CommonService.postCall('LibraryManagement/GetBookDetailsList', payLoad).subscribe((res: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = res;
        // this.barCode=res[0].BAR_CODE_ID
      }, 10)
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner(), console.log(e) })
  

  }

  activiceSpinner() {
    this.CommonService.activateSpinner();
  }
  deactivateSpinner() {
    this.CommonService.deactivateSpinner();
  }

  accesionNo(id:any){
    // this.name=this.category.find(e=>e.book_category_id == id).book_category_name
    this.myForm.patchValue({
      accession_no : ''
     })
    let payLoad={
      book_category_id: id
    }
    this.CommonService.postCall('LibraryManagement/GetaccessorValue',payLoad).subscribe((res:any)=>{
    //  this.accessionNo=res.accession_no
     this.myForm.patchValue({
      accession_no :res.accession_text
     })
    })
  }


  genrateBarCode(id){
    this.router.navigate([`/home/barCode`], { queryParams: { aNo :id ,wId:this.workItemId} })
    .then(()=>{
      window.location.reload();
    })
  }
  loadCourse() {
    this.activiceSpinner();
    this.CommonService.getAdminCourses().subscribe((res: any) => {
      this.courses = res;
      this.deactivateSpinner();
    }, e => {
      this.deactivateSpinner();
    })

  }
  getSubject(id: any) {
    this.subjects = [];
    this.CommonService.getCall(`Subject/LoadSubjectsByCourse/${id}`).subscribe((res: any) => {
      this.subjects = res;
    })
  }


  getLanguage() {
    this.language = [];
    this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.Language }).subscribe((res: any) => {
      this.language = res;
    }, e => { });

  }

  
  onSubmit(form) {
    let value: any = form.value;

    let payload = {
      
        // "accession_text":value.accession_text,
        // "accession_date":value.accession_date,
        // "material_type":value.material_type,
        // "subject":value.subject,
        // "language_id":value.language_id,
        "book_id":this.id,
        "BOOK_CREATED_BY":localStorage.getItem('UserId'),
        "BOOK_MODIFIED_BY":localStorage.getItem('UserId'),
        "BOOK_TNT_CODE":localStorage.getItem('TenantCode'),
        // "BAR_CODE_ID":value.BAR_CODE_ID
        "book_category_id":+value.BOOK_CATEGORY_ID,
        // "BOOK_CATEGORY_NAME":this.category.find(e=>e.book_category_id == value.BOOK_CATEGORY_ID ).book_category_name,
        // "accession_no":value.accession_no,
        "BOOK_NO_OF_COPIES":value.BOOK_NO_OF_COPIES
    }

    if (this.isEdit) {
      payload['ems_book_inventory_id'] = this.editData.ems_book_inventory_id;
      this.CommonService.postCall('LibraryManagement/UpdateBookDetails', payload).subscribe((res: any) => {
        this.load();
        this.toastr.success("Book Updated Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Book Not Updated')
      })
    } else {

      this.CommonService.postCall('LibraryManagement/CreateBookDetails', payload).subscribe((res: any) => {
        this.load();
        this.toastr.success("Book Created Successfully");
        document.getElementById('md_close').click();
        window.location.reload();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Book Not created')
        this.codeId='';
      })
    }
  }
  edit(bookId) {
    this.editData = bookId;
    this.isEdit = true;
    this.myForm.reset();
    let payLoad = {
      "ems_book_inventory_id": bookId
    }
    this.CommonService.postCall('LibraryManagement/GetBookDetails', payLoad).subscribe((res: any) => {
      if (res instanceof Array && res.length) {
        this.editData = res[0];
        this.getSubject(this.editData['BOOK_DEPARTMENT_ID'])
        this.dataTransForm()
      } else {
        this.editData = res;
        this.getSubject(this.editData['BOOK_DEPARTMENT_ID'])
        this.dataTransForm()
      }
      this.getSubject(this.editData['BOOK_DEPARTMENT_ID'])
      this.dataTransForm();
    }), err => { }


  }
   dataTransForm() {
   let ctrls = this.myForm.controls
    Object.keys(ctrls).map((key) => {
      let ctrl: AbstractControl = ctrls[key];
      ctrl.setValue(this.editData[key])
    });
    ctrls['accession_text'].setValue(this.editData['accession_text']);
    ctrls['accession_date'].setValue(moment(this.editData['accession_date']).format('yyyy-MM-DD'))
    ctrls['material_type'].setValue(this.editData['material_type']);
    ctrls['BOOK_DEPARTMENT_ID'].setValue(this.editData['BOOK_DEPARTMENT_ID']);
    ctrls['subject'].setValue(this.editData['subject']);
    ctrls['language_id'].setValue(this.editData['language_id']);
    ctrls['BAR_CODE_ID'].setValue(this.editData['BAR_CODE_ID']);
   
 }
  close() {
    this.isEdit = null;
    this.myForm.reset();
    this.editData = {};
   
  }
}
