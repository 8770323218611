<!-- <div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">Receive Items</h5>
                                    </div>
                                    <div class="card-body">
                                        <form action="" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                                            <fieldset>

                                                <table class="table-striped table-bordered mt-2 pt-2"
                                                    style="width:100%">
                                                    <tr class="">
                                                        <td class=" "><b>Vender :</b>
                                                            <span>{{this.editData.vendor_name}}</span>
                                                        </td>
                                                        <td colspan="2">
                                                            <div class="row">
                                                                <div class="col-xl-5">
                                                                    <b>Payment Date :</b>
                                                                </div>
                                                                <div class="col-xl-7">
                                                                    <input class="form-control form-control-sm"
                                                                        formControlName="payment_date" type="date"
                                                                        placeholder=".form-control-sm"
                                                                        aria-label=".form-control-sm example">
                                                                </div>
                                                            </div>


                                                        </td>
                                                        <td colspan="2">
                                                            <div class="row">
                                                                <div class="col-xl-5">
                                                                    <b>Goods Recieved Date:</b>
                                                                </div>
                                                                <div class="col-xl-7">
                                                                    <input class="form-control form-control-sm"
                                                                        formControlName="good_recieved_date" type="date"
                                                                        placeholder=".form-control-sm"
                                                                        aria-label=".form-control-sm example">
                                                                </div>
                                                            </div>


                                                        </td>


                                                    </tr>

                                                    <div *ngIf="myForm.controls['items']">
                                                        <div formArrayName='items'>

                                                            <tr class="">
                                                                <td class=""><b>Item</b></td>
                                                                <td class=""><b>Requested Quantity</b></td>
                                                                <td class=""><b>Quoted Cost</b></td>
                                                                <td class=""><b>Received Quantity</b></td>
                                                                <td class=""><b>Amount</b></td>

                                                            </tr>

                                                            <tr *ngFor="let item of myForm.controls['items'].controls;let i=index"
                                                                [formGroupName]="i">
                                                                <td> <select class="form-select form-select-sm"
                                                                        aria-label=".form-select-sm example"
                                                                        formControlName="item_id">
                                                                        <option [selected]="true">Select Items
                                                                        </option>
                                                                        <option disabled
                                                                            *ngFor="let item of itemsDropDown"
                                                                            [value]="item.item_id">
                                                                            {{item.item_name}}
                                                                        </option>
                                                                    </select>
                                                                </td>
                                                                <td> <input class="form-control form-control-sm"
                                                                        type="text" placeholder="Requested Quantity"
                                                                        formControlName="quotated_quantity" disabled
                                                                        aria-label=".form-control-sm example"></td>
                                                                <td> <input class="form-control form-control-sm"
                                                                        type="text" placeholder="Quoted Cost"
                                                                        formControlName="quoted_cost" disabled
                                                                        aria-label=".form-control-sm example"></td>
                                                                <td> <input class="form-control form-control-sm"
                                                                        type="text" placeholder="Received Quantity"
                                                                        formControlName="received_quantity"
                                                                        aria-label=".form-control-sm example"></td>
                                                                <td> <input class="form-control form-control-sm"
                                                                        type="text" placeholder="Amount" disabled
                                                                        formControlName="amount"
                                                                        aria-label=".form-control-sm example"></td>
                                                            </tr>
                                                        </div>
                                                    </div>


                                                    <tr>
                                                        <td colspan="4"><span class="float-end">Total
                                                                Amount</span></td>
                                                        <td> <input class="form-control form-control-sm" type="text"
                                                                placeholder="Total Amount" [(ngModel)]="total_cost"
                                                                formControlName="total_amount" disabled
                                                                aria-label=".form-control-sm example"></td>

                                                    </tr>
                                                    <tr>
                                                        <td colspan="4"><span class="float-end">Advance
                                                                Paid</span></td>
                                                        <td> <input class="form-control form-control-sm" type="text"
                                                                placeholder="Advance Paid"
                                                                formControlName="advance_paid"
                                                                aria-label=".form-control-sm example"></td>

                                                    </tr>
                                                    <tr>
                                                        <td colspan="4"><span class="float-end">Paid
                                                                Amount</span></td>
                                                        <td> <input class="form-control form-control-sm" type="text"
                                                                placeholder="Paid Amount" formControlName="paid_amount"
                                                                aria-label=".form-control-sm example"></td>

                                                    </tr>

                                                    <tr>

                                                        <td colspan="4">
                                                            <label for="">Payment Type</label>
                                                        </td>
                                                        <td>
                                                            <div class="form-check mb-3">
                                                                <input class="form-check-input" type="radio"
                                                                    value="Cash" (click)="cheque($event)"
                                                                    name="flexRadioDefault" id="flexRadioDefault1"
                                                                    [checked]="true">
                                                                <label class="form-check-label" for="flexRadioDefault1">
                                                                    Cash
                                                                </label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio"
                                                                    value="Cheque" (click)="cheque($event)"
                                                                    name="flexRadioDefault" id="flexRadioDefault2"
                                                                    checked>
                                                                <label class="form-check-label" for="flexRadioDefault2">
                                                                    Cheque
                                                                </label>
                                                            </div>
                                                            <div *ngIf="isCheque">
                                                                <div>
                                                                    <label class="form-check-label"
                                                                        for="flexRadioDefault2">
                                                                        Cheque Number
                                                                    </label>
                                                                    <input class="form-control form-control-sm mb-3"
                                                                        type="text" placeholder="Cheque Number"
                                                                        formControlName="cheque_number"
                                                                        aria-label=".form-control-sm example">
                                                                </div>
                                                                <div>
                                                                    <label class="form-check-label"
                                                                        for="flexRadioDefault2">
                                                                        Cheque Date
                                                                    </label>
                                                                    <input class="form-control form-control-sm"
                                                                        type="date" placeholder=".Cheque Date"
                                                                        formControlName="cheque_date"
                                                                        aria-label=".form-control-sm example">
                                                                </div>
                                                            </div>


                                                        </td>

                                                    </tr>
                                                    <br>

                                                </table>
                                                
                                                <div class="row" *ngIf="myForm.controls['getItems']">
                                                    <table class="col-md-8" formArrayName='getItems'>
                                                        
                                                        <tbody>
                                                            <tr  *ngFor="let item of myForm.controls['getItems'].controls;let i=index"
                                                            [formGroupName]="i">
                                                                <td colspan="2">
                                                                    <select class="form-select form-select-sm"
                                                                        aria-label=".form-select-sm example"
                                                                        formControlName="item_id">
                                                                        <option [selected]="true">Select Items
                                                                        </option>
                                                                        <option 
                                                                            *ngFor="let item of itemsDropDown"
                                                                            [value]="item.item_id">
                                                                            {{item.item_name}}
                                                                        </option>
                                                                    </select>
                                                                </td>
                                                                <td colspan="3">
                                                                    <input class="form-control form-control-sm"
                                                                        type="text" placeholder="Standard Price"
                                                                        formControlName="standard_price"
                                                                        aria-label=".form-control-sm example">

                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <br>
                                                    <div class="col-md-10">
                                                        <div class="float-end">
                                                            <button type="button" class="btn btn-di float-end"
                                                                *ngIf="isVisable()" (click)="delete()"><i
                                                                    class="fas fa-trash"></i></button> &nbsp;
                                                            <button type="button" class="btn btn-ai float-end" *ngIf="isAdd()"
                                                                (click)="add()">Add
                                                                Item</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </form>

                                    </div>
                                    <div class="text-center pt-4 mb-4">
                                       
                                        <button type="button" class="btn btn-sm  btn-save"
                                            [disabled]="myForm.invalid" (click)="onSubmit(myForm)"
                                            type="submit">Save</button>
                                        &nbsp; <button class="btn btn-danger btn-sm"
                                            (click)='back()'>Close</button>

                                    </div>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->



<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.ReceiveItems}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <form action="" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                                            <fieldset>
                                                <div *ngIf="myForm.controls['receicedItems']">
                                                    <div formArrayName='receicedItems'>
                                                        <table class=" table-bordered receive_items_table pt-4 mb-4" *ngFor="let rev of myForm.controls['receicedItems'].controls;let inn = index"
                                                        [formGroupName]="inn"
                                                            style="width: 100%;">

                                                            <tr class="">

                                                                <td class=" ">Vendor :                                                                    
                                                                    <input type="text" formControlName="vendor_name" disabled>
                                                                </td>
                                                                <td colspan="2">
                                                                    <div class="row">
                                                                        <div class="col-xl-5">
                                                                            <b>Payment Date :</b>
                                                                        </div>
                                                                        <div class="col-xl-7">
                                                                            <input class="form-control form-control-sm"
                                                                                formControlName="payment_date"
                                                                                type="date"
                                                                                placeholder=".form-control-sm"
                                                                                aria-label=".form-control-sm example">
                                                                        </div>
                                                                    </div>


                                                                </td>
                                                                <td colspan="2">
                                                                    <div class="row">
                                                                        <div class="col-xl-5">
                                                                            <b>Good Recieved Date :</b>
                                                                        </div>
                                                                        <div class="col-xl-7">
                                                                            <input class="form-control form-control-sm"
                                                                                formControlName="good_recieved_date"
                                                                                type="date"
                                                                                placeholder=".form-control-sm"
                                                                                aria-label=".form-control-sm example">
                                                                        </div>
                                                                    </div>


                                                                </td>


                                                            </tr>
                                                            <tr>
                                                                <td colspan="5">
                                                                    <div *ngIf="rev.controls['quantity']">
                                                                        <div formArrayName='quantity'>
                                                                            <table class="receive_innertable"
                                                                                style="width: 100%;">
                                                                                <tr *ngFor="let item of rev.controls.quantity.controls;let i=index"
                                                                                    [formGroupName]="i">
                                                                                    <td>
                                                                                        <label><strong>{{labelName.Item}}</strong></label>
                                                                                        <select
                                                                                            class="form-select form-select-sm"
                                                                                            aria-label=".form-select-sm example"
                                                                                            formControlName="item_id">
                                                                                            <option [selected]="true">
                                                                                                Select
                                                                                                Items
                                                                                            </option>
                                                                                            <option disabled
                                                                                                *ngFor="let item of itemsDropDown"
                                                                                                [value]="item.item_id">
                                                                                                {{item.item_name}}
                                                                                            </option>
                                                                                        </select>
                                                                                    </td>
                                                                                    <td><label><strong>{{labelName.RequestedQuantity}}</strong></label>
                                                                                        <input
                                                                                            class="form-control form-control-sm"
                                                                                            type="text" disabled
                                                                                            formControlName="quotated_quantity"
                                                                                            aria-label=".form-control-sm example">
                                                                                    </td>
                                                                                    <td> <label><strong>Quoted
                                                                                                Cost</strong></label><input
                                                                                            class="form-control form-control-sm"
                                                                                            type="text" disabled
                                                                                            formControlName="Quotedfinalcost"
                                                                                            aria-label=".form-control-sm example">
                                                                                    </td>
                                                                                    <td> <label><strong>{{labelName.ReceivedQuantity}}</strong></label><input
                                                                                            class="form-control form-control-sm"
                                                                                            type="text"
                                                                                            formControlName="received_quantity"
                                                                                            aria-label=".form-control-sm example">
                                                                                    </td>
                                                                                    <td><label><strong>{{labelName.Amount}}</strong></label>
                                                                                        <input disabled
                                                                                            class="form-control form-control-sm"
                                                                                            type="text"
                                                                                            formControlName="amount"
                                                                                            aria-label=".form-control-sm example">
                                                                                    </td>
                                                                                </tr>

                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td class="border-end-0" colspan="3"></td>
                                                                <td class="border-start-0"><span class="float-end">Total
                                                                        Amount</span></td>
                                                                <td> <input class="form-control form-control-sm"
                                                                        type="text" placeholder="Total Amount"
                                                                        
                                                                        formControlName="total_amount" disabled
                                                                        aria-label=".form-control-sm example"></td>

                                                            </tr>
                                                            <tr>
                                                                <td class="border-end-0" colspan="3"></td>
                                                                <td class="border-start-0"><span
                                                                        class="float-end">Advance
                                                                        Paid</span></td>
                                                                <td> <input class="form-control form-control-sm"
                                                                        type="text" placeholder="Advance Paid"
                                                                        formControlName="advance_paid"
                                                                        aria-label=".form-control-sm example"></td>

                                                            </tr>
                                                            <tr>
                                                                <td class="border-end-0" colspan="3"></td>
                                                                <td class="border-start-0"><span class="float-end">Paid
                                                                        Amount</span></td>
                                                                <td> <input class="form-control form-control-sm"
                                                                        type="text" placeholder="Paid Amount"
                                                                        formControlName="paid_amount"
                                                                        aria-label=".form-control-sm example"></td>

                                                            </tr>
                                                            <tr>
                                                                <td class="border-end-0" colspan="3"></td>
                                                                <td class="border-start-0"><span
                                                                        class="float-end">Payment
                                                                        Type</span></td>
                                                                <td>
                                                                    <div class="form-check ">
                                                                        <input class="form-check-input" type="radio"
                                                                            value="Cash"  id="{{ 'acceptable' + inn}}"
                                                                           
                                                                             formControlName="payment_mode">
                                                                        <label class="form-check-label" for="{{ 'acceptable' + inn}}"
                                                                          >
                                                                            Cash
                                                                        </label>
                                                                        <!-- name={{getName(inn)}}  -->
                                                                    </div>
                                                                    <div class="form-check mb-3">
                                                                        <input class="form-check-input" type="radio"
                                                                            value="Cheque" 
                                                                            id="{{ 'unacceptable' + inn}}"
                                                                               formControlName="payment_mode">
                                                                        <label class="form-check-label" for="{{ 'unacceptable' + i}}"
                                                                            >
                                                                            Cheque
                                                                        </label>
                                                                    </div>
                                                                    <div *ngIf="rev.value.payment_mode=='Cheque'">
                                                                        <div >
                                                                            <label class="form-check-label"
                                                                                for="flexRadioDefault2">
                                                                                {{labelName.ChequeNumber}}<span
                                                                                    class="text-danger">*</span>
                                                                            </label>
                                                                            <input
                                                                                class="form-control form-control-sm mb-3"
                                                                                type="text" placeholder="Cheque Number"
                                                                                formControlName="cheque_number"
                                                                                aria-label=".form-control-sm example">
                                                                        </div>
                                                                        <div >
                                                                            <label class="form-check-label"
                                                                                for="flexRadioDefault2">
                                                                                {{labelName.ChequeDate}}<span
                                                                                    class="text-danger">*</span>
                                                                            </label>
                                                                            <input class="form-control form-control-sm"
                                                                                type="date" placeholder=".Cheque Date"
                                                                                formControlName="cheque_date"
                                                                                aria-label=".form-control-sm example">
                                                                        </div>
                                                                    </div>


                                                                </td>

                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                                <br>
                                                <div>
                                                    <table class=" table-bordered receive_items_table"
                                                        style="width: 100%;">
                                                        <tr>
                                                            <td colspan="5">
                                                                <div class="row" *ngIf="myForm.controls['items']">

                                                                    <table class="receive_innertable"
                                                                        style="width: 100%;" formArrayName='items'>
                                                                        <tr *ngFor="let item of myForm.controls['items'].controls;let in=index"
                                                                            [formGroupName]="in">
                                                                            <td>
                                                                                <select
                                                                                    class="form-select form-select-sm"
                                                                                    aria-label=".form-select-sm example"
                                                                                    formControlName="item_id">
                                                                                    <option [selected]="true">
                                                                                        Select Items
                                                                                    </option>
                                                                                    <option
                                                                                        *ngFor="let item of itemsDropDown"
                                                                                        [value]="item.item_id">
                                                                                        {{item.item_name}}
                                                                                    </option>
                                                                                </select>
                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    class="form-control form-control-sm"
                                                                                    type="text"
                                                                                    placeholder="Standard Price"
                                                                                    formControlName="unit_price"
                                                                                    aria-label=".form-control-sm example">

                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                    <br>
                                                                    <div class="col-md-10">
                                                                        <div class="float-end">
                                                                            <button type="button"
                                                                                class="btn btn-di float-end"
                                                                                *ngIf="isVisable()"
                                                                                (click)="delete()"><i
                                                                                    class="fas fa-trash"></i></button>
                                                                            &nbsp;
                                                                            <button type="button"
                                                                                class="btn btn-ai float-end"
                                                                                *ngIf="isAdd()" (click)="add()">Add
                                                                                Item</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </fieldset>
                                        </form>
                                    </div>
                                    <div class="text-center pt-4 mb-4">

                                        <button type="button" class="btn btn-sm  btn-save" [disabled]="myForm.invalid"
                                            (click)="onSubmit(myForm)" type="submit">Save</button>
                                        &nbsp; <button class="btn btn-danger btn-sm" (click)='back()'>Close</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>