<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.AddQuotation}}</h5>
                                        <!-- <h5 *ngIf="isEdit" class="mb-0">Edit Quotation</h5> -->
                                    </div>
                                    <div class="card-body">
                                        <div class="custom_container">
                                            <form class="well form-horizontal" [formGroup]="myForm"
                                                (ngSubmit)="onSubmit(myForm)">

                                                <fieldset>
                                                    <div class="form-group row">
                                                        <label class="col-md-2 offset-md-3 control-label mb-4">
                                                            {{labelName?.Requestdate}} <span class="text-danger">*</span></label>
                                                        <div class="col-md-4 mb-4 inputGroupContainer">
                                                            <div class="input-group"> <strong>:</strong> &nbsp;
                                                                <input placeholder="Request Date"
                                                                    class="form-control form-control-sm"
                                                                    formControlName="request_date" type="date">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="myForm.controls['list']">
                                                        <div formArrayName='list'>
                                                            <div class="form-group row">
                                                                <table class="col-md-6 offset-md-3 text-center">
                                                                    <thead class="top"
                                                                        style="background-color: #624FD7; color:#ffffff;">
                                                                        <tr>
                                                                            <th>Item</th>
                                                                            <th>Quantity</th>
                                                                            <th>Unit Cost</th>
                                                                            <th>Estimated Total</th>

                                                                        </tr>
                                                                    </thead>

                                                                    <tbody
                                                                        *ngFor="let item of myForm.controls['list'].controls;let i=index"
                                                                        [formGroupName]="i">
                                                                        <tr>
                                                                            <td><select
                                                                                    class="form-control form-control-sm"
                                                                                    formControlName="item_id" id="item">
                                                                                    <option value="" [selected]="true">
                                                                                        Select
                                                                                    </option>
                                                                                    <option *ngFor="let item of items" [value]="item.item_id">{{item.item_name}}
                                                                                    </option>
                                                                                    

                                                                                </select></td>
                                                                            <td> <input placeholder="Quantity"
                                                                                    formControlName="quantity"
                                                                                    class="form-control form-control-sm"
                                                                                    type="number" id="Quantity"
                                                                                    [(ngModel)]="item.quantity">
                                                                            </td>
                                                                            <td> <input placeholder="Unit Cost"
                                                                                    formControlName="estimated_cost"
                                                                                    class="form-control form-control-sm"
                                                                                    type="number" id="unitCost"
                                                                                    [(ngModel)]="item.unitCost">
                                                                            </td>
                                                                            <td> <input placeholder="Estimated Totals"
                                                                                    disabled
                                                                                    formControlName="estimated_total"
                                                                                    class="form-control form-control-sm"
                                                                                    [(ngModel)]="item.estimatedTotal"
                                                                                    type="number" id="estimatedTotal">

                                                                            </td>


                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>

                                                        <div class="row">
                                                            <div class="col-md-6 offset-md-3 mt-4">
                                                                <div class="float-end">
                                                                    <button type="button" class="btn btn-di float-end"
                                                                        *ngIf="isVisable()" (click)="delete()"><i
                                                                            class="fas fa-trash"></i></button>
                                                                    <button type="button" class="btn btn-ai float-end" *ngIf="isAdd()"
                                                                        (click)="add()">Add
                                                                        Item</button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div class="form-group row ">
                                                        <label class="col-md-2 offset-md-3 control-label mb-4 mt-4">
                                                            {{labelName?.TotalCost}} <span class="text-danger">*</span></label>
                                                        <div class="col-md-4 mb-4 inputGroupContainer  mt-4">
                                                            <div class="input-group"> <strong>:</strong> &nbsp;
                                                                <input placeholder="Total Cost" [(ngModel)]="total_cost"
                                                                    disabled class="form-control form-control-sm"
                                                                    formControlName="quoted_total" type="test">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </form>
                                            <div>
                                                <div class="text-center pt-4">
                                                    <!-- <button type="button" *ngIf="isEdit" class="btn btn-sm  btn-save"
                                                        [disabled]="myForm.invalid" (click)="onSubmit(myForm)"
                                                        type="submit">Update</button> -->

                                                    <button type="button" class="btn btn-sm  btn-save"
                                                        [disabled]="myForm.invalid" (click)="onSubmit(myForm)"
                                                        type="submit">Save</button>
                                                    &nbsp; <button class="btn btn-danger btn-sm"
                                                        (click)='back()'>Close</button>

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>