import { Component, OnInit } from '@angular/core';
import { FormArray, FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-hostel-items',
  templateUrl: './hostel-items.component.html',
  styleUrls: ['./hostel-items.component.css']
})
export class HostelItemsComponent implements OnInit {
  table: Array<any> = [];
  headingName: any = {};
  workItemId: any;

  constructor(private fb: FormBuilder, private route: Router, private CommonService: CommonService, private toastr: ToastrService, private active: ActivatedRoute) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadHostelItems();
    this.loadHeadings();
  }
 
  ngOnInit(): void {
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  async loadHostelItems() {
    await this.CommonService.postCall('EmsHostelStudents/GetHostelItemsList', { 'tnt_code': localStorage.getItem('TenantCode')}).subscribe((res: any) => {
      this.table = res;
    })
  }

}
