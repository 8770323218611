import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {
  myForm: FormGroup;
  categories:Array<any>=[];
  constructor() { }

  ngOnInit(): void {
  }
  onSubmit(form:FormGroup){

  }
  close(){
    
  }
}
