import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ThemeService } from 'ng2-charts';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-assigned-periods',
  templateUrl: './assigned-periods.component.html',
  styleUrls: ['./assigned-periods.component.css']
})
export class AssignedPeriodsComponent implements OnInit {
  table: Array<any> = [];
  sheduleId: string = '';
  courceId: string = '';
  cources: Array<any> = [];
  scheduls: Array<any> = [];
  teachers: Array<any> = [];
  periods: Array<any> = [];
  list: FormArray;
  thead: Array<any> = [];
  metaData: Array<any> = [];
  finalNum: Array<any> = [];
  isPrint: Boolean = false;
  labelName: any = {};
  headingName: any = {};
  workItemId: any;
  tenantCode = localStorage.getItem('TenantCode');
  trainer: any;
  constructor(private fb: FormBuilder, private active: ActivatedRoute, private CommonService: CommonService, private toastr: ToastrService, private route: Router, private ref: ChangeDetectorRef) {
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
    this.loadTeachers();
    this.loadlabels();
    this.loadHeadings();
    this.getCourses(this.tenantCode);
  }

  ngOnInit(): void {

  }
  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }


  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  getCourses(tId: any) {
    this.activeSpinner()
    this.CommonService.getCall(`Courses/GetAdminCourses/${localStorage.getItem('UserId')}/${localStorage.getItem('RoleId')}/${tId || localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.cources = res;
      this.deactivateSpinner()
    }, e => {
      this.deactivateSpinner()
    })
  }

  courceChange(id) {
    this.sheduleId = '';
    let data = {
      "CourseId": id
    }
    this.activeSpinner()
    this.CommonService.getAdminCourseSchedule(data).subscribe((res: any) => {
      this.deactivateSpinner()
      this.scheduls = res;
    }, e => { this.deactivateSpinner() })
  }

  getTeachers(ids) {
    let payload = {
      "COURSE_TRAINER_COURSESHD_ID": ids
    }
    this.CommonService.postCall('CourseSchedule/LoadAssignTrainers', payload).subscribe((res: any) => {
      this.teachers = res;
    })
  }
  loadAssignedPeriods(event: any) {
    this.metaData = [];
    this.table = [];
    this.isPrint = false;
    let id = event.target.value;
    this.metaData.push('Day')
    let payLoad = {
      "people_id": id,
      "USER_ID": localStorage.getItem('UserId'),
      "ROLE_NAME": localStorage.getItem('RoleName')
    }
    this.CommonService.postCall('TimeTable/AssignedPeriodsList', payLoad).subscribe((res: any) => {
      this.thead = res;
      this.isPrint = true;
      res.forEach(e => {
        let obj = {
          day: '',
        }
        if (e.periods.length > 0) {
          e.periods.forEach((ep, index) => {
            if (index == 0) {
              if (e.day === 1) {
                obj.day = "Monday"
              }
              if (e.day === 2) {
                obj.day = "Tuesday"
              }
              if (e.day === 3) {
                obj.day = "Wednesday"
              }
              if (e.day === 4) {
                obj.day = "Thursday"
              }
              if (e.day === 5) {
                obj.day = "Friday"
              }
              if (e.day === 6) {
                obj.day = "Saturday"
              }
            }
            if (!this.metaData.includes('Period' + '_' + ep.period)) {
              this.metaData.push('Period' + '_' + ep.period);
              console.log(this.metaData)
            }
            if (!this.finalNum.includes('Period' + '_' + ep.period)) {
              this.finalNum.push('Period' + '_' + ep.period);
              console.log(this.finalNum)
            }
            obj['Period' + '_' + ep.period] = ep;
          });
          this.table.push(obj);
        }
      });


    })

  }


  loadTeachers() {
    this.CommonService.postCall(`Registration/GetTrainersList`, { "TenantCode": localStorage.getItem('TenantCode') }).subscribe((res: any) => {
      this.teachers = res;
    })
  }


  Print() {
    var printContents: any = document.getElementById("print")?.innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  }
}
