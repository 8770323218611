<div class="page-wrapper">
    <div class="page-content pr-0">
        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-xl-8 col-8 pt-2">
                        <h6 class="mb-0 text-uppercase"> {{headingName.Clientslist}}</h6>
                    </div>
                    <div class="col-xl-4 col-4">
                        <button type="button" class="btn btn-sm btn-primary float-end" data-bs-toggle="modal"
                            data-bs-target="#myModal" (click)="add()"><i class="fas fa-plus-circle"></i>Add Client</button>
                    </div>
                </div>
            </div>

            <div class="card-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <div class="row">
                        <div class="col-md-3">
                            <label class="col-form-label">Type keyword to search</label>
                            <div class="input-group">
                                <input type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label class="col-form-label">Name <span class="text-danger">*</span></label>
                            <div class="input-group">
                                <select class=" form-select form_select_border" aria-label="Default select example">
                                    <option value="">Select</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label class="col-form-label">Contains <span class="text-danger">*</span></label>
                            <div class="input-group">
                                <select class=" form-select form_select_border" aria-label="Default select example">
                                    <option value="">Select</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label class="col-form-label">Select Status <span class="text-danger">*</span></label>
                            <div class="input-group">
                                <select class=" form-select form_select_border" aria-label="Default select example">
                                    <option value="">Select</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="text-center pt-1 mt-3">
                    <button type="button"  class="btn btn-sm btn-save">Search</button>
                </div>
                <div class="table-responsive mt-3">
                    <table datatable class="table table-striped table-bordered" style="width:100%">
                        <thead >
                            <tr>
                                <th>Name</th>
                                <th>Reg No.</th>
                                <th>Slug</th>
                                <th>Mobile</th>
                                <th>Subscription</th>
                                <th>Licence Start</th>
                                <th>Licence End</th>
                                <th>Students</th>
                                <th>Users</th>
                                <th>Client Status</th>
                                <th>Licence Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr >
                                <td>Dhanush</td>
                                <td>12345</td>
                                <td>Dhanush</td>
                                <td>9123456789</td>
                                <td>Live Version</td>
                                <td>30jun</td>
                                <td>20jun</td>
                                <td>0</td>
                                <td>1</td>
                                <td>Active</td>
                                <td>Active</td>
                                <td>
                                    <select class=" form-select form_select_border" aria-label="Default select example">
                                        <option value="">Actions</option>
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
            </div>
           
        </div>
    </div>
</div>