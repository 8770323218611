<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.BonafiedCertificate}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="form-group">
                                            <div class="row mb-4">

                                                <div *ngIf="roleId=='4' || roleId=='5'" class="col-md-3 p-4">
                                                    <label for="sel1">{{labelName.TenantName}} : </label>
                                                    <select [(ngModel)]='tId ' (change)="getCourses($any($event.target).value)"
                                                        class="form-control form-control-sm shadow-sm rounded-lg"
                                                        id="sel1">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let item of tenanates" [value]='item.TNT_CODE'>
                                                            {{item.TNT_NAME}}</option>
                                                    </select>
                          
                                                </div>
                                                <div class="col-md-3 p-4">
                                                    <label for="sel1">{{labelName.Course}} <strong>:</strong> </label>
                                                    <select (change)="courceChange($event)"
                                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let course of cources"
                                                            [value]='course.COURSE_ID'>
                                                            {{course.COURSE_NAME}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <!-- <div class="col-md-3 p-4">
                                                    <label for="sel1">{{labelName.Students}} <strong>:</strong> </label>
                                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                        (change)="getUsersData($event)">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let item of students" [value]='item.userid'>
                                                            {{item.name}}
                                                        </option>
                                                    </select>
                                                </div> -->


                                                
                                                <!-- <div class="col-md-3 pt-4">
                                                    <div class="inputbox">
                                                        <label for="sel1">{{labelName.Students}}: </label>
                                                        <input type="text" title="Search in box" (click)="show = !show"
                                                            class="form-control form-control-sm"
                                                            [(ngModel)]="currentValue" [placeholder]="'Enter Value'"
                                                            (keyup.enter)="submit()" (keyup.esc)="clear()" /> -->
                                                        <!-- <button class="btn btn-save btn-sm"
                                                            (click)="show = !show">Select</button> -->
                                                        <!--focus & blur to control display-->
                                                    <!-- </div> -->
                                                    <!--below is options list-->
                                                  
                                                <!-- </div> -->
                                                <div class="col-md-3 p-4">
                                                    <label for="sel1">{{labelName.PurposeOfBonafied}}
                                                        <strong>:</strong></label>
                                                    <input class="form-control form-control-sm" [(ngModel)]="purpose" />
                                                </div>
                                                <div class="col-md-3 p-4">
                                                    <label for="sel1">{{labelName.Remarks}}
                                                        <strong>:</strong></label>
                                                    <input class="form-control form-control-sm" [(ngModel)]="remarks"
                                                        #name />
                                                </div>

                                                <div class="col-md-3 p-4">
                                                    <label for="sel1">{{labelName.EnrollmentNumber}}
                                                        <strong>:</strong></label>
                                                    <input class="form-control form-control-sm"
                                                        (change)="enrollNumber($event)" [disabled]="bonafied"
                                                        #EnrollmentNumber [(ngModel)]="Enrollment_Number" />
                                                </div>


       
                                                <div class="col-md-3 pt-4">
                                                    <div class="inputbox">
                                                        <label for="sel1">{{labelName.Students}}: </label>
                                                        <input type="text" title="Search in box" (click)="show = !show"
                                                            class="form-control form-control-sm"
                                                            [(ngModel)]="currentValue" [placeholder]="'Enter Value'"
                                                            (keyup.enter)="submit()" (keyup.esc)="clear()" />
                                                        <!-- <button class="btn btn-save btn-sm"
                                                            (click)="show = !show">Select</button> -->
                                                        <!--focus & blur to control display-->
                                                    </div>
                                                    <!--below is options list-->
                                                  
                                                </div>




                                                <div class="inputbox col-md-3" *ngIf="show">
                                                    <!-- <div class="overflow-auto" style="cursor: pointer;"
                                                        *ngFor="let option of stuSearchArr | searchPipe:currentValue "
                                                        (click)="optionselected(option)">
                                                        {{option}}
                                                    </div> -->
                                                    <!-- <div class="scrolling-wrapper">
                                                        <div class="cardblock" style="cursor: pointer;"
                                                            *ngFor="let option of stuSearchArr | searchPipe:currentValue "
                                                            (click)="optionselected(option)">
                                                            {{option}}
                                                        </div>

                                                    </div> -->
                                                    <div class="cardblock">
                                                        <div class="card-body">
                                                          <!-- <h5 class="card-title">Students List</h5> -->
                                                          <div class="scrollable">
                                                            <p class="card-text" *ngFor="let option of stuSearchArr | searchPipe:currentValue "
                                                            (click)="optionselected(option)" style="cursor: pointer;">{{option}}</p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    <!-- <div class="inputbox--display" style="cursor: pointer;"
                                                        *ngFor="let option of stuSearchArr | searchPipe:currentValue "
                                                        (click)="optionselected(option)">{{option}}</div> -->
                                                    <!-- <select class="form-select form-select-sm shadow-sm rounded-lg">
                                                        <option value="" [selected]="true">select</option>
                                                        <option (click)="optionselected(option)"
                                                            *ngFor="let option of stuSearchArr | searchPipe:currentValue ">
                                                            {{option}}
                                                        </option>
                                                    </select> -->

                                                </div>
                                                <!-- <div class="form-group col-md-6 pt-4">
                                                    <label class="col-md-4 control-label">
                                                        Purpose Of Bonafied<strong>:</strong> <span
                                                            class="text-danger">*</span></label>
                                                    <div class="col-md-4 inputGroupContainer">
                                                        <div class="input-group"> &nbsp;
                                                            <input class="form-control" [(ngModel)]="purpose" #name />
                                                        </div>
                                                    </div>
                                                </div> -->
                                            </div>
                                            <!--  -->
                                            <button class="btn btn-save btn-sm" (click)="generateBonafied()"
                                                [disabled]="isDisabled(+EnrollmentNumber.value.length,name.value.length)">Generate
                                                Bonafide</button>&nbsp;
                                            <button class="btn btn-save btn-sm" (click)="duplicateBonafied()"
                                                [disabled]="isDisabled(+EnrollmentNumber.value.length,name.value.length)">Duplicate
                                                Bonafide</button>

                                            <div *ngIf="receipt" class="certificate-container" id="printtable">
                                                <div class="certificate-header">
                                                    <div class="logo1" style="width: 15%; text-align: center;">
                                                        <img src="../../../assets/img/logo1.jpg" alt="" width="70px">
                                                    </div>
                                                    <div style="width: 70%; text-align: center;">
                                                        <p style="margin-bottom:0">Shri Sai Shikshan Sanstha's
                                                        </p>
                                                        <h2 style="color: #FD5845; font-size: 2.3em; margin-bottom:0">
                                                            NIT
                                                            Polytechnic</h2>
                                                        <h4
                                                            style="color: #FD5845; font-size: 15px; font-weight: normal; margin-bottom:0">
                                                            All Programmes are NBA Accredited</h4>
                                                    </div>
                                                    <div class="logo2" style="width: 15%; text-align: center;">
                                                        <img src="../../../assets/img/logo2.jpg" alt="" width="70px">

                                                    </div>
                                                </div>
                                                <div class="certificate-header" style="padding: 0;">
                                                    <div class="logo1" style="width: 10%; text-align: center;">
                                                    </div>
                                                    <div style="width: 80%;">
                                                        <p style="font-size: 12px; text-align: center;">(An ISO
                                                            9001-2015 and 14001-2015 certified Institute)
                                                            <br />(Approved by: AICTE, DTE Mumbal,
                                                            Govt. of
                                                            Maharashtra and Affiliated to MSBTE Mumbai)</p>
                                                        <p style="margin-top: 8px; font-size: 12px;"><b>Campus
                                                                :</b>
                                                            Survey
                                                            No. 13/2. Mahurzari, Near Fetri, Katol Road,
                                                            Nagpur-441
                                                            501<br /><b>Mobile :</b> 9545525450, 9545557700,
                                                            7972515445,
                                                            8007777170<br /> <b>Website :</b>
                                                            www.nitpoly.edu.in,&nbsp;&nbsp;<b>E-mail :</b>
                                                            principainitpoly@nitpoly.edu.in<br /><b>Corporate
                                                                Office
                                                                :</b>
                                                            2nd Floor, Midas Heights, Central Bazar Road,
                                                            Ramdaspeth,
                                                            Nagpur-10<br /><b>Ph.:</b> (0712) 2462268.</p>
                                                        <div
                                                            style="font-size: 1rem; margin-top: 10px; margin-bottom: 5px; color: #FD5845;">
                                                            <span style="float: left;">Principal: Prof. G, F.
                                                                Potbhare</span>
                                                            <span style="float: right;">Secretary: Salil
                                                                Deshmukh</span>
                                                            <div style="clear: both;"></div>
                                                        </div>
                                                    </div>
                                                    <div class="logo1" style="width: 10%; text-align: center;">
                                                    </div>
                                                </div>
                                                <hr style="margin-top: 0; margin-bottom: 0;" />
                                                <div style="padding: 0px 60px;">
                                                    <div style="padding: 40px 0">
                                                        <div class="passport-img"><img [src]="getImage()" alt=""></div>
                                                        <h2
                                                            style="text-decoration: underline; margin: 60px auto; text-align: center; font-size: 1.8em;">
                                                            Bonafide Certificate</h2>
                                                        <p style="line-height: 1.5; word-spacing: 3px; ">This is to
                                                            certify that Miss / Mr / Mrs <span
                                                                style="text-decoration: underline">{{usersData.name}}                                                                
                                                            </span> &nbsp; is/was a Bonafide student of this college studying
                                                            in <span style="text-decoration: underline">{{usersData.course_year}}</span> year, <span
                                                                style="text-decoration: underline">{{usersData.course_name}}</span>
                                                            Branch, <span
                                                                style="text-decoration: underline">{{usersData.course_category_name}}</span>
                                                            Course
                                                            during <span
                                                                style="text-decoration: underline">{{usersData.year}}</span>
                                                            academic year.</p>
                                                        <p
                                                            style="line-height: 1.5; word-spacing: 3px; margin-top: 15px;">
                                                            
                                                            According to college record his/her date of birth is
                                                            <span style="text-decoration: underline">{{usersData.dob |
                                                                date:'dd/MM/yyyy'}}</span>. In
                                                            word <span class="text-uppercase"
                                                                style="text-decoration: underline">{{dobInWords}}</span>
                                                            and he/she belongs to
                                                            <span style="text-decoration: underline">{{usersData.category_name}}</span>
                                                            category.
                                                        </p>
                                                        <p
                                                            style="line-height: 1.5; word-spacing: 3px; margin-top: 15px;">
                                                            This Bonafide certificate is issued for the purpose
                                                            of <span
                                                                style="text-decoration: underline">{{purpose}}</span>.
                                                        </p>

                                                        <p
                                                            style="line-height: 1.5; word-spacing: 3px; margin-top: 15px;">
                                                            Remarks :<span
                                                                style="text-decoration: underline">{{remarks}}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <br />
                                                <div style="padding: 40px 60px;">
                                                    <span style="float: left">Date:{{myDate |
                                                        date:'dd/MM/yyyy'}}</span>
                                                    <span style="float: right">Signature of Principal</span>
                                                </div>
                                                <div style="clear: both;"></div>
                                            </div>



                                            <div *ngIf="certificate" class="certificate-container" id="printtable1">
                                                <div class="certificate-header">
                                                    <div class="logo1" style="width: 15%; text-align: center;">
                                                        <img src="../../../assets/img/logo1.jpg" alt="" width="70px">
                                                    </div>
                                                    <div style="width: 70%; text-align: center;">
                                                        <p style="margin-bottom:0">Shri Sai Shikshan Sanstha's
                                                        </p>
                                                        <h2 style="color: #FD5845; font-size: 2.3em; margin-bottom:0">
                                                            NIT
                                                            Polytechnic</h2>
                                                            <h4
                                                            style="color: #FD5845; font-size: 15px; font-weight: normal; margin-bottom:0">
                                                            All Programmes are NBA Accredited</h4>
                                                    </div>
                                                    <div class="logo2" style="width: 15%; text-align: center;">
                                                        <img src="../../../assets/img/logo2.jpg" alt="" width="70px">

                                                    </div>
                                                </div>
                                                <div class="certificate-header" style="padding: 0;">
                                                    <div class="logo1" style="width: 10%; text-align: center;">
                                                    </div>
                                                    <div style="width: 80%;">
                                                        <p style="font-size: 12px; text-align: center;">(An ISO
                                                            9001-2015 and 14001-2015 certified Institute)
                                                            <br />(Approved by: AICTE, DTE Mumbal,
                                                            Govt. of
                                                            Maharashtra and Affiliated to MSBTE Mumbai)</p>
                                                        <p style="margin-top: 8px; font-size: 12px;"><b>Campus
                                                                :</b>
                                                            Survey
                                                            No. 13/2. Mahurzari, Near Fetri, Katol Road,
                                                            Nagpur-441
                                                            501<br /><b>Mobile :</b> 9545525450, 9545557700,
                                                            7972515445,
                                                            8007777170<br /> <b>Website :</b>
                                                            www.nitpoly.edu.in,&nbsp;&nbsp;<b>E-mail :</b>
                                                            principainitpoly@nitpoly.edu.in<br /><b>Corporate
                                                                Office
                                                                :</b>
                                                            2nd Floor, Midas Heights, Central Bazar Road,
                                                            Ramdaspeth,
                                                            Nagpur-10<br /><b>Ph.:</b> (0712) 2462268.</p>
                                                        <div
                                                            style="font-size: 1rem; margin-top: 10px; margin-bottom: 5px; color: #FD5845;">
                                                            <span style="float: left;">Principal: Prof. G, F.
                                                                Potbhare</span>
                                                            <span style="float: right;">Secretary: Salil
                                                                Deshmukh</span>
                                                            <div style="clear: both;"></div>
                                                        </div>
                                                    </div>
                                                    <div class="logo1" style="width: 10%; text-align: center;">
                                                    </div>
                                                </div>
                                                <hr style="margin-top: 0; margin-bottom: 0;" />
                                                <div style="padding: 0px 60px;">
                                                    <div style="padding: 40px 0">
                                                        <div class="passport-img"><img [src]="getImage()" alt=""></div>

                                                        <h2
                                                            style="text-decoration: underline; margin: 60px auto; text-align: center; font-size: 1.8em;">
                                                            Bonafide Certificate</h2>
                                                        <p style="line-height: 1.5; word-spacing: 3px; ">This is
                                                            to
                                                            certify
                                                            that Miss / Mr / Mrs. <span
                                                                style="text-decoration: underline">{{usersData.name}}
                                                            </span> is was bonafide student of this college
                                                            studying in <span style="text-decoration: underline">{{usersData.course_year}}</span> year, <span
                                                                style="text-decoration: underline">{{usersData.course_name}}</span>
                                                            Branch, <span
                                                                style="text-decoration: underline">{{usersData.course_category_name}}</span>
                                                            Course
                                                            during <span
                                                                style="text-decoration: underline">{{usersData.year}}</span>
                                                            academic year.</p>
                                                        <p
                                                            style="line-height: 1.5; word-spacing: 3px; margin-top: 15px;">
                                                            According to college record his/her date of birth is
                                                            <span style="text-decoration: underline">{{usersData.dob
                                                                |
                                                                date:'dd/MM/yyyy'}}</span>. In
                                                            word <span style="text-decoration: underline    ">THIRTEENTH
                                                                AUGUEST
                                                                TWO THOUSAND FIVE</span> and he/she belongs to
                                                            <span style="text-decoration: underline">{{usersData.category_name}}</span>
                                                            category.
                                                        </p>
                                                        <p
                                                            style="line-height: 1.5; word-spacing: 3px; margin-top: 15px;">
                                                            This bonafide certificate is issued for the purpose
                                                            of <span
                                                                style="text-decoration: underline">{{purpose}}</span>.
                                                        </p>
                                                        <p
                                                        style="line-height: 1.5; word-spacing: 3px; margin-top: 15px;">
                                                        Remarks :<span
                                                            style="text-decoration: underline">{{remarks}}</span>
                                                    </p>
                                                    </div>
                                                </div>
                                                <br />
                                                <div style="padding: 40px 60px;">
                                                    <span style="float: left">Date:{{myDate |
                                                        date:'dd/MM/yyyy'}}</span>
                                                    <span style="float: right">Signature of Principal</span>
                                                </div>

                                                <span style="float: right"> Duplicate Copy</span>
                                                <div style="clear: both;"></div>
                                            </div>
                                        </div>
                                        <div class="footer text-center pb-2" *ngIf="receipt">
                                            <button class="btn btn-save btn-sm"
                                                (click)="convetToPDF()">Download</button>
                                            &nbsp;
                                            <button class="btn btn-save btn-sm" (click)="print()">Print</button>
                                        </div>
                                        <div class="footer text-center pb-2" *ngIf="certificate">
                                            <button class="btn btn-save btn-sm"
                                                (click)="convetToPDF1()">Download</button>
                                            &nbsp;
                                            <button class="btn btn-save btn-sm" (click)="print1()">Print</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>