<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.DynamicLabeling}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <label for="sel1">{{labelName.Modules}}<span
                                                            class="text-danger">*</span></label>
                                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                        (change)="modulesChange($event)">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let item of modules" [value]='item.TaskId'>
                                                            {{item.TaskName}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-md-3">
                                                    <label for="sel1">{{labelName.WorkItems}}<span
                                                            class="text-danger">*</span></label>
                                                    <select name="workItem" id="workItem"
                                                        (change)="workItemsChange($event)"
                                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                                        <option value="" [selected]="true">Select</option>
                                                        <option *ngFor="let item of workItems" [value]="item.TaskId">
                                                            {{item.TaskName}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <br>

                                        <div class="float-container p-3">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="row" *ngFor="let item of table;let i=index;">
                                                        <!-- <div class="col-xl-3">
                                                            <div class="float-child" >
                                                                <div class="green mt-2">
                                                                    <label for="sel1">Label {{i+1}}</label>
                                                                </div>
                                                            </div>
                                                        </div> -->
                                                        <div class="col-xl-3">
                                                            <div class="float-child">
                                                                <div class="green mt-2">
                                                                    <label
                                                                        for="sel1">{{item.actual_label_value}}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- <div class="col-xl-3">
                                                            <div class="float-child" >
                                                                <div class="green mt-2">
                                                                    <label for="sel1">Change Label {{i+1}}</label>
                                                                </div>
                                                            </div>
                                                        </div> -->
                                                        <div class="col-xl-3">
                                                            <div class="float-child p-2">
                                                                <div class="blue">
                                                                    <input class="form-control form-control-sm"
                                                                        type="text" [(ngModel)]="item.label_value">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="text-center pt-4">
                                            <button id='update' type="button" class="btn btn-sm  btn-save"
                                                [disabled]="!isDisable" (click)="Update()">Update</button>
                                            &nbsp;
                                            <button id='back' type="button" class="btn btn-sm  btn-danger"
                                                (click)="back()">Back</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>