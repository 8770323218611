import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-vendor-payments',
  templateUrl: './vendor-payments.component.html',
  styleUrls: ['./vendor-payments.component.css']
})
export class VendorPaymentsComponent implements OnInit {
  myForm: FormGroup;
  isCheck: boolean = false;
  isChaque: boolean = false;
  itemsDropDown: Array<any> = [];
  qId: any;
  qNo: any;
  vId: any;
  balanceAmount: any;
  labelName: any = {};
  headingName: any = {};
  workItemId: any;

  constructor(private fb: FormBuilder, private CommonService: CommonService, private toastr: ToastrService, private active: ActivatedRoute, private route: Router,) {
    active.queryParams.subscribe((res: any) => {
      this.qId = res.qId;
      this.qNo = res.qNo;
      this.workItemId = res.wId
    })
    this.loadVendors();
    this.getLabels();
    this.loadHeadings();

  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      vendor_id: ['', Validators.required],
      paid_amount: ['', Validators.required],
      payment_date: ['',],
      payment_mode: ['', Validators.required],
      cheque_number: ['',],
      cheque_date: ['',],
    })
  }
  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  onCheck(event: any) {
    let check = event.target.checked;
    if (check == true)
      this.isCheck = true;
    else
      this.isCheck = false
  }
  cheque(event) {
    let cash = event.target.value;
    if (cash == 'Cash')
      this.isChaque = false;
    else
      this.isChaque = true
  }
  changeVendor(vId: any) {
    this.CommonService.getCall(`QuotationReceive/getbalanceamountbyvendor/${vId}`).subscribe((res: any) => {
      this.balanceAmount = res.balance_amount;
    })
  }


  loadVendors() {
    this.itemsDropDown = [];
    this.CommonService.getCall(`QuotationReceive/VendorsDropDownPayScr/${this.qId}`).subscribe((res: any) => {
      this.itemsDropDown = res;
    })
  }

  // paidAmount(pay:any){
  //   console.log(pay.target.value);    
  //   let payAmount =pay.target.value
  //   if(payAmount>this.balanceAmount){      
  //     this.toastr.warning('Please do not enter more than Balance amount')
  //   }
  // }


  onSubmit(form: FormGroup) {
    let value = form.value;
    let balAmount = this.balanceAmount - value.paid_amount;

    let payload = {
      "quotation_number": this.qNo,
      "quotation_receive_id": 23,
      "quotation_id": this.qId,
      "vendor_id": value.vendor_id,
      "balance_amount": balAmount,
      "paid_amount": value.paid_amount,
      "payment_mode": value.payment_mode,
      "payment_date": value.payment_date,
      "cheque_number": value.cheque_number,
      "cheque_date": value.cheque_date,
      "created_by": localStorage.getItem('UserId'),
      "modified_by": localStorage.getItem('UserId'),
      "tnt_code": localStorage.getItem('TenantCode'),
    }
    if (value.paid_amount > this.balanceAmount) {
      // this.toastr.warning('Please do not enter more than Balance amount');
      return this.toastr.warning('Please do not enter more than Balance amount');
    } else {
      this.CommonService.postCall('QuotationReceive/InsertPayAmountDetails', payload).subscribe((res: any) => {
        this.toastr.success("Quotation Items Recevied Successfully");
        this.route.navigate(['home/receivedQuotationsList']);
      }, err => {
        this.toastr.error(err.error ? err.error : 'Quotation Items Not Recevied');

      })
    }

  }

  close() {
    window.history.back();
  }

}
