<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.CollegeFeedbackList}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div *ngIf='isSuperAdmin' class="col-md-3">
                                                <label for="sel1">{{labelName.TenantName}} : </label>
                                                <select [(ngModel)]='tId '
                                                    class="form-control form-control-sm shadow-sm rounded-lg"
                                                    id="sel1">
                                                    <option value="" [selected]="true">select</option>
                                                    <option *ngFor="let item of tenanates" [value]='item.TNT_CODE'>
                                                        {{item.TNT_NAME}}</option>
                                                </select>

                                            </div>
                                            <div class="form-group col-md-3">
                                                <label> {{labelName.CourseYear}}</label>
                                                <div class="input-group">
                                                    <select (change)="changeCourseYear($event)"
                                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                                        <option value="" [selected]="true">Select
                                                        </option>
                                                        <option *ngFor="let item of courseYear"
                                                            [value]='item.DICTIONARYID'>
                                                            {{item.DICTIONARYNAME}}
                                                        </option>
                                                    </select>

                                                </div>
                                            </div>

                                            <div class="form-group col-md-3">
                                                <label> {{labelName.Semester}}</label>
                                                <div class="input-group">
                                                    <select (change)="changeSemester($event)"
                                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                                        <option value="" [selected]="true">Select
                                                        </option>
                                                        <option *ngFor="let item of semester"
                                                            [value]='item.DICTIONARYID'>
                                                            {{item.DICTIONARYNAME}}
                                                        </option>
                                                    </select>

                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="table.length" class="pt-4">
                                            <table id="patreggraph" datatable
                                                class="table table-striped table-bordered mt-2 pt-2 table-sm small display"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624fd7; color:#ffffff;">
                                                    <tr>
                                                        <th> Course Name </th>
                                                        <th> Course Year </th>
                                                        <th> Semester </th>
                                                        <th> Total List </th>
                                                        <th> Responded List </th>
                                                        <th> Course Wise Report </th>
                                                        <th> Answer Wise Report </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.course_name}}</td>
                                                        <td>{{item.course_year_name}}</td>
                                                        <td>{{item.semester}}</td>
                                                        <td>{{item.course_student_id}}</td>
                                                        <td>{{item.responded_list}}</td>
                                                        <td class="text-center">
                                                            <button class="btn btn-sm btn-ai" data-bs-toggle="modal"
                                                                data-bs-target="#myModal"
                                                                (click)="clickReport(item.course_id,item.course_name)">Report</button>

                                                        </td>
                                                        <td class="text-center">
                                                            <button class="btn btn-sm btn-ai"
                                                                (click)="clickView(item.course_id,item.semester_id,item.course_year)">View</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center pt-4 text_red" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog  modal-lg">
        <div class="modal-content">

            <div class="modal-header py-2">
                <h4 class="modal-title w-100"> {{headingName.CourseWiseReport}}</h4>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>


            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card radius-10 border-0 mb-4 overflow">
                            <div class="card-body">
                                <div>
                                    <h6 class="text-center">{{CourseName}}</h6>
                                </div>
                                <div class="my-3" id="chart7">
                                    <div>
                                        <div style="display: block">
                                            <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels"
                                                [options]="barChartOptions1" [legend]="barChartLegend"
                                                [chartType]="barChartType">
                                            </canvas>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div style="line-height: 2; font-weight: 500; font-size: 12px;" class="ps-0 mb-0 row">
                                    <div class="col-4  card-values"><span class="text_green"><i
                                                class="fas fa-circle"></i></span><span class="ps-2">Excellent
                                            (70)</span></div>
                                    <div class="col-4  card-values"><span class="text_yellow"><i
                                                class="fas fa-circle"></i></span><span class="ps-2"> Good (30)</span>
                                    </div>
                                    <div class="col-4  card-values"><span class="text_blue"><i
                                                class="fas fa-circle"></i></span><span class="ps-2">Average (70)</span>
                                    </div>
                                    <div class="col-4  card-values"><span class="text_orange"><i
                                                class="fas fa-circle"></i></span><span class="ps-2"> Poor (30)</span>
                                    </div>
                                    <div class="col-4  card-values"><span class="text_red"><i
                                                class="fas fa-circle"></i></span><span class="ps-2"> Very Poor
                                            (30)</span></div>
                                </div> -->

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-danger" id='md_close' (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>