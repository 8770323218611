<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.BusFeeReceipt}}</h5>
                                    </div>
                                    <div class="card-body p-2">
                                        <div class="form-group">
                                            <div class="row">
                                                <div *ngIf="roleId=='4' || roleId=='5'" class="col-md-3 p-4">
                                                    <label for="sel1">{{labelName.TenantName}} : </label>
                                                    <select [(ngModel)]='tId ' (change)="getCourses($any($event.target).value)"
                                                        class="form-control form-control-sm shadow-sm rounded-lg"
                                                        id="sel1">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let item of tenanates" [value]='item.TNT_CODE'>
                                                            {{item.TNT_NAME}}</option>
                                                    </select>
                          
                                                </div>
                                                <div class="col-md-3 p-4">
                                                    <label for="sel1">{{labelName.Course}}: </label>
                                                    <select (change)="courceChange($event)"
                                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let course of cources"
                                                            [value]='course.COURSE_ID'>
                                                            {{course.COURSE_NAME}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-md-3 p-4">
                                                    <label for="sel1">{{labelName.Students}}: </label>
                                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                        (change)="getUsersData($event)">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let item of students" [value]='item.userid'>
                                                            {{item.name}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="receipt" style="padding-bottom: 50px;">
                                            <table datatable id="DataTables_Table_0" [dtOptions]="dtOptions"
                                                [dtTrigger]="dtTrigger"
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th> Name </th>
                                                        <th>Terms</th>
                                                        <th>Academic Year</th>
                                                        <th> Amount</th>
                                                        <th>Action</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{{usersData.feetype_name}}</td>
                                                        <td>Term {{usersData.feereceivable_term_id}}</td>
                                                        <td>{{usersData.academic_name}}</td>
                                                        <td>{{usersData.feereceivable_paidamount}}</td>
                                                        <td>
                                                            <!-- <button class="btn btn-save btn-sm p">Download
                                                    </button>&nbsp;&nbsp; -->
                                                            <button class="btn btn-save btn-sm" (click)="view()">View
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <table id="printtable" width="800" *ngIf="printReceipt"
                                            style="border: 1px #dfdfdf solid; margin: auto;" cellspacing="10"
                                            cellpadding="10px" align="center">
                                            <tbody>
                                                <tr>
                                                    <td height="561">
                                                        <table width="800" border="0" cellspacing="0" cellpadding="0">
                                                            <tbody>
                                                                <tr *ngIf="false">
                                                                    <td width="100%"
                                                                        style="text-align: center; vertical-align: middle; padding-left: 20px;"
                                                                        valign="middle">
                                                                        <h3
                                                                            style="font-size: 1.2rem; margin-top: 10px; margin-bottom: 10px;">
                                                                            POOJA
                                                                            VAISHNAVI BUS SERVICES</h3>
                                                                    </td>

                                                                </tr>

                                                                <tr>
                                                                    <td height="430" colspan="2">
                                                                        <table width="100%" border="0" cellspacing="0"
                                                                            cellpadding="6" style=" font-size:13px;">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td colspan="4" align="center"
                                                                                        style="font-size:13px; ">
                                                                                        <b> BUS FEE RECEIPT</b>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td width="18%"><b>Date</b></td>
                                                                                    <td width="33%">:{{usersData.date}}
                                                                                    </td>
                                                                                    <td><b>Receipt No.</b></td>
                                                                                    <td>: {{usersData.receiptno}}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td><b>Name</b></td>
                                                                                    <td colspan="3">: {{usersData.name}}
                                                                                    </td>

                                                                                </tr>
                                                                                <tr>
                                                                                    <td><b>Branch</b></td>
                                                                                    <td>: {{usersData.course_name}}</td>
                                                                                    <td><b>Year</b></td>
                                                                                    <td>: {{usersData.year}}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        <table width="100%" cellspacing="0"
                                                                            cellpadding="3">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td style="padding:6px">
                                                                                        <table width="100%"
                                                                                            bordercolor="#CCCCCC"
                                                                                            cellspacing="0"
                                                                                            cellpadding="5"
                                                                                            style="font-size:13px;"
                                                                                            class="table table-bordered">

                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <th width="17%">
                                                                                                        <b>S.No.</b>
                                                                                                    </th>
                                                                                                    <th width="51%">
                                                                                                        <b>Particulars</b>
                                                                                                    </th>
                                                                                                    <th width="32%">
                                                                                                        <b>Amount</b>
                                                                                                    </th>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td
                                                                                                        style="text-align: center;">
                                                                                                        1</td>
                                                                                                    <td>{{usersData.feetype_name}}
                                                                                                    </td>
                                                                                                    <td>{{usersData.feereceivable_paidamount}}
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td colspan="2"
                                                                                                        style="text-align: center;">
                                                                                                        <b>Total
                                                                                                            Amount</b>
                                                                                                    </td>
                                                                                                    <td>{{usersData.feereceivable_paidamount}}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td><b>In Words: </b> {{this.words}}
                                                                                        RUPEES ONLY</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td><b>Mode of Payment: </b> CASH/DD
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <table width="100%">
                                                                                        <tr>
                                                                                            <td><b>D.D. No.: </b>
                                                                                                CASH/DD
                                                                                            </td>
                                                                                            <td><b>DD Amount: </b>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td colspan="2"><b>Bank
                                                                                                    Name:</b>{{usersData.bank_name}}
                                                                                            </td>
                                                                                        </tr>
                                                                                    </table>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td colspan="2" height="47"
                                                                                        style="padding-right: 20px; padding-bottom: 20px"
                                                                                        align="right" valign="bottom"
                                                                                        style="font-size:14px">
                                                                                        <b>Receiver</b>
                                                                                    </td>
                                                                                </tr>
                                                                                <!-- <tr>
                <td height="49" style="font-size:14px; padding:6px"><b> Note : Parents are requested to preserve this receipt for future clarifications in respect of fee paid by you.<br>
                Fee once paid will not be refunded or transferred.Cheques subject to realization.</b>
                </td>
            </tr> -->
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="footer text-center p-4 pb-2" *ngIf="printReceipt">
                                        <button class="btn btn-save btn-sm" (click)="convetToPDF()">Download</button>
                                        &nbsp;
                                        <button class="btn btn-save btn-sm" (click)="print()">Print</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>