<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">

                    <div class="container-fluid">

                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.StudentDailyDiaryList6}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <form class="well form-horizontal" [formGroup]="myForm"
                                            (ngSubmit)="onSubmit(myForm)">
                                            <fieldset>
                                                <div class="row">

                                                    <div class="form-group col-md-2">
                                                        <label> {{labelName.Course}}</label>
                                                        <div class="input-group">
                                                            <select formControlName="course_id" [(ngModel)]='courceId'
                                                            (change)="courceChange($any($event).target.value)"
                                                            class="form-control form-control-sm shadow-sm rounded-lg">
                                                            <option value="" selected>Select</option>
                                                            <option *ngFor="let course of cources"
                                                                [value]='course.COURSE_ID'>
                                                                {{course.COURSE_NAME}}
                                                            </option>
                                                        </select>

                                                        </div>
                                                    </div>


                                                    <div class="form-group col-md-2">

                                                        <label> {{labelName.CourseSchedule}}</label>
                                                        <div class="input-group">
                                                            <select formControlName="courseshd_id"
                                                                [(ngModel)]='courseshdId'
                                                                class="form-control form-control-sm shadow-sm rounded-lg">
                                                                <option value="" selected>Select</option>
                                                                <option *ngFor="let schedul of scheduls"
                                                                    [value]='schedul.courseshd_id'>
                                                                    {{schedul.courseshd_name}}</option>
                                                            </select>
                                                        </div>

                                                    </div>
                                                    <div class="form-group col-md-2">
                                                        <label> {{labelName.Status}}</label>

                                                        <div class="input-group">
                                                            <select formControlName="status"
                                                                placeholder="Select Attendance Type"
                                                                class="form-select form-select-sm shadow-sm rounded-lg">
                                                                <option value="" selected>Select 
                                                                </option>
                                                                <option value='Pending'>Pending</option>
                                                                <option value='Approved'>Approved</option>
                                                                <option value='Reject'>Reject</option>
                                                            </select>
                                                        </div>

                                                    </div>

                                                    <div class="form-group col-md-2">

                                                        <label> {{labelName.Month}}</label>
                                                        <div class="input-group">
                                                            <input type="date" formControlName="date"placeholder="date"
                                                             class="form-control form-control-sm" />
                                                        </div>
                                                        
                                                    </div>
                                                    <div class="col-md-2 pt-4">
                                                        <button type="button" [disabled]="myForm.invalid"
                                                            (click)="onSubmit(myForm)" class="btn btn-sm  btn-save"><i
                                                                class="fas fa-search"></i></button>
                                                    </div>

                                                </div>
                                            </fieldset>
                                        </form>
                                        <!-- <div>
                                            <button type="button" [disabled]="myForm.invalid" (click)="onSubmit(myForm)"
                                                class="btn btn-sm  btn-save"><i class="fas fa-search"></i></button>
                                        </div> -->

                                        <div class="text-right ">
                                            <button type="button" class="btn btn-primary btn-sm" (click)="add()">Add Daily
                                                Diary</button>
                                        </div>
                                        <div *ngIf="isTable">
                                            <div *ngIf="table.length">
                                                <table id="patreggraph" datatable
                                                    class="table table-striped table-bordered mt-2 pt-2 table-sm small display"
                                                    style="width:100%">
                                                    <thead class="top" style="background-color: #624fd7; color:#ffffff;">
                                                        <tr>
                                                            <!-- <th> Campus Name </th> -->
                                                            <th> Course </th>
                                                            <th> Course Schedule </th>
                                                            <th> Student Name </th>
                                                            <th> USID </th>
                                                            <!-- <th> Admission No </th> -->
                                                            <th> Date </th>
                                                            <th> Status </th>
                                                            <th> Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let item of table">
                                                            <!-- <td>{{item.campus_name}}</td> -->
                                                            <td>{{item.course_name}}</td>
                                                            <td>{{item.course_schedule_name}}</td>
                                                            <td>{{item.student_name}}</td>
                                                            <td>{{item.student_rollno}}</td>
                                                            <!-- <td>{{item.student_rollno}}</td> -->
                                                            <td>{{item.date | date:'dd-MM-yyyy'}}</td>
                                                            <td>{{item.daily_diary_status}}</td>
                                                            <td style="display: flex; text-align: center; cursor: pointer;">
                                                                <a
                                                                    (click)="edit(item.student_id,item.date,item.section_id)"><i
                                                                        class="fas fa-eye"></i></a>&nbsp;
                                                                <button
                                                                    *ngIf="item.daily_diary_status == 'Pending' || item.daily_diary_status == 'Reject'"
                                                                    class=" btn btn-warning"
                                                                    (click)="changeApprove(item.student_id,'Approved',item.date,item.section_id)">Approve</button>
                                                                <button *ngIf="item.daily_diary_status == 'Approved'"
                                                                    class=" btn btn-danger"
                                                                    (click)="changeApprove(item.student_id,'Reject',item.date,item.section_id)">Reject</button>
                                                            </td>
    
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="text-center" *ngIf="!table.length">
                                                No record to display
                                            </div>
                                        </div>
                                        

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>