import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PolsService } from 'src/app/services/pols.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-surveys',
  templateUrl: './surveys.component.html',
  styleUrls: ['./surveys.component.css']
})
export class SurveysComponent implements OnInit {
   surveys:[]=[]
   headingName:any= {};
  workItemId: any;

  constructor(private pollService:PolsService,private active : ActivatedRoute,private route:Router,private CommonService: CommonService) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.getSurveys();
    this.loadHeadings();
   }

  ngOnInit(): void {
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
   
  getSurveys(){
    this.CommonService.activateSpinner();
    this.pollService.getSurveys().subscribe((res:any)=>{
      this.CommonService.deactivateSpinner();
      this.surveys=res;
      
    },(err)=>{this.CommonService.deactivateSpinner();})
  }

  takeSurvey(id){
    let params={id:id,wId:this.workItemId}
    this.route.navigate(['home/takesurvey'],{queryParams:params})
  }

}
