import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { isThisSecond } from 'date-fns';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-categories-list',
  templateUrl: './categories-list.component.html',
  styleUrls: ['./categories-list.component.css']
})
export class CategoriesListComponent extends BaseComponent  implements OnInit {
  table: Array<any> = [];
  myForm: FormGroup;
  isEdit: boolean = false;
  editData = {};
  labelName: any = {};
  headingName: any = {};
  workItemId:any
  constructor(private fb: FormBuilder, private active:ActivatedRoute, CommonService: CommonService,  toastr: ToastrService) {
    super(CommonService, toastr);
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
    this.loadCategories();
    this.getLabels();
    this.loadHeadings();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      event_category_name: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      event_category_description: [''],
    });
  }

  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }
  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  loadCategories() {
    this.CommonService.postCall(`EmsEventsCategories/GetList`, { "tnt_code":this.tId || localStorage.getItem('TenantCode') }).subscribe((res: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = res;
      }, 10);
    })
  }


  onSubmit(form: FormGroup) {
    let value = form.value;
    value['tnt_code'] = localStorage.getItem('TenantCode');
    value['created_by'] = localStorage.getItem('UserId');
    value['modified_by'] = localStorage.getItem('UserId');
    value['academic_year_id'] = 0;
    console.log(value)
    if (this.isEdit) {
      value['modified_by'] = localStorage.getItem('UserId');
      value['event_category_id'] = this.editData['event_category_id'];
      this.CommonService.postCall('EmsEventsCategories/Update', value).subscribe((res: any) => {
        this.loadCategories();
        this.toastr.success("Categories Updated Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Categories Not Updated')
      })
    } else {
      if (this.table.find((e: any) => e.event_category_name == value.event_category_name)) {
        this.toastr.warning('Category Name Already Exists');
        return;
      }
      this.CommonService.postCall('EmsEventsCategories/Create', value).subscribe((res: any) => {
        this.loadCategories();
        this.toastr.success("Categories Created Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Categories Not created')

      })
    }
  }


  edit(bmId: any) {
    this.isEdit = true;
    this.CommonService.postCall('EmsEventsCategories/EventsCategoriesGetById', { "event_category_id": bmId }).subscribe((res: any) => {
      this.editData = res;
      setTimeout(() => {
        this.dataTransfer(res);
      }, 10);
    })
  }
  dataTransfer(data) {
    this.myForm.patchValue({
      event_category_name: data['event_category_name'],
      event_category_description: data['event_category_description'],
    })
  }

  actives(cId: any, status: any) {

    if (confirm(`Are you sure you want to ${status}`)) {
      this.CommonService.postCall(`EmsEventsCategories/Delete`, { "event_category_id": cId, "event_category_status": status }).subscribe((res: any) => {
        this.loadCategories();
        this.toastr.success('Category Inactived')
      })
    }

  }

  close() {
    this.isEdit = null;
    this.editData = {};
    this.myForm.reset();
  }

}
