import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { combineLatest } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';
import { FileuploadService } from 'src/app/services/fileupload.service';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-add-quotation',
  templateUrl: './add-quotation.component.html',
  styleUrls: ['./add-quotation.component.css']
})
export class AddQuotationComponent implements OnInit {
  myForm: FormGroup;
  isEdit: boolean = false;
  editData: any = {};
  total_cost: any;
  items: Array<any> = [];
  addItem: any = {
    item: 0,
    quantity: 0,
    unitCost: 0,
    estimatedTotal: 0
  };
  labelName: any = {};
  headingName: any = {};
  workItemId: any;

  constructor(private ref: ChangeDetectorRef, private fb: FormBuilder, private route: Router, private CommonService: CommonService, private toastr: ToastrService, private active: ActivatedRoute, private FileuploadService: FileuploadService) {
    
    this.active.queryParams.subscribe((res:any)=>{
      this.workItemId = res.wId;
    })
    this.loadItems();
    this.getLabels();
    this.loadHeadings();

  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      request_date: ['', Validators.required],
      quoted_total: ['',],
      list: this.fb.array([]),
    });
    this.add();
  }

  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  getOptionGroup(data) {
    let newGroup = this.fb.group({
      item_id: [data.item_id ? data.item_id : Number, Validators.required],
      quantity: [data.quantity ? data.quantity : Number, Validators.required],
      estimated_cost: [data.estimated_cost ? data.estimated_cost : Number, Validators.required],
      estimated_total: [data.estimated_total ? data.estimated_total : Number, Validators.required],
      // itemRows : this.fb.array([this.initItemRows()]),
      // total_cost: [0,Validators.required]
    });
    const quantityCtrl = newGroup.get('quantity');
    const unitCostCtrl = newGroup.get('estimated_cost');
    const estimatedTotalCtrl = newGroup.get('estimated_total');
    const TotalCtrl = this.myForm.get('total_cost');
    combineLatest(quantityCtrl.valueChanges.pipe(startWith(quantityCtrl.value)),
      unitCostCtrl.valueChanges.pipe(startWith(unitCostCtrl.value))).subscribe(([quantity, estimated_cost]) =>
        estimatedTotalCtrl.setValue(quantity * estimated_cost))

    if (this.myForm.get('list')) {
      this.myForm.get('list').valueChanges.subscribe(values => {
        this.total_cost = 0;
        const ctrl = <FormArray>this.myForm.controls['list']
        ctrl.controls.forEach(x => {
          // let parsed = parseInt(x.get('estimatedTotal').value)
          this.total_cost += x.get('estimated_total').value;
          this.ref.detectChanges()
        })
      })
    }
    // this.myForm.value.formArray.forEach(e => {
    //   if (e.estimatedTotal != undefined) {
    //     sum = sum + e.estimatedTotal
    //   }
    // });

    // TotalCtrl.setValue(sum)
    // this.myForm.patchValue({
    //   total_cost: sum
    // })

    return newGroup

  }

  initItemRows() {
    return this.fb.group({})
  }

  add(data = {}) {
    const arrayControl = <FormArray>this.myForm.controls['list'];
    let grp: FormGroup = this.getOptionGroup(data)
    arrayControl.push(grp)
  }

  delete() {
    const arrayControl = <FormArray>this.myForm.controls['list'];
    let index = arrayControl.length;
    arrayControl.removeAt(index - 1)
    // this.isEdit=true
  }


  isVisable() {
    let arrayControl = <FormArray>this.myForm.controls['list'];
    if (arrayControl.length && arrayControl.length > 0) {
      return true
    }
    else {
      return false
    }
  }


  isAdd() {

    let arrayControl = <FormArray>this.myForm.controls['list'];
    if (arrayControl.length && arrayControl.length > 25) {
      return false
    }
    else {
      return true
    }

  }


  ItemSum(sum: any) {
    console.log(sum, 'hai');
  }


  loadItems() {
    this.items = [];
    this.CommonService.postCall('InvetoryItems/GetList', { "tnt_code": localStorage.getItem('TenantCode') }).subscribe((res: any) => {
      this.items = res;
    })
  }

  onSubmit(myForm: any) {
    let value: any = myForm.value;
    value['tnt_code'] = localStorage.getItem('TenantCode');
    value['created_by'] = localStorage.getItem('UserId');
    console.log(myForm.value);
    this.CommonService.postCall('Quotation/Create', value).subscribe((res: any) => {
      this.toastr.success("Quotation Created Successfully");
      this.route.navigate(['home/quotation']);
      document.getElementById('md_close').click();
    }, err => {
      this.toastr.error(err.error ? err.error : 'Quotation Not created')

    })

  }

  back() {
    this.route.navigate([`home/quotation/${this.workItemId}`]);
  }






}
