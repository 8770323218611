import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { startWith } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-class-item',
  templateUrl: './class-item.component.html',
  styleUrls: ['./class-item.component.css']
})
export class ClassItemComponent implements OnInit {
  myForm: FormGroup;
  campus: Array<any> = [];
  Courses: Array<any> = [];
  table: Array<any> = [];
  isEdit: boolean = false;
  items: Array<any> = [];
  updateList: Array<any> = [];
  ucost: any;
  total_cost: any;
  final_amount: any;
  total_discount: any;
  updateAmount: any = 0;
  updateDiscount: any = 0;
  updateFinal: any = 0;
  editData: any;
  labelName: any = {};

  addItem: any = {
    item: 0,
    price: 0,
    quantity: 0,
    amount: 0
  };
  price: any;
  headingName: any = {};
  workItemId: any;
  constructor(private active: ActivatedRoute,private ref: ChangeDetectorRef, private toastr: ToastrService, private fb: FormBuilder, private CommonService: CommonService) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.campusDropdown();
    this.loadcourses()
    this.loadItems();
    this.getLabels();
    this.loadHeadings();

    this.loadClass();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      campus_id: ['', Validators.required],
      course_id: ['', Validators.required],
      amounts: ['', Validators.required],
      total_discounts: [''],
      final_amounts: ['', Validators.required],
      list: this.fb.array([]),
    })
    this.add();
  }

  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }
  loadClass() {
    let payload = {
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('EmsClassItems/GetList', payload).subscribe((res: any) => {
      this.table = res;
    })
  }

  campusDropdown() {

    let payload = {
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('EmsCampus/CampusDropDownList', payload).subscribe((res: any) => {
      this.campus = res;
    })
  }


  loadcourses() {
    // LoadCourseSchedule
    // Loadyear Loadcourses
    this.activeSpinner()
    let payaload = {
      "TENANT_CODE": localStorage.getItem('TenantCode')
    }
    this.CommonService.getAdminCourses().subscribe((res: any) => {
      this.Courses = res;
      this.deactivateSpinner();
    }, e => this.deactivateSpinner());
  }

  getOptionGroup(data) {
    let newGroup = this.fb.group({
      item_id: [data.item_id ? data.item_id : Number, Validators.required],
      price: [data.price ? data.price : Number, Validators.required],
      quantity: [data.quantity ? data.quantity : Number, Validators.required],
      amount: [data.amount ? data.amount : Number, Validators.required],
    });

    const priceCtrl = newGroup.get('price');
    const quantityCtrl = newGroup.get('quantity');
    const amountCtrl = newGroup.get('amount');
    const TotalCtrl = this.myForm.get('total_cost');
    const finalCtrl = this.myForm.get('final_amount');
    const discountCtrl = this.myForm.get('total_discount');
    combineLatest(priceCtrl.valueChanges.pipe(startWith(priceCtrl.value)),
      quantityCtrl.valueChanges.pipe(startWith(quantityCtrl.value))).subscribe(([price, quantity]) =>
        amountCtrl.setValue(price * quantity))

    if (this.myForm.get('list')) {
      this.myForm.get('list').valueChanges.subscribe(values => {
        this.total_cost = 0;
        this.final_amount = 0;
        // this.total_discount = 0;
        this.myForm.value.total_discount;
        const ctrl = <FormArray>this.myForm.controls['list']
        ctrl.controls.forEach(x => {
          if (this.total_discount == 0) {
            this.total_cost += x.get('amount').value;
            this.final_amount = x.get('amount').value;
          }
          else {
            this.total_cost += x.get('amount').value;
            this.final_amount += x.get('amount').value;

          }


          this.ref.detectChanges()

        })
      })
    }

    return newGroup
  }

  discountCalculate(discount: any) {
    return this.total_cost * discount / 100;
  }

  discountChange(discountper: any) {
    this.final_amount = this.total_cost - this.discountCalculate(discountper)
  }


  add(data = {}) {

    const arrayControl = <FormArray>this.myForm.controls['list'];
    let grp: FormGroup = this.getOptionGroup(data)
    arrayControl.push(grp)
  }
  delete() {
    const arrayControl = <FormArray>this.myForm.controls['list'];
    let index = arrayControl.length;
    arrayControl.removeAt(index - 1)

  }


  isVisable() {
    let arrayControl = <FormArray>this.myForm.controls['list'];
    if (arrayControl.length && arrayControl.length > 0) {
      return true
    }
    else {
      return false
    }
  }
  isAdd() {

    let arrayControl = <FormArray>this.myForm.controls['list'];
    if (arrayControl.length && arrayControl.length > 25) {
      return false
    }
    else {
      return true
    }

  }

  loadItems() {
    this.items = [];
    this.CommonService.postCall('InvetoryItems/InventoryItemsCampus', {}).subscribe((res: any) => {
      this.items = res;
    })
  }
  campusName(event) {
    if (event.target.value == this.updateList.find(x => x.item_id).item_id) {
      this.toastr.show("Already Exists");
    }
  }

  priceAmount(event, item) {
    let ovj = this.items.find(x => x.item_id == event.target.value);
    item.price = ovj.unit_price;
    // this.myForm.controls['list'].forEach(e => {
    //   this.price = e.unit_price
    //  });

  }

  onSubmit(myForm: FormGroup) {
    let value: any = myForm.value;
    let list = [];
    myForm.value.list.forEach(e => {
      let item: any = '';
      Object.keys(e).map((key, i) => {
        let value = e[key];
        if (i > 0) {
          if (value) {
            item = item ? item + ',' + i : i;
          }
        }
      })
      let obj = {
        "item_id": e.item_id,
        "price": e.price,
        "quantity": e.quantity,
        "amount": e.amount
        // "floor_name": e.floor_name,
        // "amenities": item
      }
      list.push(obj);
    });
    let payload = {

      "campus_id": value.campus_id,
      "class_id": value.course_id,
      "total_amount": value.amounts,

      "total_discount": value.total_discounts,
      "final_amount": value.final_amounts,
      "created_by": localStorage.getItem('UserId'),
      "modified_by": localStorage.getItem('UserId'),
      "tnt_code": localStorage.getItem('TenantCode'),
      "ClassItemsDetails": list

    }

    if (this.isEdit) {
      value.modified_by = localStorage.getItem('UserId');
      // value['room_type_id'] = this.editData.room_type_id;
      payload['id'] = this.editData.id;
      this.CommonService.postCall('EmsClassItems/Update', payload).subscribe((res: any) => {
        this.loadClass();
        this.toastr.success("Updated Successfully");
        document.getElementById('md_close').click();
        this.updateAmount = 0;
        this.updateDiscount = 0;
        this.updateFinal = 0;

      }, err => {
        this.toastr.error(err.error ? err.error : 'Not Updated')
      })
    } else {
      this.CommonService.postCall('EmsClassItems/Create', payload).subscribe((res: any) => {
        this.loadClass();
        this.toastr.success("ClassItems Created Succuessfully");
        document.getElementById('md_close').click();
        this.updateAmount = 0;
        this.updateDiscount = 0;
        this.updateFinal = 0;

      }, err => {
        this.toastr.error(err.error ? err.error : 'ClassItems Not created')

      })

    }
  }
  edit(cid) {
    this.editData = cid;
    // this.editData = clid;
    this.isEdit = true;
    this.myForm.reset();
    let payLoad = {
      "id": cid,
      // "class_id": clid
    }
    this.CommonService.postCall('EmsClassItems/Get', payLoad).subscribe((res: any) => {
      this.updateList = res.ClassItemsDetails;
      this.updateAmount = res.total_amount;
      this.total_cost;
      this.updateDiscount = res.total_discount;
      this.updateFinal = res.final_amount;


      if (res instanceof Array && res.length) {
        this.editData = res[0];
        this.dataTransfer(res[0]);
      } else {
        this.editData = res;
        this.dataTransfer(res)
      }
      // this.campusTable(this.editData.campus_id)


    })
  }

  dataTransfer(data) {
    let ctrls = this.myForm.controls;
    // Object.keys(ctrls).map(key => {
    //   let ctrl = ctrls[key];
    //   ctrl?.setValue(data[key])
    // })
    ctrls['campus_id'].setValue(data['campus_id']);
    ctrls['course_id'].setValue(data['class_id']);
  }

  close() {
    this.isEdit = false;
    this.myForm.reset();
    this.editData = {};
    this.updateList = null;

  }
}
