import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-driver-health',
  templateUrl: './driver-health.component.html',
  styleUrls: ['./driver-health.component.css']
})
export class DriverHealthComponent implements OnInit {
  myForm: FormGroup;
  editData: any;
  labelName: any = {};
  headingName: any = {};
  workItemId: any;

  constructor(private fb: FormBuilder,private active : ActivatedRoute, private CommonService: CommonService, private toastr: ToastrService) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.getDriverHealth();
    this.getLabels();
    this.loadHeadings();

  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      max_alcohol_level: ['', Validators.required],
      max_temparature_level: ['', Validators.required],

    });
  }
  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/4280/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/4280/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  getDriverHealth() {
    this.CommonService.postCall('Transport/GetDrivelHealthConfiguration', {}).subscribe((res: any) => {
      if (res instanceof Array && res.length) {
        this.editData = res[0];
        this.dataTransForm()
      } else {
        this.editData = res;
        this.dataTransForm()
      }
      this.dataTransForm();
    }), err => { }

  }

  dataTransForm() {
    // let ctrls = this.myForm.controls

    this.myForm.controls['max_alcohol_level'].setValue(this.editData['max_alcohol_level']);
    this.myForm.controls['max_temparature_level'].setValue(this.editData['max_temparature_level']);
  }

  onSubmit(form: any) {
    let value = form.value
    let payload = {
      "max_alcohol_level": value.max_alcohol_level,
      "max_temparature_level": value.max_temparature_level,
      "created_by": localStorage.getItem('UserId'),
      "modified_by": localStorage.getItem('UserId'),
      "tnt_code": localStorage.getItem('TenantCode'),
    }
    if (this.editData == null) {
      payload['driver_health_checkup_configuration_id'] = 0;
      this.CommonService.postCall('Transport/UpdateDriverHealth', payload).subscribe((res: any) => {
        this.toastr.success("Driver Health Saved Successfully");
        // location.reload();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Driver Health Not Saved')
      })
    } else {
      payload['driver_health_checkup_configuration_id'] = this.editData.driver_health_checkup_configuration_id;
      this.CommonService.postCall('Transport/UpdateDriverHealth', payload).subscribe((res: any) => {
        this.toastr.success("Driver Health Updated Successfully");
        // location.reload();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Driver Health Not Updated')
      })
    }
  }

  close() {
    location.reload();
  }

}
