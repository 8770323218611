import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { threadId } from 'worker_threads';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.css']
})
export class EmployeesComponent implements OnInit {
  myForm: FormGroup;
  table: Array<any> = [];
  headingName: any = {};
  workItemId: any;
  constructor(private commonService: CommonService,private active : ActivatedRoute) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadEmployee();
    this.loadHeadings();
  }

  ngOnInit(): void {
  }


  loadHeadings() {
    this.headingName = {};
    this.commonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  loadEmployee() {
    let payload = {

    }
    this.commonService.getCall(`EmsReports/EmployeeSales/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.table = res;
    })
  }
  onSubmit(myForm) {

  }
}
