import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { event } from 'jquery';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-staff-convenyance',
  templateUrl: './staff-convenyance.component.html',
  styleUrls: ['./staff-convenyance.component.css']
})
export class StaffConvenyanceComponent implements OnInit {

  table: Array<any> = [];
  myForm: FormGroup;
  isEdit: boolean = false;
  staff: Array<any> = [];
  type: Array<any> = [];
  transportType: string = 'both';
  editData: any = {};
  pickupRoutes: Array<any> = [];
  pickupStop: Array<any> = [];
  pickupBus: Array<any> = [];
  dropRoutes: Array<any> = [];
  dropStop: Array<any> = [];
  dropBus: Array<any> = [];
  trainer: Array<any> = [];
  staffConveyanceId: any;
  labelName: any = {};
  headingName: any = {};
  workItemId: any;



  constructor(private fb: FormBuilder,private active : ActivatedRoute, private CommonService: CommonService, private toastr: ToastrService) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadStaff();
    this.loadPickUpRoutes();
    this.loadDropRoutes();
    this.getTrainer();
    this.getLabels();
    this.loadHeadings();

  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      staff_id: ['', Validators.required],
      conveyance_type: ['', Validators.required],
      pick_route_id: [0],
      pick_route_stop_id: [0],
      pick_bus_no: [0],
      drop_route_id: [0],
      drop_route_stop_id: [0],
      drop_bus_no: [0]
    });
  }
  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  loadStaff() {
    this.CommonService.getCall(`Transport/GetStaffConveyanceList/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = res;
      }, 10);
    })
  }

  getTrainer() {
    this.trainer = [];
    // let payLoad = {
    //   "TenantCode": localStorage.getItem('TenantCode'),
    //   "objUserinrole": { "RoleId": localStorage.getItem('RoleId') || 0 }
    // }
    console.log(this.editData['staff_conveyance_id'] || 0);

    this.CommonService.getCall(`Transport/GetApprovedHostelStaff/${localStorage.getItem('TenantCode')}/${this.staffConveyanceId || 0}`).subscribe((res: any) => {
      this.trainer = res;
      console.log(this.trainer)
    })
  }

  loadPickUpRoutes() {
    this.CommonService.getCall(`Transport/RouteDropDownList/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.pickupRoutes = res;
    })
  }

  loadPickUpStops(rId: any) {

    this.CommonService.getCall(`Transport/RouteStopDropDownList/${rId}`).subscribe((res: any) => {
      this.pickupStop = res;
    })
  }


  loadPickUpBus(rId: any) {

    this.CommonService.getCall(`Transport/RouteBusesDropDownList/${rId}`).subscribe((res: any) => {
      this.pickupBus = res;
    })
  }

  loadDropRoutes() {
    this.CommonService.getCall(`Transport/RouteDropDownList/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.dropRoutes = res;
    })
  }

  loadDropStops(rId: any) {

    this.CommonService.getCall(`Transport/RouteStopDropDownList/${rId}`).subscribe((res: any) => {
      this.dropStop = res;
    })
  }


  loadDropBus(rId: any) {

    this.CommonService.getCall(`Transport/RouteBusesDropDownList/${rId}`).subscribe((res: any) => {
      this.dropBus = res;
    })
  }



  onSubmit(form: FormGroup) {
    let value = form.value;
    console.log(value)
    let payload = {
      // "campus_id":value.campus_id,     
      "staff_id": value.staff_id,
      "pick_route_id": value.pick_route_id || 0,
      "pick_route_stop_id": value.pick_route_stop_id || 0,
      "pick_bus_no": value.pick_bus_no || 0,
      "drop_route_id": value.drop_route_id || 0,
      "drop_route_stop_id": value.drop_route_stop_id || 0,
      "drop_bus_no": value.drop_bus_no || 0,
      "conveyance_type": value.conveyance_type,
      "created_by": localStorage.getItem('UserId'),
      "modified_by": localStorage.getItem('UserId'),
      "tnt_code": localStorage.getItem('TenantCode')
    }
    console.log(payload);

    if (this.isEdit) {
      payload['modified_by'] = localStorage.getItem('UserId');
      payload['staff_conveyance_id'] = this.editData.staff_conveyance_id;
      this.CommonService.postCall('Transport/UpdateStaffConveyance', payload).subscribe((res: any) => {
        this.loadStaff();
        this.toastr.success("Staff Conveyance Updated Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Staff Conveyance Not Updated')
      })
    } else {
      this.CommonService.postCall('Transport/CreateStaffConveyance', payload).subscribe((res: any) => {
        this.loadStaff();
        this.toastr.success("Staff Conveyance Created Successfully");
        document.getElementById('md_close').click();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Staff Conveyance Not created')

      })
    }
  }

  edit(scId: any) {
    this.staffConveyanceId = scId;
    this.getTrainer();
    console.log(scId);
    this.isEdit = true;
    this.CommonService.getCall(`Transport/GetStaffConveyance/${scId}`).subscribe((res: any) => {
      this.editData = res;
      console.log(res);
      this.transportType = res.conveyance_type;
      this.loadPickUpStops(this.editData['pick_route_id']),
        this.loadPickUpBus(this.editData['pick_route_id']),
        this.loadDropStops(this.editData['drop_route_id']),
        this.loadDropBus(this.editData['drop_route_id']),
        setTimeout(() => {
          this.dataTransfer(res);
        }, 100);

    })

  }
  dataTransfer(data) {
    this.myForm.patchValue({
      staff_id: data['staff_id'],
      conveyance_type: data['conveyance_type'],
      pick_route_id: data['pick_route_id'],
      pick_route_stop_id: data['pick_route_stop_id'],
      pick_bus_no: data['pick_bus_no'],
      drop_route_id: data['drop_route_id'],
      drop_route_stop_id: data['drop_route_stop_id'],
      drop_bus_no: data['drop_bus_no'],
    })

  }

  inactive(scId: any, status: any) {

    let sts;
    let stst;
    if (status == "Active") {
      sts = "false";
      stst = "Active";
    } else if (status == "Inactive") {
      sts = "true";
      stst = "Inactive";
    }
    let payload = {
      "staff_conveyance_id": scId,
      "status": sts
    }
    if (confirm(`Are you sure you want to ${stst} Status`)) {
      this.CommonService.getCall(`Transport/DeleteStaffConveyance/${scId}/${sts}`).subscribe((res: any) => {
        this.loadStaff();
        if (sts == "true") {
          this.toastr.success("Staff Conveyance Activated Successfully");
        } else if (sts == "false") {
          this.toastr.success("Staff Conveyance Inactivated Successfully");
        }
      })
    }


  }


  close() {
    this.isEdit = null;
    this.editData = {};
    this.myForm.reset();
    this.transportType = 'both';
  }
}
