<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.EventParticipants}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="custom_container">

                                            <div class="form-group row">
                                                <label class="col-md-4 control-label ">{{labelName.EventName}}
                                                    <span class="text-danger">*</span></label>
                                                <div class="col-md-8 inputGroupContainer">
                                                    <div class="input-group"> <strong>:</strong> &nbsp;
                                                        <select class="form-select form-select-sm shadow-sm rounded-lg"
                                                            (change)="getParticipants($event)">
                                                            <option value="" selected>Select</option>
                                                            <option *ngFor="let item of eventDropdownlist"
                                                                [value]="item.event_id">
                                                                {{item.event_name}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="card-body pt-4">
                                                <div *ngIf="table.length">
                                                    <table id="patreggraph" datatable
                                                        class="table table-striped table-bordered mt-2 pt-2 table-sm small display"
                                                        style="width:100%">
                                                        <thead class="top"
                                                            style="background-color: #624fd7; color:#ffffff;">
                                                            <tr>
                                                                <th>S.No</th>
                                                                <th>Student Name</th>
                                                                <th>Registration No</th>
                                                                <th>Attendance</th>
                                                                <th>Awards</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let item of table;let i = index">
                                                                <td>{{i+1}}</td>
                                                                <td>{{item.name}}</td>
                                                                <td>{{item.registration_no}}</td>
                                                                <td>
                                                                    <div class="form-check">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="defaultCheck1_{{i}}"
                                                                            id="defaultCheck1_{{i}}"
                                                                            [(ngModel)]="item.isPresent">
                                                                        <label class="form-check-label"
                                                                            for="defaultCheck">
                                                                            If Present
                                                                        </label>
                                                                    </div>
                                                                    <div class="input-group" *ngIf="item.isPresent">
                                                                        <input type="date" placeholder=""
                                                                            class="form-control" [min]="startDate"
                                                                            [max]="endDate" id="myDate"
                                                                            [ngModel]="item.participation_timestamp | date:'yyyy-MM-dd'"
                                                                            (ngModelChange)="item.participation_timestamp = $event" />

                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="form-check" *ngIf="item.isPresent">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="defaultCheck2_{{i}}"
                                                                            id="defaultCheck2_{{i}}"
                                                                            [(ngModel)]="item.isRank">
                                                                        <label class="form-check-label"
                                                                            for="defaultCheck">
                                                                            If Awarded
                                                                        </label>
                                                                    </div>
                                                                    <div class="input-group"
                                                                        *ngIf="item.isRank && item.isPresent">
                                                                        <input type="tel" id="num" placeholder=""
                                                                            class="form-control" minlength="1"
                                                                            maxlength="2" [(ngModel)]="item.rank"
                                                                            value="num"
                                                                            (input)="validateRank($event.target)" />
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="text-center" *ngIf="!table.length">
                                                    No Records to display
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" [disabled]="!isDesable || table.length == 0"
                                                    class="btn btn-sm  btn-save" (click)="onSubmit()">Save</button>
                                                <button type="button" class="btn btn-sm btn-danger" (click)="close()"
                                                    data-bs-dismiss="modal">Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>