import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { CommonService } from './../services/common.service'
export abstract class BaseComponent {
    table: Array<any> = [];
    isEdit: boolean;
    tenanates: Array<any> = [];
    roleId: string = localStorage.getItem('RoleId');
    TenantCode: string = localStorage.getItem('TenantCode');
    userId: string = localStorage.getItem('UserId');
    myForm: FormGroup;
    editData: any = {};
    tId: string = '';
    file: File;
    fileName: string;
    courses: Array<any> = [];
    courseId: string = '';
    shedules: Array<any> = [];
    schedules: Array<any> = [];
    scheduleId:string='';
    public dtTrigger: Subject<any> = new Subject<any>();
    dtOptions: any = {};
    formBuilder: FormBuilder=new FormBuilder();
    loadTenanat:boolean=true;
 //   fb:FormBuilder=new FormBuilder();
    get isSuperAdmin(): boolean {
        if (localStorage.getItem('RoleId') == '4' || localStorage.getItem('RoleId') == '5') { return true } else return false
    }
    constructor(protected CommonService: CommonService, protected toastr: ToastrService) {
        if(this.loadTenanat)this.getTennates();
    }
    activeSpinner() {
        this.CommonService.activateSpinner();
    }

    deactivateSpinner() {
        this.CommonService.deactivateSpinner()
    }
    getGridData(route: string, payload, callBack?: Function) {
        this.activeSpinner();
        this.CommonService.postCall(route, payload).subscribe(
            (res: any) => {
                this.deactivateSpinner()
                this.table = [];
                setTimeout(() => {
                    this.table = res;
                    if (callBack) {
                        callBack();
                    }
                })
            }, err => {
                this.deactivateSpinner()
              
            }
        )
    }
    public getTennates(callBack?: Function) {
        if (!this.isSuperAdmin) return
        this.activeSpinner();
        // this.CommonService.postCall('GetTenantByRoleId', { RoleId: this.roleId }).subscribe(
            this.CommonService.postCall('GetTenantByRoleId', { RoleId: this.roleId ,TENANT_CODE: localStorage.getItem('TenantCode')}).subscribe(
            (res) => {
                this.tenanates = res;
                this.deactivateSpinner();
                if (callBack) {
                    callBack();
                }
            }, err => {
                this.deactivateSpinner()
            }
        )
    }
    renderDataTable() {
        let t = $('#DataTables_Table_0').DataTable();
        t.destroy();
        this.dtTrigger.next();
    }
    changeTname() { }

    ///get admin cources
    getCourses(callback?:Function) {
        this.activeSpinner()
        let id=this.tId==''?null:this.tId
        this.CommonService.getAdminCourses(id).subscribe((res: any) => {
            this.courses = res;
            this.deactivateSpinner();
            if(callback){
                callback();
            }
        }, e => {
            this.deactivateSpinner()
        });

    }
    courseChange(callBack?:Function) {
      let data = {
            "CourseId": this.courseId
        }
        this.activeSpinner()
        this.CommonService.getAdminCourseSchedule(data).subscribe((res: any) => {
            this.deactivateSpinner()
            this.shedules = res;
            this.schedules=res;
            if(callBack){
                callBack()
            }
        }, e => { this.deactivateSpinner() });
    }

    //////////////////default action in page////////////////////
    post(url,payload,callback?:Function){
        this.activeSpinner();
        this.CommonService.postCall(url,payload).subscribe(
            (res)=>{
                this.deactivateSpinner();
                if(callback){
                    callback(res);
                }
            },err=>{
                this.deactivateSpinner();
            }
        )
    }


    add(){}
    close(){}
    onSubmit(form?:FormGroup){}
    edit(data){}
     //////////////////default action in page////////////////////

     loadReportDtOptions(){
           this.dtOptions={
      dom: 'Bfrtip',
      buttons:['excel'],
      order:[]
    }
     }
     back(){
         window.history.back();
     }
     studentCourses(){
        this.activeSpinner();
        this.CommonService.getCourses().subscribe((res: any) => {
          this.deactivateSpinner();
          this.courses = res;
        }, e => { this.deactivateSpinner(); })
     }
}