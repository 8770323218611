<div class="page-wrapper">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">{{headingName.Stationary}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <div >
                                            <table id="patreggraph" datatable
                                                class="table table-striped table-bordered mt-2 pt-2 table-sm small display"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624fd7; color:#ffffff;">
                                                    <tr>
                                                        <th> Campus Name </th>
                                                        <th> USID </th>
                                                        <th> Student Name </th>
                                                        <th> Gender </th>
                                                        <th> Status </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.campus_name }}</td>
                                                        <td>{{item.student_id }}</td>
                                                        <td>{{item.fullname}}</td>
                                                        <td>{{item.gender }}</td>
                                                        <td>{{item.status}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center">
                                            No Records to display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>