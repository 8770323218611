import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { add } from 'date-fns';
import { CommonService } from 'src/app/services/common.service';
import { startWith } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-campus-sales',
  templateUrl: './campus-sales.component.html',
  styleUrls: ['./campus-sales.component.css']
})
export class CampusSalesComponent implements OnInit {
  table: Array<any> = [];
  myForm: FormGroup;
  campus: Array<any> = [];
  isEdit: boolean = false;
  isCampus: boolean = false;
  items: Array<any> = [];
  total_cost: any;
  labelName: any = {};

  final_amount: any;
  total_discount: any;
  campuses: Array<any> = [];
  updateAmount: any = 0;
  updateDiscount: any = 0;
  updateFinal: any = 0;
  totalcost: any;
  addItem: any = {
    item: 0,
    price: 0,
    quantity: 0,
    amount: 0
  };
  editData: any;
  total_amount: any;
  camId: any;
  headingName: any = {};
  workItemId: any;


  constructor(private active: ActivatedRoute,private ref: ChangeDetectorRef, private toastr: ToastrService, private fb: FormBuilder, private CommonService: CommonService) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.campusSales();
    this.campusDropdown();
    this.loadItems();
    this.getLabels();
    this.loadHeadings();

  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      campus_id: ['', Validators.required],
      item_id: [''],
      amounts: ['', Validators.required],
      total_discounts: [''],
      final_amounts: ['', Validators.required],
      items_campus_id: [''],
      table_data: [''],
      list: this.fb.array([]),
    })
    this.add();
  }
  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }
  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  campusSales() {
    this.activeSpinner();
    let payLoad: any = {
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('EmsCampusSales/GetList', payLoad).subscribe((res: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = res;
      }, 10)
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner() })
  }

  campusDropdown() {

    let payload = {
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('EmsCampus/CampusDropDownList', payload).subscribe((res: any) => {
      this.campus = res;
    })
  }


  campusTable(cId: any) {

    this.camId = cId;
    this.isCampus = true;
    let payload = {
      "campus_id": cId
    }
    this.CommonService.postCall('EmsCampusSales/LoadCampusSalesbasedonCampusId', payload).subscribe((res: any) => {
      this.campuses = res;
    })
  }
  // onClick(){
  //   this.campusTable(cId);
  // }
  getOptionGroup(data) {
    let newGroup = this.fb.group({
      item_id: [data.item_id ? data.item_id : Number, Validators.required],
      price: [data.price ? data.price : Number, Validators.required],
      quantity: [data.quantity ? data.quantity : Number, Validators.required],
      amount: [data.amount ? data.amount : Number, Validators.required],
    });

    const priceCtrl = newGroup.get('price');
    const quantityCtrl = newGroup.get('quantity');
    const amountCtrl = newGroup.get('amount');
    const TotalCtrl = this.myForm.get('total_cost');
    const finalCtrl = this.myForm.get('final_amount');
    const discountCtrl = this.myForm.get('total_discount');
    combineLatest(priceCtrl.valueChanges.pipe(startWith(priceCtrl.value)),
      quantityCtrl.valueChanges.pipe(startWith(quantityCtrl.value))).subscribe(([price, quantity]) =>
        amountCtrl.setValue(price * quantity))

    if (this.myForm.get('list')) {
      this.myForm.get('list').valueChanges.subscribe(values => {
        this.total_cost = 0;
        this.final_amount = 0;
        // this.total_discount = 0;
        this.myForm.value.total_discount;
        const ctrl = <FormArray>this.myForm.controls['list']
        ctrl.controls.forEach(x => {
          if (this.total_discount == 0) {
            this.total_cost += x.get('amount').value;
            this.final_amount = x.get('amount').value;
          }
          else {
            this.total_cost += x.get('amount').value;
            this.final_amount += x.get('amount').value;

          }


          this.ref.detectChanges()

        })
      })
    }

    return newGroup
  }


  discountCalculate(discount: any) {
    return this.total_cost * discount / 100;
  }

  discountChange(discountper: any) {
    this.final_amount = this.total_cost - this.discountCalculate(discountper)
  }

  add(data = {}) {

    const arrayControl = <FormArray>this.myForm.controls['list'];
    let grp: FormGroup = this.getOptionGroup(data)
    arrayControl.push(grp)
  }
  delete() {
    const arrayControl = <FormArray>this.myForm.controls['list'];
    let index = arrayControl.length;
    arrayControl.removeAt(index - 1)

  }


  isVisable() {
    let arrayControl = <FormArray>this.myForm.controls['list'];
    if (arrayControl.length && arrayControl.length > 0) {
      return true
    }
    else {
      return false
    }
  }
  isAdd() {

    let arrayControl = <FormArray>this.myForm.controls['list'];
    if (arrayControl.length && arrayControl.length > 25) {
      return false
    }
    else {
      return true
    }

  }

  loadItems() {
    this.items = [];
    this.CommonService.postCall('InvetoryItems/InventoryItemsCampus', {}).subscribe((res: any) => {
      this.items = res;
    })
  }
  priceAmount(event, i) {
    let ovj = this.items.filter(x => x.item_id == event.target.value);
    ovj.forEach(e => {
      // this.ucost[i] = e.unit_price
      this.myForm.value.list[i].price = e.unit_price;
    });
  }

  //   let obj={
  // price:ovj.price
  //   }
  //   arr.push(obj);

  // add(){

  // }

  // priceAmounts(event,i){
  //   let arr=[];
  //   let obj  = this.items.find(x => x.item_id == event.target.value);
  //   let obj1  = this.items.find(x => x.price == event.target.value);
  //     // this.ucost[i] = e.unit_price

  //   arr.push(obj)
  //   arr.push(obj1)
  // }

  onSubmit(myForm: FormGroup) {
    let value: any = myForm.value;
    let list = [];
    myForm.value.list.forEach(e => {
      let item: any = '';
      Object.keys(e).map((key, i) => {
        let value = e[key];
        if (i > 0) {
          if (value) {
            item = item ? item + ',' + i : i;
          }
        }
      })
      let obj = {
        "item_id": e.item_id,
        "price": e.price,
        "quantity": e.quantity,
        "amount": e.amount
        // "floor_name": e.floor_name,
        // "amenities": item
      }
      list.push(obj);
    });
    let payload = {
      "campus_id": value.campus_id,
      "total_discount": (+value.total_discounts) + (+this.updateDiscount),
      "total_amount": value.amounts + this.updateAmount,
      "final_amount": value.final_amounts + this.updateFinal,
      "ItemsCampusDetails": list,
      "tnt_code": localStorage.getItem('TenantCode'),
      "created_by": localStorage.getItem('UserId'),
      "modified_by": localStorage.getItem('UserId'),


    }
    if (this.isEdit) {
      value.modified_by = localStorage.getItem('UserId');
      // value['room_type_id'] = this.editData.room_type_id;
      payload['items_campus_id'] = this.editData.items_campus_id;
      this.CommonService.postCall('EmsCampusSales/Update', payload).subscribe((res: any) => {
        this.campusSales();
        this.toastr.success("Updated Succuessfully");
        document.getElementById('md_close')?.click();
        this.updateAmount = 0;
        this.updateDiscount = 0;
        this.updateFinal = 0;

      }, err => {
        this.toastr.error(err.error ? err.error : 'Not Updated')
      })
    } else {
      this.CommonService.postCall('EmsCampusSales/Create', payload).subscribe((res: any) => {
        this.campusSales();
        this.toastr.success("CampusSales Created Successfully");
        document.getElementById('md_close')?.click();
        this.updateAmount = 0;
        this.updateDiscount = 0;
        this.updateFinal = 0;

      }, err => {
        this.toastr.error(err.error ? err.error : 'ClassItems Not created')

      })

    }

  }
  edit(campusid) {
    this.editData = campusid;
    this.isEdit = true;
    this.myForm.reset();
    let payLoad = {
      "items_campus_id": campusid
    }
    this.CommonService.postCall('EmsCampusSales/Get', payLoad).subscribe((res: any) => {
      this.updateAmount = res.total_amount;
      this.totalcost;
      this.updateDiscount = res.total_discount;
      this.updateFinal = res.final_amount;
      if (res instanceof Array && res.length) {
        this.editData = res[0];
        this.dataTransfer(res[0]);
      } else {
        this.editData = res;
        this.dataTransfer(res)
      }
      this.campusTable(this.editData.campus_id)


    })
  }

  dataTransfer(data) {
    let ctrls = this.myForm.controls;
    // Object.keys(ctrls).map(key => {
    //   let ctrl = ctrls[key];
    //   ctrl?.setValue(data[key])
    // })

    ctrls['campus_id'].setValue(data['campus_id'])
    ctrls['amounts'].setValue(data['amounts'])
    // ctrls['table_data'].setValue(data['table_data'])

  }
  close() {
    this.isEdit = false;
    this.myForm.reset();
    this.editData = {};
    this.isCampus = null;
  }

}





