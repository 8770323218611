import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray, AbstractControl } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-book-allocation-report',
  templateUrl: './book-allocation-report.component.html',
  styleUrls: ['./book-allocation-report.component.css']
})
export class BookAllocationReportComponent implements OnInit {
  myForm: FormGroup;
  titleOption: Array<any> = [];

  labelName: any = {};
  headingName: any = {};
  workItemId: any;

  accession_no:any;
  constructor(private commonService: CommonService, private fb: FormBuilder, private active: ActivatedRoute, private route: Router, toastr: ToastrService) {
    this.active.params.subscribe(res=>{
      console.log(res);      
      this.workItemId = res.id
    })
    this.getLabels();
    this.loadHeadings();

  }
  ngOnInit(): void {
    this.myForm = this.fb.group({
      // title: ['', Validators.required],
      accession_no:['',Validators.required]
    })
    // this.getTitles()
  }
  getLabels() {
    this.labelName = {};
    this.commonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.commonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  activeSpinner() {
    this.commonService.activateSpinner();
  }

  deactivateSpinner() {
    this.commonService.deactivateSpinner()
  }

  // getTitles() {

  //   this.activeSpinner();
  //   let payLoad = {
  //     "BOOK_TNT_CODE": localStorage.getItem('TenantCode')

  //   }
  //   this.commonService.postCall("LibraryManagement/BooksDropDown", payLoad).subscribe((res: any) => {
  //     this.titleOption = res;

  //     this.deactivateSpinner();
  //   }, e => {
  //     this.deactivateSpinner();
  //   });

  // }




  onSubmit(form) {

    this.route.navigate(['/home/viewReport'], {queryParams:{ wId : this.workItemId,titleId:this.accession_no}}),{
      // this.route.navigate(['/home/addSurvey'], { queryParams: params })
    }

    // let titleObj = this.titleOption.filter(x => x.BOOK_ID == form.value['accession_no']);
    // let isbnObj = this.isbnOption.filter(x => x.CURRICULUM_ID == form.value['isbn']);


    // this.route.navigate(['/home/viewReport'], {
    //   queryParams: {
    //     wId : this.workItemId, titleId: titleObj[0]['BOOK_ID'], titleName: titleObj[0]['BOOK_NAME'], bookCode: titleObj[0]['BOOK_CODE']
    //   }
    // })

  }
}
