import { Component, OnInit } from '@angular/core';
import { FormArray, FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-compus',
  templateUrl: './compus.component.html',
  styleUrls: ['./compus.component.css']
})
export class CompusComponent implements OnInit {
  table: Array<any> = [];
  myForm: FormGroup;
  headingName: any = {};
  workItemId : any;
  constructor(private fb: FormBuilder, private CommonService: CommonService, toastr: ToastrService, private route: Router,private active : ActivatedRoute) {
 
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadCampus();
    this.getLabels();
    this.loadHeadings();
  }

  ngOnInit(): void {

  }

  loadCampus() {
    this.CommonService.postCall('EmsCampus/GetList', { "tnt_code": localStorage.getItem('TenantCode') }).subscribe((res: any) => {
      this.table = [];
      setTimeout(() => {
        this.table = res;
      }, 10)
    })
  }

  add() {
    this.route.navigate(['home/addCampus/add']);
  }

  edit(item: any) {
    let payload = {
      cId: item.campus_id
    }
    this.route.navigate(['home/addCampus/edit'], { queryParams: payload });
  }

  getLabels() {
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      console.log(res);
      res.forEach(element => {
        if (element.dynamic_lable_id == 11) {
          console.log(element.label_value);
        }


      });
    })
  }


  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
}
