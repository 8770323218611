import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, Form, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { makeStateKey } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Logger } from 'html2canvas/dist/types/core/logger';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { async } from 'rxjs/internal/scheduler/async';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-edit-time-table-new',
  templateUrl: './edit-time-table-new.component.html',
  styleUrls: ['./edit-time-table-new.component.css']
})
export class EditTimeTableNewComponent implements OnInit {
  timeTableId: number = 0;
  periodList: Array<any> = [];
  cources: Array<any> = [];
  trainer: Array<any> = [];
  subjects: Array<any> = [];
  rooms: Array<any> = [];
  editData: any;
  editType: string = '';
  myAssignSubjectForms: FormGroup;
  editTimeTableForms: FormGroup;
  viewTable: boolean = false;
  labelName: any = {};
  selectedItem = { item: {}, index: 0 };
  headingName: any = {};
  workItemId: any;
  constructor(private fb: FormBuilder, private active: ActivatedRoute, private CommonService: CommonService, private toastr: ToastrService, private route: Router, private ref: ChangeDetectorRef) {
    active.queryParams.subscribe((res) => {
      this.timeTableId = res.timeTableId;
      this.workItemId = res.wId
    });
    Promise.all([this.editRes(), this.getCourses()]);
    // this.editRes().then(() => {
    //   this.getTrainer();
    //   this.getSubject();
    // });
    // this.getCourses();
    this.loadlabels();
    this.loadHeadings();
  }

  ngOnInit(): void {
    this.assignSubjectInit();
    this.editTimeTableInit();
  }

  loadlabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
      console.log(res);
    })
  }

  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }


  editTimeTableInit() {
    this.editTimeTableForms = this.fb.group({
      type: ['', Validators.required],
      date: ['', Validators.required],
      reason: ['', Validators.required],
      teacher: [0],
      subject: [0],
      room_id: [0]

    });
  }

  assignSubjectInit() {
    this.myAssignSubjectForms = this.fb.group({
      teacher_id: [''],
      subject: [''],
      room_id: ['']
    });
  }

  getCourses() {
    this.activeSpinner()
    this.CommonService.getAdminCourses().subscribe((res: any) => {
      this.cources = res;
      this.deactivateSpinner();
    }, e => {
      this.deactivateSpinner();
    })
  }
  //LoadSubjectsByCourseshd/{courseshd_id}
  async getTrainer() {
    await this.CommonService.getCall(`Registration/LoadTrainersByCourse/${this.editData.course_id}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.trainer = res;
    });
  }

  async editRes() {
    this.viewTable = true;
    await this.CommonService.getCall(`TimeTable/GetTimeTable/${this.timeTableId}`).subscribe((res: any) => {
      this.editData = res;
      // this.periodList = this.groupBy(this.editData.periods, 'period');
      var date = this.groupBy(this.editData.periods, 'period');
      var resultArray = Object.keys(date).map(function (personNamedIndex) {
        return date[personNamedIndex];
      });
      //this.periodList = resultArray;
      let obj = this.getNewPeriod();
      resultArray.forEach((m, index) => {
        let weekdayArr: Array<any> = m.map(e => e['week_day']);
        for (let i = 0; i < 6; i++) {
          if (!weekdayArr.includes(i + 1)) {
            obj.week_day = i + 1;
            obj.period = index + 1;
            obj.period_start_time = m.find(e => e.teacher_id > 0).period_start_time;
            obj.period_end_time = m.find(e => e.teacher_id > 0).period_end_time;
            m.splice(i, 0, Object.assign({}, obj));
          }
        }
      })
      this.periodList = resultArray;
      this.getTrainer();
      this.getSubject();
      this.getRooms();
    });
  }

  async getSubject() {
    await this.CommonService.getCall(`Subject/LoadSubjectsByCourse/${this.editData.course_id}`).subscribe((res: any) => {
      this.subjects = res;
    });
  }



  async getRooms() {
    let payload = {
      "tnt_code": localStorage.getItem('TenantCode')
    }

    this.activeSpinner();
    // await this.CommonService.getCall(`Subject/LoadSubjectsByCourseshd/${id}`).subscribe((res: any) => {
    await this.CommonService.postCall('EmsRoom/GetList', payload).subscribe((res: any) => {
      this.rooms = res;
      this.deactivateSpinner();
    },
      err => { this.deactivateSpinner(); });
  }

  getNewPeriod() {
    return {
      period: 0,
      period_end_time: "",
      period_start_time: "",
      subject_id: 0,
      subject_name: "",
      teacher_id: 0,
      teacher_name: "",
      timeTableHistroy: null,
      timetable_details_id: 0,
      week_day: 0
    };
  }

  addNewPeriod(event: any) {
    let value = event.target.value;
    let arr: Array<any> = [];
    let obj = this.getNewPeriod();
    for (let i = 0; i < 6; i++) {
      obj.week_day = i + 1;
      obj.period = this.periodList.length + 1;
      arr.push(Object.assign({}, obj));
    }
    if (value == 1)
      this.periodList.push(arr);
    else {
      if (this.periodList[this.periodList.length - 1][0].timetable_details_id == 0)
        this.periodList.pop();
    }
    console.log('Period List', this.periodList);
  }

  assignTeacher(item: any, index: number) {
    this.selectedItem.index = index;
    this.selectedItem.item = item;
    this.editTimeTableInit();
    console.log('selected item', item);
    this.editTimeTableForms.patchValue({
      teacher: item.teacher_id,
      subject: item.subject_id,
      room_id: item.room_id
    });
  }


  onSubmit() {
    let data = [];
    this.periodList.forEach((m: Array<any>) => {
      m.forEach(e => {
        e.period_end_time = m[0].period_end_time;
        e.period_start_time = m[0].period_start_time;
        if (e.subject_id > 0 && e.teacher_id > 0 && e.room_id > 0)
          data.push(e);
      });
    });
    this.editData.periods = data;
    let payload = this.editData;
    payload['operation'] = 'update';
    this.CommonService.postCall('TimeTable/CreateUpdateTimeTable', payload).subscribe((res: any) => {
      this.route.navigate([`home/time-table/${this.workItemId}`]);
      this.toastr.success("Time Table Updated Succuessfully");
    }, err => {
      this.toastr.error(err.error ? err.error : 'Time Table Not Updated');
    })
  }

  SubmitEdit(assignForm: FormGroup) {
    this.periodList[this.selectedItem.index]?.map((item) => {
      if (item.week_day == this.selectedItem.item['week_day']) {
        item.teacher_id = +assignForm.value.teacher_id,
          item.teacher_name = this.trainer.find(m => m.userid == +assignForm.value.teacher_id).trainer_name,
          item.subject_id = +assignForm.value.subject,
          item.subject_name = this.subjects.find(m => m.subject_id == +assignForm.value.subject).subject_name,
          item.room_id = +assignForm.value.room_id,
          item.room_number = this.rooms.find(m => m.room_id == +assignForm.value.room_id)?.room_number
      }
    });
    this.closeAssignSubject();
    $('.close').click();
    document.getElementById('assignSub_close')?.click();
  }

  SubmitEditTimeTable(forms: FormGroup) {
    this.periodList[this.selectedItem.index]?.map((item, index) => {
      if (item.timetable_details_id == this.selectedItem.item['timetable_details_id']) {
        item.teacher_id = +forms.value.teacher,
          item.teacher_name = this.trainer.find(m => m.userid == +forms.value.teacher).trainer_name,
          item.subject_id = +forms.value.subject,
          item.subject_name = this.subjects.find(m => m.subject_id == +forms.value.subject).subject_name,
          item.room_id = +forms.value.room_id,
          item.room_number = this.rooms.find(m => m.room_id == +forms.value.room_id).room_number,
          item.timeTableHistroy = {
            date: moment(forms.value.date).format('yyyy-MM-DD'),
            reason: forms.value.reason,
            timetable_history_status: '',
          }
      }
    });
    this.closeEdit();
  }

  groupBy = (items, key) => items.reduce(
    (result, item) => ({ ...result, [item[key]]: [...(result[item[key]] || []), item,], }), {});

  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner();
  }

  close() {
    this.route.navigate([`home/time-table/${this.workItemId}`]);
  }

  closeAssignSubject() {
    this.assignSubjectInit();
  }

  closeEdit() {
    this.editTimeTableInit();
  }



}
